import React, {useState} from 'react'
import {Modal} from 'react-bootstrap'
import {Row, Col} from 'react-bootstrap'
import close from '../../../img/close.png'
import submitImg from '../../../img/Submit-White.png'
import './style.scss'
import {ApiPut} from '../../../apiCommon/helpers/API/ApiData'
import {SuccessToast, ErrorToast} from '../../../apiCommon/helpers/Toast'
import { capitalizeFirstLetter } from '../../../Utilities/utils'

interface ButtonProps {
  show: any
  handleClose: any
  type: any
  invSeq: any
  getDetails: any
}

const InvConfigureModal = ({handleClose, show, type, invSeq, getDetails}: ButtonProps) => {
  const [startNo, setStartNo] = useState<any>(invSeq?.startNumber)
  const [inc, setInc] = useState<any>(invSeq?.increment)
  const [noOfDigits, setNoOfDigits] = useState<any>(invSeq?.noOfDigits)
  const [prefix, setPrefix] = useState<any>(invSeq?.prefix)
  const [suffix, setSuffix] = useState<any>(invSeq?.suffix)
  const [max, setMax] = useState<any>('10')
  const [maxDigitError, setMaxDigitError] = useState<any>(false)

  const [startNoError, setStartNoError] = useState<any>(false)
  const [incError, setIncError] = useState<any>(false)
  const [noOfDigitsError, setNoOfDigitsError] = useState<any>(false)

  const generateSequence = () => {
    if (
      startNo != '0' &&
      startNo != '' &&
      inc != '0' &&
      inc != '' &&
      noOfDigits != '0' &&
      noOfDigits != ''
    ) {
      if (noOfDigits > 10) {
        setMaxDigitError(true)
        setNoOfDigitsError(true)
      } else {
        let body: any
        if (type == 'invoice') {
          body = {
            isInvoiceSequence: true,
            invoiceSequence: {
              startNumber: parseInt(startNo),
              increment: parseInt(inc),
              noOfDigits: parseInt(noOfDigits),
              activityStatus: invSeq?.activityStatus,
              prefix: prefix,
              suffix: suffix,
              lastCount: invSeq?.lastCount,
            },
          }
        } else if (type == 'receipt') {
          body = {
            isReceiptSequence: true,
            receiptSequence: {
              startNumber: parseInt(startNo),
              increment: parseInt(inc),
              noOfDigits: parseInt(noOfDigits),
              activityStatus: invSeq?.activityStatus,
              prefix: prefix,
              suffix: suffix,
              lastCount: invSeq?.lastCount,
            },
          }
        } else if(type == 'creditnote') {
          body = {
            isCreditNoteSequence: true,
            creditNoteSequence: {
              startNumber: parseInt(startNo),
              increment: parseInt(inc),
              noOfDigits: parseInt(noOfDigits),
              activityStatus: invSeq?.activityStatus,
              prefix: prefix,
              suffix: suffix,
              lastCount: invSeq?.lastCount,
            },
          }
        }
        else if(type == 'refund') {
          body = {
            isRefundSequence: true,
            refundSequence: {
              startNumber: parseInt(startNo),
              increment: parseInt(inc),
              noOfDigits: parseInt(noOfDigits),
              activityStatus: invSeq?.activityStatus,
              prefix: prefix,
              suffix: suffix,
              lastCount: invSeq?.lastCount,
            },
          }
        }
        else {
          body = {
            isDebitNoteSequence: true,
            debitNoteSequence: {
              startNumber: parseInt(startNo),
              increment: parseInt(inc),
              noOfDigits: parseInt(noOfDigits),
              activityStatus: invSeq?.activityStatus,
              prefix: prefix,
              suffix: suffix,
              lastCount: invSeq?.lastCount,
            },
          }
        }

        ApiPut('corporate/corporate_setting', body)
          .then((res) => {
            handleClose()
            if (type == 'invoice')
              SuccessToast('Sequence for INVOICE NO. has been generated successfully!!!')
            else if (type == 'receipt')
              SuccessToast('Sequence for RECEIPT NO. has been generated successfully!!!')
            else if (type == 'creditnote') SuccessToast('Sequence for CREDIT NOTE NO. has been generated successfully!!!')
            else if (type == 'refund') SuccessToast('Sequence for REFUND NO. has been generated successfully!!!')
            else SuccessToast('Sequence for DEBIT NOTE NO. has been generated successfully!!!')
            getDetails()
          })
          .catch((err) => ErrorToast(err.message))
      }
    } else {
      if (startNo == 0 || startNo == '') setStartNoError(true)
      if (inc == 0 || inc == '') setIncError(true)
      if (noOfDigits == 0 || noOfDigits == '') setNoOfDigitsError(true)
    }
  }
  return (
    <div>
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-700px mx-auto'
        show={show}
        onHide={() => {
          handleClose()
        }}
        backdrop={true}
        size='sm'
      >
        <div
          className='modal-header text-white ps-5 pe-1 header-style'
          style={{zIndex: '1', opacity: '0.85', borderBottom: '0px', minHeight: '93px'}}
        >
          <Row className='w-100'>
            <Col xs={8} sm={8} md={8} lg={8}>
              <h2 className='mx-0 my-1 mt-2 text-white'>
                {type == 'invoice'
                  ? 'Invoice No. Generator'
                  : type == 'receipt'
                  ? 'Receipt No. Generator'
                  : type == 'creditnote'
                  ? 'Credit Note No. Generator'
                  : type == 'refund'
                  ? 'Refund No. Generator'
                  : 'Debit Note No. Generator'}
              </h2>
            </Col>
            <Col xs={4} sm={4} md={4} lg={4} className='text-end pe-0'>
              <div
                className='btn btn-sm btn-icon btn-active-color-primary ps-auto modal-header-cross'
                onClick={handleClose}
              >
                <img className='svg-icon-1 text-black' src={close} width='14px' height='14px' />
              </div>
            </Col>
          </Row>
        </div>

        <div className='header-bg'>
          <Row className='mx-2'>
            <Col xs={9} sm={9} md={9} lg={9}></Col>
            <Col xs={3} sm={3} md={3} lg={3} className='pt-2 '>
              {/* <img src={latePayment} height='80px' width='80px' className='me-3 ms-5' /> */}
            </Col>
          </Row>
        </div>
        <div
          className='modal-body py-lg-10 px-lg-10 pt-lg-7'
          style={{transform: 'translate(0px, -80px)', marginBottom: '-65px'}}
        >
          <Row className='align-items-end'>
            <Col lg={6}></Col>
            <Col lg={6} md={6} className='align-items-center text-end'>
              <button
                className='btn btn-sm fw-bold ms-3 ps-4 text-white generate-btn status-w-180 ms-auto'
                onClick={() => generateSequence()}
              >
                <img
                  src={submitImg}
                  height={20}
                  width={20}
                  className='ms-1 me-5'
                  
                />{' '}
                {'  '}
                Generate
              </button>
            </Col>
          </Row>
          <Row className='align-items-start mt-5'>
            <Col xs={12} sm={6} md={6} lg={6} className='align-items-center px-3 mb-5'>
              <label className='required mb-2 label-color'>Start No.</label>
              <input
                type='number'
                className={
                  startNoError
                    ? 'form-control form-control-solid-bg border-danger'
                    : 'form-control form-control-solid-bg mytest'
                }
                placeholder='Enter Start No.'
                name='item_id'
                value={startNo}
                onChange={(event: any) => {
                  setStartNoError(false)
                  setStartNo(event?.target.value)
                }}
              />
            </Col>
            <Col xs={12} sm={6} md={6} lg={6} className='align-items-center px-3 mb-5'>
              <label className='required mb-2 label-color'>Increment</label>
              <input
                type='number'
                className={
                  incError
                    ? 'form-control form-control-solid-bg border-danger'
                    : 'form-control form-control-solid-bg mytest'
                }
                placeholder='Enter Number'
                name='item_id'
                value={inc}
                onChange={(event: any) => {
                  setIncError(false)
                  setInc(event?.target.value)
                }}
                min={1}
              />
            </Col>
            <Col xs={12} sm={6} md={6} lg={6} className='align-items-center px-3 mb-5'>
              <label className='required mb-2 label-color'>No of Digits</label>
              <input
                type='number'
                className={
                  noOfDigitsError
                    ? 'form-control form-control-solid-bg border-danger'
                    : 'form-control form-control-solid-bg mytest'
                }
                placeholder='Enter No. of Digits'
                name='item_id'
                value={noOfDigits}
                onChange={(event: any) => {
                  setNoOfDigits(event?.target.value)
                  setNoOfDigitsError(false)
                  if (event.target.value > 10) {
                    setNoOfDigitsError(true)
                    setMaxDigitError(true)
                  } else {
                    setMaxDigitError(false)
                  }
                }}
                max={max}
              />
              {maxDigitError && (
                <p className='text-danger'> The No. of Digits should not exceed 10 </p>
              )}
            </Col>

            <Col xs={12} sm={6} md={6} lg={6} className='align-items-center px-3 mb-5'>
              <label className='mb-2 label-color'>Prefix.</label>
              <input
                type='text'
                className='form-control form-control-solid-bg mytest'
                placeholder='Enter Prefix'
                name='item_id'
                value={prefix}
                onChange={(event: any) => {
                  if (event.target.value == '') setPrefix(null)
                  else {
                    let newVal:any = capitalizeFirstLetter(event?.target.value)
                    setPrefix(newVal)
                  }
                }}
              />
            </Col>

            <Col xs={12} sm={6} md={6} lg={6} className='align-items-center px-3 mb-5'>
              <label className='mb-2 label-color'>Suffix</label>
              <input
                type='text'
                className='form-control form-control-solid-bg mytest'
                placeholder='Enter Suffix'
                name='item_id'
                value={suffix}
                onChange={(event: any) => {
                  if (event.target.value == '') setSuffix(null)
                  else {
                    let newVal:any = capitalizeFirstLetter(event?.target.value)
                    setSuffix(newVal)
                  }
                }}
              />
            </Col>

            {/* <Col lg={6} md={6} className='align-items-center  ps-5 mb-5'>
              <label className='mb-2 label-color'>Last Count</label>
              <input
                type='number'
                className='form-control form-control-solid-bg mytest'
                placeholder='Enter Suffix'
                name='item_id'
                style={{
                  border: '0.1rem solid #bec3cb',
                  borderRadius: '2px',
                  padding: '0.7rem 1rem',
                }}
                value={invSeq?.lastCount}
                disabled
                
              />
            </Col> */}
          </Row>
        </div>
      </Modal>
    </div>
  )
}

export default InvConfigureModal
