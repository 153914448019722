import React, {useEffect, useState, useRef} from 'react'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {
  ApiGet,
  ApiGetNoAuth,
  ApiPost,
  ApiPut,
  ApiUpload,
  Bucket,
  ApiDelete,
} from '../../../../../apiCommon/helpers/API/ApiData'
import {ErrorToast, SuccessToast} from '../../../../../apiCommon/helpers/Toast'
import swal from 'sweetalert2'
import moment from 'moment'
import backArrow from '../../../../../img/back-arrow.png'
import trashImg from '../../../../../img/trash.png'
import Select from 'react-select'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import pencil from '../../../../../img/edit-pen.png'
import Paper from '@mui/material/Paper'
import InputBase from '@mui/material/InputBase'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import ReactPaginate from 'react-paginate'
import addToListIcon from '../../../../../img/vendor-lists-add.png'
import correct from '../../../../../img/correct.png'
import noData from '../../../../../img/NoData1.svg'
import { capitalizeFirstLetter } from '../../../../../Utilities/utils'

const ManageLists = () => {
  const [page, setPage] = useState<any>(1)
  const [limit, setLimit] = useState<any>(25)
  const [pageLimit, setPageLimit] = useState<any>(1)

  const handlePageClick = (event: any) => {
    setPage(event.selected + 1)
  }

  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState<any>(false)

  const [open, setOpen] = React.useState(false)

  // React Select
  const customStyles = {
    container: (defaultStyles: any) => ({
      ...defaultStyles,
      width: '170px',
    }),

    option: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      color: state.isSelected ? '#000' : '#000',
      backgroundColor: state.isSelected ? '#c2d8d9' : '',
      ':hover': {
        backgroundColor: '#e0f5f0',
        color: '#000',
      },

      fontWeight: '500',
    }),

    menu: (defaultStyles: any) => ({
      ...defaultStyles,
      border: '0.1rem solid #007a59 ',
      marginTop: '0px',
      borderRadius: '0px',
      zIndex: '2',
    }),
    menuList: (defaultStyles: any) => ({
      ...defaultStyles,
      maxHeight: '200px',
    }),
    placeholder: (defaultStyles: any) => ({
      ...defaultStyles,
      fontStyle: 'Italic',
      zIndex: '1',
    }),

    input: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      color: ' #b5b5c3',
      fontWeight: '500',
    }),

    control: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      boxShadow: 'none',
      color: 'white',
      border: '0.1rem solid #bec3cb',
      borderRadius: '2px',
      padding: '0rem',
      height: '35px',
      ':hover': {
        borderColor: '#007a59',
        backgroundColor: '#fff',
      },
      ':focus': {
        borderColor: '#007a59 !important',
        backgroundColor: '#fff',
      },
      '::placeholder': {
        fontStyle: 'Italic',
      },
      minHeight: '35px',
    }),
    singleValue: (defaultStyles: any) => ({
      ...defaultStyles,
      color: '#5e6278',
      fontWeight: '600',
      zIndex: '1',
    }),
    valueContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: '0px 8px 2px 8px',
    }),
    indicatorContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: '0px 8px 2px 8px',
    }),
    indicatorsContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: '0px 8px 2px 8px',
    }),
  }

  const searchContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#f0f0f0',
    borderRadius: '25px',
    // marginLeft: '10px',
  }

  const searchIconStyle = {
    padding: '10px',
    color: 'gray',
  }

  const inputBaseStyle = {
    flex: 1,
    padding: '5px',
  }

  const [sortType, setSortType] = useState<any>('A-Z')
  const [sortOptions, setSortOptions] = useState<any>([
    {value: 'A-Z', label: 'Alphabetic (A-Z)'},
    {value: 'Z-A', label: 'Alphabetic (Z-A)'},
    {value: 'latest', label: '(Latest - Oldest)'},
    {value: 'oldest', label: '(Oldest - Latest)'},
  ])

  const [searchName, setSearchName] = useState<any>('')
  const [lists, setLists] = useState<any>([])
  const [checkedValues, setCheckedValues] = useState<any>([])

  let totalSelected: any = 0

  const getServiceLists = async () => {
    setIsLoading(true)
    setLists([])
    let body = {
      page: page,
      limit: limit,
      search: searchName,
      sortType: sortType,
    }

    await ApiPost(`corporate/maintenance_service_list/get`, body)
      .then((res) => {
        setLists(res?.data?.data?.maintenance_service_list_data)

        let values: any = []
        for (let i = 0; i < res?.data?.data?.maintenance_service_list_data; i++) {
          values[i] = false
        }
        setCheckedValues(values)

        setPageLimit(res?.data?.data?.state?.page_limit)
        setTimeout(() => {
          setIsLoading(false)
        }, 1500)
      })
      .catch((err) => {
        ErrorToast(err?.message)
        setTimeout(() => {
          setIsLoading(false)
        }, 1000)
      })
  }

  useEffect(() => {
    getServiceLists()
  }, [searchName, sortType, page])

  const [editFile, setEditFile] = useState<any>(-1)
  const [newName, setNewName] = useState<any>('')

  //
  const deleteMultipleServiceList = () => {
    setIsLoading(true)
    let ids: any = []

    let i: any = 0

    checkedValues?.map((ch: any, ind: any) => {
      if (ch == true) {
        ids[i] = lists[ind]?._id
        i++
      }
    })

    const body = {
      ids: ids,
    }

    ApiPost(`corporate/maintenance_service_list/delete`, body)
      .then((res: any) => {
        SuccessToast(res?.data?.message)
        setIsLoading(false)

        getServiceLists()

        let values = []

        for (let i = 0; i < lists?.length; i++) {
          values[i] = false
        }

        setCheckedValues(values)
      })
      .catch((err: any) => {
        setIsLoading(false)
        ErrorToast(err?.message)
      })
  }

  //
  const deleteSingleServiceList = (v: any) => {
    setIsLoading(true)
    let ids: any = []

    ids[0] = v?._id

    const body = {
      ids: ids,
    }

    ApiPost(`corporate/maintenance_service_list/delete`, body)
      .then((res: any) => {
        SuccessToast(res?.data?.message)
        setIsLoading(false)

        getServiceLists()

        let values = []

        for (let i = 0; i < lists?.length; i++) {
          values[i] = false
        }

        setCheckedValues(values)
      })
      .catch((err: any) => {
        setIsLoading(false)
        ErrorToast(err?.message)
      })
  }

  const updateName = (data: any) => {
    setIsLoading(true)

    let name = newName.charAt(0).toUpperCase() + newName.slice(1)

    const body = {
      id: data?._id,
      name: name,
    }
    ApiPut(`corporate/maintenance_service_list`, body)
      .then((res: any) => {
        SuccessToast(res?.data?.message)
        setIsLoading(false)
        getServiceLists()

        setTimeout(() => {
          setEditFile(-1)
        }, 500)
      })
      .catch((err: any) => {
        setIsLoading(false)
        ErrorToast(err?.message)
      })
  }

  const [showAddNew, setShowAddNew] = useState<any>(false)
  const [newError, setNewError] = useState<any>(false)

  const addList = () => {
    if (newName?.trim()?.length > 0) {
      setNewError(false)
      setIsLoading(true)

      let name = newName.charAt(0).toUpperCase() + newName.slice(1)

      const body = {
        name: name,
      }
      ApiPost(`corporate/maintenance_service_list`, body)
        .then((res: any) => {
          SuccessToast(res?.data?.message)
          setIsLoading(false)
          getServiceLists()

          setTimeout(() => {
            setShowAddNew(false)
          }, 300)
        })
        .catch((err: any) => {
          setIsLoading(false)
          ErrorToast(err?.message)
        })
    } else {
      setNewError(true)
    }
  }

  return (
    <>
      <div className='app-main flex-column flex-row-fluid px-2' id='kt_app_main'>
        <div id='' className='pt-0 mt-0'>
          {/* Header */}
          <div className='page-title d-flex flex-column justify-content-center flex-wrap'>
            <div className=' d-flex flex-stack pt-1 mt-0 px-0'>
              {' '}
              <div className=''>
                <div className='d-flex align-items-center mb-1'>
                  <span
                    className='rounded-circle'
                    style={{cursor: 'pointer'}}
                    onClick={() => {
                      navigate('/settings/maintenance/services-library')
                    }}
                  >
                    <img src={backArrow} style={{stroke: 'red'}} height='16' width='16' />
                  </span>
                  <h1 className='m-0 head-text ms-2'>{'Manage Service Lists'}</h1>
                </div>
              </div>
            </div>
          </div>

          {checkedValues?.length > 0 && (
            <>
              {checkedValues?.map((ch: any, ind: any) => {
                if (ch == true) totalSelected = totalSelected + 1
                return <></>
              })}
            </>
          )}

          <div className='card card-flush py-5 mt-5 pt-0 px-5'>
            <div
              className='d-flex align-items-center py-5 pt-2 gy-3 mx-0 mt-4 xyz tst'
              // style={{ justifyContent: "space-between" }}
            >
              <label className='head-text me-5 pe-3' style={{fontWeight: '600'}}>
                Search{' '}
              </label>

              {/* Seach by Vendor */}
              <div className='me-4 xyz'>
                <Paper component='form' style={searchContainerStyle}>
                  <InputBase
                    placeholder='List Name...'
                    style={inputBaseStyle}
                    inputProps={{'aria-label': 'search'}}
                    value={searchName}
                    onChange={(e: any) => setSearchName(e.target.value)}
                  />
                  <IconButton style={searchIconStyle} aria-label='search'>
                    <SearchIcon />
                  </IconButton>
                </Paper>
              </div>
              <div className='me-4 xyz'>
                <Select
                  placeholder={'Sort'}
                  styles={customStyles}
                  isSearchable={false}
                  options={sortOptions}
                  name='sort'
                  onChange={(e: any) => {
                    setSortType(e.value)
                  }}
                />
              </div>

              <div className=' d-flex ms-auto'>
                {totalSelected > 0 && (
                  <>
                    <div className='d-flex'>
                      <h3 className='my-2' style={{color: 'black'}}>
                        <label className='labl-gry '>
                          {' '}
                          <i>Total Selected </i>
                        </label>
                      </h3>
                      <span className='ms-5 ps-1 my-2 me-4'>
                        <b>{totalSelected}</b>
                      </span>
                    </div>

                    {/* Delete CTA */}
                    <button
                      type='button'
                      className='btn btn-sm fw-bold  ms-3 red-hollow-btn'
                      onClick={() => {
                        swal
                          .fire({
                            html: ` 
                                        <div class='fs-3'> <b>Delete Service Lists</></div>
                                        <div class='fs-5 mt-4'>You are about to delete the selected service lists.</div>
                                                  <div class='fs-5 mt-4'> Once deleted, you will not be able to view them nor select them in future maintenance requests. All past and current requests will not be affected.
                                                  </div>
                                                <div class='fs-5 mt-4'> Are you sure you want to continue? </div>`,
                            // icon: 'warning',
                            showConfirmButton: true,
                            confirmButtonColor: '#35475e',
                            confirmButtonText: 'Yes',
                            showCancelButton: true,
                            cancelButtonText: 'No',
                            cancelButtonColor: '#fff',
                            customClass: {
                              confirmButton: 'custom-confirm-button',
                              cancelButton: 'custom-cancel-button',
                              popup: 'custom-popup',
                            },
                            reverseButtons: true, // Reverse the order of buttons reverseButtons: true,
                          })
                          .then((res: any) => {
                            if (res.isConfirmed) {
                              deleteMultipleServiceList()
                            }
                          })
                      }}
                      disabled={isLoading}
                    >
                      <img src={trashImg} height={18} width={18} className='me-2' /> Delete
                    </button>
                  </>
                )}

                {/* Add to List CTA */}
                <button
                  type='button'
                  className='btn btn-sm fw-bold  mx-3 px-2 green-submit-btn'
                  onClick={() => {
                    setNewName('')
                    setEditFile(-1)
                    setShowAddNew(true)
                  }}
                  disabled={isLoading || showAddNew || editFile != -1}
                >
                  <img src={addToListIcon} height={18} width={18} style={{marginRight: '10px'}} />{' '}
                  New List
                </button>
              </div>
            </div>

            <div className='table-responsive'>
              <table
                className='table align-start fs-6 gy-5 table-row-dashed'
                id='kt_ecommerce_sales_table'
              >
                <thead>
                  <th className='text-start min-w-25px test'>
                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 px-0'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        id='vacant0'
                        name='none'
                        checked={
                          totalSelected == lists?.length && lists?.length != 0 ? true : false
                        }
                        onChange={(e: any) => {
                          let values = [...checkedValues]
                          if (e.target.checked) {
                            for (let i = 0; i < lists?.length; i++) {
                              values[i] = true
                            }
                          } else {
                            for (let i = 0; i < lists?.length; i++) {
                              values[i] = false
                            }
                          }
                          setCheckedValues(values)
                        }}
                      />
                    </div>
                  </th>

                  <th className='text-start min-w-150px'>Name</th>
                  <th className='text-start min-w-200px'>Services</th>
                  <th className='text-start min-w-150px'>Created</th>
                  <th className='text-start min-w-150px'>Updated</th>
                  <th className='text-start min-w-150px'></th>
                </thead>
                <tbody className='fw-semibold text-gray-600'>
                  {showAddNew && (
                    <tr>
                      <td className='text-start ps-0 test'>
                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 px-0'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            id='vacant0'
                            name='none'
                            checked={false}
                          />
                        </div>
                      </td>
                      <td className='text-start ps-0'>
                        <div className='d-flex head-text align-items-center'>
                          <p className='user-head pe-2 mb-0 ps-0'>{'-'}</p>
                          <div
                            className='d-flex  vendor me-5 pe-2'
                            style={{width: '-webkit-fill-available'}}
                          >
                            <input
                              type='text'
                              value={newName}
                              className='form-control form-control-solid mytest ms-5'
                              onChange={(e: any) => {
                                let newVal:any = capitalizeFirstLetter(e.target.value)
                                setNewName(newVal)
                              }}
                              disabled={isLoading}
                              placeholder='Enter List Name'
                            />
                          </div>
                        </div>
                        {newError && (
                          <p
                            style={{color: 'var(--kt-danger)', gridColumn: '2/2'}}
                            className='m-0 mt-1 ms-9'
                          >
                            Please enter list name
                          </p>
                        )}
                      </td>

                      <td className='user-head py-3 ps-0 ps-0 pe-5'>
                        <p className='my-0'>{'-'}</p>
                      </td>
                      <td className=' py-3 ps-0  ps-0 pe-5'>
                        <p className='my-0 user-head'>
                          <p className='my-0'>{'-'}</p>
                        </p>
                      </td>
                      <td className='py-3 ps-0  ps-0 pe-5'>
                        <p className='my-0 user-head'>
                          <p className='my-0'>{'-'}</p>
                        </p>
                      </td>
                      <td>
                        <div className='d-flex align-items-center ms-5'>
                          <img
                            src={trashImg}
                            height={18}
                            width={18}
                            className='mx-5 cursor-pointer'
                            onClick={(e) => {
                              setShowAddNew(false)
                            }}
                          />
                          <img
                            src={correct}
                            height={28}
                            width={28}
                            className='mx-5 cursor-pointer'
                            onClick={() => addList()}
                          />
                        </div>
                      </td>
                    </tr>
                  )}

                  {lists?.length > 0 ? (
                    lists?.map((list: any, ind: any) => {
                      return (
                        <>
                          <tr>
                            <td className='text-start ps-0 test'>
                              <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 px-0 pt-1'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  id='vacant0'
                                  name='none'
                                  checked={checkedValues[ind] == true ? true : false}
                                  onChange={(e: any) => {
                                    const values = [...checkedValues]
                                    values[ind] = !values[ind]
                                    setCheckedValues(values)
                                  }}
                                />
                              </div>
                            </td>
                            <td className='text-start ps-0'>
                              <div className='d-flex head-text align-items-center'>
                                <p className='user-head pe-2 mb-0 ps-0'>
                                  {(page - 1) * limit + (ind + 1)}
                                  {'.   '}
                                </p>

                                {editFile == ind ? (
                                  <div
                                    className='d-flex  vendor me-5 pe-2'
                                    style={{width: '-webkit-fill-available'}}
                                  >
                                    <input
                                      type='text'
                                      value={newName}
                                      className='form-control form-control-solid mytest ms-3'
                                      onChange={(e: any) => {
                                        let newVal:any = capitalizeFirstLetter(e.target.value)
                                        setNewName(newVal)
                                      }}
                                      disabled={isLoading}
                                      placeholder='Enter List Name'
                                    />
                                  </div>
                                ) : (
                                  <p className='user-head pe-2 mb-0 ps-3'>
                                    {list.name ? list?.name : '-'}
                                  </p>
                                )}
                              </div>
                            </td>

                            <td
                              className='user-head py-3 ps-0 ps-0 pe-5'
                              style={editFile == ind ? {opacity: '0.5'} : {opacity: '1'}}
                            >
                              <p className='my-0'>
                                {list.mainServiceCount || list?.subServiceCount
                                  ? Number(list?.mainServiceCount) + Number(list?.subServiceCount)
                                  : '-'}
                              </p>
                            </td>
                            <td
                              className=' py-3 ps-0  ps-0 pe-5'
                              style={editFile == ind ? {opacity: '0.5'} : {opacity: '1'}}
                            >
                              <p className='my-0 user-head'>
                                {list?.createdBy?.[0] &&
                                  `${list?.createdBy?.[0]?.firstName} ${list?.createdBy?.[0]?.lastName} - `}
                              </p>
                              <p className='my-0 user-head'>
                                {list?.createdAt
                                  ? `${moment(list?.createdAt).format('DD/MM/YYYY hh:mm A')}`
                                  : '-'}
                              </p>
                            </td>
                            <td
                              className='py-3 ps-0  ps-0 pe-5'
                              style={editFile == ind ? {opacity: '0.5'} : {opacity: '1'}}
                            >
                              <p className='my-0 user-head'>
                                {list?.updatedBy?.[0] &&
                                  `${list?.updatedBy?.[0]?.firstName} ${list?.updatedBy?.[0]?.lastName} - `}
                              </p>
                              <p className='my-0 user-head'>
                                {list?.updatedAt
                                  ? ` ${moment(list?.updatedAt).format('DD/MM/YYYY hh:mm A')}`
                                  : '- -'}
                              </p>
                            </td>
                            <td>
                              <div className='d-flex align-items-center'>
                                <img
                                  src={pencil}
                                  height={16}
                                  width={16}
                                  style={
                                    editFile == ind
                                      ? {opacity: '0.4', cursor: 'not-allowed'}
                                      : {opacity: '1', cursor: 'pointer'}
                                  }
                                  className='me-5 '
                                  onClick={() => {
                                    if(editFile !== ind){
                                    setNewName(list?.name)
                                    setShowAddNew(false)
                                    setEditFile(ind)
                                    }
                                  }}
                                />
                                <img
                                  src={trashImg}
                                  height={18}
                                  width={18}
                                  className='me-5'
                                  style={
                                    editFile == ind
                                      ? {opacity: '0.4', cursor: 'not-allowed'}
                                      : {opacity: '1', cursor: 'pointer'}
                                  }
                                  onClick={(e) => {
                                    if(editFile !== ind){
                                    swal
                                      .fire({
                                        html: ` 
                                        <div class='fs-3'> <b>Delete Service List</></div>
                                        <div class='fs-5 mt-4'>You are about to delete the selected service list.</div>
                                                  <div class='fs-5 mt-4'> Once deleted, you will not be able to view them nor select them in future maintenance requests. All past and current requests will not be affected.
                                                  </div>
                                                <div class='fs-5 mt-4'> Are you sure you want to continue? </div>`,
                                        // icon: 'warning',
                                        showConfirmButton: true,
                                        confirmButtonColor: '#35475e',
                                        confirmButtonText: 'Yes',
                                        showCancelButton: true,
                                        cancelButtonText: 'No',
                                        cancelButtonColor: '#fff',
                                        customClass: {
                                          confirmButton: 'custom-confirm-button',
                                          cancelButton: 'custom-cancel-button',
                                          popup: 'custom-popup',
                                        },
                                        reverseButtons: true, // Reverse the order of buttons reverseButtons: true,
                                      })
                                      .then((res: any) => {
                                        if (res.isConfirmed) {
                                          deleteSingleServiceList(list)
                                        }
                                      })
                                    }
                                  }}
                                />
                                {editFile == ind && (
                                  <img
                                    src={correct}
                                    height={28}
                                    width={28}
                                    className='ms-5 cursor-pointer'
                                    onClick={() => updateName(list)}
                                  />
                                )}
                              </div>
                            </td>
                          </tr>
                        </>
                      )
                    })
                  ) : (
                    <tr>
                      {!isLoading ? (
                        <td colSpan={5} className='text-center'>
                          <img src={noData} alt='' width={350} />
                        </td>
                      ) : (
                        <td colSpan={6} className='text-center'>
                          <div
                            className='d-flex justify-content-center align-items-center w-100'
                            style={{height: '25vh'}}
                          >
                            <CircularProgress style={{color: '#007a59'}} />
                          </div>
                        </td>
                      )}
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            <div className='row mb-5'>
              <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
              <div className='row mt-2'>
                <div className='col-sm-12 col-md-12 d-flex align-items-center justify-content-center justify-content-md-end mt-5'>
                  <div
                    className='dataTables_paginate paging_simple_numbers'
                    id='kt_ecommerce_sales_table_paginate'
                  >
                    <ReactPaginate
                      breakLabel='...'
                      nextLabel='>'
                      pageClassName='paginate_button page-item +'
                      pageLinkClassName='page-link'
                      containerClassName='pagination'
                      activeClassName='active'
                      previousClassName='paginate_button page-item previous'
                      nextClassName='paginate_button page-item next'
                      previousLinkClassName='page-link'
                      nextLinkClassName='page-link'
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={5}
                      pageCount={pageLimit}
                      forcePage={page - 1}
                      previousLabel='<'
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Backdrop sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}} open={open}>
        <CircularProgress style={{color: '#007a59'}} />
        {/* <div style={{color: '#007a59', fontWeight: '700',
      }}>LOADING..</div> */}
      </Backdrop>
    </>
  )
}

export default ManageLists
