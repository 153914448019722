import React, {useEffect} from 'react'
import {useState} from 'react'
import {Row, Col, OverlayTrigger, Tooltip} from 'react-bootstrap'
import {useNavigate, useParams} from 'react-router-dom'
import backArrow from '../../../../img/back-arrow.png'
// import './style.scss'
import {ApiGet, ApiPost, ApiPut} from '../../../../apiCommon/helpers/API/ApiData'
import noData from '../../../../img/NoData1.svg'
import resImg from '../../../../img/residential.png'
import commImg from '../../../../img/commercial.png'
import settingsIcon from '../../../../img/settings-black.png'
import {AiOutlineExclamationCircle} from 'react-icons/ai'
import Switch from 'react-switch'
import swal from 'sweetalert2'
import { IoMdInformationCircleOutline } from "react-icons/io";

const SubTypeMaintenance = () => {
  const navigate = useNavigate()

  const {flg, mainId, id, typeName} = useParams()

  const [isFirst, setIsFirst] = useState<any>(true)

  const [developmentApproval, setDevelopmentApproval] = useState<any>(false)
  const [commonAreaApproval, setCommonAreaApproval] = useState<any>(false)
  const [unitApproval, setUnitApproval] = useState<any>(false)
  const [plannedPreventativeApproval, setPlannedPreventativeApproval] = useState<any>(false)

  const [developmentPO, setDevelopmentPO] = useState<any>(false)
  const [commonAreaPO, setCommonAreaPO] = useState<any>(false)
  const [unitPO, setUnitPO] = useState<any>(false)
  const [plannedPreventativePO, setPlannedPreventativePO] = useState<any>(false)

  const [subWorkflowList, setSubWorkflowList] = useState<any>()
  const [selected, setSelected] = useState<any>()

  //
  const getSubWorkFlowsList = () => {
    const body = {
      subWorkflowId: id,
    }
    ApiPost('corporate/sub_workflow_list/get', body)
      .then((res: any) => {
        let subFlows: any = []

        res?.data?.data?.map((flow: any, index: any) => {
          if (flow?.name == typeName) {
            setSelected(flow?._id)
            let keysArray: string[] = Object.keys(flow?.maintenanceObject || {})

            subFlows = keysArray.map((key) => {
              return {
                key: key,
                value: flow?.maintenanceObject[key],
              }
            })

            // console.log(subFlows)
          }
        })

        setSubWorkflowList(subFlows)

        subFlows?.map((sb: any, ind: any) => {
          if (sb?.key == 'development') {
            setDevelopmentApproval(sb?.value?.isApprovalEnabled)
            setDevelopmentPO(sb?.value?.isPurchaseOrderInclude)
          } else if (sb?.key == 'commonArea') {
            setCommonAreaApproval(sb?.value?.isApprovalEnabled)
            setCommonAreaPO(sb?.value?.isPurchaseOrderInclude)
          } else if (sb?.key == 'unit') {
            setUnitApproval(sb?.value?.isApprovalEnabled)
            setUnitPO(sb?.value?.isPurchaseOrderInclude)
          }
        })

        setTimeout(() => {
          setIsFirst(false)
        }, 1500)
      })
      .catch(() => {})
  }

  //
  const updateSubWorkFlowsList = () => {
    const body = {
      id: selected,
      maintenanceObject:{
        development: {
            isApprovalEnabled: developmentApproval,
            isPurchaseOrderInclude: developmentPO
        },
        commonArea: {
            isApprovalEnabled: commonAreaApproval,
            isPurchaseOrderInclude: commonAreaPO
        },
        unit: {
            isApprovalEnabled: unitApproval,
            isPurchaseOrderInclude: unitPO
        }
      }

    }
    ApiPut('corporate/sub_workflow_list', body)
      .then((res: any) => {
        getSubWorkFlowsList()
      })
      .catch(() => {})
  }

  useEffect(() => {
    if (!isFirst) updateSubWorkFlowsList()
  }, [
    developmentApproval,
    commonAreaApproval,
    unitApproval,
    developmentPO,
    commonAreaPO,
    unitPO,
  ])

  //
  useEffect(() => {
    getSubWorkFlowsList()
  }, [])

  return (
    <>
      <div id='' className='pt-0 mt-0 px-2 ps-1'>
        <div className='d-flex align-items-center gap-2 mb-5 gap-lg-3'>
          <div className='d-flex align-items-center gap-3'>
            <span
              className='rounded-circle'
              style={{cursor: 'pointer'}}
              onClick={() => navigate(`/settings/workflows/maintenance/${mainId}/${flg}/${id}`)}
            >
              <img src={backArrow} style={{stroke: 'red'}} height='14px' width='14px' />
            </span>
            <h2 className='page-heading m-0 head-text'>{`${typeName} Workflows`}</h2>
          </div>
          <div className='ms-auto'>
            <img src={flg == '1' ? resImg : commImg} height='30px' width='30px' />
          </div>
        </div>

        <div className='card card-flush gap-5 p-5 pt-3 pb-0 mt-5 px-3' style={{minHeight: '75vh'}}>
          <div className='card-body pt-0 mt-5 py-0 px-3'>
            <Row className='my-5'>
              {subWorkflowList?.length > 0 ? (
                <>
                  {subWorkflowList?.map((workflow: any, index: any) => {
                    return (
                      <Col md={12} className='mb-5'>
                        <div className='card card-flush gap-5 p-3 cursor-pointer'>
                          <div className='card-body p-3 pb-0'>
                            <div className='table-responsive add-user-height'>
                              <table
                                className='table align-middle fs-6 gy-5 '
                                id='kt_ecommerce_sales_table'
                              >
                                <tbody className='fw-semibold text-gray-600'>
                                  <tr>
                                    <td
                                      className='text-start ps-0 min-w-150px w-lg-250px'
                                      colSpan={1}
                                    >
                                      <div className='d-flex align-items-center'>
                                        <img
                                          src={settingsIcon}
                                          height={28}
                                          width={28}
                                          className='me-5 pe-2 object-fit-contain'
                                        />
                                        <h4 className=' m-0 head-text my-3'>
                                          {workflow?.key == 'development'
                                          ? <b>Development or Cluster</b>
                                          :  workflow?.key == 'commonArea'
                                          ?  <b>Common Area</b>
                                          :
                                          <b>
                                            {workflow?.key?.charAt(0).toUpperCase() +
                                              workflow?.key?.slice(1)}
                                          </b>
                                          }
                                        </h4>
                                      </div>
                                    </td>

                                    <td
                                      className='text-start ps-0 min-w-175px w-lg-250px'
                                      colSpan={3}
                                    >
                                      <div className='text-center'>
                                        <h5 className=' m-0 lease-grey-label mb-3'>
                                          Workflow Category
                                        </h5>
                                        <h5 className=' m-0 head-text mb-3'>

                                        {workflow?.key == 'development'
                                          ? <>{typeName} - {'Development or Cluster'}</>
                                          :  workflow?.key == 'commonArea'
                                          ? <>{typeName} - {'Common Area'}</>
                                          : <>{typeName} - {' '}
                                          {workflow?.key?.charAt(0).toUpperCase() +
                                            workflow?.key?.slice(1)}</>
                                          }
                                        </h5>
                                      </div>
                                    </td>
                                    <td className='text-start ps-0 min-w-150px' colSpan={3}>
                                      <div className='lease ms-10 ps-10'>
                                        <h5 className=' m-0 lease-grey-label mb-3'>
                                          Approvals
                                          <span>
                                            <OverlayTrigger
                                              placement='top'
                                              delay={{show: 250, hide: 400}}
                                              overlay={
                                                <Tooltip
                                                  id='button-tooltip-3'
                                                  className='custom-tooltip'
                                                >
                                                  {workflow?.key == 'development' ? 
                                                  <>Development or Cluster's Approval</>
                                                  :  workflow?.key == 'commonArea'
                                                  ? <>Common Area's Approval</>
                                                  : <>{`${workflow?.key?.charAt(0).toUpperCase() +
                                                    workflow?.key?.slice(1)}'s Maintenance Approvals`}</>
                                                  }
                                                </Tooltip>
                                              }
                                            >
                                              <span>
                                                {' '}
                                                <IoMdInformationCircleOutline className='ms-0 cursor-pointer exclam-circle' />
                                              </span>
                                            </OverlayTrigger>
                                          </span>
                                        </h5>
                                        <div className='d-flex  align-items-center '>
                                          <Switch
                                            onChange={(e) => {
                                            //   setSelected(workflow)

                                              swal
                                                .fire({
                                                  html: ` 
                                                  <div class='fs-3'> <b>Switch ${
                                                    e ? 'ON' : 'OFF'
                                                  } Approvals in ${workflow?.key == 'development' ? 'Development or Cluster' : workflow?.key == 'commonArea' ? 'Common Area' : workflow?.key?.charAt(0).toUpperCase() +
                                                    workflow?.key?.slice(1)}'s Maintenance</b></div>
                                                  <div class='mt-4'>You are about to switch ${
                                                    e ? 'ON' : 'OFF'
                                                  }  the approval process for the '${workflow?.key == 'development' ? 'Development or Cluster' : workflow?.key == 'commonArea' ? 'Common Area' : workflow?.key?.charAt(0).toUpperCase() +
                                                    workflow?.key?.slice(1)}'s Maintenance' flow. </div>
                                                  <div class='mt-4'>The approval process will be applied on new maintenance requests generated.
                                                This update will not be applied to existing incomplete/ draft requests. </div>
                                                <div class='mt-4'> Are you sure you want to continue? </div>`,
                                                  // icon: 'warning',
                                                  showConfirmButton: true,
                                                  confirmButtonColor: '#35475e',
                                                  confirmButtonText: 'Yes',
                                                  showCancelButton: true,
                                                  cancelButtonText: 'No',
                                                  cancelButtonColor: '#fff',
                                                  customClass: {
                                                    confirmButton: 'custom-confirm-button',
                                                    cancelButton: 'custom-cancel-button',
                                                    popup: 'custom-popup',
                                                  },
                                                  reverseButtons: true, // Reverse the order of buttons reverseButtons: true,
                                                })
                                                .then((res: any) => {
                                                  if (res.isConfirmed) {
                                                    if (workflow?.key == 'development')
                                                      setDevelopmentApproval(e)
                                                    else if (
                                                      workflow?.key == 'commonArea'
                                                    )
                                                      setCommonAreaApproval(e)
                                                    else if (
                                                      workflow?.key == 'unit'
                                                    )
                                                      setUnitApproval(e)
                                                    
                                                  } else if (res.isDenied) {
                                                  } else {
                                                  }
                                                })
                                            }}
                                            defaultChecked={
                                              workflow?.key == 'development'
                                                ? developmentApproval
                                                : workflow?.key == 'commonArea'
                                                ? commonAreaApproval
                                                : workflow?.key == 'unit'
                                                ? unitApproval
                                                : ''
                                            }
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            checked={
                                               workflow?.key == 'development'
                                                ? developmentApproval
                                                : workflow?.key == 'commonArea'
                                                ? commonAreaApproval
                                                : workflow?.key == 'unit'
                                                ? unitApproval
                                                : ''
                                            }
                                            onColor='#00cf99'
                                            offColor='#b0bec5'
                                          />
                                          <h5 className=' m-0 head-text ms-3'>
                                            {workflow?.key == 'development' ? (
                                              <> {developmentApproval ? 'On' : 'Off'}</>
                                            ) : workflow?.key == 'commonArea' ? (
                                              <> {commonAreaApproval ? 'On' : 'Off'}</>
                                            ) : workflow?.key == 'unit' ? (
                                              <> {unitApproval ? 'On' : 'Off'}</>
                                            )  : (
                                              ''
                                            )}
                                          </h5>
                                        </div>
                                      </div>
                                    </td>

                                    <td className='text-start ps-0 min-w-200px'>
                                      <div className='text-center lease'>
                                        <h5 className=' m-0 lease-grey-label mb-3'>
                                          {`Include Purchase Order in workflow ?`}

                                          <span>
                                            <OverlayTrigger
                                              placement='top'
                                              delay={{show: 250, hide: 400}}
                                              overlay={
                                                <Tooltip
                                                  id='button-tooltip-3'
                                                  className='custom-tooltip'
                                                >
                                                  <div>{`Include purchase orders in ${workflow?.key == 'development' ? 'Development or Cluster' : workflow?.key == 'commonArea' ? 'Common Area' : workflow?.key?.charAt(0).toUpperCase() +
                                                    workflow?.key?.slice(1)}'s Maintenance workflow.`}</div>

                                                  <div>{`The PO section will become part of the maintenance request form and will be optional. PO’s can be generated at anytime after requests are created and approved.`}</div>
                                                </Tooltip>
                                              }
                                            >
                                              <span>
                                                {' '}
                                                <IoMdInformationCircleOutline className='ms-0 cursor-pointer exclam-circle' />
                                              </span>
                                            </OverlayTrigger>
                                          </span>
                                        </h5>

                                        <div className='d-flex justify-content-center align-items-center'>
                                          <Switch
                                            onChange={(e) => {
                                            //   setSelected(workflow)

                                              swal
                                                .fire({
                                                  html: ` 
                                                  <div class='fs-3'> <b>${
                                                    e ? 'Include' : 'Remove'
                                                  } Purchase Orders ${e ? 'in' : 'from'}  ${
                                                    workflow?.key?.charAt(0).toUpperCase() +
                                            workflow?.key?.slice(1)
                                                  }'s Maintenance</b></div>
                                                  <div class='mt-4'>You are about to ${
                                                    e ? 'include' : 'remove'
                                                  }  purchase orders ${
                                                    e ? 'as part of the' : 'from the'
                                                  } '${workflow?.key?.charAt(0).toUpperCase() +
                                                    workflow?.key?.slice(1)}'s Maintenance' flow. </div>
                                                  <div class='mt-4'>
                                                  ${
                                                    e
                                                      ? 'Requests will only be generated once the purchase order section is complete.'
                                                      : 'Requests can now be generated without a purchase order. You will have the option to add purchase orders after the request is finalized.'
                                                  }

                                                   </div>
                                                  <div class='mt-4'>Once confirmed, this update will only be applied on new requests generated. Ongoing or draft requests will not be affected.</div>
                                                <div class='mt-4'> Are you sure you want to continue? </div>`,
                                                  // icon: 'warning',
                                                  showConfirmButton: true,
                                                  confirmButtonColor: '#35475e',
                                                  confirmButtonText: 'Yes',
                                                  showCancelButton: true,
                                                  cancelButtonText: 'No',
                                                  cancelButtonColor: '#fff',
                                                  customClass: {
                                                    confirmButton:
                                                      'custom-confirm-button h-31 status-w-100 d-flex justify-content-center align-items-center',
                                                    cancelButton:
                                                      'custom-cancel-button h-31 status-w-100 d-flex justify-content-center align-items-center',
                                                    popup: 'custom-popup',
                                                  },
                                                  reverseButtons: true, // Reverse the order of buttons reverseButtons: true,
                                                })
                                                .then((res: any) => {
                                                  if (res.isConfirmed) {
                                                    if (workflow?.key == 'development')
                                                      setDevelopmentPO(e)
                                                    else if (
                                                      workflow?.name == 'commonArea'
                                                    )
                                                      setCommonAreaPO(e)
                                                    else if (
                                                      workflow?.key == 'unit'
                                                    )
                                                      setUnitPO(e)
                                                    
                                                  } else if (res.isDenied) {
                                                  } else {
                                                  }
                                                })
                                            }}
                                            defaultChecked={
                                              workflow?.key == 'development'
                                                ? developmentPO
                                                : workflow?.name == 'commonArea'
                                                ? commonAreaPO
                                                : workflow?.key == 'unit'
                                                ? unitPO
                                                : ''
                                            }
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            checked={
                                             workflow?.key == 'development'
                                                ? developmentPO
                                                : workflow?.name == 'commonArea'
                                                ? commonAreaPO
                                                : workflow?.key == 'unit'
                                                ? unitPO
                                               
                                                : ''
                                            }
                                            onColor='#00cf99'
                                            offColor='#b0bec5'
                                          />
                                          <h5 className=' m-0 head-text ms-3'>
                                            {workflow?.key == 'development' ? (
                                              <> {developmentPO ? 'Yes' : 'No'}</>
                                            ) : workflow?.name == 'commonArea' ? (
                                              <> {commonAreaPO ? 'Yes' : 'No'}</>
                                            ) : workflow?.key == 'unit' ? (
                                              <> {unitPO ? 'Yes' : 'No'}</>
                                            )  : (
                                              ''
                                            )}
                                          </h5>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </Col>
                    )
                  })}
                </>
              ) : (
                <>
                  <Row>
                    <Col md={12} className='text-center'>
                      <img src={noData} alt='' width={350} />
                    </Col>
                  </Row>
                </>
              )}
            </Row>
          </div>
        </div>
      </div>
    </>
  )
}

export default SubTypeMaintenance
