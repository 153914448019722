import React, {useState, useEffect} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import Swal from 'sweetalert2'
import backArrow from '../../../../../../img/back-arrow.png'
import trashImg from '../../../../../../img/trash.png'
import RecordPayment from './RecordPayment'
import OtherAttachments from '../../OtherAttachments'
import ReceiptCard from './ReceiptCard'
import {ApiGet, ApiPost, ApiDelete} from '../../../../../../apiCommon/helpers/API/ApiData'
import {Controller, useForm} from 'react-hook-form'
import {SuccessToast, ErrorToast} from '../../../../../../apiCommon/helpers/Toast'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'

const ViewReceipt = () => {
  const navigate = useNavigate()
  const {flg, id} = useParams()

  const [isSkeleton, setIsSkeleton] = useState<any>(false)
  const [isLoading, setIsLoading] = useState<any>(false)
  const [isViewOnly, setIsViewOnly] = useState<any>(false)

  const [selectedInvoices, setSelectedInvoices] = useState<any>([])

  const [notesCount, setNotesCount] = useState<any>(`0/1000`)

  const {
    register,
    control,
    handleSubmit,
    reset,
    setValue,
    formState: {errors},
    trigger,
  } = useForm<any>({mode: 'onSubmit', reValidateMode: 'onChange'})

  const [receiptData, setReceiptData] = useState<any>({
    name: 'RECEIPT',
    receiptNo: '',
    note: '',
    attachments: [],
    payorData: {},
    datePaid: null,
    referenceNo: '',
    receiptCompany: {},
    receiptLogo: null,
  })

  //
  const updateSelectedInvoices = (val: any) => {
    let values: any = [...selectedInvoices]
    val?.map((vl: any, ind: number) => {
      values?.push(vl)
    })

    setSelectedInvoices(values)
  }

  // const [isLoading, setIsLoading] = useState<any>(false)

  const [autoSaveData, setAutoSaveData] = useState<any>(false)

  const [open, setOpen] = React.useState(false)

  //
  const getReceiptById = async () => {
    let url = `corporate/payment_receipt/${id}`

    await ApiGet(`${url}`)
      .then((res) => {
        setValue('documentName', res?.data?.data?.name)
        setValue('documentReceiptNo', res?.data?.data?.receiptNo)
        setValue(
          'paymentMethod',
          res?.data?.data?.paymentMethod != null ? `${res?.data?.data?.paymentMethod}1` : ''
        )
        setValue('paymentDate', res?.data?.data?.datePaid)

        const values: any = {
          name: res?.data?.data?.name,
          receiptNo: res?.data?.data?.receiptNo,
          note: res?.data?.data?.note,
          attachments: res?.data?.data?.otherAttachment,
          payorData:
            res?.data?.data?.tenant?.length > 0
              ? res?.data?.data?.tenant?.[0]
              : res?.data?.data?.invoiceRecords?.[0]?.payment_invoice?.tenant?.length > 0
              ? res?.data?.data?.invoiceRecords?.[0]?.payment_invoice?.tenant?.[0]
              : {},
          datePaid: res?.data?.data?.datePaid,
          referenceNo: res?.data?.data?.referenceNo,
          receiptCompany: res?.data?.data?.receiptCompany,
          receiptLogo: res?.data?.data?.receiptLogo,
          paymentMethod: [],
          createdAt: res?.data?.data?.createdAt,
        }

        switch (res?.data?.data?.paymentMethod) {
          case 1:
            values.paymentMethod = [{value: 1, label: 'Cheque'}]
            break
          case 2:
            values.paymentMethod = [{value: 2, label: 'Bank Transfer'}]
            break
          case 3:
            values.paymentMethod = [{value: 3, label: 'Cash'}]
            break
          case 0:
            values.paymentMethod = [{value: 0, label: 'Card'}]
            break
          default:
            values.paymentMethod = []
        }

        setReceiptData(values)

        let invValues: any = []
        res?.data?.data?.invoiceRecords?.map((inv: any, index: number) => {
          invValues?.push(inv?.payment_invoice)

          // invValues[index].payment_account = inv?.payment_account
          invValues[index].paymentSubItems = inv?.paymentSubItems
          invValues[index].tenant =
            inv?.tenant?.length > 0
              ? inv?.tenant
              : res?.data?.data?.tenant?.length > 0
              ? res?.data?.data?.tenant
              : []
          invValues[index].paymentAccountId =
            inv?.payment_invoice?.payment_account?.length > 0
              ? inv?.payment_invoice?.payment_account?.[0]?._id
              : null
          invValues[index].paymentId = inv?.paymentId

          setValue(
            `accountNumber${index}`,
            invValues[index].paymentAccountId != null ? `${invValues[index].paymentAccountId}` : ''
          )
        })

        setSelectedInvoices(invValues)

        setNotesCount(`${res?.data?.data?.note?.length}/${1000}`)
      })
      .catch((err: any) => {
        console.log('err', err)
      })
  }

  useEffect(() => {
    getReceiptById()
  }, [])

  // delete receipt
  const deleteReceipt = () => {
    ApiDelete(`corporate/payment_receipt/${id}`)
      .then((res) => {
        SuccessToast('Payment receipt has been deleted successfully..')
        navigate('/finance-income')
      })
      .catch((err) => {
        ErrorToast(err.message)
      })
  }

  return (
    <>
      <div className='app-main flex-column flex-row-fluid px-2' id='kt_app_main'>
        <form>
          <div id='' className='pt-0 mt-0'>
            {/* Header */}
            <div className='page-title d-flex flex-column justify-content-center flex-wrap'>
              {' '}
              <div className='d-flex align-items-center mb-1'>
                <div className='d-flex align-items-center'>
                  <span
                    className='rounded-circle cursor-pointer'
                    onClick={() => {
                      navigate('/finance-income')
                    }}
                  >
                    <img src={backArrow} height='14' width='14' />
                  </span>
                  <h2 className='m-0 head-text ms-2 text-capitalize'>{`Receipt`}</h2>
                </div>

                {selectedInvoices?.length > 0 && (
                  <div className='d-flex ms-auto'>
                    <button
                      type='button'
                      className='btn btn-sm fw-bold  ms-3 red-hollow-btn ps-3'
                      disabled={isLoading || isViewOnly}
                      onClick={() => {
                        Swal.fire({
                          html: ` 
                            <div class='fs-3'> <b>Delete Payment Receipt</></div>
                             <div class='fs-5 mt-4'>You are about to delete the payment receipt.</div>
                            <div class='fs-5 mt-4'> Are you sure you want to continue? </div>`,
                          // icon: 'warning',
                          showConfirmButton: true,
                          confirmButtonColor: '#35475e',
                          confirmButtonText: 'Yes',
                          showCancelButton: true,
                          cancelButtonText: 'No',
                          cancelButtonColor: '#fff',
                          customClass: {
                            confirmButton: 'custom-confirm-button',
                            cancelButton: 'custom-cancel-button',
                            popup: 'custom-popup',
                          },
                          reverseButtons: true, // Reverse the order of buttons reverseButtons: true,
                        }).then((res: any) => {
                          if (res.isConfirmed) {
                            deleteReceipt()
                          }
                        })
                      }}
                    >
                      <img src={trashImg} height={18} width={18} className='me-4' /> Delete
                    </button>
                  </div>
                )}
              </div>
            </div>

            <RecordPayment
              slctdInvoices={selectedInvoices}
              register={register}
              errors={errors}
              rcptData={receiptData}
              setValue={setValue}
              trigger={trigger}
              control={control}
              updatePaymentRecord={(data: any) => {
                setSelectedInvoices(data)
              }}
              autoSaveParent={() => {
                if (flg == '2') {
                  setTimeout(() => {
                    setAutoSaveData(Date.now())
                  }, 700)
                }
              }}
              editMode={false}
            />

            {/* Notes & Attachments */}
            <div className='row mt-4 d-flex'>
              {/* Notes */}
              <div className='col-6 px-4' style={{display: 'flex', flexDirection: 'column'}}>
                <div className={'card card-flush py-5 container-xxl mt-7 pe-5 mb-5 px-6 h-100'}>
                  <div className='mb-5'>
                    <div className='d-flex align-items-start mb-1'>
                      <h2 className='me-1' style={{minWidth: '160px', fontWeight: '700'}}>
                        Notes
                      </h2>
                    </div>

                    <p>
                      <span className='ms-3'>{receiptData?.note ? receiptData?.note : '-'}</span>
                    </p>
                  </div>
                </div>
              </div>
              <div className='col-6 px-4 pe-0' style={{display: 'flex', flexDirection: 'column'}}>
                <OtherAttachments
                  incmData={receiptData}
                  from={'view-receipt'}
                  updateParent={(attachVals: any) => {
                    const values = {...receiptData}
                    values.attachments = attachVals
                    setReceiptData(values)

                    if (flg == '2') {
                      setTimeout(() => {
                        setAutoSaveData(Date.now())
                      }, 700)
                    }
                  }}
                  editMode={false}
                />
              </div>
            </div>

            {/* Receipt Card */}
            <ReceiptCard
              register={register}
              errors={errors}
              rcptData={receiptData}
              setValue={setValue}
              trigger={trigger}
              control={control}
              updateReceipt={(data: any) => {
                console.log(data)
                setReceiptData(data)

                if (flg == '2') {
                  setTimeout(() => {
                    setAutoSaveData(Date.now())
                  }, 700)
                }
              }}
              slctdInvoices={selectedInvoices}
              editMode={false}
            />
          </div>
        </form>
      </div>

      <Backdrop sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}} open={open}>
        <CircularProgress style={{color: '#007a59'}} />
      </Backdrop>
    </>
  )
}

export default ViewReceipt
