import React, {useEffect, useState} from 'react'
import {ApiPost} from '../../../../../../../apiCommon/helpers/API/ApiData'
import noData from '../../../../../../img/NoData1.svg'
import moment from 'moment'
import {MultiSelect} from 'react-multi-select-component'
import {
  removeLeadingZeros,
  formatAmount,
  invertOutstandingAmount,
} from '../../../../../../../Utilities/utils'
import {useParams} from 'react-router-dom'

interface ButtonProps {
  slctdInvoices?: any
  updateDetails?: any
  notePaymentData?: any
  register?: any
  errors?: any
  setValue?: any
  trigger?: any
  control?: any
  updatePaymentRecord: (val: any) => void // Function type, not void
  autoSaveParent?: any
  editMode?: any
  from?: string
}

const RecordPayment = ({
  slctdInvoices,
  updateDetails,
  notePaymentData,
  register,
  errors,
  setValue,
  trigger,
  control,
  updatePaymentRecord,
  autoSaveParent,
  editMode,
  from,
}: ButtonProps) => {
  const [selectedAccount, setSelectedAccount] = useState<any>([])
  const [accountOptions, setAccountOptions] = useState<any>([])

  const {flg} = useParams()
  useEffect(() => {}, [])

  return (
    <div>
      <div
        className='card card-flush py-4 mt-7  px-5 mb-4'
        style={{maxHeight: '500px', overflow: 'auto'}}
      >
        <div className='d-flex align-items-top'>
          <h2 className='pt-2 mb-0'>
            <b>{`Set ${from == 'credit note' ? 'Credit' : 'Debit'} Amount`}</b>
          </h2>

          <div className='mt-0 ms-auto'>
            {flg != '1' && (
              <>
                {notePaymentData?.formStatusDateTime ? (
                  <div className=' me-2'>
                    <div
                      className={
                        notePaymentData?.formStatus == 0
                          ? 'status draft-st ms-5 status-w-120'
                          : 'status create-st ms-5 status-w-120'
                      }
                    >
                      {notePaymentData?.formStatus == 0 ? 'Draft' : 'Created'}
                    </div>
                    <i style={{paddingRight: '3px', paddingLeft: '18px'}}>
                      {' '}
                      {notePaymentData?.formStatusDateTime
                        ? `${moment
                            .utc(notePaymentData?.formStatusDateTime)
                            .local()
                            .format('DD.MM.YYYY - hh:mm A')}`
                        : ''}
                    </i>
                  </div>
                ) : (
                  <div className='status not-saved ms-5 status-w-120'>-</div>
                )}
              </>
            )}
          </div>
        </div>
        <div className='d-flex align-items-center'>
          <h4 className=' pt-2 mb-0 head-text'>
            {`Enter the ${
              from == 'credit note' ? 'credit' : 'debit'
            }  amount for each item under the selected invoices and ensure to select the items you would like to add to your 
          ${from == 'credit note' ? 'credit' : 'debit'} note below:`}
          </h4>
        </div>

        <>
          {slctdInvoices?.map((inv: any, indOut: any) => {
            let outstandingAmount: any = Number(inv?.totalAmount) - Number(inv?.totalPaidAmount)

            return (
              <div className='mb-10'>
                <div className='table-responsive pb-3'>
                  <table className='table align-start fs-6 gy-5' id='kt_ecommerce_sales_table'>
                    <thead>
                      <tr className='text-start text-gray-400 fw-bold fs-7 gs-0'>
                        <th className='min-w-125px ps-0'>Invoice </th>
                        <th className='min-w-125px'>Amount</th>
                        <th className='min-w-125px ps-7 pe-5'>Due Date</th>
                        <th className='min-w-100px text-center'>Category</th>
                        <th className='min-w-100px text-center'>Status</th>
                        <th className='min-w-150px '>Payor</th>
                        <th className='min-w-100px'>Unit </th>
                        <th className='text-start min-w-150px px-3'>Contract No.</th>
                        <th className='min-w-150px'>Lease Term</th>
                      </tr>
                    </thead>

                    <tbody className='fw-semibold head-text'>
                      <>
                        <tr>
                          <td className='ps-0'>
                            <div className='d-flex text-decoration-underline'>
                              <b>{inv?.invoiceNo ? inv?.invoiceNo : '-'}</b>
                            </div>
                          </td>
                          <td>
                            {inv?.totalAmount > 0 ? (
                              <>AED {formatAmount(inv?.totalAmount)}</>
                            ) : (
                              <>AED 0</>
                            )}
                          </td>
                          <td className='ps-7 pe-5'>
                            {inv?.dueDate ? moment(inv?.dueDate).format('DD.MM.YYYY') : '-'}
                          </td>
                          <td>
                            <div
                              className={`status status-w-100
                                ${
                                  inv?.categories == 0
                                    ? 'active-bar '
                                    : inv?.categories == 1
                                    ? 'expired-bar'
                                    : inv?.categories == 3
                                    ? 'complete-bar'
                                    : inv?.categories == 2
                                    ? 'req-bar'
                                    : 'no-st-bar'
                                }
                              `}
                            >
                              {inv?.categories == 0
                                ? 'Upcoming'
                                : inv?.categories == 1
                                ? 'Overdue'
                                : inv?.categories == 3
                                ? 'Complete'
                                : inv?.categories == 2
                                ? 'Deferred'
                                : '-'}
                            </div>
                          </td>

                          <td>
                            <div
                              className={`status-w-100 status
                                ${
                                  inv?.status === 2
                                    ? 'pending-approval-st '
                                    : inv?.status === 1
                                    ? 'approve-st'
                                    : inv?.status === 0
                                    ? 'cancelled-st'
                                    : inv?.status === 3 || inv?.status === 4
                                    ? 'pend-st'
                                    : 'not-saved'
                                }`}
                            >
                              {inv?.status === 0
                                ? 'Unpaid'
                                : inv?.status === 1
                                ? 'Paid'
                                : inv?.status === 2
                                ? 'Partially paid'
                                : inv?.status === 3
                                ? 'Void'
                                : inv?.status === 4
                                ? 'Draft'
                                : '-'}
                            </div>
                          </td>

                          <td className=''>
                            {inv?.tenant?.[0]?.firstName
                              ? `${inv?.tenant?.[0]?.firstName} ${inv?.tenant?.[0]?.lastName}`
                              : '-'}
                          </td>

                          <td>{inv?.unit?.length > 0 ? inv?.unit?.[0]?.unitNo : '-'}</td>

                          <td className=''>
                            {inv?.tenancy?.[0]?.contractNo > 0
                              ? inv?.tenancy?.[0]?.contractNo
                              : '-'}
                          </td>
                          <td className='text-start '>
                            {inv?.tenancy?.[0]?.duration?.start_date
                              ? `${moment
                                  .utc(inv?.tenancy?.[0]?.duration?.start_date)
                                  .local()
                                  .format('DD.MM.YYYY')} `
                              : '-'}
                            {'-'}
                            {inv?.tenancy?.[0]?.duration?.end_date
                              ? `${moment
                                  .utc(inv?.tenancy?.[0]?.duration?.end_date)
                                  .local()
                                  .format('DD.MM.YYYY')} `
                              : '-'}
                          </td>
                        </tr>
                      </>
                    </tbody>
                  </table>
                </div>

                <div className='separator my-3 mt-7'></div>

                <div
                  className='table-responsive pb-3'
                  // style={{maxHeight: '350px', overflow: 'auto'}}
                >
                  <table className='table align-start fs-6 gy-5' id='kt_ecommerce_sales_table'>
                    <thead>
                      <tr className='text-start text-gray-400 fw-bold fs-7 gs-0'>
                        <th className='min-w-25px pe-3 ps-0'>ID</th>
                        <th className='min-w-150px px-3'>Item</th>
                        <th className='min-w-100px px-3'>Rate</th>
                        <th className='min-w-100px  px-3'>QTY</th>
                        <th className='min-w-100px  px-3'>VAT</th>
                        <th className='min-w-150px  px-3'>Total Amount</th>
                        <th className='min-w-150px px-3'>Outstanding</th>
                        <th className='min-w-150px  px-3'>Debit</th>
                        <th className='min-w-100px px-3'>
                          {from == 'credit note' ? 'Credit ' : 'Debit '} Rate Amount
                        </th>
                        <th className='min-w-100px  px-3'>QTY</th>
                        <th className='min-w-100px  px-3'>VAT</th>
                        <th className='min-w-25px  px-3'>Add</th>
                      </tr>
                    </thead>
                    <tbody className='fw-semibold head-text'>
                      {inv?.paymentSubItems?.map((invItem: any, indIn: any) => {
                        let totalAmountOfItem: any = 0
                        let vatAmnt: any =
                          ((Number(invItem?.amount) * Number(invItem?.quantity)) / 100) *
                          Number(invItem?.VATPercentage)
                        totalAmountOfItem =
                          Number(invItem?.amount) * Number(invItem?.quantity) + vatAmnt

                        return (
                          <>
                            <td className='pe-3 ps-0'>
                              {invItem?.frontendStorage?.paymentItemId
                                ? invItem?.frontendStorage?.paymentItemId
                                : '-'}
                            </td>
                            <td className='px-3'>{invItem?.name ? invItem?.name : '-'}</td>{' '}
                            <td className='px-3'>
                              {' '}
                              {invItem?.amount ? formatAmount(invItem?.amount) : '-'}
                            </td>{' '}
                            <td className='px-3'>{invItem?.quantity ? invItem?.quantity : '-'}</td>{' '}
                            <td className='px-3'>
                              {' '}
                              {invItem.VATPercentage ? `${invItem.VATPercentage}%` : '-'}
                            </td>{' '}
                            <td className='px-3'>
                              {'AED '}
                              <b className='ps-1'>
                                {totalAmountOfItem ? formatAmount(totalAmountOfItem) : ' -'}
                              </b>
                            </td>{' '}
                            <td className='px-3'>
                              {'AED '}
                              <b className='ps-1'>
                                {invItem?.outstandingAmount
                                  ? formatAmount(
                                      invertOutstandingAmount(invItem?.outstandingAmount)
                                    )
                                  : ' -'}
                              </b>
                            </td>{' '}
                            <td className='px-3'>
                              {editMode ? (
                                <>
                                  <input
                                    //   {...register('documentName', {required: true})}
                                    type='number'
                                    className={`form-control form-control-solid mytest tenancy-control   status-w-200 ${
                                      invItem?.amountPaid > invItem?.outstandingAmount
                                        ? 'border-danger'
                                        : ''
                                    }`}
                                    name='documentName'
                                    value={invItem?.amountPaid}
                                    style={{height: '35px'}}
                                    placeholder={'AED'}
                                    onChange={(e: any) => {
                                      let values = [...slctdInvoices]
                                      let valuesItems = values[indOut].paymentSubItems
                                      let newValue: any = removeLeadingZeros(e.target.value)
                                      // console.log(newValue)
                                      valuesItems[indIn].amountPaid = Number(newValue)
                                      values[indOut].paymentSubItems = valuesItems

                                      updatePaymentRecord(values)
                                    }}
                                    autoComplete='off'
                                    onBlur={autoSaveParent}
                                  />

                                  {invItem?.amountPaid > invItem?.outstandingAmount && (
                                    <p
                                      className='text-danger mb-0 ms-0 ps-0'
                                      style={{maxWidth: '200px', wordBreak: 'break-word'}}
                                    >
                                      {' '}
                                      Amount paid is greater than the outstanding amount.
                                    </p>
                                  )}
                                </>
                              ) : (
                                <>
                                  {'AED '}
                                  <b className='ps-1'>
                                    {invItem?.amountPaid
                                      ? `${formatAmount(invItem?.amountPaid)}`
                                      : ' -'}
                                  </b>
                                </>
                              )}
                            </td>
                            <td className='px-3'>
                              {editMode ? (
                                <>
                                  <input
                                    //   {...register('documentName', {required: true})}
                                    type='number'
                                    className={`form-control form-control-solid mytest tenancy-control   status-w-200 ${
                                      invItem?.amountPaid > invItem?.outstandingAmount
                                        ? 'border-danger'
                                        : ''
                                    }`}
                                    name='documentName'
                                    value={invItem?.amountPaid}
                                    style={{height: '35px'}}
                                    placeholder={'AED'}
                                    onChange={(e: any) => {
                                      let values = [...slctdInvoices]
                                      let valuesItems = values[indOut].paymentSubItems
                                      let newValue: any = removeLeadingZeros(e.target.value)
                                      // console.log(newValue)
                                      valuesItems[indIn].amountPaid = Number(newValue)
                                      values[indOut].paymentSubItems = valuesItems

                                      updatePaymentRecord(values)
                                    }}
                                    autoComplete='off'
                                    onBlur={autoSaveParent}
                                  />

                                  {invItem?.amountPaid > invItem?.outstandingAmount && (
                                    <p
                                      className='text-danger mb-0 ms-0 ps-0'
                                      style={{maxWidth: '200px', wordBreak: 'break-word'}}
                                    >
                                      {' '}
                                      Amount paid is greater than the outstanding amount.
                                    </p>
                                  )}
                                </>
                              ) : (
                                <>
                                  {'AED '}
                                  <b className='ps-1'>
                                    {invItem?.amountPaid
                                      ? `${formatAmount(invItem?.amountPaid)}`
                                      : ' -'}
                                  </b>
                                </>
                              )}
                            </td>
                          </>
                        )
                      })}
                    </tbody>
                  </table>
                </div>

                <div className='separator my-3 mt-7'></div>
              </div>
            )
          })}
        </>
      </div>
    </div>
  )
}

export default RecordPayment
