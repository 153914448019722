import React, {useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import moment from 'moment'
import redCross from '../../../../../../img/remove.png'
import {formatAmount, invertOutstandingAmount} from '../../../../../../Utilities/utils'

interface SelectedInvoicesCardProps {
  slctdInvoices: any
  updateCheckedInvs: (val: any) => void // Function type, not void
  from?: string
}

const SelectedInvoicesCard: React.FC<SelectedInvoicesCardProps> = ({
  slctdInvoices,
  updateCheckedInvs,
  from
}) => {
  const [selectedInvoices, setSelectedInvoices] = useState<any>(slctdInvoices ? slctdInvoices : [])

  useEffect(() => {
    setSelectedInvoices(slctdInvoices)
  }, [slctdInvoices])

  return (
    <>
      {/* Selected Invoices Card */}
      <div className={'card card-flush py-5 mt-7  px-5 mb-5  min-h-350'}>
        <div className='d-flex  align-items-center'>
          <h2 className='' style={{fontWeight: '700'}}>
            {'Selected Invoice'}
          </h2>
        </div>
        <div className='d-flex  align-items-center'>
          <p className='user-head mb-2 fs-5' style={{fontWeight: '500'}}>
            Total Selected: <b>{slctdInvoices?.length}</b>
          </p>
        </div>

        {/* Table Listing */}
        <div
          className='table-responsive pb-8'
          id='global-table'
          style={{maxHeight: '350px', overflow: 'auto'}}
        >
          <table className='table align-start fs-6 gy-5' id='kt_ecommerce_sales_table'>
            <thead>
              <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                <th className='min-w-125px'>Invoice No.</th>
                <th className='min-w-125px'>Amount</th>
                <th className='min-w-100px text-center'>Category</th>
                <th className='min-w-125px ps-7 pe-5'>Due Date</th>
                <th className='min-w-100px text-center'>Status</th>
                <th className='min-w-150px'>Outstanding</th>
                {from == 'credit note' && <th className='min-w-150px'>Credit</th> }
                {/* <th className='min-w-150px '>Payor</th> */}
                <th className='min-w-150px'>Development</th>
                <th className='min-w-100px'>Unit No.</th>
                <th className='min-w-125px text-center'>Lease Status</th>
                <th className='min-w-150px'>Lease Term</th>
                <th className='text-start min-w-150px'>Contract No.</th>
                <th className='text-start min-w-50px'></th>
              </tr>
            </thead>
            <tbody className='fw-semibold head-text'>
              {selectedInvoices?.length > 0 ? (
                <>
                  {selectedInvoices?.map((rcp: any, ind: number) => {
                    let outstandingAmount: any =
                      Number(rcp?.totalAmount) - Number(rcp?.totalPaidAmount)
                    return (
                      <>
                        <tr>
                          <td className=''>
                            <div className='d-flex text-decoration-underline'>
                              <b>{rcp?.invoiceNo ? rcp?.invoiceNo : '-'}</b>
                            </div>
                          </td>
                          <td>
                            {rcp?.totalAmount > 0 ? (
                              <>AED {formatAmount(rcp?.totalAmount)}</>
                            ) : (
                              <>AED 0</>
                            )}
                          </td>
                          <td>
                            <div
                              className={`status status-w-100
                            ${
                              rcp?.categories == 0
                                ? 'active-bar '
                                : rcp?.categories == 1
                                ? 'expired-bar'
                                : rcp?.categories == 3
                                ? 'complete-bar'
                                : rcp?.categories == 2
                                ? 'req-bar'
                                : 'no-st-bar'
                            }
                          `}
                            >
                              {rcp?.categories == 0
                                ? 'Upcoming'
                                : rcp?.categories == 1
                                ? 'Overdue'
                                : rcp?.categories == 3
                                ? 'Complete'
                                : rcp?.categories == 2
                                ? 'Deferred'
                                : '-'}
                            </div>
                          </td>
                          <td className='ps-7 pe-5'>
                            {rcp?.dueDate ? moment(rcp?.dueDate).format('DD.MM.YYYY') : '-'}
                          </td>
                          <td>
                            <div
                              className={`status-w-100 status
                                ${
                                  rcp?.status === 2
                                    ? 'pending-approval-st '
                                    : rcp?.status === 1
                                    ? 'approve-st'
                                    : rcp?.status === 0
                                    ? 'cancelled-st'
                                    : rcp?.status === 3 || rcp?.status === 4
                                    ? 'pend-st'
                                    : 'not-saved'
                                }`}
                            >
                              {rcp?.status === 0
                                ? 'Unpaid'
                                : rcp?.status === 1
                                ? 'Paid'
                                : rcp?.status === 2
                                ? 'Partially Paid'
                                : rcp?.status === 3
                                ? 'Void'
                                : rcp?.status === 4
                                ? 'Draft'
                                : '-'}
                            </div>
                          </td>
                          <td>
                            {outstandingAmount != undefined ? (
                              <>AED {formatAmount(invertOutstandingAmount(outstandingAmount))}</>
                            ) : (
                              <>AED 0</>
                            )}
                          </td>
                          <td>
                            {outstandingAmount != undefined ? (
                              <>AED {formatAmount(invertOutstandingAmount(outstandingAmount))}</>
                            ) : (
                              <>AED 0</>
                            )}
                          </td>
                          {/* <td className=''>
                            {rcp?.tenant?.[0]?.firstName
                              ? `${rcp?.tenant?.[0]?.firstName} ${rcp?.tenant?.[0]?.lastName}`
                              : '-'}
                          </td> */}

                          <td>
                            {rcp?.community?.length > 0
                              ? rcp?.community?.[0]?.name
                              : rcp?.building?.[0]?.name}
                          </td>
                          <td>{rcp?.unit?.length > 0 ? rcp?.unit?.[0]?.unitNo : '-'}</td>
                          <td>
                            <div className='d-flex justify-content-center'>
                              <div
                                className={
                                  rcp?.tenancy?.[0]?.tenancyStatus == 0
                                    ? 'status booked-bar'
                                    : rcp?.tenancy?.[0]?.tenancyStatus == 1
                                    ? 'status active-bar  '
                                    : rcp?.tenancy?.[0]?.tenancyStatus == 2
                                    ? 'status expiring-bar'
                                    : rcp?.tenancy?.[0]?.tenancyStatus == 3
                                    ? 'status expired-bar'
                                    : 'status  no-st-bar'
                                }
                              >
                                {rcp?.tenancy?.[0]?.tenancyStatus == 0
                                  ? 'Booked'
                                  : rcp?.tenancy?.[0]?.tenancyStatus == 1
                                  ? 'Active'
                                  : rcp?.tenancy?.[0]?.tenancyStatus == 2
                                  ? 'Expiring'
                                  : rcp?.tenancy?.[0]?.tenancyStatus == 3
                                  ? 'Expired'
                                  : '-'}
                              </div>
                            </div>
                          </td>

                          <td className='text-start '>
                            {rcp?.tenancy?.[0]?.duration?.start_date
                              ? `${moment
                                  .utc(rcp?.tenancy?.[0]?.duration?.start_date)
                                  .local()
                                  .format('DD.MM.YYYY')} `
                              : '-'}
                            {'-'}
                            {rcp?.tenancy?.[0]?.duration?.end_date
                              ? `${moment
                                  .utc(rcp?.tenancy?.[0]?.duration?.end_date)
                                  .local()
                                  .format('DD.MM.YYYY')} `
                              : '-'}
                          </td>

                          <td className=''>
                            {rcp?.tenancy?.[0]?.contractNo > 0
                              ? rcp?.tenancy?.[0]?.contractNo
                              : '-'}
                          </td>
                          <td className='text-start px-0 test'>
                            <img
                              src={redCross}
                              height={16}
                              width={16}
                              className='cursor-pointer'
                              onClick={() => {
                                let values = [...selectedInvoices]
                                values.splice(ind, 1)
                                updateCheckedInvs(values)
                              }}
                            />
                          </td>
                        </tr>
                      </>
                    )
                  })}
                </>
              ) : (
                <tr>
                  <td colSpan={13} className='text-center'></td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}

export default SelectedInvoicesCard
