import React, {useState, useEffect} from 'react'
import noData from '../../../../img/NoData1.svg'
import allProperties from '../../../img/allProperties.png'
import allDevelopment from '../../../img/allDevelopment.png'
import allBuilding from '../../../img/small-building.png'
import allCommunities from '../../../img/allCommunities.png'
import bulkImportWhite from '../../../img/bulkImportWhite.png'
import unitsBlue from '../../../img/allPropertiesBlue.png'
import tenantBlue from '../../../img/tenantBlue.png'
import leasesBlue from '../../../img/leasesBlue.png'
import yellowStar from '../../../../img/yellow-star.png'
import occupancyBlue from '../../../img/occupancyBlue.png'
import {MultiSelect} from 'react-multi-select-component'

import moment from 'moment'
import {useDispatch, useSelector} from 'react-redux'
import {Col, Row} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {useNavigate, useLocation} from 'react-router-dom'
import {ApiDelete, ApiGet, ApiPost} from '../../../../apiCommon/helpers/API/ApiData'
import ReactPaginate from 'react-paginate'
import {SuccessToast, ErrorToast} from '../../../../apiCommon/helpers/Toast'
import allTen from '../../../img/all-tenc.png'
import bookTen from '../../../img/book-tenc.png'
import activeTen from '../../../img/active-tenc.png'
import expngTen from '../../../img/expng-tenc.png'
import terminTen from '../../../img/expired-tenc.png'
import trash from '../../../img/trash-white.png'
import create from '../../../img/create.png'
import closes from '../../../img/close.svg'
import windowImg from '../../../img/window.png'
import sync from '../../../img/sync.png'
import Paper from '@mui/material/Paper'
import InputBase from '@mui/material/InputBase'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import {DatePicker, Space} from 'antd'
import Select from 'react-select'
import downGreen from '../../../img/down-arrow-green.png'
import upGreen from '../../../img/up-arrow-green.png'
import trashImg from '../../../img/trash.png'
import swal from 'sweetalert2'
import cancelledTen from '../../../img/cancelled-tab.png'
import draftTen from '../../../img/draft.png'
import expiredTen from '../../../img/expired-tab.png'
import {setComefrom} from '../../../../redux/counterSlice'

import townhouse from '../../../../img/TownHouse.svg'
import appartment from '../../../../img/Apartment.svg'
import villa from '../../../../img/Villa.svg'
import other from '../../../../img/other.svg'
import buildings from '../../../../img/buildings.svg'
import common_area from '../../../../img/common_area.svg'
import penthouse from '../../../../img/PentHouse.svg'
import editPen from '../../../../img/edit-pen.png'
import {Bucket} from '../../../../apiCommon/helpers/API/ApiData'
import AttachmentPreview from '../../General/AttachmentsPreview'
import userIcon from '../../../../img/user-blue.png'
import OwnerListModel from './OwnerListModel'

interface ButtonProps {
  goToMiantenance: any
  handlePageClick: any
  pageLimit: any
  page: any
  listData: any
  isCommunity?: any
  isEditBtn?: boolean
  isFloorCol?: boolean
  goToEdit?: any
}

const PropertiesList = ({goToMiantenance, handlePageClick, pageLimit, page, listData, isCommunity, isEditBtn, isFloorCol, goToEdit}: ButtonProps) => {
  const calculateExpencePercentage = (totalAmount: any, totalExpence: any) => {
    let percentage = (totalExpence / totalAmount) * 100
    return Math.round(percentage)
  }
  const navigate = useNavigate()

  const [attachmentsForPreview, setAttachmentsForPreview] = useState<any>([])
  const [ownerListeModal, setOwnerListModal] = useState<any>(false)

  useEffect(() => {
    let values: any = []
    let valuesInd: any = 0
    {
      listData?.map((inv: any, index: any) => {
        inv?.images?.map((imge: any, ind: any) => {
          values[valuesInd] = {
            type: '',
            url: `${Bucket}${imge}`,
            thumbnail: '',
          }

          if (imge?.split('.').pop() === 'pdf') values[valuesInd].type = 'pdf'
          else if (imge?.split('.').pop() === 'doc' || imge?.split('.').pop() === 'docx') values[valuesInd].type = 'doc'
          else if (
            imge?.split('.').pop() === 'jpeg' ||
            imge?.split('.').pop() === 'jpg' ||
            imge?.split('.').pop() === 'png' ||
            imge?.split('.').pop() === 'svg' ||
            imge?.split('.').pop() === 'gif'
          )
            values[valuesInd].type = 'image'
          else if (
            inv?.URL?.split('.').pop()?.toLowerCase() === 'mp4' ||
            inv?.URL?.split('.').pop()?.toLowerCase() === 'webm' ||
            inv?.URL?.split('.').pop()?.toLowerCase() === 'avi' ||
            inv?.URL?.split('.').pop()?.toLowerCase() === 'mkv' ||
            inv?.URL?.split('.').pop()?.toLowerCase() === 'mov' ||
            inv?.URL?.split('.').pop()?.toLowerCase() === 'flv' ||
            inv?.URL?.split('.').pop()?.toLowerCase() === 'wmv'
          )
            values[valuesInd].type = 'video'
          else values[valuesInd].type = 'file'

          valuesInd = valuesInd + 1
        })
      })
    }

    // setAttachmentsForPreview(values)
  }, [])

  const [show, setShow] = useState<any>([])
  const [ownerListData, setOwnerListData] = useState<any>([])
  useEffect(() => {
    let temp = []
    for (let i = 0; i < listData.length; i++) {
      temp[i] = false
    }
    setShow(temp)
  }, [listData])

  const handleImageClick = (val: any) => {
    // Add data-fancybox attribute to the clicked image
    const image = document.getElementById(val)
    if (image) {
      image.setAttribute('data-fancybox', '')
    }
  }

  const handleOwnerModel = (listItem:any) => {
    const ownerTemp = listItem?.owners?.flatMap((owner:any) => {
      return listItem?.owner.map((item:any) => {
        if(owner.ownerId == item?._id){
          return {
            ...item,
            ownershipPercentage:owner?.ownershipPercentage
          }
        }
      })
    }).filter(Boolean)

    if(ownerTemp?.length > 0){
      setOwnerListData(ownerTemp)
      setOwnerListModal(true)
    }
 
  }

  return (
    <>
    <div className='card card-flush py-5  px-7'>
      <div className='app-main flex-column flex-row-fluid' id='------------kt_app_main'>
        <div className='table-responsive'>
          <table className='table align-middle table-row-dashed fs-8 gy-5 ' id='kt_ecommerce_sales_table'>
            <thead className='fs-7'>
              <th className='text-start min-w-50px ps-0'></th>
              <th className='text-start min-w-100px ps-0'>Unit No.</th>
              {isFloorCol && <th className='text-start min-w-100px ps-0'>Floor</th>}
              {isCommunity && <th className='text-start min-w-125px ps-0'>Cluster</th>}
              <th className='text-start min-w-100px ps-0'>Category</th>
              {!isCommunity && <th className='text-start min-w-125px ps-0'>Development</th>}
              <th className='text-start min-w-125px ps-0'>Type</th>
              <th className='text-start min-w-100px ps-0'>Bedrooms</th>
              <th className='text-start min-w-100px ps-0'>Bathrooms</th>
              <th className='text-start min-w-100px ps-0 '>Size</th>
              <th className='text-center min-w-100px ps-0 '>Lease Status</th>
              <th className='text-start min-w-125px ps-5'>Occupancy Status</th>
              <th className='text-center min-w-125px ps-0 '>Occupancy Rate</th>
              <th className='text-start min-w-100px ps-5 '>Total Income</th>
              <th className='text-start min-w-100px ps-0'>Total Expenses</th>
              <th className='text-start min-w-100px ps-0'></th>
              <th className='text-start min-w-125px ps-0 '>Upcoming Income</th>
              <th className='text-start min-w-175px ps-0 '>Owner</th>
              <th className='text-start min-w-100px ps-4 '>Rating</th>
              <th className='text-start min-w-100px ps-0 '>Assigned</th>
              {isEditBtn && <th className='text-start min-w-25px ps-0 '></th>}
            </thead>
            <tbody className='fw-semibold head-text'>
              {listData?.length > 0 ? (
                listData?.map((listItem: any, i: any) => {
                  return (
                    <tr>
                      <td className={`text-start cursor-pointer ps-0 m-0 p-0 min-w-50px`}>
                        <img
                          src={
                            listItem?.images?.length > 0
                              ? `${Bucket}${listItem?.images?.[0]}`
                              : listItem?.unitType === 3
                              ? townhouse
                              : listItem?.unitType === 5
                              ? other
                              : listItem?.unitType === 2
                              ? common_area
                              : listItem?.unitType === 4
                              ? villa
                              : listItem?.unitType === 0
                              ? appartment
                              : listItem?.unitType === 1
                              ? penthouse
                              : ''
                          }
                          height={30}
                          width={30}
                          id={`clickedImage${i}`}
                          className='m-0 p-0 light-box-single-image'
                          style={listItem?.images?.length > 0 ? {borderRadius: '5px'} : {borderRadius: ''}}
                          // data-fancybox
                          // data-src={`${Bucket}${listItem?.images?.[0]}`}
                          onClick={() => handleImageClick(`clickedImage${i}`)}
                        />
                      </td>
                      <td className='text-start cursor-pointer ps-0' onClick={() => goToMiantenance(listItem)}>
                        {listItem?.unitNo ? listItem?.unitNo : '-'}
                      </td>
                      {isFloorCol && (
                        <td className='text-start cursor-pointer ps-0' onClick={() => goToMiantenance(listItem)}>
                          {listItem?.floor ? listItem?.floor?.name : '-'}
                        </td>
                      )}
                      {isCommunity && (
                        <td className='text-start cursor-pointer ps-0' onClick={() => goToMiantenance(listItem)}>
                          {listItem?.cluster?.[0]?.name ? listItem?.cluster?.[0]?.name : '-'}
                        </td>
                      )}
                      <td className='text-start cursor-pointer ps-0' onClick={() => goToMiantenance(listItem)}>
                        {listItem?.propertyType === 0 ? 'Residential' : listItem?.propertyType === 1 ? 'Commercial' : '-'}
                      </td>

                      {!isCommunity && (
                        <td className='text-start cursor-pointer ps-0' onClick={() => goToMiantenance(listItem)}>
                          {listItem?.community?.[0]?.name
                            ? listItem?.community?.[0]?.name
                            : listItem?.building?.[0]?.name
                            ? listItem?.building?.[0]?.name
                            : '-'}
                        </td>
                      )}
                      <td className='text-start cursor-pointer ps-0' onClick={() => goToMiantenance(listItem)}>
                        {' '}
                        <td className='text-start'>
                          {listItem?.unitType === 3
                            ? 'TownHouse'
                            : listItem?.unitType === 5
                            ? `Other - ${listItem?.otherName}`
                            : listItem?.unitType === 2
                            ? 'Common Area'
                            : listItem?.unitType === 4
                            ? 'Villa'
                            : listItem?.unitType === 0
                            ? 'Apartment'
                            : listItem?.unitType === 1
                            ? 'Penthouse'
                            : '-'}
                        </td>
                      </td>

                      <td className='text-start cursor-pointer ps-0 min-w-125' onClick={() => goToMiantenance(listItem)}>
                        {' '}
                        {listItem?.bedrooms >= 0 ? `${listItem?.bedrooms} BR` : '-'}
                      </td>
                      <td className='text-start cursor-pointer ps-0 ' onClick={() => goToMiantenance(listItem)}>
                        {' '}
                        {listItem?.bathrooms >= 0 ? `${listItem?.bathrooms} Bath` : '-'}
                      </td>
                      <td className='text-start cursor-pointer ps-0 min-w-100 px' onClick={() => goToMiantenance(listItem)}>
                        {' '}
                        {listItem?.size ? `${listItem?.size} ${listItem?.sizeType === 0 ? 'Sq ft' : listItem?.sizeType === 1 ? 'Sq mt' : ''}` : '-'}
                      </td>
                      <td className='text-center cursor-pointer ps-0' onClick={() => goToMiantenance(listItem)}>
                        <div
                          className={
                            listItem?.tenancyStatus == 0
                              ? 'status booked-bar'
                              : listItem?.tenancyStatus == 1
                              ? 'status active-bar'
                              : listItem?.tenancyStatus == 2
                              ? 'status expiring-bar'
                              : listItem?.tenancyStatus == 3
                              ? 'status expired-bar'
                              : 'status  no-st-bar'
                          }
                        >
                          {listItem?.tenancyStatus == 0
                            ? 'Booked'
                            : listItem?.tenancyStatus == 1
                            ? 'Active'
                            : listItem?.tenancyStatus == 2
                            ? 'Expiring'
                            : listItem?.tenancyStatus == 3
                            ? 'Expired'
                            : '-'}
                        </div>
                      </td>
                      <td className='text-start cursor-pointer ps-0 min-w-125px ps-5' onClick={() => goToMiantenance(listItem)}>
                        {' '}
                        {listItem?.occupy === 0 ? 'Vacant ' : listItem?.occupy === 1 ? 'Occupied' : '-'}
                      </td>
                      <td className={`text-center p-0 cursor-pointer d-flex justify-content-center`} onClick={() => goToMiantenance(listItem)}>
                        {' '}
                        <div
                          className={` status ${
                            Math.round(listItem?.occupancyRate) <= 50 && Math.round(listItem?.occupancyRate) > 0
                              ? 'cancelled-st'
                              : Math.round(listItem?.occupancyRate) <= 75 && Math.round(listItem?.occupancyRate) > 50
                              ? 'requested-st'
                              : Math.round(listItem?.occupancyRate) <= 99 && Math.round(listItem?.occupancyRate) > 75
                              ? 'draft-st'
                              : Math.round(listItem?.occupancyRate) >= 100
                              ? 'create-st'
                              : 'not-saved'
                          }`}
                        >
                          {listItem?.occupancyRate ? `${Math.round(listItem?.occupancyRate)}%` : '-'}
                        </div>
                      </td>
                      <td className='text-start cursor-pointer ps-5 min-w-125px' onClick={() => goToMiantenance(listItem)}>
                        {' '}
                        {}
                        {/* {`AED ${listItem?.incomePaymentCount ?? '-'}`} */}
                        {`AED ${parseFloat(listItem?.incomePaymentCount)?.toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                        })}`}
                      </td>
                      <td className='text-start cursor-pointer ps-0 min-w-125px' onClick={() => goToMiantenance(listItem)}>
                        {' '}
                        {/* {`AED ${listItem?.expensePaymentCount ?? '-'}`} */}
                        {`AED ${parseFloat(listItem?.expensePaymentCount)?.toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                        })}`}
                      </td>
                      <td className='text-start cursor-pointer ps-0' onClick={() => goToMiantenance(listItem)}>
                        {' '}
                        {`${
                          listItem?.incomePaymentCount > 0 && listItem?.expensePaymentCount > 0
                            ? `${calculateExpencePercentage(listItem?.incomePaymentCount, listItem?.expensePaymentCount)} %`
                            : '-'
                        }`}
                      </td>

                      <td className='text-start cursor-pointer ps-0 min-w-125px' onClick={() => goToMiantenance(listItem)}>
                        {' '}
                        {/* {`AED ${listItem?.incomePaymentOutstandingCount ?? '-'}`} */}
                        {`AED ${parseFloat(listItem?.incomePaymentOutstandingCount)?.toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                        })}`}
                      </td>
                      <td
                        className='text-start cursor-pointer ps-0 min-w-175px'
                        onClick={() => handleOwnerModel(listItem)}
                      >
                        {' '}
                        <div className='d-flex align-items-center flex-wrap'>
                          {listItem?.owner?.length > 0 ? `${listItem?.owner?.[0]?.firstName} ${listItem?.owner?.[0]?.lastName}` : '-'}
                          <span className='ms-auto'>{listItem?.owner?.length > 1 ? `+${listItem?.owner?.length - 1}` : ''}</span>
                        </div>
                   
                      </td>
                      <td className='text-start cursor-pointer ps-4 min-w-100px' onClick={() => goToMiantenance(listItem)}>
                        {' '}
                        <div className='d-flex align-items-center '>
                          <img src={yellowStar} height={18} width={18} className='me-1' />
                          {listItem?.rating != undefined ? listItem?.rating : '-'}
                        </div>
                      </td>

                      <td className='text-start cursor-pointer ps-0 min-w-100px' onClick={() => goToMiantenance(listItem)}>
                        {' '}
                        {listItem?.assignUser.length >= 0 && <img src={userIcon} height={20} width={20} className='me-2' />}
                        {listItem?.assignUser.length > 0 ? listItem?.assignUser?.length : 0}
                      </td>
                      
                      {isEditBtn && (
                        <td>
                          <img src={editPen} width='12' className='mx-6' onClick={() => goToEdit(listItem)} style={{cursor: 'pointer'}} />
                        </td>
                      )}
                    </tr>
                  )
                })
              ) : (
                <tr>
                  <td colSpan={10} className='text-center'>
                    <img src={noData} alt='' width={350} />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {/* pagination start*/}
        <div className='row mb-5'>
          <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
          <div className='row mt-5'>
            <div className='col-sm-12 col-md-12 d-flex align-items-center justify-content-center justify-content-md-end mt-5'>
              <div className='dataTables_paginate paging_simple_numbers' id='kt_ecommerce_sales_table_paginate'>
                <ReactPaginate
                  breakLabel='...'
                  nextLabel='>'
                  pageClassName='paginate_button page-item +'
                  pageLinkClassName='page-link'
                  containerClassName='pagination'
                  activeClassName='active'
                  previousClassName='paginate_button page-item previous'
                  nextClassName='paginate_button page-item next'
                  previousLinkClassName='page-link'
                  nextLinkClassName='page-link'
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pageLimit}
                  forcePage={page - 1}
                  previousLabel='<'
                />
              </div>
            </div>
          </div>
        </div>
        {/* pagination end*/}
      </div>
      <div className='light-box-single-image'>{attachmentsForPreview && <AttachmentPreview attachments={attachmentsForPreview} />}</div>
    </div>

    {ownerListeModal && (
        <OwnerListModel
        listData={ownerListData}
          show={ownerListeModal}
          handleClose={() => {
            setOwnerListModal(false)
          }}
        />
      )}
    </>
  )
}

export default PropertiesList
