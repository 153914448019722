import React, {useEffect, useState} from 'react'
import trashImg from '../../../../img/trash.png'
import saveGreenImg from '../../../../img/save-dark-green.png'
import {useNavigate, useParams} from 'react-router-dom'
import redCross from '../../../../img/remove.png'
import active from '../../../../img/active.png'
import backArrow from '../../../../img/back-arrow.png'
import {
  ApiGet,
  ApiPost,
  ApiPut,
  ApiUpload,
  Bucket,
  ApiDelete,
} from '../../../../apiCommon/helpers/API/ApiData'
import {ErrorToast, SuccessToast} from '../../../../apiCommon/helpers/Toast'
import Recipient from './Recipient'
import {Controller, useForm} from 'react-hook-form'
import CustomDateRangePicker from './CustomDateRangePicker'
import upload from '../../../../img/upload-blue.png'
import moment from 'moment'
import {useMediaQuery} from '@mui/material'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import pencil from '../../../../img/edit-pen.png'
import terminTen from '../../../../img/expired-tenc.png'
import swal from 'sweetalert2'
import pdfDocument from '../../../../img/pdf.svg'
import docCocument from '../../../../img/doc.svg'
import fileIcon from '../../../../img/file.svg'
import TextEditor from '../../General/TextEditor'
import ModalLoader from '../../General/ModalLoader'
import { capitalizeFirstLetter } from '../../../../Utilities/utils'

import { Fancybox as fancybox } from "@fancyapps/ui";
import '@fancyapps/ui/dist/fancybox/fancybox.css'

const CreateAnnouncement = () => {
  const isAboveMediumScreen = useMediaQuery('(min-width:768px)')
  const {flg, id} = useParams()
  const navigate = useNavigate()

  const userData: any = localStorage.getItem('userData')
  const user = JSON.parse(userData)
  const roleAndPermissions: any = localStorage.getItem('roleAndPermissions')
  const role = JSON.parse(roleAndPermissions)

  const [isLoading, setIsLoading] = useState<any>()
  const [start_date, setStart] = useState<any>(null)
  const [end_date, setEnd] = useState<any>(null)

  const [announcementMessage, setAnnouncementMessage] = useState<any>('')
  const [announcementTitle, setAnnouncementTitle] = useState<any>()
  const [announcementData, setAnnouncementData] = useState<any>({})
  const [properties, setProperties] = useState<any>([])
  const [isSaveFirst, setIsSaveFirst] = useState<any>(false)

  const [added, setAdded] = useState<any>(false)
  const [addedProperties, setAddedProperties] = useState<any>([])
  
  const [showLoader, setShowLoader] = useState<boolean>(false)
  const [uploadProgress, setUploadProgress] = useState<number>(0)
  const [totalFiles, setTotalFiles] = useState<number>(0)

  const [uploadingText, setUploadingText] = useState<any>('Uploading')
  const [percentProgress, setPercentProgress] = useState(0)

  const {
    register,
    control,
    handleSubmit,
    reset,
    setValue,
    formState: {errors},
  } = useForm<any>({mode: 'onSubmit', reValidateMode: 'onChange'})

  const [attachments, setAttachments] = useState<any>([])
  const [annouceMsgError, setAnnouceMsgError] = useState<any>(false)

  const onSubmitHandler = async (e: any, isSave: any) => {
    if (e != '') e?.preventDefault()

    if ((startDatePayload == null || endDatePayload == null) && !isSave) {
      setDateError(true)
    } else if(announcementMessage?.trim()?.length == 0 && !isSave){
      setAnnouceMsgError(true)
    }
    else {
      if (flg == '2' || flg == '3') setOpen(true)
      setIsLoading(true)

      let attachedMedia: any = []
      attachments?.map((attach: any, ind: any) => {
        attachedMedia[ind] = attach?.image
      })

      let recipientLists: any = {
        developmentIds: [],
        tenanciesIds: [],
        tenantIds: [],
        unitIds: [],
        hierarchy: [],
      }
      const newHierarchy = properties.flatMap(
        (property: any) => property?.assignedProperties?.hierarchy
      )

      properties?.map((property: any) => {
        if (property?.assignedProperties?.developmentId != undefined)
          recipientLists.developmentIds[recipientLists.developmentIds?.length] =
            property?.assignedProperties?.developmentId

        if (property?.assignedProperties?.tenancy != undefined)
          recipientLists.tenanciesIds[recipientLists.tenanciesIds?.length] =
            property?.assignedProperties?.tenancy

        if (property?.assignedProperties?.tenant != undefined)
          recipientLists.tenantIds[recipientLists.tenantIds?.length] =
            property?.assignedProperties?.tenant

        if (property?.assignedProperties?.unitIds != undefined)
          recipientLists.unitIds[recipientLists.unitIds?.length] =
            property?.assignedProperties?.unitIds
      })

      let newDev = recipientLists?.developmentIds?.filter((value: any, index: any, self: any) => {
        return self.indexOf(value) === index
      })

      let newTenanciesIds = recipientLists?.tenanciesIds?.filter(
        (value: any, index: any, self: any) => {
          return self.indexOf(value) === index
        }
      )

      let newTenantIds = recipientLists?.tenantIds?.filter((value: any, index: any, self: any) => {
        return self.indexOf(value) === index
      })
      let newUnitIds = recipientLists?.unitIds?.filter((value: any, index: any, self: any) => {
        return self.indexOf(value) === index
      })
      
      // let newHierarchy = recipientLists?.hierarchy?.filter((value: any, index: any, self: any) => {
      //   console.log(value,self);
      //   return self.indexOf(value) === index
      // })

      let newArr: any = {
        unitIds: newUnitIds,
        tenantIds: newTenantIds,
        tenanciesIds: newTenanciesIds,
        developmentIds: newDev,
        hierarchy: newHierarchy,
      }

      const body = {
        id: flg == '1' ? undefined : id,
        title: announcementTitle,
        announcementMessage: announcementMessage,
        startDate: startDatePayload,
        endDate: endDatePayload,
        saveStatus: isSave ? 0 : 1,
        documents: attachedMedia,
        recipientLists: newArr,
      }

      if (flg == '1') {
        await ApiPost(`corporate/announcement`, body)
          .then((res: any) => {
            setOpen(false)
            if (!isSave) {
              SuccessToast(res?.data?.message)
              navigate('/announcements')
            } else {
              setIsLoading(true)
              navigate(`/create-announcement/2/${res?.data?.data?._id}`)
              setIsSaveFirst(true)
            }
          })
          .catch((err: any) => {
            setOpen(false)
            ErrorToast(err?.message)
          })
      } else {
        await ApiPut(`corporate/announcement`, body)
          .then((res: any) => {
            setOpen(false)

            if (flg == '2' && !isSave) {
              navigate('/announcements')
              SuccessToast(res?.data?.message)
            }
            if (flg == '3') {
              SuccessToast(res?.data?.message)
              getAnnouncementById()
              setIsEdit(false)
            }

            setIsLoading(false)
          })
          .catch((err: any) => {
            setOpen(false)
            setIsLoading(false)
            ErrorToast(err?.message)
          })
      }
    }
    //   else{
    //     setDateError(true)
    //   }
  }

  React.useEffect(() => {
    if (flg == '2' || flg == '3' || isSaveFirst) {
      getAnnouncementById()
    }
  }, [isSaveFirst])

  //
  const getAnnouncementById = () => {
    setIsLoading(true)
    ApiGet(`corporate/announcement/${id}`)
      .then((res: any) => {
        setAnnouncementData(res?.data?.data)
        setAnnouncementTitle(res?.data?.data?.title)
        setAnnouncementMessage(res?.data?.data?.announcementMessage)

        if (res?.data?.data?.startDate != null) setStart(moment(res?.data?.data?.startDate))
        else setStart(null)

        setStartDatePayload(res?.data?.data?.startDate)

        if (res?.data?.data?.endDate != null) setEnd(moment(res?.data?.data?.endDate))
        else setEnd(null)

        setEndDatePayload(res?.data?.data?.endDate)

        setValue('title', res?.data?.data?.title)
        setValue('message', res?.data?.data?.announcementMessage)

        let values: any = []
        res?.data?.data?.documents?.map((img: any, ind: any) => {
          values[ind] = {
            image: img,
          }
        })
        setAttachments(values)

        setAddedProperties(res?.data?.data?.recipientLists)
        setProperties(res?.data?.data?.unit)
        setAdded(true)
        setIsLoading(false)
      })
      .catch((err: any) => {
        setIsLoading(false)
        ErrorToast(err?.message)
      })
  }

  //
  const handleChange = async (e: any) => {
    console.log(e)
    e.preventDefault()

    const values = [...attachments]
    let newVal = values?.length - 1

    if (e.target.files && e.target.files[0]) {
      setShowLoader(true)
      setTotalFiles(e.target.files.length)

      for (let i = 0; i < e.target.files.length; i++) {
        // let fileURL = URL.createObjectURL(e.target.files[0])
        let fileURL = e.target.files[i]

        let userData: any = localStorage.getItem('userData')
        const data = JSON.parse(userData)

        let x = values[values.length]?.src?.name?.split('.')[1]

        var formdata = new FormData()
        formdata.append('image', fileURL)

        let name: any = e.target.files[i]?.name

        await ApiPost(
          `upload/image/params?fileType=announcement&databaseId=${data._id}&fileName=${name}`,
          formdata
        )
          .then((res) => {
            console.log(res?.data?.data?.image)
            newVal = newVal + 1
            values[newVal] = {
              src: fileURL,
              name: '',
              fileType: 'cheque',
              databaseId: data?._id,
              image: res?.data?.data?.image,
            }
            setUploadProgress(i + 1)
          })
          .catch((err: any) => {
            ErrorToast(err?.message)
            setShowLoader(false)
            setUploadProgress(0) // Reset upload progress
            setTotalFiles(0)
          })
      }
      setAttachments(values)

      setShowLoader(false)
      setUploadProgress(0) // Reset upload progress
      setTotalFiles(0)
      e.target.value = null

      setTimeout(() => {
        setAutoSaveData(Date.now())
      }, 700)
    }
  }

  const [open, setOpen] = React.useState(false)

  const [startDatePayload, setStartDatePayload] = useState<any>(null)
  const [endDatePayload, setEndDatePayload] = useState<any>(null)
  const [isFormDirty, setIsFormDirty] = useState(false)
  const [dateError, setDateError] = useState<any>(false)

  // 
  const handleDatesChange = ({
    startDate,
    endDate,
  }: {
    startDate: moment.Moment | null
    endDate: moment.Moment | null
  }) => {
    if (startDate == null && endDate == null) {
      setStart(null)
      setStartDatePayload(null)
      setEnd(null)
      setEndDatePayload(null)
    } else {
      setStart(startDate)
      setStartDatePayload(startDate?.format('YYYY-MM-DD'))
      setEnd(endDate)
      setEndDatePayload(endDate?.format('YYYY-MM-DD'))

      setIsFormDirty(true)
      if (startDatePayload && endDatePayload) {
      }
    }
  }

  const [isEdit, setIsEdit] = useState<any>(flg == '3' ? false : true)
  const [autoSaveData, setAutoSaveData] = useState<any>(false)

  // 
  const deleteAnnouncement = () => {
    ApiDelete(`corporate/announcement/${id}`)
      .then((res: any) => {
        SuccessToast(res?.data?.message)
        navigate('/announcements')
      })
      .catch((error: any) => {
        ErrorToast(error?.message)
      })
  }

  useEffect(() => {
    if (autoSaveData != false && flg == '2') onSubmitHandler('', true)
  }, [autoSaveData])

  // 
  const deActivate = async () => {
    setIsLoading(true)
    const body = {
      id: id,
      saveStatus: 2,
    }
    await ApiPut(`corporate/announcement`, body)
      .then((res: any) => {
        SuccessToast(res?.data?.message)
        setIsLoading(false)
        getAnnouncementById()
      })
      .catch((error: any) => {
        setIsLoading(false)
        ErrorToast(error?.message)
      })
  }

  useEffect(() => {
    fancybox.bind('[data-fancybox]', {
      Toolbar: {
        display: {
          left: ['infobar'],
          middle: ['zoomIn', 'zoomOut', 'toggle1to1', 'rotateCCW', 'rotateCW', 'flipX', 'flipY'],
          right: ['slideshow', 'thumbs', 'close'],
        },
      },
    })
  }, []);

  return (
    <>
      <div>
        <div className='app-main flex-column flex-row-fluid px-2' id='kt_app_main'>
          <form onSubmit={handleSubmit((data: any, e: any) => onSubmitHandler(e, false))}>
            <div id='' className='pt-0 mt-0'>
              {/* Header */}
              <div className='page-title d-flex flex-column justify-content-center flex-wrap'>
                <div className=' d-flex flex-stack pt-1 mt-0 px-0'>
                  {' '}
                  {/* container-xxl */}
                  <div className=''>
                    <div className='d-flex align-items-center mb-1'>
                      <span
                        className='rounded-circle'
                        style={{cursor: 'pointer'}}
                        onClick={() => navigate('/announcements')}
                      >
                        <img src={backArrow} height='14' width='14' />
                      </span>
                      <h2 className='m-0 head-text ms-2'>
                        {flg == '1'
                          ? 'Create Announcement'
                          : flg == '2'
                          ? 'Draft Announcement'
                          : flg == '3' && isEdit
                          ? 'Edit Announcement'
                          : 'Announcement'}
                      </h2>
                    </div>
                  </div>
                  <div className='d-flex'>
                    {!isEdit ? (
                      <>
                        <img
                          src={pencil}
                          alt=''
                          height={18}
                          width={18}
                          className='ms-4 cursor-pointer'
                          onClick={() => {
                            setIsEdit(true)
                          }}
                        />
                        <img
                          src={trashImg}
                          height={19}
                          width={19}
                          className='ms-5 cursor-pointer'
                          onClick={() => {
                            swal
                            .fire({
                              html: `<div class='fs-3'> <b>Delete Announcement </b></div>
                                <div class='fs-5 mt-4'> Are you sure you want to delete this announcement? </div>`,
                              // icon: 'warning',
                              showConfirmButton: true,
                              confirmButtonColor: '#35475e',
                              confirmButtonText: 'Yes',
                              showCancelButton: true,
                              cancelButtonText: 'No',
                              cancelButtonColor: '#fff',
                              customClass: {
                                confirmButton: 'custom-confirm-button',
                                cancelButton: 'custom-cancel-button',
                                popup: 'custom-popup',
                              },
                              // reverseButtons: true, 
                            })
                              .then((res: any) => {
                                if (res.isConfirmed) {
                                  deleteAnnouncement()
                                }
                              })
                          }}
                        />{' '}
                      </>
                    ) : (
                      <>
                        {flg == '3' && (
                          <button
                            type='button'
                            className='btn btn-sm fw-bold  mx-3 red-hollow-btn ps-2'
                            onClick={() => {
                              setAdded(false)
                              getAnnouncementById()

                              setIsEdit(false)
                            }}
                            disabled={isLoading}
                          >
                            <img src={redCross} height={18} width={18} className='me-4' /> Cancel
                          </button>
                        )}

                        <button
                          type='submit'
                          className='btn btn-sm fw-bold mx-3 green-submit-btn status-w-120 ps-2'
                          disabled={isLoading}
                        >
                          <img src={active} height={18} width={18} className='me-5' /> {'  '}
                          {flg == '3' ? 'Re-Launch' : 'Launch'}
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className='card card-flush p-5 mt-5 px-5'>
                {isEdit ? (
                  <div className='d-flex'>
                    <div className='col-md-7'>
                      <h2 className='m-0 head-text ms-2'>
                        <b>Announcement Details</b>
                      </h2>

                      <div className='d-flex mt-7 ps-2'>
                        <h4 className='my-2' style={{color: 'black'}}>
                          <label
                            style={{width: '130px'}}
                            className={`labl-gry ${isEdit && 'required'}`}
                          >
                            {' '}
                            Title
                          </label>

                          {!isEdit && flg == '3' && (
                            <span className='ms-1'>
                              <b>{announcementTitle ? announcementTitle : '-'}</b>
                            </span>
                          )}
                        </h4>

                        {isEdit && (
                          <h4>
                            <input
                              {...register('title', {required: true})}
                              type='text'
                              className='form-control form-control-solid mytest ms-1 bg-white'
                              placeholder='Enter title'
                              name='title'
                              value={announcementTitle}
                              onChange={(e) => {
                                let newVal:any = capitalizeFirstLetter(e.target.value)
                                setAnnouncementTitle(newVal)
                                setValue('title', e.target.value)
                              }}
                              style={{width: '285px'}}
                              onBlur={() =>
                                setTimeout(() => {
                                  setAutoSaveData(Date.now())
                                }, 500)
                              }
                            />

                            {errors.title && (
                              <p
                                style={{color: 'var(--kt-danger)', gridColumn: '2/2'}}
                                className='m-0 mt-1'
                              >
                                This field is required
                              </p>
                            )}
                          </h4>
                        )}
                      </div>

                      <div className='d-flex mt-5 ps-3'>
                        <h4 className='my-2' style={{color: 'black'}}>
                          <label style={{width: '130px'}} className='labl-gry required'>
                            {' '}
                            Validity{' '}
                          </label>
                        </h4>

                        {isEdit && (
                          <CustomDateRangePicker
                            startDate={start_date}
                            endDate={end_date}
                            dateStatuses={[]}
                            onDatesChange={handleDatesChange}
                            //  datesToCompare={datesToCompare}
                            autoSave={() => {
                              if (flg == '2') setOpen(true)
                              setTimeout(() => {
                                setAutoSaveData(Date.now())
                              }, 500)
                            }}
                            from={'create'}
                            disabled={false}
                          />
                        )}
                      </div>

                      <div className='d-flex'>
                        <h4 className='' style={{color: 'black'}}>
                          <label style={{width: '130px'}} className='labl-gry'></label>
                        </h4>
                        {dateError && (
                          <p style={{color: 'var(--kt-danger)'}} className='ps-4'>
                            This field is required
                          </p>
                        )}
                      </div>

                      <div className='d-flex ps-3 mt-5'>
                        {/* <h4 className='my-2' style={{color: 'black'}}>
                          <label style={{width: '130px'}} className='labl-gry'>
                            {' '}
                          </label>
                        </h4> */}
                        <h4 className='my-2' style={{color: 'black'}}>
                          <label style={{width: '130px'}} className='labl-gry '>
                            {' '}
                            Attachments
                          </label>
                        </h4>
                        <label
                          title='Add New File'
                          htmlFor='uploadNewTenant'
                          className='btn btn-sm fw-bold upload-btn ms-auto me-10 btn-fit-content ps-2 pe-5'
                        >
                          <img src={upload} height={18} width={18} className='me-5' />
                          Upload{' '}
                        </label>
                        <input
                          type='file'
                          hidden
                          id='uploadNewTenant'
                          className='form-control form-control-solid'
                          placeholder='upload'
                          name='newTenant'
                          onChange={(e: any) => {
                            handleChange(e)
                          }}
                          multiple
                        />
                      </div>

                      <div className='col-12 px-5  ps-0 my-5'>
                        <div className='d-flex '>
                          <h4 className='my-2' style={{color: 'black'}}>
                            <label style={{width: '130px'}} className='labl-gry'>
                              {' '}
                            </label>
                          </h4>
                          {attachments?.length > 0 && (
                            <div
                              className='tops d-flex gap-5 align-items-start mx-5 flex-wrap'
                              style={{maxHeight: '350px', overflow: 'auto'}}
                            >
                              {attachments?.map((file: any, ind: any) => {
                                return (
                                    <div className='imgs-set'>
                                      <div className='d-flex mw-350px me-3 flex-wrap'>
                                        {/* <a target='_blank' href={`${Bucket}${file?.image}`}> */}
                                          <img
                                            src={
                                              file?.image?.split('.')[1] === 'pdf'
                                                ? pdfDocument
                                                : file?.image?.split('.')[1] === 'doc' ||
                                                  file?.image?.split('.')[1] === 'docx'
                                                ? docCocument
                                                : file?.image?.split('.')[1] === 'jpeg' ||
                                                  file?.image?.split('.')[1] === 'jpg' ||
                                                  file?.image?.split('.')[1] === 'png' ||
                                                  file?.image?.split('.')[1] === 'svg' ||
                                                  file?.image?.split('.')[1] === 'gif'
                                                ? `${Bucket}${file?.image}`
                                                : fileIcon
                                            }
                                            // src={Bucket + file?.image}
                                            width='100'
                                            height='100'
                                            className='main_img mb-5 cursor-pointer'
                                            data-fancybox
                                            data-src={`${Bucket}${file?.image}`}
                                            key={file?.image}
                                        
                                            
                                          />
                                        {/* </a> */}
                                        <img
                                          src={redCross}
                                          height={15}
                                          width={15}
                                          className='cursor-pointer ms-2 mt-1'
                                          onClick={() => {
                                            const updateAttachment = [...attachments]
                                            updateAttachment.splice(ind,1)
                                            setAttachments(updateAttachment)
                                          }}
                                        />
                                      </div>
                                    </div>
                          
                                )
                              })}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className='col-md-5 d-flex flex-column  '>
                      <div className='d-flex justify-content-between'>
                        <div className='d-flex'>
                          {flg == '2' || flg == '3' ? (
                            <div className='d-block'>
                              <div
                                className={
                                  announcementData?.saveStatus == 0
                                    ? 'status booked-bar status-w-110'
                                    : announcementData?.saveStatus == 1
                                    ? 'status active-bar status-w-110'
                                    : announcementData?.saveStatus == 2
                                    ? 'status expired-bar status-w-110'
                                    : 'status not-saved status-w-110'
                                }
                              >
                                {announcementData?.saveStatus == 0
                                  ? 'Draft'
                                  : announcementData?.saveStatus == 1
                                  ? 'Active'
                                  : announcementData?.saveStatus == 2
                                  ? 'Inactive'
                                  : '-'}
                              </div>
                              <i>
                                {' '}
                                {announcementData?.saveStatusDateTime
                                  ? `${moment(announcementData?.saveStatusDateTime).format(
                                      'DD.MM.YYYY - HH:mm A'
                                    )} `
                                  : ''}
                              </i>
                            </div>
                          ) : (
                            <div className='status not-saved status-w-110'>-</div>
                          )}
                        </div>
                        {flg == '2' && (
                          <button
                            type='button'
                            className='btn btn-sm fw-bold  mx-3 red-hollow-btn ms-auto ps-2'
                            onClick={() => {
                              swal
                                .fire({
                                  html: `<div class='fs-3'> <b>Delete Announcement </b></div>
                                    <div class='fs-5 mt-4'> Are you sure you want to delete this announcement? </div>`,
                                  // icon: 'warning',
                                  showConfirmButton: true,
                                  confirmButtonColor: '#35475e',
                                  confirmButtonText: 'Yes',
                                  showCancelButton: true,
                                  cancelButtonText: 'No',
                                  cancelButtonColor: '#fff',
                                  customClass: {
                                    confirmButton: 'custom-confirm-button',
                                    cancelButton: 'custom-cancel-button',
                                    popup: 'custom-popup',
                                  },
                                  // reverseButtons: true, 
                                })
                                .then((res: any) => {
                                  if (res.isConfirmed) {
                                    deleteAnnouncement()
                                  }
                                })
                            }}
                            disabled={isLoading}
                          >
                            <img src={trashImg} height={18} width={18} className='me-5' /> Delete
                          </button>
                        )}
                        {flg == '1' && (
                          <button
                            type='button'
                            className='btn btn-sm fw-bold green-hollow-btn ps-2'
                            onClick={(e) => {
                              onSubmitHandler(e, true)
                            }}
                            disabled={isLoading}
                          >
                            <img src={saveGreenImg} height={18} width={18} className='me-5' />{' '}
                            {'  '}Save
                          </button>
                        )}
                      </div>
                      <div>
                        <div className='mt-4 d-flex flex-column me-4'>
                          <h4 className='my-2 ms-1 mb-3' style={{color: 'black'}}>
                            <label className={`labl-gry ${isEdit && 'required'}`}>
                              {' '}
                              Annoucement Message
                            </label>
                          </h4>
                          {isEdit && (
                            <>
                              <TextEditor
                                value={announcementMessage}
                                onChange={(val: any) => {
                                  setAnnouncementMessage(val)
                                  setValue('message', val)
                                }}
                                autoSaveText={() => {
                                  if (flg == '2') {
                                    setTimeout(() => {
                                      setAutoSaveData(Date.now())
                                    }, 700)
                                  }
                                }}
                                refresh={announcementMessage}
                                placeholder={'Enter Message'}
                                from={'annoucements'}
                              />

                              {annouceMsgError && (
                                <p
                                  style={{color: 'var(--kt-danger)', gridColumn: '2/2'}}
                                  className='m-0 mt-1'
                                >
                                  This field is required
                                </p>
                              )}
                            </>
                          )}

                          {/* {isEdit && (
                            <h4>
                              <textarea
                                {...register('message', {required: true})}
                                className='form-control form-control-solid mytest ms-1'
                                placeholder='Enter Message'
                                name='message'
                                value={announcementMessage}
                                onChange={(e) => {
                                  setAnnouncementMessage(e.target.value)
                                  setValue('message', e.target.value)
                                }}
                                style={{height: '200px'}}
                                onBlur={() =>
                                  setTimeout(() => {
                                    setAutoSaveData(Date.now())
                                  }, 500)
                                }
                              />

                              {errors.message && (
                                <p
                                  style={{color: 'var(--kt-danger)', gridColumn: '2/2'}}
                                  className='m-0 mt-1'
                                >
                                  This field is required
                                </p>
                              )}
                            </h4>
                          )} */}

                          {/* <div className='mb-2 tArea'>
                          <textarea
                            className='myTextArea'
                            placeholder='Enter Message'
                            rows={10}
                            value={announcementMessage}
                            onChange={(e) => setAnnouncementMessage(e.target.value)}
                          />
                        </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className='row'>
                      <div className='col-7'>
                        <h2 className='m-0 ms-2 mt-2 text-capitalize'>
                          <b>{announcementTitle}</b>
                        </h2>
                      </div>

                      <div className='col-5'>
                        <div className='d-flex'>
                          <h3 className='mt-2 me-5'>
                            <span className='ms-5 ps-1 my-2'>
                              <b>
                                {start_date != null || end_date != null
                                  ? `${moment(start_date).format('DD-MM-YYYY')}  -  ${moment(
                                      end_date
                                    ).format('DD-MM-YYYY')} `
                                  : '-'}
                              </b>
                            </span>
                          </h3>
                          <div className='d-block ms-auto'>
                            <div
                              className={
                                announcementData?.saveStatus == 0
                                  ? 'status booked-bar status-w-110'
                                  : announcementData?.saveStatus == 1
                                  ? 'status active-bar status-w-110'
                                  : 'status expired-bar status-w-110'
                              }
                            >
                              {announcementData?.saveStatus == 0
                                ? 'Draft'
                                : announcementData?.saveStatus == 1
                                ? 'Active'
                                : 'Inactive'}
                            </div>
                            <i>
                              {' '}
                              {announcementData?.saveStatusDateTime
                                ? `${moment(announcementData?.saveStatusDateTime).format(
                                    'DD.MM.YYYY - HH:mm A'
                                  )} `
                                : ''}
                            </i>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-7 mb-10 view-annouce m-2'>
                        {/* <h4 className='my-2 ms-2 labl-gry' style={{color: 'black'}}>
                          {announcementMessage}
                        </h4> */}

                        <TextEditor
                          value={announcementMessage}
                          onChange={(val: any) => {
                            setAnnouncementMessage(val)
                            setValue('message', val)
                          }}
                          autoSaveText={() => {
                            if (flg == '2') {
                              setTimeout(() => {
                                setAutoSaveData(Date.now())
                              }, 700)
                            }
                          }}
                          refresh={announcementMessage}
                          placeholder={'Enter Message'}
                          from={'annoucements'}
                        />
                      </div>
                      <div className='col-5'>
                        {announcementData?.saveStatus == 1 ? (
                          <button
                            type='button'
                            className='btn btn-sm fw-bold red-hollow-btn red-filled-btn m-0 ms-5 status-w-130'
                            onClick={() => {
                              swal
                                .fire({
                                  text: 'Are you sure you want to deactivate this announcement?',
                                  icon: 'warning',
                                  showConfirmButton: true,
                                  confirmButtonColor: '#D72852',
                                  confirmButtonText: 'Yes',
                                  showCancelButton: true,
                                  // cancelButtonColor: "transparent",
                                  cancelButtonText: 'Cancel',
                                })
                                .then((res: any) => {
                                  if (res.isConfirmed) {
                                    deActivate()
                                  }
                                })
                            }}
                          >
                            <img src={terminTen} height={16} width={16} className='me-3' />{' '}
                            Deactivate
                          </button>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                    <div className='row ps-3 mt-5'>
                      <h3 className='my-2' style={{color: 'black'}}>
                        <label className=''>
                          <b>Attachments</b>
                        </label>
                      </h3>

                      <div className='col-12 px-5  ps-0 my-5'>
                        <div className='d-flex '>
                          {attachments?.length > 0 && (
                            <div
                              className='tops d-flex gap-5 align-items-start mx-5 flex-wrap'
                              style={{maxHeight: '350px', overflow: 'auto'}}
                            >
                              {attachments?.map((file: any, ind: any) => {
                                return (
                                  <>
                                    <div className='imgs-set'>
                                      <div className='d-flex mw-350px me-3'>
                                        <img
                                          src={Bucket + file?.image}
                                          width='100'
                                          height='100'
                                          className='main_img mb-5 cursor-pointer'
                                          data-fancybox
                                          data-src={`${Bucket}${file?.image}`}
                                        />
                                      </div>
                                    </div>
                                  </>
                                )
                              })}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )}

                <div className='col-12'>
                  <h2 className='m-0 head-text ms-2 mb-7'>
                    <b>Recipients</b>
                  </h2>
                  <Recipient
                    setAdded={setAdded}
                    added={added}
                    addedProperties={addedProperties}
                    setAddedProperties={setAddedProperties}
                    properties={properties}
                    setProperties={setProperties}
                    autoSave={() => {
                      setTimeout(() => {
                        setAutoSaveData(Date.now())
                      }, 500)
                    }}
                    editMode={isEdit}
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <Backdrop sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}} open={open}>
        <CircularProgress style={{color: '#007a59'}} />
      </Backdrop>

      <ModalLoader
        isVisible={showLoader}
        progress={uploadProgress}
        totalFiles={totalFiles}
        uploadingText={uploadingText}
        percentageProgress={percentProgress}
      />
    </>
  )
}

export default CreateAnnouncement
