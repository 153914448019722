import React, {useState, useEffect} from 'react'
import {Modal} from 'react-bootstrap'
import './styles.scss'
import {Col, Row} from 'react-bootstrap'
import {MultiSelect} from 'react-multi-select-component'
import {ApiPost, ApiGet} from '../../../apiCommon/helpers/API/ApiData'
import {ErrorToast} from '../../../apiCommon/helpers/Toast'
import redCross from '../../../img/remove.png'
import addWhite from '../../../img/add-white.png'
import Paper from '@mui/material/Paper'
import InputBase from '@mui/material/InputBase'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import sync from '../../../img/sync.png'
import {CircularProgress} from '@mui/material'
import noData from '../../../img/NoData1.svg'
import eye from '../../../img/eye-blue.png'

interface ButtonProps {
  show: any
  updateVendor: (data: string) => void
  handleClose: any
  addedVendor: any
}

const SelectVendorModal = ({handleClose, show, addedVendor, updateVendor}: ButtonProps) => {
  const [searchList, setSearchList] = useState<any>('')
  const [searchVendor, setSearchVendor] = useState<any>('')
  const [result, setResult] = useState<any>()
  const [isLoading, setIsLoading] = useState<any>(true)

  const [eyeInd, setEyeInd] = useState<any>(-1)

  let totalSelected: any = 0

  const searchContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#f0f0f0',
    borderRadius: '25px',
  }

  const searchIconStyle = {
    padding: '10px',
    color: 'gray',
  }

  const inputBaseStyle = {
    flex: 1,
    padding: '5px',
  }

  const [list, setList] = useState<any>([])
  const [listOptions, setListOptions] = useState<any>([])

  const [vendors, setVendors] = useState<any>([])
  const [vendorsOptions, setVendorsOptions] = useState<any>([])

  const [radioChecked, setRadioChecked] = useState<any>(
    addedVendor?._id != undefined ? addedVendor?._id : ''
  )

  const [selectedService, setSelectedService] = useState<any>(addedVendor)

  const [selected, setSelected] = useState<any>('list')
  const [refreshData, setRefreshData] = useState<any>(false)
  const [refreshTable, setRefreshTable] = useState<any>(false)

  //
  const getListForDropdown = async () => {
    await ApiGet(`corporate/vendor_list`)
      .then((res) => {
        let values: any = []
        res?.data?.data?.map((val: any, ind: any) => {
          values[ind] = {
            label: `${val?.name}`,
            value: `${val?._id}`,
          }
        })
        setListOptions(values)
      })
      .catch((err) => {
        ErrorToast(err?.message)
      })
  }

  //
  const getVendorsForDropdown = async () => {
    await ApiGet(`corporate/vendor`)
      .then((res) => {
        let values: any = []
        res?.data?.data?.map((val: any, ind: any) => {
          values[ind] = {
            label: `${val?.companyName}`,
            value: `${val?._id}`,
          }
        })
        setVendorsOptions(values)
      })
      .catch((err) => {
        ErrorToast(err?.message)
      })
  }

  //
  const updateList = async () => {
    let lst: any = []
    list?.map((v: any, ind: any) => {
      lst[ind] = v?.value
    })

    let vnd: any = []
    vendors?.map((v: any, ind: any) => {
      vnd[ind] = v?.value
    })

    const body = {
      // page: 1,
      // limit: 25,
      searchVendorList: searchList,
      searchVendor: searchVendor,
      vendorListIds: lst,
      vendorIds: vnd,
    }
    await ApiPost(`corporate/vendor/get/maintenance`, body)
      .then((res) => {
        let values = [...res?.data?.data?.vendor_data]
        res?.data?.data?.vendor_data?.map((res: any, ind: any) => {
          values[ind].check = false
        })

        setResult(values)
        setIsLoading(false)
      })
      .catch((e) => {
        setIsLoading(false)
        ErrorToast(e?.message)
      })
  }

  useEffect(() => {
    updateList()
  }, [searchList, searchVendor, refreshTable])

  const getFilters = (val: any) => {
    let lst: any = []
    list?.map((v: any, ind: any) => {
      lst[ind] = v?.value
    })

    let vnd: any = []
    vendors?.map((v: any, ind: any) => {
      vnd[ind] = v?.value
    })

    const body = {
      vendorIdFilter: val == 'vendor' ? vnd : undefined,
      vendorListIdFilter: val == 'list' ? lst : undefined,
    }

    ApiPost(`corporate/maintenance_service_job/filter/for_add`, body)
      .then((res: any) => {
        // list
        let listOptions: any = []
        let selectedListOptions: any = []
        let selectedListInd: any = 0

        res?.data?.data?.vendorList?.map((val: any, ind: any) => {
          listOptions[ind] = {
            label: `${val?.name}`,
            value: `${val?._id}`,
          }
          if (val?.isCheck) {
            selectedListOptions[selectedListInd] = listOptions[ind]
            selectedListInd++
          }
        })
        setListOptions(listOptions)
        setList(selectedListOptions)

        // vendors
        let vendOptions: any = []
        let selectedVendOptions: any = []
        let selectedVendInd: any = 0

        res?.data?.data?.vendor?.map((val: any, ind: any) => {
          vendOptions[ind] = {
            label: `${val?.companyName}`,
            value: `${val?._id}`,
          }
          if (val?.isCheck) {
            selectedVendOptions[selectedVendInd] = vendOptions[ind]
            selectedVendInd++
          }
        })
        setVendorsOptions(vendOptions)
        setVendors(selectedVendOptions)

        setTimeout(() => {
          setRefreshTable(new Date())
        }, 300)
      })
      .catch((err) => {
        ErrorToast(err.message)
        setIsLoading(false)
      })
  }

  //
  useEffect(() => {
    getFilters(selected)
    // getListForDropdown()
    // getVendorsForDropdown()
  }, [refreshData])

  //
  const CustomValueRenderer = (selected: any, _options: any) => {
    return selected.length > 0 ? `${selected.length} Selected` : 'Select Cluster'
  }

  return (
    <div>
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-900px mx-auto'
        show={show}
        onHide={() => {
          handleClose()
        }}
        backdrop={true}
        size='sm'
        style={{}}
      >
        <Row>
          <div
            className='card p-5'
            style={{
              boxShadow: '0px 0px 20px 0px #516176',
              zIndex: '1',
              borderRadius: '0px',
            }}
          >
            <div className='d-flex align-items-center'>
              <h2 className='page-heading d-flex text-dark fw-bold flex-column justify-content-center '>
                Select Vendor
              </h2>

              <div className='d-flex ms-auto align-items-center'>
                {/* <button
                  type='button'
                  className='btn btn-sm fw-bold  me-3  red-hollow-btn'
                  onClick={() => {
                    handleClose()
                  }}
                >
                  <img src={redCross} height={16} width={16} style={{marginRight: '10px'}} /> Cancel
                </button> */}

                <button
                  type='button'
                  className='btn btn-sm fw-bold  me-3  select-btn ps-3 status-w-100'
                  onClick={() => {
                    updateVendor(selectedService)
                    handleClose()
                  }}
                >
                  <img src={addWhite} height={20} width={20} className='me-4' /> Add
                </button>

                <label
                  className='head-text fs-3 cursor-pointer ms-3'
                  style={{fontWeight: '700'}}
                  onClick={() => {
                    handleClose()
                  }}
                >
                  X
                </label>
              </div>
            </div>

            <div className='row mt-4'>
              <div className='col-1'>
                {' '}
                <label style={{width: '100px'}} className='text-dark fs-5 fnt-500 mt-10'>
                  {' '}
                  Search
                </label>
              </div>
              <div className='col-11'>
                <div
                  className='align-items-start row'
                  style={{
                    // justifyContent: 'flex-start',
                    paddingLeft: '0px',
                    borderBottom: '0px',
                  }}
                >
                  <Col md={3} className='mb-5 property xyz tst pe-6'>
                     <label className='text-dark fs-5 fnt-500 mb-1'>
                        Vendor List
                      </label>

                    <Paper component='form' style={searchContainerStyle}>
                      <InputBase
                        placeholder='List...'
                        style={inputBaseStyle}
                        inputProps={{'aria-label': 'search'}}
                        value={searchList}
                        onChange={(e: any) => {
                          setSearchList(e.target.value)
                        }}
                        disabled={list?.length > 0 || vendors?.length > 0}
                      />
                      <IconButton style={searchIconStyle} aria-label='search'>
                        <SearchIcon />
                      </IconButton>
                    </Paper>
                  </Col>

                  <Col md={3} className='mb-5 property xyz tst pe-6'>
                    <label className='text-dark fs-5 fnt-500 mb-1'>
                        Vendor Name
                    </label>
                    <Paper component='form' style={searchContainerStyle}>
                      <InputBase
                        placeholder='Vendor...'
                        style={inputBaseStyle}
                        inputProps={{'aria-label': 'search'}}
                        value={searchVendor}
                        onChange={(e: any) => {
                          setSearchVendor(e.target.value)
                        }}
                        disabled={list?.length > 0 || vendors?.length > 0}
                      />
                      <IconButton style={searchIconStyle} aria-label='search'>
                        <SearchIcon />
                      </IconButton>
                    </Paper>
                  </Col>

                  <Col md={6} className='mb-5 property xyz tst ps-0 pe-7'>
                    <div
                      className='filter-div d-flex align-items-center justify-content-center cursor-pointer mt-1 ms-auto'
                      onClick={() => {
                        setSearchList('')
                        setSearchVendor('')
                        setList([])
                        setVendors([])

                        setSelected('list')
                        setTimeout(() => {
                          setRefreshData(new Date())
                        }, 300)
                      }}
                    >
                      <img src={sync} height={20} width={22} className='cursor-pointer' />
                    </div>
                  </Col>
                </div>
              </div>
            </div>

            <div className='row mt-1'>
              <div className='col-1'>
                {' '}
                <label style={{width: '100px'}} className='text-dark fs-5 fnt-500 mt-9'>
                  {' '}
                  Filter
                </label>
              </div>
              <div className='col-11'>
                <div
                  className='align-items-start row'
                  style={{
                    // justifyContent: 'flex-start',
                    paddingLeft: '0px',
                    borderBottom: '0px',
                  }}
                >
                  {/* Lists */}
                  <Col md={3} className='test property'>
                    <div
                      className={`multi-select-container  jobs-property  me-7  ${
                        list.length === 0 ? 'no-value list' : 'has-value'
                      }`}
                    >
                      <label className='text-dark fs-5 fnt-500 mb-1'>
                        Vendor List
                      </label>
                      <MultiSelect
                        options={listOptions}
                        value={list}
                        onChange={(e: any) => {
                          setList(e)
                          setSelected('list')
                          setTimeout(() => {
                            setRefreshData(new Date())
                          }, 300)
                        }}
                        labelledBy='List'
                        overrideStrings={{
                          allItemsAreSelected: 'All Lists',
                        }}
                        disabled={
                          searchList?.trim()?.length > 0 || searchVendor?.trim()?.length > 0
                        }
                        valueRenderer={CustomValueRenderer}
                      />
                    </div>
                  </Col>

                  {/* Vendor */}
                  <Col md={3} className='test property'>
                    <div
                      className={`multi-select-container  jobs-property ${
                        vendors.length === 0 ? 'no-value vendor' : 'has-value'
                      }`}
                    >
                       <label className='text-dark fs-5 fnt-500 mb-1'>
                        Vendor Name
                      </label>
                      <MultiSelect
                        options={vendorsOptions}
                        value={vendors}
                        onChange={(e: any) => {
                          setVendors(e)
                          setSelected('vendor')
                          setTimeout(() => {
                            setRefreshData(new Date())
                          }, 300)
                        }}
                        labelledBy='Vendor'
                        overrideStrings={{
                          allItemsAreSelected: 'All Vendors',
                        }}
                        disabled={
                          searchList?.trim()?.length > 0 || searchVendor?.trim()?.length > 0
                        }
                        valueRenderer={CustomValueRenderer}
                      />
                    </div>
                  </Col>
                </div>
              </div>
            </div>

            <div className='row mt-3 mx-0' style={{borderTop: '2px solid lightgrey'}}></div>

            {result?.map((res: any, ind: any) => {
              if (res?.check == true) totalSelected = totalSelected + 1
              return <></>
            })}

            <div className='row mt-3'>
              <div className='col-12 pe-0'>
                <div className=''>
                  <div className='table-responsive' style={{maxHeight: '380px', overflow: 'auto'}}>
                    <table className='table align-middle  fs-6 gy-5' id='kt_ecommerce_sales_table'>
                      <thead>
                        <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                          <th className='text-start w-25px test'></th>
                          <th className='text-start min-w-100px'>Vendor</th>
                          <th className='text-start min-w-100px'>List</th>
                        </tr>
                      </thead>

                      <tbody className='fw-semibold head-text'> {/* className='fw-semibold text-gray-600'*/ }
                        {result?.length > 0 ? (
                          <>
                            {result?.map((rs: any, index: any) => {
                              return (
                                <tr>
                                  <td className='text-start w-25px  test'>
                                    {radioChecked === rs?._id ? (
                                      <div
                                        style={{
                                          height: '16px',
                                          width: '16px',
                                          backgroundColor: '#146c6a',
                                          borderRadius: '50%',
                                        }}
                                        className='me-5'
                                      ></div>
                                    ) : (
                                      <div
                                        style={{
                                          height: '16px',
                                          width: '16px',
                                          backgroundColor: 'transparent',
                                          borderRadius: '50%',
                                          border: '1px solid #146c6a',
                                        }}
                                        onClick={() => {
                                          setRadioChecked(rs?._id)
                                          setSelectedService(rs)
                                          // setSelectedName(rs?.name)
                                        }}
                                        className='me-5'
                                      ></div>
                                    )}
                                  </td>
                                  <td className='text-start min-w-100px'>
                                    {rs?.companyName ? rs?.companyName : '-'}
                                  </td>
                                  <td className='text-start min-w-100px'>
                                    <div className='d-flex align-items-center'>
                                      {rs?.vendor_list?.length > 0 && (
                                        <div
                                          className='bullet-div-sm me-3 '
                                          style={{
                                            backgroundColor: `${rs?.vendor_list?.[0]?.colorCode}`,
                                          }}
                                        ></div>
                                      )}
                                      {rs?.vendor_list?.[0]?.name
                                        ? rs?.vendor_list?.[0]?.name
                                        : '-'}
                                      {rs?.vendor_list?.length > 1 && (
                                        <img
                                          src={eye}
                                          height={18}
                                          width={18}
                                          className='ms-5 cursor-pointer me-3'
                                          onClick={() => {
                                            if (eyeInd == index) setEyeInd(-1)
                                            else setEyeInd(index)
                                          }}
                                        />
                                      )}
                                    </div>

                                    {index == eyeInd && (
                                      <div style={{height: '0px'}}>
                                        <div className='card p-3 status-w-225 mt-3 vend-lists mb-7'>
                                          {rs?.vendor_list?.map((vl: any, ind: any) => {
                                            return (
                                              <div className='d-flex align-items-baseline '>
                                                <div
                                                  className='bullet-div-sm me-3 '
                                                  style={{backgroundColor: `${vl?.colorCode}`}}
                                                ></div>
                                                {vl?.name ? vl?.name : '-'}
                                              </div>
                                            )
                                          })}
                                        </div>
                                      </div>
                                    )}
                                  </td>
                                </tr>
                              )
                            })}
                          </>
                        ) : (
                          <tr>
                            {!isLoading ? (
                              <td colSpan={3} className='text-center'>
                                <img src={noData} alt='' width={350} />
                              </td>
                            ) : (
                              <td colSpan={3} className='text-center'>
                                <div
                                  className='d-flex justify-content-center align-items-center w-100'
                                  style={{height: '25vh'}}
                                >
                                  <CircularProgress style={{color: '#007a59'}} />
                                </div>
                              </td>
                            )}
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Row>
      </Modal>
    </div>
  )
}

export default SelectVendorModal
