import React, {useEffect, useState, useRef} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {
  ApiGet,
  ApiGetNoAuth,
  ApiPost,
  ApiPut,
  ApiUpload,
  Bucket,
  ApiDelete,
} from '../../../apiCommon/helpers/API/ApiData'
import {ErrorToast, SuccessToast} from '../../../apiCommon/helpers/Toast'
import swal from 'sweetalert2'
import 'antd/dist/antd.css'
import moment from 'moment'
import noData from '../../../img/NoData1.svg'
import {Button, Spinner} from 'react-bootstrap'
import ReactPaginate from 'react-paginate'
import backArrow from '../../../img/back-arrow.png'
import redCross from '../../../img/remove.png'
import trashImg from '../../../img/trash.png'
import Select from 'react-select'
import './styles.scss'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import plannedImg from '../../../img/planned.png'
import preventativeImg from '../../../img/preventative.png'
import reactiveImg from '../../../img/reactive.png'
import SelectServiceModal from './SelectServiceModal'
import SelectVendorModal from './SelectVendorModal'
import AddJobsModal from './AddJobsModal'
import Schedule from './Schedule'
import JobDetailsModal from './JobDetailsModal'
import internalTeamGreen from '../../../img/internal-team-green.png'
import externalTeamGreen from '../../../img/external-team-green.png'
import {Modal} from 'react-bootstrap'
import tick from '../../../img/tick-white.png'
import starYellow from '../../../img/starnotification.svg'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {AiOutlineExclamationCircle} from 'react-icons/ai'
import uploadFileWhite from '../../../img/upload-file-white.png'
import pdfIcon from '../../../img/pdf.svg'
import correct from '../../../img/correct.png'
import removed from '../../../img/remove-filled.png'
import SingleFileLoader from '../General/SingleFileLoader'
import GeneratePOModal from './GeneratePOPopup'
import {Fancybox as fancybox} from '@fancyapps/ui'
import '@fancyapps/ui/dist/fancybox/fancybox.css'
import pencil from '../../../img/pen.png'

const ViewEditRequest = () => {
  const {flg, type, id, tenancyId, unitId} = useParams()

  const userData: any = localStorage.getItem('userData')
  const user = JSON.parse(userData)

  const roleAndPermissions: any = localStorage.getItem('roleAndPermissions')
  const role = JSON.parse(roleAndPermissions)

  const [open, setOpen] = React.useState(false)
  let totalSelected: any = 0

  const [isFormDirty, setIsFormDirty] = useState(false)
  const dispatch = useDispatch()

  const [selectedService, setSelectedService] = useState<any>()
  const [selectedVendor, setSelectedVendor] = useState<any>()

  const [selectedVendorId, setSelectedVendorId] = useState<any>('')

  // React Select
  const customStyles = {
    container: (defaultStyles: any) => ({
      ...defaultStyles,
      width: '100%',
    }),

    option: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      color: state.isSelected ? '#000' : '#000',
      backgroundColor: state.isSelected ? '#c2d8d9' : '',
      ':hover': {
        backgroundColor: '#e0f5f0',
        color: '#000',
      },

      fontWeight: '500',
    }),

    menu: (defaultStyles: any) => ({
      ...defaultStyles,
      border: '0.1rem solid #007a59 ',
      marginTop: '0px',
      borderRadius: '0px',
      zIndex: '2',
    }),
    menuList: (defaultStyles: any) => ({
      ...defaultStyles,
      maxHeight: '200px',
    }),
    placeholder: (defaultStyles: any) => ({
      ...defaultStyles,
      fontStyle: 'Italic',
      zIndex: '1',
    }),

    input: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      color: ' #b5b5c3',
      fontWeight: '500',
    }),

    control: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      boxShadow: 'none',
      color: 'white',
      border: '0.1rem solid #bec3cb',
      borderRadius: '2px',
      padding: '0rem',
      height: '35px',
      ':hover': {
        borderColor: '#007a59',
        backgroundColor: '#fff',
      },
      ':focus': {
        borderColor: '#007a59 !important',
        backgroundColor: '#fff',
      },
      '::placeholder': {
        fontStyle: 'Italic',
      },
    }),
    singleValue: (defaultStyles: any) => ({
      ...defaultStyles,
      color: '#5e6278',
      fontWeight: '600',
      zIndex: '1',
    }),
    valueContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: '0px 8px 2px 8px',
    }),
  }

  // React Select
  const customStylesApprove = {
    container: (defaultStyles: any) => ({
      ...defaultStyles,
      width: '100%',
    }),

    option: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      color: state.isSelected ? '#000' : '#000',
      backgroundColor: state.isSelected ? '#c2d8d9' : '',
      ':hover': {
        backgroundColor: '#e0f5f0',
        color: '#000',
      },

      fontWeight: '500',
    }),

    menu: (defaultStyles: any) => ({
      ...defaultStyles,
      border: '0.1rem solid #007a59 ',
      marginTop: '0px',
      borderRadius: '0px',
      zIndex: '2',
      width: '200px',
    }),
    menuList: (defaultStyles: any) => ({
      ...defaultStyles,
      maxHeight: '200px',
    }),
    placeholder: (defaultStyles: any) => ({
      ...defaultStyles,
      fontStyle: 'Italic',
      zIndex: '1',
    }),

    input: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      color: ' #b5b5c3',
      fontWeight: '500',
    }),

    control: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      width: '200px',
      boxShadow: 'none',
      color: 'white',
      border: '0.1rem solid #bec3cb',
      borderRadius: '2px',
      padding: '0rem',
      height: '31px',
      ':hover': {
        borderColor: '#007a59',
        backgroundColor: '#fff',
      },
      ':focus': {
        borderColor: '#007a59 !important',
        backgroundColor: '#fff',
      },
      '::placeholder': {
        fontStyle: 'Italic',
      },
      minHeight: '31px',
    }),
    singleValue: (defaultStyles: any) => ({
      ...defaultStyles,
      color: '#5e6278',
      fontWeight: '600',
      zIndex: '1',
    }),
    valueContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: '0px 8px 2px 8px',
    }),
    indicatorContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: '0px 8px 2px 8px',
    }),
    indicatorsContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: '0px 8px 2px 8px',
    }),
  }

  const {state} = useLocation()
  const navigate = useNavigate()

  const [isSkeleton, setIsSkeleton] = useState<any>(false)
  const [activeTab, setActiveTab] = useState<any>('review')

  const [requestData, setRequestData] = useState<any>({
    maintenanceMainServiceId: '',
    maintenanceSubServiceId: '',
  })

  const [isLoading, setIsLoading] = useState<any>()
  const [sendError, setSendError] = useState<any>(false)

  setTimeout(() => {
    setIsLoading(false)
  }, 5000)

  setTimeout(() => {
    setOpen(false)
  }, 10000)

  useEffect(() => {
    setIsSkeleton(true)
  }, [])

  //
  const deleteRequest = () => {
    setIsLoading(true)
    let ids = [id]
    const body = {
      ids: ids,
    }
    ApiPost(`corporate/maintenance_service_request/delete`, body)
      .then((response: any) => {
        SuccessToast(response?.data?.message)
        navigate('/maintenance')
        setIsLoading(false)
      })
      .catch((error: any) => {
        setOpen(false)
        setIsLoading(false)
        ErrorToast(error?.message)
      })
  }

  const [isSaveFirst, setIsSaveFirst] = useState<any>(false)
  const [autoSaveData, setAutoSaveData] = useState<any>(false)
  const [approvalWorkflowCard, setApprovalWorkflowCard] = useState<any>(false)

  useEffect(() => {
    if (isSaveFirst == true) {
      getRequestById()
      getApprovalDetails()

      setIsSaveFirst(false)
      setTimeout(() => {
        setOpen(false)
      }, 1000)
    }
  }, [isSaveFirst])

  useEffect(() => {
    if (autoSaveData != false && flg == '2') {
      setOpen(true)
      addRequest('', 0)
    }
  }, [autoSaveData])

  const [hideApproval, setHideApproval] = useState<any>(false)
  const [approvalUsers, setApprovalUsers] = useState<any>([])
  const [selectedApprover, setSelectedApprover] = useState<any>(null)
  const [approvalDropdownUsers, setApprovalDropdownUsers] = useState<any>([])
  const [approvalComments, setApprovalComments] = useState<any>('')
  const [isViewMode, setIsViewMode] = useState<any>(false)
  const [financeDropdownUsers, setFinanceDropdownUsers] = useState<any>([])
  const [isSubmitToApprovar, setIsSubmitToApprovar] = useState<any>(false)
  const [isSubmitToFinance, setIsSubmitToFinance] = useState<any>(false)
  const [showApprovarDropdown, setShowApprovarDropdown] = useState<any>(false)
  const [showFinanceDropdown, setShowFinanceDropdown] = useState<any>(false)
  const [leasingDropdownUsers, setLeasingDropdownUsers] = useState<any>([])

  const getViewMode = () => {
    if (user?._id === requestData?.createdBy) {
      setIsViewMode(false)
    } else {
      if (approvalUsers[approvalUsers?.length - 1]?.createdBy?.[0]?._id !== user?._id) {
        setIsViewMode(true)
      } else setIsViewMode(false)
    }
  }

  const [lastApproverId, setLastApproverId] = useState<any>()
  const [isAssign, setIsAssign] = useState<any>(false)
  const [assignData, setAssignData] = useState<any>()
  const [requestWithApproval, setRequestWithApproval] = useState<any>()
  const [showLock, setShowLock] = useState<any>(false)

  //
  const getApprovalDetails = () => {
    const body = {
      maintenanceServiceRequestId: id,
    }
    ApiPost(`corporate/approval_workflow/maintenance/get`, body)
      .then((res: any) => {
        setApprovalUsers(res?.data?.data)

        for (let i = res?.data?.data?.length - 2; i >= 0; i--) {
          if (res?.data?.data?.[i]?.approvalStatus !== 5) {
            setLastApproverId(res?.data?.data?.[i]?.createdBy?.[0]?._id)
            //  console.log(res?.data?.data?.[i]?.createdBy?.[0]?._id)
            break
          }
        }
      })
      .catch((err: any) => {})
  }

  //
  useEffect(() => {
    getApprovalDetails()
  }, [])

  useEffect(() => {
    if (flg === '2') getViewMode()
  }, [approvalUsers, requestData])

  //
  const approvalFlowSubmission = (event: any) => {
    let newServiceJobs: any = []

    jobs?.map((job: any, ind: any) => {
      newServiceJobs[ind] = {
        maintenanceJobStatus: job?.maintenanceJobStatus ? job?.maintenanceJobStatus : 0,
        jobUrgency: job?.jobUrgency,
        schedule: job?.schedule,
        comments: job?.comments,
        attachments: job?.attachments,
        communityId: job?.community?.length > 0 ? job?.community?.[0]?._id : null,
        clusterId: job?.cluster?.length > 0 ? job?.cluster?.[0]?._id : null,
        tenantId: null,
        buildingId: job?.building?.length > 0 ? job?.building?.[0]?._id : null,
        floorId: job?.floor?.length > 0 ? job?.floor?.[0]?._id : null,
        unitGroupId: job?.unit_group?.length > 0 ? job?.unit_group?.[0]?._id : null,
        unitId: job?._id ? job?._id : null,
      }
    })

    const body = {
      serviceRequest: {
        _id: flg == '2' ? id : undefined,
        requestorType: 0,
        saveStatus: 0,
        maintenanceRequestType: type == 'reactive' ? 0 : type == 'planned' ? 1 : 2,
        maintenanceMainServiceId: selectedService?.maintenance_main_service?._id,
        maintenanceSubServiceId: selectedService?._id,
        buildingId: requestData?.building?.value,
        communityId: requestData?.community?.value,
        staffId: null,
        vendorId: requestData?.handlerType == 1 ? selectedVendor?._id : null,
        subWorkflowListId: null,
        handlerType: requestData?.handlerType,
        description: requestData?.description,
      },
      newServiceJobs: newServiceJobs,
    }

    ApiPost(`corporate/maintenance_service_request/all_in_one`, body)
      .then((res) => {
        if (flg == '1') {
          const body = {
            maintenanceServiceRequestId: res?.data?.data?._id,
          }
          ApiPost(`corporate/approval_workflow/maintenance/get`, body)
            .then((res: any) => {
              setApprovalUsers(res?.data?.data)

              const body = {
                id: res?.data?.data[res?.data?.data?.length - 1]?._id,
                type: 0,
                tenancyWorkFlowType: 0,
                approvalStatus:
                  approvalUsers[approvalUsers?.length - 1]?.approvalStatus === 2 ? 3 : 1,
                comment: approvalComments,
                approvalUserId: selectedApprover,
                maintenanceSubmitterId: '',
              }
              ApiPost(`corporate/approval_workflow/maintenance/submission`, body)
                .then((res: any) => {
                  SuccessToast(res?.data?.message)
                  navigate('/maintenance')
                })
                .catch((err: any) => {})
            })
            .catch((err: any) => {})
        } else {
          const body = {
            id: approvalUsers[approvalUsers?.length - 1]?._id,
            type: 1,
            tenancyWorkFlowType: 0,
            approvalStatus: approvalUsers[approvalUsers?.length - 1]?.approvalStatus === 2 ? 3 : 1,
            comment: approvalComments,
            approvalUserId: selectedApprover,
            maintenanceSubmitterId: '',
          }
          ApiPost(`corporate/approval_workflow/maintenance/submission`, body)
            .then((res: any) => {
              SuccessToast(res?.data?.message)
              navigate('/maintenance')
            })
            .catch((err: any) => {})
        }

        setIsLoading(false)
      })
      .catch((err) => {
        setOpen(false)
        setIsLoading(false)
        ErrorToast(err.message)
      })
  }

  //
  const sendBack = () => {
    setIsLoading(true)
    const body = {
      approvalWorkflowId: approvalUsers[approvalUsers?.length - 1]?._id,
      comment: approvalComments,
    }
    ApiPost(`corporate/approval_workflow/maintenance/sent_back`, body)
      .then((res: any) => {
        SuccessToast(res?.data?.message)
        navigate('/maintenance')
        setIsLoading(false)
      })
      .catch((err) => {
        setIsLoading(false)
        ErrorToast(err.message)
      })
  }

  //
  const reAssign = () => {
    setIsLoading(true)
    const body = {
      approvalWorkflowId: assignData?._id,
      approvalUserId: selectedApprover,
    }
    ApiPost(`corporate/approval_workflow/maintenance/reassign`, body)
      .then((res: any) => {
        SuccessToast(res?.data?.message)
        navigate('/maintenance')
        setIsLoading(false)
      })
      .catch((err) => {
        setIsLoading(false)
        ErrorToast(err.message)
      })
  }

  const getWorkFlowListByName = () => {
    let name: any = ''
    if (type == 'reactive') name = 'Reactive Maintenance'

    ApiGet(`corporate/sub_workflow_list/get?name=${name}`)
      .then((res: any) => {
        setRequestWithApproval(res?.data?.data?.isApprovalEnabled)
        // if (
        //   (requestData?.isApprovalEnabled &&
        //     !res?.data?.data?.isApprovalEnabled &&
        //     approvalUsers[approvalUsers?.length - 1]?.approvalStatus === 0) ||
        //   requestData?.approval_workflow?.[0]?.approvalStatus === 6
        // ) {
        //   setShowLock(true)
        //   setIsViewMode(true)
        // }
      })
      .catch((err: any) => {})
  }

  useEffect(() => {
    if (flg == '1') getWorkFlowListByName()
  }, [])

  const isViewOnly =
    approvalUsers[approvalUsers?.length - 1]?.createdBy?.[0]?._id != user?._id && flg === '2'

  const isInitiator =
    user?._id == requestData?.createdBy &&
    user?._id !== approvalUsers[approvalUsers?.length - 1]?.createdBy?.[0]?._id

  const [showSelectService, setShowSelectService] = useState<any>(false)
  const [showSelectVendor, setShowSelectVendor] = useState<any>(false)
  const [showSelectJob, setShowSelectJob] = useState<any>(false)
  const [showSchedule, setShowSchedule] = useState<any>(false)

  const [scheduleToAddEdit, setScheduleToAddEdit] = useState<any>('')
  const [scheduleIndToAddEdit, setScheduleIndToAddEdit] = useState<any>(-1)

  const [showJobDetails, setShowJobDetails] = useState<any>(false)
  const [jobsToAddEdit, setJobsToAddEdit] = useState<any>('')
  const [jobsIndToAddEdit, setJobsIndToAddEdit] = useState<any>(-1)

  const [jobs, setJobs] = useState<any>([])
  const [requestDetails, setRequestDetails] = useState<any>()

  //
  const addRequest = (e: any, isDraft: any) => {
    let newServiceJobs: any = []
    let updateServiceJobs: any = []

    let newInd: any = 0
    let updInd: any = 0
    jobs?.map((job: any, ind: any) => {
      if (job?.jobId == null) {
        newServiceJobs[newInd] = {
          maintenanceJobStatus: job?.maintenanceJobStatus ? job?.maintenanceJobStatus : 0,
          jobUrgency: job?.jobUrgency,
          schedule: job?.schedule,
          comments: job?.comments,
          attachments: job?.attachments,
          communityId: job?.community?.length > 0 ? job?.community?.[0]?._id : null,
          clusterId: job?.cluster?.length > 0 ? job?.cluster?.[0]?._id : null,
          tenantId: null,
          buildingId: job?.building?.length > 0 ? job?.building?.[0]?._id : null,
          floorId: job?.floor?.length > 0 ? job?.floor?.[0]?._id : null,
          unitGroupId: job?.unit_group?.length > 0 ? job?.unit_group?.[0]?._id : null,
          unitId: job?._id ? job?._id : null,
        }
        newInd++
      } else {
        updateServiceJobs[updInd] = {
          _id: job?.jobId,
          maintenanceJobStatus: job?.maintenanceJobStatus ? job?.maintenanceJobStatus : 0,
          jobUrgency: job?.jobUrgency,
          schedule: job?.schedule,
          comments: job?.comments,
          attachments: job?.attachments,
          communityId: job?.community?.length > 0 ? job?.community?.[0]?._id : null,
          clusterId: job?.cluster?.length > 0 ? job?.cluster?.[0]?._id : null,
          tenantId: null,
          buildingId: job?.building?.length > 0 ? job?.building?.[0]?._id : null,
          floorId: job?.floor?.length > 0 ? job?.floor?.[0]?._id : null,
          unitGroupId: job?.unit_group?.length > 0 ? job?.unit_group?.[0]?._id : null,
          unitId: job?._id ? job?._id : null,
        }
        updInd++
      }
    })

    const body = {
      serviceRequest: {
        _id: flg == '2' ? id : undefined,
        requestorType: 0,
        saveStatus: isDraft,
        maintenanceRequestType: type == 'reactive' ? 0 : type == 'planned' ? 1 : 2,
        maintenanceMainServiceId: selectedService?.maintenance_main_service?._id,
        maintenanceSubServiceId: selectedService?._id,
        buildingId: requestData?.building?.value,
        communityId: requestData?.community?.value,
        staffId: null,
        vendorId: requestData?.handlerType == 1 ? selectedVendor?._id : null,
        subWorkflowListId: null,
        handlerType: requestData?.handlerType,
        description: requestData?.description,
      },
      newServiceJobs: newServiceJobs,
      updateServiceJobs: updateServiceJobs,
    }

    ApiPost(`corporate/maintenance_service_request/all_in_one`, body)
      .then((res: any) => {
        if (flg == '1') {
          if (isDraft == 1) {
            SuccessToast(res?.data?.message)
            navigate('/maintenance')
          } else navigate(`/maintenance/create-request/reactive/2/${res?.data?.data?._id}`)
        } else {
          if (isDraft == 1) {
            SuccessToast(res?.data?.message)
            navigate('/maintenance')
          }
        }

        setTimeout(() => {
          setIsSaveFirst(true)
        }, 1000)

        setTimeout(() => {
          setOpen(false)
          setIsLoading(false)
        }, 1500)
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
        setOpen(false)
        setIsLoading(false)
      })
  }

  //
  const getVendorById = (val: any) => {
    ApiGet(`corporate/vendor/${val}`)
      .then((res: any) => {
        setSelectedVendor(res?.data?.data)
        setShowSelectVendor(false)
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
      })
  }

  const [generatePOEnabledCurrent, setGeneratePOEnabledCurrent] = useState<any>(false)

  //
  const getPODetails = () => {
    ApiGet('corporate/corporate_setting')
      .then((res) => {
        setGeneratePOEnabledCurrent(res?.data?.data?.purchaseOrderCompany?.isPurchaseOrderActive)
      })
      .catch((err) => {
        console.log('err', err)
      })
  }

  //
  const getRequestById = () => {
    ApiGet(`corporate/maintenance_service_request/${id}`)
      .then((res: any) => {
        setRequestDetails(res?.data?.data)
        let values = {
          building:
            res?.data?.data?.maintenance_service_job?.[0]?.building?.length > 0
              ? {
                  value: res?.data?.data?.maintenance_service_job?.[0]?.building?.[0]?._id,
                  label: res?.data?.data?.maintenance_service_job?.[0]?.building?.[0]?.name,
                }
              : undefined,
          community:
            res?.data?.data?.maintenance_service_job?.[0]?.community?.length > 0
              ? {
                  value: res?.data?.data?.maintenance_service_job?.[0]?.community?.[0]?._id,
                  label: res?.data?.data?.maintenance_service_job?.[0]?.community?.[0]?.name,
                }
              : null,
          staffId: res?.data?.data?.staffId ? res?.data?.data?.staffId : null,
          vendorId: res?.data?.data?.vendor?.[0]?._id ? res?.data?.data?.vendor?.[0]?._id : null,
          subWorkflowListId: null,
          handlerType: res?.data?.data?.handlerType,
          description: res?.data?.data?.description,
          createdBy: res?.data?.data?.createdBy?._id,
          saveStatus: res?.data?.data?.saveStatus,
        }

        setRequestData(values)

        setAddedPOs(res?.data?.data?.maintenance_purchase_order)

        let valuesPOs = [...res?.data?.data?.purchaseOrderList]
        valuesPOs?.map((vl:any, ind:any)=>{
          valuesPOs[ind].isEdit = false
        })

        setUploadedPOs(valuesPOs)

        setRequestWithPO(res?.data?.data?.isPurchaseOrderEnabled)
        setGeneratePOEnabled(res?.data?.data?.isPurchaseOrderAutoGenerateEnabled)

        if (!res?.data?.data?.isPurchaseOrderAutoGenerateEnabled) {
          getPODetails()
        }

        setSelectedVendor(res?.data?.data?.vendor?.[0])

        // if (res?.data?.data?.vendor?.[0]?._id != undefined)
        //   getVendorsDetails(res?.data?.data?.vendor?.[0]?._id)

        // if (res?.data?.data?.maintenance_sub_service?.[0]?._id)
        //   getServiceDetails(res?.data?.data?.maintenance_sub_service?.[0]?._id)

        let serviceVal : any = {
          name: res?.data?.data?.maintenance_sub_service?.[0]?.name,
          maintenance_main_service: res?.data?.data?.maintenance_main_service?.[0],
          _id: res?.data?.data?.maintenance_sub_service?.[0]?._id
        }

        setSelectedService(serviceVal)
        
        setJobs(res?.data?.data?.maintenance_service_job)

        setIsLoading(false)
        setIsSkeleton(false)
        setOpen(false)
      })
      .catch((err: any) => {
        setOpen(false)
        ErrorToast(err?.message)
        setIsLoading(false)
        setIsSkeleton(false)
      })
  }

  //
  const getVendorsDetails = async (id: any) => {
    const body = {
      page: 1,
      limit: 50,
      searchVendorList: '',
      searchVendor: '',
      vendorListIds: '',
      vendorIds: '',
    }
    await ApiPost(`corporate/vendor/get/maintenance`, body)
      .then((res) => {
        res?.data?.data?.vendor_data?.map((data: any, ind: any) => {
          if (data?._id == id) {
            getVendorById(id)
          }
        })
      })
      .catch((e) => {
        setIsLoading(false)
        ErrorToast(e?.message)
      })
  }

  //
  const getServiceDetails = async (id: any) => {
    const body = {
      listSearch: '',
      mainServiceSearch: '',
      subServiceSearch: '',
      maintenanceServiceListIds: [],
      maintenanceMainServiceIds: [],
      maintenanceSubServiceIds: [],
    }
    await ApiPost(`corporate/maintenance_sub_service/get`, body)
      .then((res) => {
        res?.data?.data?.maintenance_sub_service_data?.map((data: any, ind: any) => {
          if (data?._id == id) setSelectedService(data)
        })

        setIsLoading(false)
      })
      .catch((e) => {
        setIsLoading(false)
        ErrorToast(e?.message)
      })
  }

  const getServiceJobs = async (data: any) => {
    const body = {
      searchDevelopment: '',
      unitSearch: '',
      developmentIds: [],
      clusterIds: [],
      unitGroupIds: [],
      floorIds: [],
      unitIds: [],
    }
    await ApiPost(`corporate/maintenance_service_request/get/development`, body)
      .then((res) => {
        let values: any = []
        let vlInd: any = 0
        // res?.data?.data?.map((res: any, ind: any) => {
        //   values[ind].check = false
        // })

        data?.map((dt: any, ind: any) => {
          res?.data?.data?.map((rs: any, index: any) => {
            if (dt?.unit?.[0]?._id == rs?._id) {
              values[vlInd] = rs
              values[vlInd].check = false
              values[vlInd].jobId = dt?._id
              values[vlInd].jobIdNo = dt?.id

              vlInd++
            }
          })
        })

        setJobs(values)

        setIsLoading(false)
      })
      .catch((e) => {
        setIsLoading(false)
        ErrorToast(e?.message)
      })
  }

  useEffect(() => {
    getRequestById()
  }, [])

  const goToRequest = (rq: any) => {
    navigate(`/maintenance/request/${id}/job/${rq?._id}`)
  }

  const [dragActive, setDragActive] = React.useState(false)
  const inputRef = React.useRef(null)

  // handle drag events
  const handleDrag = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true)
    } else if (e.type === 'dragleave') {
      setDragActive(false)
    }
  }

  // triggers when file is dropped
  const handleDrop = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    setDragActive(false)
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      // handleFiles(e.dataTransfer.files);
    }
  }

  const [showLoader, setShowLoader] = useState<boolean>(false)
  const [showGeneratePOModel, setShowGeneratePOModel] = useState<any>(false)
  const [requestWithPO, setRequestWithPO] = useState<any>(false)
  const [generatePOEnabled, setGeneratePOEnabled] = useState<any>(false)
  const [addedPOs, setAddedPOs] = useState<any>([])
  const [uploadedPOs, setUploadedPOs] = useState<any>([
    // {
    //   documentName: '',
    //   purchaseOrderNo: '',
    //   totalAmount: 0,
    //   singedCopyURL: '',
    //   pdfURL: '',
    // },
  ])

  const handleAddUploadedPOs = (type: any) => {
    const values = [...uploadedPOs]
    values.push({
      documentName: '',
      purchaseOrderNo: '',
      totalAmount: '',
      singedURL: '',
      pdfURL: '',
      vendorName: type == 1 ? selectedVendor?.companyName : '',
      maintenancePurchaseOrderType: type,
      createdAt: '',
      isEdit: true,
    })
    setUploadedPOs(values)
    setShowGeneratePOModel(false)
  }

  const deletePO = (val: any) => {
    setIsLoading(true)

    const body = {
      serviceRequest: {
        _id: id,
      },
      newPurchaseOrder: [],
      updatePurchaseOrder: [],
      deletePurchaseOrder: [{_id: val}],
    }

    ApiPost(`corporate/maintenance_service_request/all_in_one`, body)
      .then((res: any) => {
        SuccessToast('Purchase Order has been deleted successfully...')

        getRequestById()
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
        setOpen(false)
        setIsLoading(false)
      })
  }

  //
  const handleUploadSignedCopies = async (e: any, ind: any, pO: any) => {
    if (e.target.files && e.target.files[0]) {
      if (e.target.files[0]?.type === 'application/pdf') {
        if (flg == '2') setOpen(true)
        setShowLoader(true)

        var formdata = new FormData()
        formdata.append('image', e.target.files[0])

        let userData: any = localStorage.getItem('userData')
        const data = JSON.parse(userData)

        let name: any = e.target.files[0]?.name

        await ApiPost(
          `upload/image/params?fileType=maintenance_service_job&databaseId=${data._id}&fileName=${name}`,
          formdata
        )
          .then((res) => {
            let values = [...addedPOs]
            values[ind].singedURL = res?.data?.data?.image

            setAddedPOs(values)

            const body = {
              serviceRequest: {
                _id: id,
              },

              updatePurchaseOrder: [
                {
                  _id: pO?._id,
                  singedURL: res?.data?.data?.image,
                },
              ],
            }

            ApiPost(`corporate/maintenance_service_request/all_in_one`, body)
              .then((res: any) => {
                SuccessToast('Signed Copy has been uploaded successfully..')
                getRequestById()
                setShowLoader(false)
              })
              .catch((err: any) => {
                ErrorToast(err?.message)
                setOpen(false)
                setShowLoader(false)
              })
          })
          .catch((err) => {
            ErrorToast(err?.message)
            setShowLoader(false)
          })
      } else {
        ErrorToast('Files other than PDF are not allowed..')
      }
    }
  }

  //
  const handleUploadPO = async (e: any, ind: any) => {
    if (e.target.files && e.target.files[0]) {
      if (e.target.files[0]?.type === 'application/pdf') {
        setShowLoader(true)
        var formdata = new FormData()
        formdata.append('image', e.target.files[0])

        let userData: any = localStorage.getItem('userData')
        const data = JSON.parse(userData)

        let name: any = e.target.files[0]?.name

        await ApiPost(
          `upload/image/params?fileType=maintenance_service_job&databaseId=${data._id}&fileName=${name}`,
          formdata
        )
          .then((res) => {
            let values = [...uploadedPOs]
            values[ind].pdfURL = res?.data?.data?.image

            values[ind].isEdit = true
            values[ind].documentName = res?.data?.data?.image?.split('/').pop()

            setUploadedPOs(values)

            setShowLoader(false)
          })
          .catch((err) => {
            setShowLoader(false)
            ErrorToast(err?.message)
          })
      } else {
        ErrorToast('Files other than PDF are not allowed..')
      }
    }
  }

  //
  const deletePOSignedCopy = (pO: any, isUpload: any, ind: any) => {
    setIsLoading(true)

    let values: any = []
    if (isUpload) values = [...uploadedPOs]
    else values = [...addedPOs]

    values[ind].singedURL = ''

    values?.map((vl: any, ind: any) => {
      values[ind].isEdit = undefined
    })

    let body: any

    if (isUpload) {
      body = {
        serviceRequest: {
          _id: id,
          purchaseOrderList: values,
        },
      }
    } else {
      body = {
        serviceRequest: {
          _id: id,
        },

        updatePurchaseOrder: [
          {
            _id: pO?._id,
            singedURL: '',
          },
        ],
      }
    }

    ApiPost(`corporate/maintenance_service_request/all_in_one`, body)
      .then((res: any) => {
        SuccessToast('Signed Copy has been deleted successfully...')
        getRequestById()
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
        setOpen(false)
        setIsLoading(false)
      })
  }

  //
  const addUpdatePOs = () => {
    setIsLoading(true)

    let values = [...uploadedPOs]
    values?.map((vl: any, ind: any) => {
      values[ind].isEdit = undefined
      values[ind].createdAt = new Date()
    })

    if (flg == '1') setUploadedPOs(values)
    else {
      setOpen(true)
      const body = {
        serviceRequest: {
          _id: id,
          purchaseOrderList: values,
        },
      }

      ApiPost(`corporate/maintenance_service_request/all_in_one`, body)
        .then((res: any) => {
          getRequestById()
        })
        .catch((err: any) => {
          ErrorToast(err?.message)
          setOpen(false)
          setIsLoading(false)
        })
    }
  }

  //
  const deleteUploadPO = (val: any, ind: any) => {
    setIsLoading(true)

    let values = [...uploadedPOs]
    values.splice(ind, 1)
    if (flg == '1') setUploadedPOs(values)
    else {
      setOpen(true)

      const body = {
        serviceRequest: {
          _id: id,
          purchaseOrderList: values,
        },
      }

      ApiPost(`corporate/maintenance_service_request/all_in_one`, body)
        .then((res: any) => {
          getRequestById()
        })
        .catch((err: any) => {
          ErrorToast(err?.message)
          setOpen(false)
          setIsLoading(false)
        })
    }
  }

  //
  const handleUploadSignedCopiesOfUploadedPO = async (e: any, ind: any, pO: any) => {
    if (e.target.files && e.target.files[0]) {
      if (e.target.files[0]?.type === 'application/pdf') {
        setShowLoader(true)
        if (flg == '2') setOpen(true)

        var formdata = new FormData()
        formdata.append('image', e.target.files[0])

        let userData: any = localStorage.getItem('userData')
        const data = JSON.parse(userData)

        let name: any = e.target.files[0]?.name

        await ApiPost(
          `upload/image/params?fileType=maintenance_service_job&databaseId=${data._id}&fileName=${name}`,
          formdata
        )
          .then((res) => {
            let values = [...uploadedPOs]
            values[ind].singedURL = res?.data?.data?.image

            setUploadedPOs(values)

            if (flg == '2') {
              values?.map((vl: any) => {
                vl.isEdit = undefined
              })

              const body = {
                serviceRequest: {
                  _id: id,
                  purchaseOrderList: values,
                },
              }
              ApiPost(`corporate/maintenance_service_request/all_in_one`, body)
                .then((res: any) => {
                  getRequestById()
                })
                .catch((err: any) => {
                  ErrorToast(err?.message)
                  setOpen(false)
                })
            }

            setShowLoader(false)
          })
          .catch((err) => {
            ErrorToast(err?.message)
            setShowLoader(false)
          })
      } else {
        ErrorToast('Files other than PDF are not allowed..')
      }
    }
  }

  let showCluster: any = false
  let showFloor: any = false
  let showUnitGroup: any = false
  let showBuilding: any = false

  //
  const cancelRequest = () => {
    const body = {
      ids: [id],
    }

    ApiPost(`corporate/maintenance_service_request/cancel`, body)
      .then((res: any) => {
        SuccessToast('Maintenance Request has been cancelled successfully...')
        navigate('/maintenance')
      })
      .catch((err: any) => {
        setOpen(false)
        ErrorToast(err?.message)
      })
  }

  const handleImageClick = (val: any, img: any) => {
    const groupName = `group_${val}`

    // Add data-fancybox attribute to the clicked image
    const image = document.getElementById(val)
    if (image) {
      image.setAttribute('data-fancybox', groupName)
      image.setAttribute('data-src', img)
    }
  }

  useEffect(() => {
    fancybox.bind('[data-fancybox]', {
      Toolbar: {
        display: {
          left: ['infobar'],
          middle: ['zoomIn', 'zoomOut', 'toggle1to1', 'rotateCCW', 'rotateCW', 'flipX', 'flipY'],
          right: ['slideshow', 'thumbs', 'close'],
        },
      },
    })

    return () => {
      fancybox.destroy();
    };
  }, []);


  return (
    <>
      {!isSkeleton ? (
        <div className='app-main flex-column flex-row-fluid px-2' id='kt_app_main'>
          <div id='' className='pt-0 mt-0'>
            {' '}
            {/* Header */}
            <div className='page-title d-flex flex-column justify-content-center flex-wrap'>
              {' '}
              <div className='d-flex align-items-center mb-1'>
                <div className='d-flex align-items-center'>
                  <span
                    className='rounded-circle cursor-pointer'
                    onClick={() => navigate('/maintenance')}
                  >
                    <img src={backArrow} height='14' width='14' />
                  </span>
                  <h2 className='m-0 head-text ms-2 text-capitalize'>{`Maintenance Request`}</h2>
                </div>

                {approvalUsers?.length > 0 && (
                  <div className='d-flex ms-auto'>
                    <button
                      type='button'
                      className='btn btn-sm fw-bold  ms-3 blue-btn '
                      style={{width: 'fit-content'}}
                      onClick={() => setApprovalWorkflowCard(true)}
                    >
                      <img src={tick} height={18} width={18} className='me-3' /> Approval Workflow
                    </button>
                  </div>
                )}
              </div>
            </div>
            {/* Details Card */}
            <div className='card card-flush py-5 mt-5'>
              <div className='row mx-1'>
                {/* left section */}
                <div className='col-6 px-5'>
                  <div className='row'>
                    {/* Service Details */}
                    <div className='col-12 pe-5 ps-0 mt-3'>
                      <div className='row me-4 ms-0 mt-0'>
                        <div className='col-12 px-5'>
                          <h2 className='top ps-0'>
                            <b>Service Details</b>
                          </h2>
                        </div>
                      </div>
                      <div className='row me-4 ms-0 mt-0'>
                        <div className='col-12 px-5 mt-4'>
                          <div className='d-flex my-4'>
                            <h4 className='my-2' style={{color: 'black'}}>
                              <label style={{width: '130px'}} className='labl-gry '>
                                {' '}
                                Main Service
                              </label>
                              <span className='ms-1'>
                                <b>
                                  {selectedService?.maintenance_main_service?.name
                                    ? selectedService?.maintenance_main_service?.name
                                    : '-'}
                                </b>
                              </span>
                            </h4>
                          </div>
                        </div>
                        <div className='col-12 px-5 mt-2'>
                          <div className='d-flex my-4'>
                            <h4 className='my-2' style={{color: 'black'}}>
                              <label style={{width: '130px'}} className='labl-gry '>
                                {' '}
                                Sub Service
                              </label>
                              <span className='ms-1'>
                                <b>{selectedService?.name ? selectedService?.name : '-'}</b>
                              </span>
                            </h4>
                          </div>
                        </div>
                        <div className='col-12 px-5 mt-4'>
                          <h4 className='my-2 mb-3' style={{color: 'black'}}>
                            <label className={`labl-gry`}> Service Description</label>
                          </h4>

                          <h4>
                            <textarea
                              className='form-control form-control-solid mytest'
                              placeholder='---'
                              name='message'
                              value={requestData?.description}
                              style={{minHeight: '150px'}}
                              disabled
                            />
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-6 px-5 text end'>
                  <div className='row'>
                    {/* Requesy Details */}
                    <div className='col-12 px-5 mt-3'>
                      <div
                        className={'card card-flush py-5 container-xxl mt-2  pe-5 mb-5'}
                        style={{position: 'relative', zIndex: '10'}}
                      >
                        <div className='d-flex'>
                          <div className='d-block px-3 py-2'>
                            <h2 className='top ps-0'>
                              <b>Request Details</b>
                            </h2>

                            <div className='d-flex my-4 mt-7'>
                              <h4 className='my-2' style={{color: 'black'}}>
                                <label style={{width: '130px'}} className='labl-gry '>
                                  {' '}
                                  Request ID
                                </label>
                                <span className='ms-1'>
                                  <b>{requestDetails?.id ? requestDetails?.id : '-'}</b>
                                </span>
                              </h4>
                            </div>

                            <div className='d-flex my-4'>
                              <h4 className='my-2' style={{color: 'black'}}>
                                <label style={{width: '130px'}} className='labl-gry '>
                                  Duration
                                </label>
                                <span className='ms-1'>
                                  <b>
                                    {requestDetails?.duration != null
                                      ? `${requestDetails?.duration} Days`
                                      : '-'}
                                  </b>
                                </span>
                              </h4>
                            </div>

                            {requestDetails?.maintenanceRequestStatus != 2 &&
                              requestDetails?.maintenanceRequestStatus != 3 && (
                                <button
                                  type='button'
                                  className='btn btn-sm fw-bold red-hollow-btn status-w-150 ps-2  mt-12'
                                  onClick={() => {
                                    swal
                                      .fire({
                                        html: ` 
                                      <div class='fs-3'> <b>Cancel Request</b></div>
                                      <div class='fs-5 mt-4'> You are about to cancel this request. Are you sure you want to continue? </div>`,
                                        // icon: 'warning',
                                        showConfirmButton: true,
                                        confirmButtonColor: '#35475e',
                                        confirmButtonText: 'Yes',
                                        showCancelButton: true,
                                        cancelButtonText: 'No',
                                        cancelButtonColor: '#fff',
                                        customClass: {
                                          confirmButton: 'custom-confirm-button',
                                          cancelButton: 'custom-cancel-button',
                                          popup: 'custom-popup',
                                        },
                                        reverseButtons: true,
                                      })
                                      .then((res: any) => {
                                        if (res.isConfirmed) {
                                          cancelRequest()
                                        }
                                      })
                                  }}
                                >
                                  <img src={redCross} height={18} width={18} className='me-6' />{' '}
                                  Cancel Request
                                </button>
                              )}
                          </div>

                          <div className='ms-auto'>
                            <div className='d-flex align-items-center mb-7'>
                              <img
                                src={
                                  type == 'reactive'
                                    ? reactiveImg
                                    : type == 'planned'
                                    ? plannedImg
                                    : preventativeImg
                                }
                                height={55}
                                width={55}
                                className=' ms-auto me-5'
                              />
                              <h3 className='text-capitalize me-5'>
                                <b>{type}</b>
                              </h3>
                            </div>

                            {requestDetails?.maintenanceRequestStatus != null &&
                            requestDetails?.maintenanceRequestStatus != undefined ? (
                              <div className='mt-10 mb-7 text-end me-2'>
                                <div
                                  className={`status status-w-120  ms-auto me-7 ${
                                    requestDetails?.maintenanceRequestStatus == 0
                                      ? 'booked-bar'
                                      : requestDetails?.maintenanceRequestStatus == 1
                                      ? 'expiring-bar'
                                      : requestDetails?.maintenanceRequestStatus == 2
                                      ? 'active-bar'
                                      : requestDetails?.maintenanceRequestStatus == 3
                                      ? 'expired-bar'
                                      : requestDetails?.maintenanceRequestStatus == 4
                                      ? 'req-bar'
                                      : 'no-st-bar'
                                  }`}
                                >
                                  {requestDetails?.maintenanceRequestStatus == 0
                                    ? 'Not Started'
                                    : requestDetails?.maintenanceRequestStatus == 1
                                    ? 'In-Progress'
                                    : requestDetails?.maintenanceRequestStatus == 2
                                    ? 'Completed'
                                    : requestDetails?.maintenanceRequestStatus == 3
                                    ? 'Cancelled'
                                    : requestDetails?.maintenanceRequestStatus == 4
                                    ? 'Requested'
                                    : '-'}
                                </div>
                                <i style={{paddingRight: '3px'}}>
                                  {requestDetails?.maintenanceRequestStatusDateTime
                                    ? `${moment
                                        .utc(requestDetails?.maintenanceRequestStatusDateTime)
                                        .local()
                                        .format('DD.MM.YYYY - hh:mm A')}`
                                    : '-'}
                                </i>
                              </div>
                            ) : (
                              <div className='mb-7 text-end me-2'>
                                <div className='status not-saved ms-auto mt-10 me-7 status-w-120'>
                                  -
                                </div>
                                <i style={{paddingRight: '3px'}}>
                                  {' '}
                                  {requestDetails?.maintenanceRequestStatusDateTime
                                    ? `${moment
                                        .utc(requestDetails?.maintenanceRequestStatusDateTime)
                                        .local()
                                        .format('DD.MM.YYYY - hh:mm A')}`
                                    : '-'}
                                </i>
                              </div>
                            )}

                            {requestDetails?.saveStatusDateTime ? (
                              <div className='text-end me-2'>
                                <div
                                  className={
                                    requestDetails?.saveStatus == 0
                                      ? 'status draft-st ms-5 mt-10 status-w-120 me-7'
                                      : 'status create-st ms-5 mt-10 status-w-120 me-7'
                                  }
                                >
                                  {requestDetails?.saveStatus == 0 ? 'Draft' : 'Created'}
                                </div>
                                <i style={{paddingRight: '3px'}}>
                                  {' '}
                                  {requestDetails?.saveStatusDateTime
                                    ? `${moment
                                        .utc(requestDetails?.saveStatusDateTime)
                                        .local()
                                        .format('DD.MM.YYYY - hh:mm A')}`
                                    : ''}
                                </i>
                              </div>
                            ) : (
                              <div className='status not-saved ms-5 mt-10 status-w-120 me-7'>-</div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Team Card */}
                    <div className='col-12 px-5 mt-3'>
                      <div
                        className={'card card-flush py-5 container-xxl mt-2  pe-5 mb-5'}
                        style={{position: 'relative', zIndex: '10'}}
                      >
                        <div className='d-flex'>
                          <div className='d-block px-3 py-2'>
                            <h2 className='top ps-0'>
                              <b>Handled By</b>
                            </h2>
                            {requestData?.handlerType == 1 ? (
                              <div className='d-flex my-4'>
                                <h4 className='my-2' style={{color: 'black'}}>
                                  <label style={{width: '130px'}} className='labl-gry '>
                                    {' '}
                                    Vendor Name
                                  </label>
                                  <span className='ms-1'>
                                    <b>
                                      {selectedVendor?.companyName
                                        ? selectedVendor?.companyName
                                        : '-'}
                                    </b>
                                  </span>
                                </h4>
                              </div>
                            ) : (
                              <div className='d-flex my-4'>
                                <h4 className='my-2' style={{color: 'black'}}>
                                  <label style={{width: '130px'}} className='labl-gry '>
                                    {' '}
                                    Team Name
                                  </label>
                                  <span className='ms-1'>
                                    <b>{'Internal Team'}</b>
                                  </span>
                                </h4>
                              </div>
                            )}

                            {requestData?.handlerType == 1 && (
                              <>
                                <div className='d-flex my-4'>
                                  <h4 className='my-2' style={{color: 'black'}}>
                                    <label style={{width: '130px'}} className='labl-gry '>
                                      {' '}
                                      Main Contact
                                    </label>
                                    <span className='ms-1'>
                                      <b>
                                        {selectedVendor?.contactInformation?.[0]?.firstName !=
                                          null ||
                                        selectedVendor?.contactInformation?.[0]?.lastName != null
                                          ? `${selectedVendor?.contactInformation?.[0]?.firstName} ${selectedVendor?.contactInformation?.[0]?.lastName}`
                                          : '-'}
                                      </b>
                                    </span>
                                  </h4>
                                </div>

                                <div className='d-flex my-4'>
                                  <h4 className='my-2' style={{color: 'black'}}>
                                    <label style={{width: '130px'}} className='labl-gry '>
                                      {' '}
                                    </label>
                                    <span className='ms-1'>
                                      <b>
                                        {selectedVendor?.contactInformation?.[0]?.email
                                          ? selectedVendor?.contactInformation?.[0]?.email
                                          : '-'}
                                      </b>
                                    </span>
                                  </h4>
                                </div>

                                <div className='d-flex my-4'>
                                  <h4 className='my-2' style={{color: 'black'}}>
                                    <label style={{width: '130px'}} className='labl-gry '>
                                      {' '}
                                    </label>
                                    <span className='ms-1'>
                                      <b>
                                        {selectedVendor?.contactInformation?.[0]?.phoneNumber
                                          ? selectedVendor?.contactInformation?.[0]?.phoneNumber
                                          : '-'}
                                      </b>
                                    </span>
                                  </h4>
                                </div>
                              </>
                            )}
                          </div>

                          <div className={`mainRequestBox mt-3 ms-auto`} style={{height: '150px'}}>
                            <h2 className={`head-text mb-3`}>
                              <b>
                                {requestData?.handlerType == 0
                                  ? 'Internal Team'
                                  : requestData?.handlerType == 1
                                  ? 'Vendor'
                                  : '-'}{' '}
                              </b>
                            </h2>
                            <img
                              src={
                                requestData?.handlerType == 0
                                  ? internalTeamGreen
                                  : requestData?.handlerType == 1
                                  ? externalTeamGreen
                                  : ''
                              }
                              height={80}
                              width={80}
                              className='me-3 d-block mt-3'
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={'card card-flush py-5 mt-7  px-5 mb-5 mx-7 '}
                style={{position: 'relative', zIndex: '10'}}
              >
                <div className='d-flex  align-items-center'>
                  <h2 className='' style={{fontWeight: '700'}}>
                    {'Jobs'}
                  </h2>
                </div>

                {jobs?.map((jb: any, ind: any) => {
                  showFloor = false
                  showCluster = false
                  showUnitGroup = false
                  showBuilding = false
                })}

                {jobs?.map((jb: any, ind: any) => {
                  if (jb?.floor?.length > 0) showFloor = true
                  if (jb?.community?.length > 0) showCluster = true
                  if (jb?.unit_group?.length > 0 || jb?.cluster?.[0]?.type == 2)
                    showUnitGroup = true
                  if (jb?.cluster?.[0]?.type == 1) showBuilding = true

                  return <></>
                })}

                <div className='table-responsive mt-4' style={{maxHeight: '700px'}}>
                  <table className='table align-top  fs-6 gy-5' id='kt_ecommerce_sales_table'>
                    <thead>
                      <tr className='text-start text-gray-400 fw-bold fs-7 gs-0'>
                        <th className='text-start min-w-75px ps-0'>Job ID</th>
                        {requestDetails?.maintenanceJobType != 0 && (
                          <th className='text-start min-w-100px'>
                            {requestDetails?.maintenanceJobType == 2 ? 'Unit' : 'Common Area'}
                          </th>
                        )}
                        {requestDetails?.maintenanceJobType == 2 && (
                          <th className='text-start min-w-100px pt-2'>Unit Type</th>
                        )}

                        {showFloor && requestDetails?.maintenanceJobType != 0 && (
                          <th className='text-start min-w-100px'>Floor</th>
                        )}
                        {showBuilding && <th className='text-start min-w-125px'>Building</th>}

                        {showUnitGroup && <th className='text-start min-w-100px'>Group</th>}

                        {showCluster && <th className='text-start min-w-100px'>Cluster</th>}

                        <th className='text-start min-w-100px'>Development</th>
                        {requestDetails?.maintenanceJobType == 2 && (
                          <th className='text-start min-w-100px  pt-2'>Occupancy</th>
                        )}
                        <th className='text-center min-w-125px'>Urgency</th>
                        <th className='text-center min-w-125px'>Job Status</th>
                        <th className='text-center min-w-200px'>Schedule</th>
                        <th className='text-start min-w-100px'>Duration</th>
                        <th className='text-start min-w-100px'>Job Cost</th>
                        <th className='text-start min-w-100px'>Rating</th>
                      </tr>
                    </thead>

                    <tbody className='fw-semibold head-text'> {/* className='fw-semibold text-gray-600'*/ }
                      {jobs?.map((rs: any, index: any) => {
                        return (
                          <tr onClick={() => goToRequest(rs)} className='cursor-pointer'>
                            <td className='text-start ps-0'>
                              <div className='green_color text-decoration-underline'>
                                <b>{rs?.id != null ? rs?.id : '-'}</b>
                              </div>
                            </td>
                            {requestDetails?.maintenanceJobType != 0 && (
                              <td className='text-start '>
                                {requestDetails?.maintenanceJobType == 2 ? (
                                  <>{rs?.unit?.[0]?.unitNo ? `${rs?.unit?.[0]?.unitNo}` : `-`} </>
                                ) : (
                                  <>
                                    {' '}
                                    {rs?.unit?.[0]?.unitNo
                                      ? `${rs?.unit?.[0]?.unitNo} - ${rs?.unit?.[0]?.commonAreaName}`
                                      : `-`}
                                  </>
                                )}
                              </td>
                            )}
                            {requestDetails?.maintenanceJobType == 2 && (
                              <td className='text-start '>
                                {rs?.unit?.[0]?.unitType === 0
                                  ? 'Apartment'
                                  : rs?.unit?.[0]?.unitType === 1
                                  ? 'Penthouse'
                                  : rs?.unit?.[0]?.unitType === 2
                                  ? 'Common Area'
                                  : rs?.unit?.[0]?.unitType === 3
                                  ? 'Townhouse'
                                  : rs?.unit?.[0]?.unitType === 4
                                  ? 'Villa'
                                  : rs?.unit?.[0]?.unitType === 5
                                  ? `Other - ${rs?.unit?.[0]?.otherName}`
                                  : rs?.unit?.[0]?.unitType === 6
                                  ? 'Office'
                                  : rs?.unit?.[0]?.unitType === 7
                                  ? 'Restaurant'
                                  : rs?.unit?.[0]?.unitType === 8
                                  ? 'Cafe'
                                  : rs?.unit?.[0]?.unitType === 9
                                  ? 'Retail'
                                  : '-'}
                              </td>
                            )}

                            {showFloor && requestDetails?.maintenanceJobType != 0 && (
                              <td className='text-start '>
                                {rs?.floor?.length > 0 ? `${rs?.floor?.[0]?.name}` : `-`}
                              </td>
                            )}

                            {showBuilding && (
                              <td className='text-start '>
                                {rs?.building?.length > 0 ? `${rs?.building?.[0]?.name}` : `-`}
                              </td>
                            )}

                            {showUnitGroup && (
                              <td className='text-start'>
                                {rs?.unit_group?.length > 0 ? `${rs?.unit_group?.[0]?.name}` : `-`}
                              </td>
                            )}

                            {showCluster && (
                              <td className='text-start '>
                                {rs?.cluster?.length > 0 ? `${rs?.cluster?.[0]?.name}` : `-`}
                              </td>
                            )}

                            <td className='text-start'>
                              {rs?.community?.length > 0
                                ? `${rs?.community?.[0]?.name}`
                                : rs?.building.length > 0
                                ? `${rs?.building?.[0]?.name}`
                                : `-`}
                            </td>

                            {requestDetails?.maintenanceJobType == 2 && (
                              <td className='text-start'>
                                {Number(rs?.unit?.[0]?.unitType) !== 2 && (
                                  <>
                                    {rs?.unit?.[0]?.occupy == 1 ? (
                                      <span className='text-success '>
                                        <b>Occupied</b>
                                      </span>
                                    ) : rs?.unit?.[0]?.occupy == 0 ? (
                                      <span className='text-danger '>
                                        <b>Vacant</b>
                                      </span>
                                    ) : (
                                      '-'
                                    )}
                                  </>
                                )}
                              </td>
                            )}

                            <td className='text-start min-w-100px'>
                              <div className='d-flex justify-content-center'>
                                <div
                                  className={
                                    rs?.jobUrgency == 0
                                      ? 'status urgencyStandardStatus status-w-120'
                                      : 'status urgencyEmergencyStatus status-w-120'
                                  }
                                >
                                  {rs?.jobUrgency == 0 ? 'Standard' : 'Emergency'}
                                </div>
                              </div>
                            </td>
                            <td className='text-center d-flex justify-content-center'>
                              <div
                                className={
                                  rs?.maintenanceJobStatus == 0
                                    ? 'status booked-bar justify-content-center status-w-120'
                                    : rs?.maintenanceJobStatus == 1
                                    ? 'status expiring-bar justify-content-center status-w-120'
                                    : rs?.maintenanceJobStatus == 2
                                    ? 'status expired-bar justify-content-center status-w-120'
                                    : rs?.maintenanceJobStatus == 3
                                    ? 'status active-bar justify-content-center status-w-120'
                                    : 'status  no-st-bar justify-content-center status-w-120'
                                }
                              >
                                {rs?.maintenanceJobStatus == 0
                                  ? 'Not Started'
                                  : rs?.maintenanceJobStatus == 1
                                  ? 'In-Progress'
                                  : rs?.maintenanceJobStatus == 2
                                  ? 'Cancelled'
                                  : rs?.maintenanceJobStatus == 3
                                  ? 'Complete'
                                  : '-'}
                              </div>
                            </td>
                            <td className='text-center'>
                              <div className='d-block align-items-center justify-content-center'>
                                {/* {rs?.schedule?.date != null &&
                                  rs?.schedule?.startTime != null &&
                                  rs?.schedule?.endTime != null && ( */}
                                <div className='text-decoration-underline'>
                                  {`${moment
                                    .utc(rs?.maintenance_service_job_schedule?.[0]?.date)
                                    .local()
                                    .format('DD.MM.YYYY')} - 
                                    ${moment
                                      .utc(rs?.maintenance_service_job_schedule?.[0]?.startTime)
                                      .local()
                                      .format('HH:mm')}-${moment
                                    .utc(rs?.maintenance_service_job_schedule?.[0]?.endTime)
                                    .local()
                                    .format('HH:mm')}`}
                                </div>
                                <div
                                  className={
                                    rs?.schedule?.[0]?.visitStatus == 0
                                      ? ''
                                      : rs?.schedule?.[0]?.visitStatus == 1
                                      ? 'text-success'
                                      : rs?.schedule?.[0]?.visitStatus == 2
                                      ? 'text-warning'
                                      : rs?.schedule?.[0]?.visitStatus == 3
                                      ? 'text-danger'
                                      : ''
                                  }
                                >
                                  <b>
                                    {rs?.schedule?.[0]?.visitStatus == 0
                                      ? '-'
                                      : rs?.schedule?.[0]?.visitStatus == 1
                                      ? 'On Time'
                                      : rs?.schedule?.[0]?.visitStatus == 2
                                      ? 'Late'
                                      : rs?.schedule?.[0]?.visitStatus == 3
                                      ? 'No Show'
                                      : ''}{' '}
                                  </b>
                                </div>
                              </div>
                            </td>
                            <td className='text-start '>
                              <div className='d-flex align-items-center '>
                                {rs?.duration != null ? rs?.duration : '-'}
                              </div>
                            </td>
                            <td className='text-start '>
                              <div className='d-flex align-items-center '>
                                {rs?.totalAmount != undefined ? rs?.totalAmount : '-'}
                              </div>
                            </td>
                            <td className='text-start '>
                              <div className='d-flex align-items-center '>
                                <img src={starYellow} height={18} width={18} className='me-1' />
                                {rs?.rating != undefined ? rs?.rating : '-'}
                              </div>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </div>

              {requestWithPO ? (
                <>
                  <div
                    className='card card-flush mb-10 mt-5  p-5 mx-7'
                    // style={addedPOs?.length == 0 ? {minHeight: '400px'} : {}}
                  >
                    <div className='d-flex  align-items-center'>
                      <h2 className='' style={{fontWeight: '700'}}>
                        {'Purchase Orders'}
                      </h2>
                      {generatePOEnabled ? (
                        <button
                          type='button'
                          className='btn btn-sm fw-bold  green-submit-btn ps-3 d-flex justify-content-center ms-auto'
                          onClick={() => {
                            // Internal Team
                            if (requestData?.handlerType == 0) {
                              navigate(
                                `/maintenance/create-request/purchase-order/${type}/1/${id}/0`,
                                {
                                  state: {
                                    from: 'view-maintenance-request',
                                  },
                                }
                              )
                            } else {
                              setShowGeneratePOModel(true)
                            }
                          }}
                          disabled={isLoading}
                        >
                          Generate PO
                        </button>
                      ) : (
                        <button
                          type='button'
                          className='btn btn-sm fw-bold green-submit-btn ps-3 d-flex justify-content-center ms-auto'
                          disabled={isLoading || isViewOnly}
                          onClick={() => {
                            if (
                              uploadedPOs?.length == 0 ||
                              (uploadedPOs?.length > 0 &&
                                uploadedPOs[uploadedPOs?.length - 1]?.isEdit != true)
                            ) {
                              if (requestData?.handlerType == 0) handleAddUploadedPOs(0)
                              else setShowGeneratePOModel(true)
                            } else {
                              ErrorToast('Please first add the previous record..')
                            }
                          }}
                        >
                          Add PO
                        </button>
                      )}
                    </div>
                    <div
                      className='table-responsive mt-1 pe-5'
                      style={{maxHeight: '400px', overflow: 'auto'}}
                    >
                      <table
                        className='table align-middle table-row-dashed  fs-6 gy-5'
                        id='kt_ecommerce_sales_table'
                      >
                        <thead>
                          <th className='text-start min-w-100px ps-0'>Purchase Order</th>
                          <th className='text-start  ps-0 status-w-50'></th>
                          <th className='text-start  ps-0 min-w-50px'></th>
                          <th className='text-start min-w-100px ps-0'>Amount</th>
                          <th className='text-center min-w-150px ps-0'>Signed Copy</th>
                          <th className='text-start min-w-100px ps-0'>Vendor</th>
                          <th className='text-start min-w-100px ps-0'>Date Issued</th>
                        </thead>

                        {generatePOEnabled ? (
                          <tbody className='fw-semibold head-text'> {/* className='fw-semibold text-gray-600'*/ }
                            {addedPOs?.length > 0 ? (
                              <>
                                {addedPOs?.map((ad: any, index: any) => {
                                  return (
                                    <tr className=''>
                                      <td
                                        className='text-start min-w-100px ps-0'
                                        onClick={() => {
                                          if (ad?.saveStatus == 0)
                                            navigate(
                                              `/maintenance/create-request/purchase-order/${type}/2/${id}/${ad?._id}/${ad?.maintenancePurchaseOrderType}`,
                                              {
                                                state: {
                                                  from: 'view-maintenance-request',
                                                },
                                              }
                                            )
                                          else
                                            navigate(
                                              `/maintenance/view-purchase-order/${type}/2/${id}/${ad?._id}`,
                                              {
                                                state: {
                                                  from: 'view-maintenance-request',
                                                },
                                              }
                                            )
                                        }}
                                      >
                                        <div className='green_color text-decoration-underline cursor-pointer'>
                                          <b>{ad?.purchaseOrderNo ? ad?.purchaseOrderNo : '-'}</b>
                                        </div>
                                      </td>
                                      <td className='text-start  ps-0'>
                                        <div className='d-flex align-items-center my-3'>
                                          {ad?.saveStatus == 0 ? (
                                            <p className='mb-0 ps-1'>Draft</p>
                                          ) : (
                                            // <a href={`${Bucket}${ad?.pdfURL}`} target='_blank'>
                                            <img
                                              src={pdfIcon}
                                              height={30}
                                              width={30}
                                              className='me-4 cursor-pointer'
                                              id={`addedPos${index}`}
                                              onClick={() =>
                                                handleImageClick(
                                                  `addedPos${index}`,
                                                  `${Bucket}${ad?.pdfURL}`
                                                )
                                              }
                                            />
                                            // </a>
                                          )}
                                        </div>
                                      </td>
                                      <td className='text-start  ps-0'>
                                        <div>
                                          {ad?.maintenancePurchaseOrderType == 1 && (
                                            <span>
                                              <OverlayTrigger
                                                placement='top'
                                                delay={{show: 250, hide: 200}}
                                                overlay={
                                                  <Tooltip
                                                    id='button-tooltip-2'
                                                    className='custom-tooltip'
                                                  >
                                                    {' '}
                                                    PO for the External Handling Team and will be
                                                    shared with them as part of the request via the
                                                    vendor portal.
                                                  </Tooltip>
                                                }
                                              >
                                                <span>
                                                  {' '}
                                                  <AiOutlineExclamationCircle
                                                    color='#384a62'
                                                    fontSize={15}
                                                    fontWeight={700}
                                                    className='cursor-pointer'
                                                    style={{
                                                      cursor: 'pointer',
                                                      marginBottom: '2px',
                                                    }}
                                                  />
                                                </span>
                                              </OverlayTrigger>
                                            </span>
                                          )}
                                        </div>
                                      </td>
                                      <td className='text-start min-w-100px ps-0'>
                                        {'AED'}{' '}
                                        {ad?.totalAmount
                                          ? ad?.totalAmount?.toLocaleString('en-US', {
                                              minimumFractionDigits: 2,
                                            })
                                          : '-'}
                                      </td>
                                      <td className='text-center min-w-100px ps-0'>
                                        {ad?.saveStatus == 1 ? (
                                          <>
                                            {ad?.singedURL?.trim()?.length > 0 ? (
                                              <div className='d-flex align-items-start justify-content-center my-3'>
                                                {/* <a
                                                  href={`${Bucket}${ad?.singedURL}`}
                                                  target='_blank'
                                                > */}
                                                <img
                                                  src={pdfIcon}
                                                  height={30}
                                                  width={30}
                                                  className='mx-4 cursor-pointer'
                                                  id={`addedSignedPos${index}`}
                                                  onClick={() =>
                                                    handleImageClick(
                                                      `addedSignedPos${index}`,
                                                      `${Bucket}${ad?.singedURL}`
                                                    )
                                                  }
                                                />
                                                {/* </a> */}
                                                {!isViewOnly && (
                                                  <img
                                                    src={redCross}
                                                    height={16}
                                                    width={16}
                                                    className='me-4 cursor-pointer'
                                                    onClick={() => {
                                                      swal
                                                        .fire({
                                                          html: ` 
                                                          <div class='fs-3'> <b>Delete Signed Copy </b></div>
                                                          <div class='fs-5 mt-4'> Are you want to delete this purchase order's signed copy? </div>`,
                                                          // icon: 'warning',
                                                          showConfirmButton: true,
                                                          confirmButtonColor: '#35475e',
                                                          confirmButtonText: 'Yes',
                                                          showCancelButton: true,
                                                          cancelButtonText: 'No',
                                                          cancelButtonColor: '#fff',
                                                          customClass: {
                                                            confirmButton:
                                                              'custom-cancel-button status-w-100 d-flex justify-content-center h-31 align-items-center',
                                                            cancelButton:
                                                              'custom-confirm-button status-w-100 d-flex justify-content-center h-31 align-items-center',
                                                            popup: 'custom-popup',
                                                          },
                                                          // reverseButtons: true,
                                                        })
                                                        .then((res: any) => {
                                                          if (res.isConfirmed) {
                                                            deletePOSignedCopy(ad, false, index)
                                                          }
                                                        })
                                                    }}
                                                  />
                                                )}
                                              </div>
                                            ) : (
                                              <div className='d-flex align-items-center justify-content-center'>
                                                <form
                                                  id='form-file-upload'
                                                  onDragEnter={handleDrag}
                                                  onSubmit={(e) => e.preventDefault()}
                                                >
                                                  <label
                                                    id='label-file-upload'
                                                    htmlFor='input-file-upload'
                                                    className={
                                                      dragActive
                                                        ? 'drag-active form-label m-0 upload d-flex gap-2 text-center p-auto'
                                                        : 'form-label m-0 upload d-flex gap-2 text-center p-auto'
                                                    }
                                                    style={{
                                                      fontSize: '16px',
                                                      color: 'white',
                                                    }}
                                                    ref={inputRef}
                                                  >
                                                    <div className='btn btn-sm fw-bold blue-btn ps-3 mx-auto my-3'>
                                                      <img
                                                        src={uploadFileWhite}
                                                        height={20}
                                                        width={18}
                                                        className='me-4'
                                                      />{' '}
                                                      {'  '}Upload
                                                    </div>
                                                  </label>
                                                  <input
                                                    type='file'
                                                    // multiple={true}
                                                    onChange={(e: any) => {
                                                      handleUploadSignedCopies(e, index, ad)
                                                    }}
                                                    id='input-file-upload'
                                                    ref={inputRef}
                                                    name='input-file-upload'
                                                    disabled={isLoading || isViewOnly}
                                                    accept='application/pdf'
                                                    style={{
                                                      display: 'none',
                                                      visibility: 'hidden',
                                                    }}
                                                  />
                                                  {dragActive && (
                                                    <div
                                                      id='drag-file-element'
                                                      onDragEnter={handleDrag}
                                                      onDragLeave={handleDrag}
                                                      onDragOver={handleDrag}
                                                      onDrop={handleDrop}
                                                    ></div>
                                                  )}
                                                </form>
                                              </div>
                                            )}
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                      </td>
                                      <td className='text-start min-w-100px ps-0'>
                                        {ad?.vendorAddress?.name ? ad?.vendorAddress?.name : '-'}
                                      </td>
                                      <td className='text-start min-w-100px ps-0'>
                                        {ad?.createdAt && ad?.saveStatus == 1
                                          ? `${moment
                                              .utc(ad?.createdAt)
                                              .local()
                                              .format('DD.MM.YYYY')} `
                                          : '-'}
                                      </td>
                                      {!isViewOnly && (
                                        <td className='text-start min-w-25px ps-0'>
                                          <img
                                            src={trashImg}
                                            height={18}
                                            width={18}
                                            className='mx-5 cursor-pointer'
                                            onClick={() => {
                                              swal
                                                .fire({
                                                  html: ` 
                                                  <div class='fs-3'> <b>Delete Purchase Order</b></div>
                                                  <div class='fs-5 mt-4'>You are about to delete this purchase order. Deleting it will also remove it from the vendor portal.</div>
                                                  <div class='fs-5 mt-4'> Are you sure you want to continue? </div>`,
                                                  // icon: 'warning',
                                                  showConfirmButton: true,
                                                  confirmButtonColor: '#35475e',
                                                  confirmButtonText: 'Yes',
                                                  showCancelButton: true,
                                                  cancelButtonText: 'No',
                                                  cancelButtonColor: '#fff',
                                                  customClass: {
                                                    confirmButton:
                                                      'custom-cancel-button status-w-100 d-flex justify-content-center h-31 align-items-center',
                                                    cancelButton:
                                                      'custom-confirm-button status-w-100 d-flex justify-content-center h-31 align-items-center',
                                                    popup: 'custom-popup',
                                                  },
                                                  // reverseButtons: true,
                                                })
                                                .then((res: any) => {
                                                  if (res.isConfirmed) {
                                                    deletePO(ad?._id)
                                                  }
                                                })
                                            }}
                                          />
                                        </td>
                                      )}
                                    </tr>
                                  )
                                })}
                              </>
                            ) : (
                              <></>
                            )}
                          </tbody>
                        ) : (
                          <tbody className='fw-semibold head-text'> {/* className='fw-semibold text-gray-600'*/ }
                            {uploadedPOs?.length > 0 ? (
                              <>
                                {uploadedPOs?.map((up: any, index: any) => {
                                  return (
                                    <tr className=''>
                                      <td
                                        className='text-start min-w-100px ps-0 pe-10'
                                        onClick={() => {}}
                                      >
                                        {up?.isEdit ? (
                                          <input
                                            type='text'
                                            className='form-control form-control-solid mytest tenancy-control me-10'
                                            placeholder={'Enter PO #'}
                                            name='mainService'
                                            value={up?.purchaseOrderNo}
                                            style={{height: '35px'}}
                                            onChange={(e: any) => {
                                              let values = [...uploadedPOs]
                                              values[index].purchaseOrderNo = e.target.value
                                              setUploadedPOs(values)
                                            }}
                                          />
                                        ) : (
                                          // <a href={`${Bucket}${up?.pdfURL}`} target='_blank'>
                                            <div
                                              className={``}
                                            >
                                              <b>
                                                {up?.purchaseOrderNo ? up?.purchaseOrderNo : '-'}
                                              </b>
                                            </div>
                                          // </a>
                                        )}
                                      </td>
                                      <td className='text-start  ps-0 '>
                                        <div className='d-flex align-items-center'>
                                          {up?.pdfURL?.trim()?.length == 0 ? (
                                            <div className='d-flex align-items-center justify-content-center'>
                                              <form
                                                id='form-file-upload'
                                                onDragEnter={handleDrag}
                                                onSubmit={(e) => e.preventDefault()}
                                              >
                                                <label
                                                  id='label-file-upload'
                                                  htmlFor='input-file-upload'
                                                  className={
                                                    dragActive
                                                      ? 'drag-active form-label m-0 upload d-flex gap-2 text-center p-auto'
                                                      : 'form-label m-0 upload d-flex gap-2 text-center p-auto'
                                                  }
                                                  style={{
                                                    fontSize: '16px',
                                                    color: 'white',
                                                  }}
                                                  ref={inputRef}
                                                >
                                                  <div className='btn btn-sm fw-bold blue-btn ps-3 mx-auto my-3'>
                                                    <img
                                                      src={uploadFileWhite}
                                                      height={20}
                                                      width={18}
                                                      className='me-4'
                                                    />{' '}
                                                    {'  '}Upload
                                                  </div>
                                                </label>
                                                <input
                                                  type='file'
                                                  // multiple={true}
                                                  onChange={(e: any) => {
                                                    handleUploadPO(e, index)
                                                  }}
                                                  id='input-file-upload'
                                                  ref={inputRef}
                                                  name='input-file-upload'
                                                  disabled={isLoading || isViewOnly}
                                                  accept='application/pdf'
                                                  style={{
                                                    display: 'none',
                                                    visibility: 'hidden',
                                                  }}
                                                />
                                                {dragActive && (
                                                  <div
                                                    id='drag-file-element'
                                                    onDragEnter={handleDrag}
                                                    onDragLeave={handleDrag}
                                                    onDragOver={handleDrag}
                                                    onDrop={handleDrop}
                                                  ></div>
                                                )}
                                              </form>
                                            </div>
                                          ) : (
                                            <div className='d-flex'>
                                            {/*  <a href={`${Bucket}${up?.pdfURL}`} target='_blank'> */}
                                            <img
                                              src={pdfIcon}
                                              height={30}
                                              width={30}
                                              className='me-3 cursor-pointer'
                                              id={`uploadedPos${index}`}
                                              onClick={() =>
                                                handleImageClick(
                                                  `uploadedPos${index}`,
                                                  `${Bucket}${up?.pdfURL}`
                                                )
                                              }
                                            />
                                            {/* </a> */}
                                            {up?.isEdit &&
                                            <img
                                                src={redCross}
                                                height={14}
                                                width={14}
                                                className='me-3 cursor-pointer'
                                                onClick={() => {
                                                //   swal
                                                //     .fire({
                                                //       html: ` 
                                                //         <div class='fs-3'> <b>Delete PO</b></div>
                                                //         <div class='fs-5 mt-4'> Are you want to delete this purchase order? </div>`,
                                                //       // icon: 'warning',
                                                //       showConfirmButton: true,
                                                //       confirmButtonColor: '#35475e',
                                                //       confirmButtonText: 'Yes',
                                                //       showCancelButton: true,
                                                //       cancelButtonText: 'No',
                                                //       cancelButtonColor: '#fff',
                                                //       customClass: {
                                                //       confirmButton:
                                                //         'custom-cancel-button status-w-100 d-flex justify-content-center h-31 align-items-center',
                                                //       cancelButton:
                                                //         'custom-confirm-button status-w-100 d-flex justify-content-center h-31 align-items-center',
                                                //       popup: 'custom-popup',
                                                //     },
                                                //         // reverseButtons: true,
                                                // })
                                                //   .then((res: any) => {
                                                //     if (res.isConfirmed) {
                                                      let values:any = [...uploadedPOs]
                                                      values[index].pdfURL = ''
                                                      setUploadedPOs(values)
                                                         
                                                  //   }
                                                  // })
                                                }}
                                                />
                                           }
                                      </div>
                                          )}
                                        </div>
                                      </td>
                                      
                                      <td className='text-start  ps-0'>
                                      {!up?.isEdit &&
                                        <div>
                                          {up?.maintenancePurchaseOrderType == 1 && (
                                            <span>
                                              <OverlayTrigger
                                                placement='top'
                                                delay={{show: 250, hide: 200}}
                                                overlay={
                                                  <Tooltip
                                                    id='button-tooltip-2'
                                                    className='custom-tooltip'
                                                  >
                                                    {' '}
                                                    PO for the External Handling Team and will be
                                                    shared with them as part of the request via the
                                                    vendor portal.
                                                  </Tooltip>
                                                }
                                              >
                                                <span>
                                                  {' '}
                                                  <AiOutlineExclamationCircle
                                                    color='#384a62'
                                                    fontSize={15}
                                                    fontWeight={700}
                                                    className='cursor-pointer'
                                                    style={{
                                                      cursor: 'pointer',
                                                      marginBottom: '2px',
                                                    }}
                                                  />
                                                </span>
                                              </OverlayTrigger>
                                            </span>
                                          )}
                                        </div>
                                          }
                                      </td>
                                     
                                      <td className='text-start min-w-100px ps-0 pe-10'>
                                        {up?.isEdit ? (
                                          <input
                                            type='number'
                                            className='form-control form-control-solid mytest tenancy-control mb-3'
                                            placeholder={'Enter PO Amount'}
                                            name='mainService'
                                            value={up?.totalAmount}
                                            style={{height: '35px'}}
                                            onChange={(e: any) => {
                                              const newValue = e.target.value.replace(/^0+/, '')
                                              let values = [...uploadedPOs]
                                              values[index].totalAmount = newValue
                                              setUploadedPOs(values)
                                            }}
                                          />
                                        ) : (
                                          <>
                                            {'AED'}{' '}
                                            {up?.totalAmount
                                              ? (up?.totalAmount)?.toLocaleString('en-US', {
                                                  minimumFractionDigits: 2,
                                                })
                                              : '-'}
                                          </>
                                        )}
                                      </td>
                                      <td className='text-center min-w-100px ps-0'>
                                        <div className='d-flex align-items-center justify-content-center'>
                                          {up?.singedURL?.trim()?.length == 0 ? (
                                            <div className='d-flex align-items-center justify-content-center'>
                                              <form
                                                id='form-file-upload-po-copy'
                                                onDragEnter={handleDrag}
                                                onSubmit={(e) => e.preventDefault()}
                                              >
                                                <label
                                                  id='form-file-upload-po-copy'
                                                  htmlFor='form-file-upload-po-copyyy'
                                                  className={
                                                    dragActive
                                                      ? 'drag-active form-label m-0 upload d-flex gap-2 text-center p-auto'
                                                      : 'form-label m-0 upload d-flex gap-2 text-center p-auto'
                                                  }
                                                  style={{
                                                    fontSize: '16px',
                                                    color: 'white',
                                                  }}
                                                  // ref={inputRef}
                                                >
                                                  <div className='btn btn-sm fw-bold blue-btn ps-3 mx-auto my-3'>
                                                    <img
                                                      src={uploadFileWhite}
                                                      height={20}
                                                      width={18}
                                                      className='me-4'
                                                    />{' '}
                                                    {'  '}Upload
                                                  </div>
                                                </label>
                                                <input
                                                  type='file'
                                                  // multiple={true}
                                                  onChange={(e: any) => {
                                                    handleUploadSignedCopiesOfUploadedPO(
                                                      e,
                                                      index,
                                                      up
                                                    )
                                                  }}
                                                  id='form-file-upload-po-copyyy'
                                                  // ref={inputRef}
                                                  name='form-file-upload-po-copy'
                                                  disabled={isLoading || isViewOnly}
                                                  accept='application/pdf'
                                                  style={{
                                                    display: 'none',
                                                    visibility: 'hidden',
                                                  }}
                                                />
                                                {dragActive && (
                                                  <div
                                                    id='drag-file-element'
                                                    onDragEnter={handleDrag}
                                                    onDragLeave={handleDrag}
                                                    onDragOver={handleDrag}
                                                    onDrop={handleDrop}
                                                  ></div>
                                                )}
                                              </form>
                                            </div>
                                          ) : (
                                            <div className='d-flex justify-content-center'>
                                              {/* <a href={`${Bucket}${up?.singedURL}`} target='_blank'> */}
                                              <img
                                                src={pdfIcon}
                                                height={30}
                                                width={30}
                                                className='cursor-pointer'
                                                id={`uploadedSignedPos${index}`}
                                                onClick={() =>
                                                  handleImageClick(
                                                    `uploadedSignedPos${index}`,
                                                    `${Bucket}${up?.singedURL}`
                                                  )
                                                }
                                              />
                                              {/* </a> */}
                                              {!isViewOnly && (
                                                <>
                                                {!up?.isEdit ? (
                                                  <>
                                                  {/* // <div
                                                  //   className='d-flex'
                                                  //   style={{width: 'max-content'}}
                                                  // >
                                                  //   <img
                                                  //     src={removed}
                                                  //     height={20}
                                                  //     width={20}
                                                  //     className='mx-5 cursor-pointer'
                                                  //     onClick={(e: any) => {
                                                  //       let values = [...uploadedPOs]

                                                  //       values.splice(index, 1)

                                                  //       setUploadedPOs(values)
                                                  //     }}
                                                  //   />
                                                  //   <img
                                                  //     src={correct}
                                                  //     height={20}
                                                  //     width={20}
                                                  //     className='mx-5 ms-0 cursor-pointer'
                                                  //     onClick={() => {
                                                  //       let values = [...uploadedPOs]
                                                  //       if (
                                                  //         values[index].vendorName?.trim()
                                                  //           ?.length == 0 ||
                                                  //         values[index].purchaseOrderNo?.trim()
                                                  //           ?.length == 0 ||
                                                  //         values[index].totalAmount <= 0 ||
                                                  //         //  values[index].totalAmount?.trim()?.length == 0  ||

                                                  //         up?.singedURL?.trim()?.length == 0 ||
                                                  //         up?.pdfURL?.trim()?.length == 0
                                                  //       )
                                                  //         ErrorToast(
                                                  //           'Please ensure all fields are completed accurately.'
                                                  //         )
                                                  //       else addUpdatePOs()
                                                  //     }}
                                                  //   />
                                                  // </div> */}
                                                  </>
                                                ) : (
                                                <img
                                                  src={redCross}
                                                  height={16}
                                                  width={16}
                                                  className='ms-3 cursor-pointer'
                                                  onClick={() => {
                                                    // swal
                                                    //   .fire({
                                                    //     html: ` 
                                                    //       <div class='fs-3'> <b>Delete Signed Copy </b></div>
                                                    //       <div class='fs-5 mt-4'> Are you want to delete this purchase order's signed copy? </div>`,
                                                    //     // icon: 'warning',
                                                    //     showConfirmButton: true,
                                                    //     confirmButtonColor: '#35475e',
                                                    //     confirmButtonText: 'Yes',
                                                    //     showCancelButton: true,
                                                    //     cancelButtonText: 'No',
                                                    //     cancelButtonColor: '#fff',
                                                    //     customClass: {
                                                    //       confirmButton:
                                                    //         'custom-cancel-button status-w-100 d-flex justify-content-center h-31 align-items-center',
                                                    //       cancelButton:
                                                    //         'custom-confirm-button status-w-100 d-flex justify-content-center h-31 align-items-center',
                                                    //       popup: 'custom-popup',
                                                    //     },
                                                    //     // reverseButtons: true,
                                                    //   })
                                                    //   .then((res: any) => {
                                                    //     if (res.isConfirmed) {
                                                    //       // deletePOSignedCopy(up, true, index)
                                                          let values:any = [...uploadedPOs]
                                                          values[index].singedURL = ''
                                                          setUploadedPOs(values)
                                                      //   }
                                                      // })
                                                  }}
                                                />
                                                )}
                                                </>
                                              )} 
                                            </div>
                                          )}
                                        </div>
                                      </td>
                                      <td className='text-start min-w-100px ps-0 pe-10'>
                                        {up?.isEdit ? (
                                          <>
                                            {up?.maintenancePurchaseOrderType == 1 ? (
                                              <>{up?.vendorName ? up?.vendorName : '-'}</>
                                            ) : (
                                              <input
                                                type='text'
                                                className='form-control form-control-solid mytest tenancy-control me-10'
                                                placeholder={'Enter Vendor Name'}
                                                name='mainService'
                                                value={up?.vendorName}
                                                style={{height: '35px'}}
                                                onChange={(e: any) => {
                                                  let values = [...uploadedPOs]
                                                  values[index].vendorName = e.target.value
                                                  setUploadedPOs(values)
                                                }}
                                              />
                                            )}
                                          </>
                                        ) : (
                                          <>{up?.vendorName ? up?.vendorName : '-'}</>
                                        )}
                                      </td>
                                      <td className='text-start min-w-100px ps-0'>
                                        {!up?.isEdit
                                          ? `${moment
                                              .utc(up?.createdAt)
                                              .local()
                                              .format('DD.MM.YYYY')} `
                                          : '-'}
                                      </td>
                                      {!isViewOnly && (
                                        <td className='text-start min-w-25px ps-0'>
                                          {up?.isEdit ? (
                                            <div className='d-flex' style={{width: 'max-content'}}>
                                              <img
                                                src={removed}
                                                height={20}
                                                width={20}
                                                className='mx-5 cursor-pointer'
                                                onClick={(e: any) => {
                                                  getRequestById()
                                                }}
                                              />
                                              <img
                                                src={correct}
                                                height={20}
                                                width={20}
                                                className='mx-5 ms-0 cursor-pointer'
                                                onClick={() => {
                                                  let values = [...uploadedPOs]
                                                  if (
                                                    values[index].vendorName?.trim()?.length == 0 ||
                                                    values[index].purchaseOrderNo?.trim()?.length ==
                                                      0 ||
                                                    values[index].totalAmount <= 0 ||
                                                    //  values[index].totalAmount?.trim()?.length == 0  ||

                                                    up?.singedURL?.trim()?.length == 0 ||
                                                    up?.pdfURL?.trim()?.length == 0
                                                  )
                                                    ErrorToast(
                                                      'Please ensure all fields are completed accurately.'
                                                    )
                                                  else addUpdatePOs()
                                                }}
                                              />
                                            </div>
                                          ) : (
                                            <div className="d-flex">
                                              <img
                                                src={pencil}
                                                alt=''
                                                height={18}
                                                width={18}
                                                className='ms-auto me-3 cursor-pointer'
                                                onClick={() => {
                                                  let values:any =[...uploadedPOs]
                                                  values[index].isEdit = true
                                                  setUploadedPOs(values)
                                                }}
                                              />
                                            <img
                                              src={trashImg}
                                              height={18}
                                              width={18}
                                              className='mx-5 cursor-pointer'
                                              onClick={() => {
                                                swal
                                                  .fire({
                                                    html: ` 
                                                    <div class='fs-3'> <b>Delete Purchase Order</b></div>
                                                    <div class='fs-5 mt-4'>You are about to delete this purchase order. Deleting it will also remove it from the vendor portal.</div>
                                                    <div class='fs-5 mt-4'> Are you sure you want to continue? </div>`,
                                                    // icon: 'warning',
                                                    showConfirmButton: true,
                                                    confirmButtonColor: '#35475e',
                                                    confirmButtonText: 'Yes',
                                                    showCancelButton: true,
                                                    cancelButtonText: 'No',
                                                    cancelButtonColor: '#fff',
                                                    customClass: {
                                                      confirmButton:
                                                        'custom-cancel-button status-w-100 d-flex justify-content-center h-31 align-items-center',
                                                      cancelButton:
                                                        'custom-confirm-button status-w-100 d-flex justify-content-center h-31 align-items-center',
                                                      popup: 'custom-popup',
                                                    },
                                                    // reverseButtons: true,
                                                  })
                                                  .then((res: any) => {
                                                    if (res.isConfirmed) {
                                                      deleteUploadPO(up, index)
                                                    }
                                                  })
                                              }}
                                            />
                                            </div>
                                          )}
                                        </td>
                                      )}
                                    </tr>
                                  )
                                })}
                              </>
                            ) : (
                              <></>
                            )}
                          </tbody>
                        )}
                      </table>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div
                    className='card card-flush mb-10 mt-5  p-5 mx-7'
                    // style={addedPOs?.length == 0 ? {minHeight: '400px'} : {}}
                  >
                    <div className='d-flex  align-items-center'>
                      <h2 className='' style={{fontWeight: '700'}}>
                        {'Purchase Orders'}
                      </h2>
                      {generatePOEnabledCurrent ? (
                        <button
                          type='button'
                          className='btn btn-sm fw-bold  green-submit-btn ps-3 d-flex justify-content-center ms-auto'
                          onClick={() => {
                            // Internal Team
                            if (requestData?.handlerType == 0) {
                              navigate(
                                `/maintenance/create-request/purchase-order/${type}/1/${id}/0`,
                                {
                                  state: {
                                    from: 'view-maintenance-request',
                                  },
                                }
                              )
                            } else {
                              setShowGeneratePOModel(true)
                            }
                          }}
                          disabled={isLoading}
                        >
                          Generate PO
                        </button>
                      ) : (
                        <button
                          type='button'
                          className='btn btn-sm fw-bold green-submit-btn ps-3 d-flex justify-content-center ms-auto'
                          disabled={isLoading || isViewOnly}
                          onClick={() => {
                            if (requestData?.handlerType == 0) handleAddUploadedPOs(0)
                            else setShowGeneratePOModel(true)
                            // handleAddUploadedPOs()
                          }}
                        >
                          Add PO
                        </button>
                      )}
                    </div>
                    <div
                      className='table-responsive mt-1 pe-5'
                      style={{maxHeight: '400px', overflow: 'auto'}}
                    >
                      <table
                        className='table align-middle table-row-dashed  fs-6 gy-5'
                        id='kt_ecommerce_sales_table'
                      >
                        <thead>
                          <th className='text-start min-w-100px ps-0'>Purchase Order</th>
                          <th className='text-start  ps-0 status-w-50'></th>
                          <th className='text-start  ps-0 min-w-50px'></th>
                          <th className='text-start min-w-100px ps-0'>Amount</th>
                          <th className='text-center min-w-150px ps-0'>Signed Copy</th>
                          <th className='text-start min-w-100px ps-0'>Vendor</th>
                          <th className='text-start min-w-100px ps-0'>Date Issued</th>
                        </thead>

                        {/* {generatePOEnabledCurrent ? ( */}
                        <tbody className='fw-semibold head-text'> {/* className='fw-semibold text-gray-600'*/ }
                          {addedPOs?.length > 0 ? (
                            <>
                              {addedPOs?.map((ad: any, index: any) => {
                                return (
                                  <tr className=''>
                                    <td
                                      className='text-start min-w-100px ps-0'
                                      onClick={() => {
                                        if (ad?.saveStatus == 0)
                                          navigate(
                                            `/maintenance/create-request/purchase-order/${type}/2/${id}/${ad?._id}/${ad?.maintenancePurchaseOrderType}`,
                                            {
                                              state: {
                                                from: 'view-maintenance-request',
                                              },
                                            }
                                          )
                                        else
                                          navigate(
                                            `/maintenance/view-purchase-order/${type}/2/${id}/${ad?._id}`,
                                            {
                                              state: {
                                                from: 'view-maintenance-request',
                                              },
                                            }
                                          )
                                      }}
                                    >
                                      <div className='green_color text-decoration-underline cursor-pointer'>
                                        <b>{ad?.purchaseOrderNo ? ad?.purchaseOrderNo : '-'}</b>
                                      </div>
                                    </td>
                                    <td className='text-start  ps-0'>
                                      <div className='d-flex align-items-center my-3'>
                                        {ad?.saveStatus == 0 ? (
                                          <p className='mb-0 ps-1'>Draft</p>
                                        ) : (
                                          // <a href={`${Bucket}${ad?.pdfURL}`} target='_blank'>
                                          <img
                                            src={pdfIcon}
                                            height={30}
                                            width={30}
                                            className='me-4 cursor-pointer'
                                            id={`addedPos1${index}`}
                                            onClick={() =>
                                              handleImageClick(
                                                `addedPos1${index}`,
                                                `${Bucket}${ad?.pdfURL}`
                                              )
                                            }
                                          />
                                          // </a>
                                        )}
                                      </div>
                                    </td>
                                    <td className='text-start  ps-0'>
                                      <div>
                                        {ad?.maintenancePurchaseOrderType == 1 && (
                                          <span>
                                            <OverlayTrigger
                                              placement='top'
                                              delay={{show: 250, hide: 200}}
                                              overlay={
                                                <Tooltip
                                                  id='button-tooltip-2'
                                                  className='custom-tooltip'
                                                >
                                                  {' '}
                                                  PO for the External Handling Team and will be
                                                  shared with them as part of the request via the
                                                  vendor portal.
                                                </Tooltip>
                                              }
                                            >
                                              <span>
                                                {' '}
                                                <AiOutlineExclamationCircle
                                                  color='#384a62'
                                                  fontSize={15}
                                                  fontWeight={700}
                                                  className='cursor-pointer'
                                                  style={{
                                                    cursor: 'pointer',
                                                    marginBottom: '2px',
                                                  }}
                                                />
                                              </span>
                                            </OverlayTrigger>
                                          </span>
                                        )}
                                      </div>
                                    </td>
                                    <td className='text-start min-w-100px ps-0'>
                                      {'AED'}{' '}
                                      {ad?.totalAmount
                                        ? ad?.totalAmount?.toLocaleString('en-US', {
                                            minimumFractionDigits: 2,
                                          })
                                        : '-'}
                                    </td>
                                    <td className='text-center min-w-100px ps-0'>
                                      {ad?.saveStatus == 1 ? (
                                        <>
                                          {ad?.singedURL?.trim()?.length > 0 ? (
                                            <div className='d-flex align-items-start justify-content-center my-3'>
                                              {/* <a href={`${Bucket}${ad?.singedURL}`} target='_blank'> */}
                                              <img
                                                src={pdfIcon}
                                                height={30}
                                                width={30}
                                                className='mx-4 cursor-pointer'
                                                id={`addedPos1Signed${index}`}
                                                onClick={() =>
                                                  handleImageClick(
                                                    `addedPos1Signed${index}`,
                                                    `${Bucket}${ad?.singedURL}`
                                                  )
                                                }
                                              />
                                              {/* </a> */}
                                              {!isViewOnly && (
                                                <img
                                                  src={redCross}
                                                  height={16}
                                                  width={16}
                                                  className='me-4 cursor-pointer'
                                                  onClick={() => {
                                                    swal
                                                      .fire({
                                                        html: ` 
                                                        <div class='fs-3'> <b>Delete Signed Copy </b></div>
                                                        <div class='fs-5 mt-4'> Are you want to delete this purchase order's signed copy? </div>`,
                                                        // icon: 'warning',
                                                        showConfirmButton: true,
                                                        confirmButtonColor: '#35475e',
                                                        confirmButtonText: 'Yes',
                                                        showCancelButton: true,
                                                        cancelButtonText: 'No',
                                                        cancelButtonColor: '#fff',
                                                        customClass: {
                                                          confirmButton:
                                                            'custom-cancel-button status-w-100 d-flex justify-content-center h-31 align-items-center',
                                                          cancelButton:
                                                            'custom-confirm-button status-w-100 d-flex justify-content-center h-31 align-items-center',
                                                          popup: 'custom-popup',
                                                        },
                                                        // reverseButtons: true,
                                                      })
                                                      .then((res: any) => {
                                                        if (res.isConfirmed) {
                                                          deletePOSignedCopy(ad, false, index)
                                                        }
                                                      })
                                                  }}
                                                />
                                              )}
                                            </div>
                                          ) : (
                                            <div className='d-flex align-items-center justify-content-center'>
                                              <form
                                                id='form-file-upload'
                                                onDragEnter={handleDrag}
                                                onSubmit={(e) => e.preventDefault()}
                                              >
                                                <label
                                                  id='label-file-upload'
                                                  htmlFor='input-file-upload'
                                                  className={
                                                    dragActive
                                                      ? 'drag-active form-label m-0 upload d-flex gap-2 text-center p-auto'
                                                      : 'form-label m-0 upload d-flex gap-2 text-center p-auto'
                                                  }
                                                  style={{
                                                    fontSize: '16px',
                                                    color: 'white',
                                                  }}
                                                  ref={inputRef}
                                                >
                                                  <div className='btn btn-sm fw-bold blue-btn ps-3 mx-auto my-3'>
                                                    <img
                                                      src={uploadFileWhite}
                                                      height={20}
                                                      width={18}
                                                      className='me-4'
                                                    />{' '}
                                                    {'  '}Upload
                                                  </div>
                                                </label>
                                                <input
                                                  type='file'
                                                  // multiple={true}
                                                  onChange={(e: any) => {
                                                    handleUploadSignedCopies(e, index, ad)
                                                  }}
                                                  id='input-file-upload'
                                                  ref={inputRef}
                                                  name='input-file-upload'
                                                  disabled={isLoading || isViewOnly}
                                                  accept='application/pdf'
                                                  style={{
                                                    display: 'none',
                                                    visibility: 'hidden',
                                                  }}
                                                />
                                                {dragActive && (
                                                  <div
                                                    id='drag-file-element'
                                                    onDragEnter={handleDrag}
                                                    onDragLeave={handleDrag}
                                                    onDragOver={handleDrag}
                                                    onDrop={handleDrop}
                                                  ></div>
                                                )}
                                              </form>
                                            </div>
                                          )}
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </td>
                                    <td className='text-start min-w-100px ps-0'>
                                      {ad?.vendorAddress?.name ? ad?.vendorAddress?.name : '-'}
                                    </td>
                                    <td className='text-start min-w-100px ps-0'>
                                      {ad?.createdAt && ad?.saveStatus == 1
                                        ? `${moment
                                            .utc(ad?.createdAt)
                                            .local()
                                            .format('DD.MM.YYYY')} `
                                        : '-'}
                                    </td>
                                    {!isViewOnly && (
                                      <td className='text-start min-w-25px ps-0'>
                                        <img
                                          src={trashImg}
                                          height={18}
                                          width={18}
                                          className='mx-5 cursor-pointer'
                                          onClick={() => {
                                            swal
                                              .fire({
                                                html: ` 
                                                <div class='fs-3'> <b>Delete Purchase Order</b></div>
                                                <div class='fs-5 mt-4'>You are about to delete this purchase order. Deleting it will also remove it from the vendor portal.</div>
                                                <div class='fs-5 mt-4'> Are you sure you want to continue? </div>`,
                                                // icon: 'warning',
                                                showConfirmButton: true,
                                                confirmButtonColor: '#35475e',
                                                confirmButtonText: 'Yes',
                                                showCancelButton: true,
                                                cancelButtonText: 'No',
                                                cancelButtonColor: '#fff',
                                                customClass: {
                                                  confirmButton:
                                                    'custom-cancel-button status-w-100 d-flex justify-content-center h-31 align-items-center',
                                                  cancelButton:
                                                    'custom-confirm-button status-w-100 d-flex justify-content-center h-31 align-items-center',
                                                  popup: 'custom-popup',
                                                },
                                                // reverseButtons: true,
                                              })
                                              .then((res: any) => {
                                                if (res.isConfirmed) {
                                                  deletePO(ad?._id)
                                                }
                                              })
                                          }}
                                        />
                                      </td>
                                    )}
                                  </tr>
                                )
                              })}
                            </>
                          ) : (
                            <></>
                          )}
                          {/* </tbody>
                      ) : (
                        <tbody className='fw-semibold text-gray-600'> */}
                          {uploadedPOs?.length > 0 ? (
                            <>
                              {uploadedPOs?.map((up: any, index: any) => {
                                return (
                                  <tr className=''>
                                    <td
                                      className='text-start min-w-100px ps-0 pe-10'
                                      onClick={() => {}}
                                    >
                                      {up?.isEdit ? (
                                        <input
                                          type='text'
                                          className='form-control form-control-solid mytest tenancy-control me-10'
                                          placeholder={'Enter PO #'}
                                          name='mainService'
                                          value={up?.purchaseOrderNo}
                                          style={{height: '35px'}}
                                          onChange={(e: any) => {
                                            let values = [...uploadedPOs]
                                            values[index].purchaseOrderNo = e.target.value
                                            setUploadedPOs(values)
                                          }}
                                        />
                                      ) : (
                                        // <a href={`${Bucket}${up?.pdfURL}`} target='_blank'>
                                          <div
                                            className={``}
                                          >
                                            {up?.purchaseOrderNo ? up?.purchaseOrderNo : '-'}
                                          </div>
                                        // </a>
                                      )}
                                    </td>
                                    <td className='text-start  ps-0 '>
                                      <div className='d-flex align-items-center'>
                                        {up?.pdfURL?.trim()?.length == 0 ? (
                                          <div className='d-flex align-items-center justify-content-center'>
                                            <form
                                              id='form-file-upload'
                                              onDragEnter={handleDrag}
                                              onSubmit={(e) => e.preventDefault()}
                                            >
                                              <label
                                                id='label-file-upload'
                                                htmlFor='input-file-upload'
                                                className={
                                                  dragActive
                                                    ? 'drag-active form-label m-0 upload d-flex gap-2 text-center p-auto'
                                                    : 'form-label m-0 upload d-flex gap-2 text-center p-auto'
                                                }
                                                style={{
                                                  fontSize: '16px',
                                                  color: 'white',
                                                }}
                                                ref={inputRef}
                                              >
                                                <div className='btn btn-sm fw-bold blue-btn ps-3 mx-auto my-3'>
                                                  <img
                                                    src={uploadFileWhite}
                                                    height={20}
                                                    width={18}
                                                    className='me-4'
                                                  />{' '}
                                                  {'  '}Upload
                                                </div>
                                              </label>
                                              <input
                                                type='file'
                                                // multiple={true}
                                                onChange={(e: any) => {
                                                  handleUploadPO(e, index)
                                                }}
                                                id='input-file-upload'
                                                ref={inputRef}
                                                name='input-file-upload'
                                                disabled={isLoading || isViewOnly}
                                                accept='application/pdf'
                                                style={{
                                                  display: 'none',
                                                  visibility: 'hidden',
                                                }}
                                              />
                                              {dragActive && (
                                                <div
                                                  id='drag-file-element'
                                                  onDragEnter={handleDrag}
                                                  onDragLeave={handleDrag}
                                                  onDragOver={handleDrag}
                                                  onDrop={handleDrop}
                                                ></div>
                                              )}
                                            </form>
                                          </div>
                                        ) : (
                                          <>
                                          {/* // <a href={`${Bucket}${up?.pdfURL}`} target='_blank'> */}
                                          <img
                                            src={pdfIcon}
                                            height={30}
                                            width={30}
                                            className='me-4 cursor-pointer'
                                            id={`uploadedPos1${index}`}
                                            onClick={() =>
                                              handleImageClick(
                                                `uploadedPos1${index}`,
                                                `${Bucket}${up?.pdfURL}`
                                              )
                                            }
                                          />
                                          {/* // </a> */}
                                          {!isViewOnly && up?.isEdit &&
                                            <img
                                                src={redCross}
                                                height={14}
                                                width={14}
                                                className='me-3 cursor-pointer'
                                                onClick={() => {
                                                //   swal
                                                //     .fire({
                                                //       html: ` 
                                                //         <div class='fs-3'> <b>Delete PO</b></div>
                                                //         <div class='fs-5 mt-4'> Are you want to delete this purchase order? </div>`,
                                                //       // icon: 'warning',
                                                //       showConfirmButton: true,
                                                //       confirmButtonColor: '#35475e',
                                                //       confirmButtonText: 'Yes',
                                                //       showCancelButton: true,
                                                //       cancelButtonText: 'No',
                                                //       cancelButtonColor: '#fff',
                                                //       customClass: {
                                                //       confirmButton:
                                                //         'custom-cancel-button status-w-100 d-flex justify-content-center h-31 align-items-center',
                                                //       cancelButton:
                                                //         'custom-confirm-button status-w-100 d-flex justify-content-center h-31 align-items-center',
                                                //       popup: 'custom-popup',
                                                //     },
                                                //         // reverseButtons: true,
                                                // })
                                                //   .then((res: any) => {
                                                //     if (res.isConfirmed) {
                                                      let values:any = [...uploadedPOs]
                                                      values[index].pdfURL = ''
                                                      setUploadedPOs(values)
                                                         
                                                  //   }
                                                  // })
                                                }}
                                                />
                                           }
                                           </>
                                          
                                        )}
                                      </div>
                                    </td>
                                    <td className='text-start  ps-0'>
                                    {!up?.isEdit &&
                                      <div>
                                        {up?.maintenancePurchaseOrderType == 1 && (
                                          <span>
                                            <OverlayTrigger
                                              placement='top'
                                              delay={{show: 250, hide: 200}}
                                              overlay={
                                                <Tooltip
                                                  id='button-tooltip-2'
                                                  className='custom-tooltip'
                                                >
                                                  {' '}
                                                  PO for the External Handling Team and will be
                                                  shared with them as part of the request via the
                                                  vendor portal.
                                                </Tooltip>
                                              }
                                            >
                                              <span>
                                                {' '}
                                                <AiOutlineExclamationCircle
                                                  color='#384a62'
                                                  fontSize={15}
                                                  fontWeight={700}
                                                  className='cursor-pointer'
                                                  style={{
                                                    cursor: 'pointer',
                                                    marginBottom: '2px',
                                                  }}
                                                />
                                              </span>
                                            </OverlayTrigger>
                                          </span>
                                        )}
                                      </div>
                                      }
                                    </td>
                                    <td className='text-start min-w-100px ps-0 pe-10'>
                                      {up?.isEdit ? (
                                        <input
                                          type='number'
                                          className='form-control form-control-solid mytest tenancy-control mb-3'
                                          placeholder={'Enter PO Amount'}
                                          name='mainService'
                                          value={up?.totalAmount}
                                          style={{height: '35px'}}
                                          onChange={(e: any) => {
                                            const newValue = e.target.value.replace(/^0+/, '')
                                            let values = [...uploadedPOs]
                                            values[index].totalAmount = newValue
                                            setUploadedPOs(values)
                                          }}
                                        />
                                      ) : (
                                        <>
                                          {'AED'}{' '}
                                          {up?.totalAmount
                                            ? up?.totalAmount?.toLocaleString('en-US', {
                                                minimumFractionDigits: 2,
                                              })
                                            : '-'}
                                        </>
                                      )}
                                    </td>
                                    <td className='text-center min-w-100px ps-0'>
                                      <div className='d-flex align-items-center justify-content-center'>
                                        {up?.singedURL?.trim()?.length == 0 ? (
                                          <div className='d-flex align-items-center justify-content-center'>
                                            <form
                                              id='form-file-upload-po-copy'
                                              onDragEnter={handleDrag}
                                              onSubmit={(e) => e.preventDefault()}
                                            >
                                              <label
                                                id='form-file-upload-po-copy'
                                                htmlFor='form-file-upload-po-copyyy'
                                                className={
                                                  dragActive
                                                    ? 'drag-active form-label m-0 upload d-flex gap-2 text-center p-auto'
                                                    : 'form-label m-0 upload d-flex gap-2 text-center p-auto'
                                                }
                                                style={{
                                                  fontSize: '16px',
                                                  color: 'white',
                                                }}
                                                // ref={inputRef}
                                              >
                                                <div className='btn btn-sm fw-bold blue-btn ps-3 mx-auto my-3'>
                                                  <img
                                                    src={uploadFileWhite}
                                                    height={20}
                                                    width={18}
                                                    className='me-4'
                                                  />{' '}
                                                  {'  '}Upload
                                                </div>
                                              </label>
                                              <input
                                                type='file'
                                                // multiple={true}
                                                onChange={(e: any) => {
                                                  handleUploadSignedCopiesOfUploadedPO(e, index, up)
                                                }}
                                                id='form-file-upload-po-copyyy'
                                                // ref={inputRef}
                                                name='form-file-upload-po-copy'
                                                disabled={isLoading || isViewOnly}
                                                accept='application/pdf'
                                                style={{
                                                  display: 'none',
                                                  visibility: 'hidden',
                                                }}
                                              />
                                              {dragActive && (
                                                <div
                                                  id='drag-file-element'
                                                  onDragEnter={handleDrag}
                                                  onDragLeave={handleDrag}
                                                  onDragOver={handleDrag}
                                                  onDrop={handleDrop}
                                                ></div>
                                              )}
                                            </form>
                                          </div>
                                        ) : (
                                          <div className='d-flex justify-content-center'>
                                            {/* <a href={`${Bucket}${up?.singedURL}`} target='_blank'> */}
                                            <img
                                              src={pdfIcon}
                                              height={30}
                                              width={30}
                                              className='cursor-pointer'
                                              id={`uploadedPos1Singed${index}`}
                                              onClick={() =>
                                                handleImageClick(
                                                  `uploadedPos1Singed${index}`,
                                                  `${Bucket}${up?.singedURL}`
                                                )
                                              }
                                            />
                                            {/* </a> */}
                                            {!isViewOnly && up?.isEdit && (
                                              <img
                                                src={redCross}
                                                height={16}
                                                width={16}
                                                className='ms-3 cursor-pointer'
                                                onClick={() => {
                                                  // swal
                                                  //   .fire({
                                                  //     html: ` 
                                                  //       <div class='fs-3'> <b>Delete Signed Copy </b></div>
                                                  //       <div class='fs-5 mt-4'> Are you want to delete this purchase order's signed copy? </div>`,
                                                  //     // icon: 'warning',
                                                  //     showConfirmButton: true,
                                                  //     confirmButtonColor: '#35475e',
                                                  //     confirmButtonText: 'Yes',
                                                  //     showCancelButton: true,
                                                  //     cancelButtonText: 'No',
                                                  //     cancelButtonColor: '#fff',
                                                  //     customClass: {
                                                  //       confirmButton:
                                                  //         'custom-cancel-button status-w-100 d-flex justify-content-center h-31 align-items-center',
                                                  //       cancelButton:
                                                  //         'custom-confirm-button status-w-100 d-flex justify-content-center h-31 align-items-center',
                                                  //       popup: 'custom-popup',
                                                  //     },
                                                  //     // reverseButtons: true,
                                                  //   })
                                                  //   .then((res: any) => {
                                                      // if (res.isConfirmed) {
                                                        // deletePOSignedCopy(up, true, index)
                                                        let values:any = [...uploadedPOs]
                                                        values[index].singedURL = ''
                                                        setUploadedPOs(values)
                                                    //   }
                                                    // })
                                                }}
                                              />
                                            )}
                                          </div>
                                        )}
                                      </div>
                                    </td>
                                    <td className='text-start min-w-100px ps-0 pe-10'>
                                      {up?.isEdit ? (
                                        <>
                                          {up?.maintenancePurchaseOrderType == 1 ? (
                                            <>{up?.vendorName ? up?.vendorName : '-'}</>
                                          ) : (
                                            <input
                                              type='text'
                                              className='form-control form-control-solid mytest tenancy-control me-10'
                                              placeholder={'Enter Vendor Name'}
                                              name='mainService'
                                              value={up?.vendorName}
                                              style={{height: '35px'}}
                                              onChange={(e: any) => {
                                                let values = [...uploadedPOs]
                                                values[index].vendorName = e.target.value
                                                setUploadedPOs(values)
                                              }}
                                            />
                                          )}
                                        </>
                                      ) : (
                                        <>{up?.vendorName ? up?.vendorName : '-'}</>
                                      )}
                                    </td>
                                    <td className='text-start min-w-100px ps-0'>
                                      {!up?.isEdit
                                        ? `${moment
                                            .utc(up?.createdAt)
                                            .local()
                                            .format('DD.MM.YYYY')} `
                                        : '-'}
                                    </td>
                                    {!isViewOnly && (
                                      <td className='text-start min-w-25px ps-0'> 
                                        {up?.isEdit ? (
                                            <div className='d-flex' style={{width: 'max-content'}}>
                                              <img
                                                src={removed}
                                                height={20}
                                                width={20}
                                                className='mx-5 cursor-pointer'
                                                onClick={(e: any) => {
                                                  getRequestById()
                                                }}
                                              />
                                              <img
                                                src={correct}
                                                height={20}
                                                width={20}
                                                className='mx-5 ms-0 cursor-pointer'
                                                onClick={() => {
                                                  let values = [...uploadedPOs]
                                                  if (
                                                    values[index].vendorName?.trim()?.length == 0 ||
                                                    values[index].purchaseOrderNo?.trim()?.length ==
                                                      0 ||
                                                    values[index].totalAmount <= 0 ||
                                                    //  values[index].totalAmount?.trim()?.length == 0  ||

                                                    up?.singedURL?.trim()?.length == 0 ||
                                                    up?.pdfURL?.trim()?.length == 0
                                                  )
                                                    ErrorToast(
                                                      'Please ensure all fields are completed accurately.'
                                                    )
                                                  else addUpdatePOs()
                                                }}
                                              />
                                            </div>
                                          ) : (
                                            <div className="d-flex">
                                              <img
                                                src={pencil}
                                                alt=''
                                                height={18}
                                                width={18}
                                                className='ms-auto me-3 cursor-pointer'
                                                onClick={() => {
                                                  let values:any =[...uploadedPOs]
                                                  values[index].isEdit = true
                                                  setUploadedPOs(values)
                                                }}
                                              />
                                            <img
                                              src={trashImg}
                                              height={18}
                                              width={18}
                                              className='mx-5 cursor-pointer'
                                              onClick={() => {
                                                swal
                                                  .fire({
                                                    html: ` 
                                                    <div class='fs-3'> <b>Delete Purchase Order</b></div>
                                                    <div class='fs-5 mt-4'>You are about to delete this purchase order. Deleting it will also remove it from the vendor portal.</div>
                                                    <div class='fs-5 mt-4'> Are you sure you want to continue? </div>`,
                                                    // icon: 'warning',
                                                    showConfirmButton: true,
                                                    confirmButtonColor: '#35475e',
                                                    confirmButtonText: 'Yes',
                                                    showCancelButton: true,
                                                    cancelButtonText: 'No',
                                                    cancelButtonColor: '#fff',
                                                    customClass: {
                                                      confirmButton:
                                                        'custom-cancel-button status-w-100 d-flex justify-content-center h-31 align-items-center',
                                                      cancelButton:
                                                        'custom-confirm-button status-w-100 d-flex justify-content-center h-31 align-items-center',
                                                      popup: 'custom-popup',
                                                    },
                                                    // reverseButtons: true,
                                                  })
                                                  .then((res: any) => {
                                                    if (res.isConfirmed) {
                                                      deleteUploadPO(up, index)
                                                    }
                                                  })
                                              }}
                                            />
                                            </div>
                                          )}
                                      </td>
                                    )}
                                  </tr>
                                )
                              })}
                            </>
                          ) : (
                            <></>
                          )}
                        </tbody>
                        {/* )} */}
                      </table>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div
          className='d-flex justify-content-center align-items-center w-100'
          style={{height: '75vh'}}
        >
          <CircularProgress style={{color: '#007a59'}} />
        </div>
      )}

      <Backdrop sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}} open={open}>
        <CircularProgress style={{color: '#007a59'}} />
      </Backdrop>

      {/* Select Services Modal */}
      {showSelectService && (
        <SelectServiceModal
          show={showSelectService}
          handleClose={() => {
            setShowSelectService(false)
          }}
          updateService={(data: any) => {
            setSelectedService(data)

            setTimeout(() => {
              setAutoSaveData(Date.now())
            }, 700)
          }}
          addedService={selectedService}
        />
      )}

      {/* Select Vendor Modal */}
      {showSelectVendor && (
        <SelectVendorModal
          show={showSelectVendor}
          handleClose={() => {
            setShowSelectVendor(false)
          }}
          updateVendor={(data: any) => {
            getVendorById(data?._id)

            setTimeout(() => {
              setAutoSaveData(Date.now())
            }, 700)
          }}
          addedVendor={selectedVendor}
        />
      )}

      {/* Add Jobs Modal */}
      {showSelectJob && (
        <AddJobsModal
          show={showSelectJob}
          handleClose={() => {
            setShowSelectJob(false)
          }}
          handleSubmitId={(data: any) => {
            let values: any = []
            let vlInd: any = 0
            data?.map((vl: any, ind: any) => {
              if (vl?.check) {
                values[vlInd] = vl

                values[vlInd].check = false
                vlInd++
              }
            })
            setJobs(values)

            if (data?.length > 0) {
              let values = {...requestData}
              if (data?.[0]?.community?.length > 0) {
                values.community = {
                  value: data?.[0]?.community?.[0]?._id,
                  label: data?.[0]?.community?.[0]?.name,
                }
              } else {
                values.building = {
                  value: data?.[0]?.building?.[0]?._id,
                  label: data?.[0]?.building?.[0]?.name,
                }
              }

              setRequestData(values)

              setTimeout(() => {
                setAutoSaveData(Date.now())
              }, 700)
            }
          }}
          addedJobs={jobs}
          development={requestData?.community ? requestData?.community : requestData?.building}
        />
      )}

      {/* Schedule */}
      {showSchedule && (
        <Schedule
          show={showSchedule}
          handleClose={() => {
            setShowSchedule(false)
          }}
          modalData={scheduleToAddEdit}
          dataInd={scheduleIndToAddEdit}
          updateSchedule={(data: any, ind: any) => {
            let values = [...jobs]
            values[ind].schedule = data
            setJobs(values)

            setShowSchedule(false)

            setTimeout(() => {
              setAutoSaveData(Date.now())
            }, 700)
          }}
        />
      )}

      {/* Job Details Modal */}
      {showJobDetails && (
        <JobDetailsModal
          show={showJobDetails}
          handleClose={() => {
            setShowJobDetails(false)
          }}
          modalData={jobsToAddEdit}
          dataInd={jobsIndToAddEdit}
          updateJobDetails={(comments: any, attachments: any, ind: any) => {
            let values = [...jobs]
            values[ind].comments = comments
            values[ind].attachments = attachments
            setJobs(values)

            setShowJobDetails(false)

            setTimeout(() => {
              setAutoSaveData(Date.now())
            }, 700)
          }}
          tab={activeTab}
          isViewMode={isViewOnly}
        />
      )}

      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-900px mx-auto'
        show={approvalWorkflowCard}
        onHide={() => {
          setApprovalWorkflowCard(false)
        }}
        backdrop={true}
        size='sm'
      >
        <div
          className='modal-body py-lg-0 px-lg-0'
          style={{
            transform: 'translate(0px, -70px)',
            // border: '3px solid #384a62',
            top: '70px',
            borderRadius: '5px',
            boxShadow: '0px 0px 20px 0px #516176',
            // minHeight: '150px',
          }}
        >
          <div className='my-1 pb-1 row mx-3 mt-3'>
            <div className='col-md-12 text-end'>
              <label
                className='head-text fs-3 cursor-pointer'
                style={{fontWeight: '700'}}
                onClick={() => {
                  setApprovalWorkflowCard(false)
                }}
              >
                X
              </label>
            </div>
          </div>

          <div className='py-5 mt-0 pt-1 px-7'>
            <div className='d-flex'>
              <h2 className='top ps-0'>
                <b>Approval Workflow</b>
              </h2>
              <h3 className='ms-auto head-text pe-0' style={{fontWeight: '600'}}>
                <b>
                  {' '}
                  <span
                    className={
                      approvalUsers[approvalUsers?.length - 1]?.approvalStatus === 3
                        ? 'select-color ms-5'
                        : 'labl-gry ms-5'
                    }
                    style={{fontWeight: '700'}}
                  >
                    {approvalUsers[approvalUsers?.length - 1]?.approvalStatus === 0
                      ? 'Pending Submission'
                      : approvalUsers[approvalUsers?.length - 1]?.approvalStatus === 1
                      ? 'Submitted'
                      : approvalUsers[approvalUsers?.length - 1]?.approvalStatus === 2
                      ? 'Pending Approval'
                      : approvalUsers[approvalUsers?.length - 1]?.approvalStatus === 3
                      ? 'Approved'
                      : approvalUsers[approvalUsers?.length - 1]?.approvalStatus === 4
                      ? 'Sent Back'
                      : approvalUsers[approvalUsers?.length - 1]?.approvalStatus === 5
                      ? 'Reassigned'
                      : ''}
                  </span>
                </b>
              </h3>
            </div>

            {approvalUsers?.length > 0 && (
              <div className='table-responsive' style={{maxHeight: '500px'}}>
                <table className='table align-start fs-6 gy-5 ' id='kt_ecommerce_sales_table'>
                  <thead>
                    <th className='text-start min-w-150px test'>User</th>
                    <th className='text-start min-w-150px'>Role</th>

                    <th className='text-start min-w-150px'>Status</th>
                    <th className='text-start min-w-150px'>Comments</th>
                  </thead>
                  <tbody className='fw-semibold head-text'> {/* className='fw-semibold text-gray-600'*/ }
                    {approvalUsers?.length > 0 &&
                      approvalUsers?.map((app: any, ind: any) => {
                        return (
                          <>
                            <tr>
                              <td className='text-start ps-0'>
                                <div className='d-flex'>
                                  <div className='head-text'>
                                    <p
                                      className='user-head head-text pe-2 mb-0'
                                      style={{fontWeight: '600'}}
                                    >
                                      {app?.createdBy?.[0]?.firstName}{' '}
                                      {app?.createdBy?.[0]?.lastName}
                                    </p>
                                    <p className='labl-gry mb-2'>({app?.createdBy?.[0]?.email})</p>
                                  </div>
                                </div>
                              </td>

                              <td className='text-start ps-0'>
                                <div className='head-text'>
                                  <p
                                    className='user-head pe-2 mb-0 ps-1'
                                    style={{fontWeight: '600'}}
                                  >
                                    {app?.isRootUser &&
                                    !app?.isApprovalRequest &&
                                    !app?.isProcurementRequest &&
                                    !app?.isProcurementApprovalRequest
                                      ? 'Root (Maintenance Officer)'
                                      : !app?.isRootUser &&
                                        !app?.isApprovalRequest &&
                                        !app?.isProcurementRequest &&
                                        !app?.isProcurementApprovalRequest
                                      ? 'Maintenance Officer'
                                      : app?.isRootUser && app?.isApprovalRequest
                                      ? 'Root (Maintenance Manager)'
                                      : app?.isRootUser && app?.isProcurementRequest
                                      ? 'Root (Procurement Officer)'
                                      : app?.isRootUser && app?.isProcurementApprovalRequest
                                      ? 'Root (Procurement Manager)'
                                      : !app?.isRootUser && app?.isApprovalRequest
                                      ? 'Maintenance Manager'
                                      : !app?.isRootUser && app?.isProcurementRequest
                                      ? 'Procurement Officer'
                                      : !app?.isRootUser && app?.isProcurementApprovalRequest
                                      ? 'Procurement Manager'
                                      : app?.isMaintenanceVendorSubmitRequest
                                      ? 'vendor'
                                      : app?.isRootUser
                                      ? 'Root User'
                                      : ''}
                                  </p>
                                </div>
                              </td>

                              <td className='text-start ps-0'>
                                <div className='head-text'>
                                  <p className='user-head pe-2 mb-0' style={{fontWeight: '600'}}>
                                    {app?.approvalStatus === 0
                                      ? 'Pending Submission'
                                      : app?.approvalStatus === 1
                                      ? 'Submitted'
                                      : app?.approvalStatus === 2
                                      ? 'Pending Approval'
                                      : app?.approvalStatus === 3
                                      ? 'Approved'
                                      : app?.approvalStatus === 4
                                      ? 'Sent Back'
                                      : app?.approvalStatus === 5
                                      ? 'Reassigned'
                                      : ''}
                                  </p>
                                  <p className='mb-2'>
                                    <i>
                                      {app?.approvalStatusAt
                                        ? `${moment
                                            .utc(app?.approvalStatusAt)
                                            .local()
                                            .format('DD.MM.YYYY - hh:mm A')} `
                                        : ''}
                                    </i>
                                  </p>
                                </div>
                              </td>
                              <td className='text-start ps-0 pe-3'>
                                <textarea
                                  className='form-control form-control-solid mytest'
                                  placeholder='---'
                                  name='description'
                                  value={app?.comment}
                                  onBlur={() => {}}
                                  disabled
                                />{' '}
                              </td>
                            </tr>
                          </>
                        )
                      })}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </Modal>

      <SingleFileLoader isVisible={showLoader} />

      {showGeneratePOModel && (
        <GeneratePOModal
          show={showGeneratePOModel}
          handleClose={() => {
            setShowGeneratePOModel(false)
          }}
          generatePOEnabled={requestWithPO ? generatePOEnabled : generatePOEnabledCurrent}
          updateRequest={(val: any, draft: any) => addRequest(val, 0)}
          addUploads={(val: any) => {
            handleAddUploadedPOs(val)
          }}
          from={'view-maintenance-request'}
        />
      )}
    </>
  )
}

export default ViewEditRequest
