import React from 'react'
import {useState, useEffect} from 'react'
import {Row, Col, Modal} from 'react-bootstrap'
import {useNavigate} from 'react-router-dom'
import latePayment from '../../../img/overdue.png'
import backArrow from '../../../img/back-arrow.png'
import {ApiDelete, ApiGet, ApiPost, ApiPut} from '../../../apiCommon/helpers/API/ApiData'
import './style.scss'

const LatePaymentFee = () => {
  const navigate = useNavigate()

  const [buildings, setBuildings] = useState<any>()

  // list of buildings
  const getBuildings = () => {
    const body = {
      limit: 50,
      page: 1,
    }
    ApiPost(`corporate/building/late_payment_item_list`, body)
      .then((res) => {
        setBuildings(res?.data?.data?.building_data)
      })
      .catch((err) => console.log('err', err))
  }

  useEffect(() => {
    getBuildings()
  }, [])

  return (
    <>
      {/* <div className='app-main flex-column flex-row-fluid' id='kt_app_main'> */}
      <div id='' className='pt-0 mt-0 px-2'>
        <div className='page-title d-flex justify-content-center flex-wrap mb-5 min-w-200px align-items-center'>
          <span
            className='rounded-circle cursor-pointer'
            onClick={() => navigate('/settings')}
          >
            <img src={backArrow} height={14} width={14} className='me-2'/>
          </span>
          <h2 className='page-heading d-flex text-dark fw-bold flex-column justify-content-center my-0 head-text'>
            Late Payment Fee
          </h2>
          <div className='ms-auto'>
            <img src={latePayment} height='30px' width='30px' />
          </div>
        </div>

        {/* Tabs */}
        <div
          className='d-flex align-items-center gap-2 gap-lg-5 justify-content-center align-items-center'
          style={{marginBottom: '35px'}}
        >
          <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2'>
            <li className='nav-item'>
              <a
                className='nav-link text-active-primary pb-2 me-5 active'
                data-bs-toggle='tab'
                onClick={() => navigate('/settings/buildings-late-payment-fee')}
              >
                Buildings
              </a>
            </li>
            <li className='nav-item'>
              <a
                className='nav-link text-active-primary pb-2 mx-5 '
                data-bs-toggle='tab'
                onClick={() => navigate('/settings/communities-late-payment-fee')}
              >
                Communities
              </a>
            </li>
          </ul>
        </div>

        <div className='card card-flush gap-5 p-5 pt-1 pb-0'>
          <div className='card-body pt-0 table-responsive my-1 py-2 px-0'>
            <table className='table align-middle fs-6 gy-5' id='kt_ecommerce_sales_table'>
              <thead>
                <tr className='text-start text-gray-400 fw-bold text-uppercase gs-0'>
                  <th className='min-w-200px'>Development Name</th>
                  <th className='min-w-150px'>Total Floors</th>
                  <th className='min-w-150px'>Total Properties</th>
                  <th className='min-w-150px'>Available Items</th>
                  <th className='min-w-50px'></th>
                </tr>
              </thead>
              <tbody style={{fontWeight: '500'}} className='table-body'>
                {buildings &&
                  buildings.map((build: any, index: any) => {
                    return (
                      <tr>
                        <td className=''>{build?.name}</td>
                        <td className=''>{build?.totalFloors}</td>
                        <td className=''>{build?.totalProperties}</td>
                        <td className=''>{build?.totalLatePaymentItemCharges}</td>

                        <td className=''>
                          {' '}
                          <div
                            onClick={() =>
                              navigate('/settings/late-payment-fee/' + build._id, {
                                state: {development: 'building', devName: build.name},
                              })
                            }
                            className='btn btn-sm fw-bold btn-primary btn-green hover-none'
                          >
                            View
                          </div>
                          {/* <button
                            className='btn btn-sm btn-green fw-bold text-white'
                            style={{
                              fontSize: '1.15rem',
                              padding: '7px 40px',
                              borderRadius: '2px',
                            }}
                            onClick={() =>
                              navigate('/settings/late-payment-fee/' + build._id, {
                                state: {development: 'building', devName: build.name},
                              })
                            }
                          >
                            View
                          </button> */}
                        </td>
                      </tr>
                    )
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}

export default LatePaymentFee
