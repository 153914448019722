import React, {useState} from 'react'
import {Modal} from 'react-bootstrap'
import {Row, Col} from 'react-bootstrap'
import close from '../../../img/close.png'
import submitImg from '../../../img/Submit-White.png'
import './style.scss'
import accountNewImg from '../../../img/statements-white.png'
import {ApiPost} from '../../../apiCommon/helpers/API/ApiData'
import {SuccessToast} from '../../../apiCommon/helpers/Toast'
import {capitalizeFirstLetter} from '../../../Utilities/utils'

interface ButtonProps {
  show: any
  handleClose: any
  accountsList: any
}

const AddAccount = ({handleClose, show, accountsList}: ButtonProps) => {
  const [accountName, setAccountName] = useState<any>()
  const [accountNo, setAccountNo] = useState<any>()
  const [iban, setIban] = useState<any>()
  const [swift, setSwift] = useState<any>()
  const [bankName, setBankName] = useState<any>()

  const addAccount = () => {
    const body = {
      bankName: bankName,
      bankAddress: '',
      accountHolderName: accountName,
      IBAN: iban,
      swiftCode: swift,
      accountNumber: accountNo,
      activityStatus: 1,
      paymentMethod: 2,
    }

    ApiPost(`corporate/payment_account`, body)
      .then((res) => {
        SuccessToast('Account has been added successfully!!')
        handleClose()
        accountsList()
        setAccountName('')
        setAccountNo('')
        setIban('')
        setBankName('')
      })
      .catch((err) => console.log('err', err))
    // }
  }

  return (
    <div>
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-750px mx-auto'
        show={show}
        onHide={() => {
          handleClose()
        }}
        backdrop={true}
        size='sm'
      >
        <div
          className='modal-header text-white ps-5 pe-1 header-style'
          style={{zIndex: '1', opacity: '0.85', borderBottom: '0px', minHeight: '95px'}}
        >
          <Row className='w-100'>
            <Col xs={8} sm={8} md={8}>
              <h2 className='mx-0 my-1 mt-2 text-white'>Add Account</h2>
            </Col>
            <Col xs={4} sm={4} md={4} className='text-end pe-0'>
              <div
                className='btn btn-sm btn-icon btn-active-color-primary ps-auto modal-header-cross'
                onClick={handleClose}
              >
                <img className='svg-icon-1 text-black' src={close} width='14px' height='14px' />
              </div>
            </Col>
          </Row>
        </div>

        <div className='header-bg'>
          <Row className='mx-2'>
            <Col xs={9} sm={9} md={9} lg={9}></Col>
            <Col xs={3} sm={3} md={3} lg={3} className='pt-2 text-end' style={{}}>
              <img src={accountNewImg} height='80px' width='80px' className='me-5' />
            </Col>
          </Row>
        </div>
        <div
          className='modal-body py-lg-10 px-lg-10 pt-lg-5'
          style={{transform: 'translate(0px, -80px)', marginBottom: '-65px'}}
        >
          <Row className='align-items-end mb-3'>
            <Col lg={12} md={12} className='align-items-center text-end'>
              <button
                className='btn btn-sm fw-bold ms-3 ps-4 text-white  generate-btn status-w-160 ms-auto'
                onClick={addAccount}
              >
                <img src={submitImg} height={20} width={20} className='ms-1 me-5' /> {'  '}
                Submit
              </button>
            </Col>
          </Row>
          <Row className='align-items-end mt-8'>
            <Col xs={12} sm={6} md={4} className='align-items-center  px-2 mb-5'>
              <label className='required mb-2 label-color'>Account Name</label>
              <input
                type='text'
                className='form-control form-control-solid-bg mytest'
                placeholder='Enter Account Name'
                name='item_id'
                value={accountName}
                onChange={(e) => {
                  let newVal: any = capitalizeFirstLetter(e.target.value)
                  setAccountName(newVal)
                }}
                autoComplete='off'
                style={{width: '95%'}}
              />
            </Col>
            <Col xs={12} sm={6} md={4} className='align-items-center  px-2 mb-5'>
              <label className='required mb-2 label-color'>Account No.</label>
              <input
                type='text'
                className='form-control form-control-solid-bg mytest'
                placeholder='Enter Account No.'
                name='item_id'
                value={accountNo}
                onChange={(e) => {
                  let newVal: any = capitalizeFirstLetter(e.target.value)
                  setAccountNo(newVal)
                }}
                autoComplete='off'
                style={{width: '95%'}}
              />
            </Col>
            <Col xs={12} sm={6} md={4} className='align-items-center  px-2 mb-5'>
              <label className='required mb-2 label-color'>Bank Name</label>
              <input
                type='text'
                className='form-control form-control-solid-bg mytest'
                placeholder='Enter Bank Name'
                name='item_id'
                value={bankName}
                onChange={(e) => {
                  let newVal: any = capitalizeFirstLetter(e.target.value)
                  setBankName(newVal)
                }}
                autoComplete='off'
                style={{width: '95%'}}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={6} md={4} className='align-items-center px-2 mb-5'>
              <label className='required mb-2 label-color'>IBAN</label>
              <input
                type='text'
                className='form-control form-control-solid-bg mytest'
                placeholder='Enter IBAN'
                name='item_id'
                value={iban}
                onChange={(e) => {
                  let newVal: any = capitalizeFirstLetter(e.target.value)
                  setIban(newVal)
                }}
                autoComplete='off'
                style={{width: '95%'}}
              />
            </Col>
            <Col xs={12} sm={6} md={4} className='align-items-center px-2 mb-5'>
              <label className='required mb-2 label-color'>SWIFT Code</label>
              <input
                type='text'
                className='form-control form-control-solid-bg mytest'
                placeholder='Enter SWIFT Code'
                name='item_id'
                value={swift}
                onChange={(e) => {
                  let newVal: any = capitalizeFirstLetter(e.target.value)
                  setSwift(newVal)
                }}
                autoComplete='off'
                style={{width: '95%'}}
              />
            </Col>
          </Row>
        </div>
      </Modal>
    </div>
  )
}

export default AddAccount
