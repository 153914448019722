import React, {useState} from 'react'
import {Modal} from 'react-bootstrap'
import uploadWhite from '../../../../img/upload.svg'
import {ApiPost, Bucket, ApiGet} from '../../../../apiCommon/helpers/API/ApiData'
import pdfDocument from '../../../../img/pdf.svg'
import docCocument from '../../../../img/doc.svg'
import fileIcon from '../../../../img/file.svg'
import trashImg from '../../../../img/trash.png'
import {ErrorToast, SuccessToast} from '../../../../apiCommon/helpers/Toast'
import submitWhite from '../../../../img/Submit-White.png'
import {useParams} from 'react-router-dom'
import addImgGreen from '../../../../img/add-require-green.png'
import addImgWhite from '../../../../img/add-require-white.png'
import incompleteImgGreen from '../../../../img/job-incomplete-green.png'
import incompleteImgWhite from '../../../../img/job-incomplete-white.png'
import completeImgGreen from '../../../../img/job-complete-green.png'
import completeImgWhite from '../../../../img/job-completion-white.png'
import grayStar from '../../../../img/grey-star.png'
import yellowStar from '../../../../img/yellow-star.png'
import ModalLoader from '../../General/ModalLoader'
import videoImg from '../../../../img/video.png'

import { Fancybox as fancybox } from "@fancyapps/ui";
import '@fancyapps/ui/dist/fancybox/fancybox.css'

interface ButtonProps {
  show: any
  handleClose: any
  updateDetails?: any
  tenantData: any
}

const AddReport = ({show, handleClose, updateDetails, tenantData}: ButtonProps) => {
  const {id} = useParams()
  const [selectedType, setSelectedType] = useState<any>('')

  const userData: any = localStorage.getItem('userData')
  const user = JSON.parse(userData)

  const [isLoading, setIsLoading] = useState<any>(false)

  const [comment, setComment] = useState<any>('')
  const [attachments, setAttachments] = useState<any>([])

  const [closeJob, setCloseJob] = useState<any>(true)
  const [rating, setRating] = useState<any>(0)

  const [showLoader, setShowLoader] = useState<boolean>(false) // State to control the visibility of the modal loader
  const [uploadProgress, setUploadProgress] = useState<number>(0) // State to track the upload progress
  const [totalFiles, setTotalFiles] = useState<number>(0) // State to track the total number of files being uploaded

  const [uploadingText, setUploadingText] = useState<any>('Uploading')
  const [percentProgress, setPercentProgress] = useState(0)

  //
  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    setShowLoader(true) // Show the modal loader when file uploading starts

    const values: any[] = [...attachments]
    let newVal: number = values?.length - 1

    if (e.target.files && e.target.files[0]) {
      setTotalFiles(e.target.files.length)

      for (let i = 0; i < e.target.files.length; i++) {
        // file upload logic here

        let userData: any = localStorage.getItem('userData')
        const data = JSON.parse(userData)

        var formdata = new FormData()
        formdata.append('image', e.target.files[i])

        var videoFormdata = new FormData()
        videoFormdata.append('video', e.target.files[i])

        let name: any = e.target.files[i]?.name

        if (e?.target?.files[i]?.type?.split('/')[0] == 'video') {
          setUploadingText('Video Uploading')

          try {
            const resUpload = await ApiPost(`upload/video/maintenance_service_job`, videoFormdata)
            setUploadingText('Video Compressing')

            await new Promise<void>((resolve, reject) => {
              const eventSource = new EventSource(
                `https://api.propertise.com/upload/video/get_stream?videoPath=${resUpload?.data?.data?.url}`
              )
              eventSource.onmessage = (event) => {
                const eventData = JSON.parse(event.data)
                const {percentageOfCompleted} = eventData
                // console.log(`Compression progress: ${percentageOfCompleted}%`)
                setPercentProgress(percentageOfCompleted)

                if (Math.round(percentageOfCompleted) == 100) {
                  eventSource.close()
                  resolve()
                }
              }
            })

            // Compression completed, move to next iteration
            newVal = newVal + 1
            values[newVal] = resUpload?.data?.data?.url
            setUploadProgress(i + 1)
          } catch (error) {
            console.error('Error while uploading video:', error)
            // Handle error
          }
        } else {
          setUploadingText('Image Uploading')

          try {
            const res = await ApiPost(
              `upload/image/params?fileType=maintenance_service_job_report&databaseId=${data._id}&fileName=${name}`,
              formdata
            )
            newVal = newVal + 1
            values[newVal] = res?.data?.data?.image

            // update the upload progress
            setUploadProgress(i + 1)
          } catch (error) {
            console.error('Error while uploading image:', error)
            // Handle error
          }
        }
      }
    }

    setShowLoader(false) // Hide the modal loader when file uploading finishes
    setUploadProgress(0) // Reset upload progress
    setTotalFiles(0) // Reset total files count
    setAttachments(values)
  }

  const addReport = () => {
    setIsLoading(true)
    const body = {
      reportType: selectedType,
      reportStatus: selectedType == 2 && closeJob ? 2 : selectedType == 2 && !closeJob ? 1 : 0,
      submitByType: 0,
      replayByType:
        selectedType == 2 && closeJob ? undefined : selectedType == 2 && !closeJob ? 1 : 2,
      submitResponse: {
        description: comment,
        createdAt: new Date().toISOString(),
        createdBy: user?._id,
        attachments: attachments,
      },
      replayResponse:
        selectedType == 2 && closeJob
          ? {
              description: '',
              createdAt: new Date().toISOString(),
              createdBy: user?._id,
              attachments: [],
            }
          : undefined,
      maintenanceServiceJobId: id,
      jobRating: selectedType == 2 && closeJob ? rating : undefined,
    }
    ApiPost(`corporate/maintenance_service_job_report`, body)
      .then((res: any) => {
        SuccessToast(res?.data?.message)
        setIsLoading(false)
        updateDetails()
      })
      .catch((err: any) => {
        SuccessToast(err?.message)
        setIsLoading(false)
      })
  }

  React.useEffect(() => {
    // Initialize Fancybox
    fancybox.bind("[data-fancybox]", {
      // FancyBox options if any
    });

    return () => {
      fancybox.destroy();
    };
  }, []);

  return (
    <div className='schedule'>
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-700px mx-auto'
        show={show}
        onHide={() => {
          handleClose()
        }}
        backdrop={true}
        size='sm'
      >
        <div
          className='modal-body py-lg-7 px-lg-7 pt-lg-3'
          style={{
            transform: 'translate(0px, -70px)',
            top: '70px',
            borderRadius: '5px',
            boxShadow: '0px 0px 20px 0px #516176',
            minHeight: '600px',
            maxHeight: '650px',
            overflow: 'auto',
          }}
        >
          <div className='d-flex align-items-center'>
            <h2 className=' pt-5 mb-0'>
              <b>Job Report </b>
            </h2>

            <label
              className='head-text fs-2 cursor-pointer ms-auto'
              style={{fontWeight: '700'}}
              onClick={() => {
                handleClose()
              }}
            >
              X
            </label>
          </div>

          <div className='d-flex justify-content-center mt-5'>
            <h3 className='top ps-0'>
              <b>Select Report Type</b>
            </h3>
          </div>

          <div className='d-flex justify-content-evenly mt-5'>
            {/* Additional Requirements */}
            <div>
              <div
                className={`ms-4 ${selectedType == 3 ? 'mainRequestBoxColor' : 'mainRequestBox'} mt-3`}
                onClick={() => {
                  setSelectedType(3)
                }}
                style={{height: '65px', width: '75px', borderRadius: '5px', padding: '10px'}}
              >
                <img
                  src={selectedType == 3 ? addImgWhite : addImgGreen}
                  height={50}
                  width={50}
                  className='d-block'
                />
              </div>

              <h4
                className={`cursor-pointer report-text mt-1 
                  ${selectedType == 3 ? ' report-active  ' : ''}
                `}
                // style={{wordBreak: 'break'}}
              >
                Additional Requirements
              </h4>
            </div>

            {/* incomplete */}
            <div>
              <div
                className={`ms-4 ${selectedType == 1 ? 'mainRequestBoxColor' : 'mainRequestBox'} mt-3`}
                onClick={() => {
                  setSelectedType(1)
                }}
                style={{height: '65px', width: '75px', borderRadius: '5px', padding: '10px'}}
              >
                <img
                  src={selectedType == 1 ? incompleteImgWhite : incompleteImgGreen}
                  height={50}
                  width={50}
                  className='d-block'
                />
              </div>

              <h4
                className={`cursor-pointer report-text mt-1 
                  ${selectedType == 1 ? ' report-active  ' : ''}
                `}
              >
                Job Incomplete
              </h4>
            </div>

            {/* Complete */}
            <div>
              <div
                className={`ms-4 ${selectedType == 2 ? 'mainRequestBoxColor' : 'mainRequestBox'} mt-3`}
                onClick={() => {
                  setSelectedType(2)
                }}
                style={{height: '65px', width: '75px', borderRadius: '5px', padding: '10px'}}
              >
                <img
                  src={selectedType == 2 ? completeImgWhite : completeImgGreen}
                  height={50}
                  width={50}
                  className='d-block'
                />
              </div>

              <h4
                className={`cursor-pointer report-text mt-1 
                  ${selectedType == 2 ? ' report-active  ' : ''}
                `}
                style={{padding: '8px 10px'}}
              >
                Job Complete
              </h4>
            </div>
          </div>

          <div
            className='d-flex align-items-center mt-3 pb-5 '
            style={{borderBottom: '0.1rem solid #bec3cb'}}
          ></div>

          {selectedType == 2 && tenantData != undefined && (
            <div className='d-flex my-4 align-items-center justify-content-center mt-10 report pt-5'>
              <div className='form-check form-check-custom form-check-solid form-check-sm pe-2'>
                <input
                  className='form-check-input '
                  type='radio'
                  id='vacant0'
                  name='none'
                  checked={closeJob}
                  onChange={(e: any) => {
                    setCloseJob(true)
                  }}
                />
              </div>
              <p className='mb-0 me-10'>Close Job</p>
              <div className='form-check form-check-custom form-check-solid form-check-sm pe-2 '>
                <input
                  className='form-check-input '
                  type='radio'
                  id='vacant0'
                  name='none'
                  checked={!closeJob}
                  onChange={(e: any) => {
                    setCloseJob(false)
                  }}
                />
              </div>
              <p className='mb-0'>Submit to Tenant</p>
            </div>
          )}

          {selectedType != '' && (
            <>
              <div className='d-flex my-4 align-items-center justify-content-center mt-10'>
                <button
                  type='button'
                  className={'btn btn-sm fw-bold ms-3 green-submit-btn ps-3 status-w-130'}
                  onClick={(e: any) => {
                    addReport()
                  }}
                  disabled={isLoading ? true : false}
                >
                  <img src={submitWhite} height={19} width={19} className='me-4' /> {'  '}
                  {'Submit'}
                </button>
              </div>
              <div className='d-flex my-4 align-items-center justify-content-center text-center mt-5'>
                {selectedType == 2 ? (
                  <>
                    {closeJob ? (
                      <h3 className='top ps-0 d-block'>
                        <i className=''>
                          {' '}
                          You will be able to directly close the job and provide the final rating
                          score.
                        </i>
                      </h3>
                    ) : (
                      <h3 className='top ps-0 d-block'>
                        <i className=''>
                          {' '}
                          Tenant will receive a request to confirm job completion and provide the
                          final job rating. If approved by the tenant, the job will automatically be
                          set as complete and the report will be approved. If rejected, the job will
                          remain open and the report will be rejected.
                        </i>
                      </h3>
                    )}
                  </>
                ) : (
                  <h3 className='top ps-0 d-block'>
                    <i className=''> This report will be submitted to the handling team.</i>
                  </h3>
                )}
              </div>

              <div className='card card-flush  mt-10 py-5 px-7'>
                <div className='d-flex justify-content-center mt-2'>
                  <h2 className='top ps-0'>
                    <b>Report Details</b>
                  </h2>
                </div>

                {/* Comments */}
                <div className='d-flex mt-10'>
                  <h3 className='top ps-0 '>
                    <b> Your Comments</b>{' '}
                  </h3>
                </div>

                <div className='d-flex mt-1'>
                  <textarea
                    className='form-control form-control-solid m-0 mytest'
                    rows={3}
                    name='comment'
                    placeholder='Enter report details here...'
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                  ></textarea>
                </div>

                {selectedType == 2 && closeJob && (
                  <>
                    <div className='d-flex mt-10'>
                      <h3 className='top ps-0'>
                        <b> Job Rating</b>{' '}
                      </h3>
                    </div>

                    <div className='d-flex mt-1'>
                      <p className='top ps-0'>
                        <i> How would you rate the overall delivery of your job?</i>{' '}
                      </p>
                    </div>

                    <div className='d-flex mt-1'>
                      <img
                        src={rating >= 1 ? yellowStar : grayStar}
                        height={35}
                        width={35}
                        className='cursor-pointer'
                        onClick={() => setRating(1)}
                      />
                      <img
                        src={rating >= 2 ? yellowStar : grayStar}
                        height={35}
                        width={35}
                        className='cursor-pointer'
                        onClick={() => setRating(2)}
                      />
                      <img
                        src={rating >= 3 ? yellowStar : grayStar}
                        height={35}
                        width={35}
                        className='cursor-pointer'
                        onClick={() => setRating(3)}
                      />
                      <img
                        src={rating >= 4 ? yellowStar : grayStar}
                        height={35}
                        width={35}
                        className='cursor-pointer'
                        onClick={() => setRating(4)}
                      />
                      <img
                        src={rating >= 5 ? yellowStar : grayStar}
                        height={35}
                        width={35}
                        className='cursor-pointer'
                        onClick={() => setRating(5)}
                      />
                    </div>
                  </>
                )}

                {/* Attachments */}
                <div className='d-flex mt-10'>
                  <div className='d-block'>
                    <h3 className='top ps-0 mb-0'>
                      <b>Attachments</b>
                    </h3>
                    <h3 className='top ps-0'>
                      <i className=''> (Attach supporting images or videos)</i>
                    </h3>
                  </div>

                  <>
                    <label
                      className='btn btn-sm fw-bold  me-0 blue-btn ps-3 pe-10 ms-auto btn-fit-content'
                      onClick={() => {}}
                      style={{width: 'fit-content'}}
                      htmlFor='uploadNew'
                    >
                      <img src={uploadWhite} height={18} width={18} className='me-3' />
                      Upload
                    </label>
                    <input
                      type='file'
                      hidden
                      id='uploadNew'
                      className='form-control form-control-solid'
                      placeholder='upload'
                      name='newTenant'
                      onChange={(e: any) => {
                        handleChange(e)
                      }}
                      multiple
                      disabled={isLoading}
                    />
                  </>
                </div>

                <div
                  className='d-flex flex-wrap mt-5'
                  style={{maxHeight: '250px', overflow: 'auto'}}
                >
                  {attachments?.length > 0 && (
                    <div className='tops d-flex gap-5 align-items-start flex-wrap'>
                      {attachments?.map((file: any, ind: any) => {
                        const videoUrl = file
                        const filename = videoUrl.substring(videoUrl.lastIndexOf('/') + 1)
                        const filenameWithoutExtension = filename.substring(
                          0,
                          filename.lastIndexOf('.')
                        )
                        const thumbnailUrl = videoUrl.replace(
                          filename,
                          `thumbnail_${filenameWithoutExtension}.png`
                        )
                        return (
                          <>
                            <div className='imgs-set'>
                              <div className='d-flex mw-350px me-3 '>
                                {/* <a target='_blank' href={`${Bucket}${file}`}> */}
                                  <img
                                    src={
                                      file?.split('.')[1] === 'pdf'
                                        ? pdfDocument
                                        : file?.split('.').pop() === 'doc' ||
                                          file?.split('.').pop() === 'docx'
                                        ? docCocument
                                        : file?.split('.').pop() === 'jpeg' ||
                                          file?.split('.').pop() === 'jpg' ||
                                          file?.split('.').pop() === 'png' ||
                                          file?.split('.').pop() === 'svg' ||
                                          file?.split('.').pop() === 'gif'
                                        ? `${Bucket}${file}`
                                        : file?.split('.').pop()?.toLowerCase() === 'mp4' ||
                                          file?.split('.').pop()?.toLowerCase() === 'webm' ||
                                          file?.split('.').pop()?.toLowerCase() === 'avi' ||
                                          file?.split('.').pop()?.toLowerCase() === 'mkv' ||
                                          file?.split('.').pop()?.toLowerCase() === 'mov' ||
                                          file?.split('.').pop()?.toLowerCase() === 'flv' ||
                                          file?.split('.').pop()?.toLowerCase() === 'wmv'
                                        ? `${Bucket}${thumbnailUrl}`
                                        : fileIcon
                                    }
                                    // src={Bucket + file}
                                    width='100'
                                    height='100'
                                    className='main_img mb-5'
                                    data-fancybox
                                    data-src={`${Bucket}${file}`}
                                  />
                                {/* </a> */}

                                <img
                                  src={trashImg}
                                  height={15}
                                  width={15}
                                  className='cursor-pointer ms-2 mt-1'
                                  onClick={() => {
                                    const values = [...attachments]
                                    values.splice(ind, 1)
                                    setAttachments(values)
                                  }}
                                />
                              </div>
                            </div>
                          </>
                        )
                      })}
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </Modal>

      <ModalLoader
        isVisible={showLoader}
        progress={uploadProgress}
        totalFiles={totalFiles}
        uploadingText={uploadingText}
        percentageProgress={percentProgress}
      />
    </div>
  )
}

export default AddReport
