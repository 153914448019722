import React, {useState, useRef, useEffect} from 'react'
import {
  Container,
  Form,
  InputGroup,
  Button,
  Card,
  Modal,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap'
import chat from '../../../img/chat.svg'
import user from '../../../img/user.svg'
import userwhite from '../../../img/userwhite.svg'
import send from '../../../img/sendwhite.svg'
import plus from '../../../img/plus.svg'
import filter from '../../../img/filter.svg'
import chatback from '../../../img/chatback.jpg'
import closes from '../../../img/closegreen.svg'
import leftarrow from '../../../img/arrowleft.svg'
import logo from '../../../img/Logo/Logo_Green_L.svg'
import All from './All'
import Internal from './Internal'
import Tenants from './Tenants'
import {useDispatch, useSelector} from 'react-redux'
import {setChatComeFrom, setChatDataSearch, setChatScreen} from '../../../redux/counterSlice'
import {ApiGet, ApiPost} from '../../../apiCommon/helpers/API/ApiData'
import moment from 'moment'

import io from 'socket.io-client'
const socket = io('https://api.propertise.com/room')

// import socket from '../General/Socket'

interface ChatMessage {
  author: string
  message: string
  createdAt: any
}

const Chat = () => {
  const myDivRef = useRef(null)
  const searchRef = useRef(null)
  const bottomRef = useRef<any>(null)

  const [chatData, setChatData] = useState<any>([])
  const chatScreen = useSelector((state: any) => state.counterReducer.chatScreen)
  const chatSelectedDataList = useSelector(
    (state: any) => state.counterReducer.chatSelectedDataList
  )
  const dispatch = useDispatch()
  const [messages, setMessages] = useState<any>([])
  const [author, setAuthor] = useState<any>('')
  const [message, setMessage] = useState<any>('')
  const [showChat, setShowChat] = useState<any>(chatScreen)
  const [activeChat, setactiveChat] = useState<any>(-1)
  const [showFilterCard, setShowFilterCard] = useState<any>(false)
  const [chatTab, setchatTab] = useState<any>('All')
  const [totalArr, settotalArr] = useState<any>([])
  const [room_id, setRoom_id] = useState<any>()
  const [modalopen, setmodalopen] = useState<any>(false)
  const [name, setName] = useState<any>('')
  const [modalData, setModalData] = useState<any>('')
  const [bodyWithSubject, SetBodyWithSubject] = useState<any>({})
  const [finalIdData, setFinalIdData] = useState<any>({})
  const [defaultRoomData, setDefaultRoomData] = useState<any>(false)
  let user: any = localStorage.getItem('userData')
  user = JSON.parse(user)
  const [chatId, setChatId] = useState<any>('')
  const [msgPage, setMsgPage] = useState<any>(1)
  const [msgPageLimit, setMsgPageLimit] = useState<any>(false)

  useEffect(() => {
    console.log(chatSelectedDataList)
    SetBodyWithSubject(chatSelectedDataList)
    setFinalIdData({
      ...chatSelectedDataList,
      staffIds: chatSelectedDataList?.staffIds?.map((e: any) => e._id),
      tenantIds: chatSelectedDataList?.tenantIds?.map((e: any) => e),
    })
    if (chatSelectedDataList?.staffIds?.length || chatSelectedDataList?.tenantIds?.length) {
      settotalArr([...chatSelectedDataList?.staffIds, ...chatSelectedDataList?.tenantIds])
    }
  }, [chatSelectedDataList])

  useEffect(() => {
    if (bottomRef.current) {
      bottomRef.current.scrollTop = bottomRef.current?.scrollHeight
    }
  }, [messages])

  useEffect(() => {
    socket.off('receive_message')
    socket.on('receive_message', (message: any) => {
      setMessages((prevMessages: any) => [...prevMessages, message])
      console.log('\nmessage', message, user._id)
    })
  }, [])
  const handleJoinRoom = (room: any) => {
    console.log('\njoin room')

    socket.emit('join_room', room)
  }
  const handleSendMessage = (message: any) => {
    socket.off('send_message')
    socket.emit('send_message', message)
  }
  useEffect(() => {
    console.log('clicked')
    socket.emit('online', {userId: user._id}, (res: any) => {
      console.log('socket res', res)
    })
  }, [])

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    console.log('clicked handleSubmit')

    let tIds: any = []
    let tncIds: any = []

    console.log(bodyWithSubject)
    console.log(defaultRoomData)

    bodyWithSubject?.tenantIds?.map((tn: any, ind: any) => {
      tIds[ind] = tn?.tenant?.[0]?._id
    })

    let body = {
      tenantIds: tIds,
      tenancyId: bodyWithSubject?.tenantIds?.[0]?._id,
      subjectTitle: bodyWithSubject?.subjectTitle,
      type: 0,
    }

    console.log(body)

    // console.log('\nbodyWithSubject', bodyWithSubject)
    if (defaultRoomData) {
      handleSendMessage({
        roomId: defaultRoomData?._id,
        message: message,
        senderId: user._id,
        receiverId: chatSelectedDataList?.staffIds?.[0],
        senderType: 0,
        receiverType: 1,
      })
      // setMessages([...messages, {author, message, createdAt: new Date()}])
      setAuthor('')
      setMessage('')
    } else {
      const newObj = {
        ...bodyWithSubject,
        staffIds: bodyWithSubject.staffIds.map((value: any) => value._id),
        tenantIds: bodyWithSubject.tenantIds.map((value: any) => value._id),
      }
      console.log('corporate/chat_room', newObj)
      await ApiPost(`corporate/chat_room`, body)
        .then(async (res) => {
          console.log('\nres.data.data', res.data.data)

          handleSendMessage({
            roomId: res.data.data?._id,
            message: message,
            senderId: user._id,
            receiverId: chatSelectedDataList?.staffIds?.[0],
            senderType: 0,
            receiverType: 1,
          })
          openMessage(e, 0, {_id: res.data.data?._id})
          // setMessages([...messages, {author, message, createdAt: new Date()}])
          setAuthor('')
          setMessage('')

          getChatList()
        })
        .catch((err) => {
          console.log('res_blob', err)
        })
    }
  }
  const dateTotime = (dd: any) => {
    return new Date(dd).toLocaleString()
  }
  const [page, setPage] = useState<any>(1)
  const [pageLimit, setPageLimit] = useState<any>(false)
  const getChatList = async () => {
    await ApiPost(`corporate/chat_room/get`, {
      limit: 25,
      page: page,
    })
      .then(async (res) => {
        setChatData([...res?.data?.data?.chat_room_data])
        // setPage(page + 1)
        setPageLimit(res?.data?.data?.state?.page_limit)
      })
      .catch((err) => {
        console.log('res_blob', err)
      })
  }

  useEffect(() => {
    getChatList()
    setShowChat(chatScreen)
  }, [chatScreen])

  const openMessage = async (e: any, el: any, chat: any) => {
    setChatId(chat._id)
    setRoom_id(chat)
    setMessages([])
    setactiveChat(el)

    setShowChat('message')
    await ApiGet(`corporate/chat_room/${chat._id}`)
      .then((res) => {
        setDefaultRoomData(res.data.data)
        setRoom_id(res.data.data)
        fetchMessage(chat._id, 1, [])
      })
      .catch((err) => {
        console.log('res_blob', err)
      })
    handleJoinRoom({
      roomId: chat._id,
      userId: user._id,
    })
  }

  const fetchMessage = async (chatId: any, searchPage: any, messages: any) => {
    await ApiPost(`corporate/chat_message/get`, {
      limit: 25,
      page: searchPage,
      chatRoomId: chatId,
    })
      .then((res) => {
        setMessages([...messages, ...res?.data?.data?.chat_message_data])
        setMsgPage(msgPage + 1)
        setMsgPageLimit(res?.data?.data?.state?.page_limit)
      })
      .catch((err) => {
        console.log('res_blob', err)
      })
  }

  const openNewMeassage = () => {
    setMessages([])
    setDefaultRoomData(false)
    setactiveChat(-1)
    setShowChat('newMessage')
    settotalArr([])
  }

  const [searchPage, setSearchPage] = useState<any>(1)
  const [searchPageLimit, setSearchPageLimit] = useState<any>(false)
  const searchFromAll = async (name: any, tab: any, searchPage: any) => {
    setName(name)
    let tab_data: any = {
      isAll: true,
      isInternal: false,
      isTenant: false,
    }
    if (tab == 'All') {
      tab_data = {
        isAll: true,
        isInternal: false,
        isTenant: false,
      }
    } else if (tab == 'Internal') {
      tab_data = {
        isAll: false,
        isInternal: true,
        isTenant: false,
      }
    } else if (tab == 'Tenants') {
      tab_data = {
        isAll: false,
        isInternal: false,
        isTenant: true,
      }
    }
    let body = {
      limit: 25,
      page: searchPage,
      searchName: name,
      ...tab_data,
    }
    await ApiPost(`corporate/chat_room/get_user`, body)
      .then((res) => {
        setSearchPageLimit(res?.data?.data?.state?.page_limit)
        dispatch(setChatDataSearch(res?.data?.data?.user_data || []))
      })
      .catch((err) => {
        console.log('res_blob', err)
      })
  }
  useEffect(() => {
    searchFromAll('', 'All', searchPage)
  }, [])

  const onChangeTab = (tab: any) => {
    setchatTab(tab)
    setSearchPage(1)

    searchFromAll('', tab, 1)
  }

  const changeSubject = (e: any) => {
    let temp = {
      ...finalIdData,
      subjectTitle: e.target.value,
    }
    SetBodyWithSubject(temp)
  }

  const userScroll = (e: any) => {
    console.log('scrolling')
    if (myDivRef.current) {
      const divElement: any = myDivRef.current // Access the div element using the ref
      if (divElement.scrollTop === divElement.scrollHeight - divElement.clientHeight) {
        if (page <= pageLimit) {
          getChatList()
        }
      }
    }
    if (bottomRef.current) {
      const divElement: any = bottomRef.current // Access the div element using the ref
      if (divElement.scrollTop === 0) {
        if (msgPage <= msgPageLimit) {
          fetchMessage(chatId, msgPage, messages)
        }
      }
    }
    if (searchRef.current) {
      const serarchElement: any = searchRef.current // Access the div element using the ref

      if (serarchElement.scrollTop === serarchElement.scrollHeight - serarchElement.clientHeight) {
        if (searchPage < searchPageLimit) {
          setSearchPage(searchPage + 1)
          searchFromAll(name, chatTab, searchPage + 1)
        }
      }
    }
  }

  return (
    <Container fluid className='text-muted-1-2-fr px-2' style={{gap: '2.5rem'}}>
      <div
        style={{
          position: 'sticky',
          top: 80,
          height: '80vh',
          display: 'flex',
          flexDirection: 'column',
          overflowY: 'scroll',
        }}
        className='card container-xxl py-5'
      >
        <>
          <div className='d-flex align-items-center gap-3'>
            <input
              type='text'
              placeholder='Search'
              className='form form-control m-0 msg_search_chat '
            />
            <img
              src={filter}
              alt=''
              width='24px'
              className='chat_filter'
              onClick={() => setShowFilterCard(!showFilterCard)}
            />
          </div>
          <ul className={showFilterCard ? 'text-center m-0 p-0 card chat_filter_drop' : 'd-none'}>
            <li className='p-1' onClick={() => setShowFilterCard(!showFilterCard)}>
              All
            </li>
            <li className='p-1' onClick={() => setShowFilterCard(!showFilterCard)}>
              Unread
            </li>
            <li className='p-1' onClick={() => setShowFilterCard(!showFilterCard)}>
              Tenants
            </li>
            <li className='p-1' onClick={() => setShowFilterCard(!showFilterCard)}>
              Internal
            </li>
          </ul>
          <div className='d-flex align-items-center my-5' style={{justifyContent: 'space-between'}}>
            <p
              className='btn btn-sm fw-bold btn-primary btn-green m-0'
              onClick={() => {
                // setNewMessageModal(true)
                setDefaultRoomData(false)
                openNewMeassage()
              }}
            >
              <img src={plus} /> New Message
            </p>
            <div className='d-flex gap-3'></div>
          </div>
          <ul
            className='p-0 m-0 my-5'
            style={{height: '80vh', overflowY: 'scroll'}}
            ref={myDivRef}
            onScroll={(e: any) => userScroll(e)}
          >
            {chatData.map((chat: any, index: any) => (
              <li
                className={
                  activeChat == index
                    ? 'd-flex align-items-center gap-5 p-5 lis act'
                    : 'd-flex align-items-center gap-5 p-5 lis'
                }
                style={{borderBottom: '1px solid #aaaaaa50'}}
                onClick={(e: any) => openMessage(e, index, chat)}
              >
                {chat?.type == 1 ? (
                  <div className='symbol-group symbol-hover flex-nowrap'>
                    {chat?.userList?.slice(0, 2).map((el: any, i: any) => (
                      <div>
                        <div className='symbol-group symbol-hover m-0'>
                          <div className='symbol symbol-40px symbol-circle '>
                            <img alt='Pic' src={`media/avatars/300-${i + 1}.jpg`} />
                          </div>
                        </div>

                        <h6 className='m-0'>
                          {el?.firstName} {el?.lastName}
                        </h6>
                      </div>
                    ))}

                    {chat?.userList?.length > 2 ? (
                      <a
                        href='#'
                        className='symbol symbol-40px symbol-circle'
                        data-bs-toggle='modal'
                        data-bs-target='#kt_modal_view_users'
                      >
                        <span
                          className='symbol-label fs-8 fw-bold green_color'
                          data-bs-toggle='tooltip'
                          data-bs-trigger='hover'
                          data-bs-original-title='View more users'
                          data-kt-initialized='1'
                          onClick={() => {
                            // setmodalopen(true)
                            // console.log('\nsetmodalopen(true)', modalopen)
                          }}
                        >
                          +{chat?.userList?.length - 2}
                        </span>
                      </a>
                    ) : null}
                  </div>
                ) : (
                  <div className='symbol symbol-40px symbol-circle '>
                    <img alt='Pic' src='media/avatars/300-23.jpg' />
                  </div>
                )}
                <div className='grid-auto-new'>
                  <div className='d-flex flex-column'>
                    <>
                      {chat?.type !== 1 ? (
                        <h6 className='m-0'>
                          {chat?.userList[0]?.firstName} {chat?.userList[0]?.lastName}
                        </h6>
                      ) : null}
                      <h6 className='m-0'>{chat.subjectTitle}</h6>
                    </>
                  </div>
                  <div className='d-flex flex-column'>
                    <p className='m-0 small_grey'>{moment(chat.createdAt).format('HH:MM')}</p>
                    <p className='m-0 small_grey'></p>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </>
      </div>
      {showChat == false ? (
        <div
          style={{height: '80vh', zIndex: '-1', overflow: 'hidden'}}
          className='card container-xxl gap-5 d-flex justify-content-center flex-column p-0 align-items-center'
        >
          <img src={chatback} alt='' className='chatback' />
          <img
            src={logo}
            alt='Metronic logo'
            width='110'
            style={{
              height: '150px !important',
              zIndex: '1',
              background: 'transparent',
            }}
          />
          {/* <h1 className='green_color'>Propertise chat</h1> */}
        </div>
      ) : null}
      {showChat == 'newMessage' || showChat == 1 || showChat == 2 || showChat == 3 ? (
        <div
          style={{height: '80vh', display: 'flex', flexDirection: 'column'}}
          className='card container-xxl p-0'
        >
          <div
            className='symbol-group symbol-hover p-5'
            style={{background: '#fff', borderRadius: '10px 10px 0 0'}}
          >
            {totalArr?.length > 0 ? (
              <>
                {totalArr?.slice(0, 4).map((el: any, i: any) => (
                  <>
                    <div className='symbol-group symbol-hover m-0'>
                      <div className='symbol symbol-40px symbol-circle '>
                        <OverlayTrigger
                          overlay={
                            <Tooltip>
                              {el?.tenant?.[0]?.firstName} {el?.tenant?.[0]?.lastName}
                            </Tooltip>
                          }
                          placement='bottom'
                        >
                          <img alt='Pic' src={`media/avatars/300-${i + 1}.jpg`} />
                        </OverlayTrigger>
                      </div>
                    </div>
                    <div className='ps-3'>
                      {' '}
                      {el?.tenant?.[0]?.firstName} {el?.tenant?.[0]?.lastName}
                    </div>
                  </>
                ))}

                {totalArr?.length > 4 ? (
                  <a
                    href='#'
                    className='symbol symbol-40px symbol-circle'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_view_users'
                  >
                    <span
                      className='symbol-label fs-8 fw-bold green_color'
                      data-bs-toggle='tooltip'
                      data-bs-trigger='hover'
                      data-bs-original-title='View more users'
                      data-kt-initialized='1'
                      onClick={() => {
                        setmodalopen(true)
                        setModalData('newMessage')
                        // console.log('\nsetmodalopen(true)', modalopen)
                      }}
                    >
                      +{totalArr?.length - 4}
                    </span>
                  </a>
                ) : null}
              </>
            ) : (
              <h6 className='m-0 p-0'>
                <a
                  className='btn btn-sm fw-bold btn-primary btn-green m-0 align-items-center d-flex gap-2'
                  onClick={() => {
                    onChangeTab('Tenants')
                    setShowChat('addNew')
                    dispatch(setChatComeFrom('page'))
                    dispatch(setChatScreen('addNew'))
                  }}
                >
                  Select Recipient
                </a>
              </h6>
            )}
          </div>
          <h6
            className='d-flex gap-5 mx-5 align-items-center pb-5'
            style={{borderBottom: '1px solid #aaa'}}
          >
            <label htmlFor='subject'>Subject</label>
            <input
              type='text'
              className='form-control form-control-solid mr-5'
              name='subject'
              id='subject'
              onChange={(e: any) => changeSubject(e)}
            />
          </h6>
          <div
            style={{flex: 1, overflowY: 'auto', marginBottom: '6rem', height: '100%'}}
            ref={bottomRef}
          >
            {messages
              ?.sort(
                (a: any, b: any) =>
                  new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
              )
              ?.map((msg: any, index: any) => (
                <div className={`${msg.senderId}` !== `${user._id}` ? 'flex-starts' : 'flex-ends'}>
                  <Card
                    style={{
                      maxWidth: '70%',
                      margin: '10px',
                      alignSelf: `${msg.senderId}` !== `${user._id}` ? 'flex-end' : 'flex-start',
                    }}
                  >
                    <Card.Body
                      className={
                        `${msg.senderId}` !== `${user._id}`
                          ? 'rec_msg br-5 p-0 m-0'
                          : 'send_msg br-5 p-0 m-0'
                      }
                    >
                      <p className='p-0 m-0 small_font'>
                        {msg?.senderUser?.firstName + ' ' + msg?.senderUser?.lastName}
                      </p>
                      <div className='px-5 pb-5 pt-0'>
                        <h4 className='m-0 p-0 pt-5'>{msg.message}</h4>
                      </div>
                    </Card.Body>
                    <span className='m-0 small_font'>{dateTotime(msg?.createdAt)}</span>
                  </Card>
                </div>
                // <ChatCard
                //   key={index}
                //   author={msg.author}
                //   message={msg.message}
                //   timestamp={msg.timestamp}
                // />
              ))}
          </div>
          <div style={{position: 'absolute', bottom: 25, width: '100%'}} className='px-5'>
            <Form onSubmit={handleSubmit}>
              <InputGroup>
                <Form.Control
                  type='text'
                  placeholder='Type a message'
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
                <Button variant='primary' type='submit' className='btn-green'  disabled={!message || totalArr?.length == 0}>
                  <img src={send} width='24px' />
                </Button>
              </InputGroup>
            </Form>
          </div>
        </div>
      ) : null}
      {showChat == 'message' ? (
        <>
          <div
            style={{height: '80vh', display: 'flex', flexDirection: 'column'}}
            className='card container-xxl p-0'
          >
            <div
              className='symbol-group symbol-hover p-5'
              style={{background: '#fff', borderRadius: '10px 10px 0 0'}}
            >
              {defaultRoomData?.userList?.slice(0, 4).map((el: any, i: any) => (
                <div className='d-flex'>
                <div className='symbol-group symbol-hover m-0'>
                  <div className='symbol symbol-40px symbol-circle '>
                    <OverlayTrigger
                      overlay={
                        <Tooltip>
                          {el.firstName} {el.lastName}
                        </Tooltip>
                      }
                      placement='bottom'
                    >
                      <img alt='Pic' src={`media/avatars/300-${i + 1}.jpg`} />

                      
                    </OverlayTrigger>
                    {/* <h6 className="ps-2">{el?.firstName} {el?.lastName}</h6> */}
                    </div>
                    {/* <Button bsStyle='default'>Holy guacamole!</Button> */}
                    {/* <img alt='Pic' src={`media/avatars/300-${i + 1}.jpg`} /> */}

                    {/* <LinkWithTooltip tooltip='Default 123 tooltip' id='tooltip-1'>
                      <img alt='Pic' src={`media/avatars/300-${i + 1}.jpg`} />
                    </LinkWithTooltip> */}
                  </div>
                </div>
              ))}

              {defaultRoomData?.userList?.length > 4 ? (
                <a
                  href='#'
                  className='symbol symbol-40px symbol-circle'
                  data-bs-toggle='modal'
                  data-bs-target='#kt_modal_view_users'
                >
                  <span
                    className='symbol-label fs-8 fw-bold green_color'
                    data-bs-toggle='tooltip'
                    data-bs-trigger='hover'
                    data-bs-original-title='View more users'
                    data-kt-initialized='1'
                    onClick={() => {
                      setmodalopen(true)
                      setModalData('chat')
                      // console.log('\nsetmodalopen(true)', modalopen)
                    }}
                  >
                    +{defaultRoomData?.userList?.length - 4}
                  </span>
                </a>
              ) : null}

              {room_id?.userList?.length == 1 ? (
                <h4 className='m-5'>
                  {room_id?.userList[0]?.firstName} {room_id?.userList[0]?.lastName}
                </h4>
              ) : null}
            </div>
            <h6
              className='d-flex gap-5 mx-5 align-items-center pb-5'
              style={{borderBottom: '1px solid #aaa'}}
            >
              <label htmlFor='subject'>Subject</label>
              <input
                type='text'
                className='form-control form-control-solid mr-5'
                name='subject'
                id='subject'
                value={defaultRoomData?.subjectTitle || ''}
                disabled={defaultRoomData?.subjectTitle ? true : false}
                onChange={(e: any) => changeSubject(e)}
              />
            </h6>
            <div
              style={{flex: 1, overflowY: 'auto', marginBottom: '6rem', height: '100%'}}
              ref={bottomRef}
              onScroll={(e: any) => userScroll(e)}
            >
              {messages
                ?.sort(
                  (a: any, b: any) =>
                    new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
                )
                ?.map((msg: any, index: any) => (
                  <div
                    // ref={bottomRef}
                    className={`${msg.senderId}` !== `${user._id}` ? 'flex-starts' : 'flex-ends'}
                  >
                    <Card
                      style={{
                        maxWidth: '70%',
                        margin: '10px',
                        alignSelf: `${msg.senderId}` !== `${user._id}` ? 'flex-end' : 'flex-start',
                      }}
                    >
                      <Card.Body
                        className={
                          `${msg.senderId}` !== `${user._id}`
                            ? 'rec_msg br-5 p-0 m-0'
                            : 'send_msg br-5 p-0 m-0'
                        }
                      >
                        <p className='p-0 m-0 small_font'>
                          {msg?.senderUser?.firstName + ' ' + msg?.senderUser?.lastName}
                        </p>
                        <div className='px-5 pb-5 pt-0'>
                          <h4 className='m-0 p-0 pt-5'>{msg.message}</h4>
                        </div>
                      </Card.Body>
                      <span className='m-0 small_font'>{dateTotime(msg?.createdAt)}</span>
                    </Card>
                  </div>
                  // <ChatCard
                  //   key={index}
                  //   author={msg.author}
                  //   message={msg.message}
                  //   timestamp={msg.timestamp}
                  // />
                ))}
            </div>
            <div style={{position: 'absolute', bottom: 25, width: '100%'}} className='px-5'>
              <Form onSubmit={handleSubmit}>
                <InputGroup>
                  <Form.Control
                    type='text'
                    placeholder='Type a message'
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                  <Button
                    variant='primary'
                    type='submit'
                    className='btn-green'
                    disabled={!message}
                  >
                    <img src={send} width='24px' />
                  </Button>
                </InputGroup>
              </Form>
            </div>
          </div>
        </>
      ) : null}
      {showChat == 'addNew' ? (
        <div
          style={{height: '80vh', display: 'flex', flexDirection: 'column', alignItems: 'center'}}
          className='card container-xxl p-0'
        >
          <div className='new_add'>
            <span
              style={{cursor: 'pointer'}}
              onClick={() => {
                setShowChat('newMessage')
              }}
            >
              <img src={leftarrow} />
            </span>
            <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 mx-auto fs-4 fw-semibold mb-n2 scr '>
              {/* <li
                className={
                  `nav-item cursor-pointer text-center min-w-125px ` +
                  (chatTab === 'All' ? 'active' : '')
                }
                onClick={() => {
                  onChangeTab('All')
                }}
              >
                <a
                  className={
                    `nav-link text-active-primary pb-4 text-center mx-5 ` +
                    (chatTab === 'All' ? 'active' : '')
                  }
                  data-bs-toggle='tab'
                >
                  All
                </a>
              </li> */}
              {/* <li
                className={
                  `nav-item cursor-pointer text-center min-w-125px ` +
                  (chatTab === 'Internal' ? 'active' : '')
                }
                onClick={() => {
                  onChangeTab('Internal')
                }}
              >
                <a
                  className={
                    `nav-link text-active-primary pb-4 text-center mx-5 ` +
                    (chatTab === 'Internal' ? 'active' : '')
                  }
                  data-bs-toggle='tab'
                >
                  Internal
                </a>
              </li> */}
              <li
                className={
                  `nav-item cursor-pointer text-center min-w-125px ` +
                  (chatTab === 'Tenants' ? 'active' : '')
                }
                onClick={() => {
                  onChangeTab('Tenants')
                }}
              >
                <a
                  className={
                    `nav-link text-active-primary pb-4 text-center mx-5 ` +
                    (chatTab === 'Tenants' ? 'active' : '')
                  }
                  data-bs-toggle='tab'
                >
                  Tenants
                </a>
              </li>
            </ul>
          </div>
          <div className='internal p-5' style={{overflowY: 'scroll', width: '90%'}}>
            <input
              type='text'
              placeholder='Search'
              className='form form-control m-0 msg_search_chat '
              value={name}
              onChange={(e: any) => searchFromAll(e.target.value, chatTab, searchPage)}
            />
            <div
              className='my-5 table-responsive '
              onScroll={(e: any) => userScroll(e)}
              ref={searchRef}
              // style={{height: '50vh', overflowY: 'scroll'}}
            >
              {chatTab == 'All' ? (
                <All />
              ) : chatTab == 'Internal' ? (
                <Internal />
              ) : chatTab == 'Tenants' ? (
                <Tenants />
              ) : null}
            </div>
          </div>
        </div>
      ) : null}
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered'
        show={modalopen}
        backdrop={true}
        size='sm'
      >
        <div className='m-auto'>
          <div className='modal-content p-5' style={{width: '460px'}}>
            <div className='scroll-y d-flex flex-column gap-5'>
              <div className='align-items-center make_grid'>
                <h1 className='m-0 text-center green_color'>Users List</h1>
                <div
                  className='btn btn-sm btn-icon btn-active-color-primary'
                  data-bs-dismiss='modal'
                  onClick={() => setmodalopen(false)}
                >
                  <img src={`${closes}`} alt='' />
                </div>
              </div>

              <div style={{height: '50vh', overflowY: 'scroll'}}>
                {modalData == 'newMessage' && totalArr?.length > 0
                  ? totalArr?.map((el: any, i: any) => (
                      <div className='d-flex flex-stack p-5 border-bottom border-gray-300 border-bottom-dashed'>
                        <div className='new_add' style={{gap: '1rem'}}>
                          <div className='symbol symbol-40px symbol-circle'>
                            <img alt='Pic' src={`media/avatars/300-${i + 1}.jpg`} />
                          </div>

                          <div
                            className='d-flex align-items-center '
                            style={{justifyContent: 'space-between'}}
                          >
                            <h6 className='m-0'>
                              {el?.firstName} {el.lastName}
                            </h6>
                            <span className='badge badge-light fs-8 fw-semibold ms-2'>
                              {el.role}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))
                  : null}
                {modalData == 'chat' && defaultRoomData?.userList?.length > 0
                  ? defaultRoomData?.userList?.map((el: any, i: any) => (
                      <div className='d-flex flex-stack py-5 border-bottom border-gray-300 border-bottom-dashed'>
                        <div className='new_add' style={{gap: '1rem'}}>
                          <div className='symbol symbol-40px symbol-circle'>
                            <img alt='Pic' src={`media/avatars/300-${i + 1}.jpg`} />
                          </div>
                          <div
                            className='d-flex align-items-center '
                            style={{justifyContent: 'space-between'}}
                          >
                            <h6 className='m-0'>
                              {el?.firstName} {el.lastName}
                            </h6>
                            <span className='badge badge-light fs-8 fw-semibold ms-2'>
                              {el.role}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))
                  : null}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </Container>
  )
}

export default Chat

{
  /* <div className='card-title'>
        <div className='symbol-group symbol-hover'>
          <div className='symbol  symbol-35px symbol-circle '>
            <img alt='Pic' src='media/avatars/300-23.jpg' />
          </div>
          <div className='symbol  symbol-35px symbol-circle '>
            <img alt='Pic' src='media/avatars/300-12.jpg' />
          </div>
          <div className='symbol  symbol-35px symbol-circle '>
            <span className='symbol-label  bg-light-warning text-warning 40px '>C</span>
          </div>
          <div className='symbol  symbol-35px symbol-circle '>
            <img alt='Pic' src='media/avatars/300-1.jpg' />
          </div>
          <div className='symbol  symbol-35px symbol-circle '>
            <span className='symbol-label  bg-light-danger text-danger 40px '>O</span>
          </div>
          <div className='symbol  symbol-35px symbol-circle '>
            <span className='symbol-label  bg-light-primary text-primary 40px '>N</span>
          </div>
          <div className='symbol  symbol-35px symbol-circle '>
            <img alt='Pic' src='media/avatars/300-1.jpg' />
          </div>
          <a
            href='#'
            className='symbol symbol-35px symbol-circle'
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_view_users'
          >
            <span
              className='symbol-label fs-8 fw-bold'
              data-bs-toggle='tooltip'
              data-bs-trigger='hover'
              data-bs-original-title='View more users'
              data-kt-initialized='1'
              onClick={() => {
                setmodalopen(true)
                console.log('\nsetmodalopen(true)', modalopen)
              }}
            >
              +42
            </span>
          </a>
        </div>
      </div>
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-600px'
        show={modalopen}
        backdrop={true}
        size='sm'
      >
        <div className='modal-dialog mw-650px'>
          <div className='modal-content p-5' style={{width: '460px'}}>
            <div className='modal-header p-0 border-0 justify-content-end'></div>

            <div className='scroll-y d-flex flex-column gap-5'>
              <div className='align-items-center make_grid'>
                <h1 className='m-0 text-center green_color'>Users List</h1>
                <div
                  className='btn btn-sm btn-icon btn-active-color-primary'
                  data-bs-dismiss='modal'
                  onClick={() => setmodalopen(false)}
                >
                  <img src={close} alt='' />
                </div>
              </div>

              <div className='d-flex flex-stack py-5 border-bottom border-gray-300 border-bottom-dashed'>
                <div className='d-flex align-items-center gap-5 '>
                  <div className='symbol symbol-35px symbol-circle'>
                    <img alt='Pic' src='media/avatars/300-6.jpg' />
                  </div>

                  <div className='ms-0'>
                    <h6 className='d-flex align-items-center fs-5 fw-bold'>
                      Emma Smith
                      <span className='badge badge-light fs-8 fw-semibold ms-2'>Art Director </span>
                    </h6>

                    <div className='fw-semibold text-muted'>smith@kpmg.com</div>
                  </div>
                </div>
              </div>
              <div className='d-flex flex-stack py-5 border-bottom border-gray-300 border-bottom-dashed'>
                <div className='d-flex align-items-center gap-5 '>
                  <div className='symbol symbol-35px symbol-circle'>
                    <img alt='Pic' src='media/avatars/300-6.jpg' />
                  </div>

                  <div className='ms-0'>
                    <h6 className='d-flex align-items-center fs-5 fw-bold'>
                      Emma Smith
                      <span className='badge badge-light fs-8 fw-semibold ms-2'>Art Director </span>
                    </h6>

                    <div className='fw-semibold text-muted'>smith@kpmg.com</div>
                  </div>
                </div>
              </div>
              <div className='d-flex flex-stack py-5 border-bottom border-gray-300 border-bottom-dashed'>
                <div className='d-flex align-items-center gap-5 '>
                  <div className='symbol symbol-35px symbol-circle'>
                    <img alt='Pic' src='media/avatars/300-6.jpg' />
                  </div>

                  <div className='ms-0'>
                    <h6 className='d-flex align-items-center fs-5 fw-bold'>
                      Emma Smith
                      <span className='badge badge-light fs-8 fw-semibold ms-2'>Art Director </span>
                    </h6>

                    <div className='fw-semibold text-muted'>smith@kpmg.com</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal> */
}
