import React, {useState, useEffect} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import Swal from 'sweetalert2'
import submitImg from '../../../../../../../img/Submit-White.png'
import backArrow from '../../../../../../../img/back-arrow.png'
import trashImg from '../../../../../../../img/trash.png'
import saveGreenImg from '../../../../../../../img/save-dark-green.png'
import NoteDetailsCard from '../components/NoteDetailsCard'
import {ApiGet, ApiPost, ApiDelete} from '../../../../../../../apiCommon/helpers/API/ApiData'
import {Controller, useForm} from 'react-hook-form'
import {SuccessToast, ErrorToast} from '../../../../../../../apiCommon/helpers/Toast'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import {Row, Col} from 'react-bootstrap'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {IoMdInformationCircleOutline} from 'react-icons/io'
import downloadImg from '../../../../../../../img/download-img.png'
import share from '../../../../../../../img/email.png'
import print from '../../../../../../../img/printer.png'
import editPen from '../../../../../../../img/edit-pen.png'
import moment from 'moment'

const ShareNote = () => {
  const navigate = useNavigate()
  const {flg, id} = useParams()

  const [isLoading, setIsLoading] = useState<any>(false)
  const [isViewOnly, setIsViewOnly] = useState<any>(false)

  const [goToNext, setGoToNext] = useState<any>(false)
  const [selectedInvoices, setSelectedInvoices] = useState<any>([])

  const {
    register,
    control,
    handleSubmit,
    reset,
    setValue,
    formState: {errors},
    trigger,
  } = useForm<any>({mode: 'onSubmit', reValidateMode: 'onChange'})

  const {type} = useParams()

  const [creditNoteData, setCreditNoteData] = useState<any>({
    name: 'CREDIT NOTE',
    creditNoteNo: '',
    note: '',
    payorData: {},
    items: [],
    creditNoteCompany: {},
    creditNoteLogo: null,
    showNotes: true,
    itemDescriptions: [],
  })

  //
  const updateSelectedInvoices = (val: any) => {
    let values: any = [...selectedInvoices]
    val?.map((vl: any, ind: number) => {
      values?.push(vl)
    })

    setSelectedInvoices(values)
  }

  // const [isLoading, setIsLoading] = useState<any>(false)

  const [isSaveFirst, setIsSaveFirst] = useState<any>(false)
  const [autoSaveData, setAutoSaveData] = useState<any>(false)

  const [existingInvoice, setExistingInvoice] = useState<any>(null)

  const [open, setOpen] = React.useState(false)

  //
  const addCreditNote = async (saveStatus: number) => {
    let isValid: any = true
    if (saveStatus == 1) {
      isValid = await trigger()
    }

    if (isValid) {
    } else {
    }
  }

  //
  const getCreditNoteById = async () => {}

  //
  useEffect(() => {
    if (isSaveFirst || flg == '2') getCreditNoteById()
  }, [isSaveFirst])

  //
  useEffect(() => {
    if (autoSaveData != false && flg == '2') {
      addCreditNote(0)
    }
  }, [autoSaveData])

  // delete credit note
  const deleteCreditNote = () => {}

  const [emails, setEmails] = useState<string[]>([])
  const [inputValue, setInputValue] = useState('')
  const [error, setError] = useState<string | null>(null)

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value)
    setError(null) // Reset error on input change
  }

  const validateEmail = (email: string) => {
    // Regular expression for basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && inputValue.trim()) {
      if (!validateEmail(inputValue.trim())) {
        setError('Please enter a valid email')
        return
      }
      if (!emails.includes(inputValue.trim())) {
        setEmails([...emails, inputValue.trim()])
      }
      setInputValue('') // Clear input field after adding
      setError(null) // Clear error if valid
    }
  }

  const removeEmail = (index: number) => {
    setEmails(emails.filter((_, i) => i !== index))
  }

  return (
    <>
      <div className='app-main flex-column flex-row-fluid px-2' id='kt_app_main'>
        <form
          onSubmit={handleSubmit((data: any, e: any) => {
            addCreditNote(1)
          })}
        >
          <div id='' className='pt-0 mt-0'>
            {/* Header */}
            <div className='page-title d-flex flex-column justify-content-center flex-wrap mb-4'>
              {' '}
              <div className='d-flex align-items-start mb-1'>
                <span
                  className='rounded-circle cursor-pointer'
                  onClick={() => {
                    navigate('/finance-income')
                  }}
                >
                  <img src={backArrow} height='14' width='14' />
                </span>
                <div className=''>
                  <h2 className='m-0 head-text ms-2 text-capitalize'>{`Share ${
                    type == 'credit-note' ? 'Credit' : 'Debit'
                  } Note`}</h2>

                  <h4 className=' pt-2 mb-0 labl-gry'>
                    To update or edit the content of the email, please go to settings
                  </h4>
                </div>

                <button
                  className='btn btn-sm fw-bold ps-3 pe-4 text-white status-w-110 blue-btn ms-auto'
                  onClick={() => {}}
                >
                  <img src={share} height={18} width={18} className='me-4' /> {'  '}
                  Send
                </button>
              </div>
            </div>

            {/*  Details Card */}
            <div className='card card-flush py-7 px-5'>
              {/* From */}
              <div className='d-flex my-2 align-items-center'>
                <h4 className='text-dark'>
                  <label className='labl-gry status-w-150'> From:</label>

                  <span className='ms-1'>
                    <b>{'finance@harmonyre.ae'}</b>
                  </span>
                </h4>
              </div>

              {/* To */}
              <div className='d-flex my-2 align-items-center'>
                <h4 className='text-dark'>
                  <label className='labl-gry status-w-150'> To</label>
                </h4>
                <div>
                  <div className='email-input-container'>
                    <div className='emails-list'>
                      {emails.map((email, index) => (
                        <span key={index} className='email-tag'>
                          {email}
                          <span className='remove-button' onClick={() => removeEmail(index)}>
                            X
                          </span>
                        </span>
                      ))}
                      <div>
                      <input
                        type='text'
                        placeholder=''
                        value={inputValue}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                        className='email-input'
                      />
                       {error && <p className='text-danger ms-2'>{error}</p>}
                       </div>
                    </div>
                   
                  </div>

                  {/* Separator */}
                  {/* <Row className='mt-2 separator mx-0'></Row> */}
                </div>
              </div>

              {/*  */}
              <div className='d-flex my-2 mt-7 align-items-center'>
                <h4 className='text-dark'>
                  <label className='labl-gry status-w-150'> Subject</label>

                  <span className='ms-1'>
                    <b>{'Credit Note for Mukund Khunt'}</b>
                  </span>
                </h4>
              </div>
            </div>
          </div>
        </form>
      </div>

      <Backdrop sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}} open={open}>
        <CircularProgress style={{color: '#007a59'}} />
      </Backdrop>
    </>
  )
}

export default ShareNote
