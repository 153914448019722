import React, {useState, useEffect} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import Swal from 'sweetalert2'
import submitImg from '../../../../../../../img/Submit-White.png'
import backArrow from '../../../../../../../img/back-arrow.png'
import trashImg from '../../../../../../../img/trash.png'
import saveGreenImg from '../../../../../../../img/save-dark-green.png'
import SelectInvoice from '../../Receipts/SelectInvoice'
import SelectedInvoicesCard from '../../Receipts/SelectedInvoices'
import SelectAccount from '../components/SelectAccount'
import RecordPayment from '../components/RecordPayment'
import NoteDetailsCard from '../components/NoteDetailsCard'
import {ApiGet, ApiPost, ApiDelete} from '../../../../../../../apiCommon/helpers/API/ApiData'
import {Controller, useForm} from 'react-hook-form'
import {SuccessToast, ErrorToast} from '../../../../../../../apiCommon/helpers/Toast'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import {Row, Col} from 'react-bootstrap'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {IoMdInformationCircleOutline} from 'react-icons/io'
import ConfirmationPopup from '../components/ConfirmationPopup'

const IssueCreditNote = () => {
  const navigate = useNavigate()
  const {flg, id} = useParams()

  const [isLoading, setIsLoading] = useState<any>(false)
  const [isViewOnly, setIsViewOnly] = useState<any>(false)

  const [goToNext, setGoToNext] = useState<any>(false)
  const [selectedInvoices, setSelectedInvoices] = useState<any>([])

  const {
    register,
    control,
    handleSubmit,
    reset,
    setValue,
    formState: {errors},
    trigger,
  } = useForm<any>({mode: 'onSubmit', reValidateMode: 'onChange'})

  const [creditNoteData, setCreditNoteData] = useState<any>({
    name: 'CREDIT NOTE',
    creditNoteNo: '',
    note: '',
    payorData: {},
    items: [],
    creditNoteCompany: {},
    creditNoteLogo: null,
    showNotes: true,
    itemDescriptions: [],
  })

  //
  const updateSelectedInvoices = (val: any) => {
    let values: any = [...selectedInvoices]
    val?.map((vl: any, ind: number) => {
      values?.push(vl)
    })

    setSelectedInvoices(values)
  }

  // const [isLoading, setIsLoading] = useState<any>(false)

  const [isSaveFirst, setIsSaveFirst] = useState<any>(false)
  const [autoSaveData, setAutoSaveData] = useState<any>(false)

  const [existingInvoice, setExistingInvoice] = useState<any>(null)

  const [open, setOpen] = React.useState(false)

  //
  const addCreditNote = async (saveStatus: number) => {
    let isValid: any = true
    if (saveStatus == 1) {
      isValid = await trigger()
    }

    if (isValid) {
    } else {
    }
  }

  //
  const getCreditNoteById = async () => {}

  //
  useEffect(() => {
    if (isSaveFirst || flg == '2') getCreditNoteById()
  }, [isSaveFirst])

  //
  useEffect(() => {
    if (autoSaveData != false && flg == '2') {
      addCreditNote(0)
    }
  }, [autoSaveData])

  // delete credit note
  const deleteCreditNote = () => {}
  const [askForConfirmation, setAskForConfirmation] = useState<boolean>(false)
  const [isShare, setIsShare] = useState<any>(true)

  return (
    <>
      <div className='app-main flex-column flex-row-fluid px-2' id='kt_app_main'>
        <form
          onSubmit={handleSubmit((data: any, e: any) => {
            addCreditNote(1)
          })}
        >
          <div id='' className='pt-0 mt-0'>
            {/* Header */}
            <div className='page-title d-flex flex-column justify-content-center flex-wrap'>
              {' '}
              <div className='d-flex align-items-center mb-1'>
                <div className='d-flex align-items-center'>
                  <span
                    className='rounded-circle cursor-pointer'
                    onClick={() => {
                      if (goToNext) setGoToNext(false)
                      else navigate('/finance-income')
                    }}
                  >
                    <img src={backArrow} height='14' width='14' />
                  </span>
                  <h2 className='m-0 head-text ms-2 text-capitalize'>{`Issue Credit Note`}</h2>
                </div>

                {/* CTAs */}
                {/* {selectedInvoices?.length > 0 && ( */}
                  <div className='d-flex ms-auto'>
                    {flg != '1' ? (
                      <button
                        type='button'
                        className='btn btn-sm fw-bold  ms-3 red-hollow-btn ps-3'
                        disabled={isLoading || isViewOnly}
                        onClick={() => {
                          Swal.fire({
                            html: ` 
                            <div class='fs-3'> <b>Delete Draft Credit Note</></div>
                            <div class='fs-5 mt-4'> Are you sure want to delete this draft credit note? </div>`,
                            // icon: 'warning',
                            showConfirmButton: true,
                            confirmButtonColor: '#35475e',
                            confirmButtonText: 'Yes',
                            showCancelButton: true,
                            cancelButtonText: 'No',
                            cancelButtonColor: '#fff',
                            customClass: {
                              confirmButton: 'custom-confirm-button',
                              cancelButton: 'custom-cancel-button',
                              popup: 'custom-popup',
                            },
                            reverseButtons: true, // Reverse the order of buttons reverseButtons: true,
                          }).then((res: any) => {
                            if (res.isConfirmed) {
                              deleteCreditNote()
                            }
                          })
                        }}
                      >
                        <img src={trashImg} height={18} width={18} className='me-4' /> Delete
                      </button>
                    ) : (
                      <button
                        type='button'
                        className='btn btn-sm fw-bold ms-3 green-hollow-btn ps-3'
                        onClick={() => {
                          addCreditNote(0)
                        }}
                        disabled={isLoading}
                      >
                        <img src={saveGreenImg} height={18} width={18} className='me-4' /> {'  '}
                        Save
                      </button>
                    )}

                    <button
                      type='button'
                      className='btn btn-sm fw-bold ms-3 green-submit-btn ps-3'
                      onClick={() => {
                        // addCreditNote(1)
                        setAskForConfirmation(true)
                      }}
                      disabled={isLoading || !goToNext}
                    >
                      <img src={submitImg} height={19} width={19} className='me-4' /> {'  '}
                      Create
                    </button>
                  </div>
                {/* )} */}
              </div>
            </div>

            {!goToNext ? (
              <>
                {/* Select Account Card */}
                <SelectAccount
                  slctdInvoices={selectedInvoices}
                  updateSelectedPayor={(data: any) => {
                    let values: any = {...creditNoteData}
                    values.payorData = data
                    setCreditNoteData(values)

                    if (flg == '2') {
                      setTimeout(() => {
                        setAutoSaveData(Date.now())
                      }, 700)
                    }
                  }}
                  rcptData={creditNoteData}
                  updateSelectedInvoices={(data: any, invs: any) => {
                    let values: any = {...creditNoteData}
                    values.payorData = data
                    setCreditNoteData(values)

                    setSelectedInvoices([])

                    if (flg == '2') {
                      setTimeout(() => {
                        setAutoSaveData(Date.now())
                      }, 700)
                    }
                  }}
                />

                {/* Credit Note Allocation */}
                <Row>
                  <Col md={12}>
                    <>
                      <h2 className='fs-2 bg-white p-2 w-fit-content ms-2 fnt-600 mb-4'>
                        Credit Note Allocation
                      </h2>

                      <div className='d-flex align-items-center tst ms-2 mb-4 mt-4 pt-2'>
                        <input
                          type='radio'
                          className='me-3 cursor-pointer form-check-input'
                          {...register('settleExistingInvoice', {
                            required: 'Please select an option to proceed',
                          })}
                          id='existingInvoice'
                          value='existingInvoice'
                          checked={existingInvoice == 1}
                          onChange={(e: any) => {
                            setExistingInvoice(1)

                            if (flg == '2') {
                              setTimeout(() => {
                                setAutoSaveData(Date.now())
                              }, 700)
                            }
                          }}
                        />
                        <h5 className='m-0 d-flex align-items-center flex-wrap'>
                          <label
                            htmlFor='existingInvoice'
                            className='fs-4'
                            style={{minWidth: '320px'}}
                          >
                            Issue credit note on existing invoices.
                          </label>

                          <p className='mb-0 me-5'>
                            <span>
                              <OverlayTrigger
                                placement='bottom'
                                delay={{show: 250, hide: 400}}
                                overlay={
                                  <Tooltip id='button-tooltip-3' className='custom-tooltip'>
                                    <p className='mb-2'>Issue credit note on existing invoices.</p>
                                  </Tooltip>
                                }
                              >
                                <span>
                                  {' '}
                                  <IoMdInformationCircleOutline className='ms-0 cursor-pointer exclam-circle' />
                                </span>
                              </OverlayTrigger>
                            </span>
                          </p>
                        </h5>
                      </div>

                      <div className='d-flex align-items-center tst ms-2 mb-4 mt-4 pt-3'>
                        <input
                          type='radio'
                          className='me-3 cursor-pointer form-check-input'
                          {...register('settleExistingInvoice', {
                            required: 'Please select an option to proceed',
                          })}
                          id='chargeCorporate'
                          value='chargeCorporate'
                          name='ownerType'
                          checked={existingInvoice == 0}
                          onChange={(e: any) => {
                            setExistingInvoice(0)

                            if (flg == '2') {
                              setTimeout(() => {
                                setAutoSaveData(Date.now())
                              }, 700)
                            }
                          }}
                        />
                        <h5 className='m-0 d-flex align-items-center flex-wrap'>
                          <label
                            htmlFor='chargeCorporate'
                            className='fs-4'
                            style={{minWidth: '320px'}}
                          >
                            Issue credit note and allocate to invoices later.
                          </label>

                          <p className='mb-0 me-5'>
                            <span>
                              <OverlayTrigger
                                placement='bottom'
                                delay={{show: 250, hide: 400}}
                                overlay={
                                  <Tooltip id='button-tooltip-3' className='custom-tooltip'>
                                    <p className='mb-2'>
                                      Issue credit note and allocate to invoices later.
                                    </p>
                                  </Tooltip>
                                }
                              >
                                <span>
                                  {' '}
                                  <IoMdInformationCircleOutline className='ms-0 cursor-pointer exclam-circle' />
                                </span>
                              </OverlayTrigger>
                            </span>
                          </p>
                        </h5>
                      </div>

                      {errors?.settleExistingInvoice && (
                        <p className='text-danger ms-2'>{'Please select an option to proceed'}</p>
                      )}
                    </>
                  </Col>
                </Row>

                {/* Select invoice Card */}
                {existingInvoice == 1 && (
                  <div className='position-relative'>
                    {creditNoteData?.payorData?._id == undefined && (
                      <div className='requestBoxOpacity z-index-999 border-radius-5px'></div>
                    )}
                    <div>
                      <SelectInvoice
                        slctdInvoices={selectedInvoices}
                        updateCheckedInvoices={(data: any) => {
                          updateSelectedInvoices(data)

                          if (flg == '2') {
                            setTimeout(() => {
                              setAutoSaveData(Date.now())
                            }, 700)
                          }
                        }}
                        rcptData={creditNoteData}
                        from={'credit note'}
                      />
                    </div>
                  </div>
                )}

                {/* Selected invoices */}
                {existingInvoice == 1 && (
                  <div className='position-relative'>
                    {creditNoteData?.payorData?._id == undefined && (
                      <div className='requestBoxOpacity z-index-999 border-radius-5px'></div>
                    )}
                    <div>
                      <SelectedInvoicesCard
                        slctdInvoices={selectedInvoices}
                        updateCheckedInvs={(data: any) => {
                          setSelectedInvoices(data)

                          if (flg == '2') {
                            setTimeout(() => {
                              setAutoSaveData(Date.now())
                            }, 700)
                          }
                        }}
                        from={'credit note'}
                      />{' '}
                    </div>
                  </div>
                )}

                {/* Next CTA */}
                <div className={`d-flex justify-content-center my-20 pt-20`}>
                  <div
                    className={`py-3 px-2  w-fit-content ${
                      selectedInvoices?.length == 0 ? 'bg-white' : ''
                    }`}
                    style={{borderRadius: '5px'}}
                  >
                    <button
                      type='button'
                      className='btn btn-sm fw-bold green-submit-btn ps-3 d-flex justify-content-center purchase-order-btn'
                      // disabled={selectedInvoices?.length == 0}
                      onClick={() => {
                        setGoToNext(true)
                      }}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <>
                <RecordPayment
                  slctdInvoices={selectedInvoices}
                  register={register}
                  errors={errors}
                  notePaymentData={creditNoteData}
                  setValue={setValue}
                  trigger={trigger}
                  control={control}
                  updatePaymentRecord={(data: any) => {
                    setSelectedInvoices(data)
                  }}
                  autoSaveParent={() => {
                    if (flg == '2') {
                      setTimeout(() => {
                        setAutoSaveData(Date.now())
                      }, 700)
                    }
                  }}
                  editMode={true}
                  from={'credit note'}
                />

                {/* Credit Note Details Card */}
                <NoteDetailsCard
                  register={register}
                  errors={errors}
                  noteCardData={creditNoteData}
                  setValue={setValue}
                  trigger={trigger}
                  control={control}
                  updateNoteData={(data: any) => {
                    console.log(data)
                    setCreditNoteData(data)

                    if (flg == '2') {
                      setTimeout(() => {
                        setAutoSaveData(Date.now())
                      }, 700)
                    }
                  }}
                  slctdInvoices={selectedInvoices}
                  editMode={true}
                  from={'credit note'}
                />
              </>
            )}
          </div>
        </form>
      </div>

      <ConfirmationPopup
          show={askForConfirmation}
          handleClose={() => {
            setAskForConfirmation(false)
          }}
          receiptData={[]}
          isShare={isShare}
          setIsShare={(val:any)=>{setIsShare(val)}}
        />

      <Backdrop sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}} open={open}>
        <CircularProgress style={{color: '#007a59'}} />
      </Backdrop>
    </>
  )
}

export default IssueCreditNote
