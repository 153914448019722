import React from 'react'
import {useState} from 'react'
import {Row, Col, OverlayTrigger, Tooltip} from 'react-bootstrap'
import {useNavigate} from 'react-router-dom'
import './style.scss'
import redCross from '../../../img/remove.png'
import {AiOutlineExclamationCircle} from 'react-icons/ai'

const Tenants = () => {
  const navigate = useNavigate()
  const [roleData, setRoleData] = useState<any>()

  const handleChangeRoleData = () => {}
  return (
    <>
      <div id='' className='pt-0 mt-0 px-2 ps-0'>
        <div className='table-responsive '>
          <table
            className='table align-start table-row-dashed fs-5 gy-5 '
            id='kt_ecommerce_sales_table'
          >
            <thead>
              <th className='text-start min-w-25px'></th>
              <th className='text-start min-w-300px m-w-300'></th>

              <th className='text-start min-w-100px text-capitalize'>
                {' '}
                View List{' '}
                <span>
                  <OverlayTrigger
                    placement='top'
                    delay={{show: 250, hide: 400}}
                    overlay={
                      <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                        {' '}
                        Tenants Profile
                      </Tooltip>
                    }
                  >
                    <span>
                      {' '}
                      <AiOutlineExclamationCircle
                        color='#35475e '
                        fontSize={14}
                        fontWeight={600}
                        className='ms-0 cursor-pointer'
                        style={{cursor: 'pointer', marginBottom: '2px'}}
                      />
                    </span>
                  </OverlayTrigger>
                </span>
              </th>
              <th className='text-start min-w-125px text-capitalize'>
                {' '}
                View Details{' '}
                <span>
                  <OverlayTrigger
                    placement='top'
                    delay={{show: 250, hide: 400}}
                    overlay={
                      <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                        {' '}
                        Tenants Profile
                      </Tooltip>
                    }
                  >
                    <span>
                      {' '}
                      <AiOutlineExclamationCircle
                        color='#35475e '
                        fontSize={14}
                        fontWeight={600}
                        className='ms-0 cursor-pointer'
                        style={{cursor: 'pointer', marginBottom: '2px'}}
                      />
                    </span>
                  </OverlayTrigger>
                </span>
              </th>
              <th className='text-start min-w-100px text-capitalize'>
                {' '}
                Create{' '}
                <span>
                  <OverlayTrigger
                    placement='top'
                    delay={{show: 250, hide: 400}}
                    overlay={
                      <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                        {' '}
                        Tenants Profile
                      </Tooltip>
                    }
                  >
                    <span>
                      {' '}
                      <AiOutlineExclamationCircle
                        color='#35475e '
                        fontSize={14}
                        fontWeight={600}
                        className='ms-0 cursor-pointer'
                        style={{cursor: 'pointer', marginBottom: '2px'}}
                      />
                    </span>
                  </OverlayTrigger>
                </span>
              </th>
              <th className='text-start min-w-75px text-capitalize'>
                {' '}
                Edit{' '}
                <span>
                  <OverlayTrigger
                    placement='top'
                    delay={{show: 250, hide: 400}}
                    overlay={
                      <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                        {' '}
                        Tenants Profile
                      </Tooltip>
                    }
                  >
                    <span>
                      {' '}
                      <AiOutlineExclamationCircle
                        color='#35475e '
                        fontSize={14}
                        fontWeight={600}
                        className='ms-0 cursor-pointer'
                        style={{cursor: 'pointer', marginBottom: '2px'}}
                      />
                    </span>
                  </OverlayTrigger>
                </span>
              </th>
              <th className='text-start min-w-100px text-capitalize'>
                {' '}
                Delete{' '}
                <span>
                  <OverlayTrigger
                    placement='top'
                    delay={{show: 250, hide: 400}}
                    overlay={
                      <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                        {' '}
                        Tenants Profile
                      </Tooltip>
                    }
                  >
                    <span>
                      {' '}
                      <AiOutlineExclamationCircle
                        color='#35475e '
                        fontSize={14}
                        fontWeight={600}
                        className='ms-0 cursor-pointer'
                        style={{cursor: 'pointer', marginBottom: '2px'}}
                      />
                    </span>
                  </OverlayTrigger>
                </span>
              </th>
              <th className='text-start min-w-100px text-capitalize'></th>
            </thead>
            <tbody className='fw-semibold text-gray-600 role-test'>
              <tr>
                <td className='text-start ps-0'>
                  <img src={redCross} height={16} width={16} className='me-2' />
                </td>
                <td className='text-start ps-0 pe-5'>
                  <p className='user-main-head pe-2 mb-0' style={{fontWeight: '700'}}>
                    Tenant Profiles
                  </p>
                  <p className='user-sub-head mb-2'>
                    <i>
                      Create and manage tenant profiles that can be used for generating lease
                      agreements, charging payments, communications, announcements and managing
                      tenant requests.
                    </i>
                  </p>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='vacant0'
                      name='none'
                      checked={false}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'></td>
              </tr>

              {/* Tenant onboarding */}
              <tr style={{borderBottom: 'none', height: '35px'}}>
                <td className='text-start min-w-25px user-th'></td>
                <td className='text-start min-w-300px m-w-300 user-th'></td>

                <td className='text-start min-w-100px text-capitalize user-th ps-0'>
                  {' '}
                  Allow{' '}
                  <span>
                    <OverlayTrigger
                      placement='top'
                      delay={{show: 250, hide: 400}}
                      overlay={
                        <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                          {' '}
                          User can enable or disable services made available to tenants on the
                          tenant portal within the Propertise Application.
                        </Tooltip>
                      }
                    >
                      <span>
                        {' '}
                        <AiOutlineExclamationCircle
                          color='#35475e '
                          fontSize={14}
                          fontWeight={600}
                          className='ms-2 cursor-pointer mb-1'
                          style={{cursor: 'pointer', marginBottom: '2px'}}
                        />
                      </span>
                    </OverlayTrigger>
                  </span>
                </td>
                <td className='text-start min-w-125px text-capitalize user-th'></td>
                <td className='text-start min-w-100px text-capitalize user-th'></td>
                <td className='text-start min-w-75px text-capitalize user-th'></td>
                <td className='text-start min-w-100px text-capitalize user-th'></td>
                <td className='text-start min-w-100px text-capitalize user-th'></td>
              </tr>
              <tr>
                <td className='text-start ps-0'>
                  <img src={redCross} height={16} width={16} className='me-2' />
                </td>
                <td className='text-start ps-0 pe-5'>
                  <p className='user-main-head pe-2 mb-0' style={{fontWeight: '700'}}>
                    Tenant Onboarding
                  </p>
                  <p className='user-sub-head mb-2'>
                    <i>
                      Onboarding a tenant to the tenant portal within the Propertise mobile
                      application.
                    </i>
                  </p>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'></td>
                <td className='text-center ps-0'></td>
                <td className='text-center ps-0'></td>
                <td className='text-center ps-0'></td>
                <td className='text-center ps-0'></td>
              </tr>

              {/* Tenant Documents */}

              <tr
                className='user-th'
                style={{
                  borderBottom: 'none',
                  height: '35px',
                }}
              >
                <td className='text-start min-w-25px user-th'></td>
                <td className='text-start min-w-300px m-w-300 user-th'></td>

                <td className='text-start min-w-100px text-capitalize user-th ps-0'>
                  {' '}
                  View List{' '}
                  <span>
                    <OverlayTrigger
                      placement='top'
                      delay={{show: 250, hide: 400}}
                      overlay={
                        <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                          {' '}
                          User can view the list of tenant folders and files.
                        </Tooltip>
                      }
                    >
                      <span>
                        {' '}
                        <AiOutlineExclamationCircle
                          color='#35475e '
                          fontSize={14}
                          fontWeight={600}
                          className='ms-2 cursor-pointer mb-1'
                          style={{cursor: 'pointer', marginBottom: '2px'}}
                        />
                      </span>
                    </OverlayTrigger>
                  </span>
                </td>
                <td
                  className='text-start min-w-125px text-capitalize user-th ps-0'
                  style={{paddingTop: '1.25rem', paddingBottom: '1.25rem'}}
                >
                  {' '}
                  View Details{' '}
                  <span>
                    <OverlayTrigger
                      placement='top'
                      delay={{show: 250, hide: 400}}
                      overlay={
                        <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                          {' '}
                          User can open and view tenant files.
                        </Tooltip>
                      }
                    >
                      <span>
                        {' '}
                        <AiOutlineExclamationCircle
                          color='#35475e '
                          fontSize={14}
                          fontWeight={600}
                          className='ms-2 cursor-pointer mb-1'
                          style={{cursor: 'pointer', marginBottom: '2px'}}
                        />
                      </span>
                    </OverlayTrigger>
                  </span>
                </td>
                <td className='text-start min-w-100px text-capitalize user-th ps-0'>
                  {' '}
                  Create{' '}
                  <span>
                    <OverlayTrigger
                      placement='top'
                      delay={{show: 250, hide: 400}}
                      overlay={
                        <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                          {' '}
                          User can create tenant folders and upload files.
                        </Tooltip>
                      }
                    >
                      <span>
                        {' '}
                        <AiOutlineExclamationCircle
                          color='#35475e '
                          fontSize={14}
                          fontWeight={600}
                          className='ms-2 cursor-pointer mb-1'
                          style={{cursor: 'pointer', marginBottom: '2px'}}
                        />
                      </span>
                    </OverlayTrigger>
                  </span>
                </td>
                <td className='text-start min-w-75px text-capitalize user-th ps-0'>
                  {' '}
                  Edit{' '}
                  <span>
                    <OverlayTrigger
                      placement='top'
                      delay={{show: 250, hide: 400}}
                      overlay={
                        <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                          {' '}
                          User can edit tenant folders and files.
                        </Tooltip>
                      }
                    >
                      <span>
                        {' '}
                        <AiOutlineExclamationCircle
                          color='#35475e '
                          fontSize={14}
                          fontWeight={600}
                          className='ms-2 cursor-pointer mb-1'
                          style={{cursor: 'pointer', marginBottom: '2px'}}
                        />
                      </span>
                    </OverlayTrigger>
                  </span>
                </td>
                <td className='text-start min-w-100px text-capitalize user-th ps-0'>
                  {' '}
                  Delete{' '}
                  <span>
                    <OverlayTrigger
                      placement='top'
                      delay={{show: 250, hide: 400}}
                      overlay={
                        <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                          {' '}
                          User can delete tenant folders and files.
                        </Tooltip>
                      }
                    >
                      <span>
                        {' '}
                        <AiOutlineExclamationCircle
                          color='#35475e '
                          fontSize={14}
                          fontWeight={600}
                          className='ms-2 cursor-pointer mb-1'
                          style={{cursor: 'pointer', marginBottom: '2px'}}
                        />
                      </span>
                    </OverlayTrigger>
                  </span>
                </td>
                <td className='text-start min-w-100px text-capitalize user-th ps-0'>
                  {' '}
                  Download{' '}
                  <span>
                    <OverlayTrigger
                      placement='top'
                      delay={{show: 250, hide: 400}}
                      overlay={
                        <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                          {' '}
                          User can download tenant files.
                        </Tooltip>
                      }
                    >
                      <span>
                        {' '}
                        <AiOutlineExclamationCircle
                          color='#35475e '
                          fontSize={14}
                          fontWeight={600}
                          className='ms-2 cursor-pointer mb-1'
                          style={{cursor: 'pointer', marginBottom: '2px'}}
                        />
                      </span>
                    </OverlayTrigger>
                  </span>
                </td>
              </tr>
              <tr className='mt-2'>
                <td className='text-start ps-0'>
                  <img src={redCross} height={16} width={16} className='me-2' />
                </td>
                <td className='text-start ps-0 pe-5'>
                  <p className='user-main-head pe-2 mb-0' style={{fontWeight: '700'}}>
                    Tenant Documents
                  </p>
                  <p className='user-sub-head mb-2'>
                    <i>Store and manage tenant folders and documents.</i>
                  </p>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>

                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='vacant0'
                      name='none'
                      checked={false}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
              </tr>

              {/* Tenant Payments */}
              <tr
                className='user-th'
                style={{
                  borderBottom: 'none',
                  height: '35px',
                }}
              >
                <td className='text-start min-w-25px user-th'></td>
                <td className='text-start min-w-300px m-w-300 user-th'></td>

                <td className='text-start min-w-100px text-capitalize user-th ps-0'>
                  {' '}
                  View List{' '}
                  <span>
                    <OverlayTrigger
                      placement='top'
                      delay={{show: 250, hide: 400}}
                      overlay={
                        <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                          {' '}
                          User can view the list of tenant payments.
                        </Tooltip>
                      }
                    >
                      <span>
                        {' '}
                        <AiOutlineExclamationCircle
                          color='#35475e '
                          fontSize={14}
                          fontWeight={600}
                          className='ms-2 cursor-pointer mb-1'
                          style={{cursor: 'pointer', marginBottom: '2px'}}
                        />
                      </span>
                    </OverlayTrigger>
                  </span>
                </td>
                <td
                  className='text-start min-w-125px text-capitalize user-th ps-0'
                  style={{paddingTop: '1.25rem', paddingBottom: '1.25rem'}}
                >
                  {' '}
                  View Details{' '}
                  <span>
                    <OverlayTrigger
                      placement='top'
                      delay={{show: 250, hide: 400}}
                      overlay={
                        <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                          {' '}
                          User can access and view details of a payment.
                        </Tooltip>
                      }
                    >
                      <span>
                        {' '}
                        <AiOutlineExclamationCircle
                          color='#35475e '
                          fontSize={14}
                          fontWeight={600}
                          className='ms-2 cursor-pointer mb-1'
                          style={{cursor: 'pointer', marginBottom: '2px'}}
                        />
                      </span>
                    </OverlayTrigger>
                  </span>
                </td>
                <td className='text-start min-w-100px text-capitalize user-th ps-0'>
                  {' '}
                  Create{' '}
                  <span>
                    <OverlayTrigger
                      placement='top'
                      delay={{show: 250, hide: 400}}
                      overlay={
                        <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                          {' '}
                          User can create a request and submit it to the tenant. User will be
                          available as an inputter in the income payments workflow.
                        </Tooltip>
                      }
                    >
                      <span>
                        {' '}
                        <AiOutlineExclamationCircle
                          color='#35475e '
                          fontSize={14}
                          fontWeight={600}
                          className='ms-2 cursor-pointer mb-1'
                          style={{cursor: 'pointer', marginBottom: '2px'}}
                        />
                      </span>
                    </OverlayTrigger>
                  </span>
                </td>
                <td className='text-start min-w-75px text-capitalize user-th ps-0'>
                  {' '}
                  Edit{' '}
                  <span>
                    <OverlayTrigger
                      placement='top'
                      delay={{show: 250, hide: 400}}
                      overlay={
                        <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                          {' '}
                          User can edit a payment.
                        </Tooltip>
                      }
                    >
                      <span>
                        {' '}
                        <AiOutlineExclamationCircle
                          color='#35475e '
                          fontSize={14}
                          fontWeight={600}
                          className='ms-2 cursor-pointer mb-1'
                          style={{cursor: 'pointer', marginBottom: '2px'}}
                        />
                      </span>
                    </OverlayTrigger>
                  </span>
                </td>
                <td className='text-start min-w-100px text-capitalize user-th ps-0'>
                  {' '}
                  Delete{' '}
                  <span>
                    <OverlayTrigger
                      placement='top'
                      delay={{show: 250, hide: 400}}
                      overlay={
                        <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                          {' '}
                          User can delete a payment.
                        </Tooltip>
                      }
                    >
                      <span>
                        {' '}
                        <AiOutlineExclamationCircle
                          color='#35475e '
                          fontSize={14}
                          fontWeight={600}
                          className='ms-2 cursor-pointer mb-1'
                          style={{cursor: 'pointer', marginBottom: '2px'}}
                        />
                      </span>
                    </OverlayTrigger>
                  </span>
                </td>
                <td className='text-start min-w-100px text-capitalize user-th ps-0'>
                  {' '}
                  Approve{' '}
                  <span>
                    <OverlayTrigger
                      placement='top'
                      delay={{show: 250, hide: 400}}
                      overlay={
                        <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                          {' '}
                          User can approve a payment before it is shared with a recipient. User will
                          be available as an approver in the income payments workflow.
                        </Tooltip>
                      }
                    >
                      <span>
                        {' '}
                        <AiOutlineExclamationCircle
                          color='#35475e '
                          fontSize={14}
                          fontWeight={600}
                          className='ms-2 cursor-pointer mb-1'
                          style={{cursor: 'pointer', marginBottom: '2px'}}
                        />
                      </span>
                    </OverlayTrigger>
                  </span>
                </td>
              </tr>
              <tr className='mt-2'>
                <td className='text-start ps-0'>
                  <img src={redCross} height={16} width={16} className='me-2' />
                </td>
                <td className='text-start ps-0 pe-5'>
                  <p className='user-main-head pe-2 mb-0' style={{fontWeight: '700'}}>
                    Tenant Payments
                  </p>
                  <p className='user-sub-head mb-2'>
                    <i>Record and manage payments charged to your tenants.</i>
                  </p>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>

                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='vacant0'
                      name='none'
                      checked={false}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
              </tr>
              {/* Invoicing */}
              <tr>
                <td className='text-start ps-0'></td>
                <td className='text-start ps-0 pe-5'>
                  <p className='user-sub2 pe-2 mb-0 ps-5 ms-3' style={{fontWeight: '600'}}>
                    {' '}
                    - Invoicing
                  </p>
                  <p className='user-sub-head mb-2 ps-5 ms-3'>
                    <i>Generate or upload invoices against the recorded payment.</i>
                  </p>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={false}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'></td>
              </tr>
              {/* Receipts */}
              <tr>
                <td className='text-start ps-0'></td>
                <td className='text-start ps-0 pe-5'>
                  <p className='user-sub2 pe-2 mb-0 ps-5 ms-3' style={{fontWeight: '600'}}>
                    {' '}
                    - Receipts
                  </p>
                  <p className='user-sub-head mb-2 ps-5 ms-3'>
                    <i>Generate or upload receipts against invoices for payments received.</i>
                  </p>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={false}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'></td>
              </tr>
              {/* Refunds */}
              <tr>
                <td className='text-start ps-0'></td>
                <td className='text-start ps-0 pe-5'>
                  <p className='user-sub2 pe-2 mb-0 ps-5 ms-3' style={{fontWeight: '600'}}>
                    {' '}
                    - Refunds
                  </p>
                  <p className='user-sub-head mb-2 ps-5 ms-3'>
                    <i>Generate or upload refund receipts for received payments.</i>
                  </p>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={false}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={false}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'></td>
              </tr>
              {/* Credit Notes */}
              <tr>
                <td className='text-start ps-0'></td>
                <td className='text-start ps-0 pe-5'>
                  <p className='user-sub2 pe-2 mb-0 ps-5 ms-3' style={{fontWeight: '600'}}>
                    {' '}
                    - Credit Notes
                  </p>
                  <p className='user-sub-head mb-2 ps-5 ms-3'>
                    <i>
                      Generate or upload credit notes against invoices for overcharged amounts or
                      for cancelling invoices.
                    </i>
                  </p>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={false}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'></td>
              </tr>

              {/* Debit Notes */}
              <tr>
                <td className='text-start ps-0'></td>
                <td className='text-start ps-0 pe-5'>
                  <p className='user-sub2 pe-2 mb-0 ps-5 ms-3' style={{fontWeight: '600'}}>
                    {' '}
                    - Debit Notes
                  </p>
                  <p className='user-sub-head mb-2 ps-5 ms-3'>
                    <i>
                      Generate or upload debit notes against invoices for undercharged amounts or
                      for cancelling invoices.
                    </i>
                  </p>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={false}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'></td>
              </tr>

              {/*Items */}
              <tr>
                <td className='text-start ps-0'></td>
                <td className='text-start ps-0 pe-5'>
                  <p className='user-sub2 pe-2 mb-0 ps-5 ms-3' style={{fontWeight: '600'}}>
                    {' '}
                    - Items
                  </p>
                  <p className='user-sub-head mb-2 ps-5 ms-3'>
                    <i>
                      Add and select item categories and define the amounts and quantities for each
                      item added to your payment record.
                    </i>
                  </p>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={false}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'></td>
              </tr>

              {/* VAT */}
              <tr>
                <td className='text-start ps-0'></td>
                <td className='text-start ps-0 pe-5'>
                  <p className='user-sub2 pe-2 mb-0 ps-5 ms-3' style={{fontWeight: '600'}}>
                    {' '}
                    - VAT
                  </p>
                  <p className='user-sub-head mb-2 ps-5 ms-3'>
                    <i>
                      Set and charge VAT to the amount of each item category added to your payment.
                    </i>
                  </p>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={false}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'></td>
              </tr>

              {/* Late Payment Fees */}
              <tr>
                <td className='text-start ps-0'></td>
                <td className='text-start ps-0 pe-5'>
                  <p className='user-sub2 pe-2 mb-0 ps-5 ms-3' style={{fontWeight: '600'}}>
                    {' '}
                    - Late Payment Fees
                  </p>
                  <p className='user-sub-head mb-2 ps-5 ms-3'>
                    <i>Apply late payment fees for each item category added to your payment.</i>
                  </p>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={false}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'></td>
              </tr>

              {/* Payment Method */}
              <tr>
                <td className='text-start ps-0'></td>
                <td className='text-start ps-0 pe-5'>
                  <p className='user-sub2 pe-2 mb-0 ps-5 ms-3' style={{fontWeight: '600'}}>
                    {' '}
                    - Payment Method
                  </p>
                  <p className='user-sub-head mb-2 ps-5 ms-3'>
                    <i>Select the payment method you want the recipient to make a payment with.</i>
                  </p>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={false}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'></td>
              </tr>

              {/*  Payment Reminders */}
              <tr>
                <td className='text-start ps-0'></td>
                <td className='text-start ps-0 pe-5'>
                  <p className='user-sub2 pe-2 mb-0 ps-5 ms-3' style={{fontWeight: '600'}}>
                    {' '}
                    - Payment Reminders
                  </p>
                  <p className='user-sub-head mb-2 ps-5 ms-3'>
                    <i>
                      Set payment reminders against the payment due date to remind recipients on
                      their payment schedule.
                    </i>
                  </p>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={false}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'></td>
              </tr>

              {/*  Attachments */}
              <tr>
                <td className='text-start ps-0'></td>
                <td className='text-start ps-0 pe-5'>
                  <p className='user-sub2 pe-2 mb-0 ps-5 ms-3' style={{fontWeight: '600'}}>
                    {' '}
                    - Attachments
                  </p>
                  <p className='user-sub-head mb-2 ps-5 ms-3'>
                    <i>Attach documents relevant to the overall payment record.</i>
                  </p>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={false}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'></td>
              </tr>

              {/*  Notes */}
              <tr>
                <td className='text-start ps-0'></td>
                <td className='text-start ps-0 pe-5'>
                  <p className='user-sub2 pe-2 mb-0 ps-5 ms-3' style={{fontWeight: '600'}}>
                    {' '}
                    - Notes
                  </p>
                  <p className='user-sub-head mb-2 ps-5 ms-3'>
                    <i>Add notes related to the overall payment record.</i>
                  </p>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={false}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'></div>
                </td>
                <td className='text-center ps-0'></td>
              </tr>

              {/* Tenant Requests and Management */}
              <tr
                className='user-th'
                style={{
                  borderBottom: 'none',
                  height: '35px',
                }}
              >
                <td className='text-start min-w-25px user-th'></td>
                <td className='text-start min-w-300px m-w-300 user-th'></td>

                <td className='text-start min-w-100px text-capitalize user-th ps-0'>
                  {' '}
                  View List{' '}
                  <span>
                    <OverlayTrigger
                      placement='top'
                      delay={{show: 250, hide: 400}}
                      overlay={
                        <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                          {' '}
                          ser can view the list of tenant requests.
                        </Tooltip>
                      }
                    >
                      <span>
                        {' '}
                        <AiOutlineExclamationCircle
                          color='#35475e '
                          fontSize={14}
                          fontWeight={600}
                          className='ms-2 cursor-pointer mb-1'
                          style={{cursor: 'pointer', marginBottom: '2px'}}
                        />
                      </span>
                    </OverlayTrigger>
                  </span>
                </td>
                <td
                  className='text-start min-w-125px text-capitalize user-th ps-0'
                  style={{paddingTop: '1.25rem', paddingBottom: '1.25rem'}}
                >
                  {' '}
                  View Details{' '}
                  <span>
                    <OverlayTrigger
                      placement='top'
                      delay={{show: 250, hide: 400}}
                      overlay={
                        <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                          {' '}
                          User can access and view details of a tenant request.
                        </Tooltip>
                      }
                    >
                      <span>
                        {' '}
                        <AiOutlineExclamationCircle
                          color='#35475e '
                          fontSize={14}
                          fontWeight={600}
                          className='ms-2 cursor-pointer mb-1'
                          style={{cursor: 'pointer', marginBottom: '2px'}}
                        />
                      </span>
                    </OverlayTrigger>
                  </span>
                </td>
                <td className='text-start min-w-100px text-capitalize user-th ps-0'>
                  {' '}
                  Create{' '}
                  <span>
                    <OverlayTrigger
                      placement='top'
                      delay={{show: 250, hide: 400}}
                      overlay={
                        <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                          {' '}
                          User can create a request and submit it to the tenant. User will be
                          available as an inputter in the tenant requests workflows.
                        </Tooltip>
                      }
                    >
                      <span>
                        {' '}
                        <AiOutlineExclamationCircle
                          color='#35475e '
                          fontSize={14}
                          fontWeight={600}
                          className='ms-2 cursor-pointer mb-1'
                          style={{cursor: 'pointer', marginBottom: '2px'}}
                        />
                      </span>
                    </OverlayTrigger>
                  </span>
                </td>
                <td className='text-start min-w-75px text-capitalize user-th ps-0'>
                  {' '}
                  Edit{' '}
                  <span>
                    <OverlayTrigger
                      placement='top'
                      delay={{show: 250, hide: 400}}
                      overlay={
                        <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                          {' '}
                          User can edit a tenant request.
                        </Tooltip>
                      }
                    >
                      <span>
                        {' '}
                        <AiOutlineExclamationCircle
                          color='#35475e '
                          fontSize={14}
                          fontWeight={600}
                          className='ms-2 cursor-pointer mb-1'
                          style={{cursor: 'pointer', marginBottom: '2px'}}
                        />
                      </span>
                    </OverlayTrigger>
                  </span>
                </td>
                <td className='text-start min-w-100px text-capitalize user-th ps-0'>
                  {' '}
                  Delete{' '}
                  <span>
                    <OverlayTrigger
                      placement='top'
                      delay={{show: 250, hide: 400}}
                      overlay={
                        <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                          {' '}
                          User can delete a tenant request.
                        </Tooltip>
                      }
                    >
                      <span>
                        {' '}
                        <AiOutlineExclamationCircle
                          color='#35475e '
                          fontSize={14}
                          fontWeight={600}
                          className='ms-2 cursor-pointer mb-1'
                          style={{cursor: 'pointer', marginBottom: '2px'}}
                        />
                      </span>
                    </OverlayTrigger>
                  </span>
                </td>
                <td className='text-start min-w-100px text-capitalize user-th ps-0'>
                  {' '}
                  Approve{' '}
                  <span>
                    <OverlayTrigger
                      placement='top'
                      delay={{show: 250, hide: 400}}
                      overlay={
                        <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                          {' '}
                          User can approve a tenant request. User will be available as an approver
                          in the tenant requests workflows
                        </Tooltip>
                      }
                    >
                      <span>
                        {' '}
                        <AiOutlineExclamationCircle
                          color='#35475e '
                          fontSize={14}
                          fontWeight={600}
                          className='ms-2 cursor-pointer mb-1'
                          style={{cursor: 'pointer', marginBottom: '2px'}}
                        />
                      </span>
                    </OverlayTrigger>
                  </span>
                </td>
              </tr>
              <tr className='mt-2'>
                <td className='text-start ps-0'>
                  <img src={redCross} height={16} width={16} className='me-2' />
                </td>
                <td className='text-start ps-0 pe-5'>
                  <p className='user-main-head pe-2 mb-0' style={{fontWeight: '700'}}>
                    Tenant Requests Management
                  </p>
                  <p className='user-sub-head mb-2'>
                    <i>Manage requests submitted by or to tenants.</i>
                  </p>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>

                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='vacant0'
                      name='none'
                      checked={false}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
              </tr>
              {/* Move In */}
              <tr>
                <td className='text-start ps-0'></td>
                <td className='text-start ps-0 pe-5'>
                  <p className='user-sub2 pe-2 mb-0 ps-5 ms-3' style={{fontWeight: '600'}}>
                    {' '}
                    - Move In
                  </p>
                  <p className='user-sub-head mb-2 ps-5 ms-3'>
                    <i>Manage move in requests submitted by or to tenants.</i>
                  </p>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={false}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
              </tr>
              {/* - Move Out*/}
              <tr>
                <td className='text-start ps-0'></td>
                <td className='text-start ps-0 pe-5'>
                  <p className='user-sub2 pe-2 mb-0 ps-5 ms-3' style={{fontWeight: '600'}}>
                    {' '}
                    - Move Out
                  </p>
                  <p className='user-sub-head mb-2 ps-5 ms-3'>
                    <i>Manage move in requests submitted by or to tenants.</i>
                  </p>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={false}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
              </tr>
              {/*  - General Complaints*/}
              <tr>
                <td className='text-start ps-0'></td>
                <td className='text-start ps-0 pe-5'>
                  <p className='user-sub2 pe-2 mb-0 ps-5 ms-3' style={{fontWeight: '600'}}>
                    {' '}
                    - General Complaints
                  </p>
                  <p className='user-sub-head mb-2 ps-5 ms-3'>
                    <i>Manage complaints requests submitted by tenants.</i>
                  </p>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={false}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
              </tr>

              {/*  
                    - Other Requests*/}
              <tr>
                <td className='text-start ps-0'></td>
                <td className='text-start ps-0 pe-5'>
                  <p className='user-sub2 pe-2 mb-0 ps-5 ms-3' style={{fontWeight: '600'}}>
                    {' '}
                    - Other Requests
                  </p>
                  <p className='user-sub-head mb-2 ps-5 ms-3'>
                    <i>Manage move in requests submitted by tenants.</i>
                  </p>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={false}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
              </tr>

              {/* Tenant Requests Panel */}
              <tr style={{borderBottom: 'none', height: '35px'}}>
                <td className='text-start min-w-25px user-th'></td>
                <td className='text-start min-w-300px m-w-300 user-th'></td>

                <td className='text-start min-w-100px text-capitalize user-th ps-0'>
                  {' '}
                  Allow{' '}
                  <span>
                    <OverlayTrigger
                      placement='top'
                      delay={{show: 250, hide: 400}}
                      overlay={
                        <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                          {' '}
                          User can enable or disable services made available to tenants on the
                          tenant portal within the Propertise Application.
                        </Tooltip>
                      }
                    >
                      <span>
                        {' '}
                        <AiOutlineExclamationCircle
                          color='#35475e '
                          fontSize={14}
                          fontWeight={600}
                          className='ms-2 cursor-pointer mb-1'
                          style={{cursor: 'pointer', marginBottom: '2px'}}
                        />
                      </span>
                    </OverlayTrigger>
                  </span>
                </td>
                <td className='text-start min-w-125px text-capitalize user-th'></td>
                <td className='text-start min-w-100px text-capitalize user-th'></td>
                <td className='text-start min-w-75px text-capitalize user-th'></td>
                <td className='text-start min-w-100px text-capitalize user-th'></td>
                <td className='text-start min-w-100px text-capitalize user-th'></td>
              </tr>
              <tr>
                <td className='text-start ps-0'>
                  <img src={redCross} height={16} width={16} className='me-2' />
                </td>
                <td className='text-start ps-0 pe-5'>
                  <p className='user-main-head pe-2 mb-0' style={{fontWeight: '700'}}>
                    Tenant Requests Panel{' '}
                  </p>
                  <p className='user-sub-head mb-2'>
                    <i>
                      Enable or disable the type of requests tenants are able to make and have
                      access to.
                    </i>
                  </p>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'></td>
                <td className='text-center ps-0'></td>
                <td className='text-center ps-0'></td>
                <td className='text-center ps-0'></td>
                <td className='text-center ps-0'></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}

export default Tenants
