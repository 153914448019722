import React, {useEffect, useState, useRef} from 'react'
import {Modal, Row, Col} from 'react-bootstrap'
import {ApiPost, Bucket, ApiGet} from '../../../../../../apiCommon/helpers/API/ApiData'
import moment from 'moment'
import InputNotRequired from '../../../../General/InputNotRequired'
import {DatePicker} from 'antd'
import {Moment} from 'moment'
import {MultiSelect} from 'react-multi-select-component'
import {Controller, useForm} from 'react-hook-form'
import {useParams} from 'react-router-dom'
import { formatAmount } from '../../../../../../Utilities/utils'

interface ButtonProps {
  slctdInvoices?: any
  rcptData?: any
  register?: any
  errors?: any
  setValue?: any
  trigger?: any
  control?: any
  updateReceipt?: any
  editMode?: boolean
}

const ReceiptCard = ({
  slctdInvoices,
  rcptData,
  register,
  errors,
  setValue,
  trigger,
  control,
  updateReceipt,
  editMode,
}: ButtonProps) => {
  const {flg, id} = useParams()
  const [logo, setLogo] = useState<any>(null)

  const [receiptData, setReceiptData] = useState<any>(rcptData)
  const [receiptCompany, setReceiptCompany] = useState<any>({
    name: '',
    address: '',
    phoneNumber: '',
    email: '',
    countryCode: '',
  })

  const [invRecDetails, setInvRecDetails] = useState<any>(null)
  //
  const getReceiptDetails = () => {
    ApiGet('corporate/corporate_setting')
      .then((res) => {
        setInvRecDetails(res?.data?.data)
        if (flg == '1') setReceiptCompany(res?.data?.data?.receiptCompany)
        setLogo(res?.data?.data?.receiptLogo)

        if (flg == '1')
          updateReceipt({
            ...receiptData,
            ['receiptCompany']: res?.data?.data?.receiptCompany,
            ['receiptLogo']: res?.data?.data?.receiptLogo,
          })
      })
      .catch((err) => {
        console.log('err', err)
      })
  }

  useEffect(() => {
    if(flg != undefined)
    getReceiptDetails()
  }, [])

  //
  function disabledDate(current: Moment) {
    return current && current > moment().endOf('day')
  }

  const [pickerKey, setPickerKey] = useState(Date.now());
  //
  const handleDateChange = (date: any) => {
    if (date != null) {
      let values = {...receiptData}
      values.datePaid = date.format('YYYY-MM-DD')
      setReceiptData(values)
      updateReceipt({...receiptData, ['datePaid']: date.format('YYYY-MM-DD')})

      setValue('paymentDate', date.format('YYYY-MM-DD'), {shouldValidate: true})
    } else {
        
  
      let values = {...receiptData}
      values.datePaid = null

      setReceiptData(values)
      if (!date) {
        // Force re-render by updating the key
        setPickerKey(Date.now()); 
      }
      updateReceipt({...receiptData, ['datePaid']: null})
    
      setValue('paymentDate', '', {shouldValidate: true})
    }
  }

  //
  const [paymentMethodOptions, setPaymentMethodOptions] = useState<any>([
    {
      label: 'Bank Transfer',
      value: 2,
    },
    {
      label: 'Card',
      value: 0,
    },
    {
      label: 'Cash',
      value: 3,
    },
    {
      label: 'Cheque',
      value: 1,
    },
  ])
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<any>([])
  
  useEffect(() => {
    setReceiptCompany(rcptData?.receiptCompany)
    setLogo(rcptData?.receiptLogo)
    setReceiptData(rcptData)
  }, [rcptData])

  const intervalRef = useRef<NodeJS.Timer | null>(null)

  var totalAmountOfReceipt: any = 0

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      const checkboxes = document.querySelectorAll(
        '.property-test .multi-select-container .rmsc .item-renderer input[type=checkbox]'
      )
      // console.log(checkboxes)
      checkboxes.forEach((checkbox) => {
        checkbox.setAttribute('type', 'radio')
      })
    }, 100)

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
      }
    }
  }, [])

  const [selectedInvoices, setSelectedInvoices] = useState<any>([])

  useEffect(() => {
    setSelectedInvoices(slctdInvoices)
  }, [slctdInvoices])


  return (
    <div>
      <div className='card card-flush py-7 px-5 pb-13 mb-5'>
        <Row className=''>
          <Col xs={6} sm={6} md={9} lg={9}>
            <div className='d-flex mb-3 mt-2 align-items-center'>
              {logo == null || logo?.trim()?.length == 0 ? (
                <></>
              ) : (
                <img
                  // src={`${Bucket}${logo}`}
                  src={`${Bucket}${logo}`}
                  height='110'
                  width='110'
                  style={{borderRadius: '7px'}}
                  className='me-4'
                />
              )}
            </div>
          </Col>
          <Col xs={6} sm={6} md={3} lg={3} className='pe-4'>
            <div className='d-flex align-items-center my-2'>
              <label
                className='mb-1 me-5 pe-5 ms-auto head-text'
                style={{minWidth: '50px', maxWidth: '50px'}}
              >
                <b>TRN</b>
              </label>
              <b>{receiptCompany?.trn ? receiptCompany?.trn : '-'}</b>
            </div>

            <div className='text-end' style={{fontWeight: '600'}}>
              <p style={{marginBottom: '0.1rem'}} className='fs-4'>
                {' '}
                <b>{receiptCompany?.name != null && receiptCompany?.name}</b>
              </p>
              <p style={{marginBottom: '0.1rem'}} className='fs-7'>
                {' '}
                {receiptCompany?.address != null && receiptCompany?.address}
              </p>

              <p style={{marginBottom: '0.1rem'}} className='fs-7'>
                {receiptCompany?.phoneNumber != null &&
                  receiptCompany?.countryCode + receiptCompany?.phoneNumber}
              </p>
              <p style={{marginBottom: '0.1rem'}} className='fs-7'>
                {' '}
                {receiptCompany?.email != null && receiptCompany?.email}
              </p>
            </div>
          </Col>
        </Row>

        {selectedInvoices?.map((slInv: any, indOut: number) => {
          slInv?.paymentSubItems?.map((invItm: any, indIn: number) => {
            totalAmountOfReceipt = Number(totalAmountOfReceipt) + Number(invItm?.amountPaid)
          })
        })}

        <Row>
          <Col xs={6} sm={6} md={9} lg={9}></Col>
          <Col xs={6} sm={6} md={3} lg={3} className='pe-4'>
            <div className='text-end'>
              {editMode ? (
                <>
                  <div className='my-5 '>
                    {/* <h1 className='mb-0'> */}
                    <InputNotRequired
                      required={true}
                      register={register}
                      errors={errors}
                      value={receiptData?.name}
                      setValue={setValue}
                      trigger={trigger}
                      onBlur={() => {
                        updateReceipt(receiptData)
                      }}
                      onChange={(val: any) => {
                        setReceiptData({...receiptData, ['name']: val})
                      }} // Pass the callback function to handle input changes
                      placeholder='Enter Document Name'
                      name='documentName'
                      pattern={/^[A-Za-z0-9._ \-]*$/} // Pattern to allow letters, numbers, periods, underscores, hyphens, and spaces
                      maxLength={30} // Maximum length of 50 characters
                      className='text-end document-name-input-field'
                    />
                    {/* </h1> */}
                  </div>
                  {/* <h3 className='text-danger mb-0 ms-2 my-5'>
                  <b>*</b>
                </h3> */}
                </>
              ) : (
                <p className='ms-auto pt-1 document-name-input-field my-5 fnt-500'>
                  {`${receiptData?.name || ''}`}
                </p>
              )}
            </div>
          </Col>
        </Row>

        <Row>
          <Col xs={6} sm={6} md={9} lg={9}>
            <div className='w-75 fnt-500'>
              <p className='mb-3 fs-3 mt-7'>Amount Received</p>

              <p className='mb-3 fs-2'>
                <b>
                  AED{' '}
                  {totalAmountOfReceipt
                    ? formatAmount(totalAmountOfReceipt)
                    : ' -'}{' '}
                </b>
              </p>

              <p className='mb-3 fs-3 mt-7'>Received From</p>

              <p style={{marginBottom: '0.1rem'}}>
                {rcptData?.payorData?.firstName} {rcptData?.payorData?.lastName}
              </p>
              <p style={{marginBottom: '0.1rem'}}>
                {rcptData?.payorData?.countryCode} {rcptData?.payorData?.phoneNumber}
              </p>
              <p style={{marginBottom: '0.1rem'}}>{rcptData?.payorData?.email}</p>
            </div>
          </Col>
          <Col xs={6} sm={6} md={3} lg={3} className='pe-4'>
            <div className='text-end'>
              {/* Receipt No. */}
              <div className='d-flex align-items-start justify-content-start my-2'>
                <label
                  className='mb-1 ms-3 pt-2 fnt-500  status-w-100 text-start'
                  style={{
                    fontSize: '1rem',
                  }}
                >
                  Receipt No.
                </label>
                {editMode ? (
                  <>
                    {invRecDetails?.receiptSequence?.activityStatus == 1 ? (
                      <>
                        {/* {invRecDetails?.receiptSequence?.prefix != null && ( */}
                          <p className='pt-2 mb-0 ms-auto'>
                          <b>{receiptData?.receiptNo ? receiptData?.receiptNo : '-'}</b>
                          </p>
                        {/* )} */}
                      </>
                    ) : (
                      <div className='ms-auto receipt-date'>
                        <InputNotRequired
                          required={true}
                          register={register}
                          errors={errors}
                          value={receiptData?.receiptNo}
                          setValue={setValue}
                          trigger={trigger}
                          onBlur={() => {
                            updateReceipt(receiptData)
                          }}
                          onChange={(val: any) => {
                            setReceiptData({...receiptData, ['receiptNo']: val})
                          }} // Pass the callback function to handle input changes
                          placeholder='Enter Receipt No.'
                          name='documentReceiptNo'
                          pattern={/^[A-Za-z0-9._ \-]*$/} // Pattern to allow letters, numbers, periods, underscores, hyphens, and spaces
                          maxLength={15} // Maximum length of 50 characters
                          style={{height: '35px'}}
                          className='your-custom-class receipt-date text-start'
                        />
                      </div>
                    )}
                    {invRecDetails?.receiptSequence?.activityStatus == 0 && (
                      <h3 className='text-danger mb-0 ms-2'>
                        <b>*</b>
                      </h3>
                    )}
                  </>
                ) : (
                  <label className='ms-auto pt-1'>
                    <b>{`${receiptData?.receiptNo || ''}`}</b>
                  </label>
                )}
              </div>

              {/* Payment Date. */}
              <div className='d-flex align-items-start justify-content-start my-2'>
                <label
                  className='mb-1 me-3 ms-3 pt-2 fnt-500  status-w-100 text-start'
                  style={{
                    fontSize: '1rem',
                  }}
                >
                  Payment Date
                </label>
                {editMode ? (
                  <>
                    <Controller
                      name='paymentDate'
                      control={control}
                      rules={{
                        required: 'this is required',
                      }}
                      render={({field}) => (
                        <div className='ms-auto'>
                          <div className='receipt-date '>
                            {receiptData?.datePaid != null ? (
                              <DatePicker
                                name='paymentDate'
                                key={pickerKey} 
                                defaultValue={moment(receiptData?.datePaid, 'YYYY-MM-DD')}
                                disabledDate={disabledDate}
                                onChange={handleDateChange}
                                // defaultValue={moment(show.date).format('DD/MM/YYYY')}
                                placeholder='Select Payment Date'

                              />
                            ) : (
                              <DatePicker
                              key={pickerKey} 
                                name='paymentDate'
                                disabledDate={disabledDate}
                                onChange={handleDateChange}
                                defaultPickerValue={moment()} 
                                
                                // defaultValue={moment(show.date).format('DD/MM/YYYY')}
                                placeholder='Select Payment Date'
                              />
                            )}
                          </div>
                          {errors?.paymentDate && (
                            <p
                              style={{color: 'var(--kt-danger)', gridColumn: '2/2'}}
                              className='m-0 mt-1 text-start'
                            >
                              This field is required
                            </p>
                          )}
                        </div>
                      )}
                    />
                    <h3 className='text-danger mb-0 ms-2'>
                      <b>*</b>
                    </h3>
                  </>
                ) : (
                  <label className='ms-auto pt-1'>
                    <b>
                      {receiptData?.datePaid
                        ? moment(receiptData?.datePaid).format('DD.MM.YYYY')
                        : '-'}
                    </b>
                  </label>
                )}
              </div>

              {/* Payment Method */}
              <div className='d-flex align-items-start justify-content-start my-2'>
                <label
                  className='mb-1 me-3 ms-3 pt-2 fnt-500  status-w-100 text-start'
                  style={{
                    fontSize: '1rem',
                  }}
                >
                  Payment Method
                </label>
                {editMode ? (
                  <>
                    <div className='receipt-date ms-auto text-start'>
                      <div className='test expense  property-test w-150px-multi-select'>
                        <div
                          className={`multi-select-container ${
                            receiptData?.paymentMethod?.length == 0
                              ? 'no-value select'
                              : 'has-value'
                          }`}
                        >
                          <Controller
                            name='paymentMethod'
                            control={control}
                            rules={{
                              required: 'This field is required',
                            }}
                            render={({field}) => (
                              <MultiSelect
                                {...field}
                                options={paymentMethodOptions}
                                value={
                                  receiptData?.paymentMethod?.length > 0
                                    ? [
                                        {
                                          label: receiptData?.paymentMethod?.[0]?.label,
                                          value: receiptData?.paymentMethod?.[0]?.value,
                                        },
                                      ]
                                    : []
                                }
                                onChange={(e: any) => {
                                  let newVal: any = e
                                  if (e?.length > 1) newVal = e.slice(1)
                                  setSelectedPaymentMethod(newVal)

                                  updateReceipt({...receiptData, ['paymentMethod']: newVal})

                                  setValue('paymentMethod', `${newVal}1`, {
                                    shouldValidate: true,
                                  })
                                }}
                                labelledBy='Type'
                                hasSelectAll={false}
                                overrideStrings={{
                                  allItemsAreSelected: `${selectedPaymentMethod?.[0]?.label}`,
                                }}
                                closeOnChangedValue={true}
                              />
                            )}
                          />
                          {errors.paymentMethod && (
                            <p
                              style={{color: 'var(--kt-danger)', gridColumn: '2/2'}}
                              className='m-0 mt-1'
                            >
                              This field is required
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <h3 className='text-danger mb-0 ms-2'>
                      <b>*</b>
                    </h3>
                  </>
                ) : (
                  <label className='pt-1 ms-auto'>
                    <b>{`${receiptData?.paymentMethod?.[0]?.label || ''}`}</b>
                  </label>
                )}
              </div>

              {/* Reference No. */}
              <div className='d-flex align-items-start justify-content-start my-2'>
                <label
                  className='mb-1 ms-3 pt-2 fnt-500  status-w-100 text-start'
                  style={{
                    fontSize: '1rem',
                  }}
                >
                  Reference No.
                </label>
                {editMode ? (
                  <div className='ms-auto receipt-date me-4'>
                    <InputNotRequired
                      required={false}
                      register={register}
                      errors={errors}
                      value={receiptData?.referenceNo}
                      setValue={setValue}
                      trigger={trigger}
                      onBlur={() => {
                        updateReceipt(receiptData)
                      }}
                      onChange={(val: any) => {
                        setReceiptData({...receiptData, ['referenceNo']: val})
                      }} // Pass the callback function to handle input changes
                      placeholder='Enter Ref No.'
                      name='documentReferenceNo'
                      pattern={/^[A-Za-z0-9._ \-]*$/} // Pattern to allow letters, numbers, periods, underscores, hyphens, and spaces
                      maxLength={15} // Maximum length of 15 characters
                      style={{height: '35px'}}
                      className='receipt-date text-start me-2'
                    />
                  </div>
                ) : (
                  <label className='ms-auto pt-1'>
                    <b>{`${receiptData?.referenceNo || ''}`}</b>
                  </label>
                )}
              </div>

              {/* Receipt Date */}
              <div className='d-flex align-items-start justify-content-start my-2'>
                <label
                  className='mb-1 ms-3 pt-2 fnt-500  status-w-100 text-start'
                  style={{
                    fontSize: '1rem',
                  }}
                >
                  Receipt Date
                </label>

                <label className='ms-auto pt-1'>
                  {editMode ? (
                    <b>-</b>
                  ) : (
                    <b>
                      {receiptData?.createdAt
                        ? moment(receiptData?.createdAt).format('DD.MM.YYYY')
                        : '-'}
                    </b>
                  )}
                </label>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default ReceiptCard
