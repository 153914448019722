import React, {useState} from 'react'
import {Modal} from 'react-bootstrap'
import './styles.scss'
import reactiveImg from '../../../img/reactive.png'
import reactiveWhiteImg from '../../../img/reactiveWhite.png'
import plannedImg from '../../../img/planned.png'
import plannedWhiteImg from '../../../img/plannedWhite.png'
import preventativeImg from '../../../img/preventative.png'
import preventativeWhiteImg from '../../../img/preventativeWhite.png'
import informationImg from '../../../img/information.png'
import {useNavigate} from 'react-router-dom'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {AiOutlineExclamationCircle} from 'react-icons/ai'
import {IoMdInformationCircleOutline} from 'react-icons/io'
import devClustImg from '../../../img/dev-clust-request.png'
import devClustGreenImg from '../../../img/dev-clust-request-green.png'
import commonAreasImg from '../../../img/common-areas-request.png'
import commonAreasGreenImg from '../../../img/common-areas-request-green.png'
import unitImg from '../../../img/unit-request-white.png'
import unitGreebImg from '../../../img/unit-request-green.png'

interface ButtonProps {
  show: any
  handleClose?: any
  updateLists?: any
  addedLists?: any
}

const RequestModel = ({show, handleClose, updateLists, addedLists}: ButtonProps) => {
  const [selectReactiveBox, setReactiveBox] = useState(false)
  const [selectPlannedBox, setPlannedBox] = useState(false)
  const [selectPreventativeBox, setPreventativeBox] = useState(false)
  const [goToNext, setGoToNext] = useState<any>(false)

  const [selectDevORCluster, setSelectDevORCluster] = useState(false)
  const [selectCommonArea, setSelectCommonArea] = useState(false)
  const [selectUnitMaintenance, setSelectUnitMaintenance] = useState(false)

  const navigate = useNavigate()

  return (
    <>
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-700px mx-auto'
        show={show}
        onHide={() => {
          handleClose()
        }}
        backdrop={true}
        size='sm'
      >
        <div
          className='modal-body py-lg-7 px-lg-7 pt-lg-3'
          style={{
            transform: 'translate(0px, -70px)',
            top: '70px',
            borderRadius: '5px',
            boxShadow: '0px 0px 20px 0px #516176',
            minHeight: '350px',
            maxHeight: '550px',
            overflow: 'auto',
          }}
        >
          <div className='my-1 pb-1 row mt-3'>
            <div className='col-md-4'>
              {goToNext && (
                <h3
                  className='mb-0 cursor-pointer'
                  onClick={() => {
                    setGoToNext(false)
                  }}
                >
                  <b>{'<'} Back</b>
                </h3>
              )}
            </div>
            <div className='col-md-8'>
              <div className='d-flex'>
                {!goToNext ? (
                  <h2 className='head-text mb-0 flex-grow-1'>
                    <b>Select Maintenance Type</b>
                  </h2>
                ) : (
                  <h2 className='head-text mb-0 flex-grow-1'>
                    <b>Select Job Type</b>
                  </h2>
                )}

                <label
                  className='head-text fs-5 cursor-pointer'
                  style={{fontWeight: '700'}}
                  onClick={() => {
                    handleClose()
                  }}
                >
                  X
                </label>
              </div>
            </div>
          </div>

          <div className='row mx-0 px-0 mt-4'>
            <div className='d-flex justify-content-evenly mt-5 pt-5'>
              {!goToNext ? (
                <>
                  {/* Reactive Maintenance */}
                  <div className='text-center'>
                    {/* <img src={informationImg} height={20} width={20} /> */}
                    <span>
                      <OverlayTrigger
                        placement='top'
                        delay={{show: 250, hide: 200}}
                        overlay={
                          <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                            {' '}
                            Submit a one-time maintenance request as a result of an unexpected issue
                            or requirement, with the aim of resolving the issue at hand.
                          </Tooltip>
                        }
                      >
                        <span>
                          {' '}
                          <IoMdInformationCircleOutline
                            color='#384a62'
                            fontSize={16}
                            fontWeight={600}
                            className='cursor-pointer'
                            style={{cursor: 'pointer', marginBottom: '2px'}}
                          />
                        </span>
                      </OverlayTrigger>
                    </span>
                    <div
                      className={`${
                        selectReactiveBox ? 'mainRequestBoxColor' : 'mainRequestBox'
                      } mt-3`}
                      onClick={() => {
                        setReactiveBox(!selectReactiveBox)
                        setPlannedBox(false)
                        setPreventativeBox(false)
                      }}
                      style={{height: '160px'}}
                    >
                      <h3 className={`head-text mb-3 ${selectReactiveBox ? 'headTextWhite' : ''}`}>
                        Reactive
                      </h3>
                      <img
                        src={selectReactiveBox ? reactiveWhiteImg : reactiveImg}
                        height={80}
                        width={80}
                        className='d-block mt-auto'
                      />
                    </div>
                    {selectReactiveBox && (
                      <button
                        type='button'
                        className=' mt-4 btn btn-sm fw-bold green-submit-btn w-100 d-flex justify-content-center'
                        onClick={() => {
                          setGoToNext(true)
                          // navigate(`/maintenance/create-request/reactive/1`)
                        }}
                      >
                        Next
                      </button>
                    )}
                  </div>

                  {/* Planned Preventative */}
                  <div className='text-center position-relative'>
                    <div className='requestBoxOpacity'></div>
                    {/* <img src={informationImg} height={20} width={20} /> */}
                    <span>
                      <OverlayTrigger
                        placement='top'
                        delay={{show: 250, hide: 200}}
                        overlay={
                          <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                            {' '}
                            Submit a future maintenance request and set the schedule and select the
                            vendor later, with the aim of avoiding to create a reactive request once
                            the issue arises.
                          </Tooltip>
                        }
                      >
                        <span>
                          {' '}
                          <IoMdInformationCircleOutline
                            color='#384a62'
                            fontSize={16}
                            fontWeight={600}
                            className='cursor-pointer'
                            style={{cursor: 'pointer', marginBottom: '2px'}}
                          />
                        </span>
                      </OverlayTrigger>
                    </span>
                    <div
                      className={`${
                        selectPlannedBox ? 'mainRequestBoxColor' : 'mainRequestBox'
                      } mt-3`}
                      onClick={() => {
                        setPlannedBox(!selectPlannedBox)
                        setPreventativeBox(false)
                        setReactiveBox(false)
                      }}
                      style={{height: '160px'}}
                    >
                      <h3 className={`head-text mb-3 ${selectPlannedBox ? 'headTextWhite' : ''}`}>
                        Planned Preventative
                      </h3>
                      <img
                        src={selectPlannedBox ? plannedWhiteImg : plannedImg}
                        height={80}
                        width={80}
                        className='d-block mt-auto'
                      />
                    </div>
                    {selectPlannedBox && (
                      <button
                        type='button'
                        className=' mt-4 btn btn-sm fw-bold green-submit-btn w-100 d-flex justify-content-center'
                        onClick={() => {
                          setGoToNext(true)
                        }}
                      >
                        Next
                      </button>
                    )}
                  </div>

                  {/* <div className='text-center position-relative'>
                <div className='requestBoxOpacity'></div>
               
                <span>
                  <OverlayTrigger
                    placement='top'
                    delay={{ show: 250, hide: 200 }}
                    overlay={
                      <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                        {' '}
                        Submit recurring maintenance requests with a set frequency, schedule and selected vendor, with the aim of reducing and preventing an issue to occur or reoccur.

                      </Tooltip>
                    }
                  >
                    <span>
                      {' '}
                      <IoMdInformationCircleOutline
                       color='#384a62'
                            fontSize={16}
                        fontWeight={600}
                        className='cursor-pointer'
                        style={{ cursor: 'pointer', marginBottom: '2px' }}
                      />
                    </span>
                  </OverlayTrigger>
                </span>
                <div
                  className={`${selectPreventativeBox ? 'mainRequestBoxColor' : 'mainRequestBox'} mt-3`}
                  onClick={() => {
                    setPreventativeBox(!selectPreventativeBox)
                    setPlannedBox(false)
                    setReactiveBox(false)
                  }}
                >
                  <h2 className={`head-text mb-3 ${selectPreventativeBox ? 'headTextWhite' : ''}`}>
                    <b>Preventative</b>
                  </h2>
                  <img
                    src={selectPreventativeBox ? preventativeWhiteImg : preventativeImg}
                    height={80}
                    width={80}
                    className='d-block'
                  />

                </div>
                {selectPreventativeBox && (
                  <button
                    type='button'
                    className=' mt-4 btn btn-sm fw-bold green-submit-btn w-100 d-flex justify-content-center'
                    onClick={() => {
                      navigate(`/maintenance/create-request/preventative/1`)
                    }}
                  >
                    Next
                  </button>
                )}
              </div> */}
                </>
              ) : (
                <>
                  {/* Development OR Cluster */}
                  <div className='text-center'>
                    {/* <img src={informationImg} height={20} width={20} /> */}
                    <span>
                      <OverlayTrigger
                        placement='top'
                        delay={{show: 250, hide: 200}}
                        overlay={
                          <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                            {' '}
                            <h5 className='text-dark'>
                              <b>Overall Development</b>
                            </h5>
                            <p className='text-dark fnt-400 mb-0'>
                              You will be able to submit a maintenance request on an overall
                              development or cluster.
                            </p>
                          </Tooltip>
                        }
                      >
                        <span>
                          {' '}
                          <IoMdInformationCircleOutline
                            color='#384a62'
                            fontSize={16}
                            fontWeight={600}
                            className='cursor-pointer'
                            style={{cursor: 'pointer', marginBottom: '2px'}}
                          />
                        </span>
                      </OverlayTrigger>
                    </span>
                    <div
                      className={`${
                        selectDevORCluster ? 'mainRequestBoxColor' : 'mainRequestBox'
                      } mt-3`}
                      onClick={() => {
                        setSelectDevORCluster(true)
                        setSelectCommonArea(false)
                        setSelectUnitMaintenance(false)
                      }}
                      style={{height: '160px'}}
                    >
                      <h3 className={`head-text mb-3 ${selectDevORCluster ? 'headTextWhite' : ''}`}>
                        Development or Cluster
                      </h3>
                      <img
                        src={selectDevORCluster ? devClustImg : devClustGreenImg}
                        height={80}
                        width={80}
                        className='d-block mt-auto'
                      />
                    </div>
                    {selectDevORCluster && (
                      <button
                        type='button'
                        className=' mt-4 btn btn-sm fw-bold green-submit-btn w-100 d-flex justify-content-center'
                        onClick={() => {
                          if (selectReactiveBox)
                            navigate(`/maintenance/create-request/reactive/1/dev-clust`)
                        }}
                      >
                        Next
                      </button>
                    )}
                  </div>

                  {/* Common Areas */}
                  <div className='text-center'>
                    {/* <img src={informationImg} height={20} width={20} /> */}
                    <span>
                      <OverlayTrigger
                        placement='top'
                        delay={{show: 250, hide: 200}}
                        overlay={
                          <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                            {' '}
                            <h5 className='text-dark'>
                              <b>Common Areas</b>
                            </h5>
                            <p className='text-dark fnt-400 mb-0'>
                              {' '}
                              You will be able to submit a maintenance request on one or more common
                              areas within a selected development or cluster.
                            </p>
                          </Tooltip>
                        }
                      >
                        <span>
                          {' '}
                          <IoMdInformationCircleOutline
                            color='#384a62'
                            fontSize={16}
                            fontWeight={600}
                            className='cursor-pointer'
                            style={{cursor: 'pointer', marginBottom: '2px'}}
                          />
                        </span>
                      </OverlayTrigger>
                    </span>
                    <div
                      className={`${
                        selectCommonArea ? 'mainRequestBoxColor' : 'mainRequestBox'
                      } mt-3`}
                      onClick={() => {
                        setSelectDevORCluster(false)
                        setSelectCommonArea(true)
                        setSelectUnitMaintenance(false)
                      }}
                      style={{height: '160px'}}
                    >
                      <h3
                        className={`head-text mb-3 ${
                          selectCommonArea ? 'headTextWhite' : 'text-dark'
                        }`}
                      >
                        Common Areas
                      </h3>
                      <img
                        src={selectCommonArea ? commonAreasImg : commonAreasGreenImg}
                        height={80}
                        width={80}
                        className='d-block mt-auto'
                      />
                    </div>
                    {selectCommonArea && (
                      <button
                        type='button'
                        className=' mt-4 btn btn-sm fw-bold green-submit-btn w-100 d-flex justify-content-center'
                        onClick={() => {
                          if (selectReactiveBox)
                            navigate(`/maintenance/create-request/reactive/1/common-area`)
                        }}
                      >
                        Next
                      </button>
                    )}
                  </div>

                  {/* Units */}
                  <div className='text-center'>
                    {/* <img src={informationImg} height={20} width={20} /> */}
                    <span>
                      <OverlayTrigger
                        placement='top'
                        delay={{show: 250, hide: 200}}
                        overlay={
                          <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                            {' '}
                            <h5 className='text-dark'>
                              <b>Units</b>
                            </h5>
                            <p className='text-dark fnt-400 mb-0'>
                              {' '}
                              You will be able to submit a maintenance request on one or more units
                              within a selected development or cluster.
                            </p>
                          </Tooltip>
                        }
                      >
                        <span>
                          {' '}
                          <IoMdInformationCircleOutline
                            color='#384a62'
                            fontSize={16}
                            fontWeight={600}
                            className='cursor-pointer'
                            style={{cursor: 'pointer', marginBottom: '2px'}}
                          />
                        </span>
                      </OverlayTrigger>
                    </span>
                    <div
                      className={`${
                        selectUnitMaintenance ? 'mainRequestBoxColor' : 'mainRequestBox'
                      } mt-3`}
                      onClick={() => {
                        setSelectDevORCluster(false)
                        setSelectCommonArea(false)
                        setSelectUnitMaintenance(true)
                      }}
                      style={{height: '160px'}}
                    >
                      <h3
                        className={`head-text mb-3 ${selectUnitMaintenance ? 'headTextWhite' : ''}`}
                      >
                        Units
                      </h3>
                      <img
                        src={selectUnitMaintenance ? unitImg : unitGreebImg}
                        height={80}
                        width={80}
                        className='d-block mt-auto'
                      />
                    </div>
                    {selectUnitMaintenance && (
                      <button
                        type='button'
                        className=' mt-4 btn btn-sm fw-bold green-submit-btn w-100 d-flex justify-content-center'
                        onClick={() => {
                          if (selectReactiveBox)
                            navigate(`/maintenance/create-request/reactive/1/unit`)
                        }}
                      >
                        Next
                      </button>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
          {/* <div className='row mx-0 px-0 my-5'>
            <button
              type='button'
              className=' mt-4 btn btn-sm fw-bold green-submit-btn w-100 d-flex justify-content-center status-w-130 ms-3'
              disabled={!selectReactiveBox && !selectPlannedBox && !selectPreventativeBox}
              onClick={() => {
                let type: any
                if (selectReactiveBox) type = 'reactive'
                else if (selectPlannedBox) type = 'planned'
                else type = 'preventative'
                navigate(`/maintenance/create-request/${type}/1`)
              }}
            >
              Next
            </button>
          </div> */}
        </div>
      </Modal>
    </>
  )
}

export default RequestModel
