import React, {useState, useEffect} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import Swal from 'sweetalert2'
import submitImg from '../../../../../../img/Submit-White.png'
import backArrow from '../../../../../../img/back-arrow.png'
import trashImg from '../../../../../../img/trash.png'
import saveGreenImg from '../../../../../../img/save-dark-green.png'
import SelectInvoice from './SelectInvoice'
import SelectedInvoicesCard from './SelectedInvoices'
import SelectPayor from './SelectPayor'
import RecordPayment from './RecordPayment'
import OtherAttachments from '../../OtherAttachments'
import ReceiptCard from './ReceiptCard'
import {ApiGet, ApiPost, ApiDelete} from '../../../../../../apiCommon/helpers/API/ApiData'
import {Controller, useForm} from 'react-hook-form'
import {SuccessToast, ErrorToast} from '../../../../../../apiCommon/helpers/Toast'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'

const RaiseReceipt = () => {
  const navigate = useNavigate()
  const {flg, id} = useParams()

  const [isSkeleton, setIsSkeleton] = useState<any>(false)
  const [isLoading, setIsLoading] = useState<any>(false)
  const [isViewOnly, setIsViewOnly] = useState<any>(false)

  const [goToNext, setGoToNext] = useState<any>(false)
  const [selectedInvoices, setSelectedInvoices] = useState<any>([])

  const [notesCount, setNotesCount] = useState<any>(`0/1000`)

  const {
    register,
    control,
    handleSubmit,
    reset,
    setValue,
    formState: {errors},
    trigger,
  } = useForm<any>({mode: 'onSubmit', reValidateMode: 'onChange'})

  const [receiptData, setReceiptData] = useState<any>({
    name: 'RECEIPT',
    receiptNo: '',
    note: '',
    attachments: [],
    payorData: {},
    datePaid: null,
    referenceNo: '',
    receiptCompany: {},
    receiptLogo: null,
  })

  //
  const updateSelectedInvoices = (val: any) => {
    let values: any = [...selectedInvoices]
    val?.map((vl: any, ind: number) => {
      values?.push(vl)
    })

    setSelectedInvoices(values)
  }

  // const [isLoading, setIsLoading] = useState<any>(false)

  const [isSaveFirst, setIsSaveFirst] = useState<any>(false)
  const [autoSaveData, setAutoSaveData] = useState<any>(false)

  const [open, setOpen] = React.useState(false)

  //
  const raiseAReceipt = async (saveStatus: number) => {

    let isValid: any = true
    if (saveStatus == 1) {
      isValid = await trigger()
    }

    let isPaymentAccountForAll : boolean = true

    if(saveStatus == 1){

    selectedInvoices?.map((slInv: any, ind: number) => {
      // console.log(slInv?.paymentAccountId)

      if(slInv?.paymentAccountId == null)
        isPaymentAccountForAll = false
    })
  }

    if (isValid && isPaymentAccountForAll ) {

    setIsLoading(true)
    setOpen(true)

    let invRecords: any = []

    let totalAmountOfReceipt: any = 0
    selectedInvoices?.map((slInv: any, ind: number) => {
      invRecords?.push({
        invoiceId: slInv?._id,
        paymentId: slInv?.paymentId,
        paymentAccountId: slInv?.paymentAccountId ? slInv?.paymentAccountId : null,
        paymentSubItems: slInv?.paymentSubItems,
      })

      slInv?.paymentSubItems?.map((invItm: any, indIn: number) => {
        totalAmountOfReceipt = Number(totalAmountOfReceipt) + Number(invItm?.amountPaid)
      })
    })

    const body = {
      createdOriginType: 1,
      invoiceRecords: invRecords,
      note: receiptData?.note,
      otherAttachment: receiptData?.attachments,
      receiptNo: receiptData?.receiptNo,
      tenantId: receiptData?.payorData?._id,
      datePaid: receiptData?.datePaid,
      amountPaid: totalAmountOfReceipt,
      paymentMethod: Number(receiptData?.paymentMethod?.[0]?.value),
      referenceNo: receiptData?.referenceNo,
      name: receiptData?.name,
      _id: flg == '2' ? id : undefined,
      formStatus: saveStatus,
      receiptCompany: receiptData?.receiptCompany,
      receiptLogo: receiptData?.receiptLogo,
    }

    // console.log(body)

    ApiPost(`corporate/payment_receipt/add_update`, body)
      .then((res) => {
        if (saveStatus == 0) {
          if (flg == '1') {
            navigate(`/finance-income/add-receipt/2/${res?.data?.data?._id}`)
            setTimeout(() => {
              setIsSaveFirst(true)
            }, 1000)
          } else {
            getReceiptById()
          }

          setTimeout(() => {
            setIsLoading(false)
            setOpen(false)
          }, 1500)
        } else {
          SuccessToast('Payment receipt has been added successfully')
          navigate('/finance-income')
        }
      })
      .catch((err: any) => {
        ErrorToast(err?.message)

        setTimeout(() => {
          setIsLoading(false)
          setOpen(false)
        }, 1500)
      })
    }
    else{
      if(!isPaymentAccountForAll)
        ErrorToast('Receiving account for each selected invoice must be selected.')
    }
  }

  //
  const getReceiptById = async () => {
    let url = `corporate/payment_receipt/${id}`

    await ApiGet(`${url}`)
      .then((res) => {
        setValue('documentName', res?.data?.data?.name)
        setValue('documentReceiptNo',  res?.data?.data?.receiptNo)
        setValue(
          'paymentMethod',
          res?.data?.data?.paymentMethod != null ? `${res?.data?.data?.paymentMethod}1` : ''
        )
        setValue('paymentDate', res?.data?.data?.datePaid)

        const values: any = {
          name: res?.data?.data?.name,
          receiptNo: res?.data?.data?.receiptNo,
          note: res?.data?.data?.note,
          attachments: res?.data?.data?.otherAttachment,
          payorData:  res?.data?.data?.tenant?.length > 0
          ? res?.data?.data?.tenant?.[0]
          : res?.data?.data?.invoiceRecords?.[0]?.payment_invoice?.tenant?.length > 0
          ? res?.data?.data?.invoiceRecords?.[0]?.payment_invoice?.tenant?.[0]
          : {},
          datePaid: res?.data?.data?.datePaid,
          referenceNo: res?.data?.data?.referenceNo,
          receiptCompany: res?.data?.data?.receiptCompany,
          receiptLogo: res?.data?.data?.receiptLogo,
          paymentMethod: [],
        }

        switch (res?.data?.data?.paymentMethod) {
          case 1:
            values.paymentMethod = [{value: 1, label: 'Cheque'}]
            break
          case 2:
            values.paymentMethod = [{value: 2, label: 'Bank Transfer'}]
            break
          case 3:
            values.paymentMethod = [{value: 3, label: 'Cash'}]
            break
          case 0:
            values.paymentMethod = [{value: 0, label: 'Card'}]
            break
          default:
            values.paymentMethod = []
        }

        setReceiptData(values)

        let invValues: any = []
        res?.data?.data?.invoiceRecords?.map((inv: any, index: number) => {
          invValues?.push(inv?.payment_invoice)

          // invValues[index].payment_account = inv?.payment_account
          invValues[index].paymentSubItems = inv?.paymentSubItems
          invValues[index].tenant =
            inv?.tenant?.length > 0
              ? inv?.tenant
              : res?.data?.data?.tenant?.length > 0
              ? res?.data?.data?.tenant
              : []
          invValues[index].paymentAccountId = inv?.payment_invoice?.payment_account?.length > 0 
              ? inv?.payment_invoice?.payment_account?.[0]?._id 
              : null
          invValues[index].paymentId = inv?.paymentId

          setValue(
            `accountNumber${index}`,
            invValues[index].paymentAccountId != null ? `${invValues[index].paymentAccountId}` : ''
          )
        })

        setSelectedInvoices(invValues)

        setNotesCount(`${res?.data?.data?.note?.length}/${1000}`)
      })
      .catch((err: any) => {
        console.log('err', err)
      })
  }

  useEffect(() => {
    if (isSaveFirst || flg == '2') getReceiptById()
  }, [isSaveFirst])

  //
  useEffect(() => {
    if (autoSaveData != false && flg == '2') {
      raiseAReceipt(0)
    }
  }, [autoSaveData])

  // delete receipt
  const deleteReceipt = () => {
    ApiDelete(`corporate/payment_receipt/${id}`)
      .then((res) => {
        SuccessToast('Payment receipt has been deleted successfully')
        navigate('/finance-income')
      })
      .catch((err) => {
        ErrorToast(err.message)
      })
  }

  return (
    <>
      <div className='app-main flex-column flex-row-fluid px-2' id='kt_app_main'>
        <form
          onSubmit={handleSubmit((data: any, e: any) => {
            raiseAReceipt(1)
          })}
        >
          <div id='' className='pt-0 mt-0'>
            {/* Header */}
            <div className='page-title d-flex flex-column justify-content-center flex-wrap'>
              {' '}
              <div className='d-flex align-items-center mb-1'>
                <div className='d-flex align-items-center'>
                  <span
                    className='rounded-circle cursor-pointer'
                    onClick={() => {
                      if (goToNext) setGoToNext(false)
                      else navigate('/finance-income')
                    }}
                  >
                    <img src={backArrow} height='14' width='14' />
                  </span>
                  <h2 className='m-0 head-text ms-2 text-capitalize'>{`Raise Receipt`}</h2>
                </div>

                {selectedInvoices?.length > 0 && (
                  <div className='d-flex ms-auto'>
                    {flg != '1' ? (
                      <button
                        type='button'
                        className='btn btn-sm fw-bold  ms-3 red-hollow-btn ps-3'
                        disabled={isLoading || isViewOnly}
                        onClick={() => {
                          Swal.fire({
                            html: ` 
                            <div class='fs-3'> <b>Delete Payment Receipt</></div>
                             <div class='fs-5 mt-4'>You are about to delete the payment receipt.</div>
                            <div class='fs-5 mt-4'> Are you sure you want to continue? </div>`,
                            // icon: 'warning',
                            showConfirmButton: true,
                            confirmButtonColor: '#35475e',
                            confirmButtonText: 'Yes',
                            showCancelButton: true,
                            cancelButtonText: 'No',
                            cancelButtonColor: '#fff',
                            customClass: {
                              confirmButton: 'custom-confirm-button',
                              cancelButton: 'custom-cancel-button',
                              popup: 'custom-popup',
                            },
                            reverseButtons: true, // Reverse the order of buttons reverseButtons: true,
                          }).then((res: any) => {
                            if (res.isConfirmed) {
                              deleteReceipt()
                            }
                          })
                        }}
                      >
                        <img src={trashImg} height={18} width={18} className='me-4' /> Delete
                      </button>
                    ) : (
                      <button
                        type='button'
                        className='btn btn-sm fw-bold ms-3 green-hollow-btn ps-3'
                        onClick={() => {
                          raiseAReceipt(0)
                        }}
                        disabled={isLoading}
                      >
                        <img src={saveGreenImg} height={18} width={18} className='me-4' /> {'  '}
                        Save
                      </button>
                    )}

                    <button
                      type='button'
                      className='btn btn-sm fw-bold ms-3 green-submit-btn ps-3'
                      onClick={() => {
                        raiseAReceipt(1)
                      }}
                      disabled={isLoading || !goToNext}
                    >
                      <img src={submitImg} height={19} width={19} className='me-4' /> {'  '}
                      Create
                    </button>
                  </div>
                )}
              </div>
            </div>

            {!goToNext ? (
              <>
                <SelectPayor
                  slctdInvoices={selectedInvoices}
                  updateSelectedPayor={(data: any) => {
                    let values: any = {...receiptData}
                    values.payorData = data
                    setReceiptData(values)

                    if (flg == '2') {
                      setTimeout(() => {
                        setAutoSaveData(Date.now())
                      }, 700)
                    }
                  }}
                  rcptData={receiptData}
                  updateSelectedInvoices={(data:any, invs:any)=>{

                    let values: any = {...receiptData}
                    values.payorData = data
                    setReceiptData(values)

                    setSelectedInvoices([])

                    if (flg == '2') {
                      setTimeout(() => {
                        setAutoSaveData(Date.now())
                      }, 700)
                    }
                  }}
                />
                {/* Select invoice Card */}
                {/* {refreshChild && ( */}
                <div className='position-relative'>
                  {receiptData?.payorData?._id == undefined && (
                    <div className='requestBoxOpacity z-index-999 border-radius-5px'></div>
                  )}
                  <div>
                    <SelectInvoice
                      slctdInvoices={selectedInvoices}
                      updateCheckedInvoices={(data: any) => {
                        updateSelectedInvoices(data)

                        if (flg == '2') {
                          setTimeout(() => {
                            setAutoSaveData(Date.now())
                          }, 700)
                        }
                      }}
                      rcptData={receiptData}
                    />
                  </div>
                </div>
                {/* )} */}

                {/* Selected invoices */}
                {/* {refreshChild && ( */}
                <div className='position-relative'>
                  {receiptData?.payorData?._id == undefined && (
                    <div className='requestBoxOpacity z-index-999 border-radius-5px'></div>
                  )}
                  <div>
                    <SelectedInvoicesCard
                      slctdInvoices={selectedInvoices}
                      updateCheckedInvs={(data: any) => {
                        setSelectedInvoices(data)

                        if (flg == '2') {
                          setTimeout(() => {
                            setAutoSaveData(Date.now())
                          }, 700)
                        }
                      }}
                    />{' '}
                  </div>
                </div>
                {/* )} */}

                {/* Next CTA */}
                <div className='d-flex justify-content-center'>
                  <button
                    type='button'
                    className='btn btn-sm fw-bold ms-3 green-submit-btn ps-3 d-flex justify-content-center purchase-order-btn mt-10'
                    disabled={selectedInvoices?.length == 0}
                    onClick={() => {
                      setGoToNext(true)
                    }}
                  >
                    Next
                  </button>
                </div>
              </>
            ) : (
              <>
                <RecordPayment
                  slctdInvoices={selectedInvoices}
                  register={register}
                  errors={errors}
                  rcptData={receiptData}
                  setValue={setValue}
                  trigger={trigger}
                  control={control}
                  updatePaymentRecord={(data: any) => {
                    setSelectedInvoices(data)
                  }}

                  autoSaveParent={()=>{
                    if (flg == '2') {
                      setTimeout(() => {
                        setAutoSaveData(Date.now())
                      }, 700)
                    }
                  }}
                  editMode={true}
                />

                {/* Notes & Attachments */}
                <div className='row mt-4 d-flex'>
                  {/* Notes */}
                  <div className='col-6 px-4' style={{display: 'flex', flexDirection: 'column'}}>
                    <div className={'card card-flush py-5 container-xxl mt-7 pe-5 mb-5 px-6 h-100'}>
                      <div className='mb-5'>
                        <div className='d-flex align-items-start mb-1'>
                          <h2 className='me-1' style={{minWidth: '160px', fontWeight: '700'}}>
                            Notes
                          </h2>
                        </div>

                        <div className='d-flex align-items-center mb-2'>
                      <h4 className='pt-2 mb-4 head-text'>
                        Enter notes related to this payment record
                      </h4>
                    </div>

                        <textarea
                          className='form-control form-control-solid-bg input-style mytest bg-white'
                          name='comment'
                          placeholder='Type here...'
                          style={{height: '150px', borderColor: 'lightgray'}}
                          value={receiptData?.note}
                          onChange={(e) => {
                            const values = {...receiptData}
                            values.note = e.target.value
                            setReceiptData(values)

                            const target = e.target

                            // Get the `maxlength` attribute
                            const maxLength = target.getAttribute('maxlength')
                            // Count the current number of characters
                            const currentLength = target.value.length

                            setNotesCount(`${currentLength}/${maxLength}`)
                          }}
                          maxLength={1000}
                          onBlur={() => {
                            if (flg == '2') {
                              setTimeout(() => {
                                setAutoSaveData(Date.now())
                              }, 700)
                            }
                          }}
                        ></textarea>

                        <div className='d-flex align-items-center justify-content-end mb-2'>
                          <label className='mb-1 blue-label fs-7'>{notesCount}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className='col-6 px-4 pe-0'
                    style={{display: 'flex', flexDirection: 'column'}}
                  >
                    <OtherAttachments
                      incmData={receiptData}
                      from={'raise-receipt'}
                      updateParent={(attachVals: any) => {
                        const values = {...receiptData}
                        values.attachments = attachVals
                        setReceiptData(values)

                        if (flg == '2') {
                          setTimeout(() => {
                            setAutoSaveData(Date.now())
                          }, 700)
                        }
                      }}
                      editMode={true}
                    />
                  </div>
                </div>

                {/* Receipt Card */}
                <ReceiptCard
                  register={register}
                  errors={errors}
                  rcptData={receiptData}
                  setValue={setValue}
                  trigger={trigger}
                  control={control}
                  updateReceipt={(data: any) => {
                    console.log(data)
                    setReceiptData(data)

                    if (flg == '2') {
                      setTimeout(() => {
                        setAutoSaveData(Date.now())
                      }, 700)
                    }
                  }}
                  slctdInvoices={selectedInvoices}
                  editMode={true}
                />
              </>
            )}
          </div>
        </form>
      </div>

      <Backdrop sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}} open={open}>
        <CircularProgress style={{color: '#007a59'}} />
      </Backdrop>
    </>
  )
}

export default RaiseReceipt
