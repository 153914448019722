import React, {useEffect, useState, useRef} from 'react'
import {Row, Col, OverlayTrigger, Tooltip, Modal} from 'react-bootstrap'
import {ApiPost, Bucket, ApiGet} from '../../../../../../../apiCommon/helpers/API/ApiData'
import moment from 'moment'
import InputNotRequired from '../../../../../General/InputNotRequired'
import {DatePicker} from 'antd'
import {Moment} from 'moment'
import {MultiSelect} from 'react-multi-select-component'
import {Controller, useForm} from 'react-hook-form'
import {useParams} from 'react-router-dom'
import {formatAmount, capitalizeFirstLetter} from '../../../../../../../Utilities/utils'
import redCrossFilled from '../../../../../../../img/remove-filled.png'
import addIcon from '../../../../../../../img/add-black.png'
import TextEditor from '../../../../../General/TextEditor'
import addWhite from '../../../../../../../img/add-white.png'
import moveUpImg from '../../../../../../../img/scroll-up.png'
import movedownImg from '../../../../../../../img/scroll-down.png'

interface ButtonProps {
  slctdInvoices?: any
  noteCardData?: any
  register?: any
  errors?: any
  setValue?: any
  trigger?: any
  control?: any
  updateNoteData?: any
  editMode?: boolean
  from?: string
  isPrint?: boolean
}

const NoteDetailsCard = ({
  slctdInvoices,
  noteCardData,
  register,
  errors,
  setValue,
  trigger,
  control,
  updateNoteData,
  editMode,
  from,
  isPrint,
}: ButtonProps) => {
  const {flg, id} = useParams()
  const [logo, setLogo] = useState<any>(null)

  const [noteData, setNoteData] = useState<any>(noteCardData)
  console.log(noteCardData)
  const [noteCompany, setNoteCompany] = useState<any>({
    name: '',
    address: '',
    phoneNumber: '',
    email: '',
    countryCode: '',
  })

  const [notesCount, setNotesCount] = useState<any>(`0/1000`)

  const [noteSettingsDetails, setNoteSettingsDetails] = useState<any>(null)
  //
  const getNoteSettingsDetails = () => {
    ApiGet('corporate/corporate_setting')
      .then((res) => {
        setNoteSettingsDetails(res?.data?.data)
        if (flg == '1') setNoteCompany(res?.data?.data?.creditNoteCompany)
        setLogo(res?.data?.data?.creditNoteLogo)

        // if (flg == '1')
        //   updateNoteData({
        //     ...noteData,
        //     ['noteCompany']: res?.data?.data?.creditNoteCompany,
        //     ['receiptLogo']: res?.data?.data?.creditNoteLogo,
        //   })
      })
      .catch((err) => {
        console.log('err', err)
      })
  }

  useEffect(() => {
    if (flg != undefined) getNoteSettingsDetails()
  }, [])

  //
  function disabledDate(current: Moment) {
    return current && current > moment().endOf('day')
  }

  const [pickerKey, setPickerKey] = useState(Date.now())
  //
  const handleDateChange = (date: any) => {
    if (date != null) {
      let values = {...noteData}
      values.datePaid = date.format('YYYY-MM-DD')
      setNoteData(values)
      updateNoteData({...noteData, ['datePaid']: date.format('YYYY-MM-DD')})

      setValue('paymentDate', date.format('YYYY-MM-DD'), {shouldValidate: true})
    } else {
      let values = {...noteData}
      values.datePaid = null

      setNoteData(values)
      if (!date) {
        // Force re-render by updating the key
        setPickerKey(Date.now())
      }
      updateNoteData({...noteData, ['datePaid']: null})

      setValue('paymentDate', '', {shouldValidate: true})
    }
  }

  useEffect(() => {
    setNoteCompany(noteCardData?.creditNoteCompany)
    setLogo(noteCardData?.creditNoteLogo)
    setNoteData(noteCardData)
  }, [noteCardData])

  const intervalRef = useRef<NodeJS.Timer | null>(null)

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      const checkboxes = document.querySelectorAll(
        '.property-test .multi-select-container .rmsc .item-renderer input[type=checkbox]'
      )
      // console.log(checkboxes)
      checkboxes.forEach((checkbox) => {
        checkbox.setAttribute('type', 'radio')
      })
    }, 100)

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
      }
    }
  }, [])

  const [selectedInvoices, setSelectedInvoices] = useState<any>([])

  useEffect(() => {
    setSelectedInvoices(slctdInvoices)
  }, [slctdInvoices])

  var nonVat: any = 0
  var vatAmount: any = 0

  // Sections
  const handleAddNewSections = () => {
    let values = {...noteData}
    let valueItems: any = [...noteData?.itemDescriptions]
    valueItems.push({
      name: '',
      content: '',
    })
    values.itemDescriptions = valueItems
    updateNoteData(values)
  }

  //
  const handleRemoveSections = (index: any) => {
    let values = {...noteData}
    let valueItems: any = [...noteData?.itemDescriptions]
    valueItems.splice(index, 1)
    values.itemDescriptions = valueItems
    updateNoteData(values)
  }

  //
  const swapItems = (prevInd: any, nextInd: any) => {
    const values = {...noteData}
    const valuesSections = [...noteData?.itemDescriptions]

    // Add animation classes
    valuesSections[prevInd].animationClass = 'move-down'
    valuesSections[nextInd].animationClass = 'move-up'

    updateNoteData({...values, itemDescriptions: valuesSections})

    setTimeout(() => {
      let temp = valuesSections[prevInd]
      valuesSections[prevInd] = valuesSections[nextInd]
      valuesSections[nextInd] = temp
      valuesSections[prevInd].animationClass = ''
      valuesSections[nextInd].animationClass = ''

      values.itemDescriptions = valuesSections
      updateNoteData(values)
    }, 300)
  }

  return (
    <div>
      <div className='card card-flush py-7 px-5 pb-13 mb-5'>
        <Row className=''>
          <Col xs={6} sm={6} md={9} lg={9}>
            <div className='d-flex mb-3 mt-2 align-items-center'>
              {logo == null || logo?.trim()?.length == 0 ? (
                <></>
              ) : (
                <img
                  src={`${Bucket}${logo}`}
                  height='110'
                  width='110'
                  style={{borderRadius: '7px'}}
                  className='me-4'
                />
              )}
            </div>
          </Col>
          <Col xs={6} sm={6} md={3} lg={3} className='pe-4'>
            <div className='d-flex align-items-center my-2'>
              <label
                className='mb-1 me-5 pe-5 ms-auto head-text'
                style={{minWidth: '50px', maxWidth: '50px'}}
              >
                <b>TRN</b>
              </label>
              <b>{noteCompany?.trn ? noteCompany?.trn : '-'}</b>
            </div>

            {/* Document Name */}
            <div className='text-end'>
              {editMode ? (
                <>
                  <div className='my-5 '>
                    <InputNotRequired
                      required={true}
                      register={register}
                      errors={errors}
                      value={noteData?.name}
                      setValue={setValue}
                      trigger={trigger}
                      onBlur={() => {
                        updateNoteData(noteData)
                      }}
                      onChange={(val: any) => {
                        setNoteData({...noteData, ['name']: val})
                      }} // Pass the callback function to handle input changes
                      placeholder='Enter Document Name'
                      name='documentName'
                      pattern={/^[A-Za-z0-9._ \-]*$/} // Pattern to allow letters, numbers, periods, underscores, hyphens, and spaces
                      maxLength={30} // Maximum length of 50 characters
                      className='text-end document-name-input-field'
                    />
                  </div>
                  {/* <h3 className='text-danger mb-0 ms-2 my-5'>
                  <b>*</b>
                </h3> */}
                </>
              ) : (
                <p className='ms-auto pt-1 document-name-input-field my-5 fnt-500'>
                  {`${noteData?.name || ''}`}
                </p>
              )}
            </div>

            {/* Credit Note No. */}
            <div className='d-flex align-items-start justify-content-start my-2'>
              <label
                className='mb-1 ms-3 pt-2 fnt-500  status-w-100 text-start'
                style={{
                  fontSize: '1rem',
                }}
              >
                {from == 'credit note' ? 'Credit ' : 'Debi '} Note No.
              </label>
              {editMode ? (
                <>
                  {noteSettingsDetails?.receiptSequence?.activityStatus == 1 ? (
                    <>
                      {/* {noteSettingsDetails?.receiptSequence?.prefix != null && ( */}
                      <p className='pt-2 mb-0 ms-auto'>
                        <b>{noteData?.creditNoteNo ? noteData?.creditNoteNo : '-'}</b>
                      </p>
                      {/* )} */}
                    </>
                  ) : (
                    <div className='ms-auto receipt-date'>
                      <InputNotRequired
                        required={true}
                        register={register}
                        errors={errors}
                        value={noteData?.creditNoteNo}
                        setValue={setValue}
                        trigger={trigger}
                        onBlur={() => {
                          updateNoteData(noteData)
                        }}
                        onChange={(val: any) => {
                          setNoteData({...noteData, ['creditNoteNo']: val})
                        }} // Pass the callback function to handle input changes
                        placeholder={`Enter ${from == 'credit note' ? 'Credit' : 'Debit'} Note No.`}
                        name='documentReceiptNo'
                        pattern={/^[A-Za-z0-9._ \-]*$/} // Pattern to allow letters, numbers, periods, underscores, hyphens, and spaces
                        maxLength={15} // Maximum length of 50 characters
                        style={{height: '35px'}}
                        className='your-custom-class receipt-date text-start'
                      />
                    </div>
                  )}
                  {noteSettingsDetails?.receiptSequence?.activityStatus == 0 && (
                    <h3 className='text-danger mb-0 ms-2'>
                      <b>*</b>
                    </h3>
                  )}
                </>
              ) : (
                <label className='ms-auto pt-1'>
                  <b>{`${noteData?.creditNoteNo || ''}`}</b>
                </label>
              )}
            </div>

            {/* Issued Date */}
            <div className='d-flex align-items-start justify-content-start my-2'>
              <label
                className='mb-1 ms-3 pt-2 fnt-500  status-w-100 text-start'
                style={{
                  fontSize: '1rem',
                }}
              >
                Issued Date
              </label>

              <label className='ms-auto pt-1'>
                {editMode ? (
                  <b>-</b>
                ) : (
                  <b>
                    {noteData?.createdAt ? moment(noteData?.createdAt).format('DD.MM.YYYY') : '-'}
                  </b>
                )}
              </label>
            </div>
          </Col>
        </Row>

        <Row>
          <Col xs={6} sm={6} md={9} lg={9}>
            <div className='w-75'>
              <div className='d-flex align-items-center'>
                <p className='mb-4 fs-4 fnt-600'>Recipient</p>
              </div>

              <p style={{marginBottom: '0.1rem'}} className='fnt-500'>
                {/* {noteData?.tenant?.firstName} {noteData?.tenant?.lastName} */}
                {noteData?.payorData?.tenant?.[0]?.firstName &&
                noteData?.payorData?.tenant?.[0]?.lastName
                  ? `${noteData?.payorData?.tenant?.[0]?.firstName} ${noteData?.payorData?.tenant?.[0]?.lastName}`
                  : '-'}
              </p>
              <p style={{marginBottom: '0.1rem'}} className='fnt-500'>
                {noteData?.payorData?.tenant?.[0]?.countryCode &&
                noteData?.payorData?.tenant?.[0]?.phoneNumber
                  ? `+${noteData?.payorData?.tenant?.[0]?.countryCode} ${noteData?.payorData?.tenant?.[0]?.phoneNumber}`
                  : '-'}
              </p>
              <p style={{marginBottom: '0.1rem'}} className='fnt-500'>
                {noteData?.payorData?.tenant?.[0]?.email
                  ? noteData?.payorData?.tenant?.[0]?.email
                  : '-'}
              </p>
            </div>
          </Col>
          <Col xs={6} sm={6} md={3} lg={3} className='pe-4'>
            <div className='text-end'>
              <p style={{marginBottom: '0.1rem'}} className='fs-4 fnt-600 mb-4'>
                {' '}
                {noteCompany?.name != null && noteCompany?.name}
              </p>
              <p style={{marginBottom: '0.1rem'}} className='fs-7 fnt-500'>
                {' '}
                {noteCompany?.address != null && noteCompany?.address}
              </p>

              <p style={{marginBottom: '0.1rem'}} className='fs-7 fnt-500'>
                {noteCompany?.phoneNumber != null &&
                  noteCompany?.countryCode + noteCompany?.phoneNumber}
              </p>
              <p style={{marginBottom: '0.1rem'}} className='fs-7 fnt-500'>
                {' '}
                {noteCompany?.email != null && noteCompany?.email}
              </p>
            </div>
          </Col>
        </Row>

        {/* Separator */}
        <Row className='mt-7 separator mx-0'></Row>

        {/* Items Details */}
        <div className='table-responsive mt-4 mb-10'>
          <table className='table align-start fs-6 gy-5' id='kt_ecommerce_sales_table'>
            <thead>
              <th className='text-start min-w-25px ps-3'>#</th>
              <th className='text-start min-w-225px ps-3'>DESCRIPTION</th>
              <th className='text-start min-w-100px ps-3'>INVOICE</th>
              <th className='text-start min-w-100px ps-3'>RATE</th>
              <th className='text-start min-w-100px ps-3'>QTY</th>
              <th className='text-start min-w-100px ps-3'>VAT</th>
              <th className='text-start min-w-100px ps-3'> CREDIT AMOUNT</th>
            </thead>

            {/* <>
                  {noteData?.payment_sub_item?.map((itm: any) => {
                    totalPrice =
                      totalPrice + parseFloat(itm?.quantity) * parseFloat(itm?.amount)
                    return <></>
                  })}
                </> */}

            <tbody className='fw-semibold'>
              {noteData?.payment_sub_item?.map((item: any, index: any) => {
                return (
                  <>
                    <tr key={index}>
                      <td className='ps-3'>
                        {' '}
                        <p className='pt-2' style={{fontWeight: '700'}}>
                          {index < 9 ? `0${index + 1}` : index + 1}
                        </p>
                      </td>
                      <td className='ps-3'>{item?.name ? item?.name : '-'}</td>
                      <td className='ps-3'>{item?.name ? item?.name : '-'}</td>
                      <td className=' ps-3'>{item?.amount ? item?.amount : '-'}</td>
                      <td className=' ps-3'>{item?.quantity ? item?.quantity : '-'}</td>
                      <td className=' ps-3'>
                        {item?.frontendStorage?.isLatePaymentItem ? (
                          <>{(item.amount / 100) * item.VATPercentage}</>
                        ) : (
                          <>{((item.amount * item.quantity) / 100) * item.VATPercentage}</>
                        )}
                      </td>
                      <td className='ps-3'>{item?.totalAmount ? item?.totalAmount : '-'}</td>
                    </tr>
                  </>
                )
              })}

              <>
                {noteData?.payment_sub_item?.map((f: any, ind: any) => {
                  vatAmount = 0
                  nonVat = 0
                  return <></>
                })}
              </>
              {noteData?.payment_sub_item?.length > 0 && (
                <>
                  {noteData?.payment_sub_item?.map((f: any, ind: any) => {
                    if (f?.frontendStorage?.isLatePaymentItem) {
                      vatAmount = vatAmount + (f.amount / 100) * f.VATPercentage
                      nonVat = nonVat + f.amount
                    } else {
                      vatAmount = vatAmount + ((f.amount * f.quantity) / 100) * f.VATPercentage
                      nonVat = nonVat + f.amount * f.quantity
                    }
                    return <></>
                  })}
                </>
              )}

              <tr style={{height: '40px'}}>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className='py-2 ps-5 fs-4' style={{color: '#B5B5C3'}}>
                  SUB TOTAL
                </td>
                <td className='py-2 fs-4'>{formatAmount(nonVat)}</td>
              </tr>

              <tr style={{height: '40px'}}>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className='py-2 ps-5 fs-4' style={{color: '#B5B5C3'}}>
                  VAT
                </td>
                <td className='py-2 fs-4'>{formatAmount(vatAmount)}</td>
              </tr>
              <tr style={{height: '40px'}} className='pb-4'>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className='py-2 ps-5 fs-3'>Total Credit</td>
                <td className='py-2 fs-3' style={{fontWeight: '800px'}}>
                  {' '}
                  <b>AED {formatAmount(nonVat + vatAmount)}</b>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* Separator */}
        <Row className='mt-7 separator mx-0 mb-15'></Row>

        {/* Notes */}
        {editMode ? (
          <Row>
            {noteData?.showNotes ? (
              <Col sm={12} md={12} style={{display: 'flex', flexDirection: 'column'}}>
                <div className={'card card-flush py-5 mt-2  pe-5 mb-5 px-6 h-100'}>
                  <div className='mb-5 h-10'>
                    <div className='d-flex align-items-start mb-4'>
                      <h2 className='me-1' style={{minWidth: '160px', fontWeight: '700'}}>
                        Notes
                      </h2>
                      {editMode ? (
                        <img
                          src={redCrossFilled}
                          height={18}
                          width={18}
                          className='cursor-pointer ms-auto'
                          onClick={() => {
                            // setShowNotes(false)

                            let values = {...noteData}
                            values.showNotes = false
                            updateNoteData(values)
                          }}
                        />
                      ) : (
                        <></>
                      )}
                    </div>

                    <textarea
                      className='form-control form-control-solid-bg input-style mytest bg-white'
                      name='comment'
                      placeholder='Enter the reasons for issuing or any additional notes to the recipient'
                      style={{height: '150px', borderColor: 'lightgray'}}
                      value={noteData?.note}
                      onChange={(e) => {
                        const values = {...noteData}
                        values.note = e.target.value
                        updateNoteData(values)
                      }}
                      maxLength={1000}
                      onBlur={() => {}}
                    ></textarea>
                  </div>
                </div>
              </Col>
            ) : (
              <Col sm={6} md={6} style={{display: 'flex', flexDirection: 'column'}} className='mx-auto'>
                <div
                  className='custom-section'
                  onClick={() => {
                    // setShowNotes(true)

                    let values = {...noteData}
                    values.showNotes = true
                    updateNoteData(values)

                    if (flg == '2') {
                    }
                  }}
                >
                  <div className='content'>
                    <img src={addIcon} height={18} width={18} className='me-4' />
                    <h4 className='my-2 mb-3'>
                      <label className={`text-gray-400 fw-bold fs-4`}>
                        {' '}
                        Add Recipient Notes Section
                      </label>
                    </h4>
                  </div>
                </div>
              </Col>
            )}
          </Row>
        ) : (
          <Row>
            {noteData?.note && noteData?.showNotes && !isPrint ? (
              <Col sm={6} md={6} style={{display: 'flex', flexDirection: 'column'}}>
                <div className={'py-5  mt-2  pe-5 mb-5 px-6 h-100'}>
                  <div className='mb-5 h-10'>
                    <div className='d-flex align-items-start mb-4'>
                      <h2 className='me-1' style={{minWidth: '160px', fontWeight: '700'}}>
                        Notes
                      </h2>
                    </div>

                    <p className=' mb-0 ms-auto'>
                      {noteData?.note && noteData?.showNotes ? noteData?.note : '-'}
                    </p>
                  </div>
                </div>
              </Col>
            ) : noteData?.note && noteData?.showNotes && isPrint ? (
              <Col sm={6} md={6} style={{display: 'flex', flexDirection: 'column'}}>
                <div className={'py-5  mt-2  pe-5 mb-5 px-6 h-100'}>
                  <div className='mb-5 h-10'>
                    <div className='d-flex align-items-start mb-4'>
                      <h2 className='me-1' style={{minWidth: '160px', fontWeight: '700'}}>
                        Notes
                      </h2>
                    </div>

                    <p className=' mb-0 ms-auto'>
                      {noteData?.note && noteData?.showNotes ? noteData?.note : '-'}
                    </p>
                  </div>
                </div>
              </Col>
            ) : !noteData?.note && noteData?.showNotes && !isPrint ? (
              <Col sm={6} md={6} style={{display: 'flex', flexDirection: 'column'}}>
                <div className={'py-5  mt-2  pe-5 mb-5 px-6 h-100'}>
                  <div className='mb-5 h-10'>
                    <div className='d-flex align-items-start mb-4'>
                      <h2 className='me-1' style={{minWidth: '160px', fontWeight: '700'}}>
                        Notes
                      </h2>
                    </div>

                    <p className=' mb-0 ms-auto'>
                      {noteData?.note && noteData?.showNotes ? noteData?.note : '-'}
                    </p>
                  </div>
                </div>
              </Col>
            ) : (
              <></>
            )}
          </Row>
        )}

        {/* Other Sections */}
        {noteData?.itemDescriptions?.map((pr: any, ind: any) => {
          return (
            <div className={`${pr.animationClass} px-6 ${editMode ? '' : 'po-editor-view pb-7'}`}>
              <div className={`d-flex mb-2 mt-7 align-items-center `}>
                <h3 className='m-0 head-text text-capitalize'>
                  {editMode ? (
                    <input
                      type='text'
                      className='form-control form-control-solid mytest tenancy-control mb-3 bg-white'
                      name='mainService'
                      value={pr?.title ? pr?.title : ''}
                      style={{height: '35px', width: '300px'}}
                      placeholder={'Add Section Title'}
                      onChange={(e: any) => {
                        const value = e.target.value
                        let values = {...noteData}
                        let valuesSections = [...noteData?.itemDescriptions]
                        valuesSections[ind].title = capitalizeFirstLetter(value)
                        values.itemDescriptions = valuesSections
                        updateNoteData(values)
                      }}
                      onBlur={() => {}}
                    />
                  ) : (
                    <p className='pt-2 mb-0 ms-auto'>
                      <h2 className='top ps-0'>
                        <b>{pr?.title ? pr?.title : '-'}</b>
                      </h2>
                    </p>
                  )}
                </h3>

                <div className='d-flex ms-auto align-items-center'>
                  {noteData?.itemDescriptions?.length > 1 && (
                    <>
                      {ind != noteData?.itemDescriptions?.length - 1 && editMode && (
                        <OverlayTrigger
                          placement='top'
                          delay={{show: 250, hide: 400}}
                          overlay={
                            <Tooltip id='button-tooltip-3' className='custom-tooltip'>
                              {`Move this section one step down`}
                            </Tooltip>
                          }
                        >
                          <span>
                            {' '}
                            <img
                              src={movedownImg}
                              height={20}
                              width={20}
                              className='cursor-pointer'
                              onClick={() => {
                                swapItems(ind, ind + 1)
                              }}
                            />
                          </span>
                        </OverlayTrigger>
                      )}

                      {ind != 0 && editMode && (
                        <OverlayTrigger
                          placement='top'
                          delay={{show: 250, hide: 400}}
                          overlay={
                            <Tooltip id='button-tooltip-3' className='custom-tooltip'>
                              {`Move this section one step up`}
                            </Tooltip>
                          }
                        >
                          <span>
                            {' '}
                            <img
                              src={moveUpImg}
                              height={20}
                              width={20}
                              className='ms-5 cursor-pointer'
                              onClick={() => {
                                swapItems(ind - 1, ind)
                              }}
                            />
                          </span>
                        </OverlayTrigger>
                      )}
                    </>
                  )}

                  {editMode ? (
                    <img
                      src={redCrossFilled}
                      height={18}
                      width={18}
                      className='ms-5 cursor-pointer'
                      onClick={() => {
                        handleRemoveSections(ind)
                      }}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </div>

              <TextEditor
                value={pr?.html}
                onChange={(val: any) => {
                  let values = {...noteData}
                  let valuesSections = [...noteData?.itemDescriptions]
                  valuesSections[ind].html = val
                  values.itemDescriptions = valuesSections
                  updateNoteData(values)
                }}
                autoSaveText={() => {}}
                refresh={noteData}
                placeholder={'Add section details here...'}
                isReadOnly={editMode ? false : true}
              />
            </div>
          )
        })}

        {editMode ? (
          <div className='d-flex justif-content-center mt-5 mb-10'>
            <button
              disabled={
                noteData?.itemDescriptions?.length > 0 &&
                noteData?.itemDescriptions?.some((itm: any) => {
                  const tempElement = document.createElement('div')
                  tempElement.innerHTML = itm?.html
                  return (
                    !tempElement.textContent?.trim() ||
                    !tempElement.innerText?.trim() ||
                    !itm?.title?.trim()
                  )
                })
              }
              type='button'
              className='btn btn-sm fw-bold select-btn btn-fit-content ps-2 mx-auto pe-5 mb-5'
              onClick={() => {
                handleAddNewSections()
              }}
            >
              <img src={addWhite} height={18} width={18} className='me-5' /> Add Section
            </button>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}

export default NoteDetailsCard
