import React, {useEffect, useState} from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css' // Import Quill styles

interface ButtonProps {
  value?: any
  onChange?: any
  autoSaveText?: any
  refresh?: any
  placeholder?: any
  from?: any
  isReadOnly?:boolean
}

const TextEditor = ({value, onChange, autoSaveText, refresh, placeholder, from, isReadOnly=false}: ButtonProps) => {
  const [content, setContent] = useState<string>(value ? value : '')

  const handleChange = (text: string) => {
    setContent(text)
    onChange(text)
  }

  const modules = {
    toolbar: from == 'annoucements' ? 
    {
      container: [
        [{header: [4, 5, 6, false]}],
        ['bold', 'italic', 'underline', 'strike'],
        ['blockquote', 'code-block'],
        [{list: 'ordered'}, {list: 'bullet'}],
        [{indent: '-1'}, {indent: '+1'}],
        [{color: []}, {background: []}],
        //  ['link', 'image'],
        // ['image'],
        [{align: []}],
        // [{ 'size': ['small', false, 'large', 'huge'] }],
        ['clean'],
      ],
    }
    : from == 'email-template' ?
    {
      container: [
        [{ 'font': [] }],
        [{header: [1,2,3,4, 5, 6, false]}],
        ['bold', 'italic', 'underline', 'strike'],
        ['blockquote', 'code-block'],
        ['link'],
        [{list: 'ordered'}, {list: 'bullet'}],
        [{indent: '-1'}, {indent: '+1'}],
        [{color: []}, {background: []}],
        //  ['link', 'image'],
        // ['image'],
        [{align: []}],
        // [{ 'size': ['small', false, 'large', 'huge'] }],
        // ['clean'],
        [{ 'script': 'sub'}, { 'script': 'super' }], 
      ],
    }
    :
    {
      container: [
        [{header: [4, 5, 6, false]}],
        ['bold', 'italic', 'underline', 'strike'],
        ['blockquote', 'code-block'],
        [{list: 'ordered'}, {list: 'bullet'}],
        [{indent: '-1'}, {indent: '+1'}],
        [{color: []}, {background: []}],
        //  ['link', 'image'],
        ['image'],
        [{align: []}],
        // [{ 'size': ['small', false, 'large', 'huge'] }],
        ['clean'],
      ],
    },
  }

  useEffect(() => {
    setContent(value)
  }, [refresh])

  return (
    <div>
      <ReactQuill
        theme='snow' // You can change the theme here
        modules={modules}
        value={content}
        onChange={handleChange}
        placeholder={placeholder}
        onBlur={autoSaveText}
        readOnly={isReadOnly}
      />
    </div>
  )
}

export default TextEditor
