import React, {useEffect, useState, useRef} from 'react'
import {Bucket} from '../../../../apiCommon/helpers/API/ApiData'
import {ErrorToast} from '../../../../apiCommon/helpers/Toast'
import trashImg from '../../../../img/trash.png'
import AddAttachments from './AddAttachments'
import downloadIcon from '../../../../img/download.png'
import greenPen from '../../../../img/pen.png'
import invoiceGreen from '../../../../img/invoice-green.png'
import {getFileIcon} from '../../../../Utilities/utils'
import { downloadFile } from '../../../../Utilities/utils'

interface OtherAttachmentsProps {
  incmData?: any
  updateParent?: any
  from?: any
  editMode?:boolean
}

const OtherAttachments: React.FC<OtherAttachmentsProps> = ({incmData, updateParent, from, editMode}) => {
  const userData: any = localStorage.getItem('userData')
  const user = JSON.parse(userData)

  const roleAndPermissions: any = localStorage.getItem('roleAndPermissions')
  const role = JSON.parse(roleAndPermissions)

  const [showAttachAdd, setShowAttachAdd] = React.useState<any>(false)

  let [incomeData, setIncomeData] = useState<any>(incmData)

  const [mode, setMode] = useState<any>('add')
  const [selectedInd, setSelectedInd] = useState<any>(-1)
  const [selectedRecord, setSelectedRecord] = useState<any>({})

  useEffect(() => {
    setIncomeData(incmData)
  }, [incmData])

  // light-box setting
  const handleImageClick = () => {
    const groupName = `group_attachments_others`

    incomeData.attachments?.map((receipt: any, idx: any) => {
      const imgeNext = document.getElementById(`clickedAttachments${idx}`)
      if (imgeNext) {
        imgeNext.setAttribute('data-src', `${Bucket}${receipt?.fileURL}`)
        imgeNext.setAttribute('data-fancybox', groupName)

        // imgeNext.setAttribute('src', `${Bucket}${receipt?.URL}`)
      }

      // Trigger the fancybox on the clicked image
      // if (imgeNext) {
      //   imgeNext.click()
      // }
    })
  }

  return (
    <>
      <div className={'card card-flush py-5 mt-7  px-5 mb-5 me-3 min-hg-300 h-100'}>
        <div className={`d-flex  align-items-center ${(from == 'raise-receipt' || from == 'view-receipt') ? 'mb-0' : 'mb-5'} `}>
          <h2 className='' style={{fontWeight: '700'}}>
            {'Other Attachments'}
          </h2>
          {from != 'view-receipt' &&
          <button
            type='button'
            className='btn btn-sm fw-bold px-3 green-hollow-btn ms-auto btn-fit-content '
            onClick={() => {
              setMode('add')
              setSelectedRecord({
                fileName: '',
                fileURL: '',
              })
              setTimeout(() => {
                setShowAttachAdd(true)
              }, 300)
            }}
          >
            <img src={invoiceGreen} height={18} width={14} className='me-4' /> Add Attachment
          </button>
          }
        </div>

        {(from == 'raise-receipt' || from == 'view-receipt') && editMode &&
          <div className='d-flex align-items-center'>
            <h4 className='pt-2 mb-0 head-text'>
            Attach any other document related to the payment record:
          </h4>
        </div>

        }

        <div className='table-responsive mt-4' style={{maxHeight: '300px', overflow: 'auto'}}>
          <table className='table align-middle  fs-6 gy-5' id='kt_ecommerce_sales_table'>
            {/* <thead>
              <tr className='text-start text-gray-400 fw-bold fs-7 gs-0'>
                <th className='text-start min-w-100px'></th>
                <th className='text-start min-w-150px'></th>
              </tr>
            </thead> */}

            <tbody className='fw-semibold text-gray-600'>
              {incomeData.attachments?.map((attachmnt: any, index: any) => {
                return (
                  <tr className=''>
                    <td className='text-start ps-0'>
                      <div className='d-flex align-items-center'>
                        <div className='bullet-item me-3 mb-0 ms-1'></div>
                        <p className='mb-0' 
                        >{attachmnt?.fileName}</p>
                      </div>
                    </td>
                    <td className='text-start min-w-100px'>
                      <div className='d-flex align-items-center cursor-pointer'>
                        <img
                          src={getFileIcon(attachmnt?.fileURL)}
                          height={30}
                          width={30}
                          className='ms-3'
                          id={`clickedAttachments${index}`}
                          onClick={() => {
                            handleImageClick()
                          }}
                          // data-fancybox
                          data-src={`${Bucket}${attachmnt?.fileURL}`}
                        />
                        {/* <a href={`${Bucket}${attachmnt?.URL}`} target='_blank'> */}
                        <img
                          src={downloadIcon}
                          height={18}
                          width={18}
                          className='ms-5 cursor-pointer'
                          onClick={() => {
                            downloadFile(`${Bucket}${attachmnt?.fileURL}`, attachmnt?.fileName)
                          }}
                        />
                        {/* </a> */}
                        {from != 'view-receipt' &&
                        <>
                        <img
                          src={greenPen}
                          height={18}
                          width={18}
                          className='ms-5 cursor-pointer'
                          onClick={() => {
                            setMode('edit')
                            setSelectedInd(index)
                            setSelectedRecord(attachmnt)
                            setShowAttachAdd(true)
                          }}
                        />
                        <img
                          src={trashImg}
                          height={18}
                          width={18}
                          className='mx-5 cursor-pointer'
                          onClick={() => {
                            let values = {...incomeData}
                            values.attachments.splice(index, 1)
                            setIncomeData(values)
                            updateParent(values.attachments)
                          }}
                        />
                        </>
                         }
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>

      {showAttachAdd && mode && (
        <AddAttachments
          show={showAttachAdd}
          handleClose={() => setShowAttachAdd(false)}
          mode={mode}
          updateData={(data: any) => {
            let values = {...incomeData}
            if (mode == 'add') values.attachments.push(data)
            else values.attachments[selectedInd] = data
            setIncomeData(values)

            updateParent(values.attachments)
            setShowAttachAdd(false)
          }}
          selectedItem={selectedRecord}
        />
      )}
    </>
  )
}

export default OtherAttachments
