import React, {useEffect} from 'react'
import {useState} from 'react'
import {Row, Col, OverlayTrigger, Tooltip} from 'react-bootstrap'
import whiteTick from '../../../../../img/onboard-tick.png'
import ColorPicker from 'react-pick-color'
import colorPickImg from '../../../../../img/colr-picker.png'
import {MultiSelect} from 'react-multi-select-component'
import alignLeftImg from '../../../../../img/align-left.png'
import alignCenterImg from '../../../../../img/align-center.png'
import alignRightImg from '../../../../../img/align-right.png'
import justifyContentImg from '../../../../../img/justify-content.png'
import Box from '@mui/material/Box'
import Slider from '@mui/material/Slider'
import Typography from '@mui/material/Typography'
import {styled} from '@mui/material/styles'
import './../styles.scss'
import SingleFileLoader from '../../../General/SingleFileLoader'
import {ApiPost, Bucket} from '../../../../../apiCommon/helpers/API/ApiData'
import {ErrorToast} from '../../../../../apiCommon/helpers/Toast'
import {convertSvgToPng} from '../../../../../Utilities/utils'

interface SectionEditorProps {
  selectedSectionStyling: any
  setSelectedSectionStyling: (values: any) => void
  selectedSectInd?: any
}

const SectionEditor: React.FC<SectionEditorProps> = ({
  selectedSectionStyling,
  setSelectedSectionStyling,
  selectedSectInd,
}) => {
  const PrettoSlider = styled(Slider)({
    color: '#146c6a',
    height: 8,
    '& .MuiSlider-root': {
      padding: '5px 0px',
    },
    '& .MuiSlider-rail': {
      height: '5px',
    },
    '& .MuiSlider-track': {
      border: 'none',
      height: '5px',
    },
    '& .MuiSlider-thumb': {
      height: 24,
      width: 24,
      backgroundColor: '#fff',
      border: '2px solid currentColor',
      '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
        boxShadow: 'inherit',
      },
      '&::before': {
        display: 'none',
      },
    },
    '& .MuiSlider-valueLabel': {
      lineHeight: 1.2,
      fontSize: 12,
      background: 'unset',
      padding: 0,
      width: 32,
      height: 32,
      borderRadius: '50% 50% 50% 0',
      backgroundColor: '#146c6a',
      transformOrigin: 'bottom left',
      transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
      '&::before': {display: 'none'},
      '&.MuiSlider-valueLabelOpen': {
        transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
      },
      '& > *': {
        transform: 'rotate(45deg)',
      },
    },
  })

  const [isColorPickerVisible, setIsColorPickerVisible] = useState(false)
  const [isColorPickerForButtonVisible, setIsColorPickerForButtonVisible] = useState(false)
  const [isColorPickerForButtonTextColorVisible, setIsColorPickerForButtonTextColorVisible] =
    useState(false)
  const [isColorPickerForBorder, setIsColorPickerForBorder] = useState(false)
  const [isColorPickerForShape, setIsColorPickerForShape] = useState(false)

  const [linkLists, setLinkLists] = useState<any>([])
  const [borderTypeLists, setBorderTypeLists] = useState<any>([
    {
      label: 'solid',
      value: 'solid',
    },
    {
      label: 'dotted',
      value: 'dotted',
    },
    {
      label: 'dashed',
      value: 'dashed',
    },
  ])

  const [showLoader, setShowLoader] = useState<boolean>(false) // State to control the visibility of the modal loader

  //
  const handleChange = async (e: any, ind: any) => {
    e.preventDefault()
    setShowLoader(true) // Show the modal loader when file uploading starts

    const values: any = {...selectedSectionStyling}

    if (e.target.files && e.target.files[0]) {
      for (let i = 0; i < e.target.files.length; i++) {
        // file upload logic here

        let userData: any = localStorage.getItem('userData')
        const data = JSON.parse(userData)

        var formdata = new FormData()
        formdata.append('image', e.target.files[i])

        var videoFormdata = new FormData()
        videoFormdata.append('video', e.target.files[i])

        let name: any = e.target.files[i]?.name

        try {
          const res = await ApiPost(
            `upload/image/params?fileType=maintenance_service_job&databaseId=${data._id}&fileName=${name}`,
            formdata
          )

          const sectionElement: any = document.getElementById(selectedSectionStyling?.id)

          if (sectionElement) {
            // Select the image element inside the section
            const img: NodeListOf<HTMLImageElement> =
              sectionElement.querySelectorAll('img:not(.svg-img)')

            if (img) {
              //
              if (values?.content?.image?.[ind]?.display == 1) {
                Array.from(img)[ind].src = Bucket + res?.data?.data?.image
                Array.from(img)[ind].style.height = selectedSectionStyling?.content?.height
                Array.from(img)[ind].style.width = selectedSectionStyling?.content?.width
              }

              // Update the content HTML with the modified outerHTML of the section
              values.content.html = sectionElement.innerHTML

              // Update the selected section styling state
              setSelectedSectionStyling(values)
            }
          }
        } catch (error: any) {
          console.error('Error while uploading image:', error)
          ErrorToast(error?.message)
        }
      }

      e.target.value = null
    }

    setShowLoader(false) // Hide the modal loader when file uploading finishes
  }

  const [selectedSectionImages, setSelectedSectionImages] = useState<any>([])

  useEffect(() => {
    const sectionElement: any = document.getElementById(selectedSectionStyling?.id)

    if (sectionElement) {
      // Select the image element inside the section
      const imgs: any = sectionElement.querySelectorAll('img:not(.svg-img)')
      setSelectedSectionImages(imgs)
    }
  }, [selectedSectionStyling])

  //
  const uploadPNG = async (
    imgSrc: any,
    section: any,
    pngImg: any,
    color: any,
    sectionElement: any
  ) => {
    var formdata = new FormData()
    formdata.append('image', imgSrc)

    let userData: any = localStorage.getItem('userData')
    const data = JSON.parse(userData)

    let name: any = Date.now()

    await ApiPost(
      `upload/compress_image/params?fileType=email_template&databaseId=${data._id}&fileName=${name}`,
      formdata
    )
      .then((res) => {
        section.content.shapeColor = color.hex
        pngImg.src = `${Bucket}${res?.data?.data?.image}`

        section.content.html = sectionElement?.innerHTML

        section.content.shapeColor = color.hex
        setSelectedSectionStyling(section)

        return res?.data?.data?.image
      })

      .catch((err) => {})
  }

  return (
    <div className='section-editor'>
      <h2 className='my-3 mx-5'>
        <b>Section {selectedSectInd ? Number(selectedSectInd) + 1 : ''} </b>
      </h2>
      <div className='mx-10'>
        {/* Section Background Color */}
        <h3 className='my-2 mt-4' style={{color: 'black'}}>
          <label className='fnt-600'> Background Color</label>
        </h3>

        <div className='color-picker-box d-flex align-items-center'>
          <div
            className='color-picker-selected-circle'
            style={{
              backgroundColor: `${
                selectedSectionStyling?.content?.backgroundColor
                  ? selectedSectionStyling?.content?.backgroundColor
                  : 'black'
              }`,
            }}
          ></div>
          <p className='fs-4 ms-2 mb-0'>
            {selectedSectionStyling?.content?.backgroundColor
              ? selectedSectionStyling?.content?.backgroundColor
              : '#0000'}
          </p>

          <img
            src={colorPickImg}
            height={25}
            width={25}
            className='ms-auto cursor-pointer'
            onClick={() => {
              setIsColorPickerVisible(!isColorPickerVisible)
              setIsColorPickerForButtonTextColorVisible(false)
              setIsColorPickerForButtonVisible(false)
              setIsColorPickerForBorder(false)
              setIsColorPickerForShape(false)
            }}
          />
        </div>

        <div style={{height: '0px'}}>
          <div className='color-picker-div'>
            {isColorPickerVisible && (
              <ColorPicker
                color={selectedSectionStyling?.content?.backgroundColor}
                onChange={(color: any) => {
                  let values = {...selectedSectionStyling}
                  if (selectedSectionStyling != null) values.content.backgroundColor = color.hex
                  setSelectedSectionStyling(values)
                }}
                presets={['#6254ff', '#146c6a', '#00b9a9', '#D7197D', '#3573CB', '#E4E4E4']}
              />
            )}
          </div>
        </div>

        {selectedSectionStyling?.content?.imgSrc == 'svg' && (
          <>
            {/* Section Background Color */}
            <h3 className='my-2 mt-8' style={{color: 'black'}}>
              <label className='fnt-600'> Shape Color</label>
            </h3>

            <div className='color-picker-box d-flex align-items-center'>
              <div
                className='color-picker-selected-circle'
                style={{
                  backgroundColor: `${
                    selectedSectionStyling?.content?.shapeColor
                      ? selectedSectionStyling?.content?.shapeColor
                      : 'black'
                  }`,
                }}
              ></div>
              <p className='fs-4 ms-2 mb-0'>
                {selectedSectionStyling?.content?.shapeColor
                  ? selectedSectionStyling?.content?.shapeColor
                  : '#0000'}
              </p>

              <img
                src={colorPickImg}
                height={25}
                width={25}
                className='ms-auto cursor-pointer'
                onClick={() => {
                  setIsColorPickerForShape(!isColorPickerForShape)
                  setIsColorPickerVisible(false)
                  setIsColorPickerForButtonTextColorVisible(false)
                  setIsColorPickerForButtonVisible(false)
                  setIsColorPickerForBorder(false)
                }}
              />
            </div>

            <div style={{height: '0px'}}>
              <div className='color-picker-div'>
                {isColorPickerForShape && (
                  <ColorPicker
                    color={selectedSectionStyling?.content?.shapeColor}
                    onChange={async (color: any) => {
                      let values = {...selectedSectionStyling}
                      const sectionElement: any = document.getElementById(
                        selectedSectionStyling?.id
                      )

                      const parser = new DOMParser()
                      const svgDocument = parser.parseFromString(
                        selectedSectionStyling?.content?.svgSrc,
                        'image/svg+xml'
                      )
                      const svgImg: any = sectionElement.querySelector('svg')

                      let pngImg: any = sectionElement.querySelector('img')

                      if (svgImg) {
                        let pathElement = svgImg.querySelector('path')
                        if (pathElement) {
                          pathElement.setAttribute('fill', color?.hex)
                        }

                        const htmlContent = svgImg
                        // let pngReturned = convertSvgToPng(htmlContent)
                        // console.log(pngReturned)

                        try {
                          const pngBlob = await convertSvgToPng(htmlContent)
                          // console.log(pngBlob)

                          // Now upload the PNG if the conversion was successful
                          if (pngBlob) {
                            const pngUploaded = await uploadPNG(
                              pngBlob,
                              values,
                              pngImg,
                              color,
                              sectionElement
                            )
                            // console.log(pngUploaded)
                          }
                        } catch (error) {
                          console.error('Error during PNG conversion or upload:', error)
                        }

                        // let pngUploaded = uploadPNG(pngReturned)
                        // console.log(pngUploaded)
                        // values.content.shapeColor = color.hex
                      }

                      // values.content.html = sectionElement?.outerHTML

                      // if (selectedSectionStyling != null) values.content.shapeColor = color.hex
                      // setSelectedSectionStyling(values)
                    }}
                    presets={['#6254ff', '#146c6a', '#00b9a9', '#D7197D', '#3573CB', '#E4E4E4']}
                  />
                )}
              </div>
            </div>

            {/* Section Background Color */}
            <h3 className='my-2 mt-8' style={{color: 'black'}}>
              <label className='fnt-600'> Shape Height</label>
            </h3>

            <div className='d-flex align-items-center'>
              <div>
                <input
                  type='number'
                  className='form-control form-control-solid input-style tenancy-control bg-white'
                  name='expenseName'
                  value={Number(selectedSectionStyling?.content?.shapeHeight)}
                  style={{height: '35px', width: '100px'}}
                  onChange={(e: any) => {
                    let values = {...selectedSectionStyling}

                    const sectionElement: any = document.getElementById(selectedSectionStyling?.id)

                    if (sectionElement) {
                      let tag: NodeListOf<HTMLImageElement> | NodeListOf<HTMLButtonElement> | null =
                        null

                      if (
                        selectedSectionStyling?.type == 'image' ||
                        selectedSectionStyling?.type == 'textImage'
                      ) {
                        tag = sectionElement.querySelectorAll('img.svg-img')
                      } else if (selectedSectionStyling?.type == 'button') {
                        tag = sectionElement.querySelectorAll('button')
                      }

                      if (tag) {
                        //
                        tag.forEach((itm, ind) => {
                          itm.style.height = e.target.value + 'px'
                          values.content.shapeHeight = e.target.value
                        })
                      }

                      // Update the content HTML with the modified outerHTML of the section
                      values.content.html = sectionElement.innerHTML
                      setSelectedSectionStyling(values)
                    }

                    //  if (selectedSectionStyling != null){
                    //    values.content.height = e.target.value
                    //    values.content.sizeType = 'px'
                    //  }
                    // setSelectedSectionStyling(values)
                  }}
                  autoComplete='off'
                  placeholder='Height...'
                />
              </div>
              <h3 className='my-2 mx-3 mt-0' style={{color: 'black'}}>
                <label className='labl-gry fnt-500'> px</label>
              </h3>
              {/* <h4 className='my-2 mx-3 mt-0' style={{color: 'black'}}>
                <label className='labl-gry fnt-500'> H</label>
              </h4> */}
            </div>
          </>
        )}

        {selectedSectionStyling?.type == 'button' && (
          <>
            {/* Button Color */}
            <div className='d-flex align-items-color'>
              <div className='w-50'>
                <h3 className='my-2 mt-8' style={{color: 'black'}}>
                  <label className='fnt-600'> Button Color</label>
                </h3>
                <div className='color-picker-box d-flex align-items-center'>
                  <div
                    className='color-picker-selected-circle'
                    style={{
                      backgroundColor: `${
                        selectedSectionStyling?.content.buttonColor
                          ? selectedSectionStyling?.content.buttonColor
                          : 'black'
                      }`,
                    }}
                  ></div>
                  <p className='fs-4 ms-2 mb-0'>
                    {selectedSectionStyling?.content.buttonColor
                      ? selectedSectionStyling?.content.buttonColor
                      : '#0000'}
                  </p>

                  <img
                    src={colorPickImg}
                    height={25}
                    width={25}
                    className='ms-auto cursor-pointer'
                    onClick={() => {
                      setIsColorPickerForButtonVisible(!isColorPickerForButtonVisible)
                      setIsColorPickerVisible(false)
                      setIsColorPickerForButtonTextColorVisible(false)
                      setIsColorPickerForBorder(false)
                      setIsColorPickerForShape(false)
                    }}
                  />
                </div>

                <div style={{height: '0px'}}>
                  <div className='color-picker-div'>
                    {isColorPickerForButtonVisible && (
                      <ColorPicker
                        color={selectedSectionStyling?.content?.buttonColor}
                        onChange={(color: any) => {
                          const values = {...selectedSectionStyling}
                          const sectionElement: any = document.getElementById(
                            selectedSectionStyling?.id
                          )

                          if (sectionElement) {
                            const btn: NodeListOf<HTMLButtonElement> =
                              sectionElement.querySelectorAll('button')
                            if (btn) {
                              //
                              btn?.forEach((itm, ind) => {
                                itm.style.backgroundColor = color.hex
                                values.content.buttonColor = color.hex
                              })
                            }
                          }
                          setSelectedSectionStyling(values)
                        }}
                        presets={['#6254ff', '#146c6a', '#00b9a9', '#D7197D', '#3573CB', '#E4E4E4']}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className='d-flex align-items-color'>
              <div className='w-50'>
                {/* Button Text */}
                <h3 className='my-2 mt-8' style={{color: 'black'}}>
                  <label className='fnt-600'> Button Text</label>
                </h3>
                <div>
                  <input
                    //   {...register('expenseName', {required: true})}
                    type='text'
                    className='form-control form-control-solid  bg-white input-style btn-clr-input'
                    name='expenseName'
                    value={selectedSectionStyling?.content?.buttonName}
                    style={{height: '35px'}}
                    placeholder={'Button Text..'}
                    onChange={(e: any) => {
                      const values = {...selectedSectionStyling}
                      const sectionElement: any = document.getElementById(
                        selectedSectionStyling?.id
                      )

                      if (sectionElement) {
                        const btn: NodeListOf<HTMLButtonElement> =
                          sectionElement.querySelectorAll('button')
                        if (btn) {
                          //
                          btn?.forEach((itm, ind) => {
                            itm.innerHTML = e.target.value
                            values.content.buttonName = e.target.value
                          })
                        }
                      }
                      setSelectedSectionStyling(values)
                    }}
                    autoComplete='off'
                  />
                </div>
              </div>
              <div className='ms-auto'>
                {/* Button Text Color */}
                <h3 className='my-2 mt-8' style={{color: 'black'}}>
                  <label className='fnt-600'> Button Text Color</label>
                </h3>
                <div className='color-picker-box d-flex align-items-center '>
                  <div
                    className='color-picker-selected-circle'
                    style={{
                      backgroundColor: `${
                        selectedSectionStyling?.content?.buttonTextColor
                          ? selectedSectionStyling?.content?.buttonTextColor
                          : 'black'
                      }`,
                    }}
                  ></div>
                  <p className='fs-4 ms-2 mb-0'>
                    {selectedSectionStyling?.content?.buttonTextColor
                      ? selectedSectionStyling?.content?.buttonTextColor
                      : '#0000'}
                  </p>

                  <img
                    src={colorPickImg}
                    height={25}
                    width={25}
                    className='ms-auto cursor-pointer'
                    onClick={() => {
                      setIsColorPickerForButtonTextColorVisible(
                        !isColorPickerForButtonTextColorVisible
                      )
                      setIsColorPickerForButtonVisible(false)
                      setIsColorPickerVisible(false)
                      setIsColorPickerForBorder(false)
                      setIsColorPickerForShape(false)
                    }}
                  />
                </div>

                <div style={{height: '0px'}}>
                  <div className='color-picker-div'>
                    {isColorPickerForButtonTextColorVisible && (
                      <ColorPicker
                        color={selectedSectionStyling?.content?.buttonTextColor}
                        onChange={(color: any) => {
                          const values = {...selectedSectionStyling}
                          const sectionElement: any = document.getElementById(
                            selectedSectionStyling?.id
                          )

                          if (sectionElement) {
                            const btn: NodeListOf<HTMLButtonElement> =
                              sectionElement.querySelectorAll('button')
                            if (btn) {
                              //
                              btn?.forEach((itm, ind) => {
                                itm.style.color = color.hex
                                values.content.buttonTextColor = color.hex
                              })
                            }
                          }
                          setSelectedSectionStyling(values)
                        }}
                        presets={['#6254ff', '#146c6a', '#00b9a9', '#D7197D', '#3573CB', '#E4E4E4']}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {/* Padding */}
        {/* {(selectedSectionStyling?.type == 'text' ||
          selectedSectionStyling?.type == 'textImage') && ( */}
        <>
          <h3 className='my-2 mt-8'>
            <label className='fnt-600'>
              {' '}
              {selectedSectionStyling?.type == 'image' || selectedSectionStyling?.type == 'button'
                ? 'Margin'
                : 'Padding'}
            </label>
          </h3>
          <div className='d-flex alig-items-center'>
            {/* Top */}
            <div>
              <h4 className='my-2 mt-3'>
                <label className='fnt-500'> Top </label>
              </h4>
              <div className='d-flex disable-input-stepper'>
                <button
                  onClick={() => {
                    let values = {...selectedSectionStyling}
                    if (selectedSectionStyling != null)
                      values.content.paddingTop = Number(values.content.paddingTop) - 1
                    setSelectedSectionStyling(values)
                  }}
                  className='stepper-left'
                >
                  -
                </button>
                <input
                  type='number'
                  className='form-control form-control-solid stepper-input-style bg-white'
                  name='expenseName'
                  value={selectedSectionStyling?.content?.paddingTop}
                  style={{
                    height: '35px',
                    width: '50px',
                    borderRadius: '0px',
                    textAlign: 'center', // Center-align text
                    MozAppearance: 'textfield', // For Firefox
                  }}
                  onChange={(e: any) => {
                    let values = {...selectedSectionStyling}
                    if (selectedSectionStyling != null) values.content.paddingTop = e.target.value
                    setSelectedSectionStyling(values)
                  }}
                  autoComplete='off'
                />
                <button
                  className='stepper-right'
                  onClick={() => {
                    let values = {...selectedSectionStyling}
                    if (selectedSectionStyling != null)
                      values.content.paddingTop = Number(values.content.paddingTop) + 1
                    setSelectedSectionStyling(values)
                  }}
                >
                  +
                </button>
              </div>
            </div>

            {/* Right */}
            <div className='ms-auto'>
              <h4 className='my-2 mt-3'>
                <label className='fnt-500'> Right </label>
              </h4>
              <div className='d-flex disable-input-stepper'>
                <button
                  onClick={() => {
                    let values = {...selectedSectionStyling}
                    if (selectedSectionStyling != null)
                      values.content.paddingRight = Number(values.content.paddingRight) - 1
                    setSelectedSectionStyling(values)
                  }}
                  className='stepper-left'
                >
                  -
                </button>
                <input
                  type='number'
                  className='form-control form-control-solid stepper-input-style bg-white'
                  name='expenseName'
                  value={selectedSectionStyling?.content?.paddingRight}
                  style={{
                    height: '35px',
                    width: '50px',
                    borderRadius: '0px',
                    textAlign: 'center', // Center-align text
                    MozAppearance: 'textfield', // For Firefox
                  }}
                  onChange={(e: any) => {
                    let values = {...selectedSectionStyling}
                    if (selectedSectionStyling != null) values.content.paddingRight = e.target.value
                    setSelectedSectionStyling(values)
                  }}
                  autoComplete='off'
                />
                <button
                  className='stepper-right'
                  onClick={() => {
                    let values = {...selectedSectionStyling}
                    if (selectedSectionStyling != null)
                      values.content.paddingRight = Number(values.content.paddingRight) + 1
                    setSelectedSectionStyling(values)
                  }}
                >
                  +
                </button>
              </div>
            </div>
          </div>

          <div className='d-flex alig-items-center'>
            {/* Bottom */}
            <div>
              <h4 className='my-2 mt-3'>
                <label className='fnt-500'> Bottom </label>
              </h4>
              <div className='d-flex disable-input-stepper'>
                <button
                  onClick={() => {
                    let values = {...selectedSectionStyling}
                    if (selectedSectionStyling != null)
                      values.content.paddingBottom = Number(values.content.paddingBottom) - 1
                    setSelectedSectionStyling(values)
                  }}
                  className='stepper-left'
                >
                  -
                </button>
                <input
                  type='number'
                  className='form-control form-control-solid stepper-input-style bg-white'
                  name='expenseName'
                  value={selectedSectionStyling?.content?.paddingBottom}
                  style={{
                    height: '35px',
                    width: '50px',
                    borderRadius: '0px',
                    textAlign: 'center', // Center-align text
                    MozAppearance: 'textfield', // For Firefox
                  }}
                  onChange={(e: any) => {
                    let values = {...selectedSectionStyling}
                    if (selectedSectionStyling != null)
                      values.content.paddingBottom = e.target.value
                    setSelectedSectionStyling(values)
                  }}
                  autoComplete='off'
                />
                <button
                  className='stepper-right'
                  onClick={() => {
                    let values = {...selectedSectionStyling}
                    if (selectedSectionStyling != null)
                      values.content.paddingBottom = Number(values.content.paddingBottom) + 1
                    setSelectedSectionStyling(values)
                  }}
                >
                  +
                </button>
              </div>
            </div>

            {/* Left */}
            <div className='ms-auto'>
              <h4 className='my-2 mt-3'>
                <label className='fnt-500'> Left </label>
              </h4>
              <div className='d-flex disable-input-stepper'>
                <button
                  onClick={() => {
                    let values = {...selectedSectionStyling}
                    if (selectedSectionStyling != null)
                      values.content.paddingLeft = Number(values.content.paddingLeft) - 1
                    setSelectedSectionStyling(values)
                  }}
                  className='stepper-left'
                >
                  -
                </button>
                <input
                  type='number'
                  className='form-control form-control-solid stepper-input-style bg-white'
                  name='expenseName'
                  value={selectedSectionStyling?.content?.paddingLeft}
                  style={{
                    height: '35px',
                    width: '50px',
                    borderRadius: '0px',
                    textAlign: 'center', // Center-align text
                    MozAppearance: 'textfield', // For Firefox
                  }}
                  onChange={(e: any) => {
                    let values = {...selectedSectionStyling}
                    if (selectedSectionStyling != null) values.content.paddingLeft = e.target.value
                    setSelectedSectionStyling(values)
                  }}
                  autoComplete='off'
                />
                <button
                  className='stepper-right'
                  onClick={() => {
                    let values = {...selectedSectionStyling}
                    if (selectedSectionStyling != null)
                      values.content.paddingLeft = Number(values.content.paddingLeft) + 1
                    setSelectedSectionStyling(values)
                  }}
                >
                  +
                </button>
              </div>
            </div>
          </div>
        </>
        {/* )} */}

        {/* Margin */}
        {selectedSectionStyling?.type != 'image' && selectedSectionStyling?.type != 'button' && (
          <>
            <h3 className='my-2 mt-8'>
              <label className='fnt-600'> Margin</label>
            </h3>
            <div className='d-flex alig-items-center'>
              {/* Top */}
              <div>
                <h4 className='my-2 mt-3'>
                  <label className='fnt-500'> Top </label>
                </h4>
                <div className='d-flex disable-input-stepper'>
                  <button
                    onClick={() => {
                      let values = {...selectedSectionStyling}
                      if (selectedSectionStyling != null)
                        values.content.marginTop = Number(values.content.marginTop) - 1
                      setSelectedSectionStyling(values)
                    }}
                    className='stepper-left'
                  >
                    -
                  </button>
                  <input
                    type='number'
                    className='form-control form-control-solid stepper-input-style bg-white'
                    name='expenseName'
                    value={selectedSectionStyling?.content?.marginTop}
                    style={{
                      height: '35px',
                      width: '50px',
                      borderRadius: '0px',
                      textAlign: 'center', // Center-align text
                      MozAppearance: 'textfield', // For Firefox
                    }}
                    onChange={(e: any) => {
                      let values = {...selectedSectionStyling}
                      if (selectedSectionStyling != null) values.content.marginTop = e.target.value
                      setSelectedSectionStyling(values)
                    }}
                    autoComplete='off'
                  />
                  <button
                    className='stepper-right'
                    onClick={() => {
                      let values = {...selectedSectionStyling}
                      if (selectedSectionStyling != null)
                        values.content.marginTop = Number(values.content.marginTop) + 1
                      setSelectedSectionStyling(values)
                    }}
                  >
                    +
                  </button>
                </div>
              </div>

              {/* Right */}
              <div className='ms-auto'>
                <h4 className='my-2 mt-3'>
                  <label className='fnt-500'> Right </label>
                </h4>
                <div className='d-flex disable-input-stepper'>
                  <button
                    onClick={() => {
                      let values = {...selectedSectionStyling}
                      if (selectedSectionStyling != null)
                        values.content.marginRight = Number(values.content.marginRight) - 1
                      setSelectedSectionStyling(values)
                    }}
                    className='stepper-left'
                  >
                    -
                  </button>
                  <input
                    type='number'
                    className='form-control form-control-solid stepper-input-style bg-white'
                    name='expenseName'
                    value={selectedSectionStyling?.content?.marginRight}
                    style={{
                      height: '35px',
                      width: '50px',
                      borderRadius: '0px',
                      textAlign: 'center', // Center-align text
                      MozAppearance: 'textfield', // For Firefox
                    }}
                    onChange={(e: any) => {
                      let values = {...selectedSectionStyling}
                      if (selectedSectionStyling != null)
                        values.content.marginRight = e.target.value
                      setSelectedSectionStyling(values)
                    }}
                    autoComplete='off'
                  />
                  <button
                    className='stepper-right'
                    onClick={() => {
                      let values = {...selectedSectionStyling}
                      if (selectedSectionStyling != null)
                        values.content.marginRight = Number(values.content.marginRight) + 1
                      setSelectedSectionStyling(values)
                    }}
                  >
                    +
                  </button>
                </div>
              </div>
            </div>

            <div className='d-flex alig-items-center'>
              {/* Bottom */}
              <div>
                <h4 className='my-2 mt-3'>
                  <label className='fnt-500'> Bottom </label>
                </h4>
                <div className='d-flex disable-input-stepper'>
                  <button
                    onClick={() => {
                      let values = {...selectedSectionStyling}
                      if (selectedSectionStyling != null)
                        values.content.marginBottom = Number(values.content.marginBottom) - 1
                      setSelectedSectionStyling(values)
                    }}
                    className='stepper-left'
                  >
                    -
                  </button>
                  <input
                    type='number'
                    className='form-control form-control-solid stepper-input-style bg-white'
                    name='expenseName'
                    value={selectedSectionStyling?.content?.marginBottom}
                    style={{
                      height: '35px',
                      width: '50px',
                      borderRadius: '0px',
                      textAlign: 'center', // Center-align text
                      MozAppearance: 'textfield', // For Firefox
                    }}
                    onChange={(e: any) => {
                      let values = {...selectedSectionStyling}
                      if (selectedSectionStyling != null)
                        values.content.marginBottom = e.target.value
                      setSelectedSectionStyling(values)
                    }}
                    autoComplete='off'
                  />
                  <button
                    className='stepper-right'
                    onClick={() => {
                      let values = {...selectedSectionStyling}
                      if (selectedSectionStyling != null)
                        values.content.marginBottom = Number(values.content.marginBottom) + 1
                      setSelectedSectionStyling(values)
                    }}
                  >
                    +
                  </button>
                </div>
              </div>

              {/* Left */}
              <div className='ms-auto'>
                <h4 className='my-2 mt-3'>
                  <label className='fnt-500'> Left </label>
                </h4>
                <div className='d-flex disable-input-stepper'>
                  <button
                    onClick={() => {
                      let values = {...selectedSectionStyling}
                      if (selectedSectionStyling != null)
                        values.content.marginLeft = Number(values.content.marginLeft) - 1
                      setSelectedSectionStyling(values)
                    }}
                    className='stepper-left'
                  >
                    -
                  </button>
                  <input
                    type='number'
                    className='form-control form-control-solid stepper-input-style bg-white'
                    name='expenseName'
                    value={selectedSectionStyling?.content?.marginLeft}
                    style={{
                      height: '35px',
                      width: '50px',
                      borderRadius: '0px',
                      textAlign: 'center', // Center-align text
                      MozAppearance: 'textfield', // For Firefox
                    }}
                    onChange={(e: any) => {
                      let values = {...selectedSectionStyling}
                      if (selectedSectionStyling != null) values.content.marginLeft = e.target.value
                      setSelectedSectionStyling(values)
                    }}
                    autoComplete='off'
                  />
                  <button
                    className='stepper-right'
                    onClick={() => {
                      let values = {...selectedSectionStyling}
                      if (selectedSectionStyling != null)
                        values.content.marginLeft = Number(values.content.marginLeft) + 1
                      setSelectedSectionStyling(values)
                    }}
                  >
                    +
                  </button>
                </div>
              </div>
            </div>
          </>
        )}

        {/* Destination Link */}
        {selectedSectionStyling?.type == 'button' && (
          <>
            <h3 className='my-2 mt-8'>
              <label className='fnt-600'> Add Destination Link to Shape</label>
            </h3>
            <h4 className='my-2' style={{color: 'black'}}>
              <label className='labl-gry '>
                {' '}
                If user click here they will be directed to the destination set:
              </label>
            </h4>
            <div className='d-flex my-2 mt-4'>
              <div className='property-test d-none-multi-checbox w-50'>
                <div
                  className={`multi-select-container jobs-property ms-0 me-5   ${
                    selectedSectionStyling?.link?.[0]?.value?.trim()?.length === 0
                      ? 'no-value select '
                      : 'has-value'
                  }`}
                >
                  <input
                    //   {...register('expenseName', {required: true})}
                    type='text'
                    className='form-control form-control-solid  bg-white input-style btn-clr-input'
                    name='expenseName'
                    value={selectedSectionStyling?.content?.buttonLink}
                    style={{height: '35px'}}
                    placeholder={'Destination Link..'}
                    onChange={(e: any) => {
                      const values = {...selectedSectionStyling}
                      const sectionElement: any = document.getElementById(
                        selectedSectionStyling?.id
                      )

                      if (sectionElement) {
                        const tag: NodeListOf<HTMLAnchorElement> =
                          sectionElement.querySelectorAll('a')
                        if (tag) {
                          //
                          tag.forEach((itm, ind) => {
                            itm.href = e.target.value
                          })
                          values.content.buttonLink = e.target.value
                        }
                      }
                      setSelectedSectionStyling(values)
                    }}
                    autoComplete='off'
                  />
                  {/* <MultiSelect
                options={linkLists}
                value={
                  selectedSectionStyling?.link?.[0]?.value?.trim()?.length > 0
                    ? selectedSectionStyling?.link?.[0]?.value
                    : []
                }
                onChange={(e: any) => {
                  let newVal: any = e
                  if (e?.length > 1) newVal = e.slice(1)

                  let values = {...selectedSectionStyling}
                  values.height = newVal
                  setSelectedSectionStyling(values)
                }}
                labelledBy=''
                overrideStrings={{
                  allItemsAreSelected: '',
                }}
                hasSelectAll={false}
                closeOnChangedValue={true}
              /> */}
                </div>
              </div>
              <div className='d-flex align-items-center ms-5 w-50'>
                <div
                  className={`d-flex align-items-center justify-content-center ${
                    selectedSectionStyling?.content?.link?.[0]?.display == 0
                      ? 'green-radio-filled'
                      : ' green-radio-hollow'
                  } h-18px w-18px me-3 cursor-pointer`}
                  onClick={() => {
                    let values = {...selectedSectionStyling}
                    if (Number(values.content.link[0].display) == 0)
                      values.content.link[0].display = 1
                    else values.content.link[0].display = 0
                    setSelectedSectionStyling(values)
                  }}
                >
                  <img src={whiteTick} height={10} width={10} />
                </div>
                <h4 className='mb-0'>No Link</h4>
              </div>
            </div>
          </>
        )}

        {/* Image */}
        {(selectedSectionStyling?.type == 'image' ||
          selectedSectionStyling?.type == 'textImage') && (
          <>
            {Array.from(selectedSectionImages)?.length > 0 && (
              <h3 className='my-2 mt-8'>
                <label className='fnt-600'> Edit Images</label>
              </h3>
            )}

            {Array.from(selectedSectionImages)?.map((image: any, imgInd: any) => (
              <>
                <h3 className='my-2 mt-8'>
                  <label className='fnt-600'>Image {imgInd + 1}</label>
                </h3>
                <div className='d-flex my-2 mt-4'>
                  <div className='w-50'>
                    {/* <h4 className=''>Upload</h4>
            <button
              type='button'
              className='btn btn-sm fw-bold green-submit-btn px-2 justify-content-center status-w-120 mt-3'
              onClick={() => {}}
            >
              Upload
            </button> */}
                    <div className='position-relative'>
                      {selectedSectionStyling?.content?.image?.[imgInd]?.display == 0 && (
                        <div
                          className='requestBoxOpacity z-index-2 border-radius-5px d-flex align-items-center justify-content-center'
                          style={{
                            boxShadow: '0px 0px 20px 0px rgba(10, 24, 64, -0.8)',
                          }}
                        ></div>
                      )}
                      <label
                        className='btn btn-sm fw-bold  me-0 green-submit-btn btn-fit-content px-7'
                        onClick={() => {}}
                        style={{width: 'fit-content'}}
                        htmlFor={`uploadNew${imgInd}`}
                      >
                        {/* <img src={uploadWhite} height={18} width={18} className='me-3' /> */}
                        Upload
                      </label>
                    </div>
                    <input
                      type='file'
                      hidden
                      id={`uploadNew${imgInd}`}
                      className='form-control form-control-solid'
                      placeholder='upload'
                      name={`newImg${imgInd}`}
                      accept='image/png, image/jpeg, image/gif, image/webp'
                      onChange={(e: any) => {
                        handleChange(e, imgInd)
                      }}
                      disabled={selectedSectionStyling?.content?.image?.[imgInd]?.display == 0}
                    />
                  </div>

                  <div className='d-flex align-items-center ms-5 w-50'>
                    <div
                      className={`d-flex align-items-center justify-content-center ${
                        selectedSectionStyling?.content?.image?.[imgInd]?.display == 0
                          ? 'green-radio-filled'
                          : ' green-radio-hollow'
                      } h-18px w-18px me-3 cursor-pointer`}
                      onClick={() => {
                        let values = {...selectedSectionStyling}
                        if (Number(values.content.image[imgInd].display) == 0)
                          values.content.image[imgInd].display = 1
                        else values.content.image[imgInd].display = 0

                        const sectionElement: any = document.getElementById(
                          selectedSectionStyling?.id
                        )

                        if (sectionElement) {
                          // Select the image element inside the section
                          const img: NodeListOf<HTMLImageElement> =
                            sectionElement.querySelectorAll('img:not(.svg-img)')
                          if (img) {
                            //
                            Array.from(img)[imgInd].src = ''
                            Array.from(img)[imgInd].style.height = 0 + 'px'
                            Array.from(img)[imgInd].style.width = 0 + 'px'

                            // Update the content HTML with the modified outerHTML of the section
                            values.content.html = sectionElement.innerHTML

                            // Update the selected section styling state
                            setSelectedSectionStyling(values)
                          }
                        }

                        setSelectedSectionStyling(values)
                      }}
                    >
                      <img src={whiteTick} height={10} width={10} />
                    </div>
                    <h4 className='mb-0'>Remove Image</h4>
                  </div>
                </div>

                {selectedSectionStyling?.type == 'image' && (
                  <>
                    <div className='d-flex my-2 mt-4'>
                      <div className='property-test d-none-multi-checbox w-50'>
                        <div
                          className={`multi-select-container jobs-property ms-0 me-5   ${
                            selectedSectionStyling?.link?.[0]?.value?.trim()?.length === 0
                              ? 'no-value select '
                              : 'has-value'
                          }`}
                        >
                          <input
                            //   {...register('expenseName', {required: true})}
                            type='text'
                            className='form-control form-control-solid  bg-white input-style btn-clr-input'
                            name='expenseName'
                            value={selectedSectionStyling?.content?.image?.[imgInd]?.link}
                            style={{height: '35px'}}
                            placeholder={'Destination Link..'}
                            onChange={(e: any) => {
                              const values = {...selectedSectionStyling}
                              const sectionElement: any = document.getElementById(
                                selectedSectionStyling?.id
                              )

                              if (sectionElement) {
                                const tag: NodeListOf<HTMLAnchorElement> =
                                  sectionElement.querySelectorAll('a')
                                if (tag) {
                                  //
                                  tag.forEach((itm, ind) => {
                                    if (ind == imgInd) itm.href = e.target.value
                                  })
                                  values.content.image[imgInd].link = e.target.value
                                }
                              }
                              setSelectedSectionStyling(values)
                            }}
                            autoComplete='off'
                            disabled={
                              selectedSectionStyling?.content?.image?.[imgInd]?.linkDisplay == 0
                            }
                          />
                        </div>
                      </div>
                      <div className='d-flex align-items-center ms-5 w-50'>
                        <div
                          className={`d-flex align-items-center justify-content-center ${
                            selectedSectionStyling?.content?.image?.[imgInd]?.linkDisplay == 0
                              ? 'green-radio-filled'
                              : ' green-radio-hollow'
                          } h-18px w-18px me-3 cursor-pointer`}
                          onClick={() => {
                            let values = {...selectedSectionStyling}
                            const sectionElement: any = document.getElementById(
                              selectedSectionStyling?.id
                            )

                            if (Number(values?.content?.image?.[imgInd]?.linkDisplay) === 0) {
                              values.content.image[imgInd].linkDisplay = 1

                              // Re-wrap the image with <a> tag
                              if (sectionElement) {
                                const imgTag: NodeListOf<HTMLImageElement> =
                                  sectionElement.querySelectorAll('img:not(.svg-img)')
                                if (imgTag) {
                                  imgTag.forEach((img, ind) => {
                                    if (ind === imgInd) {
                                      const newAnchor = document.createElement('a')
                                      newAnchor.href = values.content.image[imgInd].link
                                      img.parentNode?.insertBefore(newAnchor, img)
                                      newAnchor.appendChild(img)
                                    }
                                  })
                                }
                              }
                            } else {
                              values.content.image[imgInd].linkDisplay = 0

                              // Remove the <a> tag
                              if (sectionElement) {
                                const tag: NodeListOf<HTMLAnchorElement> =
                                  sectionElement.querySelectorAll('a')
                                if (tag) {
                                  tag.forEach((itm, ind) => {
                                    if (ind === imgInd) {
                                      const parent = itm.parentNode
                                      if (parent) {
                                        // Replace <a> with its child nodes (removing <a>)
                                        while (itm.firstChild) {
                                          parent.insertBefore(itm.firstChild, itm)
                                        }
                                        parent.removeChild(itm)
                                      }
                                    }
                                  })
                                }
                              }
                              values.content.image[imgInd].link = ''
                            }
                            setSelectedSectionStyling(values)
                          }}
                        >
                          <img src={whiteTick} height={10} width={10} />
                        </div>
                        <h4 className='mb-0'>No Link</h4>
                      </div>
                    </div>
                  </>
                )}
              </>
            ))}
          </>
        )}

        {/* Alignment */}
        {(selectedSectionStyling?.type == 'image' || selectedSectionStyling?.type == 'button') && (
          <>
            <h3 className='my-2 mt-8'>
              <label className='fnt-600'>Alignment</label>
            </h3>
            <div
              className='d-flex align-items-center mt-3'
              style={{backgroundColor: '#f0f1f4', width: 'fit-content'}}
            >
              <img
                src={alignLeftImg}
                height={50}
                width={50}
                className={`p-2 cursor-pointer ${
                  selectedSectionStyling?.content?.justifyContent == 'left'
                    ? 'selected-alignment'
                    : ''
                }`}
                onClick={(e: any) => {
                  let values = {...selectedSectionStyling}
                  values.content.justifyContent = 'left'

                  if(values?.content?.imgSrc == 'svg' || values?.content?.imgSrc == 'headerImg'){
                    const sectionElement: any = document.getElementById(selectedSectionStyling?.id)

                    if (sectionElement) {
                      const sectionElementImg: any = document.getElementById('img-in-svg')
                       sectionElementImg.style.marginLeft = '0px'
                       sectionElementImg.style.marginRight = 'auto'
                    }

                    values.content.html = sectionElement.innerHTML
                  }

                  setSelectedSectionStyling(values)
                }}
              />
              <img
                src={alignCenterImg}
                height={50}
                width={50}
                className={`p-2 cursor-pointer ${
                  selectedSectionStyling?.content?.justifyContent == 'center'
                    ? 'selected-alignment'
                    : ''
                }`}
                onClick={(e: any) => {
                  let values = {...selectedSectionStyling}
                  values.content.justifyContent = 'center'

                  if(values?.content?.imgSrc == 'svg' || values?.content?.imgSrc == 'headerImg'){
                    const sectionElement: any = document.getElementById(selectedSectionStyling?.id)

                    if (sectionElement) {
                      const sectionElementImg: any = document.getElementById('img-in-svg')
                      sectionElementImg.style.marginLeft = 'auto'
                      sectionElementImg.style.marginRight = 'auto'
                    }

                    values.content.html = sectionElement.innerHTML
                  }

                  setSelectedSectionStyling(values)
                }}
              />
              <img
                src={alignRightImg}
                height={50}
                width={50}
                className={`p-2 cursor-pointer ${
                  selectedSectionStyling?.content?.justifyContent == 'right'
                    ? 'selected-alignment'
                    : ''
                }`}
                onClick={(e: any) => {
                  let values = {...selectedSectionStyling}
                  values.content.justifyContent = 'right'

                  if(values?.content?.imgSrc == 'svg' || values?.content?.imgSrc == 'headerImg'){
                    const sectionElement: any = document.getElementById(selectedSectionStyling?.id)

                    if (sectionElement) {
                      const sectionElementImg: any = document.getElementById('img-in-svg')
                      sectionElementImg.style.marginLeft = 'auto'
                      sectionElementImg.style.marginRight = '0px'
                    }

                    values.content.html = sectionElement.innerHTML
                  }

                  setSelectedSectionStyling(values)
                }}
              />
              <img
                src={justifyContentImg}
                height={50}
                width={50}
                className={`p-2 cursor-pointer ${
                  selectedSectionStyling?.content?.justifyContent == 'space-between'
                    ? 'selected-alignment'
                    : ''
                }`}
                onClick={(e: any) => {
                  let values = {...selectedSectionStyling}
                  values.content.justifyContent = 'space-between'

                  if(values?.content?.imgSrc == 'svg' || values?.content?.imgSrc == 'headerImg'){
                    const sectionElement: any = document.getElementById(selectedSectionStyling?.id)

                    if (sectionElement) {
                      const sectionElementImg: any = document.getElementById('img-in-svg')
                      sectionElementImg.style.marginLeft = 'auto'
                      sectionElementImg.style.marginRight = 'auto'
                    }

                    values.content.html = sectionElement.innerHTML
                  }


                  setSelectedSectionStyling(values)
                }}
              />
            </div>
          </>
        )}

        {/* Size */}
        {selectedSectionStyling?.type != 'text' && (
          <>
            <h3 className='my-2 mt-8'>
              <label className='fnt-600'>
                {' '}
                {selectedSectionStyling?.type == 'textImage' ? 'Image Size' : 'Size'}
              </label>
            </h3>

            <div className='d-flex'>
              <div>
                <input
                  type='number'
                  className='form-control form-control-solid input-style tenancy-control bg-white'
                  name='expenseName'
                  value={Number(selectedSectionStyling?.content?.width)}
                  style={{height: '35px', width: '100px', border: '2px solid #2f2d2d8c'}}
                  onChange={(e: any) => {
                    let values = {...selectedSectionStyling}

                    const sectionElement: any = document.getElementById(selectedSectionStyling?.id)

                    if (sectionElement) {
                      // Select the image element inside the section
                      // console.log(img)
                      // Array.from(img).map((itm, ind) => {
                      //   console.log(itm)
                      // })

                      let tag: NodeListOf<HTMLImageElement> | NodeListOf<HTMLButtonElement> | null =
                        null

                      if (
                        selectedSectionStyling?.type == 'image' ||
                        selectedSectionStyling?.type == 'textImage'
                      ) {
                        tag = sectionElement.querySelectorAll('img:not(.svg-img)')
                      } else if (selectedSectionStyling?.type == 'button') {
                        tag = sectionElement.querySelectorAll('button')
                      }

                      if (tag) {
                        //
                        tag.forEach((itm, ind) => {
                          itm.style.width = e.target.value + 'px'
                          values.content.width = e.target.value
                        })
                      }

                      // Update the content HTML with the modified outerHTML of the section
                      values.content.html = sectionElement.innerHTML
                      setSelectedSectionStyling(values)
                    }

                    // const doc :any= document.getElementById(selectedSectionStyling?.id)
                    // console.log(doc)
                    // const img:any = doc.querySelector('img');
                    // console.log(img)
                    // img.style.width = e.target.value+'px'

                    // const div = document.getElementById(selectedSectionStyling?.id)
                    // console.log(div)
                    // values.content.html = `${div}`
                    // console.log(values.content.html)

                    // if (selectedSectionStyling != null) {
                    //   values.content.width = e.target.value
                    //   values.content.sizeType = 'px'
                    // }
                    // setSelectedSectionStyling(values)
                  }}
                  autoComplete='off'
                  placeholder='Width...'
                  // disabled={selectedSectionStyling?.content?.imgSrc != undefined}
                />
                <h3 className='my-2 mx-3' style={{color: 'black'}}>
                  <label className='labl-gry fnt-500'> px</label>
                </h3>
              </div>
              <h4 className='my-2 mx-3' style={{color: 'black'}}>
                <label className='labl-gry fnt-500'> W</label>
              </h4>

              <div>
                <input
                  type='number'
                  className='form-control form-control-solid input-style tenancy-control bg-white'
                  name='expenseName'
                  value={Number(selectedSectionStyling?.content?.height)}
                  style={{height: '35px', width: '100px'}}
                  onChange={(e: any) => {
                    let values = {...selectedSectionStyling}

                    const sectionElement: any = document.getElementById(selectedSectionStyling?.id)

                    if (sectionElement) {
                      let tag: NodeListOf<HTMLImageElement> | NodeListOf<HTMLButtonElement> | null =
                        null

                      if (
                        selectedSectionStyling?.type == 'image' ||
                        selectedSectionStyling?.type == 'textImage'
                      ) {
                        tag = sectionElement.querySelectorAll('img:not(.svg-img)')
                      } else if (selectedSectionStyling?.type == 'button') {
                        tag = sectionElement.querySelectorAll('button')
                      }

                      if (tag) {
                        //
                        tag.forEach((itm, ind) => {
                          itm.style.height = e.target.value + 'px'
                          values.content.height = e.target.value
                        })
                      }

                      // Update the content HTML with the modified outerHTML of the section
                      values.content.html = sectionElement.innerHTML
                      setSelectedSectionStyling(values)
                    }

                    //  if (selectedSectionStyling != null){
                    //    values.content.height = e.target.value
                    //    values.content.sizeType = 'px'
                    //  }
                    // setSelectedSectionStyling(values)
                  }}
                  autoComplete='off'
                  placeholder='Height...'
                />
                <h3 className='my-2 mx-3' style={{color: 'black'}}>
                  <label className='labl-gry fnt-500'> px</label>
                </h3>
              </div>
              <h4 className='my-2 mx-3' style={{color: 'black'}}>
                <label className='labl-gry fnt-500'> H</label>
              </h4>
            </div>

            {selectedSectionStyling && (
              <>
                {selectedSectionStyling?.type == 'image' && (
                  <Box className='mt-4'>
                    <PrettoSlider
                      valueLabelDisplay='auto'
                      aria-label='pretto slider'
                      defaultValue={selectedSectionStyling?.content?.height}
                      value={selectedSectionStyling?.content?.height}
                      onChange={(e: Event, newValue: number | number[]) => {
                        let values = {...selectedSectionStyling}
                        const sectionElement = document.getElementById(selectedSectionStyling?.id)

                        if (sectionElement) {
                          if (selectedSectionStyling?.type === 'image') {
                            const img: NodeListOf<HTMLImageElement> =
                              sectionElement.querySelectorAll('img:not(.svg-img)')
                            img.forEach((itm) => {
                              itm.style.height = newValue + '%'
                              itm.style.width = newValue + '%'

                              values.content.height = newValue
                              values.content.width = newValue
                            })
                          } else if (selectedSectionStyling?.type === 'button') {
                            const btn: NodeListOf<HTMLButtonElement> =
                              sectionElement.querySelectorAll('button')
                            btn.forEach((itm) => {
                              itm.style.height = newValue + '%'
                              itm.style.width = newValue + '%'

                              values.content.height = newValue
                              values.content.width = newValue
                            })
                          }

                          values.content.html = sectionElement.innerHTML
                          setSelectedSectionStyling(values)
                        }
                      }}
                      onChangeCommitted={(event: any, newValue: number | number[]) => {
                        let values = {...selectedSectionStyling}
                        const sectionElement = document.getElementById(selectedSectionStyling?.id)

                        if (sectionElement) {
                          if (selectedSectionStyling?.type === 'image') {
                            const img: NodeListOf<HTMLImageElement> =
                              sectionElement.querySelectorAll('img:not(.svg-img)')
                            img.forEach((itm) => {
                              itm.style.height = newValue + '%'
                              itm.style.width = newValue + '%'

                              values.content.height = newValue
                              values.content.width = newValue
                            })
                          } else if (selectedSectionStyling?.type === 'button') {
                            const btn: NodeListOf<HTMLButtonElement> =
                              sectionElement.querySelectorAll('button')
                            btn.forEach((itm) => {
                              itm.style.height = newValue + '%'
                              itm.style.width = newValue + '%'

                              values.content.height = newValue
                              values.content.width = newValue
                            })
                          }

                          values.content.html = sectionElement.innerHTML
                          setSelectedSectionStyling(values)
                        }
                      }}
                    />

                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        transform: 'translate(0px, -7px)',
                      }}
                    >
                      <Typography>
                        <h4>0%</h4>
                      </Typography>
                      <Typography>
                        <h4>100%</h4>
                      </Typography>
                    </Box>
                  </Box>
                )}
              </>
            )}
          </>
        )}

        {/* Border */}
        {selectedSectionStyling?.type != 'text' && (
          <>
            <h3 className='my-2 mt-8'>
              <label className='fnt-600'> Border</label>
            </h3>

            <div className='d-flex align-items-center'>
              <div className='d-flex disable-input-stepper'>
                <button
                  onClick={() => {
                    let values = {...selectedSectionStyling}

                    const sectionElement: any = document.getElementById(selectedSectionStyling?.id)

                    if (sectionElement) {
                      let tag: NodeListOf<HTMLImageElement> | NodeListOf<HTMLButtonElement> | null =
                        null

                      if (
                        selectedSectionStyling?.type == 'image' ||
                        selectedSectionStyling?.type == 'textImage'
                      ) {
                        tag = sectionElement.querySelectorAll('img:not(.svg-img)')
                      } else if (selectedSectionStyling?.type == 'button') {
                        tag = sectionElement.querySelectorAll('button')
                      }

                      if (tag) {
                        //
                        tag.forEach((itm, ind) => {
                          itm.style.border = `${values.content.borderThickness - 1}px ${
                            values.content.borderLine
                          } ${values.content.borderColor}`
                        })
                      }

                      if (selectedSectionStyling != null)
                        values.content.borderThickness = Number(values.content.borderThickness) - 1
                    }

                    // Update the content HTML with the modified outerHTML of the section
                    values.content.html = sectionElement.innerHTML

                    setSelectedSectionStyling(values)
                  }}
                  className='stepper-left'
                >
                  -
                </button>
                <input
                  type='number'
                  className='form-control form-control-solid stepper-input-style bg-white'
                  name='expenseName'
                  value={selectedSectionStyling?.content?.borderThickness}
                  style={{
                    height: '35px',
                    width: '50px',
                    borderRadius: '0px',
                    textAlign: 'center', // Center-align text
                    MozAppearance: 'textfield', // For Firefox
                  }}
                  onChange={(e: any) => {
                    let values = {...selectedSectionStyling}
                    const sectionElement: any = document.getElementById(selectedSectionStyling?.id)

                    if (sectionElement) {
                      let tag: NodeListOf<HTMLImageElement> | NodeListOf<HTMLButtonElement> | null =
                        null

                      if (
                        selectedSectionStyling?.type == 'image' ||
                        selectedSectionStyling?.type == 'textImage'
                      ) {
                        tag = sectionElement.querySelectorAll('img:not(.svg-img)')
                      } else if (selectedSectionStyling?.type == 'button') {
                        tag = sectionElement.querySelectorAll('button')
                      }

                      if (tag) {
                        //
                        tag.forEach((itm, ind) => {
                          itm.style.border = `${e.target.value}px ${values.content.borderLine} ${values.content.borderColor}`
                        })
                      }

                      if (selectedSectionStyling != null)
                        values.content.borderThickness = Number(e.target.value)
                    }

                    // Update the content HTML with the modified outerHTML of the section
                    values.content.html = sectionElement.innerHTML
                    setSelectedSectionStyling(values)
                  }}
                  autoComplete='off'
                />
                <button
                  className='stepper-right-no-border'
                  onClick={() => {
                    let values = {...selectedSectionStyling}
                    const sectionElement: any = document.getElementById(selectedSectionStyling?.id)

                    if (sectionElement) {
                      let tag: NodeListOf<HTMLImageElement> | NodeListOf<HTMLButtonElement> | null =
                        null

                      if (
                        selectedSectionStyling?.type == 'image' ||
                        selectedSectionStyling?.type == 'textImage'
                      ) {
                        tag = sectionElement.querySelectorAll('img:not(.svg-img)')
                      } else if (selectedSectionStyling?.type == 'button') {
                        tag = sectionElement.querySelectorAll('button')
                      }

                      if (tag) {
                        //
                        tag.forEach((itm, ind) => {
                          itm.style.border = `${values.content.borderThickness + 1}px ${
                            values.content.borderLine
                          } ${values.content.borderColor}`
                        })
                      }

                      if (selectedSectionStyling != null)
                        values.content.borderThickness = Number(values.content.borderThickness) + 1
                    }

                    // Update the content HTML with the modified outerHTML of the section
                    values.content.html = sectionElement.innerHTML
                    setSelectedSectionStyling(values)
                  }}
                >
                  +
                </button>
              </div>
              <div className='property-test d-none-multi-checbox'>
                <div
                  className={`multi-select-container jobs-property template-border-select ms-0 ${
                    selectedSectionStyling?.link?.[0]?.value?.trim()?.length === 0
                      ? 'no-value select '
                      : 'has-value'
                  }`}
                >
                  <MultiSelect
                    options={borderTypeLists}
                    value={
                      selectedSectionStyling?.content?.borderLine == 'solid'
                        ? [
                            {
                              label: 'solid',
                              value: 'solid',
                            },
                          ]
                        : selectedSectionStyling?.content?.borderLine == 'dotted'
                        ? [
                            {
                              label: 'dotted',
                              value: 'dotted',
                            },
                          ]
                        : selectedSectionStyling?.content?.borderLine == 'dashed'
                        ? [
                            {
                              label: 'dashed',
                              value: 'dashed',
                            },
                          ]
                        : []
                    }
                    onChange={(e: any) => {
                      let newVal: any = e
                      if (e?.length > 1) newVal = e.slice(1)

                      let values = {...selectedSectionStyling}

                      const sectionElement: any = document.getElementById(
                        selectedSectionStyling?.id
                      )

                      if (sectionElement) {
                        let tag:
                          | NodeListOf<HTMLImageElement>
                          | NodeListOf<HTMLButtonElement>
                          | null = null

                        if (
                          selectedSectionStyling?.type == 'image' ||
                          selectedSectionStyling?.type == 'textImage'
                        ) {
                          tag = sectionElement.querySelectorAll('img:not(.svg-img)')
                        } else if (selectedSectionStyling?.type == 'button') {
                          tag = sectionElement.querySelectorAll('button')
                        }

                        if (tag) {
                          //
                          tag.forEach((itm, ind) => {
                            itm.style.border = `${values.content.borderThickness}px ${newVal?.[0]?.value} ${values.content.borderColor}`
                          })
                        }
                      }

                      values.content.borderLine = newVal?.[0]?.value
                      values.content.html = sectionElement.innerHTML
                      setSelectedSectionStyling(values)
                    }}
                    labelledBy=''
                    overrideStrings={{
                      allItemsAreSelected: '',
                    }}
                    hasSelectAll={false}
                    closeOnChangedValue={true}
                  />
                </div>
              </div>
              <div>
                <div className='color-picker-box-border d-flex align-items-center'>
                  <div
                    className='color-picker-selected-circle-border'
                    style={{
                      backgroundColor: `${
                        selectedSectionStyling?.content?.borderColor
                          ? selectedSectionStyling?.content?.borderColor
                          : 'black'
                      }`,
                    }}
                  ></div>
                  <p className='fs-5 ms-2 mb-0'>
                    {selectedSectionStyling?.content?.borderColor
                      ? selectedSectionStyling?.content?.borderColor
                      : '#0000'}
                  </p>

                  <img
                    src={colorPickImg}
                    height={23}
                    width={23}
                    className='ms-auto cursor-pointer'
                    onClick={() => {
                      setIsColorPickerVisible(false)
                      setIsColorPickerForButtonTextColorVisible(false)
                      setIsColorPickerForButtonVisible(false)
                      setIsColorPickerForBorder(!isColorPickerForBorder)
                      setIsColorPickerForShape(false)
                    }}
                  />
                </div>

                <div style={{height: '0px'}}>
                  <div className='color-picker-div'>
                    {isColorPickerForBorder && (
                      <ColorPicker
                        color={selectedSectionStyling?.content?.borderColor}
                        onChange={(color: any) => {
                          let values = {...selectedSectionStyling}

                          const sectionElement: any = document.getElementById(
                            selectedSectionStyling?.id
                          )

                          if (sectionElement) {
                            let tag:
                              | NodeListOf<HTMLImageElement>
                              | NodeListOf<HTMLButtonElement>
                              | null = null

                            if (
                              selectedSectionStyling?.type == 'image' ||
                              selectedSectionStyling?.type == 'textImage'
                            ) {
                              tag = sectionElement.querySelectorAll('img:not(.svg-img)')
                            } else if (selectedSectionStyling?.type == 'button') {
                              tag = sectionElement.querySelectorAll('button')
                            }

                            if (tag) {
                              //
                              tag.forEach((itm, ind) => {
                                itm.style.border = `${values.content.borderThickness}px ${values.content.borderLine} ${color.hex}`
                              })
                            }
                          }

                          values.content.borderColor = color?.hex
                          values.content.html = sectionElement.innerHTML

                          setSelectedSectionStyling(values)
                        }}
                        presets={['#6254ff', '#146c6a', '#00b9a9', '#D7197D', '#3573CB', '#E4E4E4']}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>

            <h3 className='my-2 mt-8'>
              <label className='fnt-600'> Border Radius</label>
            </h3>

            <div className='d-flex align-items-center'>
              <div className='d-flex disable-input-stepper'>
                <button
                  onClick={() => {
                    let values = {...selectedSectionStyling}

                    const sectionElement: any = document.getElementById(selectedSectionStyling?.id)

                    if (sectionElement) {
                      let tag: NodeListOf<HTMLImageElement> | NodeListOf<HTMLButtonElement> | null =
                        null

                      if (
                        selectedSectionStyling?.type == 'image' ||
                        selectedSectionStyling?.type == 'textImage'
                      ) {
                        tag = sectionElement.querySelectorAll('img:not(.svg-img)')
                      } else if (selectedSectionStyling?.type == 'button') {
                        tag = sectionElement.querySelectorAll('button')
                      }

                      if (tag) {
                        //
                        tag.forEach((itm, ind) => {
                          itm.style.borderRadius = `${values.content.borderInnerTagRadius - 1}px`
                        })
                      }

                      if (selectedSectionStyling != null)
                        values.content.borderInnerTagRadius =
                          Number(values.content.borderInnerTagRadius) - 1
                    }

                    // Update the content HTML with the modified outerHTML of the section
                    values.content.html = sectionElement.innerHTML

                    setSelectedSectionStyling(values)
                  }}
                  className='stepper-left'
                >
                  -
                </button>
                <input
                  type='number'
                  className='form-control form-control-solid stepper-input-style bg-white'
                  name='expenseName'
                  value={selectedSectionStyling?.content?.borderInnerTagRadius}
                  style={{
                    height: '35px',
                    width: '50px',
                    borderRadius: '0px',
                    textAlign: 'center', // Center-align text
                    MozAppearance: 'textfield', // For Firefox
                  }}
                  onChange={(e: any) => {
                    let values = {...selectedSectionStyling}
                    const sectionElement: any = document.getElementById(selectedSectionStyling?.id)

                    if (sectionElement) {
                      let tag: NodeListOf<HTMLImageElement> | NodeListOf<HTMLButtonElement> | null =
                        null

                      if (
                        selectedSectionStyling?.type == 'image' ||
                        selectedSectionStyling?.type == 'textImage'
                      ) {
                        tag = sectionElement.querySelectorAll('img:not(.svg-img)')
                      } else if (selectedSectionStyling?.type == 'button') {
                        tag = sectionElement.querySelectorAll('button')
                      }

                      if (tag) {
                        //
                        tag.forEach((itm, ind) => {
                          itm.style.borderRadius = `${e.target.value + 1}px`
                        })
                      }

                      if (selectedSectionStyling != null)
                        values.content.borderInnerTagRadius = Number(e.target.value)
                    }

                    // Update the content HTML with the modified outerHTML of the section
                    values.content.html = sectionElement.innerHTML
                    setSelectedSectionStyling(values)
                  }}
                  autoComplete='off'
                />
                <button
                  className='stepper-right'
                  onClick={() => {
                    let values = {...selectedSectionStyling}
                    const sectionElement: any = document.getElementById(selectedSectionStyling?.id)

                    if (sectionElement) {
                      let tag: NodeListOf<HTMLImageElement> | NodeListOf<HTMLButtonElement> | null =
                        null

                      if (
                        selectedSectionStyling?.type == 'image' ||
                        selectedSectionStyling?.type == 'textImage'
                      ) {
                        tag = sectionElement.querySelectorAll('img:not(.svg-img)')
                      } else if (selectedSectionStyling?.type == 'button') {
                        tag = sectionElement.querySelectorAll('button')
                      }

                      if (tag) {
                        //
                        tag.forEach((itm, ind) => {
                          itm.style.borderRadius = `${values.content.borderInnerTagRadius + 1}px`
                        })
                      }

                      if (selectedSectionStyling != null)
                        values.content.borderInnerTagRadius =
                          Number(values.content.borderInnerTagRadius) + 1
                    }

                    // Update the content HTML with the modified outerHTML of the section
                    values.content.html = sectionElement.innerHTML
                    setSelectedSectionStyling(values)
                  }}
                >
                  +
                </button>
              </div>
            </div>
          </>
        )}
      </div>

      <SingleFileLoader isVisible={showLoader} />
    </div>
  )
}

export default SectionEditor
