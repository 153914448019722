import React, {useEffect} from 'react'
import {useState} from 'react'
import {Row, Col, OverlayTrigger, Tooltip} from 'react-bootstrap'
import {useNavigate, useParams} from 'react-router-dom'
import backArrow from '../../../../../img/back-arrow.png'
import {ApiGet, ApiPost, ApiPut} from '../../../../../apiCommon/helpers/API/ApiData'
import schedule from '../../../../../img/schedule-blue.png'
import closure from '../../../../../img/job-closure.png'
import correct from '../../../../../img/correct.png'
import removed from '../../../../../img/remove-filled.png'
import pencil from '../../../../../img/edit-pen.png'
import { ErrorToast, SuccessToast } from '../../../../../apiCommon/helpers/Toast'

const Operations = () => {
  const navigate = useNavigate()
  const [details, setDetails] = useState<any>(null)
  const [isEditSchedule, setIsEditSchedule] = useState<any>(false)
  const [isEditClosure, setIsEditClosure] = useState<any>(false)
  const [isLoading, setIsLoading] =  useState<any>(false)
  let flg:any = 0

  //
  const getDetails = () => {
    ApiGet('corporate/corporate_setting')
      .then((res: any) => {
        setDetails(res?.data?.data)
        setIsLoading(false)
        if(isEditSchedule && flg == 1)
          setIsEditSchedule(false)
        if(isEditClosure && flg == 2)
          setIsEditClosure(false)
      })
      .catch((err: any) => {
        console.log('err', err)
        setIsLoading(false)
      })
  }

  //
  useEffect(() => {
    getDetails()
  }, [])

  //
  const updateOperations = () => {
    
    setIsLoading(true)
    const body = {
      maintenanceOnTimeVisitMaxDuration: flg == 1
        ? details?.maintenanceOnTimeVisitMaxDuration
        : undefined,
      maintenanceMaxDurationOfTenantConfirmation: flg == 2
        ? details?.maintenanceMaxDurationOfTenantConfirmation
        : undefined,
    }
    ApiPut('corporate/corporate_setting', body)
      .then((res) => {
        getDetails()
      })
      .catch((err) => {
        setIsLoading(false)
        ErrorToast(err.message)
      }
      )
  
  }

  return (
    <>
      <div id='' className='pt-0 mt-0 px-2 ps-1'>
        <div className='d-flex align-items-center gap-2 mb-5 gap-lg-3'>
          <div className='d-flex align-items-center gap-3'>
            <span
              className='rounded-circle'
              style={{cursor: 'pointer'}}
              onClick={() => navigate(`/settings`)}
            >
              <img src={backArrow} style={{stroke: 'red'}} height='14px' width='14px' />
            </span>
            <h2 className='page-heading m-0 head-text'>{'Maintenance Settings - Operations'}</h2>
          </div>
        </div>

        <div className='card card-flush gap-5 p-5 pt-1 pb-0 mt-5 px-3' style={{minHeight: '75vh'}}>
          <div className='card-body pt-0  py-0 px-3'>
            <Row className='my-5'>
              <Col md={6} className='mb-5' style={{display: 'flex', flexDirection: 'column'}}>
                <div className='d-flex align-items-center '>
                  {!isEditSchedule ? (
                    <>
                      <img
                        src={pencil}
                        alt=''
                        height={18}
                        width={18}
                        className='ms-4 me-1 cursor-pointer ms-auto'
                        onClick={() => {
                          setIsEditSchedule(true)
                          
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <img
                        src={removed}
                        height={20}
                        width={20}
                        className='ms-4 cursor-pointer ms-auto'
                        onClick={() => {
                          flg = 1
                          setIsEditSchedule(false)
                          getDetails()
                        }}
                      />
                      <img
                        src={correct}
                        height={20}
                        width={20}
                        className='ms-5 cursor-pointer'
                        onClick={() => {
                          if(!isLoading){
                          flg = 1
                          updateOperations()
                          }
                        }}
                      />
                    </>
                  )}
                </div>

                <div className='card card-flush gap-5 p-3 cursor-pointer mt-3 side'>
                  <div className='card-body p-3'>
                    <div className='d-flex align-items-center'>
                      <img src={schedule} height={35} width={35} />
                      <h4 className='page-heading m-0 head-text ps-4'>
                        <b>Scheduling </b>
                      </h4>
                      <div className='ms-auto text-center'>
                        <h5 className=' m-0 labl-gry mb-3'> Minutes</h5>
                        {isEditSchedule ? (
                          <input
                            type='number'
                            className='form-control form-control-solid mytest tenancy-control'
                            placeholder=''
                            name='renewalDays'
                            value={details?.maintenanceOnTimeVisitMaxDuration}
                            onChange={(e: any) => {
                              let values = {...details}
                              values.maintenanceOnTimeVisitMaxDuration = e.target.value
                              setDetails(values)
                            }}
                            disabled={isLoading}
                            style={{height: '35px'}}
                          />
                        ) : (
                          <h4 className=' m-0 head-text'>
                            <b>{details?.maintenanceOnTimeVisitMaxDuration}</b>
                          </h4>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <p className='ps-2 pe-5 fs-8 mt-7' style={{color: '#9797ae', fontWeight: '500'}}>
                  {' '}
                  {/* <i> */}
                  Set the timeframe in which the handling team can arrive to the property from the
                  visit starting time. If the handling team are confirmed to arrive after the set
                  timeframe from the visit start time, they will be considered as ‘Late’ arrivals.
                  {/* </i> */}
                </p>
              </Col>

              <Col md={6} className='mb-5' style={{display: 'flex', flexDirection: 'column'}}>
                <div className='d-flex align-items-center'>
                  {!isEditClosure ? (
                    <>
                      <img
                        src={pencil}
                        alt=''
                        height={18}
                        width={18}
                        className='ms-4 me-1 cursor-pointer ms-auto'
                        onClick={() => {
                          setIsEditClosure(true)
                         
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <img
                        src={removed}
                        height={20}
                        width={20}
                        className='ms-4 cursor-pointer ms-auto'
                        onClick={() => {
                          flg = 2
                          setIsEditClosure(false)
                          getDetails()
                        }}
                      />
                      <img
                        src={correct}
                        height={20}
                        width={20}
                        className='ms-5 me-0 cursor-pointer'
                        onClick={() => {
                          if(!isLoading){
                          flg = 2
                          updateOperations()
                          }
                        }}
                      />
                    </>
                  )}
                </div>

                <div className='card card-flush gap-5 p-3 cursor-pointer mt-3 side'>
                  <div className='card-body p-3'>
                    <div className='d-flex align-items-center'>
                      <img src={closure} height={35} width={40} />
                      <h4 className='page-heading m-0 head-text ps-4'>
                        <b>{'Job Closure'}</b>
                      </h4>
                      <div className='ms-auto text-center'>
                        <h5 className=' m-0 labl-gry mb-3'>Hours</h5>
                        {isEditClosure ? (
                          <input
                            type='number'
                            className='form-control form-control-solid mytest tenancy-control'
                            placeholder=''
                            name='renewalDays'
                            value={details?.maintenanceMaxDurationOfTenantConfirmation}
                            onChange={(e: any) => {
                              let values = {...details}
                              values.maintenanceMaxDurationOfTenantConfirmation = e.target.value
                              setDetails(values)
                            }}
                            disabled={isLoading}
                            style={{height: '35px'}}
                          />
                        ) : (
                          <h4 className=' m-0 head-text'>
                          <b>{details?.maintenanceMaxDurationOfTenantConfirmation}</b>
                        </h4>
                        )}

                       
                      </div>
                    </div>
                  </div>
                </div>

                <p className='ps-2 pe-5 fs-8 mt-7' style={{color: '#9797ae', fontWeight: '500'}}>
                  {' '}
                  {/* <i> */}
                  Set the duration in which a tenant can confirm the job is complete or incomplete.
                  Based on the duration set, the job will be automatically closed if the tenant does
                  not provide a response after receiving the confirmation request.
                  {/* </i> */}
                </p>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  )
}

export default Operations
