import moment from 'moment'
import React, {useEffect, useState, useRef} from 'react'
import Paper from '@mui/material/Paper'
import InputBase from '@mui/material/InputBase'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import Select from 'react-select'
import manageIcon from '../../../../../img/manage-list.png'
import {useLocation, useNavigate} from 'react-router-dom'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {ApiPost, ApiGet, ApiPut, ApiDelete} from '../../../../../apiCommon/helpers/API/ApiData'
import {SuccessToast, ErrorToast} from '../../../../../apiCommon/helpers/Toast'
import {MultiSelect} from 'react-multi-select-component'
import addWhite from '../../../../../img/add-white.png'
import AddNewService from './AddNewServiceModal'
import trashImg from '../../../../../img/trash.png'
import noData from '../../../../../img/NoData1.svg'
import {AiOutlineExclamationCircle} from 'react-icons/ai'
import pencil from '../../../../../img/edit-pen.png'
import Switch from 'react-switch'
import ChangeList from './ChangeListModal'
import './style.scss'
import swal from 'sweetalert2'
import {CircularProgress} from '@mui/material'
import ReactPaginate from 'react-paginate'
import backArrow from '../../../../../img/back-arrow.png'

function ServicesLibrary() {
  const {state} = useLocation()
  const navigate = useNavigate()

  const [activeTab, setActiveTab] = useState<any>('all')
  const [activeTabId, setActiveTabId] = useState<any>('')

  const [servicesTabList, setServicesTabList] = useState<any>([])
  const [tableData, setTableData] = useState<any>([])

  const [page, setPage] = useState<any>(1)
  const [limit, setLimit] = useState<any>(25)
  const [pageLimit, setPageLimit] = useState<any>(1)

  const handlePageClick = (event: any) => {
    setPage(event.selected + 1)
  }

  const [searchName, setSearchName] = useState<any>('')
  const [sortType, setSortType] = useState<any>(state?.sortType ? state?.sortType : 'A-Z')
  const [sortOptions, setSortOptions] = useState<any>([
    {value: 'A-Z', label: 'Alphabetic (A-Z)'},
    {value: 'Z-A', label: 'Alphabetic (Z-A)'},
    {value: 'latest', label: '(Latest - Oldest)'},
    {value: 'oldest', label: '(Oldest - Latest)'},
  ])

  const [allCount, setAllCount] = useState<any>()

  const [servicesList, setServicesList] = useState<any>([])
  const [servicesListOptions, setServicesListOptions] = useState<any>([])

  const [mainServices, setMainServices] = useState<any>([])
  const [mainServicesOptions, setMainServicesOptions] = useState<any>([])

  const [subServices, setSubServices] = useState<any>([])
  const [subServicesOptions, setSubServicesOptions] = useState<any>([])

  let service: any = localStorage.getItem('settingsService')
  const [isMainService, setIsMainService] = useState<any>(service == 'sub' ? false : true)

  const [mainSortType, setMainSortType] = useState<any>(
    state?.servicesSortType ? state?.servicesSortType : 'A-Z'
  )

  const [mode, setMode] = useState<any>('add')

  const [mainSortOptions, setMainSortOptions] = useState<any>([
    {value: 'List A-Z', label: 'List (A-Z)'},
    {value: 'List Z-A', label: 'List (Z-A)'},
    {value: 'Main Service A-Z', label: 'Main Service (A-Z)'},
    {value: 'Main Service Z-A', label: 'Main Service (Z-A)'},
  ])

  const [subSortType, setSubSortType] = useState<any>(
    state?.servicesSortType ? state?.servicesSortType : 'A-Z'
  )
  const [subSortOptions, setSubSortOptions] = useState<any>([
    {value: 'List A-Z', label: 'List (A-Z)'},
    {value: 'List Z-A', label: 'List (Z-A)'},
    {value: 'Main Service A-Z', label: 'Main Service (A-Z)'},
    {value: 'Main Service Z-A', label: 'Main Service (Z-A)'},
    {value: 'Sub A-Z', label: 'Sub Service (A-Z)'},
    {value: 'Sub Z-A', label: 'Sub Service (Z-A)'},
  ])

  const [showAddService, setShowAddService] = useState<any>(false)
  const [showChangeList, setShowChangeList] = useState<any>(false)

  let totalSelected: any = 0

  const [checkedValues, setCheckedValues] = useState<any>([])

  const [isLoading, setIsLoading] = useState<any>(true)
  const [serviceToEdit, setServiceToEdit] = useState<any>({})

  const searchContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#f0f0f0',
    borderRadius: '25px',
  }

  const searchIconStyle = {
    padding: '10px',
    color: 'gray',
  }

  const inputBaseStyle = {
    flex: 1,
    padding: '5px',
  }

  // React Select
  const customStyles = {
    container: (defaultStyles: any) => ({
      ...defaultStyles,
      width: '170px',
    }),

    option: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      color: state.isSelected ? '#000' : '#000',
      backgroundColor: state.isSelected ? '#c2d8d9' : '',
      ':hover': {
        backgroundColor: '#e0f5f0',
        color: '#000',
      },

      fontWeight: '500',
    }),

    menu: (defaultStyles: any) => ({
      ...defaultStyles,
      border: '0.1rem solid #007a59 ',
      marginTop: '0px',
      borderRadius: '0px',
      zIndex: '2',
    }),
    menuList: (defaultStyles: any) => ({
      ...defaultStyles,
      maxHeight: '200px',
    }),
    placeholder: (defaultStyles: any) => ({
      ...defaultStyles,
      fontStyle: 'Italic',
      zIndex: '1',
    }),

    input: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      color: ' #b5b5c3',
      fontWeight: '500',
    }),

    control: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      boxShadow: 'none',
      color: 'white',
      border: '0.1rem solid #bec3cb',
      borderRadius: '2px',
      padding: '0rem',
      height: '35px',
      ':hover': {
        borderColor: '#007a59',
        backgroundColor: '#fff',
      },
      ':focus': {
        borderColor: '#007a59 !important',
        backgroundColor: '#fff',
      },
      '::placeholder': {
        fontStyle: 'Italic',
      },
      minHeight: '35px',
    }),
    singleValue: (defaultStyles: any) => ({
      ...defaultStyles,
      color: '#5e6278',
      fontWeight: '600',
      zIndex: '1',
    }),
    valueContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: '0px 8px 2px 8px',
    }),
    indicatorContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: '0px 8px 2px 8px',
    }),
    indicatorsContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: '0px 8px 2px 8px',
    }),
  }

  //
  const getServicesListForTab = async () => {
    let body = {
      search: searchName,
      sortType: sortType,
    }

    await ApiPost(`corporate/maintenance_service_list/get`, body)
      .then((res) => {
        setServicesTabList(res?.data?.data?.maintenance_service_list_data)
      })
      .catch((err) => {
        ErrorToast(err?.message)
      })
  }

  //
  const getServicesListForDropdown = async () => {
    await ApiGet(`corporate/maintenance_service_list`)
      .then((res: any) => {
        let values: any = []
        res?.data?.data?.map((val: any, ind: any) => {
          values[ind] = {
            label: `${val?.name}`,
            value: `${val?._id}`,
          }
        })
        setServicesListOptions(values)

        setServicesList([])

        let temp = [...values]
        // Filter out the Properties with checkboxes checked
        const filteredList = temp.filter((list) => list?.value !== activeTabId)
        setServicesListOptions(filteredList)
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
      })
  }

  // get Main Services Table Data
  const getMainServices = async () => {
    setIsLoading(true)
    setTableData([])

    let lst: any = []
    servicesList?.map((v: any, ind: any) => {
      lst[ind] = v?.value
    })

    if (activeTab != 'all') lst[lst?.length] = activeTabId

    let main: any = []
    mainServices?.map((v: any, ind: any) => {
      main[ind] = v?.value
    })

    let sub: any = []
    subServices?.map((v: any, ind: any) => {
      sub[ind] = v?.value
    })

    let body = {
      page: page,
      limit: limit,
      maintenanceServiceListIds: lst,
      maintenanceMainServiceIds: main,
      sortType: mainSortType,
    }

    await ApiPost('corporate/maintenance_main_service/get', body)
      .then((res) => {
        let values: any = []
        for (let i = 0; i < res?.data?.data?.maintenance_main_service_data?.length; i++) {
          values[i] = false
        }
        setCheckedValues(values)
        setTableData(res?.data?.data?.maintenance_main_service_data)

        setPageLimit(res?.data?.data?.state?.page_limit)
        setTimeout(() => {
          setIsLoading(false)
        }, 2000)
      })
      .catch((err) => {
        setIsLoading(false)
        ErrorToast(err.message)
        if (err?.message === 'Your account has been suspended!') {
          window.location.pathname = '/auth'
          localStorage.clear()
        }
      })
  }

  //
  const getMainServicesForDropdown = async () => {
    await ApiGet(`corporate/maintenance_main_service`)
      .then((res: any) => {
        let values: any = []
        res?.data?.data?.map((val: any, ind: any) => {
          values[ind] = {
            label: `${val?.name}`,
            value: `${val?._id}`,
          }
        })
        setMainServicesOptions(values)
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
      })
  }

  // get SUB Services Table Data
  const getSubServices = async () => {
    setIsLoading(true)
    setTableData([])

    let lst: any = []
    servicesList?.map((v: any, ind: any) => {
      lst[ind] = v?.value
    })

    if (activeTab != 'all') lst[lst?.length] = activeTabId

    let main: any = []
    mainServices?.map((v: any, ind: any) => {
      main[ind] = v?.value
    })

    let sub: any = []
    subServices?.map((v: any, ind: any) => {
      sub[ind] = v?.value
    })

    let body = {
      page: page,
      limit: limit,
      maintenanceServiceListIds: lst,
      maintenanceMainServiceIds: main,
      maintenanceSubServiceIds: sub,
      sortType: subSortType,
    }

    await ApiPost('corporate/maintenance_sub_service/get', body)
      .then((res) => {
        let values: any = []
        for (let i = 0; i < res?.data?.data?.maintenance_sub_service_data.length; i++) {
          values[i] = false
        }
        setCheckedValues(values)
        setTableData(res?.data?.data?.maintenance_sub_service_data)

        setPageLimit(res?.data?.data?.state?.page_limit)
        setTimeout(() => {
          setIsLoading(false)
        }, 2000)
      })
      .catch((err) => {
        setIsLoading(false)
        ErrorToast(err.message)
        if (err?.message === 'Your account has been suspended!') {
          window.location.pathname = '/auth'
          localStorage.clear()
        }
      })
  }

  //
  const getSubServicesForDropdown = async () => {
    await ApiGet(`corporate/maintenance_sub_service`)
      .then((res: any) => {
        let values: any = []
        res?.data?.data?.map((val: any, ind: any) => {
          values[ind] = {
            label: `${val?.name}`,
            value: `${val?._id}`,
          }
        })
        setSubServicesOptions(values)
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
      })
  }

  //
  const getAllCount = () => {
    let url: any = ''
    if (isMainService) url = 'corporate/maintenance_main_service/count'
    else url = 'corporate/maintenance_sub_service/count'
    ApiGet(`${url}`)
      .then((res) => {
        setAllCount(res?.data?.data)
      })
      .catch((err) => ErrorToast(err.message))
  }

  //
  const updateService = (ser: any, val: any) => {
    const body = {
      id: ser?._id,
      isAppServiceEnable: val,
    }

    let url: any = ''
    if (isMainService) url = 'corporate/maintenance_main_service'
    else url = 'corporate/maintenance_sub_service'

    ApiPut(`${url}`, body)
      .then((res: any) => {
        if (isMainService) {
          getMainServices()
          getAllCount()
          getServicesListForTab()
        } else {
          getSubServices()
          getAllCount()
          getServicesListForTab()
        }
      })
      .catch((err) => ErrorToast(err.message))
  }

  //
  const deleteServices = () => {
    setIsLoading(true)
    let ids: any = []

    let i: any = 0

    checkedValues?.map((ch: any, ind: any) => {
      if (ch == true) {
        ids[i] = tableData[ind]?._id
        i++
      }
    })

    const body = {
      ids: ids,
    }

    let url: any = ''
    if (isMainService) url = `corporate/maintenance_main_service/delete`
    else url = `corporate/maintenance_sub_service/delete`

    ApiPost(`${url}`, body)
      .then((res: any) => {
        SuccessToast(res?.data?.message)
        if (isMainService) {
          getMainServices()
          getAllCount()
          getServicesListForTab()
        } else {
          getSubServices()
          getAllCount()
          getServicesListForTab()
        }
        setIsLoading(false)
      })
      .catch((err) => {
        ErrorToast(err.message)
        setIsLoading(false)
      })
  }

  //
  const deleteService = (ser: any) => {
    setIsLoading(true)
    let id: any = [ser?._id]
    const body = {
      ids: id,
    }
    let url: any = ''
    if (isMainService) url = `corporate/maintenance_main_service/delete`
    else url = `corporate/maintenance_sub_service/delete`

    ApiPost(`${url}`, body)
      .then((res: any) => {
        SuccessToast(res?.data?.message)
        if (isMainService) {
          getMainServices()
          getAllCount()
          getServicesListForTab()
        } else {
          getSubServices()
          getAllCount()
          getServicesListForTab()
        }
        setIsLoading(false)
      })
      .catch((err) => {
        ErrorToast(err.message)
        setIsLoading(false)
      })
  }

  useEffect(() => {
    getServicesListForTab()
    // getServicesListForDropdown()
  }, [searchName, sortType, isMainService])

  useEffect(() => {
    if (isMainService) {
      getMainServices()
    } else {
      getSubServices()
    }
  }, [
    page,
    servicesList,
    mainServices,
    subServices,
    mainSortType,
    subSortType,
    isMainService,
    activeTabId,
  ])

  useEffect(() => {
    getServicesListForDropdown()
  }, [])

  useEffect(() => {
    getAllCount()
    getMainServicesForDropdown()
    getSubServicesForDropdown()
  }, [isMainService])

  return (
    <>
      <div className='mx-auto d-flex gap-5 flex-column p-0 m-0 px-2' id='kt_app_main'>
        <div className='row'>
          <div className='d-flex align-items-center gap-3'>
          <span
              className='rounded-circle'
              style={{cursor: 'pointer'}}
              onClick={() => navigate(`/settings`)}
            >
              <img src={backArrow} style={{stroke: 'red'}} height='14px' width='14px' />
            </span>
            <h2 className='page-heading  m-0 head-text'>
              <b>Services Library</b>
            </h2>
          </div>
        </div>

        {/*  */}
        <div className='card card-flush py-5 px-4'>
          <div
            className='d-flex align-items-center py-5 pt-0 gy-3 mx-0 mt-0 xyz tst'
            // style={{ justifyContent: "space-between" }}
          >
            <label className='head-text me-5 pe-3' style={{fontWeight: '600'}}>
              Search{' '}
            </label>
            {/* Property */}
            <div className='me-4'>
              <Paper component='form' style={searchContainerStyle}>
                <InputBase
                  placeholder='List Name...'
                  style={inputBaseStyle}
                  inputProps={{'aria-label': 'search'}}
                  value={searchName}
                  onChange={(e: any) => setSearchName(e.target.value)}
                />
                <IconButton style={searchIconStyle} aria-label='search'>
                  <SearchIcon />
                </IconButton>
              </Paper>
            </div>

            {/* Type */}
            <div className='me-4'>
              <Select
                styles={customStyles}
                isSearchable={false}
                options={sortOptions}
                name='sortType'
                onChange={(e: any) => {
                  console.log(e)
                  setSortType(e.value)
                }}
                placeholder={'Sort'}
              />
            </div>
            <div className='d-flex ms-auto align-items-center'>
              <button
                type='button'
                className='btn fw-bold manage-list-btn ps-2 pe-1 ms-3'
                onClick={() => {
                  navigate(`/settings/maintenance/services/manage-lists`)
                }}
              >
                <img src={manageIcon} height={18} width={18} className='me-2' /> Manage Lists
              </button>
            </div>
          </div>

          <div className='d-flex flex-wrap mt-3' style={{maxHeight: '300px', overflowY: 'auto'}}>
            <div className='me-5 mb-5'>
              <div
                className={
                  activeTab == 'all'
                    ? 'tenancy-green-card d-flex align-items-center'
                    : 'service-card d-flex align-items-center'
                }
                onClick={() => {
                  setActiveTab('all')
                  setActiveTabId('')
                  setPage(1)
                }}
              >
                <div
                  className=''
                  style={{
                    fontWeight: '700',
                    width: 'fit-content',
                  }}
                ></div>
                <div className='px-5'>
                  <h2 className={activeTab == 'all' ? 'mb-1 text-white' : 'mb-1 select-color'}>
                    {allCount ? allCount?.toLocaleString('en-US', {}) : 0}
                  </h2>
                  <h5 className={activeTab == 'all' ? 'mb-0 text-white' : 'mb-0 select-color'}>
                    All
                  </h5>
                </div>
              </div>
              {activeTab == 'all' && <div className='active-filter'></div>}
            </div>

            {/* Tabs tab */}
            {servicesTabList?.length > 0 &&
              servicesTabList?.map((tab: any, index: any) => {
                return (
                  <div className='me-5 mb-3'>
                    <div
                      className={
                        activeTab == tab?.name
                          ? 'tenancy-green-card d-flex align-items-center'
                          : 'service-card d-flex align-items-center'
                      }
                      style={{
                        backgroundColor: tab?.colorCode,
                      }}
                      onClick={() => {
                        setActiveTab(tab?.name)
                        setActiveTabId(tab?._id)
                        console.log(tab?._id)
                        setPage(1)
                      }}
                    >
                      <div
                        className=''
                        style={{
                          fontWeight: '700',
                          width: 'fit-content',
                        }}
                      ></div>

                      <div className='px-5'>
                        <h2
                          className={
                            activeTab == tab?.name ? 'mb-1 text-white' : 'mb-1 select-color'
                          }
                        >
                          {isMainService ? (
                            <>
                              {tab?.mainServiceCount
                                ? tab?.mainServiceCount?.toLocaleString('en-US', {})
                                : 0}
                            </>
                          ) : (
                            <>
                              {tab?.subServiceCount
                                ? tab?.subServiceCount?.toLocaleString('en-US', {})
                                : 0}
                            </>
                          )}
                        </h2>
                        <OverlayTrigger
                          placement='top'
                          delay={{show: 250, hide: 400}}
                          overlay={
                            <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                              {tab?.name}
                            </Tooltip>
                          }
                        >
                          <div>
                            <h5
                              className={
                                activeTab == tab?.name ? 'mb-0 text-white' : 'mb-0 select-color'
                              }
                            >
                              {tab?.name?.substring(0, 20)}
                              {tab?.name?.length > 23 && '..'}
                            </h5>
                          </div>
                        </OverlayTrigger>
                      </div>
                    </div>
                    {activeTab == tab?.name && (
                      <div
                        className='active-filter'
                        style={{
                          backgroundColor: tab?.colorCode,
                        }}
                      ></div>
                    )}
                  </div>
                )
              })}
          </div>
        </div>

        {/*  */}
        <div className=' ps-0 row mt-2'>
          <div className='col-4'>
            <h2 className='page-heading  m-0 text-capitalize pt-2'>
              <b>{activeTab}</b>
            </h2>
          </div>
          <div className='col-4'>
            <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2 xyz'>
              <li className='nav-item'>
                <a
                  className={`nav-link text-active-primary pb-2 me-5 px-5 ${
                    isMainService && 'active'
                  }`}
                  data-bs-toggle='tab'
                  onClick={() => {
                    setIsMainService(true)
                    localStorage.setItem('settingsService', 'main')
                  }}
                >
                  Main Services
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className={`nav-link text-active-primary pb-2 me-5 px-5 ${
                    !isMainService && 'active'
                  }`}
                  data-bs-toggle='tab'
                  onClick={() => {
                    setIsMainService(false)
                    localStorage.setItem('settingsService', 'sub')
                  }}
                >
                  Sub Services
                </a>
              </li>
            </ul>
          </div>
          <div className='col-4 text-end'>
            {checkedValues?.length > 0 && (
              <>
                {checkedValues?.map((ch: any, ind: any) => {
                  if (ch == true) totalSelected = totalSelected + 1
                  return <></>
                })}
              </>
            )}

            <div className='d-flex '>
              {totalSelected > 0 && (
                <>
                  <div className='d-flex ms-auto'>
                    <h4 className='my-2' style={{color: 'black'}}>
                      <label className='labl-gry '>
                        {' '}
                        <i>Total Selected </i>
                      </label>
                    </h4>
                    <span className='ms-5 ps-1 my-2 me-4'>
                      <b>{totalSelected}</b>
                    </span>
                  </div>
                  {/* Delete CTA */}
                  <button
                    type='button'
                    className='btn btn-sm fw-bold  ms-3 red-hollow-btn'
                    onClick={() => {
                      swal
                        .fire({
                          html: ` 
                                        <div class='fs-3'> <b>Delete ${
                                          isMainService ? 'Main ' : 'Sub '
                                        }Services</></div>
                                        <div class='fs-5 mt-4'>You are about to delete the selected services.</div>
                                                  <div class='fs-5 mt-4'> Once deleted, ${
                                                    isMainService &&
                                                    'all sub-services listed under the main service will also be deleted and '
                                                  } you will not be able to view them nor select them in future maintenance requests. All past and current requests will not be affected.
                                                  </div>
                                                <div class='fs-5 mt-4'> Are you sure you want to continue? </div>`,
                          // icon: 'warning',
                          showConfirmButton: true,
                          confirmButtonColor: '#35475e',
                          confirmButtonText: 'Yes',
                          showCancelButton: true,
                          cancelButtonText: 'No',
                          cancelButtonColor: '#fff',
                          customClass: {
                            confirmButton: 'custom-confirm-button',
                            cancelButton: 'custom-cancel-button',
                            popup: 'custom-popup',
                          },
                          reverseButtons: true, // Reverse the order of buttons reverseButtons: true,
                        })
                        .then((res: any) => {
                          if (res.isConfirmed) {
                            deleteServices()
                          }
                        })
                    }}
                    disabled={isLoading}
                  >
                    <img src={trashImg} height={18} width={18} style={{marginRight: '7px'}} />{' '}
                    Delete
                  </button>
                  {/* Add to List CTA */}
                  {/* {isMainService && ( */}
                    <button
                      type='button'
                      className={`btn btn-sm fw-bold  ms-3 px-2 manage-list-btn ${!isMainService && 'main-service-btn'}`}
                      onClick={() => {
                        setShowChangeList(true)
                      }}
                      disabled={isLoading}
                    >
                      <img src={manageIcon} height={18} width={18} className='me-4' />{' '}
                      {isMainService ? 'Change List' : 'Change Main Service'}
                    </button>
                  {/* )} */}
                </>
              )}
            </div>
          </div>
        </div>

        {/* Filters */}
        <div
          className='d-flex align-items-center py-5 pt-2 gy-3 mx-0 mt-4 xyz tst'
          // style={{ justifyContent: "space-between" }}
        >
          <label className='head-text me-5 pe-3' style={{fontWeight: '600'}}>
            Search{' '}
          </label>

          {/* List */}
          <div className='me-4  property test'>
            <div
              className={`multi-select-container ${
                servicesList.length === 0 ? 'no-value list' : 'has-value'
              }`}
            >
              <MultiSelect
                options={servicesListOptions}
                value={servicesList}
                onChange={setServicesList}
                labelledBy='List'
                overrideStrings={{
                  allItemsAreSelected: 'All Lists', // Custom text for when all items are selected
                }}
              />
            </div>
          </div>

          {/* Main Service*/}
          <div className='me-4  property test'>
            <div
              className={`multi-select-container ${
                mainServices.length === 0 ? 'no-value main-service' : 'has-value'
              }`}
            >
              <MultiSelect
                options={mainServicesOptions}
                value={mainServices}
                onChange={setMainServices}
                labelledBy='Main Service'
                overrideStrings={{
                  allItemsAreSelected: 'All Main Service', // Custom text for when all items are selected
                }}
              />
            </div>
          </div>

          {/* Sub Service*/}
          {!isMainService && (
            <div className='me-4  property test'>
              <div
                className={`multi-select-container ${
                  subServices.length === 0 ? 'no-value sub-service' : 'has-value'
                }`}
              >
                <MultiSelect
                  options={subServicesOptions}
                  value={subServices}
                  onChange={setSubServices}
                  labelledBy='Sub Service'
                  overrideStrings={{
                    allItemsAreSelected: 'All Sub Service', // Custom text for when all items are selected
                  }}
                />
              </div>
            </div>
          )}

          {/* Sort Type */}
          <div className='ms-auto'>
            <Select
              styles={customStyles}
              isSearchable={false}
              options={isMainService ? mainSortOptions : subSortOptions}
              name='sortType'
              onChange={(e: any) => {
                if (isMainService) setMainSortType(e.value)
                else setSubSortType(e.value)
              }}
              placeholder={'Sort'}
            />
          </div>
          <div>
            <button
              type='button'
              className='btn btn-sm fw-bold green-submit-btn status-w-120 ps-2 pe-1 ms-4'
              onClick={() => {
                setMode('add')
                setShowAddService(true)
              }}
              style={{height: '34px'}}
            >
              <img src={addWhite} height={18} width={18} className='me-2' /> New Service
            </button>
          </div>
        </div>

        {/* Table Data */}
        <div className='card card-flush py-5  px-7'>
          {' '}
          {/* container-xxl */}
          <div className='app-main flex-column flex-row-fluid' id='------------kt_app_main'>
            <div className='table-responsive '>
              {/*  */}

              <table
                className='table align-start table-row-dashed fs-8 gy-5 '
                id='kt_ecommerce_sales_table'
              >
                <thead className='fs-7'>
                  <th className='text-start pe-2 test' style={{width: '15px'}}>
                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-0 pe-5'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        id='vacant0'
                        name='none'
                        checked={
                          totalSelected == tableData?.length && tableData?.length != 0
                            ? true
                            : false
                        }
                        onChange={(e: any) => {
                          let values = [...checkedValues]
                          if (e.target.checked) {
                            for (let i = 0; i < tableData?.length; i++) {
                              values[i] = true
                            }
                          } else {
                            for (let i = 0; i < tableData?.length; i++) {
                              values[i] = false
                            }
                          }
                          setCheckedValues(values)
                        }}
                      />
                    </div>
                  </th>
                  {!isMainService && <th className='text-start min-w-150px pe-2'>Sub Service</th>}
                  <th className='text-start min-w-150px pe-2'>Main Service</th>
                  <th className='text-start min-w-100px pe-2'>List</th>
                  <th className='text-start min-w-100px pe-2'>
                    Apply Service
                    <span>
                      <OverlayTrigger
                        placement='top'
                        delay={{show: 250, hide: 200}}
                        overlay={
                          <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                            {' '}
                            You can enable or disable this service from being selected in
                            maintenance requests. This will apply to all sub services listed under
                            the selected main service.
                          </Tooltip>
                        }
                      >
                        <span>
                          {' '}
                          <AiOutlineExclamationCircle
                            color='#35475e '
                            fontSize={14}
                            fontWeight={600}
                            className='cursor-pointer'
                            style={{cursor: 'pointer', marginBottom: '2px'}}
                          />
                        </span>
                      </OverlayTrigger>
                    </span>
                  </th>
                  <th className='text-center min-w-150px'></th>
                </thead>
                <tbody className='fw-semibold text-gray-600'>
                  {tableData?.length > 0 ? (
                    tableData?.map((service: any, i: any) => {
                      return (
                        <tr>
                          <td className='text-start ps-0 test'>
                            <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-0 pe-5'>
                              <input
                                className='form-check-input'
                                type='checkbox'
                                id='vacant0'
                                name='none'
                                checked={checkedValues[i] == true ? true : false}
                                onChange={(e: any) => {
                                  const values = [...checkedValues]
                                  values[i] = !values[i]
                                  setCheckedValues(values)
                                }}
                              />
                            </div>
                          </td>

                          {!isMainService && (
                            <td
                              data-kt-ecommerce-order-filter='order_id'
                              className='text-start ps-0 cursor-pointer ps-0 pe-5'
                            >
                              {(page - 1) * limit + (i + 1)}
                              {'. '}
                              {service?.name ? service?.name : '-'}
                            </td>
                          )}

                          <td className='text-start ps-0 cursor-pointer ps-0 pe-5'>
                            {isMainService ? (
                              <>
                                {(page - 1) * 10 + (i + 1)}
                                {'. '}
                                {service?.name ? service?.name : '-'}
                              </>
                            ) : (
                              <>
                                {service?.maintenance_main_service?.name
                                  ? service?.maintenance_main_service?.name
                                  : '-'}
                              </>
                            )}
                          </td>

                          <td
                            className='text-start ps-0 cursor-pointer ps-0 pe-5'
                            data-order='Denied'
                          >
                            {service?.maintenance_service_list?.name
                              ? service?.maintenance_service_list?.name
                              : '-'}
                          </td>
                          <td
                            className='text-start ps-0 cursor-pointer ps-2 pe-5'
                            data-order='Denied'
                          >
                            <Switch
                              onChange={(e: any) => {
                                updateService(service, e)
                              }}
                              uncheckedIcon={false}
                              checkedIcon={false}
                              checked={service?.isAppServiceEnable ? true : false}
                              onColor='#00b9a9'
                              offColor='#b0bec5'
                            />
                          </td>
                          <td className='text-start ps-0 cursor-pointer  ps-2 pe-5'>
                            <div className='d-flex'>
                              <>
                                <img
                                  src={pencil}
                                  alt=''
                                  height={18}
                                  width={18}
                                  className='ms-4 cursor-pointer'
                                  onClick={() => {
                                    setMode('edit')
                                    setServiceToEdit(service)
                                    setShowAddService(true)
                                  }}
                                />
                                <img
                                  src={trashImg}
                                  height={19}
                                  width={19}
                                  className='ms-5 cursor-pointer'
                                  onClick={() => {
                                    swal
                                      .fire({
                                        html: ` 
                                        <div class='fs-3'> <b>Delete ${
                                          isMainService ? 'Main ' : 'Sub '
                                        } Service</></div>
                                        <div class='fs-5 mt-4'>You are about to delete the selected service.</div>
                                                  <div class='fs-5 mt-4'> Once deleted, ${
                                                    isMainService &&
                                                    'all sub-services listed under the main service will also be deleted and '
                                                  } you will not be able to view it nor select it in future maintenance requests. All past and current requests will not be affected.
                                                  </div>
                                                <div class='fs-5 mt-4'> Are you sure you want to continue? </div>`,
                                        // icon: 'warning',
                                        showConfirmButton: true,
                                        confirmButtonColor: '#35475e',
                                        confirmButtonText: 'Yes',
                                        showCancelButton: true,
                                        cancelButtonText: 'No',
                                        cancelButtonColor: '#fff',
                                        customClass: {
                                          confirmButton: 'custom-confirm-button',
                                          cancelButton: 'custom-cancel-button',
                                          popup: 'custom-popup',
                                        },
                                        reverseButtons: true, // Reverse the order of buttons reverseButtons: true,
                                      })
                                      .then((res: any) => {
                                        if (res.isConfirmed) {
                                          deleteService(service)
                                        }
                                      })
                                  }}
                                />{' '}
                              </>
                            </div>
                          </td>
                        </tr>
                      )
                    })
                  ) : (
                    <tr>
                      {!isLoading ? (
                        <td colSpan={5} className='text-center'>
                          <img src={noData} alt='' width={350} />
                        </td>
                      ) : (
                        <td colSpan={isMainService ? 5 : 6} className='text-center'>
                          <div
                            className='d-flex justify-content-center align-items-center w-100'
                            style={{height: '25vh'}}
                          >
                            <CircularProgress style={{color: '#007a59'}} />
                          </div>
                        </td>
                      )}
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            <div className='row mb-5'>
              <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
              <div className='row mt-5'>
                <div className='col-sm-12 col-md-12 d-flex align-items-center justify-content-center justify-content-md-end mt-5'>
                  <div
                    className='dataTables_paginate paging_simple_numbers'
                    id='kt_ecommerce_sales_table_paginate'
                  >
                    <ReactPaginate
                      breakLabel='...'
                      nextLabel='>'
                      pageClassName='paginate_button page-item +'
                      pageLinkClassName='page-link'
                      containerClassName='pagination'
                      activeClassName='active'
                      previousClassName='paginate_button page-item previous'
                      nextClassName='paginate_button page-item next'
                      previousLinkClassName='page-link'
                      nextLinkClassName='page-link'
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={5}
                      pageCount={pageLimit}
                      forcePage={page - 1}
                      previousLabel='<'
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Add/ Edit Service Modal */}
      {showAddService && (
        <AddNewService
          show={showAddService}
          handleClose={() => {
            setShowAddService(false)
          }}
          type={isMainService ? 'main' : 'sub'}
          updateList={(data: any) => {
            if (isMainService) {
              getMainServices()
              getMainServicesForDropdown()
            } else {
              getSubServices()
              getSubServicesForDropdown()
            }
            getServicesListForTab()
            getAllCount()
            setShowAddService(false)
          }}
          mode={mode}
          serviceObj={serviceToEdit}
          tab={activeTab}
          tabId={activeTabId}
        />
      )}

      {/* Change List Modal */}
      {showChangeList && (
        <ChangeList
          show={showChangeList}
          handleClose={() => {
            setShowChangeList(false)
          }}
          updateList={(data: any) => {
            if (isMainService) {
              getMainServices()
              getMainServicesForDropdown()
            } else {
              getSubServices()
              getSubServicesForDropdown()
            }
            getServicesListForTab()
            getAllCount()
            setShowChangeList(false)
          }}
          totalSelectedRecords={totalSelected}
          records={tableData}
          selectedRecords={checkedValues}
          type={isMainService ? 'main' : 'sub'}
        />
      )}
    </>
  )
}

export default ServicesLibrary
