import React, {useState, useEffect} from 'react'
import {Modal, Row, Col} from 'react-bootstrap'
import {CircularProgress} from '@mui/material'
import moment from 'moment'
import {formatAmount, getFileIcon} from '../../../../../../../Utilities/utils'
import {Bucket} from '../../../../../../../apiCommon/helpers/API/ApiData'
import emailImg from '../../../../../../../img/mailnotification.svg'

import {Fancybox as fancybox} from '@fancyapps/ui'
import '@fancyapps/ui/dist/fancybox/fancybox.css'

interface ButtonProps {
  show: any
  handleClose: any
  receiptData: any
  isShare?: boolean
  setIsShare?: any
}

const ConfirmationPopup = ({show, handleClose, receiptData, isShare, setIsShare}: ButtonProps) => {
  const userData: any = localStorage.getItem('userData')
  const user = JSON.parse(userData)

  const [isLoading, setIsLoading] = useState<any>(false)

  return (
    <div className='schedule'>
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-650px mx-auto'
        show={show}
        onHide={() => {
          handleClose()
        }}
        backdrop={true}
        size='sm'
      >
        <div
          className='modal-body py-lg-7 px-lg-7 modal-body-without-header'
          style={{
            maxHeight: '500px',
            overflow: 'auto',
          }}
        >
          <div className='d-flex align-items-center justify-content-center'>
            <h2 className='mb-0 pt-1'>
              <b>Create Credit Note</b>
            </h2>
          </div>

          <div className='d-flex align-items-center' style={{height: '0px', marginTop: '-10px'}}>
            <label
              className='head-text fs-2 cursor-pointer ms-auto'
              style={{fontWeight: '700'}}
              onClick={() => {
                handleClose()
              }}
            >
              X
            </label>
          </div>

          <div className='d-flex align-items-center justify-content-center text-center mx-3 mt-7'>
            <p className='mb-0 pt-1 labl-gry fs-4 fnt-500'>
              Once the credit note is created, the credit amount will directly reflect in your
              tenant’s outstanding balance.
            </p>
          </div>

          <div className='d-flex align-items-center justify-content-center text-center mx-5 mt-4'>
            <p className='mb-0 pt-1 labl-gry fs-4 fnt-500'>
              A copy of your credit note will be shared with your tenant on the tenant portal.
            </p>
          </div>

          <div className='d-flex  align-items-center mt-7 mx-7'>
            <p className='mb-0 fnt-500 fs-4'>
              {'Payor:   '}
              <span className='mb-0 ms-2 fnt-700'> {'Mukund Khunt'}</span>
            </p>{' '}
            <p className='mb-0 fnt-500 fs-4 ms-auto'>
              {'Credit Note:   '}
              <span className='mb-0 ms-2 fnt-700'> {'CN_101'}</span>
            </p>{' '}
          </div>

          <div className='separator my-3 mt-5'></div>

          {/*  */}
          <div className='d-flex align-items-center  mx-5 mt-4'>
            <p className='mb-0 pt-1 labl-gry fs-4 fnt-500'>Share Credit Note via:</p>
          </div>

          {/*  */}
          <div className='d-flex  align-items-start mt-7 mx-5 test'>
            <div className='d-flex align-items-center form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-0 pe-2 mt-2'>
              <input
                className='form-check-input me-3'
                type='checkbox'
                id='vacant0'
                name='none'
                checked={isShare}
                onChange={(e: any) => {
                  setIsShare(!isShare)
                }}
              />
            </div>

            <div className='position-relative'>
              {!isShare && <div className='requestBoxOpacity z-index-999 border-radius-5px'></div>}
              <div>
                <p className='mb-0 fnt-500 fs-4 mt-2'>{'Email'}</p>{' '}
                <p className='mb-0 pt-2 labl-gry fs-4 mt-4'>
                  <i>
                    Email will be sent from <u>(finance@harmonyre.ae)</u>.
                  </i>
                </p>
              </div>
            </div>
            <div className='position-relative ms-auto'>
              {!isShare && <div className='requestBoxOpacity z-index-999 border-radius-5px'></div>}

              <div className='ms-auto text-center'>
                <img src={emailImg} height={32} width={32} />
                <button
                  type='button'
                  className='btn btn-sm fw-bold select-btn px-5 btn-fit-content mt-2'
                  onClick={() => {}}
                  disabled={false}
                >
                  View Email
                </button>
              </div>
            </div>
          </div>

          {/*  */}
          <div className='d-flex  align-items-center justify-content-center text-center my-15 mx-7'>
            <button
              type='button'
              className='btn btn-sm fw-bold ms-3 green-submit-btn ps-3 status-w-200  d-flex  align-items-center justify-content-center text-center'
              onClick={() => {}}
              disabled={false}
            >
              {isShare ? 'Create & Share' : 'Create'}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default ConfirmationPopup
