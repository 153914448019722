import {useEffect, useState} from 'react'
import {MultiSelect} from 'react-multi-select-component'
import noData from '../../../../img/NoData1.svg'
import tenantUserFilledIconBlue from '../../../../img/tenant-user-filled-blue.png'
import {useNavigate} from 'react-router-dom'
import starYellow from '../../../../img/starnotification.svg'
import moment from 'moment'
import {ApiPost, ApiGet} from '../../../../apiCommon/helpers/API/ApiData'
import ReactPaginate from 'react-paginate'
import {ErrorToast} from '../../../../apiCommon/helpers/Toast'

interface ButtonProps {
  updateCount?: any
}
const CRMResidents = ({updateCount}: ButtonProps) => {
  const getCRMFilters: any = localStorage.getItem('crmResidentsFiltersOfMaintenance')
  const crmFilters = JSON.parse(getCRMFilters)

  const [developmentLists, setDevelopmentLists] = useState<any>([])
  const [selectedDevelopments, setSelectedDevelopments] = useState<any>(
    crmFilters ? crmFilters?.development : []
  )
  const [refreshSelectedDevs, setRefreshSelectedDevs] = useState<any>()

  const [unitsLists, setUnitsLists] = useState<any>([])
  const [selectedUnits, setSelectedUnits] = useState<any>(crmFilters ? crmFilters?.units : [])
  const [refreshSelectedUnits, setRefreshSelectedUnits] = useState<any>(false)

  const [tenantsLists, setTenantsLists] = useState<any>([])
  const [selectedTenants, setSelectedTenants] = useState<any>(crmFilters ? crmFilters?.tenants : [])
  const [refreshSelectedTenants, setRefreshSelectedTenants] = useState<any>(false)

  const [page, setPage] = useState<any>(1)
  const [limit, setLimit] = useState<any>(25)
  const [pageLimit, setPageLimit] = useState<any>(1)

  const [requestTypesLists, setRequestTypesLists] = useState<any>([
    {label: 'Complaints', value: 1},
    {label: 'Maintenance', value: 0},
    {label: 'General Requests', value: 2},
  ])
  const [selectedRequestTypes, setSelectedRequestTypes] = useState<any>(
    crmFilters ? crmFilters?.reqType : []
  )
  const [refreshSelectedRequestTypes, setRefreshSelectedRequestTypes] = useState<any>(false)

  const [ticketStatusesLists, setTicketStatusesLists] = useState<any>([
    {label: 'Open', value: 0},
    {label: 'Closed', value: 1},
  ])
  const [selectedTicketStatuses, setSelectedTicketStatuses] = useState<any>(
    crmFilters ? crmFilters?.ticketStatus : []
  )
  const [refreshselectedTicketStatuses, setRefreshselectedTicketStatuses] = useState<any>(false)

  const [priorityLists, setPriorityLists] = useState<any>([
    {label: 'Low', value: 0},
    {label: 'Medium', value: 1},
    {label: 'High', value: 2},
  ])
  const [selectedPriority, setSelectedPriority] = useState<any>(
    crmFilters ? crmFilters?.priority : []
  )
  const [refreshSelectedPriority, setRefreshSelectedPriority] = useState<any>(false)

  const navigate = useNavigate()

  const [tableData, setTableData] = useState<any>([
    // {
    //   id: 1,
    //   type: 0,
    //   tenant: [
    //     {
    //       firstName: '',
    //       lastName: '',
    //     },
    //   ],
    //   subject: 'I have a leak in the quest room',
    //   priority: 1,
    //   status: 0,
    //   duration: '11 hours',
    //   assignedTo: [],
    //   unit: [],
    //   createdAt: null,
    //   updatedAt: null,
    //   ratting: 3,
    // },
  ])

  const [refreshData, setRefreshData] = useState<any>(false)

  //
  const handlePageClick = (event: any) => {
    setPage(event.selected + 1)
    getCRMTickets(event.selected + 1)
  }

  //
  const CustomValueRenderer = (selected: any, _options: any) => {
    return selected.length > 0 ? `${selected.length} Selected` : 'Select'
  }

  //
  const goToRequest = (itm: any) => {
    navigate(`/maintenance-resident-request/${itm?._id}`)
  }

  //
  const getCRMTickets = (pageVal:any) => {
    let buildIds: any = []
    let buildIdsInd: any = 0
    let commIds: any = []
    let commIdsInd: any = 0

    selectedDevelopments?.length > 0 &&
      selectedDevelopments?.map((dev: any, ind: any) => {
        if (dev?.devType == 'build') {
          buildIds[buildIdsInd] = dev?.value
          buildIdsInd++
        } else if (dev?.devType == 'comm') {
          commIds[commIdsInd] = dev?.value
          commIdsInd++
        }
      })

    let unitIds: any = []
    let unitIdsInd: any = 0

    selectedUnits?.length > 0 &&
      selectedUnits?.map((dev: any, ind: any) => {
        unitIds[unitIdsInd] = dev?.value
        unitIdsInd++
      })

    let tenantIds: any = []
    let tenantIdsInd: any = 0

    selectedTenants?.length > 0 &&
      selectedTenants?.map((dev: any, ind: any) => {
        tenantIds[tenantIdsInd] = dev?.value
        tenantIdsInd++
      })

    let reqType: any = []
    let reqTypeInd: any = 0

    selectedRequestTypes?.length > 0 &&
      selectedRequestTypes?.map((dev: any, ind: any) => {
        reqType[reqTypeInd] = dev?.value
        reqTypeInd++
      })

    let ticketSt: any = []
    let ticketStInd: any = 0

    selectedTicketStatuses?.length > 0 &&
      selectedTicketStatuses?.map((dev: any, ind: any) => {
        ticketSt[ticketStInd] = dev?.value
        ticketStInd++
      })

    let ticketPr: any = []
    let ticketPrInd: any = 0

    selectedPriority?.length > 0 &&
      selectedPriority?.map((dev: any, ind: any) => {
        ticketPr[ticketPrInd] = dev?.value
        ticketPrInd++
      })

    const body = {
      limit: limit,
      page: pageVal,
      buildingIds: buildIds,
      communityIds: commIds,
      unitIds: unitIds,
      tenantIds: tenantIds,
      crmTicketTypes: reqType,
      crmTicketStatus: ticketSt,
      crmTicketPriorities: ticketPr,
      crmAssignRoleTypes:[1]
    }
    ApiPost(`corporate/crm_ticket/get`, body)
      .then((res: any) => {
        setTableData(res?.data?.data?.crm_ticket)

        setPageLimit(res?.data?.data?.state?.page_limit)
        updateCount(res?.data?.data?.state?.data_count)
      })
      .catch((err) => {
        ErrorToast(err.message)
        if (err?.message === 'Your account has been suspended!') {
          window.location.pathname = '/auth'
          localStorage.clear()
        }
      })
  }

  //
  useEffect(() => {
    if (refreshData != false) {
      setPage(1)
      getCRMTickets(1)
    }
  }, [refreshData])

  

  // Dropdowns
  const getFiltersDropdowns = () => {
    const body = {
      crmTicketBuildingIdsFilter: [],
      crmTicketCommunityIdsFilter: [],
      isAllowEmpty: true,
      // crmTicketBodyTypes:[0],
      crmAssignRoleBodyTypes:[1]
    }

    ApiPost(`corporate/crm_ticket/filter/development`, body)
      .then((res: any) => {
        // RequestType
        let selectedRequestTypeValues: any = []
        if (
          res?.data?.data?.crmTicketTypes?.[0] == 0 ||
          res?.data?.data?.crmTicketTypes?.[1] == 0 ||
          res?.data?.data?.crmTicketTypes?.[2] == 0
        )
          selectedRequestTypeValues?.push({label: 'Maintenance', value: 0})
        if (
          res?.data?.data?.crmTicketTypes?.[0] == 1 ||
          res?.data?.data?.crmTicketTypes?.[1] == 1 ||
          res?.data?.data?.crmTicketTypes?.[2] == 1
        )
          selectedRequestTypeValues?.push({label: 'Complaints', value: 1})
        if (
          res?.data?.data?.crmTicketTypes?.[0] == 2 ||
          res?.data?.data?.crmTicketTypes?.[1] == 2 ||
          res?.data?.data?.crmTicketTypes?.[2] == 2
        )
          selectedRequestTypeValues?.push({label: 'General Requests', value: 2})
        setRequestTypesLists(selectedRequestTypeValues)

        // TicketStatus
        let selectedTicketStatusValues: any = []
        if (
          res?.data?.data?.crmTicketStatus?.[0] == 0 ||
          res?.data?.data?.crmTicketStatus?.[1] == 0
        )
          selectedTicketStatusValues?.push({label: 'Open', value: 0})
        if (
          res?.data?.data?.crmTicketStatus?.[0] == 1 ||
          res?.data?.data?.crmTicketStatus?.[1] == 1
        )
          selectedTicketStatusValues?.push({label: 'Closed', value: 1})
        setTicketStatusesLists(selectedTicketStatusValues)

        // Priority
        let selectedPriorityValues: any = []
        if (
          res?.data?.data?.crmTicketPriorities?.[0] == 0 ||
          res?.data?.data?.crmTicketPriorities?.[1] == 0 ||
          res?.data?.data?.crmTicketPriorities?.[2] == 0
        )
          selectedPriorityValues?.push({label: 'Low', value: 0})
        if (
          res?.data?.data?.crmTicketPriorities?.[0] == 1 ||
          res?.data?.data?.crmTicketPriorities?.[1] == 1 ||
          res?.data?.data?.crmTicketPriorities?.[2] == 1
        )
          selectedPriorityValues?.push({label: 'Medium', value: 1})
        if (
          res?.data?.data?.crmTicketPriorities?.[0] == 2 ||
          res?.data?.data?.crmTicketPriorities?.[1] == 2 ||
          res?.data?.data?.crmTicketPriorities?.[2] == 2
        )
          selectedPriorityValues?.push({label: 'High', value: 2})
        setPriorityLists(selectedPriorityValues)
      })
      .catch((err) => {
        ErrorToast(err.message)
      })
  }

  useEffect(() => {
    getFiltersDropdowns()
  }, [])

  // Filters Dropdown - Developments
  const getFiltersForDevelopment = () => {
    let buildIds: any = []
    let buildIdsInd: any = 0
    let commIds: any = []
    let commIdsInd: any = 0

    selectedDevelopments?.length > 0 &&
      selectedDevelopments?.map((dev: any, ind: any) => {
        if (dev?.devType == 'build') {
          buildIds[buildIdsInd] = dev?.value
          buildIdsInd++
        } else if (dev?.devType == 'comm') {
          commIds[commIdsInd] = dev?.value
          commIdsInd++
        }
      })

    const body = {
      crmTicketBuildingIdsFilter: buildIds,
      crmTicketCommunityIdsFilter: commIds,
      isAllowEmpty: false,
      // crmTicketBodyTypes:[0],
      crmAssignRoleBodyTypes:[1]
    }

    ApiPost(`corporate/crm_ticket/filter/development`, body)
      .then((res: any) => {
        updateFilters(res)
      })
      .catch((err) => {
        ErrorToast(err.message)
      })
  }

  useEffect(() => {
    getFiltersForDevelopment()
  }, [refreshSelectedDevs])

  // Filters Dropdown - Units Lists
  const getFiltersForUnits = () => {
    let unitIds: any = []
    let unitIdsInd: any = 0

    selectedUnits?.length > 0 &&
      selectedUnits?.map((dev: any, ind: any) => {
        unitIds[unitIdsInd] = dev?.value
        unitIdsInd++
      })

    const body = {
      crmTicketUnitIdsFilter: unitIds,
      isAllowEmpty: false,
      // crmTicketBodyTypes:[0],
      crmAssignRoleBodyTypes:[1]
    }

    ApiPost(`corporate/crm_ticket/filter/unit`, body)
      .then((res: any) => {
        updateFilters(res)
      })
      .catch((err) => {
        ErrorToast(err.message)
      })
  }

  useEffect(() => {
    if (refreshSelectedUnits != false) getFiltersForUnits()
  }, [refreshSelectedUnits])

  // Filters Dropdown - Tenant Lists
  const getFiltersForTenants = () => {
    let tenantIds: any = []
    let tenantIdsInd: any = 0

    selectedTenants?.length > 0 &&
      selectedTenants?.map((dev: any, ind: any) => {
        tenantIds[tenantIdsInd] = dev?.value
        tenantIdsInd++
      })

    const body = {
      crmTicketTenantIdsFilter: tenantIds,
      isAllowEmpty: false,
      // crmTicketBodyTypes:[0], 
      crmAssignRoleBodyTypes:[1]
    }

    ApiPost(`corporate/crm_ticket/filter/tenant`, body)
      .then((res: any) => {
        updateFilters(res)
      })
      .catch((err) => {
        ErrorToast(err.message)
      })
  }

  useEffect(() => {
    if (refreshSelectedTenants != false) getFiltersForTenants()
  }, [refreshSelectedTenants])

  // Filters Dropdown - RequestType
  const getFiltersForRequestType = () => {
    let reqType: any = []
    let reqTypeInd: any = 0

    selectedRequestTypes?.length > 0 &&
      selectedRequestTypes?.map((dev: any, ind: any) => {
        reqType[reqTypeInd] = dev?.value
        reqTypeInd++
      })

    const body = {
      crmTicketTypesFilter: reqType,
      isAllowEmpty: false,
      // crmTicketBodyTypes:[0],
      crmAssignRoleBodyTypes:[1]
    }

    ApiPost(`corporate/crm_ticket/filter/types`, body)
      .then((res: any) => {
        updateFilters(res)
      })
      .catch((err) => {
        ErrorToast(err.message)
      })
  }

  useEffect(() => {
    if (refreshSelectedRequestTypes != false) getFiltersForRequestType()
  }, [refreshSelectedRequestTypes])

  // Filters Dropdown - TicketStatus
  const getFiltersForTicketStatus = () => {
    let ticketSt: any = []
    let ticketStInd: any = 0

    selectedTicketStatuses?.length > 0 &&
      selectedTicketStatuses?.map((dev: any, ind: any) => {
        ticketSt[ticketStInd] = dev?.value
        ticketStInd++
      })

    const body = {
      crmTicketStatusFilter: ticketSt,
      isAllowEmpty: false,
      // crmTicketBodyTypes:[0],
      crmAssignRoleBodyTypes:[1]
    }

    ApiPost(`corporate/crm_ticket/filter/status`, body)
      .then((res: any) => {
        updateFilters(res)
      })
      .catch((err) => {
        ErrorToast(err.message)
      })
  }

  useEffect(() => {
    if (refreshselectedTicketStatuses != false) getFiltersForTicketStatus()
  }, [refreshselectedTicketStatuses])

  // Filters Dropdown - Priority
  const getFiltersForPriority = () => {
    let ticketPr: any = []
    let ticketPrInd: any = 0

    selectedPriority?.length > 0 &&
      selectedPriority?.map((dev: any, ind: any) => {
        ticketPr[ticketPrInd] = dev?.value
        ticketPrInd++
      })

    const body = {
      crmTicketPrioritiesFilter: ticketPr,
      isAllowEmpty: false,
      // crmTicketBodyTypes:[0],
      crmAssignRoleBodyTypes:[1]
    }

    ApiPost(`corporate/crm_ticket/filter/priorities`, body)
      .then((res: any) => {
        updateFilters(res)
      })
      .catch((err) => {
        ErrorToast(err.message)
      })
  }

  useEffect(() => {
    if (refreshSelectedPriority != false) getFiltersForPriority()
  }, [refreshSelectedPriority])

  //
  const updateFilters = (res: any) => {
    //  Development
    let devValues: any = []
    let selectedDev: any = []
    let selectedDevInd: any = 0

    res?.data?.data?.developmentList?.map((val: any, ind: any) => {
      devValues[ind] = {
        label: `${val?.name}`,
        value: `${val?._id}`,
        devType: val?.isCommunity ? 'comm' : val?.isBuilding ? 'build' : '',
      }
      if (val?.isCheck) {
        selectedDev[selectedDevInd] = devValues[ind]
        selectedDevInd++
      }
    })

    setDevelopmentLists(devValues)
    setSelectedDevelopments(selectedDev)

    //  Units
    let unitsValues: any = []
    let selectedUnits: any = []
    let selectedUnitsInd: any = 0

    res?.data?.data?.unitList?.map((val: any, ind: any) => {
      unitsValues[ind] = {
        label: `${val?.unitNo}`,
        value: `${val?._id}`,
      }
      if (val?.isCheck) {
        selectedUnits[selectedUnitsInd] = unitsValues[ind]
        selectedUnitsInd++
      }
    })

    setUnitsLists(unitsValues)
    setSelectedUnits(selectedUnits)

    //  Tenants
    let tenantsValues: any = []
    let selectedTenants: any = []
    let selectedTenantsInd: any = 0

    res?.data?.data?.tenantList?.map((val: any, ind: any) => {
      tenantsValues[ind] = {
        label: `${val?.firstName} ${val?.lastName}`,
        value: `${val?._id}`,
      }
      if (val?.isCheck) {
        selectedTenants[selectedTenantsInd] = tenantsValues[ind]
        selectedTenantsInd++
      }
    })

    setTenantsLists(tenantsValues)
    setSelectedTenants(selectedTenants)

    // RequestType
    let selectedRequestTypeValues: any = []
    if (
      res?.data?.data?.crmTicketTypes?.[0] == 0 ||
      res?.data?.data?.crmTicketTypes?.[1] == 0 ||
      res?.data?.data?.crmTicketTypes?.[2] == 0
    )
      selectedRequestTypeValues?.push({label: 'Maintenance', value: 0})
    if (
      res?.data?.data?.crmTicketTypes?.[0] == 1 ||
      res?.data?.data?.crmTicketTypes?.[1] == 1 ||
      res?.data?.data?.crmTicketTypes?.[2] == 1
    )
      selectedRequestTypeValues?.push({label: 'Complaints', value: 1})
    if (
      res?.data?.data?.crmTicketTypes?.[0] == 2 ||
      res?.data?.data?.crmTicketTypes?.[1] == 2 ||
      res?.data?.data?.crmTicketTypes?.[2] == 2
    )
      selectedRequestTypeValues?.push({label: 'General Requests', value: 2})
    setSelectedRequestTypes(selectedRequestTypeValues)

    // TicketStatus
    let selectedTicketStatusValues: any = []
    if (res?.data?.data?.crmTicketStatus?.[0] == 0 || res?.data?.data?.crmTicketStatus?.[1] == 0)
      selectedTicketStatusValues?.push({label: 'Open', value: 0})
    if (res?.data?.data?.crmTicketStatus?.[0] == 1 || res?.data?.data?.crmTicketStatus?.[1] == 1)
      selectedTicketStatusValues?.push({label: 'Closed', value: 1})
    setSelectedTicketStatuses(selectedTicketStatusValues)

    // Priority
    let selectedPriorityValues: any = []
    if (
      res?.data?.data?.crmTicketPriorities?.[0] == 0 ||
      res?.data?.data?.crmTicketPriorities?.[1] == 0 ||
      res?.data?.data?.crmTicketPriorities?.[2] == 0
    )
      selectedPriorityValues?.push({label: 'Low', value: 0})
    if (
      res?.data?.data?.crmTicketPriorities?.[0] == 1 ||
      res?.data?.data?.crmTicketPriorities?.[1] == 1 ||
      res?.data?.data?.crmTicketPriorities?.[2] == 1
    )
      selectedPriorityValues?.push({label: 'Medium', value: 1})
    if (
      res?.data?.data?.crmTicketPriorities?.[0] == 2 ||
      res?.data?.data?.crmTicketPriorities?.[1] == 2 ||
      res?.data?.data?.crmTicketPriorities?.[2] == 2
    )
      selectedPriorityValues?.push({label: 'High', value: 2})
    setSelectedPriority(selectedPriorityValues)

    setTimeout(() => {
      setRefreshData(new Date())
    }, 200)
  }

  //
  const getDuration = (val: any) => {
    let duration = moment.duration(val, 'minutes')
    let displayValue = ''

    if (duration.asMinutes() < 60) {
      displayValue = `${duration.asMinutes().toFixed(0)} min`
    } else if (duration.asHours() < 24) {
      displayValue = `${duration.asHours().toFixed(0)} hrs`
    } else if (duration.asDays() < 7) {
      displayValue = `${duration.asDays().toFixed(0)} Days`
    } else if (duration.asWeeks() < 4) {
      displayValue = `${duration.asWeeks().toFixed(0)} Weeks`
    } else {
      displayValue = `${duration.asMonths().toFixed(0)} Months`
    }

    return displayValue
  }

  useEffect(() => {
    const filters = {
      development: selectedDevelopments,
      units: selectedUnits,
      tenants: selectedTenants,
      reqType: selectedRequestTypes,
      ticketStatus: selectedTicketStatuses,
      priority: selectedPriority,
    }
    localStorage.setItem('crmResidentsFiltersOfMaintenance', JSON.stringify(filters))
  }, [
    selectedDevelopments,
    selectedUnits,
    selectedTenants,
    selectedRequestTypes,
    selectedTicketStatuses,
    selectedPriority,
  ])

  return (
    <>
      <div className='mx-auto d-flex gap-5 flex-column p-0 m-0 px-2' id='kt_app_main'>
       
        {/* Filters */}
        <div className='row py-5 gy-3 mx-0 mt-5' style={{gap: '0px'}}>
          <div className='col-md-12 d-flex align-items-center  px-0 '>
            <div className='xyz me-5 pe-3'>
              <label className='head-text' style={{fontWeight: '600'}}>
                {' '}
                Search{' '}
              </label>
            </div>

            {/* Development */}
            <div className='test property me-4 crm'>
              <div
                className={`multi-select-container jobs-property dev ${
                  selectedDevelopments?.length === 0 ? 'no-value' : 'has-value '
                }`}
              >
                <MultiSelect
                  options={developmentLists}
                  value={selectedDevelopments}
                  onChange={(e: any) => {
                    setSelectedDevelopments(e)
                    setRefreshSelectedDevs(new Date())
                  }}
                  labelledBy='Developments'
                  valueRenderer={CustomValueRenderer}
                  overrideStrings={{
                    allItemsAreSelected: `${selectedDevelopments?.length} Selected`,
                  }}
                />
              </div>
            </div>

            {/* Unit No */}
            <div className='test property me-4 crm'>
              <div
                className={`multi-select-container jobs-property unit-no ${
                  selectedUnits?.length === 0 ? 'no-value' : 'has-value '
                }`}
              >
                <MultiSelect
                  options={unitsLists}
                  value={selectedUnits}
                  onChange={(e: any) => {
                    setSelectedUnits(e)
                    setRefreshSelectedUnits(new Date())
                  }}
                  labelledBy='Units'
                  valueRenderer={CustomValueRenderer}
                  overrideStrings={{
                    allItemsAreSelected: `${selectedUnits?.length} Selected`,
                  }}
                />
              </div>
            </div>

            {/* Tenant */}
            <div className='test property me-4 crm'>
              <div
                className={`multi-select-container jobs-property tenant-filter ${
                  selectedTenants?.length === 0 ? 'no-value' : 'has-value '
                }`}
              >
                <MultiSelect
                  options={tenantsLists}
                  value={selectedTenants}
                  onChange={(e: any) => {
                    setSelectedTenants(e)
                    setRefreshSelectedTenants(new Date())
                  }}
                  labelledBy='Units'
                  valueRenderer={CustomValueRenderer}
                  overrideStrings={{
                    allItemsAreSelected: `${selectedTenants?.length} Selected`,
                  }}
                />
              </div>
            </div>

            {/* Request Type */}
            <div className='test property me-4 crm'>
              <div
                className={`multi-select-container jobs-property req-type-filter ${
                  selectedRequestTypes?.length === 0 ? 'no-value' : 'has-value '
                }`}
              >
                <MultiSelect
                  options={requestTypesLists}
                  value={selectedRequestTypes}
                  onChange={(e: any) => {
                    setSelectedRequestTypes(e)
                    setRefreshSelectedRequestTypes(new Date())
                  }}
                  labelledBy='Request Tyoe'
                  valueRenderer={CustomValueRenderer}
                  overrideStrings={{
                    allItemsAreSelected: `${selectedRequestTypes?.length} Selected`,
                  }}
                />
              </div>
            </div>

            {/* Ticket Status */}
            <div className='test property me-4 crm'>
              <div
                className={`multi-select-container jobs-property ticket-status ${
                  selectedTicketStatuses?.length === 0 ? 'no-value' : 'has-value '
                }`}
              >
                <MultiSelect
                  options={ticketStatusesLists}
                  value={selectedTicketStatuses}
                  onChange={(e: any) => {
                    setSelectedTicketStatuses(e)
                    setRefreshselectedTicketStatuses(new Date())
                  }}
                  labelledBy='Request Tyoe'
                  valueRenderer={CustomValueRenderer}
                  overrideStrings={{
                    allItemsAreSelected: `${selectedTicketStatuses?.length} Selected`,
                  }}
                />
              </div>
            </div>

            {/* Priority */}
            <div className='test property me-4 crm'>
              <div
                className={`multi-select-container jobs-property priority ${
                  selectedPriority?.length === 0 ? 'no-value' : 'has-value '
                }`}
              >
                <MultiSelect
                  options={priorityLists}
                  value={selectedPriority}
                  onChange={(e: any) => {
                    setSelectedPriority(e)
                    setRefreshSelectedPriority(new Date())
                  }}
                  labelledBy='Priority'
                  valueRenderer={CustomValueRenderer}
                  overrideStrings={{
                    allItemsAreSelected: `${selectedPriority?.length} Selected`,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        {/* Table Data */}
        <div className='row'>
          <div className='col-12'>
            <div className='card card-flush py-5  px-7'>
              <div className='app-main flex-column flex-row-fluid' id='------------kt_app_main'>
                <div className='table-responsive'>
                  <table
                    className='table align-middle table-row-dashed fs-8 gy-5 '
                    id='kt_ecommerce_sales_table'
                  >
                    <thead className='fs-7'>
                      <th className='text-start min-w-75px ps-0'>Ticket #</th>
                      <th className='text-start min-w-100px ps-0'>Type</th>
                      <th className='text-start min-w-150px ps-0'>Tenant Name</th>
                      <th className='text-start min-w-175px ps-0'>Subject</th>
                      <th className='text-center min-w-125px px-0'>Priority</th>
                      <th className='text-center min-w-150px px-0'>Ticket Status </th>
                      <th className='text-start min-w-125px ps-0'>Duration </th>
                      <th className='text-start min-w-125px ps-0'>Assigned To</th>
                      <th className='text-start min-w-125px ps-0 '>Unit No.</th>
                      <th className='text-start min-w-125px ps-0'>Floor</th>
                      <th className='text-start min-w-125px ps-0 '>Group</th>
                      <th className='text-start min-w-125px ps-0'>Cluster</th>
                      <th className='text-start min-w-125px ps-0'>Development</th>
                      <th className='text-start min-w-150px ps-0'>Created</th>
                      <th className='text-start min-w-150px ps-0'>Last Updated</th>
                      <th className='text-start min-w-75px ps-0'>Rating</th>
                    </thead>
                    <tbody className='fw-semibold head-text'> {/* className='fw-semibold text-gray-600'*/ }
                      {tableData?.length > 0 ? (
                        tableData?.map((itm: any, i: any) => {
                          return (
                            <tr
                              onClick={() => {
                                goToRequest(itm)
                              }}
                              className='cursor-pointer'
                            >
                              <td className='text-start ps-0 test'>{itm?.id ? itm?.id : '-'}</td>
                              <td className='text-start ps-0 test'>
                                {itm?.type == 0
                                  ? 'Maintenance'
                                  : itm?.type == 1
                                  ? 'Complaint'
                                  : itm?.type == 2
                                  ? 'General Request'
                                  : '-'}
                              </td>
                              <td className={`text-start cursor-pointer ps-0 m-0 p-0 `}>
                                <img
                                  src={tenantUserFilledIconBlue}
                                  height={30}
                                  width={30}
                                  className='me-2'
                                />
                                {itm?.tenant?.length > 0
                                  ? `${itm?.tenant?.[0]?.firstName} ${itm?.tenant?.[0]?.lastName}`
                                  : `-`}
                              </td>
                              <td className='text-start ps-0 test'>
                                {itm?.description ? (
                                  <>
                                    {itm?.description?.length > 30
                                      ? `${itm?.description?.slice(0, 30)}...`
                                      : itm?.description}{' '}
                                  </>
                                ) : (
                                  '-'
                                )}
                              </td>

                              <td className='text-center px-0 test'>
                                <div
                                  className={`status ${
                                    itm?.priority == 0
                                      ? 'draft-st '
                                      : itm?.priority == 1
                                      ? 'pending-approval-st'
                                      : itm?.priority == 2
                                      ? 'cancelled-st'
                                      : 'pend-st'
                                  } mx-auto status-w-100`}
                                >
                                  {itm?.priority == 0
                                    ? 'Low'
                                    : itm?.priority == 1
                                    ? 'Medium'
                                    : itm?.priority == 2
                                    ? 'High'
                                    : '-'}
                                </div>
                              </td>

                              <td className='text-center px-0 test'>
                                <div
                                  className={`status ${
                                    itm?.status == 0
                                      ? 'status active-bar'
                                      : itm?.status == 1
                                      ? 'status expired-bar'
                                      : 'status no-st-bar'
                                  } mx-auto status-w-100`}
                                >
                                  {itm?.status == 0 ? 'Open' : itm?.status == 1 ? 'Closed' : '-'}
                                </div>
                              </td>

                              <td className='text-start ps-0 test'>
                                {itm?.duration != null ? getDuration(itm?.duration) : '-'}
                              </td>

                              <td
                                className='text-start ps-0 test'
                                style={itm?.assignRoleType == null ? {color: '#8c8c8c'} : {}}
                              >
                                {itm?.assignRoleType == 0
                                  ? 'Customer Support'
                                  : itm?.assignRoleType == 1
                                  ? 'Maintenance'
                                  : itm?.assignRoleType == 2
                                  ? 'Finance'
                                  : itm?.assignRoleType == 1
                                  ? 'Leasing'
                                  : 'Unassigned'}
                              </td>
                              <td className='text-start ps-0 test'>
                                {itm?.unit?.length > 0 ? itm?.unit?.[0]?.unitNo : '-'}
                              </td>
                              <td className='text-start ps-0 test'>
                                {itm?.floor?.length > 0 ? itm?.floor?.[0]?.name : '-'}
                              </td>
                              <td className='text-start ps-0 test'>
                                {itm?.unit_group?.length > 0 ? itm?.unit_group?.[0]?.name : '-'}
                              </td>
                              <td className='text-start ps-0 test'>
                                {itm?.cluster?.length > 0 ? itm?.cluster?.[0]?.name : '-'}
                              </td>
                              <td className='text-start ps-0 test'>
                                {itm?.community?.length > 0
                                  ? itm?.community?.[0]?.name
                                  : itm?.building?.length > 0
                                  ? itm?.building?.[0]?.name
                                  : '-'}
                              </td>
                              <td className='text-start  cursor-pointer ps-0'>
                                <p className='my-0'>
                                  {itm?.createdAt
                                    ? `${moment
                                        ?.utc(itm?.createdAt)
                                        ?.local()
                                        ?.format('DD.MM.YYYY - hh:mm A')}`
                                    : '-'}
                                </p>
                              </td>
                              <td className='text-start  cursor-pointer ps-0'>
                                <p className='my-0'>
                                  {itm?.updatedAt
                                    ? `${moment
                                        ?.utc(itm?.updatedAt)
                                        ?.local()
                                        ?.format('DD.MM.YYYY - hh:mm A')}`
                                    : '-'}
                                </p>
                              </td>
                              <td className='text-start cursor-pointer ps-0'>
                                {' '}
                                <div className='d-flex align-items-center '>
                                  <img src={starYellow} height={18} width={18} className='me-1' />
                                  {itm?.rate != undefined ? itm?.rate : '-'}
                                </div>
                              </td>
                            </tr>
                          )
                        })
                      ) : (
                        <tr>
                          <td colSpan={16} className='text-center'>
                            <img src={noData} alt='' width={350} />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>

                {/* pagination start*/}
                <div className='row mb-5'>
                  <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
                  <div className='row mt-5'>
                    <div className='col-sm-12 col-md-12 d-flex align-items-center justify-content-center justify-content-md-end mt-5'>
                      <div
                        className='dataTables_paginate paging_simple_numbers'
                        id='kt_ecommerce_sales_table_paginate'
                      >
                        <ReactPaginate
                          breakLabel='...'
                          nextLabel='>'
                          pageClassName='paginate_button page-item +'
                          pageLinkClassName='page-link'
                          containerClassName='pagination'
                          activeClassName='active'
                          previousClassName='paginate_button page-item previous'
                          nextClassName='paginate_button page-item next'
                          previousLinkClassName='page-link'
                          nextLinkClassName='page-link'
                          onPageChange={handlePageClick}
                          pageRangeDisplayed={5}
                          pageCount={pageLimit}
                          forcePage={page - 1}
                          previousLabel='<'
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/* pagination end*/}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CRMResidents
