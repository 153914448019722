import moment from 'moment'
import React, {useEffect, useState, useRef} from 'react'
import {useNavigate, useLocation} from 'react-router-dom'
import {ApiGet, ApiPost, ApiUpload, ApiPut, ApiDelete, ApiGetNoAuth} from '../../../../apiCommon/helpers/API/ApiData'
import {Bucket} from '../../../../apiCommon/helpers/API/ApiData'
import {ErrorToast, SuccessToast} from '../../../../apiCommon/helpers/Toast'
import backArrow from '../../../../img/back-arrow.png'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import Swal from 'sweetalert2'
import lock from '../../../../img/padlock.png'
import ReactPaginate from 'react-paginate'
import noData from '../../../../img/NoData1.svg'
import pencil from '../../../../img/pen.png'
import Lightbox from 'yet-another-react-lightbox'
import 'yet-another-react-lightbox/styles.css'
import {useDispatch, useSelector} from 'react-redux'
import trashImg from '../../../../img/trash.png'
import correct from '../../../../img/correct.png'
import {Controller, useForm} from 'react-hook-form'
import redCross from '../../../../img/remove.png'
import editpen from '../../../../img/pen.png'
import deleteImg from '../../../../img/delete.png'
import axios from 'axios'
import ownerFilledIconBlue from '../../../../img/owners-profile-blue.png'
import folderIcon from '../../../../img/folder.png'
import folderGreenIcon from '../../../../img/folder-green.png'
import removeFilled from '../../../../img/remove-filled.png'
import downloadIcon from '../../../../img/download.png'
import greenPen from '../../../../img/pen.png'
import pngIcon from '../../../../img/png.png'
import jpgIcon from '../../../../img/jpg-file.png'
import jpegIcon from '../../../../img/jpeg.png'
import pdfIcon from '../../../../img/pdf.svg'
import docIcon from '../../../../img/doc.svg'
import pptIcon from '../../../../img/ppt.png'
import correctFilled from '../../../../img/correct.png'
import fileIcon from '../../../../img/invoice-white.png'
import EditOwnerModel from './EditOwnerModel'
import {Fancybox as fancybox} from '@fancyapps/ui'
import '@fancyapps/ui/dist/fancybox/fancybox.css'
import ModalLoader from '../../General/ModalLoader'
import {capitalizeFirstLetter} from '../../../../Utilities/utils'
import swal from 'sweetalert2'
import yellowStar from '../../../../img/yellow-star.png'
import townhouse from '../../../../img/TownHouse.svg'
import appartment from '../../../../img/Apartment.svg'
import villa from '../../../../img/Villa.svg'
import other from '../../../../img/other.svg'
import common_area from '../../../../img/common_area.svg'
import penthouse from '../../../../img/PentHouse.svg'
import pdfDocument from '../../../../img/pdf.svg'
import docCocument from '../../../../img/doc.svg'
import fileImg from '../../../../img/file.svg'
import videoImg from '../../../../img/video.png'
import pngImg from '../../../../img/png.png'
import unitsBlue from '../../../../img/allPropertiesBlue.png'
import occupancyBlue from '../../../../img/occupancyBlue.png'
import FolderCreation from './FolderCreation'

const ViewOwner = () => {
  const [isFormDirty, setIsFormDirty] = useState(false)
  const {
    register,
    control,
    handleSubmit,
    reset,
    setValue,
    formState: {errors},
  } = useForm<any>({mode: 'onSubmit', reValidateMode: 'onChange'})

  const [unitDetails, setUnitDetails] = useState<any>()

  const navigate = useNavigate()
  const [ownerData, setOwnerData] = React.useState<any>([])
  const [propertiType, setPropertiType] = React.useState('Documents')
  const [open, setOpen] = React.useState(false)
  const [index, setIndex] = React.useState(-1)
  const [slides, setSlides] = React.useState<any>([])
  const [totalImages, setTotalImages] = React.useState<any>(0)

  // React Select
  const customStyles = {
    container: (defaultStyles: any) => ({
      ...defaultStyles,
      width: '100%',
    }),

    option: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      color: state.isSelected ? '#000' : '#000',
      backgroundColor: state.isSelected ? '#c2d8d9' : '',
      ':hover': {
        backgroundColor: '#e0f5f0',
        color: '#000',
      },

      fontWeight: '500',
    }),

    menu: (defaultStyles: any) => ({
      ...defaultStyles,
      border: '0.1rem solid #007a59 ',
      marginTop: '0px',
      borderRadius: '0px',
      zIndex: '2',
    }),
    menuList: (defaultStyles: any) => ({
      ...defaultStyles,
      maxHeight: '200px',
    }),
    placeholder: (defaultStyles: any) => ({
      ...defaultStyles,
      fontStyle: 'Italic',
      zIndex: '1',
    }),

    input: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      color: ' #b5b5c3',
      fontWeight: '500',
    }),

    control: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      boxShadow: 'none',
      color: 'white',
      border: '0.1rem solid #bec3cb',
      borderRadius: '2px',
      padding: '0rem',
      height: '35px',
      ':hover': {
        borderColor: '#007a59',
        backgroundColor: '#fff',
      },
      ':focus': {
        borderColor: '#007a59 !important',
        backgroundColor: '#fff',
      },
      '::placeholder': {
        fontStyle: 'Italic',
      },
    }),
    singleValue: (defaultStyles: any) => ({
      ...defaultStyles,
      color: '#5e6278',
      fontWeight: '600',
      zIndex: '1',
    }),
    valueContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: '0px 8px 2px 8px',
    }),
  }

  //
  const getOwnerData = async () => {
    setIsLoading(true)
    await ApiGet(`corporate/owner/${window.location.pathname.split('/')[2]}`)
      .then((res) => {
        setOwnerData(res?.data?.data)
        setValue('email', res?.data?.data?.email)
        setValue('phoneNumber', `${parseInt(res?.data?.data?.countryCode)}${res?.data?.data?.phoneNumber}`)
        setProfileImg(res?.data?.data?.profileImage)
        setIsLoading(false)
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
        setIsLoading(false)
      })
  }

  React.useEffect(() => {
    getOwnerData()
  }, [window.location.pathname.split('/')[2]])

  /************************* Documents **********************/
  const [folders, setFolders] = useState<any>()
  const [viewFolder, setViewFolder] = useState<any>(false)
  const [folderFiles, setFolderFiles] = useState<any>()
  const [selectedFolder, setSelectedFolder] = useState<any>()
  const [folderName, setFolderName] = useState<any>('')
  const [folderNameModal, setFolderNameModal] = useState<any>(false)
  const [folderEditMode, setFolderEditMode] = useState<any>(false)

  //
  const getOwnersFolders = () => {
    ApiGet(`corporate/tenant_folder?tenantId=${window.location.pathname.split('/')[2]}`)
      .then((res: any) => {
        setFolders(res?.data?.data)
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
      })
  }

  //
  const getFolderFiles = (folder: any) => {
    ApiGet(`corporate/tenant_folder/${folder?._id}`)
      .then((res: any) => {
        setSelectedFolder(folder)
        setViewFolder(true)
        setFolderFiles(res?.data?.data?.fileList)

        let y: any = []
        for (let i = 0; i < res?.data?.data?.fileList?.length; i++) {
          y[y.length] = {
            src: Bucket + res?.data?.data?.fileList[i]?.fileURL,
          }
        }

        setSlides(y)
        setTotalImages(res?.data?.data?.fileList?.length)
        setEditFolderName(-1)
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
      })
  }

  const [editFile, setEditFile] = useState<any>(-1)
  const [editFolderName, setEditFolderName] = useState<any>(-1)
  const [fileFound, setFileFound] = useState<any>(false)
  const [isFolderName, setIsFolderName] = useState<any>(false)
  const [isLimitError,setIsLimitError] = useState<boolean>(false)
  const maxLength = 30
  let fileLists_single: any = []

  const [fileToReplace, setFileToReplace] = useState<any>()
  var replaceFile: any

  const [showLoader, setShowLoader] = useState<boolean>(false)
  const [uploadProgress, setUploadProgress] = useState<number>(0)
  const [totalFiles, setTotalFiles] = useState<number>(0)

  const [uploadingText, setUploadingText] = useState<any>('Uploading')
  const [percentProgress, setPercentProgress] = useState(0)

  //
  useEffect(() => {
    setIsLimitError(false)
    setIsFolderName(false)
  },[editFolderName])

  const getUniqueFilename = (filename: any, folderFiles: any) => {
    const fileExtension = filename.split('.').pop()
    const fileNameWithoutExtension = filename.replace(`.${fileExtension}`, '')
    let newFilename = filename
    let counter = 1

    while (folderFiles.some((file: any) => file.fileName === newFilename)) {
      newFilename = `${fileNameWithoutExtension}(${counter}).${fileExtension}`
      counter++
    }

    return newFilename
  }

  //
  const onFilesAddedSingle = async (event: any, val: any, id: any) => {
    let temp = [...folderFiles]

    if (event.target.files) {
      setShowLoader(true)
      setTotalFiles(event.target.files.length)

      for (let m = 0; m < event.target.files.length; m++) {
        try {
          // Add File
          if (val == 0) {
            const files = event.target.files
            fileLists_single = Array.from(files)

            setFileToReplace(event.target.files[m])
            replaceFile = event.target.files[m]

            let isFound: any = false
            for (let i = 0; i < folderFiles?.length; i++) {
              if (event.target.files?.[m]?.name?.toLowerCase() === folderFiles[i]?.fileName?.toLowerCase()) {
                isFound = true
                i = folderFiles?.length
              }
            }

            if (!isFound) {
              // setOpen(true)

              let formData: any = new FormData()
              let data: any = fileLists_single[m]
              let fileURL: any = URL.createObjectURL(data)
              let filetypes: any = fileLists_single[m].type
              data.fileURL = fileURL
              formData.append('image', data)

              let url = 'upload/image/tenant_folder'

              await ApiUpload(`${url}`, formData)
                .then(async (res) => {
                  temp[temp?.length] = {
                    fileName: capitalizeFirstLetter(event?.target?.files[m]?.name),
                    fileURL: res?.data?.data?.image,
                    fileSize: event?.target?.files[m]?.size,
                    createdAt: moment(new Date()).format('YYYY-MM-DD'),
                  }
                  setUploadProgress(m + 1)

                  const body = {
                    id: selectedFolder?._id,
                    name: selectedFolder?.name,
                    fileList: temp,
                  }
                  await ApiPut(`corporate/tenant_folder`, body)
                    .then((res: any) => {
                      getFolderFiles(selectedFolder)
                      getOwnersFolders()
                      // setOpen(false)
                    })
                    .catch((err: any) => {})
                })
                .catch((err) => {
                  // console.log('res_blob', err)
                  ErrorToast(err?.message)
                  setShowLoader(false)
                  setUploadProgress(0) // Reset upload progress
                  setTotalFiles(0)
                })
            } else {
              let formData: any = new FormData()
              let data: any = replaceFile

              let fileURL: any = URL.createObjectURL(data)
              let filetypes: any = replaceFile.type
              let filename: any = capitalizeFirstLetter(replaceFile.name)

              // Generate unique filename
              filename = getUniqueFilename(filename, folderFiles)

              data.fileURL = fileURL
              formData.append('image', data)
              console.log('\ntype', filetypes)

              let url = 'upload/image/tenant_folder'

              await ApiUpload(`${url}`, formData)
                .then(async (res) => {
                  var name = Date.now()
                  temp[temp?.length] = {
                    fileName: `${filename}`,
                    fileURL: res?.data?.data?.image,
                    fileSize: replaceFile.size,
                    createdAt: moment(new Date()).format('YYYY-MM-DD'),
                  }
                  setUploadProgress(m + 1)

                  const body = {
                    id: selectedFolder?._id,
                    name: selectedFolder?.name,
                    fileList: temp,
                  }
                  await ApiPut(`corporate/tenant_folder`, body)
                    .then((res: any) => {
                      getFolderFiles(selectedFolder)
                      getOwnersFolders()
                    })
                    .catch((err: any) => {})
                })
                .catch((err) => {
                  console.log('res_blob', err)
                  ErrorToast(err?.message)
                  setShowLoader(false)
                  setUploadProgress(0)
                  setTotalFiles(0)
                })
            }
          }
        } catch (error) {
          console.error(error)
          // Handle errors
        }
      }
      setShowLoader(false)
      setUploadProgress(0)
      setTotalFiles(0)
      event.target.value = null
    }

    // delete file
    if (val == 1) {
      let temp = [...folderFiles]

      for (let i = 0; i < temp?.length; i++) {
        if (temp[i]?._id == id) temp.splice(i, 1)
      }

      const body = {
        id: selectedFolder?._id,
        name: selectedFolder?.name,
        fileList: temp,
      }
      ApiPut(`corporate/tenant_folder`, body)
        .then((res: any) => {
          let values = {...selectedFolder}
          values.fileList = temp
          setSelectedFolder(values)

          getFolderFiles(values)
          getOwnersFolders()
          setOpen(false)
        })
        .catch((err: any) => {})
    }
  }

  //
  const getFilename = (fileName: any, type: any, fileUrl: any) => {
    // console.log(fileUrl)
    let extension: any = fileUrl?.split('.').pop()
    // if (type) return `${fileName.split('.')[0]}.${extension}`
    if (type) {
      if(extension?.length > 8) return ''
      return `${extension}`
    }
    else {
    const lastDotIndex = fileName?.lastIndexOf('.');
    if (lastDotIndex === -1) return fileName; // No dot found, return full name
    return fileName.substring(0, lastDotIndex);
    }
  }

  useEffect(() => {
    if (folderName?.trim()?.length > 0) {
      setIsFolderName(false)
    }

    if (folderNameModal == false) {
      setFolderName('')
      setIsFolderName(false)
    }

    if (folderName === '') {
      setFileFound(false)
    }
  }, [folderName, folderNameModal])



  
  const [folderBtnDisable, setFolderBtnDisabled] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  //
  const addFolder = () => {
  
    let isFound: any = false
    for (let i = 0; i < folders?.length; i++) {
      if (folderName.toLowerCase() === folders[i]?.name.toLowerCase()) {
        isFound = true
        setFileFound(true)
        i = folders?.length
      }
    }

    if (isFound == false) {
      setIsLoading(true)
      setFolderBtnDisabled(true)
      const body = {
        name: folderName,
        tenantId: `${window.location.pathname.split('/')[2]}`,
        fileList: [],
      }
      ApiPost(`corporate/tenant_folder`, body)
        .then((res: any) => {
          SuccessToast('New Folder has been added successfully!')
          getOwnersFolders()
          setFolderNameModal(false)
          setFolderName('')
          setTimeout(() => {
            setFolderBtnDisabled(false)
            setIsLoading(false)
          }, 200)
        })
        .catch((err: any) => {
          ErrorToast(err?.message)
          setFolderBtnDisabled(false)
          setIsLoading(false)
        })
    } else {
      setIsLoading(false)
    }
  }

  

  const updateFolder = () => {
   
    let isFound: any = false
    for (let i = 0; i < folders?.length; i++) {
      if (newFolderName?.trim()?.toLowerCase() === folders[i]?.name?.toLowerCase() && newFolderName?.trim()?.toLowerCase() !== selectedFolder?.name?.toLowerCase()) {
        isFound = true
        setFileFound(true)
        i = folders?.length
      }
    }

    if (isFound == false) {
      setIsLoading(true)
      const body = {
        tenantId: `${window.location.pathname.split('/')[2]}`,
        id: selectedFolder?._id,
        name: newFolderName?.trim(),
        fileList: selectedFolder?.fileList,
      }
      ApiPut(`corporate/tenant_folder`, body)
        .then((res: any) => {
          SuccessToast('Folder has been updated successfully!')
          getOwnersFolders()
          setNewFolderName('')
          setEditFolderName(-1)
          setSelectedFolder({})
          setTimeout(() => {
      
            setIsLoading(false)
          }, 200)
        })
        .catch((err: any) => {
          ErrorToast(err?.message)
          setIsLoading(false)
        })
    } else {
      setIsLoading(false)
    }
  }

  //
  const updateName = () => {
    const body = {
      id: selectedFolder?._id,
      name: selectedFolder?.name,
      fileList: folderFiles,
    }
    ApiPut(`corporate/tenant_folder`, body)
      .then((res: any) => {
        getFolderFiles(selectedFolder)
        setEditFile(-1)
      })
      .catch((err: any) => {})
  }

  //
  const updateFolderName = () => {
    const body = {
      id: selectedFolder?._id,
      name: newFolderName,
      fileList: selectedFolder?.fileList,
    }
    ApiPut(`corporate/tenant_folder`, body)
      .then((res: any) => {
        setEditFolderName(-1)
        getOwnersFolders()
      })
      .catch((err: any) => {})
  }

  const deleteFolder = (id: any) => {
    console.log(id)
    ApiDelete(`corporate/tenant_folder/${id}`)
      .then((res: any) => {
        SuccessToast('Folder has been deleted successfully!')
        getOwnersFolders()
      })
      .catch((err: any) => {})
  }

  /************************ Properties  ********************/
  const [page, setPage] = useState<any>(1)
  const [page1, setPage1] = useState<any>(1)
  const [properties, setProperties] = useState<any>()
  const [pageLimit1, setPageLimit1] = useState<any>(1)
  const [show, setShow] = useState<any>([])
  const [profileImg, setProfileImg] = useState<any>(null)
  const [insightsData, setInsightData] = useState<any>()

  const handlePageClick1 = (event: any) => {
    setPage(event.selected + 1)

    setTimeout(() => {
      getProperties()
      getPropertiesSummary()
    }, 1000)
  }

  //
  const getProperties = () => {
    const body = {
      limit: 25,
      page: page1,
      ownerIds: [`${window.location.pathname.split('/')[2]}`],
      startDate: '',
      endDate: '',
    }

    ApiPost(`corporate/owner/unit/get`, body)
      .then((res) => {
        setProperties(res?.data?.data?.owner_unit_data)
        setPageLimit1(res?.data?.data?.state?.page_limit)
        for (let i = 0; i < res?.data?.data?.payment_data.length; i++) {
          let values = [...show]
          values[i] = false
          setShow(values)
        }
      })
      .catch((err) => console.log('err', err))
  }

  //
  const getPropertiesSummary = () => {
    const body = {
      limit: 25,
      page: page1,
      ownerIds: [`${window.location.pathname.split('/')[2]}`],
      startDate: '',
      endDate: '',
    }

    ApiPost(`corporate/owner/unit_dashboard_count/get`, body)
      .then((res) => {
        setInsightData(res?.data?.data)
      })
      .catch((err) => console.log('err', err))
  }

  const [editOwnerModel, setEditOwnerModel] = useState<any>(false)
  const [newName, setNewName] = useState<any>()
  const [newFolderName, setNewFolderName] = useState<any>('')

  useEffect(() => {
    if (newFolderName?.trim()?.length > 0) {
      setIsFolderName(false)
    }

    if (editFolderName == -1) {
      setIsFolderName(false)
    }

    if (newFolderName === '') {
      setFileFound(false)
    }
  }, [newFolderName, editFolderName])

  //
  const renameFile = async (file: any, type: any) => {
    const body = {
      newFileName: newName + '.' + type,
      image: file,
    }

    await ApiPost('upload/rename_file', body)
      .then((res) => {
        setTimeout(() => {
          setEditFile(-1)
          getFolderFiles(selectedFolder)
        }, 1500)
      })
      .catch((err) => {
        ErrorToast(err.message)
      })
  }

  const [isEditTenant, setIsEditTenant] = useState<any>(false)

  React.useEffect(() => {
    getOwnersFolders()
  }, [])

  //
  const deleteOwner = () => {
    ApiDelete(`corporate/owner/${window.location.pathname.split('/')[2]}`)
      .then(() => {
        SuccessToast('Owner profile has been deleted successfully..')
        navigate(-1)
      })
      .catch((err) => {
        ErrorToast(err?.message)
      })
  }

  //
  const updateTenant = async (data: any, e: any) => {
    console.log(e)
    e.preventDefault()
    const body = {
      _id: ownerData?._id,
      firstName: ownerData?.firstName,
      lastName: ownerData?.lastName,
      phoneNumber: ownerData?.phoneNumber,
      countryCode: ownerData?.countryCode,
      email: ownerData?.email,
      nationality: ownerData?.nationality,
      DOB: ownerData?.DOB === null ? '' : ownerData?.DOB,
      passportNo: ownerData?.passportNo,
      otherNo: ownerData?.otherNo,
      idNo: ownerData?.idNo,
      gender: Number(ownerData?.gender),
      profileImage: profileImg,
    }

    await ApiPut(`corporate/tenant`, body)
      .then((response) => {
        SuccessToast(response?.data?.message)
        setIsEditTenant(false)
      })
      .catch((error) => {
        console.log(error)
        ErrorToast(error?.message)
      })
  }

  //
  const onSelectFile = async (e: any) => {
    e.preventDefault()
    let fileURL = e.target.files[0]
    var formdata = new FormData()
    formdata.append('image', fileURL)

    await ApiPost(`upload/compress_image/tenant_profile`, formdata)
      .then((res) => {
        e.preventDefault()
        const body = {
          _id: ownerData?._id,
          firstName: ownerData?.firstName,
          lastName: ownerData?.lastName,
          phoneNumber: ownerData?.phoneNumber,
          countryCode: ownerData?.countryCode,
          email: ownerData?.email,
          nationality: ownerData?.nationality,
          // tenantType: ownerData?.tenantType,
          DOB: ownerData?.DOB === null ? '' : ownerData?.DOB,
          passportNo: ownerData?.passportNo,
          otherNo: ownerData?.otherNo,
          idNo: ownerData?.idNo,
          // folder_list: folders,
          gender: Number(ownerData?.gender),
          profileImage: res?.data?.data?.image,
        }

        ApiPut(`corporate/tenant`, body)
          .then((response) => {
            SuccessToast(response?.data?.message)
            setIsEditTenant(false)
          })
          .catch((error) => {
            console.log(error)
            ErrorToast(error?.message)
          })
      })
      .catch(() => {})
  }

  // delete logo
  const deleteProfileImg = () => {
    const body = {
      url: profileImg,
    }
    ApiPost(`upload/delete_file`, body)
      .then((res) => {
        setProfileImg(null)
        SuccessToast('Profile picture has been removed successfully!!!')
      })
      .catch((err) => {
        ErrorToast(err.message)
        console.log('err', err)
      })
  }

  useEffect(() => {
    if (propertiType == 'properties') {
      getProperties()
      getPropertiesSummary()
    }
  }, [propertiType])

  const [isDisable, setIsDisable] = useState<any>(false)

  //
  const handleDownload = async (imageUrl: any, fileName: string) => {
    setIsDisable(true)
    try {
      const response = await axios.get(imageUrl, {
        responseType: 'blob',
      })

      const contentType = response.headers['content-type']
      const blob = new Blob([response.data], {type: response.headers['content-type']})

      const extensionMap: {[key: string]: string} = {
        'image/jpeg': 'jpg',
        'image/png': 'png',
        'application/pdf': 'pdf',
        'application/msword': 'doc',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
        'application/vnd.ms-powerpoint': 'ppt',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'pptx',
        // Add more mappings as needed
      }
      let extension = extensionMap[contentType] || 'file'

      if (contentType === 'application/zip' && fileName) {
        const fileNamePart = fileName.split('.')
        const fileExtension = fileNamePart[fileNamePart.length - 1].toLowerCase()
        if (['docx', 'xlsx', 'pptx', 'doc', 'xls', 'ppt'].includes(fileExtension)) {
          extension = fileExtension
        }
      }

      // Create a link element
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = `downloaded_file.${extension}`

      // Append the link to the body
      document.body.appendChild(link)

      // Trigger the click event to start the download
      link.click()

      // Remove the link from the body
      document.body.removeChild(link)
      setIsDisable(false)
    } catch (error) {
      setIsDisable(false)
      ErrorToast('Error downloading image')
    }
  }

  React.useEffect(() => {
    fancybox.bind('[data-fancybox]', {
      Toolbar: {
        display: {
          left: ['infobar'],
          middle: ['zoomIn', 'zoomOut', 'toggle1to1', 'rotateCCW', 'rotateCW', 'flipX', 'flipY'],
          right: ['slideshow', 'thumbs', 'close'],
        },
      },
    })

    // return () => {
    //   fancybox.destroy();
    // };
  }, [])

  //
  let user: any = localStorage.getItem('userData')
  user = JSON.parse(user)

  //
  const handleImageClick = (val: any, valSrc: any) => {
    // Add data-fancybox attribute to the clicked image
    const image = document.getElementById(val)
    if (image) {
      image.setAttribute('data-fancybox', '')
      image.setAttribute('data-src', valSrc)
    }
  }

  return (
    <>
      {!isLoading ? (
        <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
          {/* <form onSubmit={handleSubmit((data: any, e: any) => updateTenant(ownerData, e))}> */}
          <div className='d-flex flex-column flex-column-fluid'>
            <div id='kt_app_content' className='app-content flex-column-fluid pt-0'>
              <div className='px-2 pt-0 mt-0'>
                <div className='d-flex align-items-center'>
                  <span
                    className='rounded-circle cursor-pointer'
                    onClick={() => {
                      navigate('/owners')
                    }}
                  >
                    <img src={backArrow} height={14} width={14} />
                  </span>
                  <h2 className='m-0 ms-2'>{'Owner Profile'}</h2>
                </div>
              </div>
              <div id='kt_app_content_container' className='px-2 mt-8'>
                <div className='d-flex flex-column flex-lg-row'>
                  <div className='flex-column flex-lg-row-auto w-lg-300px w-xl-400px mb-10'>
                    <div className='card mb-5 mb-xl-8'>
                      <div className='card-body py-3 px-5'>
                        <div id='kt_user_view_details' className='collapse show'>
                          <div className='pb-5 fs-6'>
                            <div className='row mt-4'>
                              {!isEditTenant && (
                                <>
                                  <div className='col-12'>
                                    <h3 className='m-0 ms-1 fnt-600'>
                                      {`${
                                        (ownerData?.firstName + ' ' + ownerData?.lastName).length > 15
                                          ? (ownerData?.firstName + ' ' + ownerData?.lastName).substring(0, 15) + '...'
                                          : ownerData?.firstName + ' ' + ownerData?.lastName
                                      }`}
                                    </h3>
                                  </div>
                                  <div className='col-12 symbol symbol-100px symbol d-flex align-items-center mt-5'>
                                    {/* Profile photo */}
                                    <div className='d-flex mb-3 '>
                                      {profileImg == null ? (
                                        <>
                                          <img src={ownerFilledIconBlue} height={80} width={80} />
                                        </>
                                      ) : (
                                        <div className='d-flex'>
                                          <img
                                            src={`${Bucket}${profileImg}`}
                                            height='160'
                                            width='160'
                                            style={{
                                              borderRadius: '7px',
                                              border: '1.5px solid black',
                                              objectFit: 'contain',
                                            }}
                                          />

                                          {/* <div> */}
                                          <label
                                            htmlFor='media'
                                            className='fw-bold form-label m-0 upload text-center pb-5 bg-white'
                                            style={{
                                              height: '25px',
                                              width: '25px',
                                              border: '1px solid #48a794 ',
                                              borderRadius: '4px',
                                              transform: 'translate(-45px,-10px)',
                                            }}
                                          >
                                            <img
                                              src={editpen}
                                              alt=''
                                              className=''
                                              style={{
                                                cursor: 'pointer',
                                                height: '18px',
                                                width: '18px',
                                              }}
                                            />
                                          </label>

                                          <input
                                            type='file'
                                            hidden
                                            id='media'
                                            className='form-control form-control-solid'
                                            name='passport'
                                            onChange={onSelectFile}
                                          />

                                          <div
                                            className='fw-bold form-label m-0 upload text-center pb-5 bg-white'
                                            style={{
                                              height: '25px',
                                              width: '25px',
                                              border: '1px solid crimson',
                                              borderRadius: '4px',
                                              transform: 'translate(-38px,-10px)',
                                            }}
                                            onClick={() => deleteProfileImg()}
                                          >
                                            <img
                                              src={deleteImg}
                                              alt=''
                                              className=''
                                              style={{
                                                cursor: 'pointer',
                                                height: '18px',
                                                width: '18px',
                                                marginLeft: '2px',
                                              }}
                                            />
                                          </div>
                                          {/* </div> */}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className='col-12 d-flex align-items-center  mt-5'>
                                    <img
                                      src={pencil}
                                      alt=''
                                      height={18}
                                      width={18}
                                      className='ms-auto me-5 cursor-pointer'
                                      onClick={() => {
                                        setEditOwnerModel(true)
                                      }}
                                    />
                                  </div>
                                </>
                              )}
                            </div>
                            <div className=' mt-7'>
                              {/* First Name */}
                              <div className='d-flex my-2 mb-4 align-items-center'>
                                <h4 className='my-2' style={{color: 'black', width: '35%'}}>
                                  <label className='tenant-gray-label'> First Name </label>
                                </h4>
                                <h4 style={{width: '65%'}} className='my-2 fnt-500'>
                                  <span className='ps-1'>
                                    <b>{ownerData?.firstName?.length > 15 ? `${ownerData?.firstName.substring(0, 15)}...` : ownerData?.firstName}</b>
                                  </span>
                                </h4>
                              </div>

                              {/* Last Name */}
                              <div className='d-flex my-2 mb-4 align-items-center'>
                                <h4 className='my-2' style={{color: 'black', width: '35%'}}>
                                  <label className='tenant-gray-label'> Last Name </label>
                                </h4>
                                <h4 style={{width: '65%'}} className='my-2 fnt-500'>
                                  <span className='ps-1'>
                                    <b>{ownerData?.lastName?.length > 15 ? `${ownerData.lastName.substring(0, 15)}...` : ownerData?.lastName}</b>
                                  </span>
                                </h4>
                              </div>

                              <div className='d-flex my-2 mb-4 align-items-center'>
                                <h4 className='my-2' style={{color: 'black', width: '35%'}}>
                                  <label className='tenant-gray-label'> Email </label>
                                </h4>
                                <h4 style={{width: '65%'}} className='my-2 fnt-500'>
                                  <span className='ps-1'>
                                    <b>{ownerData?.email ? ownerData?.email : '-'}</b>
                                  </span>
                                </h4>
                              </div>

                              <div className='d-flex my-2 mb-4 align-items-center'>
                                <h4 className='my-2' style={{color: 'black', width: '35%'}}>
                                  <label className='tenant-gray-label'> Mobile No. </label>
                                </h4>

                                <h4 style={{width: '65%', borderRadius: '0.475rem'}} className='my-2 fnt-500'>
                                  <span className='ps-1'>
                                    <b>{ownerData?.phoneNumber ? `${ownerData?.countryCode} ${ownerData?.phoneNumber}` : '-'}</b>
                                  </span>
                                </h4>
                              </div>

                              <div className='d-flex my-2 mb-4 align-items-center'>
                                <h4 className='my-2' style={{color: 'black', width: '35%'}}>
                                  <label className='tenant-gray-label'> Date of Birth </label>
                                </h4>
                                <h4 style={{width: '65%'}} className='my-2 fnt-500'>
                                  <span className='ps-1'>
                                    <b>{ownerData?.DOB ? moment(ownerData?.DOB && ownerData?.DOB.split('T')[0]).format('DD/MM/YYYY') : '-'}</b>
                                  </span>
                                </h4>
                              </div>

                              <div className='d-flex my-2 mb-4 align-items-center'>
                                <h4 className='my-2' style={{color: 'black', width: '35%'}}>
                                  <label className='tenant-gray-label'> Nationality </label>
                                </h4>
                                <h4 style={{width: '65%'}} className='my-2 fnt-500'>
                                  <span className='ps-1'>
                                    <b>{ownerData?.nationality ? ownerData?.nationality : '-'}</b>
                                  </span>
                                </h4>
                              </div>

                              <div className='d-flex my-2 mb-4 align-items-center'>
                                <h4 className='my-2' style={{color: 'black', width: '35%'}}>
                                  <label className='tenant-gray-label'> Gender </label>
                                </h4>
                                <h4 style={{width: '65%'}} className='my-2 fnt-500'>
                                  <span className='ps-1'>
                                    <b>{ownerData?.gender == 0 ? 'Female' : ownerData?.gender == 1 ? 'Male' : '-'}</b>
                                  </span>
                                </h4>
                              </div>

                              <div className='d-flex my-2 mb-4 align-items-center'>
                                <h4 className='my-2' style={{color: 'black', width: '35%'}}>
                                  <label className='tenant-gray-label'> ID Number </label>
                                </h4>
                                <h4 style={{width: '65%'}} className='my-2 fnt-500'>
                                  <span className='ps-1'>
                                    <b>{ownerData?.idNo?.length > 15 ? `${ownerData?.idNo.substring(0, 15)}...` : ownerData?.idNo}</b>
                                  </span>
                                </h4>
                              </div>

                              <div className='d-flex my-2 mb-4 align-items-center'>
                                <h4 className='my-2' style={{color: 'black', width: '35%'}}>
                                  <label className='tenant-gray-label'> Passport Number </label>
                                </h4>
                                <h4 style={{width: '65%'}} className='my-2 fnt-500'>
                                  <span className='ps-1'>
                                    <b>
                                      {ownerData?.passportNo?.length > 15 ? `${ownerData?.passportNo.substring(0, 15)}...` : ownerData?.passportNo}
                                    </b>
                                  </span>
                                </h4>
                              </div>

                              <div className='d-flex my-2 mb-4 align-items-center'>
                                <h4 className='my-2' style={{color: 'black', width: '35%'}}>
                                  <label className='tenant-gray-label'> Owner ID</label>
                                </h4>
                                <h4 style={{width: '65%'}} className='my-2 fnt-500'>
                                  <span className='ps-1'>
                                    {/* <b>{ownerData?.id}</b> */}
                                    <b>{ownerData?.id?.length > 15 ? `${ownerData?.id.substring(0, 15)}...` : ownerData?.id}</b>
                                  </span>
                                </h4>
                              </div>

                              <div className='d-flex my-2 align-items-center justify-content-center mt-10'>
                                <h4>
                                  <label className='tenant-gray-label'> Profile Last Updated</label>
                                </h4>
                              </div>

                              <div className='d-flex my-2  align-items-center justify-content-center  my-0'>
                                <h5 className='mb-0'>
                                  <label className='tenant-gray-label'>
                                    <i className='tenant-gray-label'>
                                      {' '}
                                      {ownerData?.updatedAt ? `${moment?.utc(ownerData?.updatedAt)?.local()?.format('DD.MM.YYYY - hh:mm A')}` : '-'}
                                    </i>
                                  </label>
                                </h5>
                              </div>

                              <div className='d-flex my-1 align-items-center justify-content-center my-0 mt-1'>
                                <h5 className='mb-0'>
                                  <label className='tenant-gray-label'>
                                    <i className='tenant-gray-label'>
                                      {' '}
                                      <p className='my-0'>
                                        {ownerData?.updatedByUser?.length > 0
                                          ? `${ownerData?.updatedByUser?.[0]?.firstName} ${ownerData?.updatedByUser?.[0]?.lastName}`
                                          : '-'}
                                      </p>
                                    </i>
                                  </label>
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='d-flex my-2 mb-4 align-items-center justify-content-center mt-5'>
                      <button
                        type='button'
                        className='d-flex align-items-center justify-content-center btn btn-sm fw-bold px-2 term-btn status-w-200 text-center'
                        onClick={() => {
                          swal
                            .fire({
                              html: `<div class='fs-3'> <b>Delete Owner </b></div>
                                      <div class='fs-5 mt-4'> You are about to delete this owner. Deleting this owner will remove them from any properties they are linked to. </div>
                                      <div class='fs-5 mt-4'> If the owner is linked to a property that is under a lease, then you will need to update the owner details in the lease.</div>
                                      <div class='fs-5 mt-4'> Are you sure you want to continue? </div>`,
                              // icon: 'warning',
                              showConfirmButton: true,
                              confirmButtonColor: '#35475e',
                              confirmButtonText: 'Yes',
                              showCancelButton: true,
                              cancelButtonText: 'No',
                              cancelButtonColor: '#fff',
                              customClass: {
                                confirmButton: 'custom-confirm-button',
                                cancelButton: 'custom-cancel-button',
                                popup: 'custom-popup',
                              },
                              // reverseButtons: true,
                            })
                            .then((res) => {
                              if (res.isConfirmed) {
                                deleteOwner()
                              }
                            })
                        }}
                      >
                        Delete Profile
                      </button>
                    </div>
                  </div>

                  <>
                    <div className='flex-lg-row-fluid ms-lg-6'>
                      <div className='d-flex justify-content-between'>
                        <ul
                          className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-5 fw-semibold mb-1 xyz ms-1'
                          role='tablist'
                          style={{transform: 'translate(0px, -40px)'}}
                        >
                          <li className='nav-item' role='presentation'>
                            <a
                              className={
                                propertiType === 'Documents' ? 'nav-link text-active-primary pb-0 active' : 'nav-link text-active-primary  pb-0'
                              }
                              data-bs-toggle='tab'
                              aria-selected='false'
                              role='tab'
                              tabIndex={-1}
                              onClick={() => {
                                setPropertiType('Documents')
                              }}
                            >
                              Documents
                            </a>
                          </li>

                          <li className='nav-item' role='presentation'>
                            <a
                              className={
                                propertiType === 'properties' ? 'nav-link text-active-primary pb-0 active' : 'nav-link text-active-primary  pb-0'
                              }
                              data-bs-toggle='tab'
                              aria-selected='false'
                              role='tab'
                              tabIndex={-1}
                              onClick={() => {
                                setPropertiType('properties')
                              }}
                            >
                              Properties
                            </a>
                          </li>
                        </ul>
                      </div>

                      <div className='card py-3 px-5' style={{transform: 'translate(0px, -30px)', minHeight: '97vh'}}>
                        {/* Documents Tab */}
                        {propertiType === 'Documents' && (
                          <>
                            <div className='row g-6 g-xl-9 mb-6 pt-2 mb-xl-9'>
                              {!viewFolder && (
                                <div className='col-lg-12 col-md-12 text-end' style={{textAlign: 'right'}}>
                                  <button
                                    type='button'
                                    className='btn btn-sm fw-bold green-hollow-btn ms-auto btn-fit-content px-2'
                                    style={{width: '130px'}}
                                    onClick={() => {
                                      setFileFound(false)
                                      setFolderNameModal(true)
                                    }}
                                  >
                                    <img
                                      src={folderGreenIcon}
                                      height={18}
                                      width={18}
                                      style={{
                                        marginRight: '7px',
                                      }}
                                    />
                                    New Folder
                                  </button>
                                </div>
                              )}

                              {/* Add New Folder Card */}
                              {/* {folderNameModal && (
                                <div className='col-md-6 col-lg-4 col-xl-4'>
                                  <div className='d-flex mx-2 mb-2 align-items-center'>
                                    <img
                                      src={redCross}
                                      height={18}
                                      width={18}
                                      className='cursor-pointer'
                                      onClick={() => setFolderNameModal(false)}
                                    />

                                    <img
                                      style={{opacity: '1', cursor: 'pointer'}}
                                      src={correct}
                                      height={24}
                                      width={24}
                                      className='ms-auto '
                                      onClick={() => {
                                        if (folderName?.trim()?.length > 0)
                                        {
                                          addFolder()
                                        }else{
                                          setIsFolderName(true)
                                        }
                                      }}
                                    />
                                  </div>
                                  <div className='card mx-2 pb-6'>
                                    <div className='card-body d-flex justify-content-center text-center flex-column px-4 py-4 test pb-2'>
                                      <a className='text-gray-800 text-hover-primary d-flex flex-column'>
                                        <div className='symbol symbol-60px mb-5'>
                                          <img
                                            src={folderIcon}
                                            className='theme-light-show'
                                            alt=''
                                          />
                                          <img
                                            src='/metronic8/demo1/assets/media/svg/files/pdf-dark.svg'
                                            className='theme-dark-show'
                                            alt=''
                                          />
                                        </div>
                                        <div className='fs-5 fw-bold mb-2 '>
                                          <input
                                            type='text'
                                            className='form-control form-control-solid mytest w-75 m-auto bg-white'
                                            placeholder='Enter Folder Name'
                                            onChange={(e: any) => setFolderName(e.target.value)}
                                          />
                                          {fileFound ? (
                                            <p className='mt-2 mb-0' style={{color: 'red'}}>
                                              Folder name already exists
                                            </p>
                                          ) : null}
                                          {isFolderName && (
                                            <p className='mt-2 mb-0' style={{color: 'red'}}>
                                              This field is required
                                            </p>
                                          )}
                                        </div>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              )} */}

                              <FolderCreation
                                folderNameModal={folderNameModal}
                                setFolderNameModal={setFolderNameModal}
                                addFolder={addFolder}
                                setFolderName={setFolderName}
                                folderName={folderName}
                                fileFound={fileFound}
                                isFolderName={isFolderName}
                                setIsFolderName={setIsFolderName}
                                folderBtnDisable={folderBtnDisable}
                              />

                              {folders?.length > 0 && (
                                <>
                                  {viewFolder ? (
                                    <>
                                      <div className='d-flex align-items-center '>
                                        <span
                                          className='rounded-circle cursor-pointer'
                                          onClick={() => {
                                            setViewFolder(false)
                                            setEditFile(-1)
                                          }}
                                        >
                                          <img src={backArrow} height={14} width={14} />
                                        </span>

                                        <ul
                                          className='ms-2 nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-5 fw-semibold mb-1 xyz ms-1'
                                          role='tablist'
                                          style={{transform: 'translate(0px, -2px)'}}
                                        >
                                          <li className='nav-item' role='presentation'>
                                            <a
                                              className={'nav-link text-active-primary pb-0 active'}
                                              data-bs-toggle='tab'
                                              aria-selected='false'
                                              role='tab'
                                              tabIndex={-1}
                                            >
                                              {selectedFolder?.name}
                                              {/* {selectedFolder?.name?.length > 15 
                                                ? `${selectedFolder?.name?.substring(0, 15)}...` 
                                                : selectedFolder?.name} */}
                                            </a>
                                          </li>
                                        </ul>

                                        {selectedFolder?.name != 'Title Deeds' && (
                                          <button
                                            type='button'
                                            className='d-flex align-items-center justify-content-center btn btn-sm fw-bold px-2 term-btn-sm text-center ms-auto'
                                            onClick={() => {
                                              swal
                                                .fire({
                                                  html: `
                                                  <div class='fs-4 mt-4 fw-bold'> 
                                                  Delete Folder
                                                  </div>
                                                  <div class='fs-5 mt-4'> 
                                                    Are you sure you want to permanently delete this folder?
                                                  </div>`,
                                                  showConfirmButton: true,
                                                  confirmButtonColor: '#d54645',
                                                  confirmButtonText: 'Yes',
                                                  showCancelButton: true,
                                                  cancelButtonColor: '#0d9e91',
                                                  // width: '205px',
                                                  padding: '0px',

                                                  // cancelButtonColor: "transparent",
                                                  cancelButtonText: 'No',
                                                })
                                                .then((res: any) => {
                                                  if (res.isConfirmed) {
                                                    // console.log(fl)
                                                    deleteFolder(selectedFolder?._id)
                                                    setViewFolder(false)
                                                    setEditFile(-1)
                                                  }
                                                })
                                            }}
                                          >
                                            Delete Folder
                                          </button>
                                        )}
                                      </div>

                                      {selectedFolder?.name != 'Title Deeds' && (
                                        <>
                                          <label
                                            title='Add New File'
                                            htmlFor='uploadPassport'
                                            className='ms-5 btn btn-sm fw-bold px-2 py-5 blue-btn '
                                          >
                                            <img src={fileIcon} width={13} className='me-3' />
                                            Add File{' '}
                                          </label>
                                          <input
                                            type='file'
                                            hidden
                                            id='uploadPassport'
                                            className='form-control form-control-solid'
                                            placeholder='upload'
                                            name='passport'
                                            onChange={(e: any) => {
                                              onFilesAddedSingle(e, 0, '')
                                            }}
                                            multiple
                                          />
                                        </>
                                      )}
                                      <div className='col-md-12 col-lg-12 col-xl-12' style={{padding: '0px 20px'}}>
                                        {folderFiles?.length > 0 ? (
                                          <>
                                            <div className='row py-3' style={{color: '#a1a5b7'}}>
                                              <div className='col-md-7 '>
                                                <span style={{paddingLeft: '25px'}}>File Name</span>
                                              </div>
                                              <div className='col-md-2 '>Date Added</div>
                                              <div className='col-md-3'></div>
                                            </div>

                                            {folderFiles?.map((file: any, ind: any) => {
                                              return (
                                                <div className='row py-3'>
                                                  {editFile == ind ? (
                                                    <>
                                                      <div className='col-md-7 head-text d-flex align-items-center'>
                                                        <div className='bullet-item me-3 mb-0'></div>
                                                        <img
                                                          src={
                                                            file?.fileURL?.split('.')?.pop() === 'png'
                                                              ? pngIcon
                                                              : file?.fileURL?.split('.')?.pop() === 'jpg'
                                                              ? jpgIcon
                                                              : file?.fileURL?.split('.')?.pop() === 'pdf'
                                                              ? pdfIcon
                                                              : file?.fileURL?.split('.')?.pop() === 'jpeg'
                                                              ? jpegIcon
                                                              : file?.fileURL?.split('.')?.pop() === 'docx'
                                                              ? docIcon
                                                              : file?.fileURL?.split('.')?.pop() === 'doc'
                                                              ? docIcon
                                                              : file?.fileURL?.split('.')?.pop() === 'pptx'
                                                              ? pptIcon
                                                              : file?.fileURL?.split('.')?.pop() === 'ppt'
                                                              ? pptIcon
                                                              : ''
                                                          }
                                                          height={30}
                                                          width={30}
                                                          className='mx-3 cursor-pointer'
                                                          data-fancybox
                                                          data-src={`${Bucket}${file?.fileURL}`}
                                                        />

                                                        <input
                                                          type='text'
                                                          // value={getFilename(newName, false, file?.fileURL)}
                                                          value={newName}
                                                          className='form-control form-control-solid p-2'
                                                          style={{
                                                            backgroundColor: '#fff',
                                                            border: '1px solid #b3b3b3',
                                                          }}
                                                          onChange={(e: any) => {
                                                            // console.log(e.target.value)
                                                            // const sanitizedValue = e.target.value.replace(/\./g, '');
                                                            // let temp = getFilename(e.target.value, true, file?.fileURL)
                                                            // setNewName(e.target.value + '.' + temp)
                                                            setNewName(e?.target?.value?.slice(0, 50))
                                                          }}
                                                        />
                                                      </div>
                                                    </>
                                                  ) : (
                                                    <div className='col-md-7 head-text d-flex align-items-center'>
                                                      <div className='bullet-item me-3 mb-0'></div>
                                                      <img
                                                        src={
                                                          file?.fileURL?.split('.')?.pop() === 'png'
                                                            ? pngIcon
                                                            : file?.fileURL?.split('.')?.pop() === 'jpg'
                                                            ? jpgIcon
                                                            : file?.fileURL?.split('.')?.pop() === 'pdf'
                                                            ? pdfIcon
                                                            : file?.fileURL?.split('.')?.pop() === 'jpeg'
                                                            ? jpegIcon
                                                            : file?.fileURL?.split('.')?.pop() === 'docx'
                                                            ? docIcon
                                                            : file?.fileURL?.split('.')?.pop() === 'doc'
                                                            ? docIcon
                                                            : file?.fileURL?.split('.')?.pop() === 'pptx'
                                                            ? pptIcon
                                                            : file?.fileURL?.split('.')?.pop() === 'ppt'
                                                            ? pptIcon
                                                            : ''
                                                        }
                                                        height={30}
                                                        width={30}
                                                        className='mx-3 cursor-pointer'
                                                        data-fancybox
                                                        data-src={`${Bucket}${file?.fileURL}`}
                                                      />
                                                      {/* {file?.fileName} */}
                                                      {getFilename(file?.fileName, false, file?.fileURL)?.length > 30
                                                        ? `${file?.fileName?.slice(0, 30)}...${getFilename(file?.fileName, true, file?.fileURL)}`
                                                        : file?.fileName}
                                                    </div>
                                                  )}
                                                  <div className='col-md-2 head-text pt-2'>{moment(file?.createdAt).format('DD.MM.YYYY')}</div>
                                                  <div className='col-md-3 head-text text-end'>
                                                    {editFile == ind ? (
                                                      <div className='d-flex justify-content-end'>
                                                        <img
                                                          src={removeFilled}
                                                          height={20}
                                                          width={20}
                                                          style={{
                                                            cursor: 'pointer',
                                                            marginRight: '25px',
                                                          }}
                                                          onClick={() => {
                                                            setEditFile(-1)
                                                          }}
                                                        />
                                                        <img
                                                          src={correctFilled}
                                                          height={20}
                                                          width={20}
                                                          className='ms-7'
                                                          style={{cursor: 'pointer'}}
                                                          onClick={() => {
                                                            // console.log(newName?.split('.')?.[0])
                                                            if (newName?.split('.')?.[0]) {
                                                              console.log(newName)
                                                              const values = [...folderFiles]
                                                              let temp = getFilename(file?.fileName, true, file?.fileURL)
                                                              values[ind].fileName = newName + '.' + temp
                                                              setFolderFiles(values)
                                                              updateName()
                                                            } else {
                                                              updateName()
                                                            }
                                                            // getFolderFiles(selectedFolder)
                                                            // setEditFile(-1)
                                                          }}
                                                          // onClick={() => updateName()}
                                                        />
                                                      </div>
                                                    ) : (
                                                      <div className='d-flex justify-content-end tst'>
                                                        <img
                                                          src={downloadIcon}
                                                          height={20}
                                                          width={20}
                                                          className='me-5'
                                                          style={{cursor: 'pointer'}}
                                                          onClick={() => {
                                                            handleDownload(`${Bucket}${file?.fileURL}`, file?.fileName)
                                                          }}
                                                        />
                                                        {selectedFolder?.name != 'Title Deeds' && (
                                                          <img
                                                            src={greenPen}
                                                            height={17}
                                                            width={17}
                                                            className='me-5 cursor-pointer'
                                                            onClick={() => {
                                                              setNewName(getFilename(file?.fileName, false, file?.fileURL))
                                                              setEditFile(ind)
                                                            }}
                                                          />
                                                        )}

                                                        <img
                                                          src={trashImg}
                                                          height={16}
                                                          width={16}
                                                          className='ms-1 cursor-pointer '
                                                          onClick={(e: any) => {
                                                            Swal.fire({
                                                              html: `
                                                      <div class='fs-4 mt-4 fw-bold'> 
                                                      Delete File
                                                      </div>
                                                      <div class='fs-5 mt-4'> 
                                                          Are you sure you want to permanently delete this file?
                                                      </div>`,
                                                              showConfirmButton: true,
                                                              confirmButtonColor: '#d54645',
                                                              confirmButtonText: 'Yes',
                                                              showCancelButton: true,
                                                              cancelButtonColor: '#0d9e91',
                                                              // width: '205px',
                                                              padding: '0px',

                                                              // cancelButtonColor: "transparent",
                                                              cancelButtonText: 'No',
                                                            }).then((res: any) => {
                                                              if (res.isConfirmed) {
                                                                onFilesAddedSingle(e, 1, file?._id)
                                                              }
                                                            })
                                                          }}
                                                        />
                                                      </div>
                                                    )}
                                                  </div>
                                                </div>
                                              )
                                            })}
                                          </>
                                        ) : (
                                          <div className='text-center'> No Files</div>
                                        )}
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      {folders
                                        ?.sort((a: any, b: any) => a.name.localeCompare(b.name))
                                        ?.map((fl: any, index: any) => {
                                          return (
                                            <div className='col-md-6 col-lg-4 col-xl-4 tst' key={`${fl?.name}${index}`}>
                                              <div style={{height: '30px'}}>
                                                {!fl?.isSystemGenerated && (
                                                  <div className='d-flex align-items-center justify-content-between'>
                                                    {editFolderName !== index ? (
                                                      <>
                                                        <img
                                                          src={trashImg}
                                                          height={16}
                                                          width={16}
                                                          className='ms-1 cursor-pointer'
                                                          onClick={(e: any) => {
                                                            Swal.fire({
                                                              html: `
                                                          <div class='fs-4 mt-4 fw-bold'> 
                                                          Delete Folder
                                                          </div>
                                                          <div class='fs-5 mt-4'> 
                                                            Are you sure you want to permanently delete this folder?
                                                          </div>`,
                                                              showConfirmButton: true,
                                                              confirmButtonColor: '#d54645',
                                                              confirmButtonText: 'Yes',
                                                              showCancelButton: true,
                                                              cancelButtonColor: '#0d9e91',
                                                              // width: '205px',
                                                              padding: '0px',

                                                              // cancelButtonColor: "transparent",
                                                              cancelButtonText: 'No',
                                                            }).then((res: any) => {
                                                              if (res.isConfirmed) {
                                                                deleteFolder(fl?._id)
                                                              }
                                                            })
                                                          }}
                                                        />
                                                        <img
                                                          src={greenPen}
                                                          height={17}
                                                          width={17}
                                                          style={{cursor: 'pointer'}}
                                                          className='me-1'
                                                          onClick={() => {
                                                            setNewFolderName(fl?.name)
                                                            setEditFolderName(index)
                                                            setSelectedFolder(fl)
                                                          }}
                                                        />
                                                      </>
                                                    ) : (
                                                      <>
                                                        <img
                                                          src={removeFilled}
                                                          className='ms-1'
                                                          height={20}
                                                          width={20}
                                                          style={{
                                                            cursor: 'pointer',
                                                          }}
                                                          onClick={() => {
                                                            setEditFolderName(-1)
                                                          }}
                                                        />
                                                        <img
                                                          src={correctFilled}
                                                          height={20}
                                                          width={20}
                                                          className='me-1'
                                                          style={{cursor: 'pointer'}}
                                                          onClick={() => {
                                                            // getFolderFiles(selectedFolder)
                                                            // setEditFile(-1)
                                                          //   if (newFolderName?.trim()?.length > 0)
                                                          //     updateFolderName()
                                                          // }
                                                          if (newFolderName?.trim()?.length > 0 && newFolderName?.trim()?.length <= maxLength) {
                                                            updateFolder()

                                                          } else if(newFolderName?.trim()?.length == 0 || newFolderName == '') {
                                                            setIsFolderName(true)
                                                          }
                                                        }}
                                                        />
                                                      </>
                                                    )}
                                                  </div>
                                                )}
                                              </div>
                                              <div className='card '>
                                                <div className='card-body d-flex justify-content-center text-center flex-column px-4 py-4'>
                                                  <div className='d-flex justify-content-end'>
                                                    <div className='gray-count-circle'>{fl?.fileList?.length}</div>
                                                  </div>
                                                  <a
                                                    onClick={() => (editFolderName == index ? () => {} : getFolderFiles(fl))}
                                                    className='text-gray-800 text-hover-primary d-flex flex-column'
                                                  >
                                                    <div className='symbol symbol-60px mb-5'>
                                                      <img
                                                        src={
                                                          ownerData?.document?.contract?.split('.')[1] === 'pdf'
                                                            ? pdfDocument
                                                            : ownerData?.other?.split('.')?.pop() === 'doc'
                                                            ? docCocument
                                                            : folderIcon
                                                        }
                                                        className='theme-light-show'
                                                        alt=''
                                                      />
                                                      <img src={folderIcon} className='theme-dark-show' alt='' />
                                                    </div>
                                                    <div className='fs-5 fw-bold mb-2'>
                                                      {editFolderName == index ? (
                                                        <>
                                                          <input
                                                            type='text'
                                                            className='form-control form-control-solid mytest w-75 m-auto bg-white'
                                                            placeholder='Enter Folder Name'
                                                            value={newFolderName}
                                                            // onChange={(e: any) => setNewFolderName(e.target.value)}
                                                            onChange={(e: any) => {
                                                              if(e?.target?.value?.length > 30){
                                                                setNewFolderName(e.target.value.slice(0, maxLength + 1))
                                                                setIsLimitError(true)
                                                              }else{
                                                                setNewFolderName(e.target.value)
                                                                setIsLimitError(false)
                                                              }
                                                            }}
                                                            maxLength={maxLength + 1}
                                                          />
                                                            {/* {fileFound ? (
                                                              <p className='mt-2' style={{color: 'red'}}>
                                                                Folder name already exists
                                                              </p>
                                                            ) : null} */}

                                                            {isFolderName ? (
                                                              <p className='mt-2 mb-0' style={{color: 'red'}}>
                                                                This field is required
                                                              </p>
                                                            ): isLimitError ?  (
                                                              <p className='mt-2 mb-0' style={{color: 'red'}}>
                                                                Folder name cannot exceed {maxLength} characters
                                                              </p>
                                                            ) : fileFound ? (
                                                              <p className='mt-2 mb-0' style={{color: 'red'}}>
                                                                Folder name already exists
                                                              </p>
                                                            ) : null}
                                                          
                                                        </>
                                                      ) : (
                                                        <h4>{fl?.name?.length > 15 ? `${fl?.name?.substring(0, 15)}...` : fl?.name}</h4>
                                                      )}
                                                    </div>
                                                  </a>
                                                </div>
                                              </div>
                                            </div>
                                          )
                                        })}
                                    </>
                                  )}
                                </>
                              )}
                            </div>
                          </>
                        )}

                        {/* Properties Tab */}
                        {propertiType === 'properties' && (
                          <>
                            <div className='row mt-7'>
                              <div className='col-12'>
                                <div className='d-flex flex-lg-wrap flex-xl-nowrap' style={{gap: '20px'}}>
                                  <div className='customCard py-4 px-7 shadow property-insight-shadow' style={{width: '12rem', height: '90px'}}>
                                    <h4 className='text-gray-500 '>Units</h4>
                                    <div className='d-flex justify-content-start align-items-center'>
                                      <img src={unitsBlue} height={50} width={50} className='me-3' />
                                      <h4 className='fw-bold'>{insightsData?.unitCount ? insightsData?.unitCount : 0}</h4>
                                    </div>
                                  </div>

                                  <div className='customCard py-4 px-7 shadow property-insight-shadow' style={{width: '12rem', height: '90px'}}>
                                    <h4 className='text-gray-500'>Occupancy</h4>
                                    <div className='d-flex justify-content-start align-items-top'>
                                      <img src={occupancyBlue} height={45} width={45} className='me-3' />
                                      <h4 className='fw-bold ms-2' style={{marginTop: '15px'}}>
                                        {insightsData?.occupancyRate >= 0 ? `${Math.round(insightsData?.occupancyRate)}%` : 0}
                                      </h4>
                                    </div>
                                  </div>
                                  <div className='customCard py-4 px-7 shadow property-insight-shadow' style={{width: '12rem', height: '90px'}}>
                                    <h4 className='text-gray-500'>Income</h4>
                                    <div style={{marginTop: '18px'}}>
                                      <h4 className='fw-bold' style={{color: '#00b8a9'}}>
                                        AED
                                      </h4>
                                      <h4 className='fw-bold'>
                                        {`${parseFloat(insightsData?.incomePaymentCount ?? 0)?.toLocaleString('en-US', {
                                          minimumFractionDigits: 2,
                                        })}`}
                                      </h4>
                                    </div>
                                  </div>
                                  <div className='customCard py-4 px-7 shadow property-insight-shadow' style={{width: '12rem', height: '90px'}}>
                                    <h4 className='text-gray-500'>Expense</h4>
                                    <div style={{marginTop: '18px'}}>
                                      <h4 className='fw-bold' style={{color: '#e46464 '}}>
                                        AED
                                      </h4>
                                      <h4 className='fw-bold'>
                                        {/* {insightsData?.expensePaymentCount ?? 0} */}
                                        {`${parseFloat(insightsData?.expensePaymentCount ?? 0)?.toLocaleString('en-US', {
                                          minimumFractionDigits: 2,
                                        })}`}
                                      </h4>
                                    </div>
                                  </div>
                                  <div className='customCard py-4 px-7 shadow property-insight-shadow' style={{width: '12rem', height: '90px'}}>
                                    <h4 className='text-gray-500'>Rating</h4>
                                    <div className='d-flex justify-content-between align-items-end'>
                                      <img src={yellowStar} height={35} width={35} className='me-3 mt-3' />
                                      <h4 className='fw-bold'>{insightsData?.ratingCount ?? 0}</h4>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='card-body pt-0 table-responsive mt-7 pb-4 px-1' id='global-table'>
                              <table className='table align-middle table-row-dashed fs-6 gy-5' id='kt_ecommerce_sales_table'>
                                <thead>
                                  <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                                    <th className='min-w-50px'></th>
                                    <th className='min-w-125px'>Development</th>
                                    <th className='min-w-125px '>Unit No.</th>
                                    <th className='min-w-125px'>Floor</th>
                                    <th className='min-w-125px'>Category</th>
                                    <th className='min-w-125px'>Type</th>
                                    <th className='min-w-125px'>Bedrooms</th>
                                    <th className='min-w-125px'>Bathrooms</th>
                                    <th className='min-w-100px'>Size</th>
                                    <th className='min-w-125px text-center'>Title Deeds</th>
                                    <th className='min-w-150px'>Occupancy Status</th>
                                    <th className='min-w-150px text-center'>Occupancy Rate</th>
                                    <th className='min-w-125px'>Total Income</th>
                                    <th className='min-w-150px'>Total Expenses</th>
                                    <th className='min-w-150px'>Upcoming Income</th>
                                    <th className='min-w-100px'>Rating</th>
                                  </tr>
                                </thead>
                                {properties?.length > 0 ? (
                                  <tbody className='fw-semibold text-gray-600'>
                                    {properties.map((prp: any, ind: any) => {
                                      return (
                                        <>
                                          <tr>
                                            <td className={`text-start ps-0 m-0 p-0`}>
                                              <img
                                                src={
                                                  prp?.images?.length > 0
                                                    ? `${Bucket}${prp?.images?.[0]}`
                                                    : prp?.unitType === 3
                                                    ? townhouse
                                                    : prp?.unitType === 5
                                                    ? other
                                                    : prp?.unitType === 2
                                                    ? common_area
                                                    : prp?.unitType === 4
                                                    ? villa
                                                    : prp?.unitType === 0
                                                    ? appartment
                                                    : prp?.unitType === 1
                                                    ? penthouse
                                                    : ''
                                                }
                                                height={30}
                                                width={30}
                                                id={`clickedImage${ind}`}
                                                className={`m-0 p-0 light-box-single-image ${prp?.images?.length > 0 ? 'cursor-pointer' : ''}`}
                                                style={prp?.images?.length > 0 ? {borderRadius: '5px'} : {borderRadius: ''}}
                                                // data-fancybox
                                                // data-src={`${Bucket}${prp?.images?.[0]}`}
                                                onClick={() => {
                                                  if (prp?.images?.length > 0) handleImageClick(`clickedImage${ind}`, `${Bucket}${prp?.images?.[0]}`)
                                                }}
                                              />
                                            </td>
                                            <td>
                                              {prp?.community?.length > 0
                                                ? prp?.community?.[0]?.name
                                                : prp?.building?.length > 0
                                                ? prp?.building?.[0]?.name
                                                : '-'}
                                            </td>
                                            <td>{prp?.unitNo ? prp?.unitNo : '-'}</td>
                                            <td>{prp?.floor ? prp?.floor?.name : '-'}</td>

                                            <td>{prp?.propertyType == 0 ? 'Residential' : prp?.propertyType == 1 ? 'Commercial' : '-'}</td>
                                            <td>
                                              {prp?.unitType === 0
                                                ? 'Apartment'
                                                : prp?.unitType === 1
                                                ? 'Penthouse'
                                                : prp?.unitType === 2
                                                ? 'Common Area'
                                                : prp?.unitType === 3
                                                ? 'Townhouse'
                                                : prp?.unitType === 4
                                                ? 'Villa'
                                                : prp?.unitType === 5
                                                ? `Other - ${prp?.otherName}`
                                                : prp?.unitType === 6
                                                ? 'Office'
                                                : prp?.unitType === 7
                                                ? 'Restaurant'
                                                : prp?.unitType === 8
                                                ? 'Cafe'
                                                : prp?.unitType === 9
                                                ? 'Retail'
                                                : '-'}
                                            </td>
                                            <td>{prp?.bedrooms >= 0 ? `${prp?.bedrooms} BR` : '-'}</td>
                                            <td> {prp?.bathrooms >= 0 ? `${prp?.bathrooms} Bath` : '-'}</td>
                                            <td>
                                              {' '}
                                              {prp?.size ? `${prp?.size} ${prp?.sizeType === 0 ? 'Sq ft' : prp?.sizeType === 1 ? 'Sq mt' : ''}` : '-'}
                                            </td>
                                            <td className='text-center'>
                                              <div className='d-flex align-items-center justify-content-center'>
                                                <img
                                                  src={
                                                    prp?.titleDeedFile?.split('.').pop() === 'pdf'
                                                      ? pdfDocument
                                                      : prp?.titleDeedFile?.split('.').pop() === 'doc' ||
                                                        prp?.titleDeedFile?.split('.').pop() === 'docx'
                                                      ? docCocument
                                                      : prp?.titleDeedFile?.split('.').pop() === 'jpeg' ||
                                                        prp?.titleDeedFile?.split('.').pop() === 'jpg' ||
                                                        prp?.titleDeedFile?.split('.').pop() === 'png' ||
                                                        prp?.titleDeedFile?.split('.').pop() === 'svg' ||
                                                        prp?.titleDeedFile?.split('.').pop() === 'gif'
                                                      ? pngImg
                                                      : prp?.titleDeedFile?.split('.').pop()?.toLowerCase() === 'mp4' ||
                                                        prp?.titleDeedFile?.split('.').pop()?.toLowerCase() === 'webm' ||
                                                        prp?.titleDeedFile?.split('.').pop()?.toLowerCase() === 'avi' ||
                                                        prp?.titleDeedFile?.split('.').pop()?.toLowerCase() === 'mkv' ||
                                                        prp?.titleDeedFile?.split('.').pop()?.toLowerCase() === 'mov' ||
                                                        prp?.titleDeedFile?.split('.').pop()?.toLowerCase() === 'flv' ||
                                                        prp?.titleDeedFile?.split('.').pop()?.toLowerCase() === 'wmv'
                                                      ? ``
                                                      : fileImg
                                                  }
                                                  height={30}
                                                  width={30}
                                                  className='cursor-pointer'
                                                  id={`clickedDeed${ind}`}
                                                  onClick={() => {
                                                    handleImageClick(`clickedDeed${ind}`, `${Bucket}${prp?.titleDeedFile}`)
                                                  }}
                                                />
                                              </div>
                                            </td>
                                            <td>{prp?.occupy === 0 ? 'Vacant ' : prp?.occupy === 1 ? 'Occupied' : '-'}</td>
                                            <td className='text-center'>
                                              <div
                                                className={` status mx-auto ${
                                                  Math.round(prp?.occupancyRate) <= 50 && Math.round(prp?.occupancyRate) > 0
                                                    ? 'cancelled-st'
                                                    : Math.round(prp?.occupancyRate) <= 75 && Math.round(prp?.occupancyRate) > 50
                                                    ? 'requested-st'
                                                    : Math.round(prp?.occupancyRate) <= 99 && Math.round(prp?.occupancyRate) > 75
                                                    ? 'draft-st'
                                                    : Math.round(prp?.occupancyRate) >= 100
                                                    ? 'create-st'
                                                    : 'not-saved'
                                                }`}
                                              >
                                                {prp?.occupancyRate ? `${Math.round(prp?.occupancyRate)}%` : '-'}
                                              </div>
                                            </td>
                                            <td>
                                              {`AED ${parseFloat(prp?.incomePaymentCount)?.toLocaleString('en-US', {
                                                minimumFractionDigits: 2,
                                              })}`}
                                            </td>
                                            <td>
                                              {`AED ${parseFloat(prp?.expensePaymentCount)?.toLocaleString('en-US', {
                                                minimumFractionDigits: 2,
                                              })}`}
                                            </td>
                                            <td>{`AED ${parseFloat(prp?.incomePaymentOutstandingCount)?.toLocaleString('en-US', {
                                              minimumFractionDigits: 2,
                                            })}`}</td>
                                            <td>
                                              <div className='d-flex align-items-center '>
                                                <img src={yellowStar} height={18} width={18} className='me-1' />
                                                {prp?.rating != undefined ? prp?.rating : '-'}
                                              </div>
                                            </td>
                                          </tr>
                                        </>
                                      )
                                    })}
                                  </tbody>
                                ) : (
                                  <tbody className='fw-semibold text-gray-600'>
                                    <tr>
                                      <td colSpan={10} className='text-center'>
                                        <img src={noData} alt='' width={350} />
                                      </td>
                                    </tr>
                                  </tbody>
                                )}
                              </table>
                            </div>

                            {/* Pagination */}
                            <div className='row mb-5'>
                              <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
                              <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end mt-2'>
                                <div className='dataTables_paginate paging_simple_numbers' id='kt_ecommerce_sales_table_paginate'>
                                  <ReactPaginate
                                    breakLabel='...'
                                    nextLabel='>'
                                    pageClassName='paginate_button page-item +'
                                    pageLinkClassName='page-link'
                                    containerClassName='pagination'
                                    activeClassName='active'
                                    previousClassName='paginate_button page-item previous'
                                    nextClassName='paginate_button page-item next'
                                    previousLinkClassName='page-link'
                                    nextLinkClassName='page-link'
                                    onPageChange={handlePageClick1}
                                    pageRangeDisplayed={5}
                                    pageCount={pageLimit1}
                                    previousLabel='<'
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </>
                </div>
              </div>
            </div>
          </div>
          {/* </form> */}

          <Lightbox
            open={index >= 0}
            index={index}
            close={() => setIndex(-1)}
            slides={slides}
            carousel={{finite: slides.length <= 1}}
            render={{
              buttonPrev: slides.length <= 1 ? () => null : undefined,
              buttonNext: slides.length <= 1 ? () => null : undefined,
            }}
          />

          <Backdrop sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}} open={open}>
            <CircularProgress style={{color: '#007a59'}} />
          </Backdrop>
        </div>
      ) : (
        <div className='d-flex justify-content-center align-items-center w-100' style={{height: '75vh'}}>
          <CircularProgress style={{color: '#007a59'}} />
        </div>
      )}

      {/*  */}
      {editOwnerModel && (
        <EditOwnerModel
          show={editOwnerModel}
          // ownerData={ownerData}
          // folders={folders}
          handleClose={(key: any) => {
            if (key === 0) {
              setEditOwnerModel(false)
            }
            if (key === 1) {
              setEditOwnerModel(false)
              getOwnerData()
              getOwnersFolders()
            }
          }}
        />
      )}

      {/*  */}
      <ModalLoader
        isVisible={showLoader}
        progress={uploadProgress}
        totalFiles={totalFiles}
        uploadingText={uploadingText}
        percentageProgress={percentProgress}
      />
    </>
  )
}

export default ViewOwner
