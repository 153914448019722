import React from 'react'
import {Section} from './EmailBuilder'
import imageUploader from '../../../../../img/Email-Template-02/Image Uploader - Rectangle Large.png'
import imageUploaderSmall from '../../../../../img/Email-Template-02/Image Uploader - Rectangle Small.png'
import TextEditor from '../../../General/TextEditor'

interface SectionRendererProps {
  section: Section
  isSelected: boolean
  selectedSectionStyling?: any
  setSelectedSectionStyling: (values: any) => void
  isDesktopPreview?: boolean
}

const SectionRenderer: React.FC<SectionRendererProps> = ({
  section,
  isSelected,
  selectedSectionStyling,
  setSelectedSectionStyling,
  isDesktopPreview
}) => {
  // switch (section.type) {
  //   case 'header':
  return (
    <>
      {section.type == 'text' || section.type == 'textImage' || section.type == 'header' ? (
        <div
          style={{
            backgroundColor: section.content.backgroundColor,
            // minHeight: `${section.content.minHeight}px`,
            // height: `${section.content.height ? section.content.height+section.content.sizeType : 'unset'}`,
            height: 'unset',
            // width: `${section.content.width}${section.content.sizeType}`,
            width: '100%',
            color: section.content.color,
            display: section.content.display,
            justifyContent: section.content.justifyContent,
            marginTop: `${section.content.marginTop}px`,
            marginRight: `${section.content.marginRight}px`,
            marginBottom: `${section.content.marginBottom}px`,
            marginLeft: `${section.content.marginLeft}px`,
            paddingTop: `${section.content.paddingTop}px`,
            paddingRight: `${section.content.paddingRight}px`,
            paddingBottom: `${section.content.paddingBottom}px`,
            paddingLeft: `${section.content.paddingLeft}px`,
            // textAlign: section.content.textAlign
          }}
        >
          {section.content.imageUrl ? (
            <img src={section.content.imageUrl} alt='Header' />
          ) : (
            <div
              className={`${!isSelected ? 'po-editor-view' : `${isDesktopPreview ? 'template-text-editor' : 'template-text-editor template-text-editor-sm'}`}`}
              style={{width: '-webkit-fill-available'}}
              id={`${section?.id}`}
            >
              <TextEditor
                value={section?.content?.html}
                placeholder={''}
                onChange={(e: any) => {
                  let values = {...selectedSectionStyling}
                  if (selectedSectionStyling != null) values.content.html = e
                  setSelectedSectionStyling(values)
                }}
                from={'email-template'}
              />
            </div>
            // <div dangerouslySetInnerHTML={{ __html: section.content.html }} />
          )}
        </div>
      ) : section.type == 'image' || section.type == 'button' ? (
        <>
          <div className='position-relative'>
            <div
              className='templateButtonBoxOpacity z-index-2 border-radius-5px'
              style={{
                boxShadow: '0px 0px 20px 0px rgba(10, 24, 64, -0.8)',
              }}
            ></div>
            <div
              style={{
                backgroundColor: section.content.backgroundColor,
                // minHeight: `${section.content.minHeight}px`,
                // height: `${
                //   section.content.height ? section.content.height + section.content.sizeType : 'unset'
                // }`,
                // width: `${section.content.width}${section.content.sizeType}`,
                color: section.content.color,
                display: section.content.display,
                justifyContent: section.content.justifyContent,
                marginTop: `${section.content.marginTop}px`,
                marginRight: `${section.content.marginRight}px`,
                marginBottom: `${section.content.marginBottom}px`,
                marginLeft: `${section.content.marginLeft}px`,
                paddingTop: `${section.content.paddingTop}px`,
                paddingRight: `${section.content.paddingRight}px`,
                paddingBottom: `${section.content.paddingBottom}px`,
                paddingLeft: `${section.content.paddingLeft}px`,
                // textAlign: section.content.textAlign
              }}
            >
              {section.content.imageUrl ? (
                <img src={section.content.imageUrl} alt='Header' />
              ) : (
                <div
                  dangerouslySetInnerHTML={{__html: section.content.html}}
                  id={`${section?.id}`}
                  style={{width: `${section?.content?.imgSrc == 'svg' ? '-webkit-fill-available' : 'unset'}`}}
                />
              )}
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  )
  // Add more cases for other section types...
  // default:
  //   return null
  // }
}

export default SectionRenderer
