import React, {useEffect, useRef} from 'react'
import {useState} from 'react'
import {Row, Col, OverlayTrigger, Tooltip} from 'react-bootstrap'
import {useNavigate, useParams, useLocation} from 'react-router-dom'
import backArrow from '../../../../../img/back-arrow.png'
import emailTemplateOneImg from '../../../../../img/email-template-one.png'
import emailTemplateTwoImg from '../../../../../img/email-template-two.png'
import imageUploader from '../../../../../img/Email-Template-02/Image Uploader - Rectangle Large.png'
import imageUploaderSmall from '../../../../../img/Email-Template-02/Image Uploader - Rectangle Small.png'
import imageUploaderSquare from '../../../../../img/Email-Template-02/Image Uploader - Square.png'
import saveGreenImg from '../../../../../img/save-dark-green.png'
import emailDesignerImg from '../../../../../img/email-designer.png'
import emailDynamicSettingImg from '../../../../../img/email-dynamic-settings.png'
import desktopImg from '../../../../../img/desktop-tab.png'
import phoneImg from '../../../../../img/phone-tab.png'
import SectionRenderer from './SectionRenderer'
import SectionControls from './SectionControls'
import SectionEditor from './SectionEditor'
import {DragDropContext, Droppable, Draggable, DropResult} from 'react-beautiful-dnd-next'
import './styles.scss'
import plusGreen from '../../../../../img/add-green-filled.png'
import addImage from '../../../../../img/image.png'
import addText from '../../../../../img/text-area.png'
import addCTA from '../../../../../img/button.png'
// import {getEmailHTML} from '../../../../../Utilities/utils'
import HTMLExport from './HTMLExport'
import TestEmailTemplate from './TestEmailTemplate'
import {getEmailHTMLFile} from '../../../../../Utilities/utils'
import {ApiPost, ApiPut} from '../../../../../apiCommon/helpers/API/ApiData'
import {SuccessToast, ErrorToast} from '../../../../../apiCommon/helpers/Toast'
import {getEmailHTMLContent} from '../../../../../Utilities/utils'

// SectionContent interface
export interface SectionContent {
  backgroundColor: string
  height: number
  width: number
  imageUrl?: string
  title?: string
  [key: string]: any // Allow any additional properties
}

// Section interface
export interface Section {
  id: string
  type: string
  content: SectionContent
}

//
interface EmailBuilderProps {
  template?: any
}

const EmailBuilder: React.FC<EmailBuilderProps> = ({template}) => {
  const navigate = useNavigate()
  const {state} = useLocation()
  const {id} = useParams()

  const [templates, SelectTemplates] = useState<any>([
    {
      src: emailTemplateOneImg,
      name: 'Template 1',
    },
    {
      src: emailTemplateTwoImg,
      name: 'Template 2',
    },
  ])

  const [selectedTemplate, setSelectedTemplate] = useState<any>(-1)
  const [selectedSection, setSelectedSection] = useState<any>(null)
  const [selectedSectionIndex, setSelectedSectionIndex] = useState<any>(null)

  const [selectedSectionStyling, setSelectedSectionStyling] = useState<any>({
    backgroundColor: '#6254ff',
    height: '',
    width: '',
    link: [{value: '', display: 0}],
    image: [{value: '', display: 0}],
    content: {
      html: '',
    },
  })

  const handleClick = (section: any) => {
    setSelectedSection(section)
  }

  // State to track the focused section
  const [focusedSection, setFocusedSection] = useState<string | null>(null)

  // Section click handler
  const handleSectionClickkkk = (sectionName: string) => {
    setFocusedSection(sectionName)
  }

  const [isDynamicActive, setIsDynamicActive] = useState<any>(false)
  const [templateName, setTemplateName] = useState<any>('')
  const [isDesktop, setIsDesktop] = useState<any>(true)

  const [sections, setSections] = useState<any>([]) // Store sections

  // Load default template as the first section
  useEffect(() => {
    setSections(template)
  }, [template])

  //
  const handleSectionClick = (sect: any) => {
    if (sect != null) {
      setSelectedSection(sect.id)
      setSelectedSectionStyling(sect)
    } else {
      setSelectedSection(null)
      setSelectedSectionStyling(null)
    }
  }

  //
  const handleSectionUpdate = (id: any, newContent: any) => {
    setSections(
      sections.map((section: any) =>
        section.id === id ? {...section, content: newContent} : section
      )
    )
  }

  //
  const handleSectionDelete = (id: any) => {
    setSections(sections.filter((section: any) => section.id !== id))
    setSelectedSection(null)
  }

  //
  const handleSectionDuplicate = (id: string) => {
    const index = sections.findIndex((section: any) => section.id === id)
    if (index === -1) return

    const newSection: Section = {
      ...sections[index],
      id: `${Date.now()}`, // Assign a unique ID
    }

    const newSections = [...sections]
    newSections.splice(index + 1, 0, newSection) // Insert the duplicate right after the original
    setSections(newSections)
  }

  //
  const handleDragEnd = (result: any) => {
    if (!result.destination) return

    const items = Array.from(sections)
    const [reorderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderedItem)

    setSections(items)
  }

  const handleAddSection = (val: any) => {
    const newSections = [...sections]
    let newVal: any = {
      id: `${Date.now()}`,
      type: val == 'text' ? 'text' : val == 'image' ? 'image' : 'button',
      content: {
        display: 'flex',
        justifyContent: val == 'text' ? 'left' : 'center',
        backgroundColor: '#f5f8fa',
        height: val == 'image' ? 80 : 35,
        width: val == 'image' ? 80 : 120,
        color: '#fff',
        marginTop: '0',
        marginRight: '0',
        marginBottom: '0',
        marginLeft: '0',
        paddingTop: '20',
        paddingRight: '20',
        paddingBottom: '20',
        paddingLeft: '20',
        imageUrl: '',
        title: 'Title One',
        buttonName: 'Click here',
        buttonColor: '#6254FF',
        buttonTextColor: '#fff',
        buttonLink: '',
        borderThickness: '1',
        borderLine: 'solid',
        borderColor: '#000',
        borderInnerTagRadius: '5',
        html:
          val == 'text'
            ? `<p style="font-size: 16px; line-height: 1.6; color: #000;">
              your content will go here
              </p>`
            : val == 'image'
            ? `<div style="background-color: transparent; padding: 30px; text-align: center; border-radius: 8px;">
                <img src="${imageUploaderSquare}" alt="Main Content Image" style="width: 80px; height: 80px; max-width: 100%;  padding: 0px 10p">
              </div>`
            : `<div style="background-color: transparent; text-align: center; border-radius: 10px; max-width: 100%; box-sizing: border-box;">
                <a href="" target="_blank" style="max-width: 100%; box-sizing: border-box;">
                  <button style="width: 120px; height: 30px; background-color: #6254FF; border: 1px solid #000; border-radius: 5px; color: #fff; max-width: 100%; box-sizing: border-box; cursor: pointer"> Click here</button>
                </a></div>
              `,
        image: [
          {
            display: 1,
          },
        ],
        link: [
          {
            display: 1,
          },
        ],
      },
    }

    newSections?.push(newVal)
    setSections(newSections)
  }

  const emailRef = useRef<HTMLDivElement>(null)

  const [showHTMLSection, setShowHTMLSection] = useState<any>(false)
  const [showEmailTestPopup, setShowEmailTestPopup] = useState<any>(false)
  const [htmlContentToSend, setHtmlContentToSend] = useState<any>('')

  const exportHTML = () => {
    if (emailRef.current) {
      const htmlContent = emailRef?.current
      // const htmlContent:any = document.getElementById('email-builder-main')

      const htmlFile: any = getEmailHTMLFile(htmlContent)
      setHtmlContentToSend(htmlContent)
      setSelectedSection(null)
      setShowEmailTestPopup(true)
    }
  }

  const [isDisabled, setIsDisabled] = useState<any>(false)

  //
  const addEmailTemplate = () => {
    setIsDisabled(true)
    const htmlContent: any = getEmailHTMLContent(htmlContentToSend)

    const body = {
      id: id ? id : undefined,
      email: state?.email ? state?.email : 'frontend.developer.propertise278@gmail.com',
      html: htmlContent,
      frontendObject: {
        sections: sections,
      },
      emailSettingId: [],
      templateName: templateName,
    }
    if (id == undefined) {
      ApiPost(`corporate/email_template`, body)
        .then((res: any) => {
          SuccessToast(res?.data?.message)
          navigate(`/settings/crm-residents/email-templates`)
          setIsDisabled(false)
        })
        .catch((err: any) => {
          ErrorToast(err?.message)
          setIsDisabled(false)
        })
    } else {
      ApiPut(`corporate/email_template`, body)
        .then((res: any) => {
          SuccessToast(res?.data?.message)
          navigate(`/settings/crm-residents/email-templates`)
          setIsDisabled(false)
        })
        .catch((err: any) => {
          ErrorToast(err?.message)
          setIsDisabled(false)
        })
    }
  }

  return (
    <>
      <div id='' className='pt-0 mt-0 px-2 ps-1'>
        <div className='d-flex align-items-center'>
          <div className='d-flex align-items-start'>
            <span className='rounded-circle cursor-pointer' onClick={() => navigate(-1)}>
              <img src={backArrow} height={14} width={14} className='me-2' />
            </span>
            <div>
              <h2 className='page-heading m-0 head-text'>Build Email</h2>
            </div>
          </div>
          <button
            type='button'
            className='btn btn-sm fw-bold green-submit-btn px-2 justify-content-center status-w-120 mt-2 ms-auto'
            onClick={() => {
              addEmailTemplate()
            }}
            disabled={templateName?.trim()?.length == 0 || isDisabled}
          >
            Generate
          </button>
        </div>
        <div className='card card-flush gap-5 p-5 py-0 tst mt-8' style={{minHeight: '75vh'}}>
          <div className='card-body pt-0 py-3 px-3'>
            <div className='d-flex align-items-center my-5 mb-10'>
              <button
                type='button'
                className='btn btn-sm fw-bold green-hollow-btn px-2  ps-3 mt-2 status-w-120'
                style={{width: '120px'}}
              >
                <img src={saveGreenImg} height={18} width={18} className='me-5' /> {'  '}Save
              </button>

              <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2 xyz mx-auto'>
                <li
                  className='nav-item text-center me-10 cursor-pointer'
                  onClick={() => {
                    setIsDynamicActive(false)
                  }}
                >
                  <img src={emailDesignerImg} height={40} width={40} />

                  <a
                    className={`nav-link text-active-primary pb-0 mx-0 fs-4 ${
                      !isDynamicActive ? 'active ' : 'fnt-400'
                    }`}
                    data-bs-toggle='tab'
                  >
                    Designer
                  </a>
                </li>
                <li
                  className='nav-item text-center cursor-pointer'
                  onClick={() => {
                    setIsDynamicActive(true)
                  }}
                >
                  <img src={emailDynamicSettingImg} height={40} width={40} />
                  <a
                    className={`nav-link text-active-primary pb-0 mx-0 fs-4 ${
                      isDynamicActive ? 'active ' : 'fnt-400'
                    }`}
                    data-bs-toggle='tab'
                  >
                    Dynamic Settings
                  </a>
                </li>
              </ul>

              <button
                type='button'
                className='btn btn-sm fw-bold green-submit-btn px-2 justify-content-center status-w-120 mt-2'
                onClick={() => {
                  setShowHTMLSection(true)
                  setTimeout(() => {
                    exportHTML()
                  }, 500)
                }}
              >
                Test
              </button>
            </div>

            <div className='separator mx-4'></div>

            <div className='row me-4 ms-0 mt-7'>
              <div className='col-6 ps-0 d-flex'>
                <input
                  //   {...register('expenseName', {required: true})}
                  type='text'
                  className='form-control form-control-solid mytest tenancy-control bg-white'
                  name='expenseName'
                  value={templateName}
                  style={{height: '35px', width: '300px'}}
                  placeholder={'Enter Template Name'}
                  onChange={(e: any) => {
                    let newVal: any =
                      e.target.value != ''
                        ? e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)
                        : e.target.value
                    setTemplateName(newVal)
                  }}
                  autoComplete='off'
                />
                <h3 className='text-danger mb-0 ms-2'>
                  <b>*</b>
                </h3>
              </div>

              <div className='col-6 ps-0 text-center'>
                <div className='d-flex justify-content-center'>
                  <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2 xyz '>
                    <li className='nav-item text-center me-10'>
                      <a
                        className={`nav-link text-active-primary pb-0 mx-0 fs-4 ${
                          isDesktop ? 'active ' : 'fnt-400'
                        }`}
                        data-bs-toggle='tab'
                        onClick={() => {
                          setIsDesktop(true)
                        }}
                      >
                        <img src={desktopImg} height={30} width={30} />
                      </a>
                    </li>
                    <li className='nav-item text-center '>
                      <a
                        className={`nav-link text-active-primary pb-0 mx-0 fs-4 ${
                          !isDesktop ? 'active ' : 'fnt-400'
                        }`}
                        data-bs-toggle='tab'
                        onClick={() => {
                          setIsDesktop(false)
                        }}
                      >
                        <img src={phoneImg} height={30} width={20} />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className='row mt-7' style={{marginLeft: '-5px', marginRight: '-5px'}}>
              {/* Editor Styling */}
              <div className='col-6 card card-flush px-0 py-5 position-relative' style={{maxHeight: '700px', overflow: 'auto'}}>
                {selectedSection == null && (
                  <div
                    className='requestBoxOpacity z-index-2 border-radius-5px d-flex align-items-center justify-content-center'
                    style={{
                      border: '0.5px solid #ccc8c8',
                      boxShadow: '0px 0px 20px 0px rgba(10, 24, 64, -0.8)',
                    }}
                  >
                    <h4 className='labl-gry'>
                      <i>
                        <b>"Select a section to unlock editing options."</b>
                      </i>
                    </h4>
                  </div>
                )}
                <SectionEditor
                  selectedSectionStyling={selectedSectionStyling}
                  setSelectedSectionStyling={(data: any) => {
                    let values = [...sections]

                    sections.map((section: any, index: any) => {
                      if (selectedSection == section.id) {
                        values[index] = data
                      }
                    })

                    setSections(values)
                  }}
                  selectedSectInd={selectedSectionIndex}
                />
              </div>

              {/* Preview */}
              <div className='col-6 card card-flush px-0' style={{backgroundColor: "rgb(245, 248, 250)"}}>
                <div style={{position: "relative"}}>
                {sections && (
                  <div
                    className={`'builder-preview px-7 ps-8 pb-5 h-100' ${!isDesktop ? 'wd-320px mx-auto' : '' }`}
                    style={{
                      backgroundColor: '#f5f8fa',
                      maxHeight: '700px',
                      overflowY: 'auto',
                      overflowX: 'hidden',
                      paddingTop: selectedSectionIndex != null && selectedSectionStyling?.type === 'text' ? '60px' : '20px',
                    }}
                    // onClick={() => handleSectionClick(null)}
                  >
                    {/* Render all sections here */}
                    <DragDropContext onDragEnd={handleDragEnd}>
                      <Droppable droppableId='sections'>
                        {(provided: any) => (
                          <>
                            <div {...provided.droppableProps} ref={provided.innerRef}>
                              <div>
                                {sections.map((section: any, index: any) => (
                                  <Draggable
                                    key={section.id}
                                    draggableId={section.id}
                                    index={index}
                                  >
                                    {(provided: any) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        // {...provided.dragHandleProps}
                                        onClick={() => {
                                          setSelectedSectionIndex(index)
                                          handleSectionClick(section)
                                        }}
                                        className={`cursor-pointer d-flex`}
                                      >
                                        {/* Render section based on its type */}
                                        <div
                                          className={`${
                                            section.id === selectedSection ? 'border' : ''
                                          }`}
                                          style={{
                                            width: selectedSectionIndex == null ? '100%' : '98%',
                                          }}
                                        >
                                          <SectionRenderer
                                            section={section}
                                            isSelected={section.id === selectedSection}
                                            selectedSectionStyling={selectedSectionStyling}
                                            setSelectedSectionStyling={(data: any) => {
                                              let values = [...sections]

                                              sections.map((section: any, index: any) => {
                                                if (selectedSection == section.id) {
                                                  values[index] = data
                                                }
                                              })

                                              setSections(values)
                                            }}
                                            isDesktopPreview={isDesktop}
                                          />
                                        </div>
                                        {/* {section.id === selectedSection && ( */}
                                        <div style={{width: '0px', height: '0px'}}>
                                          <SectionControls
                                            sectionId={section.id}
                                            onDuplicate={handleSectionDuplicate}
                                            onDelete={handleSectionDelete}
                                            dragHandleProps={provided.dragHandleProps}
                                            selectedSection={selectedSection}
                                          />
                                        </div>
                                        {/* )}  */}
                                      </div>
                                    )}
                                  </Draggable>
                                ))}
                              </div>
                            </div>
                            <div className='add-new-section '>
                              <div className='d-flex justify-content-center align-items-center mt-5'>
                                <img src={plusGreen} height={18} width={18} />
                                <h4 className='text-white ms-3 mb-0'>Add New Section</h4>
                              </div>
                              <div className='d-flex justify-content-center align-items-center mt-5 mb-7'>
                                <div className='me-7'>
                                  <OverlayTrigger
                                    placement='top'
                                    delay={{show: 250, hide: 400}}
                                    overlay={
                                      <Tooltip id='button-tooltip-3' className='custom-tooltip'>
                                        {'Add Image'}
                                      </Tooltip>
                                    }
                                  >
                                    <span>
                                      {' '}
                                      <img
                                        src={addImage}
                                        height={24}
                                        width={28}
                                        className='cursor-pointer'
                                        onClick={() => {
                                          handleAddSection('image')
                                        }}
                                      />
                                    </span>
                                  </OverlayTrigger>
                                </div>
                                <div className='me-7'>
                                  <OverlayTrigger
                                    placement='top'
                                    delay={{show: 250, hide: 400}}
                                    overlay={
                                      <Tooltip id='button-tooltip-3' className='custom-tooltip'>
                                        {'Add Text'}
                                      </Tooltip>
                                    }
                                  >
                                    <span>
                                      {' '}
                                      <img
                                        src={addText}
                                        height={24}
                                        width={28}
                                        className='cursor-pointer'
                                        onClick={() => {
                                          handleAddSection('text')
                                        }}
                                      />
                                    </span>
                                  </OverlayTrigger>
                                </div>

                                <div className=''>
                                  <OverlayTrigger
                                    placement='top'
                                    delay={{show: 250, hide: 400}}
                                    overlay={
                                      <Tooltip id='button-tooltip-3' className='custom-tooltip'>
                                        {'Add CTA'}
                                      </Tooltip>
                                    }
                                  >
                                    <span>
                                      {' '}
                                      <img
                                        src={addCTA}
                                        height={26}
                                        width={30}
                                        className='cursor-pointer'
                                        onClick={() => {
                                          handleAddSection('button')
                                        }}
                                      />
                                    </span>
                                  </OverlayTrigger>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </div>
                )}

              </div>
                {/* for HTML export */}
                {sections && showHTMLSection && (
                  <div
                    className='builder-preview px-7 py-5 h-100'
                    style={{backgroundColor: '#f5f8fa'}}
                    id='email-builder-main'
                    ref={emailRef}
                  >
                    <>
                      <div>
                        {sections?.map((section: any, index: any) => (
                          <div className={`d-flex`}>
                            {/* Render section based on its type */}
                            <div
                              className={`w-100 ${section.id === selectedSection ? 'border' : ''}`}
                            >
                              <HTMLExport
                                section={section}
                                isSelected={section.id === selectedSection}
                                selectedSectionStyling={selectedSectionStyling}
                                setSelectedSectionStyling={(data: any) => {
                                  // let values = [...sections]

                                  // sections.map((section: any, index: any) => {
                                  //   if (selectedSection == section.id) {
                                  //     values[index] = data
                                  //   }
                                  // })

                                  // setSections(values)
                                }}
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    </>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {sections && htmlContentToSend && showEmailTestPopup && (
        <TestEmailTemplate
          show={showEmailTestPopup}
          handleClose={() => {
            setShowHTMLSection(false)
            setShowEmailTestPopup(false)
          }}
          htmlContent={htmlContentToSend}
          htmlSections={sections}
        />
      )}
    </>
  )
}

export default EmailBuilder
