import React from 'react'
import {useState} from 'react'
import {Row, Col, OverlayTrigger, Tooltip} from 'react-bootstrap'
import {useNavigate} from 'react-router-dom'
import './style.scss'
import crossBtn from '../../../img/cross-button.png'
import redCross from '../../../img/remove.png'
import {AiOutlineExclamationCircle} from 'react-icons/ai'

const LeasingCommercial = () => {
  const navigate = useNavigate()
  const [roleData, setRoleData] = useState<any>()
 
  const handleChangeRoleData = () => {}
  return (
    <>
     <div id='' className='pt-0 mt-0 px-2 ps-0'>
        <div className='table-responsive '>
          <table
            className='table align-start table-row-dashed fs-5 gy-5 '
            id='kt_ecommerce_sales_table'
          >
            <thead>
              <th className='text-start min-w-25px'></th>
              <th className='text-start min-w-300px m-w-300'></th>

              <th className='text-start min-w-100px text-capitalize'>
                {' '}
                View List{' '}
                <span>
                  <OverlayTrigger
                   placement='bottom'
                    delay={{show: 250, hide: 400}}
                    overlay={
                      <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                        {' '}
                        User can view the list of lease agreements.
                      </Tooltip>
                    }
                  >
                    <span>
                      {' '}
                      <AiOutlineExclamationCircle
                        color='#35475e '
                        fontSize={14}
                        fontWeight={600}
                        className='ms-0 cursor-pointer'
                        style={{cursor: 'pointer', marginBottom: '2px'}}
                      />
                    </span>
                  </OverlayTrigger>
                </span>
              </th>
              <th className='text-start min-w-125px text-capitalize'>
                {' '}
                View Details{' '}
                <span>
                  <OverlayTrigger
                   placement='bottom'
                    delay={{show: 250, hide: 400}}
                    overlay={
                      <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                        {' '}
                        User can access and view the details of a lease agreement.
                      </Tooltip>
                    }
                  >
                    <span>
                      {' '}
                      <AiOutlineExclamationCircle
                        color='#35475e '
                        fontSize={14}
                        fontWeight={600}
                        className='ms-0 cursor-pointer'
                        style={{cursor: 'pointer', marginBottom: '2px'}}
                      />
                    </span>
                  </OverlayTrigger>
                </span>
              </th>
              <th className='text-start min-w-100px text-capitalize'>
                {' '}
                Create{' '}
                <span>
                  <OverlayTrigger
                    placement='bottom'
                    delay={{show: 250, hide: 400}}
                    overlay={
                      <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                        {' '}
                        User can initiate and create lease agreements. User will be available as an
                        initiator and inputter in the lease agreements workflow except for the
                        ‘Financials’ section.
                      </Tooltip>
                    }
                  >
                    <span>
                      {' '}
                      <AiOutlineExclamationCircle
                        color='#35475e '
                        fontSize={14}
                        fontWeight={600}
                        className='ms-0 cursor-pointer'
                        style={{cursor: 'pointer', marginBottom: '2px'}}
                      />
                    </span>
                  </OverlayTrigger>
                </span>
              </th>
              <th className='text-start min-w-75px text-capitalize'>
                {' '}
                Edit{' '}
                <span>
                  <OverlayTrigger
                   placement='bottom'
                    delay={{show: 250, hide: 400}}
                    overlay={
                      <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                        {' '}
                        User can edit a created lease agreement.
                      </Tooltip>
                    }
                  >
                    <span>
                      {' '}
                      <AiOutlineExclamationCircle
                        color='#35475e '
                        fontSize={14}
                        fontWeight={600}
                        className='ms-0 cursor-pointer'
                        style={{cursor: 'pointer', marginBottom: '2px'}}
                      />
                    </span>
                  </OverlayTrigger>
                </span>
              </th>
              <th className='text-start min-w-100px text-capitalize'>
                {' '}
                Delete{' '}
                <span>
                  <OverlayTrigger
                   placement='bottom'
                    delay={{show: 250, hide: 400}}
                    overlay={
                      <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                        {' '}
                        User can delete draft, cancelled or expired lease agreements.
                      </Tooltip>
                    }
                  >
                    <span>
                      {' '}
                      <AiOutlineExclamationCircle
                        color='#35475e '
                        fontSize={14}
                        fontWeight={600}
                        className='ms-0 cursor-pointer'
                        style={{cursor: 'pointer', marginBottom: '2px'}}
                      />
                    </span>
                  </OverlayTrigger>
                </span>
              </th>
              <th className='text-start min-w-100px text-capitalize'>
                {' '}
                Approve{' '}
                <span>
                  <OverlayTrigger
                    placement='bottom'
                    delay={{show: 250, hide: 400}}
                    overlay={
                      <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                        {' '}
                        User can approve a lease agreement request before it is shared with a
                        tenant. User will be available as an approver in the lease agreements
                        workflow and approve/edit a lease that is under approval.
                      </Tooltip>
                    }
                  >
                    <span>
                      {' '}
                      <AiOutlineExclamationCircle
                        color='#35475e '
                        fontSize={14}
                        fontWeight={600}
                        className='ms-0 cursor-pointer'
                        style={{cursor: 'pointer', marginBottom: '2px'}}
                      />
                    </span>
                  </OverlayTrigger>
                </span>
              </th>
            </thead>
            <tbody className='fw-semibold text-gray-600 role-test'>
              <tr>
                <td className='text-start ps-0'>
                  <img src={redCross} height={16} width={16} className='me-2' />
                </td>
                <td className='text-start ps-0 pe-5'>
                  <p className='user-main-head pe-2 mb-1' style={{fontWeight: '700'}}>
                    Leasing Agreements{' '}
                  </p>
                  <p className='user-sub-head mb-2'>
                    <i>Generate and manage lease agreements.</i>
                  </p>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='vacant0'
                      name='none'
                      checked={false}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td className='text-start ps-0'></td>
                <td className='text-start ps-0 pe-5'>
                  <p className='user-sub2 pe-2 mb-0 ps-5 ms-3' style={{fontWeight: '600'}}>
                    {' '}
                    - Tenant Details{' '}
                  </p>
                  <p className='user-sub-head mb-2 ps-5 ms-3'>
                    <i>Selecting tenants and managing tenant information in a lease agreement.</i>
                  </p>
                </td>
                <td className='text-center ps-0'></td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={false}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'></div>
                </td>
                <td className='text-center ps-0'></td>
              </tr>
              <tr>
                <td className='text-start ps-0'></td>
                <td className='text-start ps-0 pe-5'>
                  <p className='user-sub2 pe-2 mb-0 ps-5 ms-3' style={{fontWeight: '600'}}>
                    {' '}
                    - Contract Details{' '}
                  </p>
                  <p className='user-sub-head mb-2 ps-5 ms-3'>
                    <i>
                      Setting the details of a lease agreement such as duration, contract no.,
                      benefits, rent values, security deposits, uploading documents related to the
                      contract agreement.
                    </i>
                  </p>
                </td>
                <td className='text-center ps-0'></td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={false}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'></div>
                </td>
                <td className='text-center ps-0'></td>
              </tr>

              {/* Cancellations */}
              <tr style={{borderBottom: 'none', height: '35px'}}>
                <td className='text-start min-w-25px user-th'></td>
                <td className='text-start min-w-300px m-w-300 user-th'></td>

                <td className='text-start min-w-100px text-capitalize user-th ps-0'>
                  {' '}
                  Allow{' '}
                  <span>
                    <OverlayTrigger
                      placement='top'
                      delay={{show: 250, hide: 400}}
                      overlay={
                        <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                          {' '}
                          User can cancel upcoming leases (leases that have not yet reached their
                          start date).
                        </Tooltip>
                      }
                    >
                      <span>
                        {' '}
                        <AiOutlineExclamationCircle
                          color='#35475e '
                          fontSize={14}
                          fontWeight={600}
                          className='ms-2 cursor-pointer mb-1'
                          style={{cursor: 'pointer', marginBottom: '2px'}}
                        />
                      </span>
                    </OverlayTrigger>
                  </span>
                </td>
                <td className='text-start min-w-125px text-capitalize user-th'></td>
                <td className='text-start min-w-100px text-capitalize user-th'></td>
                <td className='text-start min-w-75px text-capitalize user-th'></td>
                <td className='text-start min-w-100px text-capitalize user-th'></td>
                <td className='text-start min-w-100px text-capitalize user-th'></td>
              </tr>
              <tr>
                <td className='text-start ps-0'>
                  <img src={redCross} height={16} width={16} className='me-2' />
                </td>
                <td className='text-start ps-0 pe-5'>
                  <p className='user-main-head pe-2 mb-1' style={{fontWeight: '700'}}>
                    Cancellations{' '}
                  </p>
                  <p className='user-sub-head mb-2'>
                    <i>Cancel upcoming leases on booked properties.</i>
                  </p>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'></td>
                <td className='text-center ps-0'></td>
                <td className='text-center ps-0'></td>
                <td className='text-center ps-0'></td>
                <td className='text-center ps-0'></td>
              </tr>

              {/* Renewals */}
              <tr
                className='user-th'
                style={{
                  borderBottom: 'none',
                  height: '35px',
                }}
              >
                <td className='text-start min-w-25px user-th'></td>
                <td className='text-start min-w-300px m-w-300 user-th'></td>

                <td className='text-start min-w-100px text-capitalize user-th ps-0'>
                  {' '}
                  View List{' '}
                  <span>
                    <OverlayTrigger
                      placement='top'
                      delay={{show: 250, hide: 400}}
                      overlay={
                        <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                          {' '}
                          User can view the list of lease renewal requests submitted.
                        </Tooltip>
                      }
                    >
                      <span>
                        {' '}
                        <AiOutlineExclamationCircle
                          color='#35475e '
                          fontSize={14}
                          fontWeight={600}
                          className='ms-2 cursor-pointer mb-1'
                          style={{cursor: 'pointer', marginBottom: '2px'}}
                        />
                      </span>
                    </OverlayTrigger>
                  </span>
                </td>
                <td
                  className='text-start min-w-125px text-capitalize user-th ps-0'
                  style={{paddingTop: '1.25rem', paddingBottom: '1.25rem'}}
                >
                  {' '}
                  View Details{' '}
                  <span>
                    <OverlayTrigger
                      placement='top'
                      delay={{show: 250, hide: 400}}
                      overlay={
                        <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                          {' '}
                          User can access and view the details of a lease renewal request. User can
                          access and view the details of a lease agreement.
                        </Tooltip>
                      }
                    >
                      <span>
                        {' '}
                        <AiOutlineExclamationCircle
                          color='#35475e '
                          fontSize={14}
                          fontWeight={600}
                          className='ms-2 cursor-pointer mb-1'
                          style={{cursor: 'pointer', marginBottom: '2px'}}
                        />
                      </span>
                    </OverlayTrigger>
                  </span>
                </td>
                <td className='text-start min-w-100px text-capitalize user-th ps-0'>
                  {' '}
                  Create{' '}
                  <span>
                    <OverlayTrigger
                      placement='top'
                      delay={{show: 250, hide: 400}}
                      overlay={
                        <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                          {' '}
                          User can initiate a lease renewal request and generate a lease renewal.
                          User will be available as an initiator and inputter in the lease renewal
                          workflow except for the ‘Financials’ section.
                        </Tooltip>
                      }
                    >
                      <span>
                        {' '}
                        <AiOutlineExclamationCircle
                          color='#35475e '
                          fontSize={14}
                          fontWeight={600}
                          className='ms-2 cursor-pointer mb-1'
                          style={{cursor: 'pointer', marginBottom: '2px'}}
                        />
                      </span>
                    </OverlayTrigger>
                  </span>
                </td>
                <td className='text-start min-w-75px text-capitalize user-th ps-0'>
                  {' '}
                  Edit{' '}
                  <span>
                    <OverlayTrigger
                      placement='top'
                      delay={{show: 250, hide: 400}}
                      overlay={
                        <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                          {' '}
                          User can edit and cancel a lease renewal request. User will be available
                          as an inputter in the lease renewal workflow except for the ‘Financials’
                          section.
                        </Tooltip>
                      }
                    >
                      <span>
                        {' '}
                        <AiOutlineExclamationCircle
                          color='#35475e '
                          fontSize={14}
                          fontWeight={600}
                          className='ms-2 cursor-pointer mb-1'
                          style={{cursor: 'pointer', marginBottom: '2px'}}
                        />
                      </span>
                    </OverlayTrigger>
                  </span>
                </td>
                <td className='text-start min-w-100px text-capitalize user-th ps-0'>
                  {' '}
                  Delete{' '}
                  <span>
                    <OverlayTrigger
                      placement='top'
                      delay={{show: 250, hide: 400}}
                      overlay={
                        <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                          {' '}
                          User can delete a cancelled lease renewal request.
                        </Tooltip>
                      }
                    >
                      <span>
                        {' '}
                        <AiOutlineExclamationCircle
                          color='#35475e '
                          fontSize={14}
                          fontWeight={600}
                          className='ms-2 cursor-pointer mb-1'
                          style={{cursor: 'pointer', marginBottom: '2px'}}
                        />
                      </span>
                    </OverlayTrigger>
                  </span>
                </td>
                <td className='text-start min-w-100px text-capitalize user-th ps-0'>
                  {' '}
                  Approve{' '}
                  <span>
                    <OverlayTrigger
                      placement='top'
                      delay={{show: 250, hide: 400}}
                      overlay={
                        <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                          {' '}
                          User can approve a lease renewal request before it is shared with a
                          tenant. User will be available as an approver in the lease renewal
                          workflow and approve/edit a renewal that is under approval.
                        </Tooltip>
                      }
                    >
                      <span>
                        {' '}
                        <AiOutlineExclamationCircle
                          color='#35475e '
                          fontSize={14}
                          fontWeight={600}
                          className='ms-2 cursor-pointer mb-1'
                          style={{cursor: 'pointer', marginBottom: '2px'}}
                        />
                      </span>
                    </OverlayTrigger>
                  </span>
                </td>
              </tr>
              <tr className='mt-2'>
                <td className='text-start ps-0'>
                  <img src={redCross} height={16} width={16} className='me-2' />
                </td>
                <td className='text-start ps-0 pe-5'>
                  <p className='user-main-head pe-2 mb-1' style={{fontWeight: '700'}}>
                    Renewals{' '}
                  </p>
                  <p className='user-sub-head mb-2'>
                    <i>Renew and extend existing leases for existing tenants.</i>
                  </p>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>

                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
              </tr>

              {/* Terminations */}
              <tr
                className='user-th'
                style={{
                  borderBottom: 'none',
                  height: '35px',
                }}
              >
                <td className='text-start min-w-25px user-th'></td>
                <td className='text-start min-w-300px m-w-300 user-th'></td>

                <td className='text-start min-w-100px text-capitalize user-th ps-0'>
                  {' '}
                  View List{' '}
                  <span>
                    <OverlayTrigger
                      placement='top'
                      delay={{show: 250, hide: 400}}
                      overlay={
                        <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                          {' '}
                          User can view the list of lease termination requests submitted.
                        </Tooltip>
                      }
                    >
                      <span>
                        {' '}
                        <AiOutlineExclamationCircle
                          color='#35475e '
                          fontSize={14}
                          fontWeight={600}
                          className='ms-2 cursor-pointer mb-1'
                          style={{cursor: 'pointer', marginBottom: '2px'}}
                        />
                      </span>
                    </OverlayTrigger>
                  </span>
                </td>
                <td
                  className='text-start min-w-125px text-capitalize user-th ps-0'
                  style={{paddingTop: '1.25rem', paddingBottom: '1.25rem'}}
                >
                  {' '}
                  View Details{' '}
                  <span>
                    <OverlayTrigger
                      placement='top'
                      delay={{show: 250, hide: 400}}
                      overlay={
                        <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                          {' '}
                          User can access and view the details of a lease termination request.
                        </Tooltip>
                      }
                    >
                      <span>
                        {' '}
                        <AiOutlineExclamationCircle
                          color='#35475e '
                          fontSize={14}
                          fontWeight={600}
                          className='ms-2 cursor-pointer mb-1'
                          style={{cursor: 'pointer', marginBottom: '2px'}}
                        />
                      </span>
                    </OverlayTrigger>
                  </span>
                </td>
                <td className='text-start min-w-100px text-capitalize user-th ps-0'>
                  {' '}
                  Create{' '}
                  <span>
                    <OverlayTrigger
                      placement='top'
                      delay={{show: 250, hide: 400}}
                      overlay={
                        <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                          {' '}
                          User can initiate a lease termination request and terminate an active
                          lease. User will be available as an initiator and inputter in the lease
                          termination workflow except for the ‘Financials’ section.
                        </Tooltip>
                      }
                    >
                      <span>
                        {' '}
                        <AiOutlineExclamationCircle
                          color='#35475e '
                          fontSize={14}
                          fontWeight={600}
                          className='ms-2 cursor-pointer mb-1'
                          style={{cursor: 'pointer', marginBottom: '2px'}}
                        />
                      </span>
                    </OverlayTrigger>
                  </span>
                </td>
                <td className='text-start min-w-75px text-capitalize user-th ps-0'>
                  {' '}
                  Edit{' '}
                  <span>
                    <OverlayTrigger
                      placement='top'
                      delay={{show: 250, hide: 400}}
                      overlay={
                        <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                          {' '}
                          User can edit and cancel a lease renewal request. User will be available
                          as an inputter in the lease termination workflow except for the
                          ‘Financials’ section.
                        </Tooltip>
                      }
                    >
                      <span>
                        {' '}
                        <AiOutlineExclamationCircle
                          color='#35475e '
                          fontSize={14}
                          fontWeight={600}
                          className='ms-2 cursor-pointer mb-1'
                          style={{cursor: 'pointer', marginBottom: '2px'}}
                        />
                      </span>
                    </OverlayTrigger>
                  </span>
                </td>
                <td className='text-start min-w-100px text-capitalize user-th ps-0'>
                  {' '}
                  Delete{' '}
                  <span>
                    <OverlayTrigger
                      placement='top'
                      delay={{show: 250, hide: 400}}
                      overlay={
                        <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                          {' '}
                          User can delete a cancelled lease renewal request.
                        </Tooltip>
                      }
                    >
                      <span>
                        {' '}
                        <AiOutlineExclamationCircle
                          color='#35475e '
                          fontSize={14}
                          fontWeight={600}
                          className='ms-2 cursor-pointer mb-1'
                          style={{cursor: 'pointer', marginBottom: '2px'}}
                        />
                      </span>
                    </OverlayTrigger>
                  </span>
                </td>
                <td className='text-start min-w-100px text-capitalize user-th ps-0'>
                  {' '}
                  Approve{' '}
                  <span>
                    <OverlayTrigger
                      placement='top'
                      delay={{show: 250, hide: 400}}
                      overlay={
                        <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                          {' '}
                          User can approve a lease termination request before it is shared with a
                          tenant. User will be available as an approver in the lease termination
                          workflow and approve/edit a termination that is under approval.
                        </Tooltip>
                      }
                    >
                      <span>
                        {' '}
                        <AiOutlineExclamationCircle
                          color='#35475e '
                          fontSize={14}
                          fontWeight={600}
                          className='ms-2 cursor-pointer mb-1'
                          style={{cursor: 'pointer', marginBottom: '2px'}}
                        />
                      </span>
                    </OverlayTrigger>
                  </span>
                </td>
              </tr>
              <tr className='mt-2'>
                <td className='text-start ps-0'>
                  <img src={redCross} height={16} width={16} className='me-2' />
                </td>
                <td className='text-start ps-0 pe-5'>
                  <p className='user-main-head pe-2 mb-1' style={{fontWeight: '700'}}>
                    Terminations{' '}
                  </p>
                  <p className='user-sub-head mb-2'>
                    <i>Terminate existing leases for existing tenants.</i>
                  </p>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>

                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
              </tr>

              {/* Lease Deletion */}
              <tr style={{borderBottom: 'none', height: '35px'}}>
                <td className='text-start min-w-25px user-th'></td>
                <td className='text-start min-w-300px m-w-300 user-th'></td>

                <td className='text-start min-w-100px text-capitalize user-th ps-0'>
                  {' '}
                  Allow{' '}
                  <span>
                    <OverlayTrigger
                      placement='top'
                      delay={{show: 250, hide: 400}}
                      overlay={
                        <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                          {' '}
                          User can permanently remove deleted leases from records. Once removed,
                          records cannot be recovered.
                        </Tooltip>
                      }
                    >
                      <span>
                        {' '}
                        <AiOutlineExclamationCircle
                          color='#35475e '
                          fontSize={14}
                          fontWeight={600}
                          className='ms-2 cursor-pointer mb-1'
                          style={{cursor: 'pointer', marginBottom: '2px'}}
                        />
                      </span>
                    </OverlayTrigger>
                  </span>
                </td>
                <td className='text-start min-w-125px text-capitalize user-th'></td>
                <td className='text-start min-w-100px text-capitalize user-th'></td>
                <td className='text-start min-w-75px text-capitalize user-th'></td>
                <td className='text-start min-w-100px text-capitalize user-th'></td>
                <td className='text-start min-w-100px text-capitalize user-th'></td>
              </tr>
              <tr>
                <td className='text-start ps-0'>
                  <img src={redCross} height={16} width={16} className='me-2' />
                </td>
                <td className='text-start ps-0 pe-5'>
                  <p className='user-main-head pe-2 mb-1' style={{fontWeight: '700'}}>
                    Lease Deletion{' '}
                  </p>
                  <p className='user-sub-head mb-2'>
                    <i>Permanently remove deleted leases from records.</i>
                  </p>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'></td>
                <td className='text-center ps-0'></td>
                <td className='text-center ps-0'></td>
                <td className='text-center ps-0'></td>
                <td className='text-center ps-0'></td>
              </tr>

              {/* Lease Financials */}
              <tr
                className='user-th'
                style={{
                  borderBottom: 'none',
                  height: '35px',
                }}
              >
                <td className='text-start min-w-25px user-th'></td>
                <td className='text-start min-w-300px m-w-300 user-th'></td>

                <td className='text-start min-w-100px text-capitalize user-th ps-0'>
                  {' '}
                  View List{' '}
                  <span>
                    <OverlayTrigger
                      placement='top'
                      delay={{show: 250, hide: 400}}
                      overlay={
                        <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                          {' '}
                          User can view the list of payments within a lease agreement under approval
                          and post approval.
                        </Tooltip>
                      }
                    >
                      <span>
                        {' '}
                        <AiOutlineExclamationCircle
                          color='#35475e '
                          fontSize={14}
                          fontWeight={600}
                          className='ms-2 cursor-pointer mb-1'
                          style={{cursor: 'pointer', marginBottom: '2px'}}
                        />
                      </span>
                    </OverlayTrigger>
                  </span>
                </td>
                <td
                  className='text-start min-w-125px text-capitalize user-th ps-0'
                  style={{paddingTop: '1.25rem', paddingBottom: '1.25rem'}}
                >
                  {' '}
                  View Details{' '}
                  <span>
                    <OverlayTrigger
                      placement='top'
                      delay={{show: 250, hide: 400}}
                      overlay={
                        <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                          {' '}
                          User can access and view the details of a payment within a lease agreement
                          under approval and post approval.
                        </Tooltip>
                      }
                    >
                      <span>
                        {' '}
                        <AiOutlineExclamationCircle
                          color='#35475e '
                          fontSize={14}
                          fontWeight={600}
                          className='ms-2 cursor-pointer mb-1'
                          style={{cursor: 'pointer', marginBottom: '2px'}}
                        />
                      </span>
                    </OverlayTrigger>
                  </span>
                </td>
                <td className='text-start min-w-100px text-capitalize user-th ps-0'>
                  {' '}
                  Create{' '}
                  <span>
                    <OverlayTrigger
                      placement='top'
                      delay={{show: 250, hide: 400}}
                      overlay={
                        <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                          {' '}
                          User can create a payment within a lease agreement under approval and post
                          approval. User will be available as an initiator and inputter for the
                          ‘Financials’ section in the lease agreements workflow.
                        </Tooltip>
                      }
                    >
                      <span>
                        {' '}
                        <AiOutlineExclamationCircle
                          color='#35475e '
                          fontSize={14}
                          fontWeight={600}
                          className='ms-2 cursor-pointer mb-1'
                          style={{cursor: 'pointer', marginBottom: '2px'}}
                        />
                      </span>
                    </OverlayTrigger>
                  </span>
                </td>
                <td className='text-start min-w-75px text-capitalize user-th ps-0'>
                  {' '}
                  Edit{' '}
                  <span>
                    <OverlayTrigger
                      placement='top'
                      delay={{show: 250, hide: 400}}
                      overlay={
                        <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                          {' '}
                          User can edit a payment within a lease agreement under approval or post
                          approval. User will be available as an inputter for the ‘Financials’
                          section in the lease agreements workflow.
                        </Tooltip>
                      }
                    >
                      <span>
                        {' '}
                        <AiOutlineExclamationCircle
                          color='#35475e '
                          fontSize={14}
                          fontWeight={600}
                          className='ms-2 cursor-pointer mb-1'
                          style={{cursor: 'pointer', marginBottom: '2px'}}
                        />
                      </span>
                    </OverlayTrigger>
                  </span>
                </td>
                <td className='text-start min-w-100px text-capitalize user-th ps-0'>
                  {' '}
                  Delete{' '}
                  <span>
                    <OverlayTrigger
                      placement='top'
                      delay={{show: 250, hide: 400}}
                      overlay={
                        <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                          {' '}
                          User can delete a payment within a lease agreement under approval and post
                          approval.
                        </Tooltip>
                      }
                    >
                      <span>
                        {' '}
                        <AiOutlineExclamationCircle
                          color='#35475e '
                          fontSize={14}
                          fontWeight={600}
                          className='ms-2 cursor-pointer mb-1'
                          style={{cursor: 'pointer', marginBottom: '2px'}}
                        />
                      </span>
                    </OverlayTrigger>
                  </span>
                </td>
                <td className='text-start min-w-100px text-capitalize user-th ps-0'>
                  {' '}
                  Approve{' '}
                  <span>
                    <OverlayTrigger
                      placement='top'
                      delay={{show: 250, hide: 400}}
                      overlay={
                        <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                          {' '}
                          User can approve a payment before it is shared with a tenant. User will be
                          available as an approver for the ‘Financials’ section in the lease
                          agreements workflow and approve/edit the section when the lease is under
                          approval.
                        </Tooltip>
                      }
                    >
                      <span>
                        {' '}
                        <AiOutlineExclamationCircle
                          color='#35475e '
                          fontSize={14}
                          fontWeight={600}
                          className='ms-2 cursor-pointer mb-1'
                          style={{cursor: 'pointer', marginBottom: '2px'}}
                        />
                      </span>
                    </OverlayTrigger>
                  </span>
                </td>
              </tr>
              <tr className='mt-2'>
                <td className='text-start ps-0'>
                  <img src={redCross} height={16} width={16} className='me-2' />
                </td>
                <td className='text-start ps-0 pe-5'>
                  <p className='user-main-head pe-2 mb-1' style={{fontWeight: '700'}}>
                    Lease Financials
                  </p>
                  <p className='user-sub-head mb-2'>
                    <i>Record and manage payments within each lease agreement.</i>
                  </p>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>

                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
              </tr>
              {/* Invoicing */}
              <tr>
                <td className='text-start ps-0'></td>
                <td className='text-start ps-0 pe-5'>
                  <p className='user-sub2 pe-2 mb-0 ps-5 ms-3' style={{fontWeight: '600'}}>
                    {' '}
                    - Invoicing
                  </p>
                  <p className='user-sub-head mb-2 ps-5 ms-3'>
                    <i>Generate or upload invoices against the recorded payment.</i>
                  </p>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={false}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'></div>
                </td>
                <td className='text-center ps-0'></td>
              </tr>
              {/* Receipts */}
              <tr>
                <td className='text-start ps-0'></td>
                <td className='text-start ps-0 pe-5'>
                  <p className='user-sub2 pe-2 mb-0 ps-5 ms-3' style={{fontWeight: '600'}}>
                    {' '}
                    - Receipts
                  </p>
                  <p className='user-sub-head mb-2 ps-5 ms-3'>
                    <i>Generate or upload receipts against invoices for payments received.</i>
                  </p>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={false}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'></div>
                </td>
                <td className='text-center ps-0'></td>
              </tr>
              {/* Refunds */}
              <tr>
                <td className='text-start ps-0'></td>
                <td className='text-start ps-0 pe-5'>
                  <p className='user-sub2 pe-2 mb-0 ps-5 ms-3' style={{fontWeight: '600'}}>
                    {' '}
                    - Refunds
                  </p>
                  <p className='user-sub-head mb-2 ps-5 ms-3'>
                    <i>Generate or upload refund receipts for received payments.</i>
                  </p>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={false}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'></div>
                </td>
                <td className='text-center ps-0'></td>
              </tr>
              {/* Credit Notes */}
              <tr>
                <td className='text-start ps-0'></td>
                <td className='text-start ps-0 pe-5'>
                  <p className='user-sub2 pe-2 mb-0 ps-5 ms-3' style={{fontWeight: '600'}}>
                    {' '}
                    - Credit Notes
                  </p>
                  <p className='user-sub-head mb-2 ps-5 ms-3'>
                    <i>
                      Generate or upload debit notes against invoices for undercharged amounts or
                      for cancelling invoices.
                    </i>
                  </p>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={false}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'></div>
                </td>
                <td className='text-center ps-0'></td>
              </tr>

              {/*Items */}
              <tr>
                <td className='text-start ps-0'></td>
                <td className='text-start ps-0 pe-5'>
                  <p className='user-sub2 pe-2 mb-0 ps-5 ms-3' style={{fontWeight: '600'}}>
                    {' '}
                    - Items
                  </p>
                  <p className='user-sub-head mb-2 ps-5 ms-3'>
                    <i>
                      Add and select item categories and define the amounts and quantities for each
                      item added to your payment record.
                    </i>
                  </p>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={false}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'></div>
                </td>
                <td className='text-center ps-0'></td>
              </tr>

              {/* VAT */}
              <tr>
                <td className='text-start ps-0'></td>
                <td className='text-start ps-0 pe-5'>
                  <p className='user-sub2 pe-2 mb-0 ps-5 ms-3' style={{fontWeight: '600'}}>
                    {' '}
                    - VAT
                  </p>
                  <p className='user-sub-head mb-2 ps-5 ms-3'>
                    <i>
                      Set and charge VAT to the amount of each item category added to your payment.
                    </i>
                  </p>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={false}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'></div>
                </td>
                <td className='text-center ps-0'></td>
              </tr>

              {/* Late Payment Fees */}
              <tr>
                <td className='text-start ps-0'></td>
                <td className='text-start ps-0 pe-5'>
                  <p className='user-sub2 pe-2 mb-0 ps-5 ms-3' style={{fontWeight: '600'}}>
                    {' '}
                    - Late Payment Fees
                  </p>
                  <p className='user-sub-head mb-2 ps-5 ms-3'>
                    <i>Apply late payment fees for each item category added to your payment.</i>
                  </p>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={false}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'></div>
                </td>
                <td className='text-center ps-0'></td>
              </tr>

              {/* Payment Method */}
              <tr>
                <td className='text-start ps-0'></td>
                <td className='text-start ps-0 pe-5'>
                  <p className='user-sub2 pe-2 mb-0 ps-5 ms-3' style={{fontWeight: '600'}}>
                    {' '}
                    - Payment Method
                  </p>
                  <p className='user-sub-head mb-2 ps-5 ms-3'>
                    <i>Select the payment method you want the recipient to make a payment with.</i>
                  </p>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={false}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'></div>
                </td>
                <td className='text-center ps-0'></td>
              </tr>

              {/*  Payment Reminders */}
              <tr>
                <td className='text-start ps-0'></td>
                <td className='text-start ps-0 pe-5'>
                  <p className='user-sub2 pe-2 mb-0 ps-5 ms-3' style={{fontWeight: '600'}}>
                    {' '}
                    - Payment Reminders
                  </p>
                  <p className='user-sub-head mb-2 ps-5 ms-3'>
                    <i>
                      Set payment reminders against the payment due date to remind recipients on
                      their payment schedule.
                    </i>
                  </p>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={false}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'></div>
                </td>
                <td className='text-center ps-0'></td>
              </tr>

              {/*  Attachments */}
              <tr>
                <td className='text-start ps-0'></td>
                <td className='text-start ps-0 pe-5'>
                  <p className='user-sub2 pe-2 mb-0 ps-5 ms-3' style={{fontWeight: '600'}}>
                    {' '}
                    - Attachments
                  </p>
                  <p className='user-sub-head mb-2 ps-5 ms-3'>
                    <i>Attach documents relevant to the overall payment record.</i>
                  </p>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={false}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'></div>
                </td>
                <td className='text-center ps-0'></td>
              </tr>

              {/*  Notes */}
              <tr>
                <td className='text-start ps-0'></td>
                <td className='text-start ps-0 pe-5'>
                  <p className='user-sub2 pe-2 mb-0 ps-5 ms-3' style={{fontWeight: '600'}}>
                    {' '}
                    - Notes
                  </p>
                  <p className='user-sub-head mb-2 ps-5 ms-3'>
                    <i>Add notes related to the overall payment record.</i>
                  </p>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={false}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'></div>
                </td>
                <td className='text-center ps-0'></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}

export default LeasingCommercial
