import React, {useEffect} from 'react'
import {useState} from 'react'
import {Row, Col, OverlayTrigger, Tooltip} from 'react-bootstrap'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import backArrow from '../../../../../img/back-arrow.png'
import emailTemplateOneImg from '../../../../../img/email-template-one.png'
import emailTemplateTwoImg from '../../../../../img/email-template-two.png'
import whiteTick from '../../../../../img/onboard-tick.png'

const SelectTemplates = () => {
  const navigate = useNavigate()

  const {id} = useParams()
  const {state} = useLocation()

  const [templates, SelectTemplates] = useState<any>([
    {
      src: emailTemplateOneImg,
      name: 'Template 1',
      url: '/settings/crm-residents/email-templates/select/template-01',
    },
    {
      src: emailTemplateTwoImg,
      name: 'Template 2',
      url: '/settings/crm-residents/email-templates/select/template-02',
    },
  ])

  const [selectedTemplateInd, setSelectedTemplateInd] = useState<any>(-1)
  const [selectedTemplate, setSelectedTemplate] = useState<any>({})

  return (
    <>
      <div id='' className='pt-0 mt-0 px-2 ps-1'>
        <div className='d-flex align-items-center'>
          <div className='d-flex align-items-start'>
            <span
              className='rounded-circle cursor-pointer'
              onClick={() => navigate(`/settings/crm-residents/email-templates`)}
            >
              <img src={backArrow} height={14} width={14} className='me-2' />
            </span>
            <div>
              <h2 className='page-heading m-0 head-text'>{'Email Builder'}</h2>
            </div>
          </div>
        </div>

        <div className='card card-flush gap-5 p-5 pt-1 pb-0 tst mt-8' style={{minHeight: '75vh'}}>
          <div className='card-body pt-0 table-responsive mt-5 py-3 px-3'>
            <div className='d-flex align-items-start'>
              <div className='d-flex align-items-start'>
                <div>
                  <h3 className='page-heading mb-2'>
                    <b>{'Select Template'}</b>
                  </h3>
                  <p className='labl-gry fs-5 mb-0'>
                    Select a template you would like to use for your communication.{' '}
                  </p>
                </div>
              </div>
              <button
                type='button'
                className='btn btn-sm fw-bold green-submit-btn px-2 justify-content-center status-w-180 ms-auto'
                onClick={() => {
                  if (id != undefined) {
                    navigate(`${selectedTemplate?.url}/${id}`, {
                      state: {
                        email: state?.email,
                      },
                    })
                  } else {
                    navigate(`${selectedTemplate?.url}`)
                  }
                }}
                disabled={selectedTemplate == -1}
              >
                Next
              </button>
            </div>

            <div className='row mt-9'>
              {templates?.map((tmp: any, ind: any) => {
                return (
                  <div className='col-4'>
                    <div className='d-flex'>
                      <div
                        className={`d-flex align-items-center justify-content-center ${
                          selectedTemplateInd == ind ? 'green-radio-filled' : ' green-radio-hollow'
                        } h-18px w-18px me-3 cursor-pointer`}
                        onClick={() => {
                          setSelectedTemplateInd(ind)
                          setSelectedTemplate(tmp)
                        }}
                      >
                        <img src={whiteTick} height={10} width={10} />
                      </div>
                      <div>
                        <div className='d-flex align-items-center justify-content-center'>
                          <h4 className='mb-2'>
                            <b>{tmp?.name}</b>
                          </h4>
                        </div>
                        <img src={tmp?.src} height={240} width={180} />
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SelectTemplates
