import React, {useState, useCallback, useEffect} from 'react'
import {Modal} from 'react-bootstrap'
import reactiveImg from '../../../../../img/reactive.png'
import reactiveWhiteImg from '../../../../../img/reactiveWhite.png'
import plannedImg from '../../../../../img/planned.png'
import plannedWhiteImg from '../../../../../img/plannedWhite.png'
import preventativeImg from '../../../../../img/preventative.png'
import preventativeWhiteImg from '../../../../../img/preventativeWhite.png'
import informationImg from '../../../../../img/information.png'
import switchOffIcon from '../../../../../img/switch-off.png'
import switchOnIcon from '../../../../../img/switch-on.png'
import removeIcon from '../../../../../img/x-mark.png'
import tenantUserHollowIconGreen from '../../../../../img/tenant-user-hollow-icon-green.png'
import tenantUserFilledIconBlue from '../../../../../img/tenant-user-filled-blue.png'
import plusBlue from '../../../../../img/add-blue.png'
import upload from '../../../../../img/upload-blue.png'
import trashImg from '../../../../../img/trash.png'
import correct from '../../../../../img/correct.png'
import folderDocument from '../../../../../img/folder-document.svg'
import pdfDocument from '../../../../../img/pdf.svg'
import docCocument from '../../../../../img/doc.svg'
import backArrow from '../../../../../img/back-arrow.png'
import check from '../../../../../img/check-white.png'
import pencil from '../../../../../img/pencil-blue.png'
import eye from '../../../../../img/eye-blue.png'
import trash from '../../../../../img/trash-blue.png'
import lock from '../../../../../img/padlock.png'
import addIcon from '../../../../../img/add.png'
import pdfIcon from '../../../../../img/pdf.svg'
import downloadIcon from '../../../../../img/download.png'
import greenPen from '../../../../../img/pen.png'
import removeFilled from '../../../../../img/remove-filled.png'
import correctFilled from '../../../../../img/correct.png'
import folderIcon from '../../../../../img/folder.png'
import folderGreenIcon from '../../../../../img/folder-green.png'
import pngIcon from '../../../../../img/png.png'
import jpgIcon from '../../../../../img/jpg-file.png'
import jpegIcon from '../../../../../img/jpeg.png'

import {useNavigate} from 'react-router-dom'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {AiOutlineExclamationCircle} from 'react-icons/ai'
import {SuccessToast, ErrorToast} from '../../../../../apiCommon/helpers/Toast'
import {IoMdInformationCircleOutline} from 'react-icons/io'
import {Controller, useForm} from 'react-hook-form'
import PhoneInput from 'react-phone-input-2'
import Select from 'react-select'
import moment from 'moment'
import Swal from 'sweetalert2'
import {AiFillDelete} from 'react-icons/ai'
import {
  ApiGet,
  ApiPost,
  ApiUpload,
  ApiPut,
  ApiDelete,
  ApiGetNoAuth,
} from '../../../../../apiCommon/helpers/API/ApiData'
import {Bucket} from '../../../../../apiCommon/helpers/API/ApiData'
import {Fancybox as fancybox} from '@fancyapps/ui'
import '@fancyapps/ui/dist/fancybox/fancybox.css'
import axios from 'axios'
import AddInviteModel from './AddInviteModel'
import {DatePicker} from 'antd'
import {Moment} from 'moment'
import ModalLoader from '../../../General/ModalLoader'

interface ButtonProps {
  show: any
  handleClose?: any
  updateLists?: any
  addedLists?: any
}

const AddTenantModel = ({show, handleClose, updateLists, addedLists}: ButtonProps) => {
  const customStyles = {
    container: (defaultStyles: any) => ({
      ...defaultStyles,
      width: '100%',
    }),

    option: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      color: state.isSelected ? '#000' : '#000',
      backgroundColor: state.isSelected ? '#c2d8d9' : '',
      ':hover': {
        backgroundColor: '#e0f5f0',
        color: '#000',
      },

      fontWeight: '500',
    }),

    menu: (defaultStyles: any) => ({
      ...defaultStyles,
      border: '0.1rem solid #35475e ',
      marginTop: '0px',
      borderRadius: '0px',
      zIndex: '2',
    }),
    menuList: (defaultStyles: any) => ({
      ...defaultStyles,
      maxHeight: '200px',
    }),
    placeholder: (defaultStyles: any) => ({
      ...defaultStyles,
      fontStyle: 'Italic',
      zIndex: '1',
      fontSize: '13px !important',
      fontWeight: '400 !important',
    }),

    input: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#fff',
      color: ' #b5b5c3',
      fontWeight: '500',
    }),

    control: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#fff',
      boxShadow: 'none',
      color: 'white',
      border: '0.1rem solid #bec3cb',
      borderRadius: '2px',
      padding: '0rem',
      height: '35px',
      ':hover': {
        borderColor: '#35475e',
        backgroundColor: '#fff',
      },
      ':focus': {
        borderColor: '#35475e !important',
        backgroundColor: '#fff',
      },
      '::placeholder': {
        fontStyle: 'Italic',
      },
    }),
    singleValue: (defaultStyles: any) => ({
      ...defaultStyles,
      color: '#5e6278',
      fontWeight: '600',
      zIndex: '1',
    }),
    valueContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: '0px 8px 2px 8px',
    }),
  }
  const {
    register,
    control,
    handleSubmit,
    reset,
    setValue,
    formState: {errors},
  } = useForm<any>({mode: 'onSubmit', reValidateMode: 'onChange'})

  const [addInviteModal, setAddInviteModal] = useState<any>(false)
  const [tenantData, setTenantData] = React.useState<any>({
    gender: 1,
    DOB: null,
  })
  const [country, setCountry] = useState<any>([])
  const [genderOptions, setGenderOptions] = useState<any>([
    {value: '0', label: 'Female'},
    {value: '1', label: 'Male'},
    // {value: '2', label: 'Other'},
  ])
  const getCountry = async () => {
    await ApiGetNoAuth('country')
      .then((res) => {
        setCountry(
          res?.data?.data.map((countries: any) => ({
            value: countries?.country,
            label: countries?.country,
          }))
        )
      })
      .catch((e: any) => {
        console.log('e', e)
      })
  }

  const [messageList, setMessageList] = React.useState<any>(false)
  const [propertiType, setPropertiType] = React.useState('Documents')

  /************************* Documents **********************/

  const [viewFolder, setViewFolder] = useState<any>(false)
  const [fileFound, setFileFound] = useState<any>(false)
  const [folderNameModal, setFolderNameModal] = useState<any>(false)
  const [folderName, setFolderName] = useState<any>('')
  const [editFile, setEditFile] = useState<any>(-1)
  const [selectedFolder, setSelectedFolder] = useState<any>()
  const [folderFiles, setFolderFiles] = useState<any>()
  const [newName, setNewName] = useState<any>()
  const [index, setIndex] = React.useState(-1)
  const [fileToReplace, setFileToReplace] = useState<any>()
  const [open, setOpen] = React.useState(false)
  const [slides, setSlides] = React.useState<any>([])
  const [totalImages, setTotalImages] = React.useState<any>(0)
  const [isCTADisabled, setIsCTADisabled] = useState<any>(true)

  const [folders, setFolders] = useState<any>([
    {isSystemGenerated: true, name: 'Contract', fileList: []},
    {isSystemGenerated: true, name: 'ID', fileList: []},
    {isSystemGenerated: true, name: 'Passport', fileList: []},
    {isSystemGenerated: true, name: 'Residency', fileList: []},
  ])

  let fileLists_single: any = []
  var replaceFile: any

  const addFolder = () => {
    let isFound: any = false
    for (let i = 0; i < folders?.length; i++) {
      if (folderName == folders[i]?.name) {
        isFound = true
        setFileFound(true)
        i = folders?.length
      }
    }

    if (isFound == false) {
      const values = [...folders]
      values[folders?.length] = {
        name: folderName,
        isSystemGenerated: false,
        fileList: [],
      }
      setFolders(values)
      SuccessToast('New Folder has been added successfully!')

      setFolderName('')
      setFolderNameModal(false)

      //   const body = {
      //     name: folderName,
      //     tenantId: `${window.location.pathname.split('/')[2]}`,
      //     fileList: [],
      //   }
      //   ApiPost(`corporate/tenant_folder`, body)
      //     .then((res: any) => {
      //       SuccessToast('New Folder has been added successfully!')
      //       getTenantFolders()
      //       setFolderNameModal(false)
      //     })
      //     .catch((err: any) => {})
    } else {
    }
  }

  const [showLoader, setShowLoader] = useState<boolean>(false)
  const [uploadProgress, setUploadProgress] = useState<number>(0)
  const [totalFiles, setTotalFiles] = useState<number>(0)

  const [uploadingText, setUploadingText] = useState<any>('Uploading')
  const [percentProgress, setPercentProgress] = useState(0)

  const onFilesAddedSingle = async (event: any, val: any, id: any) => {
    let temp = [...folderFiles]

    const generateNewFileName = (originalName: any, existingFiles: any) => {
      
      const nameParts = originalName.split('.')
      const extension = nameParts.pop()
      let baseName = nameParts.join('.')
      let baseNameCaptilize = capitalizeFirstLetter(baseName)
     
      let counter = 1

      while (
        existingFiles.some((file: any) => file.fileName?.toLowerCase() === `${baseName}(${counter}).${extension}`)
      ) {
        counter++
      }

      return `${baseNameCaptilize}(${counter}).${extension}`
    }

    if (event.target.files) {
      setShowLoader(true)
      setTotalFiles(event.target.files.length)

      for (let m = 0; m < event.target.files.length; m++) {
        try {
          if (val == 0) {
            const files = event.target.files
            fileLists_single = Array.from(files)

            setFileToReplace(event.target.files[m])
            replaceFile = event.target.files[m]

            console.log('File to replace ' + event.target.files)

            let isFound = false
            for (let i = 0; i < folderFiles?.length; i++) {
              if (event.target.files?.[m]?.name === folderFiles[i]?.fileName?.toLowerCase()) {
                isFound = true
                break
              }
            }

            if (!isFound) {
              // setOpen(true)

              let formData = new FormData()
              let data = fileLists_single[m]
              let fileURL = URL.createObjectURL(data)
              data.fileURL = fileURL
              formData.append('image', data)

              let url = 'upload/image/tenant_folder'

              await ApiUpload(`${url}`, formData)
                .then((res) => {
                  temp.push({
                    fileName: capitalizeFirstLetter(event?.target?.files[m]?.name),
                    fileURL: res?.data?.data?.image,
                    fileSize: event?.target?.files[m]?.size,
                    createdAt: moment(new Date()).format('YYYY-MM-DD'),
                  })

                  let values = [...folders]
                  for (let i = 0; i < folders?.length; i++) {
                    if (folders[i]?.name === selectedFolder?.name) {
                      folders[i].fileList = temp
                    }
                  }
                  setUploadProgress(m + 1)
                  setFolders(values)
                  getFolderFiles(selectedFolder)
                })
                .catch((err) => {
                  // console.log('res_blob', err)
                  ErrorToast(err?.message)
                  setShowLoader(false)
                  setUploadProgress(0) // Reset upload progress
                  setTotalFiles(0)
                })
            } else {
              // const userChoice = await confirmAction();
              // console.log(userChoice);
              // if (userChoice === 'keep') {
              // setOpen(true)

              let formData = new FormData()
              let data = replaceFile
              // console.log(replaceFile)
              let fileURL = URL.createObjectURL(data)
              data.fileURL = fileURL
              formData.append('image', data)
              console.log('\ntype', replaceFile.type)

              let url = 'upload/image/tenant_folder'

              await ApiUpload(`${url}`, formData)
                .then((res) => {
                  let name = Date.now()
                  temp.push({
                    fileName: generateNewFileName(replaceFile.name, temp),
                    fileURL: res?.data?.data?.image,
                    fileSize: replaceFile.size,
                    createdAt: moment(new Date()).format('YYYY-MM-DD'),
                  })

                  let values = [...folders]
                  for (let i = 0; i < folders?.length; i++) {
                    if (folders[i]?.name === selectedFolder?.name) {
                      folders[i].fileList = temp
                    }
                  }
                  setUploadProgress(m + 1)
                  setFolders(values)
                  getFolderFiles(selectedFolder)
                  // setOpen(false)
                })
                .catch((err) => {
                  console.log('res_blob', err)
                  ErrorToast(err?.message)
                  setShowLoader(false)
                  setUploadProgress(0) // Reset upload progress
                  setTotalFiles(0)
                })
              // }
              // else {
              //   setOpen(true);

              //   let formData = new FormData();
              //   let data = replaceFile;
              //   console.log(replaceFile);
              //   let fileURL = URL.createObjectURL(data);
              //   data.fileURL = fileURL;
              //   formData.append('image', data);
              //   console.log('\ntype', replaceFile.type);

              //   let url = 'upload/image/tenant_folder';

              //   await ApiUpload(`${url}`, formData)
              //     .then((res) => {
              //       for (let j = 0; j < folderFiles?.length; j++) {
              //         if (replaceFile.name === folderFiles[j]?.fileName) {
              //           temp[j] = {
              //             fileName: replaceFile.name,
              //             fileURL: res?.data?.data?.image,
              //             fileSize: replaceFile.size,
              //             createdAt: moment(new Date()).format('YYYY-MM-DD'),
              //           };
              //         }
              //       }

              //       let values = [...folders];
              //       for (let i = 0; i < folders?.length; i++) {
              //         if (folders[i]?.name === selectedFolder?.name) {
              //           folders[i].fileList = temp;
              //         }
              //       }

              //       setFolders(values);
              //       getFolderFiles(selectedFolder);
              //       setOpen(false);
              //     })
              //     .catch((err) => {
              //       console.log('res_blob', err);
              //       ErrorToast(err?.message);
              //     });
              // }
            }
            // setOpen(false)
          }
        } catch (error) {
          console.error(error)
          // Handle errors
        }
      }
      
      setTimeout(() => {
        setShowLoader(false)
      setUploadProgress(0) // Reset upload progress
      setTotalFiles(0)
      },200)
      event.target.value = null
    } else {
      let temp = [...folderFiles]
      temp.splice(id, 1)
      setFolderFiles(temp)

      let folds = [...folders]
      for (let i = 0; i < folds?.length; i++) {
        if (folds?.[i]?.name === selectedFolder?.name) {
          folds[i].fileList = temp
        }
      }

      setFolders(folds)
    }
  }

  const getFilename = (file: any, type: any) => {
    if (type) return file.split('.')[1]
    else return file.split('.')[0]
  }

  const getFolderFiles = (folder: any) => {
    // ApiGet(`corporate/tenant_folder/${folder?._id}`)
    //   .then((res: any) => {
    setSelectedFolder(folder)
    setViewFolder(true)
    setFolderFiles(folder?.fileList)

    let y: any = []
    for (let i = 0; i < folder?.fileList?.length; i++) {
      y[y.length] = {
        src: Bucket + folder?.fileList?.[i]?.fileURL,
      }
    }
    setSlides(y)
    setTotalImages(folder?.fileList?.length)
    //   })
    //   .catch((err: any) => {})
  }

  const deleteFolder = (ind: any) => {
    const values = [...folders]
    values.splice(ind, 1)
    setFolders(values)
    // ApiDelete(`corporate/tenant_folder/${id}`)
    //   .then((res: any) => {
    //     SuccessToast('Folder has been deleted successfully!')
    //     getTenantFolders()
    //   })
    //   .catch((err: any) => {})
  }

  const confirmAction = () => {
    // return new Promise((resolve) => {
    return Swal.fire({
      text: `${replaceFile?.name} exists already. Do you wish to REPLACE or KEEP BOTH ? `,
      icon: 'warning',
      showConfirmButton: true,
      confirmButtonColor: '#007a59',
      confirmButtonText: 'Keep Both',
      showCancelButton: true,
      cancelButtonText: 'Cancel',
      showDenyButton: true,
      denyButtonText: 'Replace',
      denyButtonColor: '#D72852',
      // html: `
      //   <p>File with the same name already exists. </p>
      //   <p>Do you wish to REPLACE or KEEP BOTH ? </p>
      //   <div>
      //      <button class="btn text-white" onclick="replaceKeepFile('replace')" style="background-color:#D72852 ">Replace</button>
      //      <button class="btn btn-green text-white" onclick="replaceKeepFile('keep')">Keep Both</button>
      //   </div>`,
    }).then((res: any) => {
      if (res.isConfirmed) {
        return 'keep'
      } else if (res.isDenied) {
        return 'replace'
      } else {
      }
    })
    // });
  }

  const handleDownload = async (fileUrl: any) => {
    // setIsDisable(true)
    try {
      const response = await axios.get(fileUrl, {
        responseType: 'blob',
      })

      const contentType = response.headers['content-type']
      const blob = new Blob([response.data], {type: contentType})

      // Determine file extension based on content type
      const extensionMap: {[key: string]: string} = {
        'image/jpeg': 'jpg',
        'image/png': 'png',
        'application/pdf': 'pdf',
        // Add more mappings as needed
      }

      const extension = extensionMap[contentType] || 'file'

      // Create a link element
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = `downloaded_file.${extension}`

      // Append the link to the body
      document.body.appendChild(link)

      // Trigger the click event to start the download
      link.click()

      // Remove the link from the body
      document.body.removeChild(link)
      // setIsDisable(false)
    } catch (error) {
      // setIsDisable(false)
      ErrorToast('Error downloading file')
    }
  }

  const addTenant = async (data: any, e: any,key:any) => {
    e.preventDefault()
    const body : any = {
      firstName: tenantData?.firstName,
      lastName: tenantData?.lastName,
      phoneNumber: tenantData?.phoneNumber,
      countryCode: tenantData?.countryCode,
      email: tenantData?.email,
      nationality: tenantData?.nationality,
      // tenantType: tenantData?.tenantType,
      // DOB: tenantData?.DOB  === null ? '' : tenantData?.DOB ,
      folder_list: folders,
      passportNo: tenantData?.passportNo,
      otherNo: tenantData?.otherNo,
      idNo: tenantData?.idNo,
      gender: Number(tenantData?.gender),
      // profileImage: profileImg,
    }

    if(tenantData?.DOB){
      body.DOB = tenantData?.DOB
    }
    await ApiPost(`corporate/tenant`, body)
      .then((response) => {
        if(key == 1){
          localStorage.setItem('tenantData', JSON.stringify(tenantData))
          localStorage.setItem('tenantFolders', JSON.stringify(folders))
          SuccessToast('Tenant profile created successfully!')
          handleClose(1)
        }
        if(key == 0){
          SuccessToast('Tenant profile created successfully!')
          handleClose(0)
        }
       
      })
      .catch((error) => {
        console.log(error)
        ErrorToast(error?.message)
      })
  }

  function capitalizeFirstLetter(word: any) {
    if (!word || typeof word !== 'string') {
      return word
    }
    const firstLetter = word.charAt(0)
    const firstLetterCap = firstLetter.toUpperCase()
    const remainingLetters = word.slice(1)
    const capitalizedWord = firstLetterCap + remainingLetters
    return capitalizedWord
  }

  useEffect(() => {
    getCountry()
  }, [])

  useEffect(() => {
    if (
      tenantData?.firstName !== undefined &&
      tenantData?.firstName?.trim() !== '' &&
      tenantData?.lastName !== undefined &&
      tenantData?.lastName?.trim() !== '' &&
      tenantData?.email !== undefined &&
      tenantData?.email?.trim() !== ''
    ) {
      setIsCTADisabled(false)
    } else {
      setIsCTADisabled(true)
    }
  }, [tenantData?.firstName, tenantData?.lastName, tenantData?.email])

  useEffect(() => {
    fancybox.bind('[data-fancybox]', {
      Toolbar: {
        display: {
          left: ['infobar'],
          middle: ['zoomIn', 'zoomOut', 'toggle1to1', 'rotateCCW', 'rotateCW', 'flipX', 'flipY'],
          right: ['slideshow', 'thumbs', 'close'],
        },
      },
    })
  }, [])

  function disabledDate(current: Moment) {
    return current && current > moment().endOf('day')
  }

  React.useEffect(() => {
    fancybox.bind('[data-fancybox]', {
      Toolbar: {
        display: {
          left: ['infobar'],
          middle: ['zoomIn', 'zoomOut', 'toggle1to1', 'rotateCCW', 'rotateCW', 'flipX', 'flipY'],
          right: ['slideshow', 'thumbs', 'close'],
        },
      },
    })
  }, []);

  return (
    <>
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-900px mx-auto'
        show={show}
        onHide={() => {
          handleClose(0)
        }}
        backdrop={true}
      >
        <div
          className='modal-body p-0'
          style={{
            transform: 'translate(0px, -70px)',
            top: '70px',
            borderRadius: '5px',
            boxShadow: '0px 0px 15px 0px #516176',
            minHeight: '350px',
            maxHeight: '550px',
            overflow: 'auto',
            position: 'relative',
            // paddingBottom:'100px',
          }}
        >
          <div
            className='row px-7 py-5 m-0'
            style={{position: 'sticky', top: 0, background: '#fff', zIndex: 2}}
          >
            <div className='col-md-12 px-0'>
              <div style={{position: 'relative'}}>
                <div style={{position: 'absolute', top: 0, right: 0}}>
                  <label
                    className='head-text fs-5 cursor-pointer'
                    style={{fontWeight: '700'}}
                    onClick={() => {
                      handleClose(0)
                    }}
                  >
                    X
                  </label>
                </div>
                <h2 className='head-text mb-0 text-center'>
                  <b>Add Tenant</b>
                </h2>
              </div>
            </div>
          </div>

          <form>
            <div className='row mx-0 px-0 mt-7'>
              <div className='col-12 px-0'>
                <img
                  src={tenantUserFilledIconBlue}
                  height={60}
                  width={60}
                  className='m-0 p-0 d-block mx-auto'
                />
                <h3 className='head-text mb-0 text-center mt-5 mb-5'>
                  <b>Details</b>
                </h3>
              </div>

              <div className='col-12 px-7'>
                <div className='row d-flex justify-content-center' style={{columnGap: '100px'}}>
                  {/* FIRST NAME */}
                  <div style={{width: '40%'}}>
                    <div className='d-flex flex-column my-2 mb-4 align-items-start'>
                      <h4 className='my-2' style={{color: 'black', width: '100%'}}>
                        <label className='required labl-gry'> First Name </label>
                      </h4>
                      <h4 style={{width: '100%'}} className='my-2'>
                        <input
                          {...register('firstName', {required: true})}
                          type='text'
                          className='form-control form-control-solid mytest'
                          style={{backgroundColor: '#fff'}}
                          autoComplete='off'
                          placeholder='Enter first name'
                          name='firstName'
                          value={tenantData?.firstName}
                          onChange={(e) => {
                            const captilizeWord = capitalizeFirstLetter(e.target.value?.trimStart())
                            setTenantData({
                              ...tenantData,
                              [e.target.name]: captilizeWord,
                            })
                          }}
                        />

                        {errors.firstName && (
                          <p
                            style={{color: 'var(--kt-danger)', gridColumn: '2/2'}}
                            className='m-0 mt-1'
                          >
                            This field is required
                          </p>
                        )}
                      </h4>
                    </div>
                    {/* LAST NAME */}
                    <div className='d-flex flex-column my-2 mb-4 align-items-start'>
                      <h4 className='my-2' style={{color: 'black', width: '100%'}}>
                        <label className='required labl-gry'> Last Name </label>
                      </h4>
                      <h4 style={{width: '100%'}} className='my-2'>
                        <input
                          type='text'
                          {...register('lastName', {required: true})}
                          className='form-control form-control-solid mytest'
                          placeholder='Enter last name'
                          autoComplete='off'
                          name='lastName'
                          style={{backgroundColor: '#fff'}}
                          value={tenantData?.lastName}
                          onChange={(e) => {
                            const captilizeWord = capitalizeFirstLetter(e.target.value?.trimStart())
                            setTenantData({
                              ...tenantData,
                              [e.target.name]: captilizeWord,
                            })
                          }}
                        />

                        {errors.lastName && (
                          <p
                            style={{color: 'var(--kt-danger)', gridColumn: '2/2'}}
                            className='m-0 mt-1'
                          >
                            This field is required
                          </p>
                        )}
                      </h4>
                    </div>
                    {/* EMAIL */}
                    <div className='d-flex flex-column my-2 mb-4 align-items-start'>
                      <h4 className='my-2' style={{color: 'black', width: '100%'}}>
                        <label className='required labl-gry'> Email </label>
                      </h4>
                      <h4 style={{width: '100%'}} className='my-2 test'>
                        <Controller
                          name='email'
                          control={control}
                          rules={{
                            required: 'Email is required',
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                              message: 'Invalid email address',
                            },
                          }}
                          render={({field}) => (
                            <input
                              //   {...register('email', {required: true})}
                              type='text'
                              className='form-control form-control-solid mytest'
                              placeholder='Enter email address'
                              autoComplete='off'
                              style={{backgroundColor: '#fff'}}
                              name='email'
                              value={tenantData?.email}
                              onChange={(e) => {
                                setValue('email', e.target.value)
                                setTenantData({
                                  ...tenantData,
                                  [e.target.name]: e.target.value,
                                })
                              }}
                            />
                          )}
                        />

                        {errors.email && (
                          <p
                            style={{color: 'var(--kt-danger)', gridColumn: '2/2'}}
                            className='m-0 mt-1'
                          >
                            Please enter a valid Email Address
                            {/* {errors.email.message} */}
                          </p>
                        )}
                      </h4>
                    </div>
                    {/* MOBILE NO */}
                    <div className='d-flex flex-column my-2 mb-4 align-items-start'>
                      <h4 className='my-2' style={{color: 'black', width: '100%'}}>
                        <label className='labl-gry'> Mobile No. </label>
                      </h4>

                      <h4
                        style={{width: '100%', borderRadius: '0.475rem'}}
                        className='my-2 test2'
                      >
                        <Controller
                          name='phoneNumber'
                          control={control}
                          render={({field}) => (
                            <PhoneInput
                              //   {...register('phoneNumber', {required: true})}
                              containerClass={(tenantData?.countryCode && tenantData?.phoneNumber ) ?  'isFilled' : ''}
                              placeholder='Enter phone number'
                              value={`${tenantData?.countryCode} ${tenantData?.phoneNumber}`}
                              onChange={(phone: any, e: any) => {
                                let CountryCode: any = e?.dialCode
                                let PhoneNumber: any = phone.split(CountryCode)[1]
                                setTenantData({
                                  ...tenantData,
                                  ['countryCode']: parseInt(CountryCode),
                                  ['phoneNumber']: PhoneNumber,
                                })

                                setValue('phoneNumber', `${parseInt(CountryCode)}${PhoneNumber}`)
                              }}
                            />
                          )}
                        />

                        {errors.phoneNumber && (
                          <p
                            style={{color: 'var(--kt-danger)', gridColumn: '2/2'}}
                            className='m-0 mt-1'
                          >
                            This field is required
                            {/* {errors.email.message} */}
                          </p>
                        )}
                      </h4>
                    </div>
                  </div>
                  <div style={{width: '40%'}}>
                    {/* DOB */}
                    <div className='d-flex flex-column my-2 mb-4 align-items-start'>
                      <h4 className='my-2' style={{color: 'black', width: '94%'}}>
                        <label className='labl-gry'> DOB </label>
                      </h4>
                      <h4  className='my-2 expense-date add-tenant-date tst' >
                        {tenantData?.DOB != null ? (
                          <DatePicker
                          className={tenantData?.DOB ? 'isFilled':''}
                            value={moment(tenantData.DOB, 'YYYY-MM-DD')}
                            format={'DD.MM.YYYY'}
                            disabledDate={disabledDate}
                            onChange={(date: any) =>
                              setTenantData({
                                ...tenantData,
                                ['DOB']: date != null ? date.format('YYYY-MM-DD') : null,
                              })
                            }
                            placeholder='Select DOB'
                          />
                        ) : (
                          <DatePicker
                          className={tenantData?.DOB ? 'isFilled':''}
                            format={'DD.MM.YYYY'}
                            disabledDate={disabledDate}
                            onChange={(date: any) =>
                              setTenantData({
                                ...tenantData,
                                ['DOB']: date != null ? date.format('YYYY-MM-DD') : null,
                              })
                            }
                            placeholder='Select DOB'
                          />
                        )}

                        {/* <input
                          type='date'
                          {...register('DOB', {required: false})}
                          className='form-control form-control-solid mytest'
                          placeholder='Select DOB'
                          max={new Date().toISOString().split('T')[0]}
                          name='DOB'
                          style={{backgroundColor: '#fff'}}
                          // value={moment(
                          //   tenantData?.DOB && tenantData?.DOB.split('T')[0]
                          // ).format('YYYY-MM-DD')}
                          onChange={(e) =>
                            setTenantData({...tenantData, [e.target.name]: e.target.value})
                          }
                        /> */}

                        {errors.DOB && (
                          <p
                            style={{color: 'var(--kt-danger)', gridColumn: '2/2'}}
                            className='m-0 mt-1'
                          >
                            This field is required
                          </p>
                        )}
                      </h4>
                    </div>
                    {/* NATIONALITY */}
                    <div className='d-flex flex-column my-2 mb-4 align-items-start'>
                      <h4 className='my-2' style={{color: 'black', width: '100%'}}>
                        <label className='labl-gry'> Nationality </label>
                      </h4>
                      <h4 style={{width: '100%'}} className='my-2'>
                        <Select
                          // defaultValue={tenantData?.gender == 0 ? genderOptions[0] : tenantData?.gender == 2 ? genderOptions[2] : genderOptions[1]}
                          // {...register('gender', {required: true})}
                          styles={{...customStyles,control: (defaultStyles: any, state: any) => ({
                            ...defaultStyles,
                            backgroundColor: '#fff',
                            boxShadow: 'none',
                            color: 'white',
                            border: tenantData?.nationality  ? '0.1rem solid #35475e' : '0.1rem solid #bec3cb',
                            borderRadius: '2px',
                            padding: '0rem',
                            height: '35px',
                            ':hover': {
                              borderColor: '#35475e',
                              backgroundColor: '#fff',
                            },
                            ':focus': {
                              borderColor: '#35475e !important',
                              backgroundColor: '#fff',
                            },
                            '::placeholder': {
                              fontStyle: 'Italic',
                            },
                          })}}
                          isSearchable={false}
                          options={country}
                          placeholder='Select nationality'
                          name='nationality'
                          onChange={(e: any) =>
                            setTenantData({
                              ...tenantData,
                              ['nationality']: e?.value,
                            })
                          }
                        />
                      </h4>
                    </div>
                    {/* GENDER */}
                    <div className='d-flex flex-column my-2 mb-4 align-items-start'>
                      <h4 className='my-2' style={{color: 'black', width: '100%'}}>
                        <label className='labl-gry'> Gender </label>
                      </h4>
                      <h4 style={{width: '100%'}} className='my-2'>
                        <Select
                          // defaultValue={tenantData?.gender == 0 ? genderOptions[0] : tenantData?.gender == 2 ? genderOptions[2] : genderOptions[1]}
                          // {...register('gender', {required: true})}
                          styles={{...customStyles,control: (defaultStyles: any, state: any) => ({
                            ...defaultStyles,
                            backgroundColor: '#fff',
                            boxShadow: 'none',
                            color: 'white',
                            border: tenantData?.gender  ? '0.1rem solid #35475e' : '0.1rem solid #bec3cb',
                            borderRadius: '2px',
                            padding: '0rem',
                            height: '35px',
                            ':hover': {
                              borderColor: '#35475e',
                              backgroundColor: '#fff',
                            },
                            ':focus': {
                              borderColor: '#35475e !important',
                              backgroundColor: '#fff',
                            },
                            '::placeholder': {
                              fontStyle: 'Italic',
                            },
                          })}}
                          isSearchable={false}
                          options={genderOptions}
                          placeholder='Select gender'
                          name='gender'
                          onChange={(e: any) =>
                            setTenantData({
                              ...tenantData,
                              ['gender']: e?.value,
                            })
                          }
                        />
                      </h4>
                    </div>
                    {/* ID NO. */}
                    <div className='d-flex flex-column my-2 mb-4 align-items-start'>
                      <h4 className='my-2' style={{color: 'black', width: '100%'}}>
                        <label className='labl-gry'> ID No. </label>
                      </h4>
                      <h4 style={{width: '100%'}} className='my-2'>
                        <input
                          type='text'
                          className='form-control form-control-solid mytest '
                          placeholder='Enter ID number'
                          autoComplete='off'
                          name='idNo'
                          style={{backgroundColor: '#fff'}}
                          value={tenantData?.idNo}
                          onChange={(e) => {
                            const captilizeWord = capitalizeFirstLetter(e.target.value?.trimStart())
                            setTenantData({
                              ...tenantData,
                              [e.target.name]: captilizeWord,
                            })
                          }}
                        />
                      </h4>
                    </div>
                    {/* Passport No. */}
                    <div className='d-flex flex-column my-2 mb-4 align-items-start'>
                      <h4 className='my-2' style={{color: 'black', width: '100%'}}>
                        <label className='labl-gry'> Passport No. </label>
                      </h4>
                      <h4 style={{width: '100%'}} className='my-2'>
                        <input
                          type='text'
                          className='form-control form-control-solid mytest'
                          placeholder='Enter passport number'
                          autoComplete='off'
                          name='passportNo'
                          style={{backgroundColor: '#fff'}}
                          value={tenantData?.passportNo}
                          onChange={(e) => {
                            const captilizeWord = capitalizeFirstLetter(e.target.value?.trimStart())
                            setTenantData({
                              ...tenantData,
                              [e.target.name]: captilizeWord,
                            })
                          }}
                        />
                      </h4>
                    </div>
                  </div>
                </div>

                <div
                  className='d-flex  align-items-center mt-3 pb-5 mb-5  mx-3'
                  style={{borderBottom: '0.1rem solid #bec3cb'}}
                ></div>

                {/* FOLDER */}
                <div className='col-12 pt-2'>
                  {!messageList && (
                    <div className='flex-lg-row-fluid'>
                      <div
                        className='d-flex align-items-center justify-content-end '
                        style={{position: 'relative', paddingBottom: '50px'}}
                      >
                        <h3
                          className='head-text m-0 p-0 text-center'
                          style={{position: 'absolute', top: 6, left: '46%', right: '50%'}}
                        >
                          <b>Documents</b>
                        </h3>
                        {!viewFolder && (
                          <div
                            className='text-end'
                            style={{textAlign: 'right'}}
                            onClick={() => {
                              setFileFound(false)
                              setFolderNameModal(true)
                            }}
                          >
                            <button
                              type='button'
                              className='btn btn-sm fw-bold px-2 green-hollow-btn'
                              style={{width: '130px'}}
                              onClick={() => {
                                setFileFound(false)
                                setFolderNameModal(true)
                              }}
                            >
                              <img
                                src={folderGreenIcon}
                                height={18}
                                width={18}
                                style={{
                                  marginRight: '7px',
                                }}
                              />
                              New Folder
                            </button>
                          </div>
                        )}
                      </div>

                      <div>
                        {/* Documents Tab */}
                        {propertiType === 'Documents' && (
                          <>
                            <div className='row g-6 g-xl-9 mb-6 mb-xl-9 '>
                              {/* Add New Folder Card */}
                              {folderNameModal && (
                                <div className='col-md-6 col-lg-4 col-xl-4'>
                                  <div style={{height: '30px'}}>
                                    <div className='d-flex mx-1'>
                                      <img
                                        src={removeFilled}
                                        height={18}
                                        width={18}
                                        className='cursor-pointer'
                                        onClick={() => setFolderNameModal(false)}
                                      />

                                      <img
                                        style={
                                          folderName?.trim()?.length > 0
                                            ? {opacity: '1', cursor: 'pointer'}
                                            : {opacity: '0.7', cursor: 'not-allowed'}
                                        }
                                        src={correct}
                                        height={18}
                                        width={18}
                                        className='ms-auto'
                                        onClick={() => {
                                          if (folderName?.trim()?.length > 0) addFolder()
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className='card'>
                                    <div className='card-body d-flex justify-content-center text-center flex-column px-4 py-4 test'>
                                      <a className='text-gray-800 text-hover-primary d-flex flex-column'>
                                        <div className='symbol symbol-60px mb-5'>
                                          <img
                                            src={
                                              tenantData?.document?.contract?.split('.')[1] ===
                                              'pdf'
                                                ? pdfDocument
                                                : tenantData?.other?.split('.')[1] === 'doc'
                                                ? docCocument
                                                : folderDocument
                                            }
                                            className='theme-light-show'
                                            alt=''
                                          />
                                          <img
                                            src='/metronic8/demo1/assets/media/svg/files/pdf-dark.svg'
                                            className='theme-dark-show'
                                            alt=''
                                          />
                                        </div>
                                        <div className='fs-5 fw-bold mb-2 '>
                                          <input
                                            type='text'
                                            className='form-control form-control-solid mytest w-75 m-auto'
                                            placeholder='Enter Folder Name'
                                            onChange={(e: any) => {
                                              const captilizeWord = capitalizeFirstLetter(
                                                e.target.value?.trimStart()
                                              )
                                              setFolderName(captilizeWord)
                                            }}
                                          >
                                            {fileFound ? (
                                              <p className='mt-2' style={{color: 'red'}}>
                                                Folder name already exists
                                              </p>
                                            ) : null}
                                          </input>
                                        </div>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              )}

                              {folders?.length > 0 && (
                                <>
                                  {viewFolder ? (
                                    <>
                                      <div className='d-flex align-items-center'>
                                        <span
                                          className='rounded-circle'
                                          style={{cursor: 'pointer'}}
                                          onClick={() => {
                                            setViewFolder(false)
                                            setEditFile(-1)
                                          }}
                                        >
                                          <img
                                            src={backArrow}
                                            style={{stroke: 'red'}}
                                            height='14'
                                            width='14'
                                          />
                                        </span>
                                        <h2 className='m-0 head-text ms-2'>
                                          {selectedFolder?.name}
                                        </h2>

                                        <label
                                          title='Add New File'
                                          htmlFor='uploadPassport'
                                          className='btn btn-sm fw-bold upload-btn ms-auto'
                                        >
                                          <img
                                            src={upload}
                                            height={18}
                                            width={18}
                                            className='me-3'
                                          />
                                          Upload File{' '}
                                        </label>
                                        <input
                                          type='file'
                                          hidden
                                          id='uploadPassport'
                                          className='form-control form-control-solid'
                                          placeholder='upload'
                                          name='passport'
                                          onChange={(e: any) => {
                                            onFilesAddedSingle(e, 0, '')
                                          }}
                                          multiple
                                        />
                                      </div>
                                      <div className='col-md-12 col-lg-12 col-xl-12' style={{padding:'0px 20px'}}>
                                        <div className='card h-100'>
                                          <div className='card-body d-flex justify-content-center flex-column p-8'>
                                            {folderFiles?.length > 0 ? (
                                              <>
                                                <div
                                                  className='row py-3'
                                                  style={{color: '#a1a5b7'}}
                                                >
                                                  <div className='col-md-7 '><span style={{paddingLeft:'25px'}}>File Name</span></div>
                                                  <div className='col-md-2 '>Date Added</div>
                                                  <div className='col-md-3'></div>
                                                </div>

                                                {folderFiles?.map((file: any, ind: any) => {
                                                  return (
                                                    <div
                                                      className='row py-3'
                                                    >
                                                      {editFile == ind ? (
                                                        <>
                                                          <div className='col-md-7 head-text d-flex align-items-center'>
                                                            <div className='bullet-item me-3 mb-0'></div>
                                                            <img
                                                             src={file?.fileName?.split('.')[1]  ===
                                                              'png' ? pngIcon : file?.fileName?.split('.')[1]  ===
                                                              'jpg' ? jpgIcon : file?.fileName?.split('.')[1]  ===
                                                              'pdf' ? pdfIcon : file?.fileName?.split('.')[1]  ===
                                                              'jpeg' ? jpegIcon: pdfIcon}
                                                              height={30}
                                                              width={30}
                                                              className='mx-3 cursor-pointer'
                                                              data-fancybox
                                                              data-src={`${Bucket}${file?.fileURL}`}
                                                            />

                                                            <input
                                                              type='text'
                                                              value={getFilename(newName, false)}
                                                              className='form-control form-control-solid p-2'
                                                              style={{backgroundColor:'#fff', border:'1px solid #b3b3b3'}}
                                                              onChange={(e: any) => {
                                                                let temp = getFilename(
                                                                  file?.fileName,
                                                                  true
                                                                )

                                                                setNewName(
                                                                  e.target.value + '.' + temp
                                                                )
                                                              }}
                                                            />
                                                            {/* <a
                                                                className='btn btn-sm fw-bold btn-primary btn-green'
                                                                onClick={() => {
                                                                  const values = [...folderFiles]
                                                                  values[ind].fileName = newName
                                                                  setFolderFiles(values)
                                                                  getFolderFiles(selectedFolder)
                                                                  setEditFile(-1)
                                                                }}
                                                              >
                                                                <img src={check} height={18} width={18} />
                                                              </a> */}
                                                          </div>
                                                        </>
                                                      ) : (
                                                        <div className='col-md-7 head-text d-flex align-items-center'>
                                                          <div className='bullet-item me-3 mb-0'></div>
                                                          <img
                                                             src={file?.fileName?.split('.')[1]  ===
                                                              'png' ? pngIcon : file?.fileName?.split('.')[1]  ===
                                                              'jpg' ? jpgIcon : file?.fileName?.split('.')[1]  ===
                                                              'pdf' ? pdfIcon : file?.fileName?.split('.')[1]  ===
                                                              'jpeg' ? jpegIcon: pdfIcon}
                                                            height={30}
                                                            width={30}
                                                            className='mx-3 cursor-pointer'
                                                            data-fancybox
                                                            data-src={`${Bucket}${file?.fileURL}`}
                                                          />
                                                          {getFilename(file?.fileName, false)
                                                            ?.length > 30
                                                            ? `${file?.fileName?.slice(
                                                                0,
                                                                30
                                                              )}...${getFilename(
                                                                file?.fileName,
                                                                true
                                                              )}`
                                                            : file?.fileName}
                                                        </div>
                                                      )}
                                                      <div className='col-md-2 head-text'>
                                                        {moment(file?.createdAt).format(
                                                          'DD.MM.YYYY'
                                                        )}
                                                      </div>
                                                      <div className='col-md-3 head-text text-end'>
                                                        {editFile == ind ? (
                                                          <div className='d-flex justify-content-end'>
                                                            <img
                                                              src={removeFilled}
                                                              height={20}
                                                              width={20}
                                                              style={{cursor: 'pointer',marginRight:'25px'}}
                                                              onClick={() => {
                                                                setEditFile(-1)
                                                              }}
                                                            />
                                                            <img
                                                              src={correctFilled}
                                                              height={20}
                                                              width={20}
                                                              className='ms-7'
                                                              style={{cursor: 'pointer'}}
                                                              onClick={() => {
                                                                const values = [...folderFiles]
                                                                values[ind].fileName = newName
                                                                setFolderFiles(values)
                                                                getFolderFiles(selectedFolder)
                                                                setEditFile(-1)
                                                              }}
                                                            />
                                                          </div>
                                                        ) : (
                                                          <div className='d-flex justify-content-end tst'>
                                                            <img
                                                              src={downloadIcon}
                                                              height={20}
                                                              width={20}
                                                              className='me-5'
                                                              style={{cursor: 'pointer'}}
                                                              onClick={() => {
                                                                handleDownload(
                                                                  `${Bucket}${file?.fileURL}`
                                                                )
                                                              }}
                                                            />
                                                            <img
                                                              src={greenPen}
                                                              height={17}
                                                              width={17}
                                                              style={{cursor: 'pointer'}}
                                                              className='me-5'
                                                              onClick={() => {
                                                                setNewName(
                                                                  getFilename(file?.fileName, false)
                                                                )
                                                                setEditFile(ind)
                                                              }}
                                                            />

                                                            <img
                                                              src={trashImg}
                                                              height={16}
                                                              width={16}
                                                              className='ms-1 cursor-pointer'
                                                              onClick={(e: any) => {
                                                      //           
                                                      Swal.fire({
                                                        html: `
                                            <div class='fs-4 mt-4 fw-bold'> 
                                            Delete File
                                            </div>
                                            <div class='fs-5 mt-4'> 
                                                Are you sure you want to permanently delete this file?
                                            </div>`,
                                                        showConfirmButton: true,
                                                        confirmButtonColor: '#d54645',
                                                        confirmButtonText: 'Yes',
                                                        showCancelButton: true,
                                                        cancelButtonColor: '#0d9e91',
                                                        width:'205px',
                                                        padding:'0px',
                                                        
                                                        // cancelButtonColor: "transparent",
                                                        cancelButtonText: 'No',
                                                      }).then((res: any) => {
                                                        if (res.isConfirmed) {
                                                          onFilesAddedSingle(e, 1, ind)
                                                        }
                                                      })
                                                              }}
                                                            />
                                                          </div>
                                                        )}
                                                      </div>
                                                    </div>
                                                  )
                                                })}

                                                {/* <div className='tops mw-350px d-flex gap-5 align-items-start'>
                                    {folderFiles?.map((file: any, ind: any) => {
                                      return (
                                        <div className='imgs-set'>
                                          <div className='d-flex mw-350px me-3'>
                                            <img
                                              src={Bucket + file?.fileURL}
                                              width='80'
                                              height='80'
                                              className='main_img'
                                            />

                                            <img
                                              style={{
                                                marginLeft: '-13px',
                                                marginTop: '-10px',
                                                cursor: 'pointer',
                                              }}
                                              src={closered}
                                           
                                              width='25'
                                              height='25'
                                              onClick={(e) => {
                                                onFilesAddedSingle(e, 1, file?._id)
                                              }}
                                            />
                                          </div>
                                          <p className=''>
                                            {getFilename(file?.fileName, false)
                                              ?.length > 6
                                              ? `${file?.fileName?.slice(
                                                  0,
                                                  6
                                                )}...${getFilename(
                                                  file?.fileName,
                                                  true
                                                )}`
                                              : file?.fileName}
                                          </p>
                                        </div>
                                      )
                                    })}
                                  </div> */}
                                              </>
                                            ) : (
                                              <div className='text-center'> No Files</div>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                      
                                    </>
                                  ) : (
                                    <>
                                      {folders
                                        ?.sort((a: any, b: any) => a.name.localeCompare(b.name))
                                        ?.map((fl: any, index: any) => {
                                          return (
                                            <div className='col-md-6 col-lg-4 col-xl-4'>
                                              <div style={{height: '30px'}}>
                                                {!fl?.isSystemGenerated && (
                                                  <img
                                                    src={trashImg}
                                                    height={16}
                                                    width={16}
                                                    className='ms-1 cursor-pointer'
                                                    onClick={() => {

                                                      Swal.fire({
                                                        html: `
                                            <div class='fs-4 mt-4 fw-bold'> 
                                            Delete Folder
                                            </div>
                                            <div class='fs-5 mt-4'> 
                                               Are you sure you want to permanently delete this folder?
                                            </div>`,
                                                        showConfirmButton: true,
                                                        confirmButtonColor: '#d54645',
                                                        confirmButtonText: 'Yes',
                                                        showCancelButton: true,
                                                        cancelButtonColor: '#0d9e91',
                                                        width:'205px',
                                                        padding:'0px',
                                                        
                                                        // cancelButtonColor: "transparent",
                                                        cancelButtonText: 'No',
                                                      }).then((res: any) => {
                                                        if (res.isConfirmed) {
                                                          deleteFolder(index)
                                                        }
                                                      })
                                                    }}
                                                  />
                                                )}
                                              </div>
                                              <div className='card '>
                                                <div className='card-body d-flex justify-content-center text-center flex-column px-4 py-4'>
                                                  <div className='d-flex justify-content-end'>
                                                    <div
                                                      style={{
                                                        height: '22px',
                                                        width: '22px',
                                                        backgroundColor: '#d9d9d9',
                                                        color: '#000',
                                                        borderRadius: '11px',
                                                      }}
                                                    >
                                                      {fl?.fileList?.length}
                                                    </div>
                                                  </div>
                                                  <a
                                                    onClick={() => getFolderFiles(fl)}
                                                    className='text-gray-800 text-hover-primary d-flex flex-column'
                                                  >
                                                    <div className='symbol symbol-60px mb-5'>
                                                      <img
                                                        src={
                                                          tenantData?.document?.contract?.split(
                                                            '.'
                                                          )[1] === 'pdf'
                                                            ? pdfDocument
                                                            : tenantData?.other?.split('.')[1] ===
                                                              'doc'
                                                            ? docCocument
                                                            : folderIcon
                                                        }
                                                        className='theme-light-show'
                                                        alt=''
                                                      />
                                                      <img
                                                        src={folderIcon}
                                                        className='theme-dark-show'
                                                        alt=''
                                                      />
                                                    </div>
                                                    <div className='fs-5 fw-bold mb-2'>
                                                      <h4>{fl?.name}</h4>
                                                    </div>
                                                  </a>
                                                </div>
                                              </div>
                                            </div>
                                          )
                                        })}
                                    </>
                                  )}
                                </>
                              )}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div
              className='row py-5 m-0 shadow-sm'
              style={{position: 'sticky', bottom: 0, background: '#fff', zIndex: 2}}
            >
              <div className='col-12 d-flex justify-content-between align-items-center '>
                <button
                  disabled={isCTADisabled}
                  type='button'
                  className='btn btn-sm fw-bold px-2 green-submit-btn justify-content-center ms-7 me-7'
                  style={{width: '140px'}}
                  // onClick={validateInvitation}
                  onClick={handleSubmit((data: any, e: any) => addTenant(tenantData, e,0))}
                >
                  Add Tenant
                </button>
                <button
                  disabled={isCTADisabled}
                  type='submit'
                  className='btn btn-sm fw-bold px-2 green-submit-btn justify-content-center ms-7 me-7'
                  style={{width: '140px'}}
                  onClick={handleSubmit((data: any, e: any) => addTenant(tenantData, e,1))}
                  // onClick={() => {

                  // }}
                >
                  Add & Invite
                </button>
              </div>
            </div>
          </form>
        </div>
      </Modal>

      {/* {addInviteModal && (
        <AddInviteModel
          show={addInviteModal}
          // tenantData={tenantData}
          // folders={folders}
          handleClose={() => {
            setAddInviteModal(false)
            // getTenantCountSummary()
            // getTenants()
          }}
        />
      )} */}
      <ModalLoader
        isVisible={showLoader}
        progress={uploadProgress}
        totalFiles={totalFiles}
        uploadingText={uploadingText}
        percentageProgress={percentProgress}
      />
    </>
  )
}

export default AddTenantModel
