import React, {useEffect, useState} from 'react'
import {Modal, Button, DatePicker} from 'antd'
import moment, {Moment} from 'moment'
import {DemoContainer} from '@mui/x-date-pickers/internals/demo'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {TimePicker} from '@mui/x-date-pickers/TimePicker'
import dayjs, {Dayjs} from 'dayjs'
import redCross from '../../../../img/remove.png'
import addWhite from '../../../../img/add-white.png'
import {ApiPost} from '../../../../apiCommon/helpers/API/ApiData'
import {SuccessToast, ErrorToast} from '../../../../apiCommon/helpers/Toast'
import {useParams} from 'react-router-dom'
import clock from '../../../../img/three-clock-white.png' 

interface ButtonProps {
  show: any
  handleClose: any
  updateData?: any
  modalData?: any
}

const JobStartTime = ({show, handleClose, updateData, modalData}: ButtonProps) => {
  const format = 'HH:mm'

  const {id} = useParams()

  // console.log(modalData)

  const [selectedDate, setSelectedDate] = useState<any>(
    modalData?.visitStartTime != null ? moment(new Date(modalData?.visitStartTime.split('T')[0]))?.format('YYYY-MM-DD') : null
  )

  const currentTime = new Date()
  const today = new Date()

  const [startValue, setStartValue] = React.useState<Dayjs | null>(
    modalData?.visitStartTime ? dayjs(modalData?.visitStartTime) : null
  )
  const [endValue, setEndValue] = React.useState<Dayjs | null>(
    modalData?.visitEndTime ? dayjs(modalData?.visitEndTime) : null
  )

  const [isLoading, setIsLoading] = useState<any>(false)

  const [startTimeMinute, setstartTimeMinute] = useState<any>(
    modalData?.visitStartTime ? modalData?.visitStartTime : null
  )
  const [endTimeMinute, setendTimeMinute] = useState<any>(
    modalData?.visitEndTime ? modalData?.visitEndTime : null
  )

  const handleStartValueChange = (value: any) => {
    let temp = new Date(value)

    // const utcTime = temp.toISOString()
    // let x = temp.toUTCString()
    // setStartValue(value)

    const localOffset = temp.getTimezoneOffset() * 60000; // getTimezoneOffset() returns the offset in minutes, convert to milliseconds
    const utcTime = new Date(temp.getTime() - localOffset).toISOString();

    setStartValue(value);

    // Format the selected time to 'YYYY-MM-DD HH:mm' before saving
    const formattedTime = value ? utcTime : null
    setstartTimeMinute(formattedTime)

    // setstartTimeMinute(isoStr)
  }

  //
  const startVisitSchedule = () => {
    let startTime = startTimeMinute;
    let newDate = selectedDate;
    
    let updatedStartTime = startTime.replace(/^.*T/, newDate + 'T');

    setIsLoading(true)
    const body = {
      visitStartTime: updatedStartTime,
      maintenanceServiceJobScheduleId: modalData?._id,
    }
    ApiPost(`corporate/maintenance_service_job_schedule/visit/start_time`, body)
      .then((res: any) => {
        setIsLoading(false)
        SuccessToast(res?.data?.message)
        updateData()
      })
      .catch((err: any) => {
        setIsLoading(false)
        ErrorToast(err?.message)
      })
  }

  const handleDateChange = (date: any) => {
    if (date != null) setSelectedDate(date.format('YYYY-MM-DD'))
    else setSelectedDate(null)
  }

  // function disabledDate(current: Moment) {
  //   // Disable dates before today's date
  //   return current && current < moment().startOf('day')
  // }

  function disabledDate(currentDate: Moment) {
    if (modalData?.visitEndTime) {
      let endTime = moment(new Date(modalData.visitEndTime));
      return (
        (currentDate && currentDate < moment(modalData.date).startOf('day')) || 
        (currentDate && currentDate > endTime.endOf('day'))
      );
    }

    // Check if visitStartTime is null
    if (!modalData?.date) {
      return currentDate && currentDate > moment().endOf('day'); // Disable dates after today's date if visitStartTime is null
    }
    
    // Disable dates before visitStartTime and after today's date
    let visitScheduleDate = moment(new Date(modalData.date));
    return (
      (currentDate && currentDate < visitScheduleDate.startOf('day')) || 
      (currentDate && currentDate > moment().endOf('day'))
    );
  }

  return (
    <div className='schedule'>
      <Modal
        title={[
          <div
            className='d-flex align-items-center pb-4 mt-2'
            style={{borderBottom: '0.1rem solid #bec3cb'}}
          >
            <h2 className='pt-0 mb-0'>
              <b>{'Job Start Time'}</b>
            </h2>
          </div>,
        ]}
        visible={show}
        centered
        onCancel={() => handleClose()}
        footer={[
          <div className='d-flex mx-3 justify-content-center mt-0 mb-10'>
            <button
              type='button'
              className='btn btn-sm fw-bold  mx-3 red-hollow-btn status-w-140 ps-2'
              onClick={() => {
                handleClose()
              }}
              disabled={isLoading}
            >
              <img src={redCross} height={18} width={18} className='me-7' /> Cancel
            </button>

            <button
              type='button'
              className={`btn btn-sm fw-bold  me-0 green-submit-btn  ps-2  status-w-140`}
              onClick={() => {startVisitSchedule()}}
              disabled={startTimeMinute == null || isLoading  || selectedDate == null}
            >
              <img src={clock} height={18} width={18} className='me-3' />
              {'Set Start Time'}
            </button>
          </div>,
        ]}
        className='schedule'
      >

          <div className='row mx-3'>
          <div className='col-10 px-5  ps-0 my-5 mt-0 mx-auto'>
            <div className='d-flex '>
              <h4 className='my-2' style={{color: 'black'}}>
                <label style={{width: '130px'}} className='labl-gry required'>
                  {' '}
                  Start Date
                </label>
              </h4>
              <h4 style={{width: '-webkit-fill-available'}}>
                {selectedDate != null ? 
                <DatePicker
                  value={moment(new Date(selectedDate), 'DD.MM.YYYY')}
                  format={'DD.MM.YYYY'}
                  disabledDate={disabledDate}
                  onChange={handleDateChange}
                  placeholder='Select Job Start Date'
                />
                :
                <DatePicker
                  format={'DD.MM.YYYY'}
                  disabledDate={disabledDate}
                  onChange={handleDateChange}
                  placeholder='Select Job Start Date'
                />
                }
              </h4>
            </div>
          </div>
        </div>

        {/*  */}
        <div className='row mx-3'>
          <div className='col-10 px-5  ps-0 my-5 mt-3 mx-auto'>
            <div className='d-flex align-items-center'>
              <h4 className='my-2' style={{color: 'black'}}>
                <label style={{width: '130px'}} className='labl-gry required'>
                  {' '}
                  Start Time
                </label>
              </h4>
              <h4 style={{width: '-webkit-fill-available'}}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={['TimePicker']}>
                    <TimePicker
                      ampm={false}
                      value={startValue}
                      onChange={(newValue) => handleStartValueChange(newValue)}
                      minTime={
                        new Date(selectedDate).getFullYear() === today.getFullYear() &&
                        new Date(selectedDate).getMonth() === today.getMonth() &&
                        new Date(selectedDate).getDate() === today.getDate()
                          ? dayjs(currentTime)
                          : null
                      }
                      maxTime={selectedDate === moment(modalData?.visitEndTime).format('YYYY-MM-DD') ? endValue : null}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </h4>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default JobStartTime
