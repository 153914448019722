import React, {useEffect} from 'react'
import {useState} from 'react'
import {Row, Col, OverlayTrigger, Tooltip} from 'react-bootstrap'
import {useNavigate} from 'react-router-dom'
import './style.scss'
import redCross from '../../../img/remove.png'
import {AiOutlineExclamationCircle} from 'react-icons/ai'
import {FALSE} from 'sass'

interface ButtonProps {
  roleData: any
  from: any
  updateRoleDate: any
}

const LeasingResidential = ({roleData, from, updateRoleDate}: ButtonProps) => {
  const [cameFrom, setCameFrom] = useState<any>(from)
  const navigate = useNavigate()
  const [leasingResidential, setLeasingResidential] = useState<any>(
    roleData?.featureList?.leasing_residential
  )

  const handleChangeRoleData = (data: any, obj: any, from: any, e: any) => {
    if (cameFrom != 'view') {
      // console.log(e.target.value)
      // console.log(e.target.checked)
      let values = {...leasingResidential}
      let temp:any = localStorage.getItem('roleDataBackup')
      let parseTemp = JSON.parse(temp)
 
      // Leasing Residential - View List
      if (from == 'view_list' && obj == 'lease_agreements') {
        data.view_list = e.target.checked
        values.lease_agreements.isRemoved = undefined
        // Uncheck Value 
        if (!e.target.checked) {
          data.view_details = false
          data.create = false
          data.update = false
          data.delete = false
          data.approve = false
          values.lease_agreements.tenant_detail.view_details = false
          values.lease_agreements.tenant_detail.create = false
          values.lease_agreements.tenant_detail.update = false
          values.lease_agreements.contract_detail.view_details = false
          values.lease_agreements.contract_detail.create = false
          values.lease_agreements.contract_detail.update = false
          if (values.lease_financial.view_list || values.lease_financial.view_details)
            values.lease_financial.isRemoved = undefined
          values.lease_financial.view_list = false
          values.lease_financial.view_details = false
          values.lease_financial.approve = false
        }
        values.obj = data

        values.lease_financial = parseTemp?.featureList?.leasing_residential?.lease_financial
        setLeasingResidential(values)
      }

      // Leasing Residential -  View Details
      else if (from == 'view_details' && obj == 'lease_agreements') {
        data.view_details = e.target.checked
        values.lease_agreements.isRemoved = undefined
        // check value
        if (e.target.checked) {
          data.view_list = true
          values.lease_agreements.tenant_detail.view_details = true
          values.lease_agreements.contract_detail.view_details = true
        } 
        // Uncheck value
        else {
          data.create = false
          data.update = false
          data.delete = false
          data.approve = false
          values.lease_agreements.tenant_detail.view_details = false
          values.lease_agreements.tenant_detail.create = false
          values.lease_agreements.tenant_detail.update = false
          values.lease_agreements.contract_detail.view_details = false
          values.lease_agreements.contract_detail.create = false
          values.lease_agreements.contract_detail.update = false

          if (values.renewals.view_details || values.renewals.create || values.renewals.update)
            values.renewals.isRemoved = undefined

          values.renewals.view_details = false
          values.renewals.create = false
          values.renewals.update = false

          if (
            values.terminations.view_details ||
            values.terminations.create ||
            values.terminations.update
          )
            values.terminations.isRemoved = undefined

          values.terminations.view_details = false
          values.terminations.create = false
          values.terminations.update = false

          if (values.lease_financial.view_list || values.lease_financial.view_details)
            values.lease_financial.isRemoved = undefined
          values.lease_financial.view_list = false
          values.lease_financial.view_details = false
          values.lease_financial.approve = false
        }
        values.obj = data

        values.lease_financial = parseTemp?.featureList?.leasing_residential?.lease_financial
        setLeasingResidential(values)
      }

      // Leasing Residential-  Create
      else if (from == 'create' && obj == 'lease_agreements') {
        data.create = e.target.checked
        values.lease_agreements.isRemoved = undefined
        // check value
        if (e.target.checked) {
          values.lease_agreements.tenant_detail.create = true
          values.lease_agreements.contract_detail.create = true
          if (!data.view_details) {
            data.view_list = true
            data.view_details = true
            values.lease_agreements.tenant_detail.view_details = true
            values.lease_agreements.contract_detail.view_details = true
          }
        } 
        // Uncheck value
        else {
          values.lease_agreements.tenant_detail.create = false
          values.lease_agreements.contract_detail.create = false
          data.approve = false
        }
        values.obj = data
        setLeasingResidential(values)
      }

      // Leasing Residential - Update
      else if (from == 'update' && obj == 'lease_agreements') {
        data.update = e.target.checked
        values.lease_agreements.isRemoved = undefined
        // check value
        if (e.target.checked) {
          values.lease_agreements.tenant_detail.update = true
          values.lease_agreements.contract_detail.update = true
          if (!data.view_details) {
            data.view_list = true
            data.view_details = true
            values.lease_agreements.tenant_detail.view_details = true
            values.lease_agreements.contract_detail.view_details = true
          }
        } 
        // uncheck value
        else {
          values.lease_agreements.tenant_detail.update = false
          values.lease_agreements.contract_detail.update = false
          data.approve = false
        }
        values.obj = data
        setLeasingResidential(values)
      }

      // Leasing Residential -  Delete
      else if (from == 'delete' && obj == 'lease_agreements') {
        data.delete = e.target.checked
        values.lease_agreements.isRemoved = undefined
        // check value
        if (e.target.checked) {
          data.view_list = true
        }
        // uncheck value
        else{
          data.approve = false
        }
        values.obj = data
        setLeasingResidential(values)
      }

      // Leasing Residential -  Approve
      else if (from == 'approve' && obj == 'lease_agreements') {
        data.approve = e.target.checked
        values.lease_agreements.isRemoved = undefined

        // Check value
        if (e.target.checked) {
          data.view_list = true
          data.view_details = true
          if(!values.lease_agreements?.tenant_detail?.view_details && !values.lease_agreements?.contract_detail?.view_details){
            values.lease_agreements.contract_detail.view_details = true
            values.lease_agreements.tenant_detail.view_details = true
          }

          data.create = true
          if(!values.lease_agreements?.tenant_detail?.create && !values.lease_agreements?.contract_detail?.create){
            values.lease_agreements.contract_detail.create = true
            values.lease_agreements.tenant_detail.create = true
          }

          data.update = true
          if(!values.lease_agreements?.tenant_detail?.update && !values.lease_agreements?.contract_detail?.update){
            values.lease_agreements.contract_detail.update = true
            values.lease_agreements.tenant_detail.update = true
          }
         
          data.delete = true

        }
        values.obj = data
        setLeasingResidential(values)
      }

      // Cancellations - Allow
      else if (from == 'allow' && obj == 'cancellation') {
        data.allow = e.target.checked
        values.obj = data
        values.cancellation.isRemoved = undefined
        setLeasingResidential(values)
      }

      // Tenant Details - View Details
      else if (from == 'view_details' && obj == 'tenant_detail') {
        data.view_details = !data.view_details
        values.lease_agreements.isRemoved = undefined
        // check value
        if (data.view_details) {
          values.lease_agreements.view_details = true
          values.lease_agreements.view_list = true
        } else if (!data.view_details && !values.lease_agreements?.contract_detail?.view_details) {
          values.lease_agreements.view_details = false
          values.lease_agreements.create = false
          values.lease_agreements.update = false
          values.lease_agreements.delete = false
          values.lease_agreements.approve = false
          data.create = false
          data.update = false
          values.lease_agreements.contract_detail.create = false
          values.lease_agreements.contract_detail.update = false

          if (values.renewals.view_details || values.renewals.create || values.renewals.update)
            values.renewals.isRemoved = undefined
          values.renewals.view_details = false
          values.renewals.create = false
          values.renewals.update = false

          if (
            values.terminations.view_details ||
            values.terminations.create ||
            values.terminations.update
          )
            values.terminations.isRemoved = undefined

          values.terminations.view_details = false
          values.terminations.create = false
          values.terminations.update = false

          if (values.lease_financial.view_list || values.lease_financial.view_details)
            values.lease_financial.isRemoved = undefined
          values.lease_financial.view_list = false
          values.lease_financial.view_details = false
          values.lease_financial.approve = false
        }
        values.obj = data
        setLeasingResidential(values)
      }

      // Tenant Details - Create
      else if (from == 'create' && obj == 'tenant_detail') {
        data.create = !data.create
        values.lease_agreements.isRemoved = undefined
        if (data.create) {
          values.lease_agreements.create = true
          data.view_details = true
          if (!values.lease_agreements.view_details) {
            values.lease_agreements.view_details = true
            values.lease_agreements.view_list = true
            values.lease_agreements.contract_detail.view_details = true
          }
        } else if (!data.create && !values.lease_agreements?.contract_detail?.create) {
          values.lease_agreements.create = false
          values.lease_agreements.approve = false
        }
        values.obj = data
        setLeasingResidential(values)
      }

      // Tenant Details - Update
      else if (from == 'update' && obj == 'tenant_detail') {
        data.update = !data.update
        values.lease_agreements.isRemoved = undefined
        if (data.update) {
          data.view_details = true
          values.lease_agreements.update = true
          if (!values.lease_agreements.view_details) {
            values.lease_agreements.view_details = true
            values.lease_agreements.view_list = true
            data.view_details = true
            values.lease_agreements.contract_detail.view_details = true
          }
        } else if (!data.update && !values.lease_agreements?.contract_detail?.update) {
          values.lease_agreements.update = false
          values.lease_agreements.approve = false
        }
        values.obj = data
        setLeasingResidential(values)
      }

      // contract Details - View Details
      else if (from == 'view_details' && obj == 'contract_detail') {
        data.view_details = !data.view_details
        values.lease_agreements.isRemoved = undefined
        if (data.view_details) {
          values.lease_agreements.view_details = true
          values.lease_agreements.view_list = true
        } else if (!data.view_details && !values.lease_agreements?.tenant_detail?.view_details) {
          values.lease_agreements.view_details = false
          values.lease_agreements.create = false
          values.lease_agreements.update = false
          values.lease_agreements.delete = false
          values.lease_agreements.approve = false
          data.create = false
          data.update = false
          values.lease_agreements.tenant_detail.create = false
          values.lease_agreements.tenant_detail.update = false

          if (values.renewals.view_details || values.renewals.create || values.renewals.update)
            values.renewals.isRemoved = undefined

          values.renewals.view_details = false
          values.renewals.create = false
          values.renewals.update = false

          if (
            values.terminations.view_details ||
            values.terminations.create ||
            values.terminations.update
          )
            values.terminations.isRemoved = undefined

          values.terminations.view_details = false
          values.terminations.create = false
          values.terminations.update = false

          if (values.lease_financial.view_list || values.lease_financial.view_details)
            values.lease_financial.isRemoved = undefined
          values.lease_financial.view_list = false
          values.lease_financial.view_details = false
          values.lease_financial.approve = false
        }
        values.obj = data
        setLeasingResidential(values)
      }

      // contract Details - Create
      else if (from == 'create' && obj == 'contract_detail') {
        data.create = !data.create
        values.lease_agreements.isRemoved = undefined
        if (data.create) {
          values.lease_agreements.create = true
          data.view_details = true
          if (!values.lease_agreements.view_details) {
            values.lease_agreements.view_details = true
            values.lease_agreements.view_list = true
            values.lease_agreements.tenant_detail.view_details = true
          }
        } else if (!data.create && !values.lease_agreements?.tenant_detail?.create) {
          values.lease_agreements.create = false
          values.lease_agreements.approve = false
        }
        values.obj = data
        setLeasingResidential(values)
      }

      // contract Details - Update
      else if (from == 'update' && obj == 'contract_detail') {
        data.update = !data.update
        values.lease_agreements.isRemoved = undefined
        if (data.update) {
          data.view_details = true
          values.lease_agreements.update = true
          if (!values.lease_agreements.view_details) {
            values.lease_agreements.view_details = true
            values.lease_agreements.view_list = true
            data.view_details = true
            values.lease_agreements.tenant_detail.view_details = true
          }
        } else if (!data.update && !values.lease_agreements?.tenant_detail?.update) {
          values.lease_agreements.update = false
          values.lease_agreements.approve = false
        }
        values.obj = data
        setLeasingResidential(values)
      }

      // Renewal Or Terminations - View List
      if (from == 'view_list' && (obj == 'renewals' || obj == 'terminations')) {
        data.view_list = e.target.checked
        if (obj == 'renewals') values.renewals.isRemoved = undefined
        else values.terminations.isRemoved = undefined
        if (!e.target.checked) {
          data.view_details = false
          data.create = false
          data.update = false
          data.delete = false
          data.approve = false
        }
        values.obj = data
        setLeasingResidential(values)
      }

      // Renewal Or Terminations -  View Details
      else if (from == 'view_details' && (obj == 'renewals' || obj == 'terminations')) {
        data.view_details = e.target.checked
        if (obj == 'renewals') values.renewals.isRemoved = undefined
        else values.terminations.isRemoved = undefined
        // check value
        if (e.target.checked) {
          data.view_list = true
          if (!values.lease_agreements.view_details) {
            if (
              !values.lease_agreements.view_details ||
              !values.lease_agreements.contract_detail.view_details ||
              !values.lease_agreements.tenant_detail.view_details ||
              !values.lease_agreements.view_list
            )
              values.lease_agreements.isRemoved = undefined
            values.lease_agreements.view_details = true
            values.lease_agreements.contract_detail.view_details = true
            values.lease_agreements.tenant_detail.view_details = true
            values.lease_agreements.view_list = true
          }
        } 
        // uncheck value
        else {
          data.create = false
          data.update = false
          data.delete = false
          data.approve = false
        }
        values.obj = data
        setLeasingResidential(values)
      }

      // Renewal Or Terminations-  Create
      else if (from == 'create' && (obj == 'renewals' || obj == 'terminations')) {
        data.create = e.target.checked
        if (obj == 'renewals') values.renewals.isRemoved = undefined
        else values.terminations.isRemoved = undefined

        // check value
        if (e.target.checked) {
          data.view_details = true
          data.view_list = true
          data.update = true
          if (!values.lease_agreements.view_details) {
            if (
              !values.lease_agreements.view_details ||
              !values.lease_agreements.contract_detail.view_details ||
              !values.lease_agreements.tenant_detail.view_details ||
              !values.lease_agreements.view_list
            )
              values.lease_agreements.isRemoved = undefined
            values.lease_agreements.view_details = true
            values.lease_agreements.contract_detail.view_details = true
            values.lease_agreements.tenant_detail.view_details = true
            values.lease_agreements.view_list = true
          }
        } 
        // uncheck value
        else {
          data.update = false
          data.approve = false
        }
        values.obj = data
        setLeasingResidential(values)
      }

      // Renewal Or Terminations - Update
      else if (from == 'update' && (obj == 'renewals' || obj == 'terminations')) {
        data.update = e.target.checked
        if (obj == 'renewals') values.renewals.isRemoved = undefined
        else values.terminations.isRemoved = undefined
        //  check value
        if (e.target.checked) {
          data.view_details = true
          data.view_list = true
          data.create = true
          if (!values.lease_agreements.view_details) {
            if (
              !values.lease_agreements.view_details ||
              !values.lease_agreements.contract_detail.view_details ||
              !values.lease_agreements.tenant_detail.view_details ||
              !values.lease_agreements.view_list
            )
              values.lease_agreements.isRemoved = undefined
            values.lease_agreements.view_details = true
            values.lease_agreements.contract_detail.view_details = true
            values.lease_agreements.tenant_detail.view_details = true
            values.lease_agreements.view_list = true
          }
        } 
        // uncheck value
        else {
          data.create = false
          data.approve = false
        }
        values.obj = data
        setLeasingResidential(values)
      }

      // Renewal Or Terminations -  Delete
      else if (from == 'delete' && (obj == 'renewals' || obj == 'terminations')) {
        data.delete = e.target.checked

        if (obj == 'renewals') values.renewals.isRemoved = undefined
        else values.terminations.isRemoved = undefined

        // check value
        if (e.target.checked) {
          data.view_list = true
        }
        // uncheck value
        else{
          data.approve = false
        }
        values.obj = data
        setLeasingResidential(values)
      }

      // Renewal Or Terminations -  Approve
      else if (from == 'approve' && (obj == 'renewals' || obj == 'terminations')) {
        data.approve = e.target.checked

        if (obj == 'renewals') values.renewals.isRemoved = undefined
        else values.terminations.isRemoved = undefined

        if (e.target.checked) {
          data.view_list = true
          data.view_details = true
          data.create = true
          data.update = true
          data.delete = true
        }
        values.obj = data
        setLeasingResidential(values)
      }

      // Lease Deletion - Allow
      else if (from == 'allow' && obj == 'cancellation') {
        data.allow = e.target.checked
        values.obj = data
        setLeasingResidential(values)
      }

      // Lease Deletion - Allow
      else if (from == 'allow' && obj == 'lease_deletion') {
        data.allow = e.target.checked
        values.obj = data
        values.lease_deletion.isRemoved = undefined
        setLeasingResidential(values)
      }

      // Leasing Financials - View List
      if (from == 'view_list' && obj == 'lease_financial') {
        data.view_list = e.target.checked
        values.lease_financial.isRemoved = undefined

        // uncheck value
        if (!e.target.checked) {
          data.view_details = false
          data.create = false
          data.update = false
          data.delete = false
          data.approve = false

          // Sub features
          values.lease_financial.invoicing.view_list = false
          values.lease_financial.invoicing.view_details = false
          values.lease_financial.invoicing.create = false
          values.lease_financial.invoicing.update = false
          values.lease_financial.invoicing.delete = false
          values.lease_financial.receipts.view_list = false
          values.lease_financial.receipts.view_details = false
          values.lease_financial.receipts.create = false
          values.lease_financial.receipts.update = false
          values.lease_financial.receipts.delete = false
          values.lease_financial.refunds.view_list = false
          values.lease_financial.refunds.view_details = false
          values.lease_financial.refunds.create = false
          values.lease_financial.refunds.update = false
          values.lease_financial.refunds.delete = false
          values.lease_financial.credit_notes.view_list = false
          values.lease_financial.credit_notes.view_details = false
          values.lease_financial.credit_notes.create = false
          values.lease_financial.credit_notes.update = false
          values.lease_financial.credit_notes.delete = false
          values.lease_financial.debit_notes.view_list = false
          values.lease_financial.debit_notes.view_details = false
          values.lease_financial.debit_notes.create = false
          values.lease_financial.debit_notes.update = false
          values.lease_financial.debit_notes.delete = false
          values.lease_financial.items.view_list = false
          values.lease_financial.items.view_details = false
          values.lease_financial.items.create = false
          values.lease_financial.items.update = false
          values.lease_financial.items.delete = false
          values.lease_financial.vat.view_list = false
          values.lease_financial.vat.view_details = false
          values.lease_financial.vat.create = false
          values.lease_financial.vat.update = false
          values.lease_financial.vat.delete = false
          values.lease_financial.late_payment_fees.view_list = false
          values.lease_financial.late_payment_fees.view_details = false
          values.lease_financial.late_payment_fees.create = false
          values.lease_financial.late_payment_fees.update = false
          values.lease_financial.late_payment_fees.delete = false
          values.lease_financial.payment_method.view_list = false
          values.lease_financial.payment_method.view_details = false
          values.lease_financial.payment_method.create = false
          values.lease_financial.payment_method.update = false
          values.lease_financial.payment_method.delete = false
          values.lease_financial.payment_reminders.view_list = false
          values.lease_financial.payment_reminders.view_details = false
          values.lease_financial.payment_reminders.create = false
          values.lease_financial.payment_reminders.update = false
          values.lease_financial.payment_reminders.delete = false
          values.lease_financial.attachments.view_list = false
          values.lease_financial.attachments.view_details = false
          values.lease_financial.attachments.create = false
          values.lease_financial.attachments.update = false
          values.lease_financial.attachments.delete = false
          values.lease_financial.notes.view_list = false
          values.lease_financial.notes.view_details = false
          values.lease_financial.notes.create = false
          values.lease_financial.notes.update = false
          values.lease_financial.notes.delete = false
        } 
        // check value
        else {
          if (!values.lease_agreements.view_details) {
            if (
              !values.lease_agreements.view_details ||
              !values.lease_agreements.contract_detail.view_details ||
              !values.lease_agreements.tenant_detail.view_details ||
              !values.lease_agreements.view_list
            )
              values.lease_agreements.isRemoved = undefined
            values.lease_agreements.view_details = true
            values.lease_agreements.contract_detail.view_details = true
            values.lease_agreements.tenant_detail.view_details = true
            values.lease_agreements.view_list = true
          }

          // Sub features
          values.lease_financial.invoicing.view_list = true
          values.lease_financial.receipts.view_list = true
          values.lease_financial.refunds.view_list = true
          values.lease_financial.credit_notes.view_list = true
          values.lease_financial.debit_notes.view_list = true
          values.lease_financial.items.view_list = true
          values.lease_financial.items.view_details = true
          values.lease_financial.vat.view_list = true
          values.lease_financial.vat.view_details = true
          values.lease_financial.vat.view_list = true
          values.lease_financial.late_payment_fees.view_list = true
          values.lease_financial.late_payment_fees.view_details = true
          values.lease_financial.payment_method.view_list = true
          values.lease_financial.payment_method.view_details = true
          values.lease_financial.payment_reminders.view_list = true
          values.lease_financial.payment_reminders.view_details = true
          values.lease_financial.attachments.view_list = true
          values.lease_financial.attachments.view_details = true
          values.lease_financial.notes.view_list = true
          values.lease_financial.notes.view_details = true
        }
        values.obj = data
        setLeasingResidential(values)
      }

      // Leasing Financials - View Details
      if (from == 'view_details' && obj == 'lease_financial') {
        data.view_details = e.target.checked
        values.lease_financial.isRemoved = undefined
        // check value
        if (e.target.checked) {
          data.view_list = true
          if (!values.lease_agreements.view_details) {
            if (
              !values.lease_agreements.view_details ||
              !values.lease_agreements.contract_detail.view_details ||
              !values.lease_agreements.tenant_detail.view_details ||
              !values.lease_agreements.view_list
            )
              values.lease_agreements.isRemoved = undefined
            values.lease_agreements.view_details = true
            values.lease_agreements.contract_detail.view_details = true
            values.lease_agreements.tenant_detail.view_details = true
            values.lease_agreements.view_list = true
          }
          values.lease_financial.invoicing.view_details = true
          values.lease_financial.invoicing.view_list = true
          values.lease_financial.receipts.view_list = true
          values.lease_financial.receipts.view_details = true
          values.lease_financial.receipts.view_list = true
          values.lease_financial.refunds.view_details = true
          values.lease_financial.refunds.view_list = true
          values.lease_financial.credit_notes.view_details = true
          values.lease_financial.credit_notes.view_list = true
          values.lease_financial.debit_notes.view_details = true
          values.lease_financial.debit_notes.view_list = true
          values.lease_financial.items.view_details = true
          values.lease_financial.items.view_list = true
          values.lease_financial.vat.view_details = true
          values.lease_financial.vat.view_list = true
          values.lease_financial.late_payment_fees.view_details = true
          values.lease_financial.late_payment_fees.view_list = true
          values.lease_financial.payment_method.view_details = true
          values.lease_financial.payment_method.view_list = true
          values.lease_financial.payment_reminders.view_details = true
          values.lease_financial.payment_reminders.view_list = true
          values.lease_financial.attachments.view_details = true
          values.lease_financial.attachments.view_list = true
          values.lease_financial.notes.view_details = true
          values.lease_financial.notes.view_list = true
        } 
        // uncheck value
        else {
          data.approve = false
          values.lease_financial.invoicing.view_details = false
          values.lease_financial.invoicing.create = false
          values.lease_financial.invoicing.update = false
          if (values?.lease_financial?.items?.update) {
            values.lease_financial.invoicing.view_list = false
            values.lease_financial.invoicing.delete = false
            values.lease_financial.items.update = false
          }
          values.lease_financial.receipts.view_details = false
          values.lease_financial.receipts.create = false
          values.lease_financial.receipts.update = false
          values.lease_financial.refunds.view_details = false
          values.lease_financial.refunds.create = false
          values.lease_financial.refunds.update = false
          values.lease_financial.credit_notes.view_details = false
          values.lease_financial.credit_notes.create = false
          values.lease_financial.credit_notes.update = false
          values.lease_financial.debit_notes.view_details = false
          values.lease_financial.debit_notes.create = false
          values.lease_financial.debit_notes.update = false
          values.lease_financial.items.view_details = false
          values.lease_financial.items.create = false
          values.lease_financial.items.update = false
          values.lease_financial.vat.view_details = false
          values.lease_financial.vat.view_list = false
          values.lease_financial.vat.create = false
          values.lease_financial.vat.update = false
          values.lease_financial.late_payment_fees.view_details = false
          values.lease_financial.late_payment_fees.view_list = false
          values.lease_financial.late_payment_fees.create = false
          values.lease_financial.late_payment_fees.update = false
          values.lease_financial.payment_method.view_details = false
          values.lease_financial.payment_method.view_list = false
          values.lease_financial.payment_method.create = false
          values.lease_financial.payment_method.update = false
          values.lease_financial.payment_method.delete = false
          values.lease_financial.payment_reminders.view_details = false
          values.lease_financial.payment_reminders.view_list = false
          values.lease_financial.payment_reminders.create = false
          values.lease_financial.payment_reminders.update = false
          values.lease_financial.payment_reminders.delete = false
          values.lease_financial.attachments.view_details = false
          values.lease_financial.attachments.view_list = false
          values.lease_financial.attachments.create = false
          values.lease_financial.attachments.update = false
          values.lease_financial.attachments.delete = false
          values.lease_financial.notes.view_details = false
          values.lease_financial.notes.view_list = false
          values.lease_financial.notes.create = false
          values.lease_financial.notes.update = false
          values.lease_financial.notes.delete = false
        }
        values.obj = data
        setLeasingResidential(values)
      }

      // Leasing Financials - Create
      if (from == 'create' && obj == 'lease_financial') {
        data.create = e.target.checked
        values.lease_financial.isRemoved = undefined
            // check value
        if (e.target.checked) {
          data.view_details = true
          data.view_list = true

          data.update = true
          if (!values.lease_agreements.view_details) {
            if (
              !values.lease_agreements.view_details ||
              !values.lease_agreements.contract_detail.view_details ||
              !values.lease_agreements.tenant_detail.view_details ||
              !values.lease_agreements.view_list
            )
              values.lease_agreements.isRemoved = undefined
            values.lease_agreements.view_details = true
            values.lease_agreements.contract_detail.view_details = true
            values.lease_agreements.tenant_detail.view_details = true
            values.lease_agreements.view_list = true
          }
          // sub features
          values.lease_financial.invoicing.update = true
          values.lease_financial.invoicing.view_list = true
          values.lease_financial.invoicing.view_details = true
          values.lease_financial.receipts.view_list = true
          values.lease_financial.receipts.view_details = true
          values.lease_financial.receipts.update = true
          values.lease_financial.refunds.update = true
          values.lease_financial.refunds.view_list = true
          values.lease_financial.refunds.view_details = true
          values.lease_financial.credit_notes.update = true
          values.lease_financial.credit_notes.view_list = true
          values.lease_financial.credit_notes.view_details = true
          values.lease_financial.debit_notes.update = true
          values.lease_financial.debit_notes.view_list = true
          values.lease_financial.debit_notes.view_details = true
          values.lease_financial.items.update = true
          values.lease_financial.items.view_list = true
          values.lease_financial.items.view_details = true
          values.lease_financial.invoicing.create = true
          values.lease_financial.invoicing.update = true
          values.lease_financial.items.delete = true
          values.lease_financial.vat.update = true
          values.lease_financial.vat.view_list = true
          values.lease_financial.vat.view_details = true
          values.lease_financial.vat.delete = true
          values.lease_financial.late_payment_fees.update = true
          values.lease_financial.late_payment_fees.view_list = true
          values.lease_financial.late_payment_fees.view_details = true
          values.lease_financial.late_payment_fees.delete = true
          values.lease_financial.payment_method.update = true
          values.lease_financial.payment_method.view_list = true
          values.lease_financial.payment_method.view_details = true
          values.lease_financial.payment_method.create = true
          values.lease_financial.payment_reminders.update = true
          values.lease_financial.payment_reminders.view_list = true
          values.lease_financial.payment_reminders.view_details = true
          values.lease_financial.payment_reminders.create = true
          values.lease_financial.payment_reminders.delete = true
          values.lease_financial.attachments.update = true
          values.lease_financial.attachments.view_list = true
          values.lease_financial.attachments.view_details = true
          values.lease_financial.attachments.create = true
          values.lease_financial.attachments.delete = true
          values.lease_financial.notes.update = true
          values.lease_financial.notes.view_list = true
          values.lease_financial.notes.view_details = true
          values.lease_financial.notes.create = true
          values.lease_financial.notes.delete = true

          values.lease_financial.delete = true

          if (!values.lease_agreements.view_details) {
            if (
              !values.lease_agreements.view_details ||
              !values.lease_agreements.contract_detail.view_details ||
              !values.lease_agreements.tenant_detail.view_details ||
              !values.lease_agreements.view_list
            )
              values.lease_agreements.isRemoved = undefined
            values.lease_agreements.view_details = true
            values.lease_agreements.contract_detail.view_details = true
            values.lease_agreements.tenant_detail.view_details = true
            values.lease_agreements.view_list = true
          }

          // Sub features
          values.lease_financial.invoicing.create = true
          values.lease_financial.invoicing.view_list = true
          values.lease_financial.invoicing.view_details = true
          values.lease_financial.receipts.view_list = true
          values.lease_financial.receipts.view_details = true
          values.lease_financial.receipts.create = true
          values.lease_financial.refunds.create = true
          values.lease_financial.refunds.view_list = true
          values.lease_financial.refunds.view_details = true
          values.lease_financial.credit_notes.create = true
          values.lease_financial.credit_notes.view_list = true
          values.lease_financial.credit_notes.view_details = true
          values.lease_financial.debit_notes.create = true
          values.lease_financial.debit_notes.view_list = true
          values.lease_financial.debit_notes.view_details = true
          values.lease_financial.items.create = true
          values.lease_financial.items.view_list = true
          values.lease_financial.items.view_details = true
          values.lease_financial.vat.create = true
          values.lease_financial.vat.view_list = true
          values.lease_financial.vat.view_details = true
          values.lease_financial.late_payment_fees.create = true
          values.lease_financial.late_payment_fees.view_list = true
          values.lease_financial.late_payment_fees.view_details = true
          values.lease_financial.payment_method.create = true
          values.lease_financial.payment_method.view_list = true
          values.lease_financial.payment_method.view_details = true
          values.lease_financial.payment_method.update = true
          values.lease_financial.payment_reminders.create = true
          values.lease_financial.payment_reminders.view_list = true
          values.lease_financial.payment_reminders.view_details = true
          values.lease_financial.payment_reminders.update = true
          values.lease_financial.payment_reminders.delete = true
          values.lease_financial.attachments.create = true
          values.lease_financial.attachments.view_list = true
          values.lease_financial.attachments.view_details = true
          values.lease_financial.attachments.update = true
          values.lease_financial.attachments.delete = true
          values.lease_financial.notes.create = true
          values.lease_financial.notes.view_list = true
          values.lease_financial.notes.view_details = true
          values.lease_financial.notes.update = true
          values.lease_financial.notes.delete = true
        } 
            // uncheck value
        else {
          data.approve = false
          // Sub features
          values.lease_financial.invoicing.create = false
          if (values?.lease_financial?.items?.update) {
            values.lease_financial.invoicing.view_list = false
            values.lease_financial.invoicing.update = false
            values.lease_financial.invoicing.delete = false
            values.lease_financial.invoicing.view_details = false
            values.lease_financial.items.update = false
          }
          values.lease_financial.receipts.create = false
          values.lease_financial.refunds.create = false
          values.lease_financial.credit_notes.create = false
          values.lease_financial.debit_notes.create = false
          values.lease_financial.items.create = false
          values.lease_financial.vat.create = false
          values.lease_financial.late_payment_fees.create = false
          values.lease_financial.payment_method.create = false
          values.lease_financial.payment_method.update = false
          values.lease_financial.payment_reminders.create = false
          values.lease_financial.payment_reminders.update = false
          values.lease_financial.payment_reminders.delete = false
          values.lease_financial.attachments.create = false
          values.lease_financial.attachments.update = false
          values.lease_financial.attachments.delete = false
          values.lease_financial.notes.create = false
          values.lease_financial.notes.update = false
          values.lease_financial.notes.delete = false
        }
        values.obj = data
        setLeasingResidential(values)
      }

      // Leasing Financials - Update
      if (from == 'update' && obj == 'lease_financial') {
        data.update = e.target.checked
        values.lease_financial.isRemoved = undefined
        // check value
        if (e.target.checked) {
          data.view_details = true
          data.view_list = true
          if (!values.lease_agreements.view_details) {
            if (
              !values.lease_agreements.view_details ||
              !values.lease_agreements.contract_detail.view_details ||
              !values.lease_agreements.tenant_detail.view_details ||
              !values.lease_agreements.view_list
            )
              values.lease_agreements.isRemoved = undefined
            values.lease_agreements.view_details = true
            values.lease_agreements.contract_detail.view_details = true
            values.lease_agreements.tenant_detail.view_details = true
            values.lease_agreements.view_list = true
          }
          // sub features
          values.lease_financial.invoicing.update = true
          values.lease_financial.invoicing.view_list = true
          values.lease_financial.invoicing.view_details = true
          values.lease_financial.receipts.view_list = true
          values.lease_financial.receipts.view_details = true
          values.lease_financial.receipts.update = true
          values.lease_financial.refunds.update = true
          values.lease_financial.refunds.view_list = true
          values.lease_financial.refunds.view_details = true
          values.lease_financial.credit_notes.update = true
          values.lease_financial.credit_notes.view_list = true
          values.lease_financial.credit_notes.view_details = true
          values.lease_financial.debit_notes.update = true
          values.lease_financial.debit_notes.view_list = true
          values.lease_financial.debit_notes.view_details = true
          values.lease_financial.items.update = true
          values.lease_financial.items.view_list = true
          values.lease_financial.items.view_details = true
          values.lease_financial.invoicing.create = true
          values.lease_financial.invoicing.update = true
          values.lease_financial.items.delete = true
          values.lease_financial.vat.update = true
          values.lease_financial.vat.view_list = true
          values.lease_financial.vat.view_details = true
          values.lease_financial.vat.delete = true
          values.lease_financial.late_payment_fees.update = true
          values.lease_financial.late_payment_fees.view_list = true
          values.lease_financial.late_payment_fees.view_details = true
          values.lease_financial.late_payment_fees.delete = true
          values.lease_financial.payment_method.update = true
          values.lease_financial.payment_method.view_list = true
          values.lease_financial.payment_method.view_details = true
          values.lease_financial.payment_method.create = true
          values.lease_financial.payment_reminders.update = true
          values.lease_financial.payment_reminders.view_list = true
          values.lease_financial.payment_reminders.view_details = true
          values.lease_financial.payment_reminders.create = true
          values.lease_financial.payment_reminders.delete = true
          values.lease_financial.attachments.update = true
          values.lease_financial.attachments.view_list = true
          values.lease_financial.attachments.view_details = true
          values.lease_financial.attachments.create = true
          values.lease_financial.attachments.delete = true
          values.lease_financial.notes.update = true
          values.lease_financial.notes.view_list = true
          values.lease_financial.notes.view_details = true
          values.lease_financial.notes.create = true
          values.lease_financial.notes.delete = true
        } 
        // uncheck value
        else {
          data.approve = false
          // sub features
          values.lease_financial.invoicing.update = false
          if (values?.lease_financial?.items?.update) {
            values.lease_financial.invoicing.view_list = false
            values.lease_financial.invoicing.create = false
            values.lease_financial.invoicing.delete = false
            values.lease_financial.invoicing.view_details = false
            values.lease_financial.items.update = false
          }
          values.lease_financial.receipts.update = false
          values.lease_financial.refunds.update = false
          values.lease_financial.credit_notes.update = false
          values.lease_financial.debit_notes.update = false
          values.lease_financial.items.update = false
          values.lease_financial.items.delete = false
          values.lease_financial.vat.update = false
          values.lease_financial.vat.delete = false
          values.lease_financial.late_payment_fees.update = false
          values.lease_financial.late_payment_fees.delete = false
          values.lease_financial.payment_method.update = false
          values.lease_financial.payment_method.create = false
          values.lease_financial.payment_method.delete = false
          values.lease_financial.payment_reminders.update = false
          values.lease_financial.payment_reminders.create = false
          values.lease_financial.payment_reminders.delete = false
          values.lease_financial.attachments.update = false
          values.lease_financial.attachments.create = false
          values.lease_financial.attachments.delete = false
          values.lease_financial.notes.update = false
          values.lease_financial.notes.create = false
          values.lease_financial.notes.delete = false
        }
        values.obj = data
        setLeasingResidential(values)
      }
      // Leasing Financials - Delete
      if (from == 'delete' && obj == 'lease_financial') {
        data.delete = e.target.checked
        values.lease_financial.isRemoved = undefined
        // check value
        if (e.target.checked) {
          if (!values.lease_agreements.view_details) {
            if (
              !values.lease_agreements.view_details ||
              !values.lease_agreements.contract_detail.view_details ||
              !values.lease_agreements.tenant_detail.view_details ||
              !values.lease_agreements.view_list
            )
              values.lease_agreements.isRemoved = undefined
            values.lease_agreements.view_details = true
            values.lease_agreements.contract_detail.view_details = true
            values.lease_agreements.tenant_detail.view_details = true
            values.lease_agreements.view_list = true
          }

          // Sub features
          values.lease_financial.invoicing.delete = true
          values.lease_financial.invoicing.view_list = true
          values.lease_financial.receipts.delete = true
          values.lease_financial.receipts.view_list = true
          values.lease_financial.refunds.delete = true
          values.lease_financial.refunds.view_list = true
          values.lease_financial.credit_notes.delete = true
          values.lease_financial.credit_notes.view_list = true
          values.lease_financial.debit_notes.delete = true
          values.lease_financial.debit_notes.view_list = true
          values.lease_financial.items.delete = true
          values.lease_financial.items.view_list = true
          values.lease_financial.items.update = true
          values.lease_financial.vat.delete = true
          values.lease_financial.vat.view_list = true
          values.lease_financial.vat.update = true
          values.lease_financial.late_payment_fees.delete = true
          values.lease_financial.late_payment_fees.view_list = true
          values.lease_financial.late_payment_fees.update = true
          values.lease_financial.payment_method.delete = true
          values.lease_financial.payment_method.view_list = true
          values.lease_financial.payment_method.view_details = true
          values.lease_financial.payment_method.update = true
          values.lease_financial.payment_method.create = true
          values.lease_financial.payment_reminders.delete = true
          values.lease_financial.payment_reminders.view_list = true
          values.lease_financial.payment_reminders.view_details = true
          values.lease_financial.payment_reminders.update = true
          values.lease_financial.payment_reminders.create = true
          values.lease_financial.attachments.delete = true
          values.lease_financial.attachments.view_list = true
          values.lease_financial.attachments.view_details = true
          values.lease_financial.attachments.update = true
          values.lease_financial.attachments.create = true
          values.lease_financial.notes.delete = true
          values.lease_financial.notes.view_list = true
          values.lease_financial.notes.view_details = true
          values.lease_financial.notes.update = true
          values.lease_financial.notes.create = true
        } 
        // uncheck value
        else {
          data.approve = false
          // Sub features
          values.lease_financial.invoicing.delete = false
          values.lease_financial.receipts.delete = false
          values.lease_financial.refunds.delete = false
          values.lease_financial.credit_notes.delete = false
          values.lease_financial.debit_notes.delete = false
          values.lease_financial.items.delete = false
          values.lease_financial.items.update = false
          values.lease_financial.vat.delete = false
          values.lease_financial.vat.update = false
          values.lease_financial.late_payment_fees.delete = false
          values.lease_financial.late_payment_fees.update = false
          values.lease_financial.payment_method.delete = false
          values.lease_financial.payment_reminders.delete = false
          values.lease_financial.payment_reminders.update = true
          values.lease_financial.payment_reminders.create = true
          values.lease_financial.attachments.delete = false
          values.lease_financial.attachments.update = true
          values.lease_financial.attachments.create = true
          values.lease_financial.notes.delete = false
          values.lease_financial.notes.update = true
          values.lease_financial.notes.create = true
        }
        values.obj = data
        setLeasingResidential(values)
      }

      // Leasing Financials - Approve
      if (from == 'approve' && obj == 'lease_financial') {
        data.approve = e.target.checked
        values.lease_financial.isRemoved = undefined
        // check value
        if (e.target.checked) {
          data.view_list = true
            if (!values.lease_agreements.view_details) {
              if (
                !values.lease_agreements.view_details ||
                !values.lease_agreements.contract_detail.view_details ||
                !values.lease_agreements.tenant_detail.view_details ||
                !values.lease_agreements.view_list
              )
                values.lease_agreements.isRemoved = undefined
              values.lease_agreements.view_details = true
              values.lease_agreements.contract_detail.view_details = true
              values.lease_agreements.tenant_detail.view_details = true
              values.lease_agreements.view_list = true
            }
  
            // Sub features
            values.lease_financial.invoicing.view_list = true
            values.lease_financial.receipts.view_list = true
            values.lease_financial.refunds.view_list = true
            values.lease_financial.credit_notes.view_list = true
            values.lease_financial.debit_notes.view_list = true
            values.lease_financial.items.view_list = true
            values.lease_financial.items.view_details = true
            values.lease_financial.vat.view_list = true
            values.lease_financial.vat.view_details = true
            values.lease_financial.vat.view_list = true
            values.lease_financial.late_payment_fees.view_list = true
            values.lease_financial.late_payment_fees.view_details = true
            values.lease_financial.payment_method.view_list = true
            values.lease_financial.payment_method.view_details = true
            values.lease_financial.payment_reminders.view_list = true
            values.lease_financial.payment_reminders.view_details = true
            values.lease_financial.attachments.view_list = true
            values.lease_financial.attachments.view_details = true
            values.lease_financial.notes.view_list = true
            values.lease_financial.notes.view_details = true
          
          
          data.view_details = true
            if (!values.lease_agreements.view_details) {
              if (
                !values.lease_agreements.view_details ||
                !values.lease_agreements.contract_detail.view_details ||
                !values.lease_agreements.tenant_detail.view_details ||
                !values.lease_agreements.view_list
              )
                values.lease_agreements.isRemoved = undefined
              values.lease_agreements.view_details = true
              values.lease_agreements.contract_detail.view_details = true
              values.lease_agreements.tenant_detail.view_details = true
              values.lease_agreements.view_list = true
            }
            values.lease_financial.invoicing.view_details = true
            values.lease_financial.invoicing.view_list = true
            values.lease_financial.receipts.view_list = true
            values.lease_financial.receipts.view_details = true
            values.lease_financial.receipts.view_list = true
            values.lease_financial.refunds.view_details = true
            values.lease_financial.refunds.view_list = true
            values.lease_financial.credit_notes.view_details = true
            values.lease_financial.credit_notes.view_list = true
            values.lease_financial.debit_notes.view_details = true
            values.lease_financial.debit_notes.view_list = true
            values.lease_financial.items.view_details = true
            values.lease_financial.items.view_list = true
            values.lease_financial.vat.view_details = true
            values.lease_financial.vat.view_list = true
            values.lease_financial.late_payment_fees.view_details = true
            values.lease_financial.late_payment_fees.view_list = true
            values.lease_financial.payment_method.view_details = true
            values.lease_financial.payment_method.view_list = true
            values.lease_financial.payment_reminders.view_details = true
            values.lease_financial.payment_reminders.view_list = true
            values.lease_financial.attachments.view_details = true
            values.lease_financial.attachments.view_list = true
            values.lease_financial.notes.view_details = true
            values.lease_financial.notes.view_list = true
          

          data.create = true
            if (!values.lease_agreements.view_details) {
              if (
                !values.lease_agreements.view_details ||
                !values.lease_agreements.contract_detail.view_details ||
                !values.lease_agreements.tenant_detail.view_details ||
                !values.lease_agreements.view_list
              )
                values.lease_agreements.isRemoved = undefined
              values.lease_agreements.view_details = true
              values.lease_agreements.contract_detail.view_details = true
              values.lease_agreements.tenant_detail.view_details = true
              values.lease_agreements.view_list = true
            }
            // sub features
            values.lease_financial.invoicing.update = true
            values.lease_financial.invoicing.view_list = true
            values.lease_financial.invoicing.view_details = true
            values.lease_financial.receipts.view_list = true
            values.lease_financial.receipts.view_details = true
            values.lease_financial.receipts.update = true
            values.lease_financial.refunds.update = true
            values.lease_financial.refunds.view_list = true
            values.lease_financial.refunds.view_details = true
            values.lease_financial.credit_notes.update = true
            values.lease_financial.credit_notes.view_list = true
            values.lease_financial.credit_notes.view_details = true
            values.lease_financial.debit_notes.update = true
            values.lease_financial.debit_notes.view_list = true
            values.lease_financial.debit_notes.view_details = true
            values.lease_financial.items.update = true
            values.lease_financial.items.view_list = true
            values.lease_financial.items.view_details = true
            values.lease_financial.invoicing.create = true
            values.lease_financial.invoicing.update = true
            values.lease_financial.items.delete = true
            values.lease_financial.vat.update = true
            values.lease_financial.vat.view_list = true
            values.lease_financial.vat.view_details = true
            values.lease_financial.vat.delete = true
            values.lease_financial.late_payment_fees.update = true
            values.lease_financial.late_payment_fees.view_list = true
            values.lease_financial.late_payment_fees.view_details = true
            values.lease_financial.late_payment_fees.delete = true
            values.lease_financial.payment_method.update = true
            values.lease_financial.payment_method.view_list = true
            values.lease_financial.payment_method.view_details = true
            values.lease_financial.payment_method.create = true
            values.lease_financial.payment_reminders.update = true
            values.lease_financial.payment_reminders.view_list = true
            values.lease_financial.payment_reminders.view_details = true
            values.lease_financial.payment_reminders.create = true
            values.lease_financial.payment_reminders.delete = true
            values.lease_financial.attachments.update = true
            values.lease_financial.attachments.view_list = true
            values.lease_financial.attachments.view_details = true
            values.lease_financial.attachments.create = true
            values.lease_financial.attachments.delete = true
            values.lease_financial.notes.update = true
            values.lease_financial.notes.view_list = true
            values.lease_financial.notes.view_details = true
            values.lease_financial.notes.create = true
            values.lease_financial.notes.delete = true
  
            values.lease_financial.delete = true
  
            if (!values.lease_agreements.view_details) {
              if (
                !values.lease_agreements.view_details ||
                !values.lease_agreements.contract_detail.view_details ||
                !values.lease_agreements.tenant_detail.view_details ||
                !values.lease_agreements.view_list
              )
                values.lease_agreements.isRemoved = undefined
              values.lease_agreements.view_details = true
              values.lease_agreements.contract_detail.view_details = true
              values.lease_agreements.tenant_detail.view_details = true
              values.lease_agreements.view_list = true
            }
  
            // Sub features
            values.lease_financial.invoicing.create = true
            values.lease_financial.invoicing.view_list = true
            values.lease_financial.invoicing.view_details = true
            values.lease_financial.receipts.view_list = true
            values.lease_financial.receipts.view_details = true
            values.lease_financial.receipts.create = true
            values.lease_financial.refunds.create = true
            values.lease_financial.refunds.view_list = true
            values.lease_financial.refunds.view_details = true
            values.lease_financial.credit_notes.create = true
            values.lease_financial.credit_notes.view_list = true
            values.lease_financial.credit_notes.view_details = true
            values.lease_financial.debit_notes.create = true
            values.lease_financial.debit_notes.view_list = true
            values.lease_financial.debit_notes.view_details = true
            values.lease_financial.items.create = true
            values.lease_financial.items.view_list = true
            values.lease_financial.items.view_details = true
            values.lease_financial.vat.create = true
            values.lease_financial.vat.view_list = true
            values.lease_financial.vat.view_details = true
            values.lease_financial.late_payment_fees.create = true
            values.lease_financial.late_payment_fees.view_list = true
            values.lease_financial.late_payment_fees.view_details = true
            values.lease_financial.payment_method.create = true
            values.lease_financial.payment_method.view_list = true
            values.lease_financial.payment_method.view_details = true
            values.lease_financial.payment_method.update = true
            values.lease_financial.payment_reminders.create = true
            values.lease_financial.payment_reminders.view_list = true
            values.lease_financial.payment_reminders.view_details = true
            values.lease_financial.payment_reminders.update = true
            values.lease_financial.payment_reminders.delete = true
            values.lease_financial.attachments.create = true
            values.lease_financial.attachments.view_list = true
            values.lease_financial.attachments.view_details = true
            values.lease_financial.attachments.update = true
            values.lease_financial.attachments.delete = true
            values.lease_financial.notes.create = true
            values.lease_financial.notes.view_list = true
            values.lease_financial.notes.view_details = true
            values.lease_financial.notes.update = true
            values.lease_financial.notes.delete = true
           
          data.update = true
            if (!values.lease_agreements.view_details) {
              if (
                !values.lease_agreements.view_details ||
                !values.lease_agreements.contract_detail.view_details ||
                !values.lease_agreements.tenant_detail.view_details ||
                !values.lease_agreements.view_list
              )
                values.lease_agreements.isRemoved = undefined
              values.lease_agreements.view_details = true
              values.lease_agreements.contract_detail.view_details = true
              values.lease_agreements.tenant_detail.view_details = true
              values.lease_agreements.view_list = true
            }
            // sub features
            values.lease_financial.invoicing.update = true
            values.lease_financial.invoicing.view_list = true
            values.lease_financial.invoicing.view_details = true
            values.lease_financial.receipts.view_list = true
            values.lease_financial.receipts.view_details = true
            values.lease_financial.receipts.update = true
            values.lease_financial.refunds.update = true
            values.lease_financial.refunds.view_list = true
            values.lease_financial.refunds.view_details = true
            values.lease_financial.credit_notes.update = true
            values.lease_financial.credit_notes.view_list = true
            values.lease_financial.credit_notes.view_details = true
            values.lease_financial.debit_notes.update = true
            values.lease_financial.debit_notes.view_list = true
            values.lease_financial.debit_notes.view_details = true
            values.lease_financial.items.update = true
            values.lease_financial.items.view_list = true
            values.lease_financial.items.view_details = true
            values.lease_financial.invoicing.create = true
            values.lease_financial.invoicing.update = true
            values.lease_financial.items.delete = true
            values.lease_financial.vat.update = true
            values.lease_financial.vat.view_list = true
            values.lease_financial.vat.view_details = true
            values.lease_financial.vat.delete = true
            values.lease_financial.late_payment_fees.update = true
            values.lease_financial.late_payment_fees.view_list = true
            values.lease_financial.late_payment_fees.view_details = true
            values.lease_financial.late_payment_fees.delete = true
            values.lease_financial.payment_method.update = true
            values.lease_financial.payment_method.view_list = true
            values.lease_financial.payment_method.view_details = true
            values.lease_financial.payment_method.create = true
            values.lease_financial.payment_reminders.update = true
            values.lease_financial.payment_reminders.view_list = true
            values.lease_financial.payment_reminders.view_details = true
            values.lease_financial.payment_reminders.create = true
            values.lease_financial.payment_reminders.delete = true
            values.lease_financial.attachments.update = true
            values.lease_financial.attachments.view_list = true
            values.lease_financial.attachments.view_details = true
            values.lease_financial.attachments.create = true
            values.lease_financial.attachments.delete = true
            values.lease_financial.notes.update = true
            values.lease_financial.notes.view_list = true
            values.lease_financial.notes.view_details = true
            values.lease_financial.notes.create = true
            values.lease_financial.notes.delete = true
          
          data.delete = true
            if (!values.lease_agreements.view_details) {
              if (
                !values.lease_agreements.view_details ||
                !values.lease_agreements.contract_detail.view_details ||
                !values.lease_agreements.tenant_detail.view_details ||
                !values.lease_agreements.view_list
              )
                values.lease_agreements.isRemoved = undefined
              values.lease_agreements.view_details = true
              values.lease_agreements.contract_detail.view_details = true
              values.lease_agreements.tenant_detail.view_details = true
              values.lease_agreements.view_list = true
            }
  
            // Sub features
            values.lease_financial.invoicing.delete = true
            values.lease_financial.invoicing.view_list = true
            values.lease_financial.receipts.delete = true
            values.lease_financial.receipts.view_list = true
            values.lease_financial.refunds.delete = true
            values.lease_financial.refunds.view_list = true
            values.lease_financial.credit_notes.delete = true
            values.lease_financial.credit_notes.view_list = true
            values.lease_financial.debit_notes.delete = true
            values.lease_financial.debit_notes.view_list = true
            values.lease_financial.items.delete = true
            values.lease_financial.items.view_list = true
            values.lease_financial.items.update = true
            values.lease_financial.vat.delete = true
            values.lease_financial.vat.view_list = true
            values.lease_financial.vat.update = true
            values.lease_financial.late_payment_fees.delete = true
            values.lease_financial.late_payment_fees.view_list = true
            values.lease_financial.late_payment_fees.update = true
            values.lease_financial.payment_method.delete = true
            values.lease_financial.payment_method.view_list = true
            values.lease_financial.payment_method.view_details = true
            values.lease_financial.payment_method.update = true
            values.lease_financial.payment_method.create = true
            values.lease_financial.payment_reminders.delete = true
            values.lease_financial.payment_reminders.view_list = true
            values.lease_financial.payment_reminders.view_details = true
            values.lease_financial.payment_reminders.update = true
            values.lease_financial.payment_reminders.create = true
            values.lease_financial.attachments.delete = true
            values.lease_financial.attachments.view_list = true
            values.lease_financial.attachments.view_details = true
            values.lease_financial.attachments.update = true
            values.lease_financial.attachments.create = true
            values.lease_financial.notes.delete = true
            values.lease_financial.notes.view_list = true
            values.lease_financial.notes.view_details = true
            values.lease_financial.notes.update = true
            values.lease_financial.notes.create = true
           
          



          if (!values.lease_agreements.view_details) {
            if (
              !values.lease_agreements.view_details ||
              !values.lease_agreements.contract_detail.view_details ||
              !values.lease_agreements.tenant_detail.view_details ||
              !values.lease_agreements.view_list
            )
              values.lease_agreements.isRemoved = undefined
            values.lease_agreements.view_details = true
            values.lease_agreements.contract_detail.view_details = true
            values.lease_agreements.tenant_detail.view_details = true
            values.lease_agreements.view_list = true
          }
        } 
        // uncheck value
        else {
        }
        values.obj = data
        setLeasingResidential(values)
      }

      // Invoicing - View List
      else if (from == 'view_list' && obj == 'invoicing') {
        data.view_list = !data.view_list
        values.lease_financial.isRemoved = undefined
        if (!data.view_list) {
          data.view_details = false
          data.create = false
          data.update = false
          data.delete = false
          values.lease_financial.items.update = false

          if ( 
            !values?.lease_financial?.invoicing?.view_list &&
            !values?.lease_financial?.receipts?.view_list &&
            !values?.lease_financial?.refunds?.view_list &&
            !values?.lease_financial?.credit_notes?.view_list &&
            !values?.lease_financial?.debit_notes?.view_list &&
            !values?.lease_financial?.items?.view_list &&
            !values?.lease_financial?.vat?.view_list &&
            !values?.lease_financial?.late_payment_fees?.view_list &&
            !values?.lease_financial?.payment_method?.view_list &&
            !values?.lease_financial?.payment_reminders?.view_list &&
            !values?.lease_financial?.attachments?.view_list &&
            !values?.lease_financial?.notes?.view_list
          ) {
            values.lease_financial.view_list = false
            values.lease_financial.view_details = false
            values.lease_financial.create = false
            values.lease_financial.update = false
            values.lease_financial.delete = false
            values.lease_financial.approve = false
          }
        } else {
          values.lease_financial.view_list = true
          if (!values.lease_agreements.view_details) {
            if (
              !values.lease_agreements.view_details ||
              !values.lease_agreements.contract_detail.view_details ||
              !values.lease_agreements.tenant_detail.view_details ||
              !values.lease_agreements.view_list
            )
              values.lease_agreements.isRemoved = undefined
            values.lease_agreements.view_details = true
            values.lease_agreements.contract_detail.view_details = true
            values.lease_agreements.tenant_detail.view_details = true
            values.lease_agreements.view_list = true
          }
        }
        values.obj = data
        setLeasingResidential(values)
      }

      // Invoicing - View Details
      else if (from == 'view_details' && obj == 'invoicing') {
        data.view_details = !data.view_details
        values.lease_financial.isRemoved = undefined
        if (data.view_details) {
          data.view_list = true
          values.lease_financial.receipts.view_list = true
          values.lease_financial.receipts.view_details = true
          values.lease_financial.view_details = true
          values.lease_financial.view_list = true
          if (!values.lease_agreements.view_details) {
            if (
              !values.lease_agreements.view_details ||
              !values.lease_agreements.contract_detail.view_details ||
              !values.lease_agreements.tenant_detail.view_details ||
              !values.lease_agreements.view_list
            )
              values.lease_agreements.isRemoved = undefined
            values.lease_agreements.view_details = true
            values.lease_agreements.contract_detail.view_details = true
            values.lease_agreements.tenant_detail.view_details = true
            values.lease_agreements.view_list = true
          }
        } else {
          data.create = false
          data.update = false
          if (values?.lease_financial?.items?.update) {
            data.view_list = false
            data.delete = false
            values.lease_financial.items.update = false
          }
          if (
            !values?.lease_financial?.invoicing?.view_details &&
            !values?.lease_financial?.receipts?.view_details &&
            !values?.lease_financial?.refunds?.view_details &&
            !values?.lease_financial?.credit_notes?.view_details &&
            !values?.lease_financial?.debit_notes?.view_details &&
            !values?.lease_financial?.items?.view_details &&
            !values?.lease_financial?.vat?.view_details &&
            !values?.lease_financial?.late_payment_fees?.view_details &&
            !values?.lease_financial?.payment_method?.view_details &&
            !values?.lease_financial?.payment_reminders?.view_details &&
            !values?.lease_financial?.attachments?.view_details &&
            !values?.lease_financial?.notes?.view_details
          ) {
            values.lease_financial.view_details = false
            values.lease_financial.approve = false
          }
        }
        values.obj = data
        setLeasingResidential(values)
      }

      // Invoicing - Create
      else if (from == 'create' && obj == 'invoicing') {
        data.create = !data.create
        values.lease_financial.isRemoved = undefined
        if (data.create) {
          data.view_list = true
          data.view_details = true
          values.lease_financial.receipts.view_list = true
          values.lease_financial.receipts.view_details = true
          values.lease_financial.create = true
          values.lease_financial.view_details = true
          values.lease_financial.view_list = true
          values.lease_financial.update = true
          if (!values.lease_agreements.view_details) {
            if (
              !values.lease_agreements.view_details ||
              !values.lease_agreements.contract_detail.view_details ||
              !values.lease_agreements.tenant_detail.view_details ||
              !values.lease_agreements.view_list
            )
              values.lease_agreements.isRemoved = undefined
            values.lease_agreements.view_details = true
            values.lease_agreements.contract_detail.view_details = true
            values.lease_agreements.tenant_detail.view_details = true
            values.lease_agreements.view_list = true
          }
        } else {
          if (values?.lease_financial?.items?.update) {
            data.view_list = false
            data.update = false
            data.delete = false
            data.view_details = false
            values.lease_financial.items.update = false
          }

          if (
            !values?.lease_financial?.invoicing?.create &&
            !values?.lease_financial?.receipts?.create &&
            !values?.lease_financial?.refunds?.create &&
            !values?.lease_financial?.credit_notes?.create &&
            !values?.lease_financial?.debit_notes?.create &&
            !values?.lease_financial?.items?.create &&
            !values?.lease_financial?.vat?.create &&
            !values?.lease_financial?.late_payment_fees?.create &&
            !values?.lease_financial?.payment_method?.create &&
            !values?.lease_financial?.payment_reminders?.create &&
            !values?.lease_financial?.attachments?.create &&
            !values?.lease_financial?.notes?.create
          ) {
            values.lease_financial.create = false
            values.lease_financial.approve = false
          }
        }
        values.obj = data
        setLeasingResidential(values)
      }

      // Invoicing - Update
      else if (from == 'update' && obj == 'invoicing') {
        data.update = !data.update
        values.lease_financial.isRemoved = undefined
        if (data.update) {
          data.view_list = true
          data.view_details = true
          values.lease_financial.receipts.view_list = true
          values.lease_financial.receipts.view_details = true
          values.lease_financial.update = true
          values.lease_financial.view_details = true
          values.lease_financial.view_list = true
          if (!values.lease_agreements.view_details) {
            if (
              !values.lease_agreements.view_details ||
              !values.lease_agreements.contract_detail.view_details ||
              !values.lease_agreements.tenant_detail.view_details ||
              !values.lease_agreements.view_list
            )
              values.lease_agreements.isRemoved = undefined
            values.lease_agreements.view_details = true
            values.lease_agreements.contract_detail.view_details = true
            values.lease_agreements.tenant_detail.view_details = true
            values.lease_agreements.view_list = true
          }
        } else {
          if (values?.lease_financial?.items?.update) {
            data.view_list = false
            data.create = false
            data.delete = false
            data.view_details = false
            values.lease_financial.items.update = false
          }
          if (
            !values?.lease_financial?.invoicing?.update &&
            !values?.lease_financial?.receipts?.update &&
            !values?.lease_financial?.refunds?.update &&
            !values?.lease_financial?.credit_notes?.update &&
            !values?.lease_financial?.debit_notes?.update &&
            !values?.lease_financial?.items?.update &&
            !values?.lease_financial?.vat?.update &&
            !values?.lease_financial?.late_payment_fees?.update &&
            !values?.lease_financial?.payment_method?.update &&
            !values?.lease_financial?.payment_reminders?.update &&
            !values?.lease_financial?.attachments?.update &&
            !values?.lease_financial?.notes?.update
          ) {
            values.lease_financial.update = false
            values.lease_financial.approve = false
          }
        }
        values.obj = data
        setLeasingResidential(values)
      }

      // Invoicing - Delete
      else if (from == 'delete' && obj == 'invoicing') {
        data.delete = !data.delete
        values.lease_financial.isRemoved = undefined
        if (data.delete) {
          data.view_list = true
          values.lease_financial.delete = true
          if (!values.lease_agreements.view_details) {
            if (
              !values.lease_agreements.view_details ||
              !values.lease_agreements.contract_detail.view_details ||
              !values.lease_agreements.tenant_detail.view_details ||
              !values.lease_agreements.view_list
            )
              values.lease_agreements.isRemoved = undefined
            values.lease_agreements.view_details = true
            values.lease_agreements.contract_detail.view_details = true
            values.lease_agreements.tenant_detail.view_details = true
            values.lease_agreements.view_list = true
          }
        } else {
          if (
            !values?.lease_financial?.invoicing?.delete &&
            !values?.lease_financial?.receipts?.delete &&
            !values?.lease_financial?.refunds?.delete &&
            !values?.lease_financial?.credit_notes?.delete &&
            !values?.lease_financial?.debit_notes?.delete &&
            !values?.lease_financial?.items?.delete &&
            !values?.lease_financial?.vat?.delete &&
            !values?.lease_financial?.late_payment_fees?.delete &&
            !values?.lease_financial?.payment_method?.delete &&
            !values?.lease_financial?.payment_reminders?.delete &&
            !values?.lease_financial?.attachments?.delete &&
            !values?.lease_financial?.notes?.delete
          ) {
            values.lease_financial.delete = false
            values.lease_financial.approve = false
          }
        }
        values.obj = data
        setLeasingResidential(values)
      }

      // Receipts - View List
      else if (from == 'view_list' && obj == 'receipts') {
        data.view_list = !data.view_list
        values.lease_financial.isRemoved = undefined
        if (!data.view_list) {
          data.view_details = false
          data.create = false
          data.update = false
          data.delete = false
          if (
            !values?.lease_financial?.invoicing?.view_list &&
            !values?.lease_financial?.receipts?.view_list &&
            !values?.lease_financial?.refunds?.view_list &&
            !values?.lease_financial?.credit_notes?.view_list &&
            !values?.lease_financial?.debit_notes?.view_list &&
            !values?.lease_financial?.items?.view_list &&
            !values?.lease_financial?.vat?.view_list &&
            !values?.lease_financial?.late_payment_fees?.view_list &&
            !values?.lease_financial?.payment_method?.view_list &&
            !values?.lease_financial?.payment_reminders?.view_list &&
            !values?.lease_financial?.attachments?.view_list &&
            !values?.lease_financial?.notes?.view_list
          ) {
            values.lease_financial.view_list = false
            values.lease_financial.view_details = false
            values.lease_financial.create = false
            values.lease_financial.update = false
            values.lease_financial.delete = false
            values.lease_financial.approve = false
          }
        } else {
          values.lease_financial.view_list = true
          if (!values.lease_agreements.view_details) {
            if (
              !values.lease_agreements.view_details ||
              !values.lease_agreements.contract_detail.view_details ||
              !values.lease_agreements.tenant_detail.view_details ||
              !values.lease_agreements.view_list
            )
              values.lease_agreements.isRemoved = undefined
            values.lease_agreements.view_details = true
            values.lease_agreements.contract_detail.view_details = true
            values.lease_agreements.tenant_detail.view_details = true
            values.lease_agreements.view_list = true
          }
        }

        values.obj = data
        setLeasingResidential(values)
      }

      // Receipts - View Details
      else if (from == 'view_details' && obj == 'receipts') {
        data.view_details = !data.view_details
        values.lease_financial.isRemoved = undefined
        if (data.view_details) {
          data.view_list = true
          values.lease_financial.invoicing.view_list = true
          values.lease_financial.invoicing.view_details = true
          values.lease_financial.view_details = true
          values.lease_financial.view_list = true
          if (!values.lease_agreements.view_details) {
            if (
              !values.lease_agreements.view_details ||
              !values.lease_agreements.contract_detail.view_details ||
              !values.lease_agreements.tenant_detail.view_details ||
              !values.lease_agreements.view_list
            )
              values.lease_agreements.isRemoved = undefined
            values.lease_agreements.view_details = true
            values.lease_agreements.contract_detail.view_details = true
            values.lease_agreements.tenant_detail.view_details = true
            values.lease_agreements.view_list = true
          }
        } else {
          data.create = false
          data.update = false

          if (
            !values?.lease_financial?.invoicing?.view_details &&
            !values?.lease_financial?.receipts?.view_details &&
            !values?.lease_financial?.refunds?.view_details &&
            !values?.lease_financial?.credit_notes?.view_details &&
            !values?.lease_financial?.debit_notes?.view_details &&
            !values?.lease_financial?.items?.view_details &&
            !values?.lease_financial?.vat?.view_details &&
            !values?.lease_financial?.late_payment_fees?.view_details &&
            !values?.lease_financial?.payment_method?.view_details &&
            !values?.lease_financial?.payment_reminders?.view_details &&
            !values?.lease_financial?.attachments?.view_details &&
            !values?.lease_financial?.notes?.view_details
          ) {
            values.lease_financial.view_details = false
            values.lease_financial.approve = false
          }
        }
        values.obj = data
        setLeasingResidential(values)
      }

      // Receipts - Create
      else if (from == 'create' && obj == 'receipts') {
        data.create = !data.create
        values.lease_financial.isRemoved = undefined
        if (data.create) {
          data.view_list = true
          data.view_details = true
          values.lease_financial.receipts.view_list = true
          values.lease_financial.receipts.view_details = true
          values.lease_financial.create = true
          values.lease_financial.view_details = true
          values.lease_financial.view_list = true
          values.lease_financial.update = true
          if (!values.lease_agreements.view_details) {
            if (
              !values.lease_agreements.view_details ||
              !values.lease_agreements.contract_detail.view_details ||
              !values.lease_agreements.tenant_detail.view_details ||
              !values.lease_agreements.view_list
            )
              values.lease_agreements.isRemoved = undefined
            values.lease_agreements.view_details = true
            values.lease_agreements.contract_detail.view_details = true
            values.lease_agreements.tenant_detail.view_details = true
            values.lease_agreements.view_list = true
          }
        } else {
          if (
            !values?.lease_financial?.invoicing?.create &&
            !values?.lease_financial?.receipts?.create &&
            !values?.lease_financial?.refunds?.create &&
            !values?.lease_financial?.credit_notes?.create &&
            !values?.lease_financial?.debit_notes?.create &&
            !values?.lease_financial?.items?.create &&
            !values?.lease_financial?.vat?.create &&
            !values?.lease_financial?.late_payment_fees?.create &&
            !values?.lease_financial?.payment_method?.create &&
            !values?.lease_financial?.payment_reminders?.create &&
            !values?.lease_financial?.attachments?.create &&
            !values?.lease_financial?.notes?.create
          ) {
            values.lease_financial.create = false
            values.lease_financial.approve = false
          }
        }
        values.obj = data
        setLeasingResidential(values)
      }

      // Receipts - Update
      else if (from == 'update' && obj == 'receipts') {
        data.update = !data.update
        values.lease_financial.isRemoved = undefined
        if (data.update) {
          data.view_list = true
          data.view_details = true
          values.lease_financial.invoicing.view_list = true
          values.lease_financial.invoicing.view_details = true
          values.lease_financial.update = true
          values.lease_financial.view_details = true
          values.lease_financial.view_list = true
          if (!values.lease_agreements.view_details) {
            if (
              !values.lease_agreements.view_details ||
              !values.lease_agreements.contract_detail.view_details ||
              !values.lease_agreements.tenant_detail.view_details ||
              !values.lease_agreements.view_list
            )
              values.lease_agreements.isRemoved = undefined
            values.lease_agreements.view_details = true
            values.lease_agreements.contract_detail.view_details = true
            values.lease_agreements.tenant_detail.view_details = true
            values.lease_agreements.view_list = true
          }
        } else {
          if (
            !values?.lease_financial?.invoicing?.update &&
            !values?.lease_financial?.receipts?.update &&
            !values?.lease_financial?.refunds?.update &&
            !values?.lease_financial?.credit_notes?.update &&
            !values?.lease_financial?.debit_notes?.update &&
            !values?.lease_financial?.items?.update &&
            !values?.lease_financial?.vat?.update &&
            !values?.lease_financial?.late_payment_fees?.update &&
            !values?.lease_financial?.payment_method?.update &&
            !values?.lease_financial?.payment_reminders?.update &&
            !values?.lease_financial?.attachments?.update &&
            !values?.lease_financial?.notes?.update
          ) {
            values.lease_financial.update = false
            values.lease_financial.approve = false
          }
        }
        values.obj = data
        setLeasingResidential(values)
      }

      // Receipts - Delete
      else if (from == 'delete' && obj == 'receipts') {
        data.delete = !data.delete
        values.lease_financial.isRemoved = undefined
        if (data.delete) {
          data.view_list = true
          values.lease_financial.delete = true
          if (!values.lease_agreements.view_details) {
            if (
              !values.lease_agreements.view_details ||
              !values.lease_agreements.contract_detail.view_details ||
              !values.lease_agreements.tenant_detail.view_details ||
              !values.lease_agreements.view_list
            )
              values.lease_agreements.isRemoved = undefined
            values.lease_agreements.view_details = true
            values.lease_agreements.contract_detail.view_details = true
            values.lease_agreements.tenant_detail.view_details = true
            values.lease_agreements.view_list = true
          }
        } else {
          if (
            !values?.lease_financial?.invoicing?.delete &&
            !values?.lease_financial?.receipts?.delete &&
            !values?.lease_financial?.refunds?.delete &&
            !values?.lease_financial?.credit_notes?.delete &&
            !values?.lease_financial?.debit_notes?.delete &&
            !values?.lease_financial?.items?.delete &&
            !values?.lease_financial?.vat?.delete &&
            !values?.lease_financial?.late_payment_fees?.delete &&
            !values?.lease_financial?.payment_method?.delete &&
            !values?.lease_financial?.payment_reminders?.delete &&
            !values?.lease_financial?.attachments?.delete &&
            !values?.lease_financial?.notes?.delete
          ) {
            values.lease_financial.delete = false
            values.lease_financial.approve = false
          }
        }
        values.obj = data
        setLeasingResidential(values)
      }

      // Refunds - View List
      else if (from == 'view_list' && obj == 'refunds') {
        data.view_list = !data.view_list
        values.lease_financial.isRemoved = undefined
        if (!data.view_list) {
          data.view_details = false
          data.create = false
          data.update = false
          data.delete = false
          if (
            !values?.lease_financial?.invoicing?.view_list &&
            !values?.lease_financial?.receipts?.view_list &&
            !values?.lease_financial?.refunds?.view_list &&
            !values?.lease_financial?.credit_notes?.view_list &&
            !values?.lease_financial?.debit_notes?.view_list &&
            !values?.lease_financial?.items?.view_list &&
            !values?.lease_financial?.vat?.view_list &&
            !values?.lease_financial?.late_payment_fees?.view_list &&
            !values?.lease_financial?.payment_method?.view_list &&
            !values?.lease_financial?.payment_reminders?.view_list &&
            !values?.lease_financial?.attachments?.view_list &&
            !values?.lease_financial?.notes?.view_list
          ) {
            values.lease_financial.view_list = false
            values.lease_financial.view_details = false
            values.lease_financial.create = false
            values.lease_financial.update = false
            values.lease_financial.delete = false
            values.lease_financial.approve = false
          }
        } else {
          values.lease_financial.view_list = true
          if (!values.lease_agreements.view_details) {
            if (
              !values.lease_agreements.view_details ||
              !values.lease_agreements.contract_detail.view_details ||
              !values.lease_agreements.tenant_detail.view_details ||
              !values.lease_agreements.view_list
            )
              values.lease_agreements.isRemoved = undefined
            values.lease_agreements.view_details = true
            values.lease_agreements.contract_detail.view_details = true
            values.lease_agreements.tenant_detail.view_details = true
            values.lease_agreements.view_list = true
          }
        }

        values.obj = data
        setLeasingResidential(values)
      }

      // Refunds - View Details
      else if (from == 'view_details' && obj == 'refunds') {
        data.view_details = !data.view_details
        values.lease_financial.isRemoved = undefined
        if (data.view_details) {
          data.view_list = true
          values.lease_financial.invoicing.view_list = true
          values.lease_financial.invoicing.view_details = true
          values.lease_financial.receipts.view_list = true
          values.lease_financial.receipts.view_details = true
          values.lease_financial.view_details = true
          values.lease_financial.view_list = true
          if (!values.lease_agreements.view_details) {
            if (
              !values.lease_agreements.view_details ||
              !values.lease_agreements.contract_detail.view_details ||
              !values.lease_agreements.tenant_detail.view_details ||
              !values.lease_agreements.view_list
            )
              values.lease_agreements.isRemoved = undefined
            values.lease_agreements.view_details = true
            values.lease_agreements.contract_detail.view_details = true
            values.lease_agreements.tenant_detail.view_details = true
            values.lease_agreements.view_list = true
          }
        } else {
          data.create = false
          data.update = false
          if (
            !values?.lease_financial?.invoicing?.view_details &&
            !values?.lease_financial?.receipts?.view_details &&
            !values?.lease_financial?.refunds?.view_details &&
            !values?.lease_financial?.credit_notes?.view_details &&
            !values?.lease_financial?.debit_notes?.view_details &&
            !values?.lease_financial?.items?.view_details &&
            !values?.lease_financial?.vat?.view_details &&
            !values?.lease_financial?.late_payment_fees?.view_details &&
            !values?.lease_financial?.payment_method?.view_details &&
            !values?.lease_financial?.payment_reminders?.view_details &&
            !values?.lease_financial?.attachments?.view_details &&
            !values?.lease_financial?.notes?.view_details
          ) {
            values.lease_financial.view_details = false
            values.lease_financial.approve = false
          }
        }
        values.obj = data
        setLeasingResidential(values)
      }

      // Refunds - Create
      else if (from == 'create' && obj == 'refunds') {
        data.create = !data.create
        values.lease_financial.isRemoved = undefined
        if (data.create) {
          data.view_list = true
          data.view_details = true
          values.lease_financial.invoicing.view_list = true
          values.lease_financial.invoicing.view_details = true
          values.lease_financial.receipts.view_list = true
          values.lease_financial.receipts.view_details = true
          values.lease_financial.create = true
          values.lease_financial.view_details = true
          values.lease_financial.view_list = true
          values.lease_financial.update = true
          if (!values.lease_agreements.view_details) {
            if (
              !values.lease_agreements.view_details ||
              !values.lease_agreements.contract_detail.view_details ||
              !values.lease_agreements.tenant_detail.view_details ||
              !values.lease_agreements.view_list
            )
              values.lease_agreements.isRemoved = undefined
            values.lease_agreements.view_details = true
            values.lease_agreements.contract_detail.view_details = true
            values.lease_agreements.tenant_detail.view_details = true
            values.lease_agreements.view_list = true
          }
        } else {
          if (
            !values?.lease_financial?.invoicing?.create &&
            !values?.lease_financial?.receipts?.create &&
            !values?.lease_financial?.refunds?.create &&
            !values?.lease_financial?.credit_notes?.create &&
            !values?.lease_financial?.debit_notes?.create &&
            !values?.lease_financial?.items?.create &&
            !values?.lease_financial?.vat?.create &&
            !values?.lease_financial?.late_payment_fees?.create &&
            !values?.lease_financial?.payment_method?.create &&
            !values?.lease_financial?.payment_reminders?.create &&
            !values?.lease_financial?.attachments?.create &&
            !values?.lease_financial?.notes?.create
          ) {
            values.lease_financial.create = false
            values.lease_financial.approve = false
          }
        }
        values.obj = data
        setLeasingResidential(values)
      }

      // Refunds - Update
      else if (from == 'update' && obj == 'refunds') {
        data.update = !data.update
        values.lease_financial.isRemoved = undefined
        if (data.update) {
          data.view_list = true
          data.view_details = true
          values.lease_financial.invoicing.view_list = true
          values.lease_financial.invoicing.view_details = true
          values.lease_financial.receipts.view_list = true
          values.lease_financial.receipts.view_details = true
          values.lease_financial.update = true
          values.lease_financial.view_details = true
          values.lease_financial.view_list = true
          if (!values.lease_agreements.view_details) {
            if (
              !values.lease_agreements.view_details ||
              !values.lease_agreements.contract_detail.view_details ||
              !values.lease_agreements.tenant_detail.view_details ||
              !values.lease_agreements.view_list
            )
              values.lease_agreements.isRemoved = undefined
            values.lease_agreements.view_details = true
            values.lease_agreements.contract_detail.view_details = true
            values.lease_agreements.tenant_detail.view_details = true
            values.lease_agreements.view_list = true
          }
        } else {
          if (
            !values?.lease_financial?.invoicing?.update &&
            !values?.lease_financial?.receipts?.update &&
            !values?.lease_financial?.refunds?.update &&
            !values?.lease_financial?.credit_notes?.update &&
            !values?.lease_financial?.debit_notes?.update &&
            !values?.lease_financial?.items?.update &&
            !values?.lease_financial?.vat?.update &&
            !values?.lease_financial?.late_payment_fees?.update &&
            !values?.lease_financial?.payment_method?.update &&
            !values?.lease_financial?.payment_reminders?.update &&
            !values?.lease_financial?.attachments?.update &&
            !values?.lease_financial?.notes?.update
          ) {
            values.lease_financial.update = false
            values.lease_financial.approve = false
          }
        }
        values.obj = data
        setLeasingResidential(values)
      }

      // Refunds - Delete
      else if (from == 'delete' && obj == 'refunds') {
        data.delete = !data.delete
        values.lease_financial.isRemoved = undefined
        if (data.delete) {
          data.view_list = true
          values.lease_financial.delete = true
          if (!values.lease_agreements.view_details) {
            if (
              !values.lease_agreements.view_details ||
              !values.lease_agreements.contract_detail.view_details ||
              !values.lease_agreements.tenant_detail.view_details ||
              !values.lease_agreements.view_list
            )
              values.lease_agreements.isRemoved = undefined
            values.lease_agreements.view_details = true
            values.lease_agreements.contract_detail.view_details = true
            values.lease_agreements.tenant_detail.view_details = true
            values.lease_agreements.view_list = true
          }
        } else {
          if (
            !values?.lease_financial?.invoicing?.delete &&
            !values?.lease_financial?.receipts?.delete &&
            !values?.lease_financial?.refunds?.delete &&
            !values?.lease_financial?.credit_notes?.delete &&
            !values?.lease_financial?.debit_notes?.delete &&
            !values?.lease_financial?.items?.delete &&
            !values?.lease_financial?.vat?.delete &&
            !values?.lease_financial?.late_payment_fees?.delete &&
            !values?.lease_financial?.payment_method?.delete &&
            !values?.lease_financial?.payment_reminders?.delete &&
            !values?.lease_financial?.attachments?.delete &&
            !values?.lease_financial?.notes?.delete
          ) {
            values.lease_financial.delete = false
            values.lease_financial.approve = false
          }
        }
        values.obj = data
        setLeasingResidential(values)
      }

      // Credit Note - View List
      else if (from == 'view_list' && obj == 'credit_notes') {
        data.view_list = !data.view_list
        values.lease_financial.isRemoved = undefined
        if (!data.view_list) {
          data.view_details = false
          data.create = false
          data.update = false
          data.delete = false
          if (
            !values?.lease_financial?.invoicing?.view_list &&
            !values?.lease_financial?.receipts?.view_list &&
            !values?.lease_financial?.refunds?.view_list &&
            !values?.lease_financial?.credit_notes?.view_list &&
            !values?.lease_financial?.debit_notes?.view_list &&
            !values?.lease_financial?.items?.view_list &&
            !values?.lease_financial?.vat?.view_list &&
            !values?.lease_financial?.late_payment_fees?.view_list &&
            !values?.lease_financial?.payment_method?.view_list &&
            !values?.lease_financial?.payment_reminders?.view_list &&
            !values?.lease_financial?.attachments?.view_list &&
            !values?.lease_financial?.notes?.view_list
          ) {
            values.lease_financial.view_list = false
            values.lease_financial.view_details = false
            values.lease_financial.create = false
            values.lease_financial.update = false
            values.lease_financial.delete = false
            values.lease_financial.approve = false
          }
        } else {
          values.lease_financial.view_list = true
          if (!values.lease_agreements.view_details) {
            if (
              !values.lease_agreements.view_details ||
              !values.lease_agreements.contract_detail.view_details ||
              !values.lease_agreements.tenant_detail.view_details ||
              !values.lease_agreements.view_list
            )
              values.lease_agreements.isRemoved = undefined
            values.lease_agreements.view_details = true
            values.lease_agreements.contract_detail.view_details = true
            values.lease_agreements.tenant_detail.view_details = true
            values.lease_agreements.view_list = true
          }
        }

        values.obj = data
        setLeasingResidential(values)
      }

      // Credit Note - View Details
      else if (from == 'view_details' && obj == 'credit_notes') {
        data.view_details = !data.view_details
        values.lease_financial.isRemoved = undefined
        if (data.view_details) {
          data.view_list = true
          values.lease_financial.invoicing.view_list = true
          values.lease_financial.invoicing.view_details = true
          values.lease_financial.view_details = true
          values.lease_financial.view_list = true
          if (!values.lease_agreements.view_details) {
            if (
              !values.lease_agreements.view_details ||
              !values.lease_agreements.contract_detail.view_details ||
              !values.lease_agreements.tenant_detail.view_details ||
              !values.lease_agreements.view_list
            )
              values.lease_agreements.isRemoved = undefined
            values.lease_agreements.view_details = true
            values.lease_agreements.contract_detail.view_details = true
            values.lease_agreements.tenant_detail.view_details = true
            values.lease_agreements.view_list = true
          }
        } else {
          data.create = false
          data.update = false
          if (
            !values?.lease_financial?.invoicing?.view_details &&
            !values?.lease_financial?.receipts?.view_details &&
            !values?.lease_financial?.refunds?.view_details &&
            !values?.lease_financial?.credit_notes?.view_details &&
            !values?.lease_financial?.debit_notes?.view_details &&
            !values?.lease_financial?.items?.view_details &&
            !values?.lease_financial?.vat?.view_details &&
            !values?.lease_financial?.late_payment_fees?.view_details &&
            !values?.lease_financial?.payment_method?.view_details &&
            !values?.lease_financial?.payment_reminders?.view_details &&
            !values?.lease_financial?.attachments?.view_details &&
            !values?.lease_financial?.notes?.view_details
          ) {
            values.lease_financial.view_details = false
            values.lease_financial.approve = false
          }
        }
        values.obj = data
        setLeasingResidential(values)
      }

      // Credit Note - Create
      else if (from == 'create' && obj == 'credit_notes') {
        data.create = !data.create
        values.lease_financial.isRemoved = undefined
        if (data.create) {
          data.view_list = true
          data.view_details = true
          values.lease_financial.invoicing.view_list = true
          values.lease_financial.invoicing.view_details = true
          values.lease_financial.create = true
          values.lease_financial.view_details = true
          values.lease_financial.view_list = true
          values.lease_financial.update = true
          if (!values.lease_agreements.view_details) {
            if (
              !values.lease_agreements.view_details ||
              !values.lease_agreements.contract_detail.view_details ||
              !values.lease_agreements.tenant_detail.view_details ||
              !values.lease_agreements.view_list
            )
              values.lease_agreements.isRemoved = undefined
            values.lease_agreements.view_details = true
            values.lease_agreements.contract_detail.view_details = true
            values.lease_agreements.tenant_detail.view_details = true
            values.lease_agreements.view_list = true
          }
        } else {
          if (
            !values?.lease_financial?.invoicing?.create &&
            !values?.lease_financial?.receipts?.create &&
            !values?.lease_financial?.refunds?.create &&
            !values?.lease_financial?.credit_notes?.create &&
            !values?.lease_financial?.debit_notes?.create &&
            !values?.lease_financial?.items?.create &&
            !values?.lease_financial?.vat?.create &&
            !values?.lease_financial?.late_payment_fees?.create &&
            !values?.lease_financial?.payment_method?.create &&
            !values?.lease_financial?.payment_reminders?.create &&
            !values?.lease_financial?.attachments?.create &&
            !values?.lease_financial?.notes?.create
          ) {
            values.lease_financial.create = false
            values.lease_financial.approve = false
          }
        }
        values.obj = data
        setLeasingResidential(values)
      }

      //Credit Note - Update
      else if (from == 'update' && obj == 'credit_notes') {
        data.update = !data.update
        values.lease_financial.isRemoved = undefined
        if (data.update) {
          data.view_list = true
          data.view_details = true
          values.lease_financial.invoicing.view_list = true
          values.lease_financial.invoicing.view_details = true
          values.lease_financial.update = true
          values.lease_financial.view_details = true
          values.lease_financial.view_list = true
          if (!values.lease_agreements.view_details) {
            if (
              !values.lease_agreements.view_details ||
              !values.lease_agreements.contract_detail.view_details ||
              !values.lease_agreements.tenant_detail.view_details ||
              !values.lease_agreements.view_list
            )
              values.lease_agreements.isRemoved = undefined
            values.lease_agreements.view_details = true
            values.lease_agreements.contract_detail.view_details = true
            values.lease_agreements.tenant_detail.view_details = true
            values.lease_agreements.view_list = true
          }
        } else {
          if (
            !values?.lease_financial?.invoicing?.update &&
            !values?.lease_financial?.receipts?.update &&
            !values?.lease_financial?.refunds?.update &&
            !values?.lease_financial?.credit_notes?.update &&
            !values?.lease_financial?.debit_notes?.update &&
            !values?.lease_financial?.items?.update &&
            !values?.lease_financial?.vat?.update &&
            !values?.lease_financial?.late_payment_fees?.update &&
            !values?.lease_financial?.payment_method?.update &&
            !values?.lease_financial?.payment_reminders?.update &&
            !values?.lease_financial?.attachments?.update &&
            !values?.lease_financial?.notes?.update
          ) {
            values.lease_financial.update = false
            values.lease_financial.approve = false
          }
        }
        values.obj = data
        setLeasingResidential(values)
      }

      // Credit Note - Delete
      else if (from == 'delete' && obj == 'credit_notes') {
        data.delete = !data.delete
        values.lease_financial.isRemoved = undefined
        if (data.delete) {
          data.view_list = true
          values.lease_financial.delete = true
          if (!values.lease_agreements.view_details) {
            if (
              !values.lease_agreements.view_details ||
              !values.lease_agreements.contract_detail.view_details ||
              !values.lease_agreements.tenant_detail.view_details ||
              !values.lease_agreements.view_list
            )
              values.lease_agreements.isRemoved = undefined
            values.lease_agreements.view_details = true
            values.lease_agreements.contract_detail.view_details = true
            values.lease_agreements.tenant_detail.view_details = true
            values.lease_agreements.view_list = true
          }
        } else {
          if (
            !values?.lease_financial?.invoicing?.delete &&
            !values?.lease_financial?.receipts?.delete &&
            !values?.lease_financial?.refunds?.delete &&
            !values?.lease_financial?.credit_notes?.delete &&
            !values?.lease_financial?.debit_notes?.delete &&
            !values?.lease_financial?.items?.delete &&
            !values?.lease_financial?.vat?.delete &&
            !values?.lease_financial?.late_payment_fees?.delete &&
            !values?.lease_financial?.payment_method?.delete &&
            !values?.lease_financial?.payment_reminders?.delete &&
            !values?.lease_financial?.attachments?.delete &&
            !values?.lease_financial?.notes?.delete
          ) {
            values.lease_financial.delete = false
            values.lease_financial.approve = false
          }
        }
        values.obj = data
        setLeasingResidential(values)
      }

      // Debit Note - View List
      else if (from == 'view_list' && obj == 'debit_notes') {
        data.view_list = !data.view_list
        values.lease_financial.isRemoved = undefined
        if (!data.view_list) {
          data.view_details = false
          data.create = false
          data.update = false
          data.delete = false
          if (
            !values?.lease_financial?.invoicing?.view_list &&
            !values?.lease_financial?.receipts?.view_list &&
            !values?.lease_financial?.refunds?.view_list &&
            !values?.lease_financial?.credit_notes?.view_list &&
            !values?.lease_financial?.debit_notes?.view_list &&
            !values?.lease_financial?.items?.view_list &&
            !values?.lease_financial?.vat?.view_list &&
            !values?.lease_financial?.late_payment_fees?.view_list &&
            !values?.lease_financial?.payment_method?.view_list &&
            !values?.lease_financial?.payment_reminders?.view_list &&
            !values?.lease_financial?.attachments?.view_list &&
            !values?.lease_financial?.notes?.view_list
          ) {
            values.lease_financial.view_list = false
            values.lease_financial.view_details = false
            values.lease_financial.create = false
            values.lease_financial.update = false
            values.lease_financial.delete = false
            values.lease_financial.approve = false
          }
        } else {
          values.lease_financial.view_list = true
          if (!values.lease_agreements.view_details) {
            if (
              !values.lease_agreements.view_details ||
              !values.lease_agreements.contract_detail.view_details ||
              !values.lease_agreements.tenant_detail.view_details ||
              !values.lease_agreements.view_list
            )
              values.lease_agreements.isRemoved = undefined
            values.lease_agreements.view_details = true
            values.lease_agreements.contract_detail.view_details = true
            values.lease_agreements.tenant_detail.view_details = true
            values.lease_agreements.view_list = true
          }
        }

        values.obj = data
        setLeasingResidential(values)
      }

      // Debit Note - View Details
      else if (from == 'view_details' && obj == 'debit_notes') {
        data.view_details = !data.view_details
        values.lease_financial.isRemoved = undefined
        if (data.view_details) {
          data.view_list = true
          values.lease_financial.invoicing.view_list = true
          values.lease_financial.invoicing.view_details = true
          values.lease_financial.view_details = true
          values.lease_financial.view_list = true
          if (!values.lease_agreements.view_details) {
            if (
              !values.lease_agreements.view_details ||
              !values.lease_agreements.contract_detail.view_details ||
              !values.lease_agreements.tenant_detail.view_details ||
              !values.lease_agreements.view_list
            )
              values.lease_agreements.isRemoved = undefined
            values.lease_agreements.view_details = true
            values.lease_agreements.contract_detail.view_details = true
            values.lease_agreements.tenant_detail.view_details = true
            values.lease_agreements.view_list = true
          }
        } else {
          data.create = false
          data.update = false
          if (
            !values?.lease_financial?.invoicing?.view_details &&
            !values?.lease_financial?.receipts?.view_details &&
            !values?.lease_financial?.refunds?.view_details &&
            !values?.lease_financial?.credit_notes?.view_details &&
            !values?.lease_financial?.debit_notes?.view_details &&
            !values?.lease_financial?.items?.view_details &&
            !values?.lease_financial?.vat?.view_details &&
            !values?.lease_financial?.late_payment_fees?.view_details &&
            !values?.lease_financial?.payment_method?.view_details &&
            !values?.lease_financial?.payment_reminders?.view_details &&
            !values?.lease_financial?.attachments?.view_details &&
            !values?.lease_financial?.notes?.view_details
          ) {
            values.lease_financial.view_details = false
            values.lease_financial.approve = false
          }
        }
        values.obj = data
        setLeasingResidential(values)
      }

      // Debit Note - Create
      else if (from == 'create' && obj == 'debit_notes') {
        data.create = !data.create
        values.lease_financial.isRemoved = undefined
        if (data.create) {
          data.view_list = true
          data.view_details = true
          values.lease_financial.invoicing.view_list = true
          values.lease_financial.invoicing.view_details = true
          values.lease_financial.create = true
          values.lease_financial.view_details = true
          values.lease_financial.view_list = true
          values.lease_financial.update = true
          if (!values.lease_agreements.view_details) {
            if (
              !values.lease_agreements.view_details ||
              !values.lease_agreements.contract_detail.view_details ||
              !values.lease_agreements.tenant_detail.view_details ||
              !values.lease_agreements.view_list
            )
              values.lease_agreements.isRemoved = undefined
            values.lease_agreements.view_details = true
            values.lease_agreements.contract_detail.view_details = true
            values.lease_agreements.tenant_detail.view_details = true
            values.lease_agreements.view_list = true
          }
        } else {
          if (
            !values?.lease_financial?.invoicing?.create &&
            !values?.lease_financial?.receipts?.create &&
            !values?.lease_financial?.refunds?.create &&
            !values?.lease_financial?.credit_notes?.create &&
            !values?.lease_financial?.debit_notes?.create &&
            !values?.lease_financial?.items?.create &&
            !values?.lease_financial?.vat?.create &&
            !values?.lease_financial?.late_payment_fees?.create &&
            !values?.lease_financial?.payment_method?.create &&
            !values?.lease_financial?.payment_reminders?.create &&
            !values?.lease_financial?.attachments?.create &&
            !values?.lease_financial?.notes?.create
          ) {
            values.lease_financial.create = false
            values.lease_financial.approve = false
          }
        }
        values.obj = data
        setLeasingResidential(values)
      }

      // Debit Note - Update
      else if (from == 'update' && obj == 'debit_notes') {
        data.update = !data.update
        values.lease_financial.isRemoved = undefined
        if (data.update) {
          data.view_list = true
          data.view_details = true
          values.lease_financial.invoicing.view_list = true
          values.lease_financial.invoicing.view_details = true
          values.lease_financial.update = true
          values.lease_financial.view_details = true
          values.lease_financial.view_list = true
          if (!values.lease_agreements.view_details) {
            if (
              !values.lease_agreements.view_details ||
              !values.lease_agreements.contract_detail.view_details ||
              !values.lease_agreements.tenant_detail.view_details ||
              !values.lease_agreements.view_list
            )
              values.lease_agreements.isRemoved = undefined
            values.lease_agreements.view_details = true
            values.lease_agreements.contract_detail.view_details = true
            values.lease_agreements.tenant_detail.view_details = true
            values.lease_agreements.view_list = true
          }
        } else {
          if (
            !values?.lease_financial?.invoicing?.update &&
            !values?.lease_financial?.receipts?.update &&
            !values?.lease_financial?.refunds?.update &&
            !values?.lease_financial?.credit_notes?.update &&
            !values?.lease_financial?.debit_notes?.update &&
            !values?.lease_financial?.items?.update &&
            !values?.lease_financial?.vat?.update &&
            !values?.lease_financial?.late_payment_fees?.update &&
            !values?.lease_financial?.payment_method?.update &&
            !values?.lease_financial?.payment_reminders?.update &&
            !values?.lease_financial?.attachments?.update &&
            !values?.lease_financial?.notes?.update
          ) {
            values.lease_financial.update = false
            values.lease_financial.approve = false
          }
        }
        values.obj = data
        setLeasingResidential(values)
      }

      // Debit Note - Delete
      else if (from == 'delete' && obj == 'debit_notes') {
        data.delete = !data.delete
        values.lease_financial.isRemoved = undefined
        if (data.delete) {
          data.view_list = true
          values.lease_financial.delete = true
          if (!values.lease_agreements.view_details) {
            if (
              !values.lease_agreements.view_details ||
              !values.lease_agreements.contract_detail.view_details ||
              !values.lease_agreements.tenant_detail.view_details ||
              !values.lease_agreements.view_list
            )
              values.lease_agreements.isRemoved = undefined
            values.lease_agreements.view_details = true
            values.lease_agreements.contract_detail.view_details = true
            values.lease_agreements.tenant_detail.view_details = true
            values.lease_agreements.view_list = true
          }
        } else {
          if (
            !values?.lease_financial?.invoicing?.delete &&
            !values?.lease_financial?.receipts?.delete &&
            !values?.lease_financial?.refunds?.delete &&
            !values?.lease_financial?.credit_notes?.delete &&
            !values?.lease_financial?.debit_notes?.delete &&
            !values?.lease_financial?.items?.delete &&
            !values?.lease_financial?.vat?.delete &&
            !values?.lease_financial?.late_payment_fees?.delete &&
            !values?.lease_financial?.payment_method?.delete &&
            !values?.lease_financial?.payment_reminders?.delete &&
            !values?.lease_financial?.attachments?.delete &&
            !values?.lease_financial?.notes?.delete
          ) {
            values.lease_financial.delete = false
            values.lease_financial.approve = false
          }
        }
        values.obj = data
        setLeasingResidential(values)
      }

      // Items - View List
      else if (from == 'view_list' && obj == 'items') {
        data.view_list = !data.view_list
        values.lease_financial.isRemoved = undefined
        if (!data.view_list) {
          data.view_details = false
          data.create = false
          data.update = false
          data.delete = false
          values.lease_financial.vat.view_list = false
          values.lease_financial.late_payment_fees.view_list = false
          if (
            !values?.lease_financial?.invoicing?.view_list &&
            !values?.lease_financial?.receipts?.view_list &&
            !values?.lease_financial?.refunds?.view_list &&
            !values?.lease_financial?.credit_notes?.view_list &&
            !values?.lease_financial?.debit_notes?.view_list &&
            !values?.lease_financial?.items?.view_list &&
            !values?.lease_financial?.vat?.view_list &&
            !values?.lease_financial?.late_payment_fees?.view_list &&
            !values?.lease_financial?.payment_method?.view_list &&
            !values?.lease_financial?.payment_reminders?.view_list &&
            !values?.lease_financial?.attachments?.view_list &&
            !values?.lease_financial?.notes?.view_list
          ) {
            values.lease_financial.view_list = false
            values.lease_financial.view_details = false
            values.lease_financial.create = false
            values.lease_financial.update = false
            values.lease_financial.delete = false
            values.lease_financial.approve = false
          }
        } else {
          data.view_details = true
          values.lease_financial.vat.view_list = true
          values.lease_financial.late_payment_fees.view_list = true
          values.lease_financial.view_list = true
          if (!values.lease_agreements.view_details) {
            if (
              !values.lease_agreements.view_details ||
              !values.lease_agreements.contract_detail.view_details ||
              !values.lease_agreements.tenant_detail.view_details ||
              !values.lease_agreements.view_list
            )
              values.lease_agreements.isRemoved = undefined
            values.lease_agreements.view_details = true
            values.lease_agreements.contract_detail.view_details = true
            values.lease_agreements.tenant_detail.view_details = true
            values.lease_agreements.view_list = true
          }
        }

        values.obj = data
        setLeasingResidential(values)
      }

      //Items - View Details
      else if (from == 'view_details' && obj == 'items') {
        data.view_details = !data.view_details
        values.lease_financial.isRemoved = undefined
        if (data.view_details) {
          data.view_list = true
          values.lease_financial.vat.view_list = true
          values.lease_financial.vat.view_details = true
          values.lease_financial.late_payment_fees.view_list = true
          values.lease_financial.late_payment_fees.view_details = true
          values.lease_financial.invoicing.view_list = true
          values.lease_financial.invoicing.view_details = true
          values.lease_financial.view_details = true
          values.lease_financial.view_list = true
          if (!values.lease_agreements.view_details) {
            if (
              !values.lease_agreements.view_details ||
              !values.lease_agreements.contract_detail.view_details ||
              !values.lease_agreements.tenant_detail.view_details ||
              !values.lease_agreements.view_list
            )
              values.lease_agreements.isRemoved = undefined
            values.lease_agreements.view_details = true
            values.lease_agreements.contract_detail.view_details = true
            values.lease_agreements.tenant_detail.view_details = true
            values.lease_agreements.view_list = true
          }
        } else {
          data.create = false
          data.update = false
          values.lease_financial.vat.view_list = false
          values.lease_financial.vat.view_details = false
          values.lease_financial.late_payment_fees.view_list = false
          values.lease_financial.late_payment_fees.view_details = false
          if (
            !values?.lease_financial?.invoicing?.view_details &&
            !values?.lease_financial?.receipts?.view_details &&
            !values?.lease_financial?.refunds?.view_details &&
            !values?.lease_financial?.credit_notes?.view_details &&
            !values?.lease_financial?.debit_notes?.view_details &&
            !values?.lease_financial?.items?.view_details &&
            !values?.lease_financial?.vat?.view_details &&
            !values?.lease_financial?.late_payment_fees?.view_details &&
            !values?.lease_financial?.payment_method?.view_details &&
            !values?.lease_financial?.payment_reminders?.view_details &&
            !values?.lease_financial?.attachments?.view_details &&
            !values?.lease_financial?.notes?.view_details
          ) {
            values.lease_financial.view_details = false
            values.lease_financial.approve = false
          }
        }
        values.obj = data
        setLeasingResidential(values)
      }

      // Items - Create
      else if (from == 'create' && obj == 'items') {
        data.create = !data.create
        values.lease_financial.isRemoved = undefined
        if (data.create) {
          data.view_list = true
          data.view_details = true
          values.lease_financial.invoicing.view_list = true
          values.lease_financial.invoicing.view_details = true
          values.lease_financial.create = true
          values.lease_financial.view_details = true
          values.lease_financial.view_list = true
          values.lease_financial.update = true
          if (!values.lease_agreements.view_details) {
            if (
              !values.lease_agreements.view_details ||
              !values.lease_agreements.contract_detail.view_details ||
              !values.lease_agreements.tenant_detail.view_details ||
              !values.lease_agreements.view_list
            )
              values.lease_agreements.isRemoved = undefined
            values.lease_agreements.view_details = true
            values.lease_agreements.contract_detail.view_details = true
            values.lease_agreements.tenant_detail.view_details = true
            values.lease_agreements.view_list = true
          }
        } else {
          if (
            !values?.lease_financial?.invoicing?.create &&
            !values?.lease_financial?.receipts?.create &&
            !values?.lease_financial?.refunds?.create &&
            !values?.lease_financial?.credit_notes?.create &&
            !values?.lease_financial?.debit_notes?.create &&
            !values?.lease_financial?.items?.create &&
            !values?.lease_financial?.vat?.create &&
            !values?.lease_financial?.late_payment_fees?.create &&
            !values?.lease_financial?.payment_method?.create &&
            !values?.lease_financial?.payment_reminders?.create &&
            !values?.lease_financial?.attachments?.create &&
            !values?.lease_financial?.notes?.create
          ) {
            values.lease_financial.create = false
            values.lease_financial.approve = false
          }
        }
        values.obj = data
        setLeasingResidential(values)
      }

      // Items - Update
      else if (from == 'update' && obj == 'items') {
        data.update = !data.update
        values.lease_financial.isRemoved = undefined
        if (data.update) {
          data.view_list = true
          data.view_details = true
          values.lease_financial.invoicing.view_list = true
          values.lease_financial.invoicing.view_details = true
          values.lease_financial.invoicing.create = true
          values.lease_financial.invoicing.update = true
          data.delete = true
          values.lease_financial.update = true
          values.lease_financial.view_details = true
          values.lease_financial.view_list = true
          if (!values.lease_agreements.view_details) {
            if (
              !values.lease_agreements.view_details ||
              !values.lease_agreements.contract_detail.view_details ||
              !values.lease_agreements.tenant_detail.view_details ||
              !values.lease_agreements.view_list
            )
              values.lease_agreements.isRemoved = undefined
            values.lease_agreements.view_details = true
            values.lease_agreements.contract_detail.view_details = true
            values.lease_agreements.tenant_detail.view_details = true
            values.lease_agreements.view_list = true
          }
        } else {
          data.delete = false
          if (
            !values?.lease_financial?.invoicing?.update &&
            !values?.lease_financial?.receipts?.update &&
            !values?.lease_financial?.refunds?.update &&
            !values?.lease_financial?.credit_notes?.update &&
            !values?.lease_financial?.debit_notes?.update &&
            !values?.lease_financial?.items?.update &&
            !values?.lease_financial?.vat?.update &&
            !values?.lease_financial?.late_payment_fees?.update &&
            !values?.lease_financial?.payment_method?.update &&
            !values?.lease_financial?.payment_reminders?.update &&
            !values?.lease_financial?.attachments?.update &&
            !values?.lease_financial?.notes?.update
          ) {
            values.lease_financial.update = false
            values.lease_financial.approve = false
          }
        }
        values.obj = data
        setLeasingResidential(values)
      }

      // Items - Delete
      else if (from == 'delete' && obj == 'items') {
        data.delete = !data.delete
        values.lease_financial.isRemoved = undefined
        if (data.delete) {
          data.view_list = true
          data.update = true
          values.lease_financial.delete = true
          if (!values.lease_agreements.view_details) {
            if (
              !values.lease_agreements.view_details ||
              !values.lease_agreements.contract_detail.view_details ||
              !values.lease_agreements.tenant_detail.view_details ||
              !values.lease_agreements.view_list
            )
              values.lease_agreements.isRemoved = undefined
            values.lease_agreements.view_details = true
            values.lease_agreements.contract_detail.view_details = true
            values.lease_agreements.tenant_detail.view_details = true
            values.lease_agreements.view_list = true
          }
        } else {
          data.update = false

          if (
            !values?.lease_financial?.invoicing?.delete &&
            !values?.lease_financial?.receipts?.delete &&
            !values?.lease_financial?.refunds?.delete &&
            !values?.lease_financial?.credit_notes?.delete &&
            !values?.lease_financial?.debit_notes?.delete &&
            !values?.lease_financial?.items?.delete &&
            !values?.lease_financial?.vat?.delete &&
            !values?.lease_financial?.late_payment_fees?.delete &&
            !values?.lease_financial?.payment_method?.delete &&
            !values?.lease_financial?.payment_reminders?.delete &&
            !values?.lease_financial?.attachments?.delete &&
            !values?.lease_financial?.notes?.delete
          ) {
            values.lease_financial.delete = false
            values.lease_financial.approve = false
          }
        }
        values.obj = data
        setLeasingResidential(values)
      }

      // VAT - View List
      else if (from == 'view_list' && obj == 'vat') {
        data.view_list = !data.view_list
        values.lease_financial.isRemoved = undefined
        if (!data.view_list) {
          data.view_details = false
          data.create = false
          data.update = false
          data.delete = false
          values.lease_financial.items.view_list = false
          if (
            !values?.lease_financial?.invoicing?.view_list &&
            !values?.lease_financial?.receipts?.view_list &&
            !values?.lease_financial?.refunds?.view_list &&
            !values?.lease_financial?.credit_notes?.view_list &&
            !values?.lease_financial?.debit_notes?.view_list &&
            !values?.lease_financial?.items?.view_list &&
            !values?.lease_financial?.vat?.view_list &&
            !values?.lease_financial?.late_payment_fees?.view_list &&
            !values?.lease_financial?.payment_method?.view_list &&
            !values?.lease_financial?.payment_reminders?.view_list &&
            !values?.lease_financial?.attachments?.view_list &&
            !values?.lease_financial?.notes?.view_list
          ) {
            values.lease_financial.view_list = false
            values.lease_financial.view_details = false
            values.lease_financial.create = false
            values.lease_financial.update = false
            values.lease_financial.delete = false
            values.lease_financial.approve = false
          }
        } else {
          data.view_details = true
          values.lease_financial.items.view_list = true
          values.lease_financial.view_list = true
          if (!values.lease_agreements.view_details) {
            if (
              !values.lease_agreements.view_details ||
              !values.lease_agreements.contract_detail.view_details ||
              !values.lease_agreements.tenant_detail.view_details ||
              !values.lease_agreements.view_list
            )
              values.lease_agreements.isRemoved = undefined
            values.lease_agreements.view_details = true
            values.lease_agreements.contract_detail.view_details = true
            values.lease_agreements.tenant_detail.view_details = true
            values.lease_agreements.view_list = true
          }
        }

        values.obj = data
        setLeasingResidential(values)
      }

      // VAT  - View Details
      else if (from == 'view_details' && obj == 'vat') {
        data.view_details = !data.view_details
        values.lease_financial.isRemoved = undefined
        if (data.view_details) {
          data.view_list = true
          values.lease_financial.items.view_list = true
          values.lease_financial.items.view_details = true
          values.lease_financial.late_payment_fees.view_list = true
          values.lease_financial.late_payment_fees.view_details = true
          values.lease_financial.invoicing.view_list = true
          values.lease_financial.invoicing.view_details = true
          values.lease_financial.view_details = true
          values.lease_financial.view_list = true
          if (!values.lease_agreements.view_details) {
            if (
              !values.lease_agreements.view_details ||
              !values.lease_agreements.contract_detail.view_details ||
              !values.lease_agreements.tenant_detail.view_details ||
              !values.lease_agreements.view_list
            )
              values.lease_agreements.isRemoved = undefined
            values.lease_agreements.view_details = true
            values.lease_agreements.contract_detail.view_details = true
            values.lease_agreements.tenant_detail.view_details = true
            values.lease_agreements.view_list = true
          }
        } else {
          data.view_list = false
          data.create = false
          data.update = false
          values.lease_financial.items.view_list = false
          values.lease_financial.items.view_details = false
          values.lease_financial.late_payment_fees.view_list = false
          values.lease_financial.late_payment_fees.view_details = false
          if (
            !values?.lease_financial?.invoicing?.view_details &&
            !values?.lease_financial?.receipts?.view_details &&
            !values?.lease_financial?.refunds?.view_details &&
            !values?.lease_financial?.credit_notes?.view_details &&
            !values?.lease_financial?.debit_notes?.view_details &&
            !values?.lease_financial?.items?.view_details &&
            !values?.lease_financial?.vat?.view_details &&
            !values?.lease_financial?.late_payment_fees?.view_details &&
            !values?.lease_financial?.payment_method?.view_details &&
            !values?.lease_financial?.payment_reminders?.view_details &&
            !values?.lease_financial?.attachments?.view_details &&
            !values?.lease_financial?.notes?.view_details
          ) {
            values.lease_financial.view_details = false
            values.lease_financial.approve = false
          }
        }
        values.obj = data
        setLeasingResidential(values)
      }

      //  VAT  - Create
      else if (from == 'create' && obj == 'vat') {
        data.create = !data.create
        values.lease_financial.isRemoved = undefined
        if (data.create) {
          data.view_list = true
          data.view_details = true
          values.lease_financial.create = true
          values.lease_financial.view_details = true
          values.lease_financial.view_list = true
          values.lease_financial.update = true
          if (!values.lease_agreements.view_details) {
            if (
              !values.lease_agreements.view_details ||
              !values.lease_agreements.contract_detail.view_details ||
              !values.lease_agreements.tenant_detail.view_details ||
              !values.lease_agreements.view_list
            )
              values.lease_agreements.isRemoved = undefined
            values.lease_agreements.view_details = true
            values.lease_agreements.contract_detail.view_details = true
            values.lease_agreements.tenant_detail.view_details = true
            values.lease_agreements.view_list = true
          }
        } else {
          if (
            !values?.lease_financial?.invoicing?.create &&
            !values?.lease_financial?.receipts?.create &&
            !values?.lease_financial?.refunds?.create &&
            !values?.lease_financial?.credit_notes?.create &&
            !values?.lease_financial?.debit_notes?.create &&
            !values?.lease_financial?.items?.create &&
            !values?.lease_financial?.vat?.create &&
            !values?.lease_financial?.late_payment_fees?.create &&
            !values?.lease_financial?.payment_method?.create &&
            !values?.lease_financial?.payment_reminders?.create &&
            !values?.lease_financial?.attachments?.create &&
            !values?.lease_financial?.notes?.create
          ) {
            values.lease_financial.create = false
            values.lease_financial.approve = false
          }
        }
        values.obj = data
        setLeasingResidential(values)
      }

      //  VAT  - Update
      else if (from == 'update' && obj == 'vat') {
        data.update = !data.update
        values.lease_financial.isRemoved = undefined
        if (data.update) {
          data.view_list = true
          data.view_details = true
          values.lease_financial.invoicing.view_list = true
          values.lease_financial.invoicing.view_details = true
          values.lease_financial.invoicing.create = true
          values.lease_financial.invoicing.update = true
          data.delete = true
          values.lease_financial.update = true
          values.lease_financial.view_details = true
          values.lease_financial.view_list = true
          if (!values.lease_agreements.view_details) {
            if (
              !values.lease_agreements.view_details ||
              !values.lease_agreements.contract_detail.view_details ||
              !values.lease_agreements.tenant_detail.view_details ||
              !values.lease_agreements.view_list
            )
              values.lease_agreements.isRemoved = undefined
            values.lease_agreements.view_details = true
            values.lease_agreements.contract_detail.view_details = true
            values.lease_agreements.tenant_detail.view_details = true
            values.lease_agreements.view_list = true
          }
        } else {
          data.delete = false
          if (
            !values?.lease_financial?.invoicing?.update &&
            !values?.lease_financial?.receipts?.update &&
            !values?.lease_financial?.refunds?.update &&
            !values?.lease_financial?.credit_notes?.update &&
            !values?.lease_financial?.debit_notes?.update &&
            !values?.lease_financial?.items?.update &&
            !values?.lease_financial?.vat?.update &&
            !values?.lease_financial?.late_payment_fees?.update &&
            !values?.lease_financial?.payment_method?.update &&
            !values?.lease_financial?.payment_reminders?.update &&
            !values?.lease_financial?.attachments?.update &&
            !values?.lease_financial?.notes?.update
          ) {
            values.lease_financial.update = false
            values.lease_financial.approve = false
          }
        }
        values.obj = data
        setLeasingResidential(values)
      }

      //  VAT  - Delete
      else if (from == 'delete' && obj == 'vat') {
        data.delete = !data.delete
        values.lease_financial.isRemoved = undefined
        if (data.delete) {
          data.view_list = true
          data.update = true

          values.lease_financial.delete = true
          if (!values.lease_agreements.view_details) {
            if (
              !values.lease_agreements.view_details ||
              !values.lease_agreements.contract_detail.view_details ||
              !values.lease_agreements.tenant_detail.view_details ||
              !values.lease_agreements.view_list
            )
              values.lease_agreements.isRemoved = undefined
            values.lease_agreements.view_details = true
            values.lease_agreements.contract_detail.view_details = true
            values.lease_agreements.tenant_detail.view_details = true
            values.lease_agreements.view_list = true
          }
        } else {
          data.update = false

          if (
            !values?.lease_financial?.invoicing?.delete &&
            !values?.lease_financial?.receipts?.delete &&
            !values?.lease_financial?.refunds?.delete &&
            !values?.lease_financial?.credit_notes?.delete &&
            !values?.lease_financial?.debit_notes?.delete &&
            !values?.lease_financial?.items?.delete &&
            !values?.lease_financial?.vat?.delete &&
            !values?.lease_financial?.late_payment_fees?.delete &&
            !values?.lease_financial?.payment_method?.delete &&
            !values?.lease_financial?.payment_reminders?.delete &&
            !values?.lease_financial?.attachments?.delete &&
            !values?.lease_financial?.notes?.delete
          ) {
            values.lease_financial.delete = false
            values.lease_financial.approve = false
          }
        }
        values.obj = data
        setLeasingResidential(values)
      }

      // Late Payment Fees - View List
      else if (from == 'view_list' && obj == 'late_payment_fees') {
        data.view_list = !data.view_list
        values.lease_financial.isRemoved = undefined
        if (!data.view_list) {
          data.view_details = false
          data.create = false
          data.update = false
          data.delete = false
          values.lease_financial.items.view_list = false
          if (
            !values?.lease_financial?.invoicing?.view_list &&
            !values?.lease_financial?.receipts?.view_list &&
            !values?.lease_financial?.refunds?.view_list &&
            !values?.lease_financial?.credit_notes?.view_list &&
            !values?.lease_financial?.debit_notes?.view_list &&
            !values?.lease_financial?.items?.view_list &&
            !values?.lease_financial?.vat?.view_list &&
            !values?.lease_financial?.late_payment_fees?.view_list &&
            !values?.lease_financial?.payment_method?.view_list &&
            !values?.lease_financial?.payment_reminders?.view_list &&
            !values?.lease_financial?.attachments?.view_list &&
            !values?.lease_financial?.notes?.view_list
          ) {
            values.lease_financial.view_list = false
            values.lease_financial.view_details = false
            values.lease_financial.create = false
            values.lease_financial.update = false
            values.lease_financial.delete = false
            values.lease_financial.approve = false
          }
        } else {
          data.view_details = true
          values.lease_financial.items.view_list = true
          values.lease_financial.view_list = true
          if (!values.lease_agreements.view_details) {
            if (
              !values.lease_agreements.view_details ||
              !values.lease_agreements.contract_detail.view_details ||
              !values.lease_agreements.tenant_detail.view_details ||
              !values.lease_agreements.view_list
            )
              values.lease_agreements.isRemoved = undefined
            values.lease_agreements.view_details = true
            values.lease_agreements.contract_detail.view_details = true
            values.lease_agreements.tenant_detail.view_details = true
            values.lease_agreements.view_list = true
          }
        }

        values.obj = data
        setLeasingResidential(values)
      }

      // Late Payment Fees  - View Details
      else if (from == 'view_details' && obj == 'late_payment_fees') {
        data.view_details = !data.view_details
        values.lease_financial.isRemoved = undefined
        if (data.view_details) {
          data.view_list = true
          values.lease_financial.items.view_list = true
          values.lease_financial.items.view_details = true
          values.lease_financial.vat.view_list = true
          values.lease_financial.vat.view_details = true
          values.lease_financial.invoicing.view_list = true
          values.lease_financial.invoicing.view_details = true
          values.lease_financial.view_details = true
          values.lease_financial.view_list = true
          if (!values.lease_agreements.view_details) {
            if (
              !values.lease_agreements.view_details ||
              !values.lease_agreements.contract_detail.view_details ||
              !values.lease_agreements.tenant_detail.view_details ||
              !values.lease_agreements.view_list
            )
              values.lease_agreements.isRemoved = undefined
            values.lease_agreements.view_details = true
            values.lease_agreements.contract_detail.view_details = true
            values.lease_agreements.tenant_detail.view_details = true
            values.lease_agreements.view_list = true
          }
        } else {
          data.view_list = false
          data.create = false
          data.update = false
          values.lease_financial.items.view_list = false
          values.lease_financial.items.view_details = false
          values.lease_financial.vat.view_list = false
          values.lease_financial.vat.view_details = false
          if (
            !values?.lease_financial?.invoicing?.view_details &&
            !values?.lease_financial?.receipts?.view_details &&
            !values?.lease_financial?.refunds?.view_details &&
            !values?.lease_financial?.credit_notes?.view_details &&
            !values?.lease_financial?.debit_notes?.view_details &&
            !values?.lease_financial?.items?.view_details &&
            !values?.lease_financial?.vat?.view_details &&
            !values?.lease_financial?.late_payment_fees?.view_details &&
            !values?.lease_financial?.payment_method?.view_details &&
            !values?.lease_financial?.payment_reminders?.view_details &&
            !values?.lease_financial?.attachments?.view_details &&
            !values?.lease_financial?.notes?.view_details
          ) {
            values.lease_financial.view_details = false
            values.lease_financial.approve = false
          }
        }
        values.obj = data
        setLeasingResidential(values)
      }

      //  Late Payment Fees  - Create
      else if (from == 'create' && obj == 'late_payment_fees') {
        data.create = !data.create
        values.lease_financial.isRemoved = undefined
        if (data.create) {
          data.view_list = true
          data.view_details = true
          values.lease_financial.create = true
          values.lease_financial.view_details = true
          values.lease_financial.view_list = true
          values.lease_financial.update = true
          if (!values.lease_agreements.view_details) {
            if (
              !values.lease_agreements.view_details ||
              !values.lease_agreements.contract_detail.view_details ||
              !values.lease_agreements.tenant_detail.view_details ||
              !values.lease_agreements.view_list
            )
              values.lease_agreements.isRemoved = undefined
            values.lease_agreements.view_details = true
            values.lease_agreements.contract_detail.view_details = true
            values.lease_agreements.tenant_detail.view_details = true
            values.lease_agreements.view_list = true
          }
        } else {
          if (
            !values?.lease_financial?.invoicing?.create &&
            !values?.lease_financial?.receipts?.create &&
            !values?.lease_financial?.refunds?.create &&
            !values?.lease_financial?.credit_notes?.create &&
            !values?.lease_financial?.debit_notes?.create &&
            !values?.lease_financial?.items?.create &&
            !values?.lease_financial?.vat?.create &&
            !values?.lease_financial?.late_payment_fees?.create &&
            !values?.lease_financial?.payment_method?.create &&
            !values?.lease_financial?.payment_reminders?.create &&
            !values?.lease_financial?.attachments?.create &&
            !values?.lease_financial?.notes?.create
          ) {
            values.lease_financial.create = false
            values.lease_financial.approve = false
          }
        }
        values.obj = data
        setLeasingResidential(values)
      }

      //  Late Payment Fees - Update
      else if (from == 'update' && obj == 'late_payment_fees') {
        data.update = !data.update
        values.lease_financial.isRemoved = undefined
        if (data.update) {
          data.view_list = true
          data.view_details = true
          values.lease_financial.invoicing.view_list = true
          values.lease_financial.invoicing.view_details = true
          values.lease_financial.invoicing.create = true
          values.lease_financial.invoicing.update = true
          data.delete = true
          values.lease_financial.update = true
          values.lease_financial.view_details = true
          values.lease_financial.view_list = true
          if (!values.lease_agreements.view_details) {
            if (
              !values.lease_agreements.view_details ||
              !values.lease_agreements.contract_detail.view_details ||
              !values.lease_agreements.tenant_detail.view_details ||
              !values.lease_agreements.view_list
            )
              values.lease_agreements.isRemoved = undefined
            values.lease_agreements.view_details = true
            values.lease_agreements.contract_detail.view_details = true
            values.lease_agreements.tenant_detail.view_details = true
            values.lease_agreements.view_list = true
          }
        } else {
          data.delete = false
          if (
            !values?.lease_financial?.invoicing?.update &&
            !values?.lease_financial?.receipts?.update &&
            !values?.lease_financial?.refunds?.update &&
            !values?.lease_financial?.credit_notes?.update &&
            !values?.lease_financial?.debit_notes?.update &&
            !values?.lease_financial?.items?.update &&
            !values?.lease_financial?.vat?.update &&
            !values?.lease_financial?.late_payment_fees?.update &&
            !values?.lease_financial?.payment_method?.update &&
            !values?.lease_financial?.payment_reminders?.update &&
            !values?.lease_financial?.attachments?.update &&
            !values?.lease_financial?.notes?.update
          ) {
            values.lease_financial.update = false
            values.lease_financial.approve = false
          }
        }
        values.obj = data
        setLeasingResidential(values)
      }

      //  Late Payment Fees  - Delete
      else if (from == 'delete' && obj == 'late_payment_fees') {
        data.delete = !data.delete
        values.lease_financial.isRemoved = undefined
        if (data.delete) {
          data.view_list = true
          data.update = true

          values.lease_financial.delete = true
          if (!values.lease_agreements.view_details) {
            if (
              !values.lease_agreements.view_details ||
              !values.lease_agreements.contract_detail.view_details ||
              !values.lease_agreements.tenant_detail.view_details ||
              !values.lease_agreements.view_list
            )
              values.lease_agreements.isRemoved = undefined
            values.lease_agreements.view_details = true
            values.lease_agreements.contract_detail.view_details = true
            values.lease_agreements.tenant_detail.view_details = true
            values.lease_agreements.view_list = true
          }
        } else {
          data.update = false

          if (
            !values?.lease_financial?.invoicing?.delete &&
            !values?.lease_financial?.receipts?.delete &&
            !values?.lease_financial?.refunds?.delete &&
            !values?.lease_financial?.credit_notes?.delete &&
            !values?.lease_financial?.debit_notes?.delete &&
            !values?.lease_financial?.items?.delete &&
            !values?.lease_financial?.vat?.delete &&
            !values?.lease_financial?.late_payment_fees?.delete &&
            !values?.lease_financial?.payment_method?.delete &&
            !values?.lease_financial?.payment_reminders?.delete &&
            !values?.lease_financial?.attachments?.delete &&
            !values?.lease_financial?.notes?.delete
          ) {
            values.lease_financial.delete = false
            values.lease_financial.approve = false
          }
        }
        values.obj = data
        setLeasingResidential(values)
      }

      // Payment Method - View List
      else if (from == 'view_list' && obj == 'payment_method') {
        data.view_list = !data.view_list
        values.lease_financial.isRemoved = undefined
        if (!data.view_list) {
          data.view_details = false
          data.create = false
          data.update = false
          data.delete = false
          if (
            !values?.lease_financial?.invoicing?.view_list &&
            !values?.lease_financial?.receipts?.view_list &&
            !values?.lease_financial?.refunds?.view_list &&
            !values?.lease_financial?.credit_notes?.view_list &&
            !values?.lease_financial?.debit_notes?.view_list &&
            !values?.lease_financial?.items?.view_list &&
            !values?.lease_financial?.vat?.view_list &&
            !values?.lease_financial?.late_payment_fees?.view_list &&
            !values?.lease_financial?.payment_method?.view_list &&
            !values?.lease_financial?.payment_reminders?.view_list &&
            !values?.lease_financial?.attachments?.view_list &&
            !values?.lease_financial?.notes?.view_list
          ) {
            values.lease_financial.view_list = false
            values.lease_financial.view_details = false
            values.lease_financial.create = false
            values.lease_financial.update = false
            values.lease_financial.delete = false
            values.lease_financial.approve = false
          }
        } else {
          data.view_details = true
          values.lease_financial.view_list = true
          if (!values.lease_agreements.view_details) {
            if (
              !values.lease_agreements.view_details ||
              !values.lease_agreements.contract_detail.view_details ||
              !values.lease_agreements.tenant_detail.view_details ||
              !values.lease_agreements.view_list
            )
              values.lease_agreements.isRemoved = undefined
            values.lease_agreements.view_details = true
            values.lease_agreements.contract_detail.view_details = true
            values.lease_agreements.tenant_detail.view_details = true
            values.lease_agreements.view_list = true
          }
        }

        values.obj = data
        setLeasingResidential(values)
      }

      // Payment Method  - View Details
      else if (from == 'view_details' && obj == 'payment_method') {
        data.view_details = !data.view_details
        values.lease_financial.isRemoved = undefined
        if (data.view_details) {
          data.view_list = true
          values.lease_financial.view_details = true
          values.lease_financial.view_list = true
          if (!values.lease_agreements.view_details) {
            if (
              !values.lease_agreements.view_details ||
              !values.lease_agreements.contract_detail.view_details ||
              !values.lease_agreements.tenant_detail.view_details ||
              !values.lease_agreements.view_list
            )
              values.lease_agreements.isRemoved = undefined
            values.lease_agreements.view_details = true
            values.lease_agreements.contract_detail.view_details = true
            values.lease_agreements.tenant_detail.view_details = true
            values.lease_agreements.view_list = true
          }
        } else {
          data.view_list = false
          data.create = false
          data.update = false
          data.delete = false
          if (
            !values?.lease_financial?.invoicing?.view_details &&
            !values?.lease_financial?.receipts?.view_details &&
            !values?.lease_financial?.refunds?.view_details &&
            !values?.lease_financial?.credit_notes?.view_details &&
            !values?.lease_financial?.debit_notes?.view_details &&
            !values?.lease_financial?.items?.view_details &&
            !values?.lease_financial?.vat?.view_details &&
            !values?.lease_financial?.late_payment_fees?.view_details &&
            !values?.lease_financial?.payment_method?.view_details &&
            !values?.lease_financial?.payment_reminders?.view_details &&
            !values?.lease_financial?.attachments?.view_details &&
            !values?.lease_financial?.notes?.view_details
          ) {
            values.lease_financial.view_details = false
            values.lease_financial.approve = false
          }
        }
        values.obj = data
        setLeasingResidential(values)
      }

      //  Payment Method  - Create
      else if (from == 'create' && obj == 'payment_method') {
        data.create = !data.create
        values.lease_financial.isRemoved = undefined
        if (data.create) {
          data.view_list = true
          data.view_details = true
          data.update = true
          values.lease_financial.create = true
          values.lease_financial.view_details = true
          values.lease_financial.view_list = true
          values.lease_financial.update = true
          if (!values.lease_agreements.view_details) {
            if (
              !values.lease_agreements.view_details ||
              !values.lease_agreements.contract_detail.view_details ||
              !values.lease_agreements.tenant_detail.view_details ||
              !values.lease_agreements.view_list
            )
              values.lease_agreements.isRemoved = undefined
            values.lease_agreements.view_details = true
            values.lease_agreements.contract_detail.view_details = true
            values.lease_agreements.tenant_detail.view_details = true
            values.lease_agreements.view_list = true
          }
        } else {
          data.update = false

          if (
            !values?.lease_financial?.invoicing?.create &&
            !values?.lease_financial?.receipts?.create &&
            !values?.lease_financial?.refunds?.create &&
            !values?.lease_financial?.credit_notes?.create &&
            !values?.lease_financial?.debit_notes?.create &&
            !values?.lease_financial?.items?.create &&
            !values?.lease_financial?.vat?.create &&
            !values?.lease_financial?.late_payment_fees?.create &&
            !values?.lease_financial?.payment_method?.create &&
            !values?.lease_financial?.payment_reminders?.create &&
            !values?.lease_financial?.attachments?.create &&
            !values?.lease_financial?.notes?.create
          ) {
            values.lease_financial.create = false
            values.lease_financial.approve = false
          }
        }
        values.obj = data
        setLeasingResidential(values)
      }

      //  Payment Method - Update
      else if (from == 'update' && obj == 'payment_method') {
        data.update = !data.update
        values.lease_financial.isRemoved = undefined
        if (data.update) {
          data.view_list = true
          data.view_details = true
          data.create = true
          values.lease_financial.update = true
          values.lease_financial.view_details = true
          values.lease_financial.view_list = true
          if (!values.lease_agreements.view_details) {
            if (
              !values.lease_agreements.view_details ||
              !values.lease_agreements.contract_detail.view_details ||
              !values.lease_agreements.tenant_detail.view_details ||
              !values.lease_agreements.view_list
            )
              values.lease_agreements.isRemoved = undefined
            values.lease_agreements.view_details = true
            values.lease_agreements.contract_detail.view_details = true
            values.lease_agreements.tenant_detail.view_details = true
            values.lease_agreements.view_list = true
          }
        } else {
          data.create = false
          data.delete = false

          if (
            !values?.lease_financial?.invoicing?.update &&
            !values?.lease_financial?.receipts?.update &&
            !values?.lease_financial?.refunds?.update &&
            !values?.lease_financial?.credit_notes?.update &&
            !values?.lease_financial?.debit_notes?.update &&
            !values?.lease_financial?.items?.update &&
            !values?.lease_financial?.vat?.update &&
            !values?.lease_financial?.late_payment_fees?.update &&
            !values?.lease_financial?.payment_method?.update &&
            !values?.lease_financial?.payment_reminders?.update &&
            !values?.lease_financial?.attachments?.update &&
            !values?.lease_financial?.notes?.update
          ) {
            values.lease_financial.update = false
            values.lease_financial.approve = false
          }
        }
        values.obj = data
        setLeasingResidential(values)
      }

      //  Payment Method  - Delete
      else if (from == 'delete' && obj == 'payment_method') {
        data.delete = !data.delete
        values.lease_financial.isRemoved = undefined
        if (data.delete) {
          data.view_list = true
          data.view_details = true
          data.update = true
          data.create = true

          values.lease_financial.delete = true
          if (!values.lease_agreements.view_details) {
            if (
              !values.lease_agreements.view_details ||
              !values.lease_agreements.contract_detail.view_details ||
              !values.lease_agreements.tenant_detail.view_details ||
              !values.lease_agreements.view_list
            )
              values.lease_agreements.isRemoved = undefined
            values.lease_agreements.view_details = true
            values.lease_agreements.contract_detail.view_details = true
            values.lease_agreements.tenant_detail.view_details = true
            values.lease_agreements.view_list = true
          }
        } else {
          if (
            !values?.lease_financial?.invoicing?.delete &&
            !values?.lease_financial?.receipts?.delete &&
            !values?.lease_financial?.refunds?.delete &&
            !values?.lease_financial?.credit_notes?.delete &&
            !values?.lease_financial?.debit_notes?.delete &&
            !values?.lease_financial?.items?.delete &&
            !values?.lease_financial?.vat?.delete &&
            !values?.lease_financial?.late_payment_fees?.delete &&
            !values?.lease_financial?.payment_method?.delete &&
            !values?.lease_financial?.payment_reminders?.delete &&
            !values?.lease_financial?.attachments?.delete &&
            !values?.lease_financial?.notes?.delete
          ) {
            values.lease_financial.delete = false
            values.lease_financial.approve = false
          }
        }
        values.obj = data
        setLeasingResidential(values)
      }

      // Payment Reminders/Attachments/Notes  - View List
      else if (
        from == 'view_list' &&
        (obj == 'payment_reminders' || obj == 'attachments' || obj == 'notes')
      ) {
        data.view_list = !data.view_list
        values.lease_financial.isRemoved = undefined
        if (!data.view_list) {
          data.view_details = false
          data.create = false
          data.update = false
          data.delete = false
          if (
            !values?.lease_financial?.invoicing?.view_list &&
            !values?.lease_financial?.receipts?.view_list &&
            !values?.lease_financial?.refunds?.view_list &&
            !values?.lease_financial?.credit_notes?.view_list &&
            !values?.lease_financial?.debit_notes?.view_list &&
            !values?.lease_financial?.items?.view_list &&
            !values?.lease_financial?.vat?.view_list &&
            !values?.lease_financial?.late_payment_fees?.view_list &&
            !values?.lease_financial?.payment_method?.view_list &&
            !values?.lease_financial?.payment_reminders?.view_list &&
            !values?.lease_financial?.attachments?.view_list &&
            !values?.lease_financial?.notes?.view_list
          ) {
            values.lease_financial.view_list = false
            values.lease_financial.view_details = false
            values.lease_financial.create = false
            values.lease_financial.update = false
            values.lease_financial.delete = false
            values.lease_financial.approve = false
          }
        } else {
          data.view_details = true

          values.lease_financial.view_list = true
          if (!values.lease_agreements.view_details) {
            if (
              !values.lease_agreements.view_details ||
              !values.lease_agreements.contract_detail.view_details ||
              !values.lease_agreements.tenant_detail.view_details ||
              !values.lease_agreements.view_list
            )
              values.lease_agreements.isRemoved = undefined
            values.lease_agreements.view_details = true
            values.lease_agreements.contract_detail.view_details = true
            values.lease_agreements.tenant_detail.view_details = true
            values.lease_agreements.view_list = true
          }
        }

        values.obj = data
        setLeasingResidential(values)
      }

      // Payment Reminders/Attachments/Notes   - View Details
      else if (
        from == 'view_details' &&
        (obj == 'payment_reminders' || obj == 'attachments' || obj == 'notes')
      ) {
        data.view_details = !data.view_details
        values.lease_financial.isRemoved = undefined
        if (data.view_details) {
          data.view_list = true
          values.lease_financial.view_details = true
          values.lease_financial.view_list = true
          if (!values.lease_agreements.view_details) {
            if (
              !values.lease_agreements.view_details ||
              !values.lease_agreements.contract_detail.view_details ||
              !values.lease_agreements.tenant_detail.view_details ||
              !values.lease_agreements.view_list
            )
              values.lease_agreements.isRemoved = undefined
            values.lease_agreements.view_details = true
            values.lease_agreements.contract_detail.view_details = true
            values.lease_agreements.tenant_detail.view_details = true
            values.lease_agreements.view_list = true
          }
        } else {
          data.view_list = false
          data.create = false
          data.update = false
          data.delete = false
          if (
            !values?.lease_financial?.invoicing?.view_details &&
            !values?.lease_financial?.receipts?.view_details &&
            !values?.lease_financial?.refunds?.view_details &&
            !values?.lease_financial?.credit_notes?.view_details &&
            !values?.lease_financial?.debit_notes?.view_details &&
            !values?.lease_financial?.items?.view_details &&
            !values?.lease_financial?.vat?.view_details &&
            !values?.lease_financial?.late_payment_fees?.view_details &&
            !values?.lease_financial?.payment_method?.view_details &&
            !values?.lease_financial?.payment_reminders?.view_details &&
            !values?.lease_financial?.attachments?.view_details &&
            !values?.lease_financial?.notes?.view_details
          ) {
            values.lease_financial.view_details = false
            values.lease_financial.approve = false
          }
        }
        values.obj = data
        setLeasingResidential(values)
      }

      //  Payment Reminders/Attachments/Notes   - Create
      else if (
        from == 'create' &&
        (obj == 'payment_reminders' || obj == 'attachments' || obj == 'notes')
      ) {
        data.create = !data.create
        values.lease_financial.isRemoved = undefined
        if (data.create) {
          data.view_list = true
          data.view_details = true
          data.update = true
          data.delete = true
          values.lease_financial.create = true
          values.lease_financial.view_details = true
          values.lease_financial.view_list = true
          values.lease_financial.update = true
          if (!values.lease_agreements.view_details) {
            if (
              !values.lease_agreements.view_details ||
              !values.lease_agreements.contract_detail.view_details ||
              !values.lease_agreements.tenant_detail.view_details ||
              !values.lease_agreements.view_list
            )
              values.lease_agreements.isRemoved = undefined
            values.lease_agreements.view_details = true
            values.lease_agreements.contract_detail.view_details = true
            values.lease_agreements.tenant_detail.view_details = true
            values.lease_agreements.view_list = true
          }
        } else {
          data.update = false
          data.delete = false

          if (
            !values?.lease_financial?.invoicing?.create &&
            !values?.lease_financial?.receipts?.create &&
            !values?.lease_financial?.refunds?.create &&
            !values?.lease_financial?.credit_notes?.create &&
            !values?.lease_financial?.debit_notes?.create &&
            !values?.lease_financial?.items?.create &&
            !values?.lease_financial?.vat?.create &&
            !values?.lease_financial?.late_payment_fees?.create &&
            !values?.lease_financial?.payment_method?.create &&
            !values?.lease_financial?.payment_reminders?.create &&
            !values?.lease_financial?.attachments?.create &&
            !values?.lease_financial?.notes?.create
          ) {
            values.lease_financial.create = false
            values.lease_financial.approve = false
          }
        }
        values.obj = data
        setLeasingResidential(values)
      }

      //  Payment Reminders/Attachments/Notes  - Update
      else if (
        from == 'update' &&
        (obj == 'payment_reminders' || obj == 'attachments' || obj == 'notes')
      ) {
        data.update = !data.update
        values.lease_financial.isRemoved = undefined
        if (data.update) {
          data.view_list = true
          data.view_details = true
          data.create = true
          data.delete = true
          values.lease_financial.update = true
          values.lease_financial.view_details = true
          values.lease_financial.view_list = true
          if (!values.lease_agreements.view_details) {
            if (
              !values.lease_agreements.view_details ||
              !values.lease_agreements.contract_detail.view_details ||
              !values.lease_agreements.tenant_detail.view_details ||
              !values.lease_agreements.view_list
            )
              values.lease_agreements.isRemoved = undefined
            values.lease_agreements.view_details = true
            values.lease_agreements.contract_detail.view_details = true
            values.lease_agreements.tenant_detail.view_details = true
            values.lease_agreements.view_list = true
          }
        } else {
          data.create = false
          data.delete = false
          if (
            !values?.lease_financial?.invoicing?.update &&
            !values?.lease_financial?.receipts?.update &&
            !values?.lease_financial?.refunds?.update &&
            !values?.lease_financial?.credit_notes?.update &&
            !values?.lease_financial?.debit_notes?.update &&
            !values?.lease_financial?.items?.update &&
            !values?.lease_financial?.vat?.update &&
            !values?.lease_financial?.late_payment_fees?.update &&
            !values?.lease_financial?.payment_method?.update &&
            !values?.lease_financial?.payment_reminders?.update &&
            !values?.lease_financial?.attachments?.update &&
            !values?.lease_financial?.notes?.update
          ) {
            values.lease_financial.update = false
            values.lease_financial.approve = false
          }
        }
        values.obj = data
        setLeasingResidential(values)
      }

      //  Payment Reminders/Attachments/Notes - Delete
      else if (
        from == 'delete' &&
        (obj == 'payment_reminders' || obj == 'attachments' || obj == 'notes')
      ) {
        data.delete = !data.delete
        values.lease_financial.isRemoved = undefined
        if (data.delete) {
          data.view_list = true
          data.view_details = true
          data.update = true
          data.create = true

          values.lease_financial.delete = true
          if (!values.lease_agreements.view_details) {
            if (
              !values.lease_agreements.view_details ||
              !values.lease_agreements.contract_detail.view_details ||
              !values.lease_agreements.tenant_detail.view_details ||
              !values.lease_agreements.view_list
            )
              values.lease_agreements.isRemoved = undefined
            values.lease_agreements.view_details = true
            values.lease_agreements.contract_detail.view_details = true
            values.lease_agreements.tenant_detail.view_details = true
            values.lease_agreements.view_list = true
          }
        } else {
          data.update = false
          data.create = false

          if (
            !values?.lease_financial?.invoicing?.delete &&
            !values?.lease_financial?.receipts?.delete &&
            !values?.lease_financial?.refunds?.delete &&
            !values?.lease_financial?.credit_notes?.delete &&
            !values?.lease_financial?.debit_notes?.delete &&
            !values?.lease_financial?.items?.delete &&
            !values?.lease_financial?.vat?.delete &&
            !values?.lease_financial?.late_payment_fees?.delete &&
            !values?.lease_financial?.payment_method?.delete &&
            !values?.lease_financial?.payment_reminders?.delete &&
            !values?.lease_financial?.attachments?.delete &&
            !values?.lease_financial?.notes?.delete
          ) {
            values.lease_financial.delete = false
            values.lease_financial.approve = false
          }
        }
        values.obj = data
        setLeasingResidential(values)
      }
    }
  }

  useEffect(() => {
    let values = {...roleData}
    // values = {
    //   ...values,
    //   featureList: {
    //     leasing_residential: leasingResidential,
    //   },
    // }
    values.featureList.leasing_residential = leasingResidential
    // roleData = values

    updateRoleDate(values)
  }, [leasingResidential])

  // console.log(cameFrom)

  return (
    <>
      <div id='' className='pt-0 mt-0 px-2 ps-0'>
        <div className='table-responsive '>
          <table
            className='table align-start table-row-dashed fs-5 gy-5 '
            id='kt_ecommerce_sales_table'
          >
            {/* {((leasingResidential?.lease_agreements?.view_list ||
              leasingResidential?.lease_agreements?.view_details ||
              leasingResidential?.lease_agreements?.create ||
              leasingResidential?.lease_agreements?.update ||
              leasingResidential?.lease_agreements?.delete ||
              leasingResidential?.lease_agreements?.approve) || (from == 'add')) && ( */}
            {(((from == 'add-view' || from == 'view') &&
              leasingResidential?.lease_agreements?.isRemoved == undefined &&
              !leasingResidential?.lease_agreements?.view_list &&
              !leasingResidential?.lease_agreements?.view_details &&
              !leasingResidential?.lease_agreements?.create &&
              !leasingResidential?.lease_agreements?.update &&
              !leasingResidential?.lease_agreements?.delete &&
              !leasingResidential?.lease_agreements?.approve) ||
              leasingResidential?.lease_agreements?.view_list ||
              leasingResidential?.lease_agreements?.view_details ||
              leasingResidential?.lease_agreements?.create ||
              leasingResidential?.lease_agreements?.update ||
              leasingResidential?.lease_agreements?.delete ||
              leasingResidential?.lease_agreements?.approve ||
              from == 'add' ||
              from == 'addOfEdit') && (
              <thead>
                <th className='text-start min-w-25px'></th>
                <th className='text-start min-w-300px m-w-300'></th>

                <th className='text-start min-w-100px text-capitalize'>
                  {' '}
                  View List{' '}
                  <span>
                    <OverlayTrigger
                      placement='bottom'
                      delay={{show: 250, hide: 400}}
                      overlay={
                        <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                          {' '}
                          User can view the list of lease agreements.
                        </Tooltip>
                      }
                    >
                      <span>
                        {' '}
                        <AiOutlineExclamationCircle className='ms-0 cursor-pointer exclam-circle' />
                      </span>
                    </OverlayTrigger>
                  </span>
                </th>
                <th className='text-start min-w-125px text-capitalize'>
                  {' '}
                  View Details{' '}
                  <span>
                    <OverlayTrigger
                      placement='bottom'
                      delay={{show: 250, hide: 400}}
                      overlay={
                        <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                          {' '}
                          User can access and view the details of a lease agreement.
                        </Tooltip>
                      }
                    >
                      <span>
                        {' '}
                        <AiOutlineExclamationCircle className='ms-0 cursor-pointer exclam-circle' />
                      </span>
                    </OverlayTrigger>
                  </span>
                </th>
                <th className='text-start min-w-100px text-capitalize'>
                  {' '}
                  Create{' '}
                  <span>
                    <OverlayTrigger
                      placement='bottom'
                      delay={{show: 250, hide: 400}}
                      overlay={
                        <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                          {' '}
                          User can initiate and create lease agreements. User will be available as
                          an initiator and inputter in the lease agreements workflow except for the
                          ‘Financials’ section.
                        </Tooltip>
                      }
                    >
                      <span>
                        {' '}
                        <AiOutlineExclamationCircle className='ms-0 cursor-pointer exclam-circle' />
                      </span>
                    </OverlayTrigger>
                  </span>
                </th>
                <th className='text-start min-w-75px text-capitalize'>
                  {' '}
                  Edit{' '}
                  <span>
                    <OverlayTrigger
                      placement='bottom'
                      delay={{show: 250, hide: 400}}
                      overlay={
                        <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                          {' '}
                          User can edit a created lease agreement.
                        </Tooltip>
                      }
                    >
                      <span>
                        {' '}
                        <AiOutlineExclamationCircle className='ms-0 cursor-pointer exclam-circle' />
                      </span>
                    </OverlayTrigger>
                  </span>
                </th>
                <th className='text-start min-w-100px text-capitalize'>
                  {' '}
                  Delete{' '}
                  <span>
                    <OverlayTrigger
                      placement='bottom'
                      delay={{show: 250, hide: 400}}
                      overlay={
                        <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                          {' '}
                          User can delete draft, cancelled or expired lease agreements.
                        </Tooltip>
                      }
                    >
                      <span>
                        {' '}
                        <AiOutlineExclamationCircle className='ms-0 cursor-pointer exclam-circle' />
                      </span>
                    </OverlayTrigger>
                  </span>
                </th>
                <th className='text-start min-w-100px text-capitalize'>
                  {' '}
                  Approve{' '}
                  <span>
                    <OverlayTrigger
                      placement='bottom'
                      delay={{show: 250, hide: 400}}
                      overlay={
                        <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                          {' '}
                          User can approve a lease agreement request before it is shared with a
                          tenant. User will be available as an approver in the lease agreements
                          workflow and approve/edit a lease that is under approval.
                        </Tooltip>
                      }
                    >
                      <span>
                        {' '}
                        <AiOutlineExclamationCircle className='ms-0 cursor-pointer exclam-circle' />
                      </span>
                    </OverlayTrigger>
                  </span>
                </th>
              </thead>
            )}
            <tbody className='fw-semibold text-gray-600 role-test'>
              {/* Leasing Agreements */}
              {/* {((leasingResidential?.lease_agreements?.view_list ||
                leasingResidential?.lease_agreements?.view_details ||
                leasingResidential?.lease_agreements?.create ||
                leasingResidential?.lease_agreements?.update ||
                leasingResidential?.lease_agreements?.delete ||
                leasingResidential?.lease_agreements?.approve) || (from == 'add')) && ( */}
              {(((from == 'add-view' || from == 'view') &&
                leasingResidential?.lease_agreements?.isRemoved == undefined &&
                !leasingResidential?.lease_agreements?.view_list &&
                !leasingResidential?.lease_agreements?.view_details &&
                !leasingResidential?.lease_agreements?.create &&
                !leasingResidential?.lease_agreements?.update &&
                !leasingResidential?.lease_agreements?.delete &&
                !leasingResidential?.lease_agreements?.approve) ||
                leasingResidential?.lease_agreements?.view_list ||
                leasingResidential?.lease_agreements?.view_details ||
                leasingResidential?.lease_agreements?.create ||
                leasingResidential?.lease_agreements?.update ||
                leasingResidential?.lease_agreements?.delete ||
                leasingResidential?.lease_agreements?.approve ||
                from == 'add' ||
                from == 'addOfEdit') && (
                <>
                  <tr>
                    <td className='text-start ps-0'>
                      {from != 'add' && from != 'view' && (
                        <img
                          src={redCross}
                          height={16}
                          width={16}
                          className='me-2 cursor-pointer'
                          onClick={() => {
                            let temp = localStorage.getItem('roleDataBackup')
                            let values = {...leasingResidential}
                            if (temp != null) {
                              let backup = JSON.parse(temp)

                              values.lease_agreements =
                                backup?.featureList?.leasing_residential?.lease_agreements
                              values.lease_agreements.isRemoved = true
                            }

                            // values.lease_agreements = undefined
                            setLeasingResidential(values)

                            // let temp = localStorage.getItem('roleDataBackup')
                            // let values = {...leasingResidential}
                            // if (temp != null) {
                            //   let backup = JSON.parse(temp)

                            //   values.lease_agreements =
                            //     backup?.featureList?.leasing_residential?.lease_agreements
                            // }
                            // setLeasingResidential(values)
                          }}
                        />
                      )}
                    </td>

                    <td className='text-start ps-0 pe-5'>
                      <p className='user-main-head pe-2 mb-1' style={{fontWeight: '700'}}>
                        Leasing Agreements{' '}
                      </p>
                      <p className='user-sub-head mb-2'>
                        <i>Generate and manage lease agreements.</i>
                      </p>
                    </td>
                    <td className='text-center ps-0'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          id='vacant0'
                          name='none'
                          checked={leasingResidential?.lease_agreements?.view_list}
                          onChange={(e: any) => {
                            handleChangeRoleData(
                              leasingResidential?.lease_agreements,
                              'lease_agreements',
                              'view_list',
                              e
                            )
                          }}
                        />
                      </div>
                    </td>
                    <td
                      className={
                        leasingResidential?.lease_agreements?.tenant_detail?.view_details &&
                        leasingResidential?.lease_agreements?.contract_detail?.view_details
                          ? 'text-center ps-0 role-test'
                          : 'text-center ps-0 all-select'
                      }
                    >
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          id='vacant0'
                          name='none'
                          checked={leasingResidential?.lease_agreements?.view_details}
                          onChange={(e: any) => {
                            handleChangeRoleData(
                              leasingResidential?.lease_agreements,
                              'lease_agreements',
                              'view_details',
                              e
                            )
                          }}
                        />
                      </div>
                    </td>
                    <td
                      className={
                        leasingResidential?.lease_agreements?.tenant_detail?.create &&
                        leasingResidential?.lease_agreements?.contract_detail?.create
                          ? 'text-center ps-0 role-test'
                          : 'text-center ps-0 all-select'
                      }
                    >
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          id='vacant0'
                          name='none'
                          checked={leasingResidential?.lease_agreements?.create}
                          onChange={(e: any) => {
                            handleChangeRoleData(
                              leasingResidential?.lease_agreements,
                              'lease_agreements',
                              'create',
                              e
                            )
                          }}
                        />
                      </div>
                    </td>
                    <td
                      className={
                        leasingResidential?.lease_agreements?.tenant_detail?.update &&
                        leasingResidential?.lease_agreements?.contract_detail?.update
                          ? 'text-center ps-0 role-test'
                          : 'text-center ps-0 all-select'
                      }
                    >
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          id='vacant0'
                          name='none'
                          checked={leasingResidential?.lease_agreements?.update}
                          onChange={(e: any) => {
                            handleChangeRoleData(
                              leasingResidential?.lease_agreements,
                              'lease_agreements',
                              'update',
                              e
                            )
                          }}
                        />
                      </div>
                    </td>
                    <td className='text-center ps-0'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          id='vacant0'
                          name='none'
                          checked={leasingResidential?.lease_agreements?.delete}
                          onChange={(e: any) => {
                            handleChangeRoleData(
                              leasingResidential?.lease_agreements,
                              'lease_agreements',
                              'delete',
                              e
                            )
                          }}
                        />
                      </div>
                    </td>
                    <td className='text-center ps-0'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          id='vacant0'
                          name='none'
                          checked={leasingResidential?.lease_agreements?.approve}
                          onChange={(e: any) => {
                            handleChangeRoleData(
                              leasingResidential?.lease_agreements,
                              'lease_agreements',
                              'approve',
                              e
                            )
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className='text-start ps-0'></td>
                    <td className='text-start ps-0 pe-5'>
                      <p className='user-sub2 pe-2 mb-0 ps-5 ms-3' style={{fontWeight: '600'}}>
                        {' '}
                        - Tenant Details{' '}
                      </p>
                      <p className='user-sub-head mb-2 ps-5 ms-3'>
                        <i>
                          Selecting tenants and managing tenant information in a lease agreement.
                        </i>
                      </p>
                    </td>
                    <td className='text-center ps-0'></td>
                    <td className='text-center ps-0'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                        <input
                          className='form-check-input'
                          type='radio'
                          id='vacant0'
                          name='tenant_view_details'
                          checked={
                            leasingResidential?.lease_agreements?.tenant_detail?.view_details
                          }
                          onClick={(e: any) => {
                            handleChangeRoleData(
                              leasingResidential?.lease_agreements?.tenant_detail,
                              'tenant_detail',
                              'view_details',
                              e
                            )
                          }}
                        />
                      </div>
                    </td>
                    <td className='text-center ps-0'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                        <input
                          className='form-check-input'
                          type='radio'
                          id='vacant0'
                          name='tenant_create'
                          checked={leasingResidential?.lease_agreements?.tenant_detail?.create}
                          onClick={(e: any) => {
                            handleChangeRoleData(
                              leasingResidential?.lease_agreements?.tenant_detail,
                              'tenant_detail',
                              'create',
                              e
                            )
                          }}
                        />
                      </div>
                    </td>
                    <td className='text-center ps-0'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                        <input
                          className='form-check-input'
                          type='radio'
                          id='vacant0'
                          name='tenant_update'
                          checked={leasingResidential?.lease_agreements?.tenant_detail?.update}
                          onClick={(e: any) => {
                            handleChangeRoleData(
                              leasingResidential?.lease_agreements?.tenant_detail,
                              'tenant_detail',
                              'update',
                              e
                            )
                          }}
                        />
                      </div>
                    </td>
                    <td className='text-center ps-0'></td>
                    <td className='text-center ps-0'></td>
                  </tr>
                  <tr>
                    <td className='text-start ps-0'></td>
                    <td className='text-start ps-0 pe-5'>
                      <p className='user-sub2 pe-2 mb-0 ps-5 ms-3' style={{fontWeight: '600'}}>
                        {' '}
                        - Contract Details{' '}
                      </p>
                      <p className='user-sub-head mb-2 ps-5 ms-3'>
                        <i>
                          Setting the details of a lease agreement such as duration, contract no.,
                          benefits, rent values, security deposits, uploading documents related to
                          the contract agreement.
                        </i>
                      </p>
                    </td>
                    <td className='text-center ps-0'></td>
                    <td className='text-center ps-0'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                        <input
                          className='form-check-input'
                          type='radio'
                          id='vacant0'
                          name='contract_view_details'
                          checked={
                            leasingResidential?.lease_agreements?.contract_detail?.view_details
                          }
                          onClick={(e: any) => {
                            handleChangeRoleData(
                              leasingResidential?.lease_agreements?.contract_detail,
                              'contract_detail',
                              'view_details',
                              e
                            )
                          }}
                        />
                      </div>
                    </td>
                    <td className='text-center ps-0'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                        <input
                          className='form-check-input'
                          type='radio'
                          id='vacant0'
                          name='contract_create'
                          checked={leasingResidential?.lease_agreements?.contract_detail?.create}
                          onClick={(e: any) => {
                            handleChangeRoleData(
                              leasingResidential?.lease_agreements?.contract_detail,
                              'contract_detail',
                              'create',
                              e
                            )
                          }}
                        />
                      </div>
                    </td>
                    <td className='text-center ps-0'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                        <input
                          className='form-check-input'
                          type='radio'
                          id='vacant0'
                          name='contract_update'
                          checked={leasingResidential?.lease_agreements?.contract_detail?.update}
                          onClick={(e: any) => {
                            handleChangeRoleData(
                              leasingResidential?.lease_agreements?.contract_detail,
                              'contract_detail',
                              'update',
                              e
                            )
                          }}
                        />
                      </div>
                    </td>
                    <td className='text-center ps-0'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'></div>
                    </td>
                    <td className='text-center ps-0'></td>
                  </tr>
                </>
              )}

              {/* Cancellations */}
              {/* {((leasingResidential?.cancellation?.allow) || (from == 'add')) && ( */}
              {(((from == 'add-view' || from == 'view') &&
                leasingResidential?.cancellation?.isRemoved == undefined &&
                !leasingResidential?.cancellation?.allow) ||
                leasingResidential?.cancellation?.allow ||
                from == 'add' ||
                from == 'addOfEdit') && (
                <>
                  <tr style={{borderBottom: 'none', height: '35px'}}>
                    <td className='text-start min-w-25px user-th'></td>
                    <td className='text-start min-w-300px m-w-300 user-th'></td>

                    <td className='text-start min-w-100px text-capitalize user-th ps-0'>
                      {' '}
                      Allow{' '}
                      <span>
                        <OverlayTrigger
                          placement='top'
                          delay={{show: 250, hide: 400}}
                          overlay={
                            <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                              {' '}
                              User can cancel upcoming leases (leases that have not yet reached
                              their start date).
                            </Tooltip>
                          }
                        >
                          <span>
                            {' '}
                            <AiOutlineExclamationCircle className='ms-2 cursor-pointer mb-1 exclam-circle' />
                          </span>
                        </OverlayTrigger>
                      </span>
                    </td>
                    <td className='text-start min-w-125px text-capitalize user-th'></td>
                    <td className='text-start min-w-100px text-capitalize user-th'></td>
                    <td className='text-start min-w-75px text-capitalize user-th'></td>
                    <td className='text-start min-w-100px text-capitalize user-th'></td>
                    <td className='text-start min-w-100px text-capitalize user-th'></td>
                  </tr>
                  <tr>
                    <td className='text-start ps-0'>
                      {from != 'add' && from != 'view' && (
                        <img
                          src={redCross}
                          height={16}
                          width={16}
                          className='me-2 cursor-pointer'
                          onClick={() => {
                            let temp = localStorage.getItem('roleDataBackup')
                            let values = {...leasingResidential}
                            if (temp != null) {
                              let backup = JSON.parse(temp)

                              values.cancellation =
                                backup?.featureList?.leasing_residential?.cancellation
                              values.cancellation.isRemoved = true
                            }

                            // values.lease_agreements = undefined
                            setLeasingResidential(values)

                            // let temp = localStorage.getItem('roleDataBackup')
                            // let values = {...leasingResidential}
                            // if (temp != null) {
                            //   let backup = JSON.parse(temp)
                            //   console.log(backup)
                            //   values.cancellation =
                            //     backup?.featureList?.leasing_residential?.cancellation
                            // }
                            // setLeasingResidential(values)
                          }}
                        />
                      )}
                    </td>
                    <td className='text-start ps-0 pe-5'>
                      <p className='user-main-head pe-2 mb-1' style={{fontWeight: '700'}}>
                        Cancellations{' '}
                      </p>
                      <p className='user-sub-head mb-2'>
                        <i>Cancel upcoming leases on booked properties.</i>
                      </p>
                    </td>
                    <td className='text-center ps-0'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          id='vacant0'
                          name='none'
                          checked={leasingResidential?.cancellation?.allow}
                          onClick={(e: any) => {
                            handleChangeRoleData(
                              leasingResidential?.cancellation,
                              'cancellation',
                              'allow',
                              e
                            )
                          }}
                        />
                      </div>
                    </td>
                    <td className='text-center ps-0'></td>
                    <td className='text-center ps-0'></td>
                    <td className='text-center ps-0'></td>
                    <td className='text-center ps-0'></td>
                    <td className='text-center ps-0'></td>
                  </tr>
                </>
              )}

              {/* Renewals */}
              {/* {((leasingResidential?.renewals?.view_list ||
                leasingResidential?.renewals?.view_details ||
                leasingResidential?.renewals?.create ||
                leasingResidential?.renewals?.update ||
                leasingResidential?.renewals?.delete ||
                leasingResidential?.renewals?.approve) || (from == 'add'))  && ( */}

              {(((from == 'add-view' || from == 'view') &&
                leasingResidential?.renewals?.isRemoved == undefined &&
                !leasingResidential?.renewals?.view_list &&
                !leasingResidential?.renewals?.view_details &&
                !leasingResidential?.renewals?.create &&
                !leasingResidential?.renewals?.update &&
                !leasingResidential?.renewals?.delete &&
                !leasingResidential?.renewals?.approve) ||
                leasingResidential?.renewals?.view_list ||
                leasingResidential?.renewals?.view_details ||
                leasingResidential?.renewals?.create ||
                leasingResidential?.renewals?.update ||
                leasingResidential?.renewals?.delete ||
                leasingResidential?.renewals?.approve ||
                from == 'add' ||
                from == 'addOfEdit') && (
                <>
                  <tr
                    className='user-th'
                    style={{
                      borderBottom: 'none',
                      height: '35px',
                    }}
                  >
                    <td className='text-start min-w-25px user-th'></td>
                    <td className='text-start min-w-300px m-w-300 user-th'></td>

                    <td className='text-start min-w-100px text-capitalize user-th ps-0'>
                      {' '}
                      View List{' '}
                      <span>
                        <OverlayTrigger
                          placement='top'
                          delay={{show: 250, hide: 400}}
                          overlay={
                            <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                              {' '}
                              User can view the list of lease renewal requests submitted.
                            </Tooltip>
                          }
                        >
                          <span>
                            {' '}
                            <AiOutlineExclamationCircle className='ms-2 cursor-pointer mb-1 exclam-circle' />
                          </span>
                        </OverlayTrigger>
                      </span>
                    </td>
                    <td
                      className='text-start min-w-125px text-capitalize user-th ps-0'
                      style={{paddingTop: '1.25rem', paddingBottom: '1.25rem'}}
                    >
                      {' '}
                      View Details{' '}
                      <span>
                        <OverlayTrigger
                          placement='top'
                          delay={{show: 250, hide: 400}}
                          overlay={
                            <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                              {' '}
                              User can access and view the details of a lease renewal request. User
                              can access and view the details of a lease agreement.
                            </Tooltip>
                          }
                        >
                          <span>
                            {' '}
                            <AiOutlineExclamationCircle className='ms-2 cursor-pointer mb-1 exclam-circle' />
                          </span>
                        </OverlayTrigger>
                      </span>
                    </td>
                    <td className='text-start min-w-100px text-capitalize user-th ps-0'>
                      {' '}
                      Create{' '}
                      <span>
                        <OverlayTrigger
                          placement='top'
                          delay={{show: 250, hide: 400}}
                          overlay={
                            <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                              {' '}
                              User can initiate a lease renewal request and generate a lease
                              renewal. User will be available as an initiator and inputter in the
                              lease renewal workflow except for the ‘Financials’ section.
                            </Tooltip>
                          }
                        >
                          <span>
                            {' '}
                            <AiOutlineExclamationCircle className='ms-2 cursor-pointer mb-1 exclam-circle' />
                          </span>
                        </OverlayTrigger>
                      </span>
                    </td>
                    <td className='text-start min-w-75px text-capitalize user-th ps-0'>
                      {' '}
                      Edit{' '}
                      <span>
                        <OverlayTrigger
                          placement='top'
                          delay={{show: 250, hide: 400}}
                          overlay={
                            <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                              {' '}
                              User can edit and cancel a lease renewal request. User will be
                              available as an inputter in the lease renewal workflow except for the
                              ‘Financials’ section.
                            </Tooltip>
                          }
                        >
                          <span>
                            {' '}
                            <AiOutlineExclamationCircle className='ms-2 cursor-pointer mb-1 exclam-circle' />
                          </span>
                        </OverlayTrigger>
                      </span>
                    </td>
                    <td className='text-start min-w-100px text-capitalize user-th ps-0'>
                      {' '}
                      Delete{' '}
                      <span>
                        <OverlayTrigger
                          placement='top'
                          delay={{show: 250, hide: 400}}
                          overlay={
                            <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                              {' '}
                              User can delete a cancelled lease renewal request.
                            </Tooltip>
                          }
                        >
                          <span>
                            {' '}
                            <AiOutlineExclamationCircle className='ms-2 cursor-pointer mb-1 exclam-circle' />
                          </span>
                        </OverlayTrigger>
                      </span>
                    </td>
                    <td className='text-start min-w-100px text-capitalize user-th ps-0'>
                      {' '}
                      Approve{' '}
                      <span>
                        <OverlayTrigger
                          placement='top'
                          delay={{show: 250, hide: 400}}
                          overlay={
                            <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                              {' '}
                              User can approve a lease renewal request before it is shared with a
                              tenant. User will be available as an approver in the lease renewal
                              workflow and approve/edit a renewal that is under approval.
                            </Tooltip>
                          }
                        >
                          <span>
                            {' '}
                            <AiOutlineExclamationCircle className='ms-2 cursor-pointer mb-1 exclam-circle' />
                          </span>
                        </OverlayTrigger>
                      </span>
                    </td>
                  </tr>
                  <tr className='mt-2'>
                    <td className='text-start ps-0'>
                      {from != 'add' && from != 'view' && (
                        <img
                          src={redCross}
                          height={16}
                          width={16}
                          className='me-2 cursor-pointer'
                          onClick={() => {
                            let temp = localStorage.getItem('roleDataBackup')
                            let values = {...leasingResidential}
                            if (temp != null) {
                              let backup = JSON.parse(temp)
                              values.renewals = backup?.featureList?.leasing_residential?.renewals
                              values.renewals.isRemoved = true
                            }
                            setLeasingResidential(values)
                          }}
                        />
                      )}
                    </td>

                    <td className='text-start ps-0 pe-5'>
                      <p className='user-main-head pe-2 mb-1' style={{fontWeight: '700'}}>
                        Renewals{' '}
                      </p>
                      <p className='user-sub-head mb-2'>
                        <i>Renew and extend existing leases for existing tenants.</i>
                      </p>
                    </td>
                    <td className='text-center ps-0'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          id='vacant0'
                          name='none'
                          checked={leasingResidential?.renewals?.view_list}
                          onChange={(e: any) => {
                            handleChangeRoleData(
                              leasingResidential?.renewals,
                              'renewals',
                              'view_list',
                              e
                            )
                          }}
                        />
                      </div>
                    </td>
                    <td className='text-center ps-0'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          id='vacant0'
                          name='none'
                          checked={leasingResidential?.renewals?.view_details}
                          onChange={(e: any) => {
                            handleChangeRoleData(
                              leasingResidential?.renewals,
                              'renewals',
                              'view_details',
                              e
                            )
                          }}
                        />
                      </div>
                    </td>

                    <td className='text-center ps-0'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          id='vacant0'
                          name='none'
                          checked={leasingResidential?.renewals?.create}
                          onChange={(e: any) => {
                            handleChangeRoleData(
                              leasingResidential?.renewals,
                              'renewals',
                              'create',
                              e
                            )
                          }}
                        />
                      </div>
                    </td>
                    <td className='text-center ps-0'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          id='vacant0'
                          name='none'
                          checked={leasingResidential?.renewals?.update}
                          onChange={(e: any) => {
                            handleChangeRoleData(
                              leasingResidential?.renewals,
                              'renewals',
                              'update',
                              e
                            )
                          }}
                        />
                      </div>
                    </td>
                    <td className='text-center ps-0'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          id='vacant0'
                          name='none'
                          checked={leasingResidential?.renewals?.delete}
                          onChange={(e: any) => {
                            handleChangeRoleData(
                              leasingResidential?.renewals,
                              'renewals',
                              'delete',
                              e
                            )
                          }}
                        />
                      </div>
                    </td>
                    <td className='text-center ps-0'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          id='vacant0'
                          name='none'
                          checked={leasingResidential?.renewals?.approve}
                          onChange={(e: any) => {
                            handleChangeRoleData(
                              leasingResidential?.renewals,
                              'renewals',
                              'approve',
                              e
                            )
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                </>
              )}

              {/* Terminations */}
              {/* {((leasingResidential?.terminations?.view_list ||
                leasingResidential?.terminations?.view_details ||
                leasingResidential?.terminations?.create ||
                leasingResidential?.terminations?.update ||
                leasingResidential?.terminations?.delete ||
                leasingResidential?.terminations?.approve) || (from == 'add'))  && ( */}
              {(((from == 'add-view' || from == 'view') &&
                leasingResidential?.terminations?.isRemoved == undefined &&
                !leasingResidential?.terminations?.view_list &&
                !leasingResidential?.terminations?.view_details &&
                !leasingResidential?.terminations?.create &&
                !leasingResidential?.terminations?.update &&
                !leasingResidential?.terminations?.delete &&
                !leasingResidential?.terminations?.approve) ||
                leasingResidential?.terminations?.view_list ||
                leasingResidential?.terminations?.view_details ||
                leasingResidential?.terminations?.create ||
                leasingResidential?.terminations?.update ||
                leasingResidential?.terminations?.delete ||
                leasingResidential?.terminations?.approve ||
                from == 'add' ||
                from == 'addOfEdit') && (
                <>
                  <tr
                    className='user-th'
                    style={{
                      borderBottom: 'none',
                      height: '35px',
                    }}
                  >
                    <td className='text-start min-w-25px user-th'></td>
                    <td className='text-start min-w-300px m-w-300 user-th'></td>

                    <td className='text-start min-w-100px text-capitalize user-th ps-0'>
                      {' '}
                      View List{' '}
                      <span>
                        <OverlayTrigger
                          placement='top'
                          delay={{show: 250, hide: 400}}
                          overlay={
                            <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                              {' '}
                              User can view the list of lease termination requests submitted.
                            </Tooltip>
                          }
                        >
                          <span>
                            {' '}
                            <AiOutlineExclamationCircle className='ms-2 cursor-pointer mb-1 exclam-circle' />
                          </span>
                        </OverlayTrigger>
                      </span>
                    </td>
                    <td
                      className='text-start min-w-125px text-capitalize user-th ps-0'
                      style={{paddingTop: '1.25rem', paddingBottom: '1.25rem'}}
                    >
                      {' '}
                      View Details{' '}
                      <span>
                        <OverlayTrigger
                          placement='top'
                          delay={{show: 250, hide: 400}}
                          overlay={
                            <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                              {' '}
                              User can access and view the details of a lease termination request.
                            </Tooltip>
                          }
                        >
                          <span>
                            {' '}
                            <AiOutlineExclamationCircle className='ms-2 cursor-pointer mb-1 exclam-circle' />
                          </span>
                        </OverlayTrigger>
                      </span>
                    </td>
                    <td className='text-start min-w-100px text-capitalize user-th ps-0'>
                      {' '}
                      Create{' '}
                      <span>
                        <OverlayTrigger
                          placement='top'
                          delay={{show: 250, hide: 400}}
                          overlay={
                            <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                              {' '}
                              User can initiate a lease termination request and terminate an active
                              lease. User will be available as an initiator and inputter in the
                              lease termination workflow except for the ‘Financials’ section.
                            </Tooltip>
                          }
                        >
                          <span>
                            {' '}
                            <AiOutlineExclamationCircle className='ms-2 cursor-pointer mb-1 exclam-circle' />
                          </span>
                        </OverlayTrigger>
                      </span>
                    </td>
                    <td className='text-start min-w-75px text-capitalize user-th ps-0'>
                      {' '}
                      Edit{' '}
                      <span>
                        <OverlayTrigger
                          placement='top'
                          delay={{show: 250, hide: 400}}
                          overlay={
                            <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                              {' '}
                              User can edit and cancel a lease renewal request. User will be
                              available as an inputter in the lease termination workflow except for
                              the ‘Financials’ section.
                            </Tooltip>
                          }
                        >
                          <span>
                            {' '}
                            <AiOutlineExclamationCircle className='ms-2 cursor-pointer mb-1 exclam-circle' />
                          </span>
                        </OverlayTrigger>
                      </span>
                    </td>
                    <td className='text-start min-w-100px text-capitalize user-th ps-0'>
                      {' '}
                      Delete{' '}
                      <span>
                        <OverlayTrigger
                          placement='top'
                          delay={{show: 250, hide: 400}}
                          overlay={
                            <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                              {' '}
                              User can delete a cancelled lease renewal request.
                            </Tooltip>
                          }
                        >
                          <span>
                            {' '}
                            <AiOutlineExclamationCircle className='ms-2 cursor-pointer mb-1 exclam-circle' />
                          </span>
                        </OverlayTrigger>
                      </span>
                    </td>
                    <td className='text-start min-w-100px text-capitalize user-th ps-0'>
                      {' '}
                      Approve{' '}
                      <span>
                        <OverlayTrigger
                          placement='top'
                          delay={{show: 250, hide: 400}}
                          overlay={
                            <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                              {' '}
                              User can approve a lease termination request before it is shared with
                              a tenant. User will be available as an approver in the lease
                              termination workflow and approve/edit a termination that is under
                              approval.
                            </Tooltip>
                          }
                        >
                          <span>
                            {' '}
                            <AiOutlineExclamationCircle className='ms-2 cursor-pointer mb-1 exclam-circle' />
                          </span>
                        </OverlayTrigger>
                      </span>
                    </td>
                  </tr>
                  <tr className='mt-2'>
                    <td className='text-start ps-0'>
                      {from != 'add' && from != 'view' && (
                        <img
                          src={redCross}
                          height={16}
                          width={16}
                          className='me-2 cursor-pointer'
                          onClick={() => {
                            let temp = localStorage.getItem('roleDataBackup')
                            let values = {...leasingResidential}
                            if (temp != null) {
                              let backup = JSON.parse(temp)
                              values.terminations =
                                backup?.featureList?.leasing_residential?.terminations
                              values.terminations.isRemoved = true
                            }
                            setLeasingResidential(values)
                          }}
                        />
                      )}
                    </td>

                    <td className='text-start ps-0 pe-5'>
                      <p className='user-main-head pe-2 mb-1' style={{fontWeight: '700'}}>
                        Terminations{' '}
                      </p>
                      <p className='user-sub-head mb-2'>
                        <i>Terminate existing leases for existing tenants.</i>
                      </p>
                    </td>
                    <td className='text-center ps-0'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          id='vacant0'
                          name='none'
                          checked={leasingResidential?.terminations?.view_list}
                          onChange={(e: any) => {
                            handleChangeRoleData(
                              leasingResidential?.terminations,
                              'terminations',
                              'view_list',
                              e
                            )
                          }}
                        />
                      </div>
                    </td>
                    <td className='text-center ps-0'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          id='vacant0'
                          name='none'
                          checked={leasingResidential?.terminations?.view_details}
                          onChange={(e: any) => {
                            handleChangeRoleData(
                              leasingResidential?.terminations,
                              'terminations',
                              'view_details',
                              e
                            )
                          }}
                        />
                      </div>
                    </td>

                    <td className='text-center ps-0'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          id='vacant0'
                          name='none'
                          checked={leasingResidential?.terminations?.create}
                          onChange={(e: any) => {
                            handleChangeRoleData(
                              leasingResidential?.terminations,
                              'terminations',
                              'create',
                              e
                            )
                          }}
                        />
                      </div>
                    </td>
                    <td className='text-center ps-0'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          id='vacant0'
                          name='none'
                          checked={leasingResidential?.terminations?.update}
                          onChange={(e: any) => {
                            handleChangeRoleData(
                              leasingResidential?.terminations,
                              'terminations',
                              'update',
                              e
                            )
                          }}
                        />
                      </div>
                    </td>
                    <td className='text-center ps-0'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          id='vacant0'
                          name='none'
                          checked={leasingResidential?.terminations?.delete}
                          onChange={(e: any) => {
                            handleChangeRoleData(
                              leasingResidential?.terminations,
                              'terminations',
                              'delete',
                              e
                            )
                          }}
                        />
                      </div>
                    </td>
                    <td className='text-center ps-0'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          id='vacant0'
                          name='none'
                          checked={leasingResidential?.terminations?.approve}
                          onChange={(e: any) => {
                            handleChangeRoleData(
                              leasingResidential?.terminations,
                              'terminations',
                              'approve',
                              e
                            )
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                </>
              )}

              {/* Lease Deletion */}
              {/* {(leasingResidential?.lease_deletion?.allow || from == 'add') && ( */}
              {(((from == 'add-view' || from == 'view') &&
                leasingResidential?.lease_deletion?.isRemoved == undefined &&
                !leasingResidential?.lease_deletion?.allow) ||
                leasingResidential?.lease_deletion?.allow ||
                from == 'add' ||
                from == 'addOfEdit') && (
                <>
                  <tr style={{borderBottom: 'none', height: '35px'}}>
                    <td className='text-start min-w-25px user-th'></td>
                    <td className='text-start min-w-300px m-w-300 user-th'></td>

                    <td className='text-start min-w-100px text-capitalize user-th ps-0'>
                      {' '}
                      Allow{' '}
                      <span>
                        <OverlayTrigger
                          placement='top'
                          delay={{show: 250, hide: 400}}
                          overlay={
                            <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                              {' '}
                              User can permanently remove deleted leases from records. Once removed,
                              records cannot be recovered.
                            </Tooltip>
                          }
                        >
                          <span>
                            {' '}
                            <AiOutlineExclamationCircle className='ms-2 cursor-pointer mb-1 exclam-circle' />
                          </span>
                        </OverlayTrigger>
                      </span>
                    </td>
                    <td className='text-start min-w-125px text-capitalize user-th'></td>
                    <td className='text-start min-w-100px text-capitalize user-th'></td>
                    <td className='text-start min-w-75px text-capitalize user-th'></td>
                    <td className='text-start min-w-100px text-capitalize user-th'></td>
                    <td className='text-start min-w-100px text-capitalize user-th'></td>
                  </tr>
                  <tr>
                    <td className='text-start ps-0'>
                      {from != 'add' && from != 'view' && (
                        <img
                          src={redCross}
                          height={16}
                          width={16}
                          className='me-2 cursor-pointer'
                          onClick={() => {
                            let temp = localStorage.getItem('roleDataBackup')
                            let values = {...leasingResidential}
                            if (temp != null) {
                              let backup = JSON.parse(temp)
                              values.lease_deletion =
                                backup?.featureList?.leasing_residential?.lease_deletion
                              values.lease_deletion.isRemoved = true
                            }
                            setLeasingResidential(values)
                          }}
                        />
                      )}
                    </td>

                    <td className='text-start ps-0 pe-5'>
                      <p className='user-main-head pe-2 mb-1' style={{fontWeight: '700'}}>
                        Lease Deletion{' '}
                      </p>
                      <p className='user-sub-head mb-2'>
                        <i>Permanently remove deleted leases from records.</i>
                      </p>
                    </td>
                    <td className='text-center ps-0'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          id='vacant0'
                          name='none'
                          checked={leasingResidential?.lease_deletion?.allow}
                          onChange={(e: any) => {
                            handleChangeRoleData(
                              leasingResidential?.lease_deletion,
                              'lease_deletion',
                              'allow',
                              e
                            )
                          }}
                        />
                      </div>
                    </td>
                    <td className='text-center ps-0'></td>
                    <td className='text-center ps-0'></td>
                    <td className='text-center ps-0'></td>
                    <td className='text-center ps-0'></td>
                    <td className='text-center ps-0'></td>
                  </tr>
                </>
              )}

              {/* Lease Financials */}
              {/* {((leasingResidential?.lease_financial?.view_list ||
                leasingResidential?.lease_financial?.view_details ||
                leasingResidential?.lease_financial?.create ||
                leasingResidential?.lease_financial?.update ||
                leasingResidential?.lease_financial?.delete ||
                leasingResidential?.lease_financial?.approve) || (from == 'add'))  && ( */}

              {(((from == 'add-view' || from == 'view') &&
                leasingResidential?.lease_financial?.isRemoved == undefined &&
                !leasingResidential?.lease_financial?.view_list &&
                !leasingResidential?.lease_financial?.view_details &&
                !leasingResidential?.lease_financial?.create &&
                !leasingResidential?.lease_financial?.update &&
                !leasingResidential?.lease_financial?.delete &&
                !leasingResidential?.lease_financial?.approve) ||
                leasingResidential?.lease_financial?.view_list ||
                leasingResidential?.lease_financial?.view_details ||
                leasingResidential?.lease_financial?.create ||
                leasingResidential?.lease_financial?.update ||
                leasingResidential?.lease_financial?.delete ||
                leasingResidential?.lease_financial?.approve ||
                from == 'add' ||
                from == 'addOfEdit') && (
                <>
                  <tr
                    className='user-th'
                    style={{
                      borderBottom: 'none',
                      height: '35px',
                    }}
                  >
                    <td className='text-start min-w-25px user-th'></td>
                    <td className='text-start min-w-300px m-w-300 user-th'></td>

                    <td className='text-start min-w-100px text-capitalize user-th ps-0'>
                      {' '}
                      View List{' '}
                      <span>
                        <OverlayTrigger
                          placement='top'
                          delay={{show: 250, hide: 400}}
                          overlay={
                            <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                              {' '}
                              User can view the list of payments within a lease agreement under
                              approval and post approval.
                            </Tooltip>
                          }
                        >
                          <span>
                            {' '}
                            <AiOutlineExclamationCircle className='ms-2 cursor-pointer mb-1 exclam-circle' />
                          </span>
                        </OverlayTrigger>
                      </span>
                    </td>
                    <td
                      className='text-start min-w-125px text-capitalize user-th ps-0'
                      style={{paddingTop: '1.25rem', paddingBottom: '1.25rem'}}
                    >
                      {' '}
                      View Details{' '}
                      <span>
                        <OverlayTrigger
                          placement='top'
                          delay={{show: 250, hide: 400}}
                          overlay={
                            <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                              {' '}
                              User can access and view the details of a payment within a lease
                              agreement under approval and post approval.
                            </Tooltip>
                          }
                        >
                          <span>
                            {' '}
                            <AiOutlineExclamationCircle className='ms-2 cursor-pointer mb-1 exclam-circle' />
                          </span>
                        </OverlayTrigger>
                      </span>
                    </td>
                    <td className='text-start min-w-100px text-capitalize user-th ps-0'>
                      {' '}
                      Create{' '}
                      <span>
                        <OverlayTrigger
                          placement='top'
                          delay={{show: 250, hide: 400}}
                          overlay={
                            <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                              {' '}
                              User can create a payment within a lease agreement under approval and
                              post approval. User will be available as an initiator and inputter for
                              the ‘Financials’ section in the lease agreements workflow.
                            </Tooltip>
                          }
                        >
                          <span>
                            {' '}
                            <AiOutlineExclamationCircle className='ms-2 cursor-pointer mb-1 exclam-circle' />
                          </span>
                        </OverlayTrigger>
                      </span>
                    </td>
                    <td className='text-start min-w-75px text-capitalize user-th ps-0'>
                      {' '}
                      Edit{' '}
                      <span>
                        <OverlayTrigger
                          placement='top'
                          delay={{show: 250, hide: 400}}
                          overlay={
                            <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                              {' '}
                              User can edit a payment within a lease agreement under approval or
                              post approval. User will be available as an inputter for the
                              ‘Financials’ section in the lease agreements workflow.
                            </Tooltip>
                          }
                        >
                          <span>
                            {' '}
                            <AiOutlineExclamationCircle className='ms-2 cursor-pointer mb-1 exclam-circle' />
                          </span>
                        </OverlayTrigger>
                      </span>
                    </td>
                    <td className='text-start min-w-100px text-capitalize user-th ps-0'>
                      {' '}
                      Delete{' '}
                      <span>
                        <OverlayTrigger
                          placement='top'
                          delay={{show: 250, hide: 400}}
                          overlay={
                            <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                              {' '}
                              User can delete a payment within a lease agreement under approval and
                              post approval.
                            </Tooltip>
                          }
                        >
                          <span>
                            {' '}
                            <AiOutlineExclamationCircle className='ms-2 cursor-pointer mb-1 exclam-circle' />
                          </span>
                        </OverlayTrigger>
                      </span>
                    </td>
                    <td className='text-start min-w-100px text-capitalize user-th ps-0'>
                      {' '}
                      Approve{' '}
                      <span>
                        <OverlayTrigger
                          placement='top'
                          delay={{show: 250, hide: 400}}
                          overlay={
                            <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                              {' '}
                              User can approve a payment before it is shared with a tenant. User
                              will be available as an approver for the ‘Financials’ section in the
                              lease agreements workflow and approve/edit the section when the lease
                              is under approval.
                            </Tooltip>
                          }
                        >
                          <span>
                            {' '}
                            <AiOutlineExclamationCircle className='ms-2 cursor-pointer mb-1 exclam-circle' />
                          </span>
                        </OverlayTrigger>
                      </span>
                    </td>
                  </tr>
                  <tr className='mt-2'>
                    <td className='text-start ps-0'>
                      {from != 'add' && from != 'view' && (
                        <img
                          src={redCross}
                          height={16}
                          width={16}
                          className='me-2 cursor-pointer'
                          onClick={() => {
                            let temp = localStorage.getItem('roleDataBackup')
                            let values = {...leasingResidential}
                            if (temp != null) {
                              let backup = JSON.parse(temp)
                              values.lease_financial =
                                backup?.featureList?.leasing_residential?.lease_financial
                              values.lease_financial.isRemoved = true
                            }
                            setLeasingResidential(values)
                          }}
                        />
                      )}
                    </td>

                    <td className='text-start ps-0 pe-5'>
                      <p className='user-main-head pe-2 mb-1' style={{fontWeight: '700'}}>
                        Lease Financials
                      </p>
                      <p className='user-sub-head mb-2'>
                        <i>Record and manage payments within each lease agreement.</i>
                      </p>
                    </td>
                    <td
                      className={
                        leasingResidential?.lease_financial?.invoicing?.view_list &&
                        leasingResidential?.lease_financial?.receipts?.view_list &&
                        leasingResidential?.lease_financial?.refunds?.view_list &&
                        leasingResidential?.lease_financial?.credit_notes?.view_list &&
                        leasingResidential?.lease_financial?.debit_notes?.view_list &&
                        leasingResidential?.lease_financial?.items?.view_list &&
                        leasingResidential?.lease_financial?.late_payment_fees?.view_list &&
                        leasingResidential?.lease_financial?.payment_method?.view_list &&
                        leasingResidential?.lease_financial?.payment_reminders?.view_list &&
                        leasingResidential?.lease_financial?.attachments?.view_list &&
                        leasingResidential?.lease_financial?.credit_notes?.view_list
                          ? 'text-center ps-0 role-test'
                          : 'text-center ps-0 all-select'
                      }
                    >
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          id='vacant0'
                          name='none'
                          checked={leasingResidential?.lease_financial?.view_list}
                          onChange={(e: any) => {
                            handleChangeRoleData(
                              leasingResidential?.lease_financial,
                              'lease_financial',
                              'view_list',
                              e
                            )
                          }}
                        />
                      </div>
                    </td>
                    <td
                      className={
                        leasingResidential?.lease_financial?.invoicing?.view_details &&
                        leasingResidential?.lease_financial?.receipts?.view_details &&
                        leasingResidential?.lease_financial?.refunds?.view_details &&
                        leasingResidential?.lease_financial?.credit_notes?.view_details &&
                        leasingResidential?.lease_financial?.debit_notes?.view_details &&
                        leasingResidential?.lease_financial?.items?.view_details &&
                        leasingResidential?.lease_financial?.late_payment_fees?.view_details &&
                        leasingResidential?.lease_financial?.payment_method?.view_details &&
                        leasingResidential?.lease_financial?.payment_reminders?.view_details &&
                        leasingResidential?.lease_financial?.attachments?.view_details &&
                        leasingResidential?.lease_financial?.credit_notes?.view_details
                          ? 'text-center ps-0 role-test'
                          : 'text-center ps-0 all-select'
                      }
                    >
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          id='vacant0'
                          name='none'
                          checked={leasingResidential?.lease_financial?.view_details}
                          onChange={(e: any) => {
                            handleChangeRoleData(
                              leasingResidential?.lease_financial,
                              'lease_financial',
                              'view_details',
                              e
                            )
                          }}
                        />
                      </div>
                    </td>

                    <td
                      className={
                        leasingResidential?.lease_financial?.invoicing?.create &&
                        leasingResidential?.lease_financial?.receipts?.create &&
                        leasingResidential?.lease_financial?.refunds?.create &&
                        leasingResidential?.lease_financial?.credit_notes?.create &&
                        leasingResidential?.lease_financial?.debit_notes?.view_details &&
                        leasingResidential?.lease_financial?.items?.create &&
                        leasingResidential?.lease_financial?.late_payment_fees?.create &&
                        leasingResidential?.lease_financial?.payment_method?.create &&
                        leasingResidential?.lease_financial?.payment_reminders?.create &&
                        leasingResidential?.lease_financial?.attachments?.create &&
                        leasingResidential?.lease_financial?.credit_notes?.create
                          ? 'text-center ps-0 role-test'
                          : 'text-center ps-0 all-select'
                      }
                    >
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          id='vacant0'
                          name='none'
                          checked={leasingResidential?.lease_financial?.create}
                          onChange={(e: any) => {
                            handleChangeRoleData(
                              leasingResidential?.lease_financial,
                              'lease_financial',
                              'create',
                              e
                            )
                          }}
                        />
                      </div>
                    </td>
                    <td
                      className={
                        leasingResidential?.lease_financial?.invoicing?.update &&
                        leasingResidential?.lease_financial?.receipts?.update &&
                        leasingResidential?.lease_financial?.refunds?.update &&
                        leasingResidential?.lease_financial?.credit_notes?.update &&
                        leasingResidential?.lease_financial?.debit_notes?.update &&
                        leasingResidential?.lease_financial?.items?.update &&
                        leasingResidential?.lease_financial?.late_payment_fees?.update &&
                        leasingResidential?.lease_financial?.payment_method?.update &&
                        leasingResidential?.lease_financial?.payment_reminders?.update &&
                        leasingResidential?.lease_financial?.attachments?.update &&
                        leasingResidential?.lease_financial?.credit_notes?.update
                          ? 'text-center ps-0 role-test'
                          : 'text-center ps-0 all-select'
                      }
                    >
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          id='vacant0'
                          name='none'
                          checked={leasingResidential?.lease_financial?.update}
                          onChange={(e: any) => {
                            handleChangeRoleData(
                              leasingResidential?.lease_financial,
                              'lease_financial',
                              'update',
                              e
                            )
                          }}
                        />
                      </div>
                    </td>
                    <td
                      className={
                        leasingResidential?.lease_financial?.invoicing?.delete &&
                        leasingResidential?.lease_financial?.receipts?.delete &&
                        leasingResidential?.lease_financial?.refunds?.delete &&
                        leasingResidential?.lease_financial?.credit_notes?.delete &&
                        leasingResidential?.lease_financial?.debit_notes?.delete &&
                        leasingResidential?.lease_financial?.items?.delete &&
                        leasingResidential?.lease_financial?.late_payment_fees?.delete &&
                        leasingResidential?.lease_financial?.payment_method?.delete &&
                        leasingResidential?.lease_financial?.payment_reminders?.delete &&
                        leasingResidential?.lease_financial?.attachments?.delete &&
                        leasingResidential?.lease_financial?.credit_notes?.delete
                          ? 'text-center ps-0 role-test'
                          : 'text-center ps-0 all-select'
                      }
                    >
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          id='vacant0'
                          name='none'
                          checked={leasingResidential?.lease_financial?.delete}
                          onChange={(e: any) => {
                            handleChangeRoleData(
                              leasingResidential?.lease_financial,
                              'lease_financial',
                              'delete',
                              e
                            )
                          }}
                        />
                      </div>
                    </td>
                    <td className='text-center ps-0'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          id='vacant0'
                          name='none'
                          checked={leasingResidential?.lease_financial?.approve}
                          onChange={(e: any) => {
                            handleChangeRoleData(
                              leasingResidential?.lease_financial,
                              'lease_financial',
                              'approve',
                              e
                            )
                          }}
                        />
                      </div>
                    </td>
                  </tr>

                  {/* Invoicing */}
                  <tr>
                    <td className='text-start ps-0'></td>
                    <td className='text-start ps-0 pe-5'>
                      <p className='user-sub2 pe-2 mb-0 ps-5 ms-3' style={{fontWeight: '600'}}>
                        {' '}
                        - Invoicing
                      </p>
                      <p className='user-sub-head mb-2 ps-5 ms-3'>
                        <i>Generate or upload invoices against the recorded payment.</i>
                      </p>
                    </td>
                    <td className='text-center ps-0'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                        <input
                          className='form-check-input'
                          type='radio'
                          id='vacant0'
                          name='invoicing-list'
                          checked={leasingResidential?.lease_financial?.invoicing?.view_list}
                          onClick={(e: any) => {
                            handleChangeRoleData(
                              leasingResidential?.lease_financial?.invoicing,
                              'invoicing',
                              'view_list',
                              e
                            )
                          }}
                        />
                      </div>
                    </td>
                    <td className='text-center ps-0'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                        <input
                          className='form-check-input'
                          type='radio'
                          id='vacant0'
                          name='invoicing-details'
                          checked={leasingResidential?.lease_financial?.invoicing?.view_details}
                          onClick={(e: any) => {
                            handleChangeRoleData(
                              leasingResidential?.lease_financial?.invoicing,
                              'invoicing',
                              'view_details',
                              e
                            )
                          }}
                        />
                      </div>
                    </td>
                    <td className='text-center ps-0'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                        <input
                          className='form-check-input'
                          type='radio'
                          id='vacant0'
                          name='invoicing-create'
                          checked={leasingResidential?.lease_financial?.invoicing?.create}
                          onClick={(e: any) => {
                            handleChangeRoleData(
                              leasingResidential?.lease_financial?.invoicing,
                              'invoicing',
                              'create',
                              e
                            )
                          }}
                        />
                      </div>
                    </td>
                    <td className='text-center ps-0'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                        <input
                          className='form-check-input'
                          type='radio'
                          id='vacant0'
                          name='invoicing-update'
                          checked={leasingResidential?.lease_financial?.invoicing?.update}
                          onClick={(e: any) => {
                            handleChangeRoleData(
                              leasingResidential?.lease_financial?.invoicing,
                              'invoicing',
                              'update',
                              e
                            )
                          }}
                        />
                      </div>
                    </td>
                    <td className='text-center ps-0'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                        <input
                          className='form-check-input'
                          type='radio'
                          id='vacant0'
                          name='invoicing-delete'
                          checked={leasingResidential?.lease_financial?.invoicing?.delete}
                          onClick={(e: any) => {
                            handleChangeRoleData(
                              leasingResidential?.lease_financial?.invoicing,
                              'invoicing',
                              'delete',
                              e
                            )
                          }}
                        />
                      </div>
                    </td>
                    <td className='text-center ps-0'></td>
                  </tr>
                  {/* Receipts */}
                  <tr>
                    <td className='text-start ps-0'></td>
                    <td className='text-start ps-0 pe-5'>
                      <p className='user-sub2 pe-2 mb-0 ps-5 ms-3' style={{fontWeight: '600'}}>
                        {' '}
                        - Receipts
                      </p>
                      <p className='user-sub-head mb-2 ps-5 ms-3'>
                        <i>Generate or upload receipts against invoices for payments received.</i>
                      </p>
                    </td>
                    <td className='text-center ps-0'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                        <input
                          className='form-check-input'
                          type='radio'
                          id='vacant0'
                          name='receipt-list'
                          checked={leasingResidential?.lease_financial?.receipts?.view_list}
                          onClick={(e: any) => {
                            handleChangeRoleData(
                              leasingResidential?.lease_financial?.receipts,
                              'receipts',
                              'view_list',
                              e
                            )
                          }}
                        />
                      </div>
                    </td>
                    <td className='text-center ps-0'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                        <input
                          className='form-check-input'
                          type='radio'
                          id='vacant0'
                          name='receipt-details'
                          checked={leasingResidential?.lease_financial?.receipts?.view_details}
                          onClick={(e: any) => {
                            handleChangeRoleData(
                              leasingResidential?.lease_financial?.receipts,
                              'receipts',
                              'view_details',
                              e
                            )
                          }}
                        />
                      </div>
                    </td>
                    <td className='text-center ps-0'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                        <input
                          className='form-check-input'
                          type='radio'
                          id='vacant0'
                          name='receipts-create'
                          checked={leasingResidential?.lease_financial?.receipts?.create}
                          onClick={(e: any) => {
                            handleChangeRoleData(
                              leasingResidential?.lease_financial?.receipts,
                              'receipts',
                              'create',
                              e
                            )
                          }}
                        />
                      </div>
                    </td>
                    <td className='text-center ps-0'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                        <input
                          className='form-check-input'
                          type='radio'
                          id='vacant0'
                          name='receipts-update'
                          checked={leasingResidential?.lease_financial?.receipts?.update}
                          onClick={(e: any) => {
                            handleChangeRoleData(
                              leasingResidential?.lease_financial?.receipts,
                              'receipts',
                              'update',
                              e
                            )
                          }}
                        />
                      </div>
                    </td>
                    <td className='text-center ps-0'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                        <input
                          className='form-check-input'
                          type='radio'
                          id='vacant0'
                          name='receipts-delete'
                          checked={leasingResidential?.lease_financial?.receipts?.delete}
                          onClick={(e: any) => {
                            handleChangeRoleData(
                              leasingResidential?.lease_financial?.receipts,
                              'receipts',
                              'delete',
                              e
                            )
                          }}
                        />
                      </div>
                    </td>
                    <td className='text-center ps-0'></td>
                  </tr>
                  {/* Refunds */}
                  <tr>
                    <td className='text-start ps-0'></td>
                    <td className='text-start ps-0 pe-5'>
                      <p className='user-sub2 pe-2 mb-0 ps-5 ms-3' style={{fontWeight: '600'}}>
                        {' '}
                        - Refunds
                      </p>
                      <p className='user-sub-head mb-2 ps-5 ms-3'>
                        <i>Generate or upload refund receipts for received payments.</i>
                      </p>
                    </td>
                    <td className='text-center ps-0'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                        <input
                          className='form-check-input'
                          type='radio'
                          id='vacant0'
                          name='refund-list'
                          checked={leasingResidential?.lease_financial?.refunds?.view_list}
                          onClick={(e: any) => {
                            handleChangeRoleData(
                              leasingResidential?.lease_financial?.refunds,
                              'refunds',
                              'view_list',
                              e
                            )
                          }}
                        />
                      </div>
                    </td>
                    <td className='text-center ps-0'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                        <input
                          className='form-check-input'
                          type='radio'
                          id='vacant0'
                          name='refund-details'
                          checked={leasingResidential?.lease_financial?.refunds?.view_details}
                          onClick={(e: any) => {
                            handleChangeRoleData(
                              leasingResidential?.lease_financial?.refunds,
                              'refunds',
                              'view_details',
                              e
                            )
                          }}
                        />
                      </div>
                    </td>
                    <td className='text-center ps-0'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                        <input
                          className='form-check-input'
                          type='radio'
                          id='vacant0'
                          name='refunds-create'
                          checked={leasingResidential?.lease_financial?.refunds?.create}
                          onClick={(e: any) => {
                            handleChangeRoleData(
                              leasingResidential?.lease_financial?.refunds,
                              'refunds',
                              'create',
                              e
                            )
                          }}
                        />
                      </div>
                    </td>
                    <td className='text-center ps-0'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                        <input
                          className='form-check-input'
                          type='radio'
                          id='vacant0'
                          name='refunds-update'
                          checked={leasingResidential?.lease_financial?.refunds?.update}
                          onClick={(e: any) => {
                            handleChangeRoleData(
                              leasingResidential?.lease_financial?.refunds,
                              'refunds',
                              'update',
                              e
                            )
                          }}
                        />
                      </div>
                    </td>
                    <td className='text-center ps-0'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                        <input
                          className='form-check-input'
                          type='radio'
                          id='vacant0'
                          name='refunds-delete'
                          checked={leasingResidential?.lease_financial?.refunds?.delete}
                          onClick={(e: any) => {
                            handleChangeRoleData(
                              leasingResidential?.lease_financial?.refunds,
                              'refunds',
                              'delete',
                              e
                            )
                          }}
                        />
                      </div>
                    </td>
                    <td className='text-center ps-0'></td>
                  </tr>
                  {/* Credit Notes */}
                  <tr>
                    <td className='text-start ps-0'></td>
                    <td className='text-start ps-0 pe-5'>
                      <p className='user-sub2 pe-2 mb-0 ps-5 ms-3' style={{fontWeight: '600'}}>
                        {' '}
                        - Credit Notes
                      </p>
                      <p className='user-sub-head mb-2 ps-5 ms-3'>
                        <i>
                          Generate or upload credit notes against invoices for undercharged amounts
                          or for cancelling invoices.
                        </i>
                      </p>
                    </td>
                    <td className='text-center ps-0'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                        <input
                          className='form-check-input'
                          type='radio'
                          id='vacant0'
                          name='credit_notes-list'
                          checked={leasingResidential?.lease_financial?.credit_notes?.view_list}
                          onClick={(e: any) => {
                            handleChangeRoleData(
                              leasingResidential?.lease_financial?.credit_notes,
                              'credit_notes',
                              'view_list',
                              e
                            )
                          }}
                        />
                      </div>
                    </td>
                    <td className='text-center ps-0'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                        <input
                          className='form-check-input'
                          type='radio'
                          id='vacant0'
                          name='credit_notes-details'
                          checked={leasingResidential?.lease_financial?.credit_notes?.view_details}
                          onClick={(e: any) => {
                            handleChangeRoleData(
                              leasingResidential?.lease_financial?.credit_notes,
                              'credit_notes',
                              'view_details',
                              e
                            )
                          }}
                        />
                      </div>
                    </td>
                    <td className='text-center ps-0'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                        <input
                          className='form-check-input'
                          type='radio'
                          id='vacant0'
                          name='credit_notes-create'
                          checked={leasingResidential?.lease_financial?.credit_notes?.create}
                          onClick={(e: any) => {
                            handleChangeRoleData(
                              leasingResidential?.lease_financial?.credit_notes,
                              'credit_notes',
                              'create',
                              e
                            )
                          }}
                        />
                      </div>
                    </td>
                    <td className='text-center ps-0'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                        <input
                          className='form-check-input'
                          type='radio'
                          id='vacant0'
                          name='credit_notes-update'
                          checked={leasingResidential?.lease_financial?.credit_notes?.update}
                          onClick={(e: any) => {
                            handleChangeRoleData(
                              leasingResidential?.lease_financial?.credit_notes,
                              'credit_notes',
                              'update',
                              e
                            )
                          }}
                        />
                      </div>
                    </td>
                    <td className='text-center ps-0'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                        <input
                          className='form-check-input'
                          type='radio'
                          id='vacant0'
                          name='credit_notes-delete'
                          checked={leasingResidential?.lease_financial?.credit_notes?.delete}
                          onClick={(e: any) => {
                            handleChangeRoleData(
                              leasingResidential?.lease_financial?.credit_notes,
                              'credit_notes',
                              'delete',
                              e
                            )
                          }}
                        />
                      </div>
                    </td>
                    <td className='text-center ps-0'></td>
                  </tr>

                  {/* Debit Notes */}
                  <tr>
                    <td className='text-start ps-0'></td>
                    <td className='text-start ps-0 pe-5'>
                      <p className='user-sub2 pe-2 mb-0 ps-5 ms-3' style={{fontWeight: '600'}}>
                        {' '}
                        - Debit Notes
                      </p>
                      <p className='user-sub-head mb-2 ps-5 ms-3'>
                        <i>
                          Generate or upload debit notes against invoices for undercharged amounts
                          or for cancelling invoices.
                        </i>
                      </p>
                    </td>
                    <td className='text-center ps-0'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                        <input
                          className='form-check-input'
                          type='radio'
                          id='vacant0'
                          name='debit_notes-list'
                          checked={leasingResidential?.lease_financial?.debit_notes?.view_list}
                          onClick={(e: any) => {
                            handleChangeRoleData(
                              leasingResidential?.lease_financial?.debit_notes,
                              'debit_notes',
                              'view_list',
                              e
                            )
                          }}
                        />
                      </div>
                    </td>
                    <td className='text-center ps-0'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                        <input
                          className='form-check-input'
                          type='radio'
                          id='vacant0'
                          name='debit_notes-details'
                          checked={leasingResidential?.lease_financial?.debit_notes?.view_details}
                          onClick={(e: any) => {
                            handleChangeRoleData(
                              leasingResidential?.lease_financial?.debit_notes,
                              'debit_notes',
                              'view_details',
                              e
                            )
                          }}
                        />
                      </div>
                    </td>
                    <td className='text-center ps-0'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                        <input
                          className='form-check-input'
                          type='radio'
                          id='vacant0'
                          name='debit_notes-create'
                          checked={leasingResidential?.lease_financial?.debit_notes?.create}
                          onClick={(e: any) => {
                            handleChangeRoleData(
                              leasingResidential?.lease_financial?.debit_notes,
                              'debit_notes',
                              'create',
                              e
                            )
                          }}
                        />
                      </div>
                    </td>
                    <td className='text-center ps-0'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                        <input
                          className='form-check-input'
                          type='radio'
                          id='vacant0'
                          name='debit_notes-update'
                          checked={leasingResidential?.lease_financial?.debit_notes?.update}
                          onClick={(e: any) => {
                            handleChangeRoleData(
                              leasingResidential?.lease_financial?.debit_notes,
                              'debit_notes',
                              'update',
                              e
                            )
                          }}
                        />
                      </div>
                    </td>
                    <td className='text-center ps-0'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                        <input
                          className='form-check-input'
                          type='radio'
                          id='vacant0'
                          name='debit_notes-delete'
                          checked={leasingResidential?.lease_financial?.debit_notes?.delete}
                          onClick={(e: any) => {
                            handleChangeRoleData(
                              leasingResidential?.lease_financial?.debit_notes,
                              'debit_notes',
                              'delete',
                              e
                            )
                          }}
                        />
                      </div>
                    </td>
                    <td className='text-center ps-0'></td>
                  </tr>

                  {/*Items */}
                  <tr>
                    <td className='text-start ps-0'></td>
                    <td className='text-start ps-0 pe-5'>
                      <p className='user-sub2 pe-2 mb-0 ps-5 ms-3' style={{fontWeight: '600'}}>
                        {' '}
                        - Items
                      </p>
                      <p className='user-sub-head mb-2 ps-5 ms-3'>
                        <i>
                          Add and select item categories and define the amounts and quantities for
                          each item added to your payment record.
                        </i>
                      </p>
                    </td>
                    <td className='text-center ps-0'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                        <input
                          className='form-check-input'
                          type='radio'
                          id='vacant0'
                          name='items-list'
                          checked={leasingResidential?.lease_financial?.items?.view_list}
                          onClick={(e: any) => {
                            handleChangeRoleData(
                              leasingResidential?.lease_financial?.items,
                              'items',
                              'view_list',
                              e
                            )
                          }}
                        />
                      </div>
                    </td>
                    <td className='text-center ps-0'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                        <input
                          className='form-check-input'
                          type='radio'
                          id='vacant0'
                          name='items-details'
                          checked={leasingResidential?.lease_financial?.items?.view_details}
                          onClick={(e: any) => {
                            handleChangeRoleData(
                              leasingResidential?.lease_financial?.items,
                              'items',
                              'view_details',
                              e
                            )
                          }}
                        />
                      </div>
                    </td>
                    <td className='text-center ps-0'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                        <input
                          className='form-check-input'
                          type='radio'
                          id='vacant0'
                          name='items-create'
                          checked={leasingResidential?.lease_financial?.items?.create}
                          onClick={(e: any) => {
                            handleChangeRoleData(
                              leasingResidential?.lease_financial?.items,
                              'items',
                              'create',
                              e
                            )
                          }}
                        />
                      </div>
                    </td>
                    <td className='text-center ps-0'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                        <input
                          className='form-check-input'
                          type='radio'
                          id='vacant0'
                          name='items-update'
                          checked={leasingResidential?.lease_financial?.items?.update}
                          onClick={(e: any) => {
                            handleChangeRoleData(
                              leasingResidential?.lease_financial?.items,
                              'items',
                              'update',
                              e
                            )
                          }}
                        />
                      </div>
                    </td>
                    <td className='text-center ps-0'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                        <input
                          className='form-check-input'
                          type='radio'
                          id='vacant0'
                          name='items-delete'
                          checked={leasingResidential?.lease_financial?.items?.delete}
                          onClick={(e: any) => {
                            handleChangeRoleData(
                              leasingResidential?.lease_financial?.items,
                              'items',
                              'delete',
                              e
                            )
                          }}
                        />
                      </div>
                    </td>
                    <td className='text-center ps-0'></td>
                  </tr>

                  {/* VAT */}
                  <tr>
                    <td className='text-start ps-0'></td>
                    <td className='text-start ps-0 pe-5'>
                      <p className='user-sub2 pe-2 mb-0 ps-5 ms-3' style={{fontWeight: '600'}}>
                        {' '}
                        - VAT
                      </p>
                      <p className='user-sub-head mb-2 ps-5 ms-3'>
                        <i>
                          Set and charge VAT to the amount of each item category added to your
                          payment.
                        </i>
                      </p>
                    </td>
                    <td className='text-center ps-0'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                        <input
                          className='form-check-input'
                          type='radio'
                          id='vacant0'
                          name='vat-view_list'
                          checked={leasingResidential?.lease_financial?.vat?.view_list}
                          onClick={(e: any) => {
                            handleChangeRoleData(
                              leasingResidential?.lease_financial?.vat,
                              'vat',
                              'view_list',
                              e
                            )
                          }}
                        />
                      </div>
                    </td>
                    <td className='text-center ps-0'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                        <input
                          className='form-check-input'
                          type='radio'
                          id='vacant0'
                          name='vat-view_details'
                          checked={leasingResidential?.lease_financial?.vat?.view_details}
                          onClick={(e: any) => {
                            handleChangeRoleData(
                              leasingResidential?.lease_financial?.vat,
                              'vat',
                              'view_details',
                              e
                            )
                          }}
                        />
                      </div>
                    </td>
                    <td className='text-center ps-0'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                        <input
                          className='form-check-input'
                          type='radio'
                          id='vacant0'
                          name='vat-create'
                          checked={leasingResidential?.lease_financial?.vat?.create}
                          onClick={(e: any) => {
                            handleChangeRoleData(
                              leasingResidential?.lease_financial?.vat,
                              'vat',
                              'create',
                              e
                            )
                          }}
                        />
                      </div>
                    </td>
                    <td className='text-center ps-0'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                        <input
                          className='form-check-input'
                          type='radio'
                          id='vacant0'
                          name='vat-update'
                          checked={leasingResidential?.lease_financial?.vat?.update}
                          onClick={(e: any) => {
                            handleChangeRoleData(
                              leasingResidential?.lease_financial?.vat,
                              'vat',
                              'update',
                              e
                            )
                          }}
                        />
                      </div>
                    </td>
                    <td className='text-center ps-0'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                        <input
                          className='form-check-input'
                          type='radio'
                          id='vacant0'
                          name='vat-delete'
                          checked={leasingResidential?.lease_financial?.vat?.delete}
                          onClick={(e: any) => {
                            handleChangeRoleData(
                              leasingResidential?.lease_financial?.vat,
                              'vat',
                              'delete',
                              e
                            )
                          }}
                        />
                      </div>
                    </td>
                    <td className='text-center ps-0'></td>
                  </tr>

                  {/* Late Payment Fees */}
                  <tr>
                    <td className='text-start ps-0'></td>
                    <td className='text-start ps-0 pe-5'>
                      <p className='user-sub2 pe-2 mb-0 ps-5 ms-3' style={{fontWeight: '600'}}>
                        {' '}
                        - Late Payment Fees
                      </p>
                      <p className='user-sub-head mb-2 ps-5 ms-3'>
                        <i>Apply late payment fees for each item category added to your payment.</i>
                      </p>
                    </td>
                    <td className='text-center ps-0'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                        <input
                          className='form-check-input'
                          type='radio'
                          id='vacant0'
                          name='late-payment-view-list'
                          checked={
                            leasingResidential?.lease_financial?.late_payment_fees?.view_list
                          }
                          onClick={(e: any) => {
                            handleChangeRoleData(
                              leasingResidential?.lease_financial?.late_payment_fees,
                              'late_payment_fees',
                              'view_list',
                              e
                            )
                          }}
                        />
                      </div>
                    </td>
                    <td className='text-center ps-0'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                        <input
                          className='form-check-input'
                          type='radio'
                          id='vacant0'
                          name='late-payment-view-details'
                          checked={
                            leasingResidential?.lease_financial?.late_payment_fees?.view_details
                          }
                          onClick={(e: any) => {
                            handleChangeRoleData(
                              leasingResidential?.lease_financial?.late_payment_fees,
                              'late_payment_fees',
                              'view_details',
                              e
                            )
                          }}
                        />
                      </div>
                    </td>
                    <td className='text-center ps-0'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                        <input
                          className='form-check-input'
                          type='radio'
                          id='vacant0'
                          name='late-payment-create'
                          checked={leasingResidential?.lease_financial?.late_payment_fees?.create}
                          onClick={(e: any) => {
                            handleChangeRoleData(
                              leasingResidential?.lease_financial?.late_payment_fees,
                              'late_payment_fees',
                              'create',
                              e
                            )
                          }}
                        />
                      </div>
                    </td>
                    <td className='text-center ps-0'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                        <input
                          className='form-check-input'
                          type='radio'
                          id='vacant0'
                          name='late-payment-update'
                          checked={leasingResidential?.lease_financial?.late_payment_fees?.update}
                          onClick={(e: any) => {
                            handleChangeRoleData(
                              leasingResidential?.lease_financial?.late_payment_fees,
                              'late_payment_fees',
                              'update',
                              e
                            )
                          }}
                        />
                      </div>
                    </td>
                    <td className='text-center ps-0'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                        <input
                          className='form-check-input'
                          type='radio'
                          id='vacant0'
                          name='late-payment-delete'
                          checked={leasingResidential?.lease_financial?.late_payment_fees?.delete}
                          onClick={(e: any) => {
                            handleChangeRoleData(
                              leasingResidential?.lease_financial?.late_payment_fees,
                              'late_payment_fees',
                              'delete',
                              e
                            )
                          }}
                        />
                      </div>
                    </td>
                    <td className='text-center ps-0'></td>
                  </tr>

                  {/* Payment Method */}
                  <tr>
                    <td className='text-start ps-0'></td>
                    <td className='text-start ps-0 pe-5'>
                      <p className='user-sub2 pe-2 mb-0 ps-5 ms-3' style={{fontWeight: '600'}}>
                        {' '}
                        - Payment Method
                      </p>
                      <p className='user-sub-head mb-2 ps-5 ms-3'>
                        <i>
                          Select the payment method you want the recipient to make a payment with.
                        </i>
                      </p>
                    </td>
                    <td className='text-center ps-0'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                        <input
                          className='form-check-input'
                          type='radio'
                          id='vacant0'
                          name='payment-method-view-list'
                          checked={leasingResidential?.lease_financial?.payment_method?.view_list}
                          onClick={(e: any) => {
                            handleChangeRoleData(
                              leasingResidential?.lease_financial?.payment_method,
                              'payment_method',
                              'view_list',
                              e
                            )
                          }}
                        />
                      </div>
                    </td>
                    <td className='text-center ps-0'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                        <input
                          className='form-check-input'
                          type='radio'
                          id='vacant0'
                          name='payment-method-view-details'
                          checked={
                            leasingResidential?.lease_financial?.payment_method?.view_details
                          }
                          onClick={(e: any) => {
                            handleChangeRoleData(
                              leasingResidential?.lease_financial?.payment_method,
                              'payment_method',
                              'view_details',
                              e
                            )
                          }}
                        />
                      </div>
                    </td>
                    <td className='text-center ps-0'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                        <input
                          className='form-check-input'
                          type='radio'
                          id='vacant0'
                          name='payment-method-create'
                          checked={leasingResidential?.lease_financial?.payment_method?.create}
                          onClick={(e: any) => {
                            handleChangeRoleData(
                              leasingResidential?.lease_financial?.payment_method,
                              'payment_method',
                              'create',
                              e
                            )
                          }}
                        />
                      </div>
                    </td>
                    <td className='text-center ps-0'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                        <input
                          className='form-check-input'
                          type='radio'
                          id='vacant0'
                          name='payment-method-update'
                          checked={leasingResidential?.lease_financial?.payment_method?.update}
                          onClick={(e: any) => {
                            handleChangeRoleData(
                              leasingResidential?.lease_financial?.payment_method,
                              'payment_method',
                              'update',
                              e
                            )
                          }}
                        />
                      </div>
                    </td>
                    <td className='text-center ps-0'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                        <input
                          className='form-check-input'
                          type='radio'
                          id='vacant0'
                          name='payment-method-delete'
                          checked={leasingResidential?.lease_financial?.payment_method?.delete}
                          onClick={(e: any) => {
                            handleChangeRoleData(
                              leasingResidential?.lease_financial?.payment_method,
                              'payment_method',
                              'delete',
                              e
                            )
                          }}
                        />
                      </div>
                    </td>
                    <td className='text-center ps-0'></td>
                  </tr>

                  {/*  Payment Reminders */}
                  <tr>
                    <td className='text-start ps-0'></td>
                    <td className='text-start ps-0 pe-5'>
                      <p className='user-sub2 pe-2 mb-0 ps-5 ms-3' style={{fontWeight: '600'}}>
                        {' '}
                        - Payment Reminders
                      </p>
                      <p className='user-sub-head mb-2 ps-5 ms-3'>
                        <i>
                          Set payment reminders against the payment due date to remind recipients on
                          their payment schedule.
                        </i>
                      </p>
                    </td>
                    <td className='text-center ps-0'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                        <input
                          className='form-check-input'
                          type='radio'
                          id='vacant0'
                          name='payment-reminders-view-list'
                          checked={
                            leasingResidential?.lease_financial?.payment_reminders?.view_list
                          }
                          onClick={(e: any) => {
                            handleChangeRoleData(
                              leasingResidential?.lease_financial?.payment_reminders,
                              'payment_reminders',
                              'view_list',
                              e
                            )
                          }}
                        />
                      </div>
                    </td>
                    <td className='text-center ps-0'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                        <input
                          className='form-check-input'
                          type='radio'
                          id='vacant0'
                          name='payment-reminders-view-details'
                          checked={
                            leasingResidential?.lease_financial?.payment_reminders?.view_details
                          }
                          onClick={(e: any) => {
                            handleChangeRoleData(
                              leasingResidential?.lease_financial?.payment_reminders,
                              'payment_reminders',
                              'view_details',
                              e
                            )
                          }}
                        />
                      </div>
                    </td>
                    <td className='text-center ps-0'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                        <input
                          className='form-check-input'
                          type='radio'
                          id='vacant0'
                          name='payment-reminders-create'
                          checked={leasingResidential?.lease_financial?.payment_reminders?.create}
                          onClick={(e: any) => {
                            handleChangeRoleData(
                              leasingResidential?.lease_financial?.payment_reminders,
                              'payment_reminders',
                              'create',
                              e
                            )
                          }}
                        />
                      </div>
                    </td>
                    <td className='text-center ps-0'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                        <input
                          className='form-check-input'
                          type='radio'
                          id='vacant0'
                          name='payment-reminders-update'
                          checked={leasingResidential?.lease_financial?.payment_reminders?.update}
                          onClick={(e: any) => {
                            handleChangeRoleData(
                              leasingResidential?.lease_financial?.payment_reminders,
                              'payment_reminders',
                              'update',
                              e
                            )
                          }}
                        />
                      </div>
                    </td>
                    <td className='text-center ps-0'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                        <input
                          className='form-check-input'
                          type='radio'
                          id='vacant0'
                          name='payment-reminders-delete'
                          checked={leasingResidential?.lease_financial?.payment_reminders?.delete}
                          onClick={(e: any) => {
                            handleChangeRoleData(
                              leasingResidential?.lease_financial?.payment_reminders,
                              'payment_reminders',
                              'delete',
                              e
                            )
                          }}
                        />
                      </div>
                    </td>
                    <td className='text-center ps-0'></td>
                  </tr>

                  {/*  Attachments */}
                  <tr>
                    <td className='text-start ps-0'></td>
                    <td className='text-start ps-0 pe-5'>
                      <p className='user-sub2 pe-2 mb-0 ps-5 ms-3' style={{fontWeight: '600'}}>
                        {' '}
                        - Attachments
                      </p>
                      <p className='user-sub-head mb-2 ps-5 ms-3'>
                        <i>Attach documents relevant to the overall payment record.</i>
                      </p>
                    </td>
                    <td className='text-center ps-0'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                        <input
                          className='form-check-input'
                          type='radio'
                          id='vacant0'
                          name='attachments-view-list'
                          checked={leasingResidential?.lease_financial?.attachments?.view_list}
                          onClick={(e: any) => {
                            handleChangeRoleData(
                              leasingResidential?.lease_financial?.attachments,
                              'attachments',
                              'view_list',
                              e
                            )
                          }}
                        />
                      </div>
                    </td>
                    <td className='text-center ps-0'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                        <input
                          className='form-check-input'
                          type='radio'
                          id='vacant0'
                          name='attachments-view-details'
                          checked={leasingResidential?.lease_financial?.attachments?.view_details}
                          onClick={(e: any) => {
                            handleChangeRoleData(
                              leasingResidential?.lease_financial?.attachments,
                              'attachments',
                              'view_details',
                              e
                            )
                          }}
                        />
                      </div>
                    </td>
                    <td className='text-center ps-0'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                        <input
                          className='form-check-input'
                          type='radio'
                          id='vacant0'
                          name='attachments-create'
                          checked={leasingResidential?.lease_financial?.attachments?.create}
                          onClick={(e: any) => {
                            handleChangeRoleData(
                              leasingResidential?.lease_financial?.attachments,
                              'attachments',
                              'create',
                              e
                            )
                          }}
                        />
                      </div>
                    </td>
                    <td className='text-center ps-0'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                        <input
                          className='form-check-input'
                          type='radio'
                          id='vacant0'
                          name='attachments-update'
                          checked={leasingResidential?.lease_financial?.attachments?.update}
                          onClick={(e: any) => {
                            handleChangeRoleData(
                              leasingResidential?.lease_financial?.attachments,
                              'attachments',
                              'update',
                              e
                            )
                          }}
                        />
                      </div>
                    </td>
                    <td className='text-center ps-0'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                        <input
                          className='form-check-input'
                          type='radio'
                          id='vacant0'
                          name='attachments-delete'
                          checked={leasingResidential?.lease_financial?.attachments?.delete}
                          onClick={(e: any) => {
                            handleChangeRoleData(
                              leasingResidential?.lease_financial?.attachments,
                              'attachments',
                              'delete',
                              e
                            )
                          }}
                        />
                      </div>
                    </td>
                    <td className='text-center ps-0'></td>
                  </tr>

                  {/*  Notes */}
                  <tr>
                    <td className='text-start ps-0'></td>
                    <td className='text-start ps-0 pe-5'>
                      <p className='user-sub2 pe-2 mb-0 ps-5 ms-3' style={{fontWeight: '600'}}>
                        {' '}
                        - Notes
                      </p>
                      <p className='user-sub-head mb-2 ps-5 ms-3'>
                        <i>Add notes related to the overall payment record.</i>
                      </p>
                    </td>
                    <td className='text-center ps-0'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                        <input
                          className='form-check-input'
                          type='radio'
                          id='vacant0'
                          name='notes-view-list'
                          checked={leasingResidential?.lease_financial?.notes?.view_list}
                          onClick={(e: any) => {
                            handleChangeRoleData(
                              leasingResidential?.lease_financial?.notes,
                              'notes',
                              'view_list',
                              e
                            )
                          }}
                        />
                      </div>
                    </td>
                    <td className='text-center ps-0'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                        <input
                          className='form-check-input'
                          type='radio'
                          id='vacant0'
                          name='notes-view-details'
                          checked={leasingResidential?.lease_financial?.notes?.view_details}
                          onClick={(e: any) => {
                            handleChangeRoleData(
                              leasingResidential?.lease_financial?.notes,
                              'notes',
                              'view_details',
                              e
                            )
                          }}
                        />
                      </div>
                    </td>
                    <td className='text-center ps-0'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                        <input
                          className='form-check-input'
                          type='radio'
                          id='vacant0'
                          name='notes-create'
                          checked={leasingResidential?.lease_financial?.notes?.create}
                          onClick={(e: any) => {
                            handleChangeRoleData(
                              leasingResidential?.lease_financial?.notes,
                              'notes',
                              'create',
                              e
                            )
                          }}
                        />
                      </div>
                    </td>
                    <td className='text-center ps-0'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                        <input
                          className='form-check-input'
                          type='radio'
                          id='vacant0'
                          name='notes-update'
                          checked={leasingResidential?.lease_financial?.notes?.update}
                          onClick={(e: any) => {
                            handleChangeRoleData(
                              leasingResidential?.lease_financial?.notes,
                              'notes',
                              'update',
                              e
                            )
                          }}
                        />
                      </div>
                    </td>
                    <td className='text-center ps-0'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                        <input
                          className='form-check-input'
                          type='radio'
                          id='vacant0'
                          name='notes-delete'
                          checked={leasingResidential?.lease_financial?.notes?.delete}
                          onClick={(e: any) => {
                            handleChangeRoleData(
                              leasingResidential?.lease_financial?.notes,
                              'notes',
                              'delete',
                              e
                            )
                          }}
                        />
                      </div>
                    </td>
                    <td className='text-center ps-0'></td>
                  </tr>
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}

export default LeasingResidential
