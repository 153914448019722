import React, {useEffect, useState} from 'react'
import {ApiGet} from '../../../../../../../apiCommon/helpers/API/ApiData'
import Paper from '@mui/material/Paper'
import InputBase from '@mui/material/InputBase'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import {CircularProgress} from '@mui/material'
import noData from '../../../../../../../img/NoData1.svg'
import swal from 'sweetalert2'
import { formatAmount, invertOutstandingAmount } from '../../../../../../../Utilities/utils'

interface ButtonProps {
  slctdInvoices?: any
  updateSelectedPayor?: any
  rcptData?: any
  updateSelectedInvoices?:any
}

const SelectAccount = ({slctdInvoices, updateSelectedPayor, rcptData, updateSelectedInvoices}: ButtonProps) => {
  const [isLoading, setIsLoading] = useState<any>(false)

  const [searchTenant, setSearchTenant] = useState<any>('')
  const [payorData, setPayorData] = useState<any>([])

  const [radioChecked, setRadioChecked] = useState<any>(-1)
  const [selectedPayor, setSelectedPayor] = useState<any>({_id: ''})

  const searchContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#f0f0f0',
    borderRadius: '25px',
    // marginLeft: '10px',
  }

  const searchIconStyle = {
    padding: '10px',
    color: 'gray',
  }

  const inputBaseStyle = {
    flex: 1,
    padding: '5px',
  }

  const getPayorList = () => {
    ApiGet(`corporate/tenant?onlyTenancyHaveTenant=true&search=${searchTenant}`)
      .then((res: any) => {
        setPayorData(res?.data?.data)
      })
      .catch((err: any) => {})
  }

  useEffect(() => {
    getPayorList()
    setRadioChecked(rcptData?.payorData?._id != undefined ? rcptData?.payorData?._id : -1)
  }, [searchTenant, rcptData])

  return (
    <div>
      <div className='card card-flush py-4 mt-7  px-5 mb-5'>
        <div className='d-flex align-items-top'>
          <h2 className='pt-4 mb-0'>
            <b>Select Account
            </b>
          </h2>
        </div>
        <div className='d-flex align-items-center'>
          <h4 className='pt-3 mb-0'>Select the account that you want to issue a credit note for:
          </h4>
        </div>

        <>
          <div className='d-flex align-items-center px-0 mt-8'>
            <label className='head-text status-w-80' style={{fontWeight: '600'}}>
              {' '}
              Search{' '}
            </label>

            <div className='mb-2 property xyz tst'>
              <Paper component='form' style={searchContainerStyle}>
                <InputBase
                  placeholder='Payor'
                  style={inputBaseStyle}
                  inputProps={{'aria-label': 'search'}}
                  value={searchTenant}
                  onChange={(e: any) => setSearchTenant(e.target.value)}
                />
                <IconButton style={searchIconStyle} aria-label='search'>
                  <SearchIcon />
                </IconButton>
              </Paper>
            </div>
          </div>
          <div className='row mt-3'>
            <div className='col-12 pe-0'>
              <div className=''>
                <div className='table-responsive' style={{maxHeight: '350px', overflow: 'auto'}}>
                  <table className='table align-start fs-6 gy-5' id='kt_ecommerce_sales_table'>
                    <thead>
                      <tr className='text-start text-gray-400 fw-bold fs-7 gs-0'>
                        <th className='text-start w-25px test'></th>
                        <th className='text-start min-w-125px'>Payor</th>
                        <th className='text-start min-w-150px'>Total Amount Due</th>
                        <th className='text-start min-w-125px'>Total Paid</th>
                        <th className='text-start min-w-125px'>Total Credit</th>
                        <th className='text-start min-w-150px'>Outstanding</th>
                      </tr>
                    </thead>

                    <tbody className='fw-semibold head-text'>
                      {payorData?.length > 0 ? (
                        <>
                          {payorData?.map((tnt: any, index: any) => {
                            return (
                              <tr style={{height: '30px'}}>
                                <td className='text-start w-25px  test ps-0'>
                                  {radioChecked === tnt?._id ? (
                                    <div
                                      style={{
                                        height: '16px',
                                        width: '16px',
                                        backgroundColor: '#146c6a',
                                        borderRadius: '50%',
                                      }}
                                      className='cursor-pointer'
                                    ></div>
                                  ) : (
                                    <div
                                      style={{
                                        height: '16px',
                                        width: '16px',
                                        backgroundColor: 'transparent',
                                        borderRadius: '50%',
                                        border: '1px solid #146c6a',
                                      }}
                                      onClick={() => {
                                        if (radioChecked?._id != tnt?._id && radioChecked != -1 && slctdInvoices?.length > 0) {
                                          swal
                                            .fire({
                                              html: ` 
                                                <div class='fs-3'> <b>  Switch Account Confirmation</b></div>
                                                <div class='fs-5 mt-4'>Switching the selected account will discard all currently selected invoices because you cannot select invoices with different accounts.</div>
                                                <div class='fs-5 mt-4'> Are you sure you want to continue? </div>`,
                                              // icon: 'warning',
                                              showConfirmButton: true,
                                              confirmButtonColor: '#35475e',
                                              confirmButtonText: 'Yes',
                                              showCancelButton: true,
                                              cancelButtonText: 'No',
                                              cancelButtonColor: '#fff',
                                              customClass: {
                                                confirmButton: 'custom-confirm-button',
                                                cancelButton: 'custom-cancel-button',
                                                popup: 'custom-popup',
                                              },
                                              reverseButtons: true, // Reverse the order of buttons reverseButtons: true,
                                            })
                                            .then((res: any) => {
                                              if (res.isConfirmed) {
                                                setRadioChecked(tnt?._id)
                                                setSelectedPayor(tnt)
                                                updateSelectedInvoices(tnt, [])
                                              }
                                            })
                                        } else {
                                          setRadioChecked(tnt?._id)
                                          setSelectedPayor(tnt)
                                          updateSelectedPayor(tnt)
                                        }
                                      }}
                                      className='cursor-pointer'
                                    ></div>
                                  )}
                                </td>
                                <td className='text-start'>
                                  <div className='position-relative'>
                                    {radioChecked != tnt?._id && radioChecked != -1 && (
                                      <div className='requestBoxOpacity z-index-999 border-radius-5px'></div>
                                    )}
                                    <div>
                                      {tnt?.firstName || tnt?.lastName
                                        ? `${tnt?.firstName} ${tnt?.lastName}`
                                        : '-'}
                                    </div>
                                  </div>
                                </td>
                                <td className='text-start'>
                                  <div className='position-relative'>
                                    {radioChecked != tnt?._id && radioChecked != -1 && (
                                      <div className='requestBoxOpacity z-index-999 border-radius-5px'></div>
                                    )}
                                    <div>
                                      {tnt?.totalAmountDue > 0 ? (
                                        <>
                                          AED{' '}
                                          {formatAmount(tnt?.totalAmountDue)}
                                        </>
                                      ) : (
                                        <>AED 0</>
                                      )}
                                    </div>
                                  </div>
                                </td>

                                <td className='text-start'>
                                  <div className='position-relative'>
                                    {radioChecked != tnt?._id && radioChecked != -1 && (
                                      <div className='requestBoxOpacity z-index-999 border-radius-5px'></div>
                                    )}
                                    <div>
                                      {tnt?.totalPaidAmount > 0 ? (
                                        <>
                                          AED{' '}
                                          {formatAmount(tnt?.totalPaidAmount)}
                                        </>
                                      ) : (
                                        <>AED 0</>
                                      )}
                                    </div>
                                  </div>
                                </td>

                                <td className='text-start'>
                                  <div className='position-relative'>
                                    {radioChecked != tnt?._id && radioChecked != -1 && (
                                      <div className='requestBoxOpacity z-index-999 border-radius-5px'></div>
                                    )}
                                    <div>
                                      {tnt?.totalPaidAmount > 0 ? (
                                        <>
                                          AED{' '}
                                          {formatAmount(tnt?.totalPaidAmount)}
                                        </>
                                      ) : (
                                        <>AED 0</>
                                      )}
                                    </div>
                                  </div>
                                </td>
                                
                                <td className='text-start '>
                                  <div className='position-relative'>
                                    {radioChecked != tnt?._id && radioChecked != -1 && (
                                      <div className='requestBoxOpacity z-index-999 border-radius-5px'></div>
                                    )}
                                    <div>
                                      {tnt?.totalOutstanding != undefined ? (
                                        <>
                                          AED{' '}
                                          {formatAmount(invertOutstandingAmount(tnt?.totalOutstanding))}
                                        </>
                                      ) : (
                                        <>AED 0</>
                                      )}
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            )
                          })}
                        </>
                      ) : (
                        <tr>
                          {!isLoading ? (
                            <td colSpan={7} className='text-center'>
                              <img src={noData} alt='' width={350} />
                            </td>
                          ) : (
                            <td colSpan={7} className='text-center'>
                              <div
                                className='d-flex justify-content-center align-items-center w-100'
                                style={{height: '25vh'}}
                              >
                                <CircularProgress style={{color: '#007a59'}} />
                              </div>
                            </td>
                          )}
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </>
      </div>
    </div>
  )
}

export default SelectAccount
