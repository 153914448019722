import React, {useEffect, useState, useRef} from 'react'
import Select from 'react-select'
import Swal from 'sweetalert2'
import {useNavigate} from 'react-router-dom'
import {components} from 'react-select'
import ReactPaginate from 'react-paginate'
import Switch from 'react-switch'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {IoMdInformationCircleOutline} from 'react-icons/io'
import MultiSelectSearch from './MultiSelectSearch'
import AddLatePaymentModel from './AddLatePaymentModel'
import StatusModel from './StatusModel'

import Paper from '@mui/material/Paper'
import InputBase from '@mui/material/InputBase'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'

import backArrow from '../../../../img/back-arrow.png'
import swapImg from '../../../../img/up-down-arrow.png'
import noData from '../../../../img/NoData1.svg'
import addGreen from '../../../../img/add.png'
import trashImg from '../../../../img/trash.png'
import switchOffIcon from '../../../../img/switch-off.png'
import switchOnIcon from '../../../../img/switch-on.png'

import {ApiPost, ApiPut} from '../../../../apiCommon/helpers/API/ApiData'
import {ErrorToast, SuccessToast} from '../../../../apiCommon/helpers/Toast'
import {formatAmount} from '../../../../Utilities/utils'
import './style.scss'
import {FaBullseye} from 'react-icons/fa'

function LatePaymentFeeListing() {
  const navigate = useNavigate()
  const [searchName, setSearchName] = useState<any>('')
  const [statusOptions, setStatusOptions] = useState<any>([
    {
      label: 'Enabled',
      value: 1,
    },
    {
      label: 'Disabled',
      value: 0,
    },
  ])
  const [statusDropdown, setStatusDropdown] = useState<any>([])
  const [typeOptions, setTypeOptions] = useState<any>([
    {label: 'Fixed Amount', value: 0},
    {label: 'Percentage', value: 1},
  ])
  const [typeDropdown, setTypeDropdown] = useState<any>([])
  const [latePaymentModal, setLatePaymentModal] = useState<any>(false)
  const [viewPaymentModal, setViewPaymentModal] = useState<any>(false)

  const [sortType, setSortType] = useState<any>('A-Z')
  const [sortOptions, setSortOptions] = useState<any>([
    {value: 'A-Z', label: 'Alphabetic (A-Z)'},
    {value: 'Z-A', label: 'Alphabetic (Z-A)'},
    {value: 'latest', label: '(Latest - Oldest)'},
    {value: 'oldest', label: '(Oldest - Latest)'},
  ])

  const [listData, setListData] = useState<any>([])
  const [checkedValues, setCheckedValues] = useState<any>([])
  let totalSelected: any = 0

  const [isLoading, setIsLoading] = useState<any>(false)
  const [latePaymentItems, setLatePaymentItems] = useState<any>({})
  const [mode, setMode] = useState<string>('create')
  const [listItemId, setListItemId] = useState<string>('')
  const [showDisableModal, setShowDisableModal] = useState(false)
  const [modelName, setModelName] = useState('Enable')
  const [isChangeHappened, setIsChangeHappened] = useState<any>(true)

  const handleDisable = () => {
    // setIsEnabled(false);
    setShowDisableModal(false)
    // You might want to add additional logic here, such as API calls
  }

  const [pageLimit, setPageLimit] = useState<any>(1)
  const [page, setPage] = useState<any>(1)
  const handlePageClick = (event: any) => {
    setPage(event.selected + 1)
  }

  const getLatePaymentItems = async (pg = 1) => {
    setIsLoading(true)
    setListData([])

    let typeDropdownIds: any = []
    typeDropdown?.map((v: any, ind: any) => {
      typeDropdownIds[ind] = v?.value
    })

    let statusDropdownIds: any = []
    statusDropdown?.map((v: any, ind: any) => {
      statusDropdownIds[ind] = v?.value
    })

    let body: any = {
      page: page,
      limit: 25,
      feeTypes: [...typeDropdownIds],
      activityStatus: [...statusDropdownIds],
      searchName: searchName,
      sortType: sortType,
    }

    await ApiPost('corporate/late_payment_item/get', body)
      .then((res) => {
        console.log(res?.data?.data?.late_payment_item)
        setListData(res?.data?.data?.late_payment_item)

        let values: any = []
        for (let i = 0; i < res?.data?.data?.payment_main_item_list_data; i++) {
          values[i] = false
        }
        setCheckedValues(values)

        setPageLimit(res?.data?.data?.state?.page_limit)

        setTimeout(() => {
          setIsLoading(false)
        }, 1000)
      })
      .catch((err) => {
        setIsLoading(false)
        ErrorToast(err.message)
        if (err?.message === 'Your account has been suspended!') {
          window.location.pathname = '/auth'
          localStorage.clear()
        }
      })
  }

  const updateListData = async (id: any, toggleValue: any, index: any) => {
    setIsLoading(true)
    let body = {
      id: id,
      activityStatus: toggleValue,
    }

    await ApiPut('corporate/late_payment_item', body)
      .then((res) => {
        const updateRequestData = [...listData]
        updateRequestData[index].activityStatus = res?.data?.data?.activityStatus
        setListData(updateRequestData)
        setIsLoading(false)
        setIsChangeHappened(false)
        setLatePaymentItems({})
      })
      .catch((err) => {
        ErrorToast(err.message)
        setIsLoading(false)
        if (err?.message === 'Your account has been suspended!') {
          window.location.pathname = '/auth'
          localStorage.clear()
        }
      })
  }

  const deleteLatePaymentItems = () => {
    setIsLoading(true)
    let vIds: any = []

    let i: any = 0

    checkedValues?.map((ch: any, ind: any) => {
      if (ch == true) {
        vIds[i] = listData[ind]?._id
        i++
      }
    })

    const body = {
      ids: vIds,
    }

    ApiPost(`corporate/late_payment_item/delete`, body)
      .then((res: any) => {
        // SuccessToast(res?.data?.message)
        setIsLoading(false)

        getLatePaymentItems()

        let values = []

        for (let i = 0; i < listData?.length; i++) {
          values[i] = false
        }

        setCheckedValues(values)
        SuccessToast('Late Payment Fee Deleted')
      })
      .catch((err: any) => {
        setIsLoading(false)
        ErrorToast(err?.message)
      })
  }

  const goToView = (listItem: any) => {
    setListItemId(listItem?._id)
    setMode('view')
    setLatePaymentModal(true)
    // setViewPaymentModal(true)
  }

  useEffect(() => {
    getLatePaymentItems()
  }, [searchName,sortType,typeDropdown,statusDropdown])

  useEffect(() => {
    if (isChangeHappened !== true && Object.keys(latePaymentItems)?.length > 0) {
      updateListData(
        latePaymentItems?.listItem?._id,
        latePaymentItems?.status,
        latePaymentItems?.index
      )
    }
  }, [isChangeHappened])

  const CustomValueRenderer = (selected: any, _options: any) => {
    return selected.length > 0 ? `${selected.length} Selected` : 'Select'
  }

  const searchContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#f0f0f0',
    borderRadius: '25px',
  }

  const inputBaseStyle = {
    flex: 1,
    padding: '5px',
  }

  const searchIconStyle = {
    padding: '10px',
    color: 'gray',
  }

  const customStylesImage = {
    container: (defaultStyles: any) => ({
      ...defaultStyles,
      // width: "200px",
    }),

    option: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      color: state.isSelected ? '#000' : '#000',
      backgroundColor: state.isSelected ? '#c2d8d9' : '',
      ':hover': {
        backgroundColor: '#e0f5f0',
        color: '#000',
      },

      fontWeight: '500',
    }),

    menu: (defaultStyles: any) => ({
      ...defaultStyles,
      border: '0.1rem solid #0d9e91 ',
      marginTop: '0px',
      borderRadius: '0px',
      zIndex: '2',
      width: '150px',
      transform: 'translate(-110px, 0px)',
    }),
    menuList: (defaultStyles: any) => ({
      ...defaultStyles,
      maxHeight: '200px',
    }),

    control: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: 'transparent',
      boxShadow: 'none',
      border: '0rem solid transparent',
      borderRadius: '2px',
    }),

    valueContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: '0px 8px 2px 8px',
      display: 'none',
    }),

    singleValue: (provided: any) => ({
      ...provided,
      backgroundImage: `url(${swapImg})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      backgroundSize: 'contain', // or 'cover' based on your preference
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: 'transparent',
    }),
    indicatorContainer: () => ({display: 'none'}), // Hide the default dropdown indicator
    indicatorSeparator: () => ({display: 'none'}),
  }

  const DropdownIndicator = (props: any) => {
    return (
      <components.DropdownIndicator {...props}>
        <div className='swap-circle-div d-flex align-items-center justify-content-center cursor-pointer'>
          <img src={swapImg} height={18} width={18} alt='Dropdown Indicator' />
        </div>
      </components.DropdownIndicator>
    )
  }

  return (
    <>
      <div className='app-main flex-column flex-row-fluid px-2' id='kt_app_main'>
        <div id='' className='pt-0 mt-0'>
          {/* Header */}
          <div className='page-title d-flex flex-column justify-content-center flex-wrap'>
            <div className=' d-flex flex-stack pt-1 mt-0 px-0'>
              {' '}
              <div className=''>
                <div className='d-flex align-items-center mb-1'>
                  <span
                    className='rounded-circle cursor-pointer'
                    onClick={() => {
                      navigate('/settings')
                    }}
                  >
                    <img src={backArrow} height={14} width={14} />
                  </span>
                  <h2 className='m-0 head-text ms-2'>{'Late Payment Fee'}</h2>
                </div>
              </div>
            </div>
          </div>

          {checkedValues?.length > 0 && (
            <>
              {checkedValues?.map((ch: any, ind: any) => {
                if (ch == true) totalSelected = totalSelected + 1
                return <></>
              })}
            </>
          )}

          <div className='card card-flush py-5 mt-5 pt-0 px-5'>
            {/* Search and Dropdown */}
            <div className='d-flex align-items-center py-5 pt-2 gy-3 mx-0 mt-4 xyz tst'>
              <label className='head-text me-5 pe-3' style={{fontWeight: '600'}}>
                Search{' '}
              </label>
              <div className='me-4 xyz'>
                <Paper component='form' style={searchContainerStyle}>
                  <InputBase
                    placeholder='Search Name...'
                    style={inputBaseStyle}
                    inputProps={{'aria-label': 'search'}}
                    value={searchName}
                    onChange={(e: any) => setSearchName(e.target.value)}
                  />
                  <IconButton style={searchIconStyle} aria-label='search'>
                    <SearchIcon />
                  </IconButton>
                </Paper>
              </div>

              <div className='test property me-4 '>
                <MultiSelectSearch
                  statusDropdown={statusDropdown}
                  setStatusDropdown={setStatusDropdown}
                  statusOptions={statusOptions}
                  CustomValueRenderer={CustomValueRenderer}
                  label='Status'
                />
              </div>

              {/* Select Type */}
              <div className='test property me-4 '>
                <MultiSelectSearch
                  statusDropdown={typeDropdown}
                  setStatusDropdown={setTypeDropdown}
                  statusOptions={typeOptions}
                  CustomValueRenderer={CustomValueRenderer}
                  label='Type'
                />
              </div>
              <div className='me-4 xyz'>
                <Select
                  placeholder={'Sort'}
                  styles={customStylesImage}
                  isSearchable={false}
                  options={sortOptions}
                  name='sort'
                  onChange={(e: any) => {
                    setSortType(e.value)
                  }}
                  components={{DropdownIndicator}}
                />
              </div>

              <div className='d-flex align-items-center ms-auto'>
                {totalSelected > 0 && (
                  <>
                    <div className='d-flex'>
                      <h3 className='my-2' style={{color: 'black'}}>
                        <label className='labl-gry '>
                          {' '}
                          <i>Total Selected </i>
                        </label>
                      </h3>
                      <span className='ms-5 ps-1 my-2 me-4'>
                        <b>{totalSelected}</b>
                      </span>
                    </div>

                    {/* Delete CTA */}
                    <button
                      disabled={isLoading}
                      type='button'
                      className='btn btn-sm fw-bold my-4  mx-3 red-hollow-btn btn-fit-content'
                      onClick={() => {
                        Swal.fire({
                          html: ` 
                          <div class='fs-3'> <b>Delete Late Payment Items</></div>
                           <div class='fs-5 mt-4'>You are about to delete late payment fee.</div>
                           <div class='fs-5 mt-4'>Deleting these late payment fee will remove it from all existing payments.</div>
                          <div class='fs-5 mt-4'>All previous charges generated by the late payment fee will not be removed.</div>`,
                          // icon: 'warning',
                          showConfirmButton: true,
                          confirmButtonColor: '#d54645',
                          confirmButtonText: 'Delete',
                          showCancelButton: false,
                          cancelButtonText: 'No',
                          cancelButtonColor: '#fff',
                          customClass: {
                            confirmButton: 'custom-confirm-button tst',
                            cancelButton: 'custom-cancel-button',
                            popup: 'custom-popup tst',
                          },
                          reverseButtons: true, // Reverse the order of buttons reverseButtons: true,
                        }).then((res: any) => {
                          if (res.isConfirmed) {
                            deleteLatePaymentItems()
                          }
                        })
                      }}
                    >
                      <img src={trashImg} height={18} width={18} className='me-3' />{' '}
                      Delete
                    </button>
                  </>
                )}
                <button
                  disabled={isLoading}
                  type='button'
                  className='btn btn-sm fw-bold my-4  mx-3 green-hollow-btn btn-fit-content'
                  onClick={() => {
                    setLatePaymentModal(true)
                  }}
                >
                  <img src={addGreen} height={18} width={18} className='me-3' /> Add
                </button>
              </div>
            </div>

            {/* table */}

            <div className='table-responsive'>
              <table
                className='table align-start fs-6 gy-5 table-row-dashed'
                id='kt_ecommerce_sales_table'
              >
                <thead>
                  <th className='text-start min-w-25px test'>
                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 px-0'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        id='vacant0'
                        name='none'
                        checked={
                          totalSelected == listData?.length && listData?.length != 0
                            ? true
                            : false
                        }
                        onChange={(e: any) => {
                          let values = [...checkedValues]
                          if (e.target.checked) {
                            for (let i = 0; i < listData?.length; i++) {
                              values[i] = true
                            }
                          } else {
                            for (let i = 0; i < listData?.length; i++) {
                              values[i] = false
                            }
                          }
                          setCheckedValues(values)
                        }}
                      />
                    </div>
                  </th>

                  <th className='text-start min-w-150px'>Name</th>
                  <th className='text-start min-w-125px'>Fee Type</th>
                  <th className='text-start min-w-125px'>Fee</th>
                  <th className='text-center min-w-100px'>Days after due date</th>
                  <th className='text-start min-w-150px ps-5'>Frequency</th>
                  <th className='text-start min-w-150px'>
                    Status
                    <span>
                      <OverlayTrigger
                        placement='bottom'
                        delay={{show: 250, hide: 400}}
                        overlay={
                          <Tooltip id='button-tooltip-3' className='custom-tooltip'>
                            <div className='d-flex align-items-center'>
                              <img src={switchOnIcon} height={25} width={25} />
                              <p className='m-0 ms-3'>Enabled</p>
                            </div>
                            <p className='mb-2 text-start'>
                              User will be able to find and select the late payment fee
                              when applying it to a payment or invoice.
                            </p>
                            <div className='d-flex align-items-center'>
                              <img src={switchOffIcon} height={25} width={25} />
                              <p className='m-0 ms-3'>Disabled</p>
                            </div>
                            <p className='mb-2 text-start'>
                              The late payment fee will not be available for the user to
                              select and apply on a payment or invoice.
                            </p>
                          </Tooltip>
                        }
                      >
                        <span>
                          {' '}
                          <IoMdInformationCircleOutline className='ms-0 cursor-pointer exclam-circle' />
                        </span>
                      </OverlayTrigger>
                    </span>
                  </th>
                </thead>
                <tbody className='fw-semibold text-gray-600'>
                  {listData?.length > 0 ? (
                    listData?.map((listItem: any, ind: any) => {
                      return (
                        <>
                          <tr>
                            <td className='text-start ps-0 test'>
                              <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 px-0'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  id='vacant0'
                                  name='none'
                                  checked={checkedValues[ind] == true ? true : false}
                                  onChange={(e: any) => {
                                    const values = [...checkedValues]
                                    values[ind] = !values[ind]
                                    setCheckedValues(values)
                                  }}
                                />
                              </div>
                            </td>
                            <td
                              className='text-start ps-0'
                              onClick={() => goToView(listItem)}
                            >
                              {listItem?.name ? listItem?.name : '-'}
                            </td>
                            <td
                              className='text-start ps-0'
                              onClick={() => goToView(listItem)}
                            >
                              {listItem?.feeType === 0
                                ? 'Fixed Amount'
                                : listItem?.feeType === 1
                                ? 'Percnetage'
                                : '-'}
                            </td>
                            <td
                              className='text-start ps-0'
                              onClick={() => goToView(listItem)}
                            >
                              {listItem?.feeType === 0
                                ? `${`AED ${formatAmount(listItem?.fixedAmount)}`}`
                                : listItem?.feeType === 1
                                ? `${
                                    listItem?.percentageAmount >= 0
                                      ? `${listItem?.percentageAmount}%`
                                      : '-'
                                  }`
                                : '-'}
                            </td>
                            <td
                              className='text-center ps-0'
                              onClick={() => goToView(listItem)}
                            >
                              {formatAmount(listItem?.dayAfterPaymentDue)}
                            </td>
                            <td
                              className='text-start ps-5'
                              onClick={() => goToView(listItem)}
                            >
                              {listItem?.frequency === 0
                                ? 'Once'
                                : listItem?.frequency === 1
                                ? 'Daily'
                                : listItem?.frequency === 2
                                ? 'Weekly'
                                : listItem?.frequency === 3
                                ? 'Monthly'
                                : '-'}
                            </td>
                            <td className='d-flex align-items-center ps-0'>
                              <div className='d-flex  align-items-center '>
                                <Switch
                                  onChange={(e) => {
                                    setShowDisableModal(true)
                                    if (listItem?.activityStatus === 1) {
                                      setModelName('Disable')
                                    } else if (listItem?.activityStatus === 0) {
                                      setModelName('Enable')
                                    }
                                    setLatePaymentItems({
                                      index: ind,
                                      listItem: listItem,
                                      status: listData[ind].activityStatus === 0 ? 1 : 0,
                                    })
                                  }}
                                  handleDiameter={20}
                                  height={24}
                                  uncheckedIcon={false}
                                  checkedIcon={false}
                                  checked={listItem?.activityStatus}
                                  onColor='#00cf99'
                                  offColor='#b0bec5'
                                />
                                <h5 className=' m-0 head-text ms-3'>
                                  {listItem?.activityStatus === 1
                                    ? 'Enabled'
                                    : 'Disabled'}
                                </h5>
                              </div>
                            </td>
                          </tr>
                        </>
                      )
                    })
                  ) : (
                    <tr>
                      <td colSpan={10} className='text-center'>
                        <img src={noData} alt='' width={350} />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            <div className='row mb-5'>
              <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
              <div className='row mt-5'>
                <div className='col-sm-12 col-md-12 d-flex align-items-center justify-content-center justify-content-md-end mt-5'>
                  <div
                    className='dataTables_paginate paging_simple_numbers'
                    id='kt_ecommerce_sales_table_paginate'
                  >
                    <ReactPaginate
                      breakLabel='...'
                      nextLabel='>'
                      pageClassName='paginate_button page-item +'
                      pageLinkClassName='page-link'
                      containerClassName='pagination'
                      activeClassName='active'
                      previousClassName='paginate_button page-item previous'
                      nextClassName='paginate_button page-item next'
                      previousLinkClassName='page-link'
                      nextLinkClassName='page-link'
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={5}
                      pageCount={pageLimit}
                      forcePage={page - 1}
                      previousLabel='<'
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {latePaymentModal && (
        <AddLatePaymentModel
          listItemId={listItemId}
          mode={mode}
          setMode={setMode}
          show={latePaymentModal}
          isEdit={false}
          handleClose={() => {
            setLatePaymentModal(false)
            setMode('create')
            getLatePaymentItems()
          }}
        />
      )}

      <StatusModel
        isChangeHappened={isChangeHappened}
        setIsChangeHappened={setIsChangeHappened}
        modelName={modelName}
        show={showDisableModal}
        onHide={() => {
          setShowDisableModal(false)
          setModelName('Enable')
        }}
        onDisable={handleDisable}
      />
    </>
  )
}

export default LatePaymentFeeListing
