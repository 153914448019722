import React, { useState, useEffect } from 'react'
import noData from '../../../../img/NoData1.svg'
import allProperties from '../../../img/allProperties.png'
import allDevelopment from '../../../img/allDevelopment.png'
import allBuilding from '../../../img/small-building.png'
import allCommunities from '../../../img/allCommunities.png'
import bulkImportWhite from '../../../img/bulkImportWhite.png'
import unitsBlue from '../../../img/allPropertiesBlue.png'
import tenantBlue from '../../../img/tenantBlue.png'
import leasesBlue from '../../../img/leasesBlue.png'
import allBuildingBlue from '../../../../img/allBuildingBlue.png'
import yellowStar from '../../../../img/yellow-star.png'
import occupancyBlue from '../../../img/occupancyBlue.png'
import { MultiSelect } from 'react-multi-select-component'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Row } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { useNavigate, useLocation } from 'react-router-dom'
import { ApiDelete, ApiGet, ApiPost } from '../../../../apiCommon/helpers/API/ApiData'
import ReactPaginate from 'react-paginate'
import { SuccessToast, ErrorToast } from '../../../../apiCommon/helpers/Toast'
import allTen from '../../../img/all-tenc.png'
import bookTen from '../../../img/book-tenc.png'
import activeTen from '../../../img/active-tenc.png'
import expngTen from '../../../img/expng-tenc.png'
import terminTen from '../../../img/expired-tenc.png'
import trash from '../../../img/trash-white.png'
import create from '../../../img/create.png'
import closes from '../../../img/close.svg'
import windowImg from '../../../img/window.png'
import sync from '../../../img/sync.png'
import Paper from '@mui/material/Paper'
import InputBase from '@mui/material/InputBase'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import { DatePicker, Space } from 'antd'
import Select from 'react-select'
import downGreen from '../../../img/down-arrow-green.png'
import upGreen from '../../../img/up-arrow-green.png'
import trashImg from '../../../img/trash.png'
import swal from 'sweetalert2'
import cancelledTen from '../../../img/cancelled-tab.png'
import draftTen from '../../../img/draft.png'
import expiredTen from '../../../img/expired-tab.png'
import { setComefrom } from '../../../../redux/counterSlice'

import townhouse from '../../../../img/TownHouse.svg'
import appartment from '../../../../img/Apartment.svg'
import villa from '../../../../img/Villa.svg'
import other from '../../../../img/other.svg'
import buildings from '../../../../img/buildings.svg'
import common_area from '../../../../img/common_area.svg'
import penthouse from '../../../../img/PentHouse.svg'
import communityBlue from '../../../../img/communityBlue.png'
import { Bucket } from '../../../../apiCommon/helpers/API/ApiData'

interface ButtonProps {
  goToMiantenance: any
  handlePageClick: any
  pageLimit: any
  page: any,
  listData: any
}

const CommunityList = ({ goToMiantenance, handlePageClick, pageLimit, page, listData }: ButtonProps) => {
  const calculateExpencePercentage = (totalAmount: any, totalExpence: any) => {
    let percentage = (totalExpence / totalAmount) * 100
    return Math.round(percentage)
  }
  return (
    <div className='card card-flush py-5  px-7'>
      <div className='app-main flex-column flex-row-fluid' id='------------kt_app_main'>
        <div className='table-responsive'>
          <table
            className='table align-middle table-row-dashed fs-8 gy-5 '
            id='kt_ecommerce_sales_table'
          >
            <thead className='fs-7'>
              <th className='text-start min-w-50px ps-0'></th>
              <th className='text-start min-w-150px ps-0'>Community</th>
              <th className='text-start min-w-100px ps-0'>Units</th>
              <th className='text-start min-w-100px ps-0'>Cluster</th>
              <th className='text-start min-w-100px ps-0'>Unit Clusters</th>
              <th className='text-start min-w-125px ps-0'>Building Clusters</th>
              <th className='text-start min-w-100px ps-0'>Mixed Clusters</th>
              <th className='text-center min-w-125px ps-0 '>Occupancy Rate</th>
              <th className='text-start min-w-100px ps-5 '>Total Income</th>
              <th className='text-start min-w-125px ps-5'>Total Expanses</th>
              <th className='text-start min-w-50px ps-0'></th>
              <th className='text-start min-w-125px ps-0 '>Upcoming Income</th>
              <th className='text-start min-w-100px ps-0 '>Rating</th>
            </thead>
            <tbody className='fw-semibold head-text'>
              {listData?.length > 0 ? (
                listData?.map((listItem: any, i: any) => {
                  return (
                    <tr>
                      <td className={`text-start cursor-pointer ps-0 m-0 p-0 min-w-50px`}>
                        <img src={
                          listItem?.images?.length > 0
                          ?`${Bucket}${listItem?.images?.[0]}` 
                          : communityBlue}
                          height={30}
                          className='m-0 p-0'
                        />
                      </td>

                      <td
                        className='text-start cursor-pointer ps-0 min-w-150px'
                        onClick={() => goToMiantenance(listItem)}
                      >
                        {listItem?.name  ? listItem?.name : '-'}

                      </td>

                      <td
                        className='text-start cursor-pointer ps-0 100px'
                        onClick={() => goToMiantenance(listItem)}
                      >
                      {listItem?.totalUnit >= 0  ? listItem?.totalUnit : '-'}

                      </td>
                      <td
                        className='text-start cursor-pointer ps-0 100px'
                        onClick={() => goToMiantenance(listItem)}
                      >
                        {listItem?.totalCluster >= 0  ? listItem?.totalCluster : '-'}

                      </td>
                      <td
                        className='text-start cursor-pointer ps-0 min-w-100'
                        onClick={() => goToMiantenance(listItem)}
                      >
                        {listItem?.totalUnitCluster >= 0 ? listItem?.totalUnitCluster : '-'}

                      </td>
                      <td
                        className='text-start cursor-pointer ps-0 min-w-125'
                        onClick={() => goToMiantenance(listItem)}
                      >
                        {listItem?.totalBuildingCluster >= 0  ? listItem?.totalBuildingCluster : '-'}

                      </td>
                      <td
                        className='text-start cursor-pointer ps-0 min-w-100'
                        onClick={() => goToMiantenance(listItem)}
                      >
                         {listItem?.totalMixedCluster >= 0 ? listItem?.totalMixedCluster  : '-'}
                      </td>

                      <td
                        className={`text-center p-0 cursor-pointer d-flex justify-content-center`}
                        onClick={() => goToMiantenance(listItem)}
                      >
                          <div className={` status ${(Math.round(listItem?.occupancyRate) <= 50 && Math.round(listItem?.occupancyRate) > 0) ? 'cancelled-st' : (Math.round(listItem?.occupancyRate) <= 75 && Math.round(listItem?.occupancyRate) > 50) ? 'requested-st' : (Math.round(listItem?.occupancyRate) <= 99 && Math.round(listItem?.occupancyRate) > 75) ? 'draft-st' : Math.round(listItem?.occupancyRate) >= 100 ? 'create-st' : 'not-saved'}`}>
                          {listItem?.occupancyRate >= 0 ? `${Math.round(listItem?.occupancyRate)}%` : '-'}
                        </div>
                      </td>
                      <td
                        className='text-start cursor-pointer ps-0 min-w-100px ps-5'
                        onClick={() => goToMiantenance(listItem)}
                      >
                       {/* {`AED ${listItem?.incomePaymentCount ?? '-'}`} */}
                       {`AED ${parseFloat(listItem?.incomePaymentCount)?.toLocaleString('en-US', {
                              minimumFractionDigits: 2,
                            })}`}

                      </td>
                      <td
                        className='text-start cursor-pointer ps-5 min-w-125x'
                        onClick={() => goToMiantenance(listItem)}
                      >
                         {/* {`AED ${listItem?.expensePaymentCount ?? '-'}`} */}
                         {`AED ${parseFloat(listItem?.expensePaymentCount)?.toLocaleString('en-US', {
                              minimumFractionDigits: 2,
                            })}`}


                      </td>
                      <td
                        className='text-start cursor-pointer ps-0 min-w-50px'
                        onClick={() => goToMiantenance(listItem)}
                      >
                        {`${(listItem?.incomePaymentCount > 0 && listItem?.expensePaymentCount > 0) ? `${calculateExpencePercentage(listItem?.incomePaymentCount,listItem?.expensePaymentCount)} %` : '-'}`}
                      </td>

                      <td
                        className='text-start cursor-pointer ps-0 min-w-125px'
                        onClick={() => goToMiantenance(listItem)}
                      >
                       {/* {`AED ${listItem?.incomePaymentOutstandingCount ?? '-'}`} */}
                       {`AED ${parseFloat(listItem?.incomePaymentOutstandingCount)?.toLocaleString('en-US', {
                              minimumFractionDigits: 2,
                            })}`}
                      </td>
                      <td
                        className='text-start cursor-pointer ps-0 min-w-100px'
                        onClick={() => goToMiantenance(listItem)}
                      >
                        {' '}
                        <div className='d-flex align-items-center '>
                          <img src={yellowStar} height={18} width={18} className='me-1' />
                          {listItem?.rating != undefined ? listItem?.rating : '-'}
                        </div>
                      </td>


                    </tr>
                  )
                })
              ) : (
                <tr>
                  <td colSpan={10} className='text-center'>
                    <img src={noData} alt='' width={350} />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {/* pagination start*/}
        <div className='row mb-5'>
          <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
          <div className='row mt-5'>
            <div className='col-sm-12 col-md-12 d-flex align-items-center justify-content-center justify-content-md-end mt-5'>
              <div
                className='dataTables_paginate paging_simple_numbers'
                id='kt_ecommerce_sales_table_paginate'
              >
                <ReactPaginate
                  breakLabel='...'
                  nextLabel='>'
                  pageClassName='paginate_button page-item +'
                  pageLinkClassName='page-link'
                  containerClassName='pagination'
                  activeClassName='active'
                  previousClassName='paginate_button page-item previous'
                  nextClassName='paginate_button page-item next'
                  previousLinkClassName='page-link'
                  nextLinkClassName='page-link'
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pageLimit}
                  forcePage={page - 1}
                  previousLabel='<'
                />
              </div>
            </div>
          </div>
        </div>
        {/* pagination end*/}
      </div>
    </div>
  )
}

export default CommunityList