import React, {useState, useEffect} from 'react'
import {Modal} from 'react-bootstrap'
import {CircularProgress} from '@mui/material'
import moment from 'moment'
import {formatAmount, getFileIcon} from '../../../../../../Utilities/utils'
import {Bucket} from '../../../../../../apiCommon/helpers/API/ApiData'

import {Fancybox as fancybox} from '@fancyapps/ui'
import '@fancyapps/ui/dist/fancybox/fancybox.css'

interface ButtonProps {
  show: any
  handleClose: any
  invoicesData: any
}

const InvoicesPopup = ({show, handleClose, invoicesData}: ButtonProps) => {
  const userData: any = localStorage.getItem('userData')
  const user = JSON.parse(userData)

  const [isLoading, setIsLoading] = useState<any>(false)

  useEffect(() => {
    fancybox.bind('[data-fancybox]', {
      Toolbar: {
        display: {
          left: ['infobar'],
          middle: ['zoomIn', 'zoomOut', 'toggle1to1', 'rotateCCW', 'rotateCW', 'flipX', 'flipY'],
          right: ['slideshow', 'thumbs', 'close'],
        },
      },
    })
  }, [])

  let totalInvoiceAmount: any = 0
  let totalPaidAmount: any = 0

  return (
    <div className='schedule'>
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-900px mx-auto'
        show={show}
        onHide={() => {
          handleClose()
        }}
        backdrop={true}
        size='sm'
      >
        <div
          className='modal-body py-lg-7 px-lg-7 modal-body-without-header'
          style={{
            maxHeight: '500px',
            overflow: 'auto',
          }}
        >
          <div className='d-flex align-items-center'>
            <h2 className='mb-0 pt-1'>
              <b>Invoices</b>
            </h2>

            <label
              className='head-text fs-2 cursor-pointer ms-auto'
              style={{fontWeight: '700'}}
              onClick={() => {
                handleClose()
              }}
            >
              X
            </label>
          </div>

          {invoicesData?.map((rcp: any, index: any) => {
            totalInvoiceAmount =
              Number(totalInvoiceAmount) + Number(rcp?.payment_invoice?.totalAmount)
            totalPaidAmount =
              Number(totalPaidAmount) + Number(rcp?.payment_invoice?.totalPaidAmount)
          })}

          <div className='d-flex align-items-center mt-4'>
            <div className='me-5'>
              <p className='mb-1 ms-3 pt-2 fnt-500 text-start mb-0 fs-5'>Total</p>
              <p className='mb-1 ms-3 pt-2 fnt-700  text-start fs-5'>
                {formatAmount(invoicesData?.length)}
              </p>
            </div>

            <div className='me-5'>
              <p className='mb-1 ms-3 pt-2 fnt-500  text-start mb-0 fs-5'>Total Invoice Amount</p>
              <p className='mb-1 ms-3 pt-2 fnt-700  text-start fs-5'>
                {totalInvoiceAmount > 0 ? <>AED {formatAmount(totalInvoiceAmount)}</> : <>AED 0</>}
              </p>
            </div>

            <div className='me-5'>
              <p className='mb-1 ms-3 pt-2 fnt-500  text-start mb-0 fs-5'>Total Paid Amount</p>
              <p className='mb-1 ms-3 pt-2 fnt-700  text-start fs-5'>
                {totalPaidAmount > 0 ? <>AED {formatAmount(totalPaidAmount)}</> : <>AED 0</>}
              </p>
            </div>
          </div>

          <div>
            <>
              <div className='separator my-3 mt-5'></div>
              <div className='row mt-3'>
                <div className='col-12 pe-0'>
                  <div className=''>
                    <div
                      className='table-responsive pb-3'
                      style={{maxHeight: '380px', overflow: 'auto'}}
                    >
                      <table
                        className='table align-middle  fs-6 gy-5'
                        id='kt_ecommerce_sales_table'
                      >
                        <thead>
                          <tr className='text-start text-gray-400 fw-bold fs-7 gs-0'>
                            <th className='text-start min-w-125px'>Invoice No.</th>
                            <th className='text-start w-25px ps-0 pe-2'></th>
                            <th className='text-start min-w-100px ps-0'>Invoice Date</th>
                            <th className='text-start min-w-100px'>Due Date</th>
                            <th className='text-start min-w-125px'>Total Amount</th>
                            <th className='text-start min-w-100px'>Paid Amount</th>
                            <th className='text-center min-w-125px'>Status</th>
                            <th className='text-start min-w-125px'>Development</th>
                            <th className='text-start min-w-100px'>Unit No.</th>
                            <th className='text-start min-w-125px pe-0'>Unit Category</th>
                            <th className='text-start min-w-125px'>Contract No.</th>
                            <th className='text-start min-w-125px'>Lease Value</th>
                            <th className='text-start min-w-150px'>Payment Account</th>
                          </tr>
                        </thead>

                        <tbody className='fw-semibold'>
                          {invoicesData?.length > 0 ? (
                            <>
                              {invoicesData?.map((rcp: any, index: any) => {
                                return (
                                  <tr>
                                    <td className='text-decoration-underline'>
                                      <b>
                                        {rcp?.payment_invoice?.invoiceNo
                                          ? rcp?.payment_invoice?.invoiceNo
                                          : '-'}
                                      </b>
                                    </td>
                                    <td className='ps-0 pe-2'>
                                      {rcp?.payment_invoice?.formStatus == 1 && (
                                        <img
                                          src={getFileIcon(rcp?.payment_invoice?.pdfURL)}
                                          height={30}
                                          width={30}
                                          className='me-2 cursor-pointer'
                                          data-src={`${Bucket}${rcp?.payment_invoice?.pdfURL}`}
                                          data-fancybox
                                          id={`clickedInvoice${index}`}
                                        />
                                      )}
                                    </td>
                                    <td className='ps-0'>
                                      {rcp?.payment_invoice?.createdAt
                                        ? moment(rcp?.payment_invoice?.createdAt).format(
                                            'DD.MM.YYYY'
                                          )
                                        : '-'}
                                    </td>
                                    <td className=''>
                                      {rcp?.payment_invoice?.dueDate
                                        ? moment(rcp?.payment_invoice?.dueDate).format('DD.MM.YYYY')
                                        : '-'}
                                    </td>
                                    <td>
                                      {rcp?.payment_invoice?.totalAmount > 0 ? (
                                        <>AED {formatAmount(rcp?.payment_invoice?.totalAmount)}</>
                                      ) : (
                                        <>AED 0</>
                                      )}
                                    </td>
                                    <td>
                                      {rcp?.payment_invoice?.totalPaidAmount > 0 ? (
                                        <>
                                          AED {formatAmount(rcp?.payment_invoice?.totalPaidAmount)}
                                        </>
                                      ) : (
                                        <>AED 0</>
                                      )}
                                    </td>
                                    <td>
                                      <div
                                        className={`status-w-100 status mx-auto
                                ${
                                  rcp?.payment_invoice?.status === 2
                                    ? 'pending-approval-st '
                                    : rcp?.payment_invoice?.status === 1
                                    ? 'approve-st'
                                    : rcp?.payment_invoice?.status === 0
                                    ? 'cancelled-st'
                                    : rcp?.payment_invoice?.status === 3 ||
                                      rcp?.payment_invoice?.status === 4
                                    ? 'pend-st'
                                    : 'not-saved'
                                }`}
                                      >
                                        {rcp?.payment_invoice?.status === 0
                                          ? 'Unpaid'
                                          : rcp?.payment_invoice?.status === 1
                                          ? 'Paid'
                                          : rcp?.payment_invoice?.status === 2
                                          ? 'Partially paid'
                                          : rcp?.payment_invoice?.status === 3
                                          ? 'Void'
                                          : rcp?.payment_invoice?.status === 4
                                          ? 'Draft'
                                          : '-'}
                                      </div>
                                    </td>
                                    <td>
                                      {rcp?.payment_invoice?.community?.length > 0
                                        ? rcp?.payment_invoice?.community?.[0]?.name
                                        : rcp?.payment_invoice?.building?.[0]?.name}
                                    </td>{' '}
                                    <td>
                                      {rcp?.payment_invoice?.unit?.length > 0
                                        ? rcp?.payment_invoice?.unit?.[0]?.unitNo
                                        : '-'}
                                    </td>
                                    <td className='text-start pe-0'>
                                      {rcp?.payment_invoice?.unit?.[0]?.propertyType === 0
                                        ? 'Residential'
                                        : rcp?.payment_invoice?.unit?.[0]?.propertyType === 1
                                        ? 'Commercial'
                                        : '-'}
                                    </td>
                                    <td className=''>
                                      {rcp?.payment_invoice?.tenancy?.[0]?.contractNo > 0
                                        ? rcp?.payment_invoice?.tenancy?.[0]?.contractNo
                                        : '-'}
                                    </td>
                                    <td>
                                      {rcp?.payment_invoice?.tenancy?.[0]?.totalAmount > 0 ? (
                                        <>
                                          AED{' '}
                                          {formatAmount(
                                            rcp?.payment_invoice?.tenancy?.[0]?.totalAmount
                                          )}
                                        </>
                                      ) : (
                                        <>AED 0</>
                                      )}
                                    </td>
                                    <td>
                                      {rcp?.payment_invoice?.payment_account?.length > 0
                                        ? rcp?.payment_invoice?.payment_account?.[0]?.accountNumber
                                        : '-'}
                                    </td>
                                  </tr>
                                )
                              })}
                            </>
                          ) : (
                            <tr>
                              {!isLoading ? (
                                <td colSpan={4} className='text-center'></td>
                              ) : (
                                <td colSpan={4} className='text-center'>
                                  <div
                                    className='d-flex justify-content-center align-items-center w-100'
                                    style={{height: '25vh'}}
                                  >
                                    <CircularProgress style={{color: '#007a59'}} />
                                  </div>
                                </td>
                              )}
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default InvoicesPopup
