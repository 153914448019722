import React from 'react'
import {useState, useEffect, useRef} from 'react'
import {Row, Col, Modal} from 'react-bootstrap'
import leftArrow from '../../../img/arrowleft.svg'
import {useNavigate} from 'react-router-dom'
import {Form} from 'react-bootstrap'
import lock from '../../../img/padlock.png'
import edit from '../../../img/edit.png'
import bin from '../../../img/bin.png'
import Swal from 'sweetalert2'
import close from '../../../img/close.png'
import backArrow from '../../../img/back-arrow.png'
import invoiceImg from '../../../img/invoice-blue.png'
import {ApiDelete, ApiGet, ApiPost, ApiPut, Bucket} from '../../../apiCommon/helpers/API/ApiData'
import submitImg from '../../../img/Submit-White.png'
import {SuccessToast, ErrorToast} from '../../../apiCommon/helpers/Toast'
import send from '../../../img/sendwhite.svg'
import editpen from '../../../img/pen.png'
import EditCorporateDetails from './EditCorporateDetailsModal'
import Switch from 'react-switch'
import deleteImg from '../../../img/delete.png'

import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
  convertToPixelCrop,
} from 'react-image-crop'
import {canvasPreview} from './CanvasPreview'
import 'react-image-crop/dist/ReactCrop.css'
import {useDebounceEffect} from './UseDebounceEffect'
import { Fancybox as fancybox } from "@fancyapps/ui";
import '@fancyapps/ui/dist/fancybox/fancybox.css'
import { capitalizeFirstLetter } from '../../../Utilities/utils'

// This is to demonstate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
function centerAspectCrop(mediaWidth: number, mediaHeight: number, aspect: number) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: 'px',
        width: 180,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  )
}

const ReceiptDesign = () => {
  const navigate = useNavigate()

  const [editDetails, setEditDetails] = useState<any>(false)
  const [trnModal, setTRNModal] = useState<any>(false)
  const [trn, setTRN] = useState<any>('1234567890')
  const [trnError, setTRNError] = useState<any>(false)
  const [logo, setLogo] = useState<any>(null)
  const [receiptCompany, setReceiptCompany] = useState<any>(null)

  const [imgSrc, setImgSrc] = useState('')
  const previewCanvasRef = useRef<HTMLCanvasElement>(null)
  const imgRef = useRef<HTMLImageElement>(null)
  const hiddenAnchorRef = useRef<HTMLAnchorElement>(null)
  const blobUrlRef = useRef('')
  const [crop, setCrop] = useState<Crop>()
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>()
  const [scale, setScale] = useState(1)
  const [rotate, setRotate] = useState(0)
  const [aspect, setAspect] = useState<number | undefined>(1 / 1)

  const [showCropped, setShowCropped] = useState<any>(false)

  //
  const onSelectFile = (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined) // Makes crop preview update between images.
      const reader = new FileReader()
      reader.addEventListener('load', () => setImgSrc(reader.result?.toString() || ''))
      reader.readAsDataURL(e.target.files[0])
    }
    setShowCropped(true)
  }

  //
  const onImageLoad = (e: any) => {
    if (aspect) {
      const {width, height} = e.currentTarget
      setCrop(centerAspectCrop(180, 180, aspect))
    }
  }

  // upload Cropped LOGO
  const getCroppedImage = () => {
    // console.log(previewCanvasRef.current)
    if (previewCanvasRef.current) {
      const croppedImage = previewCanvasRef.current.toDataURL('image/jpeg')
      // console.log(croppedImage)

      const body = {
        base64Image: croppedImage,
      }

      ApiPost('upload/base64/image/logo', body)
        .then((res) => {
          const body = {
            isReceipt: true,
            receiptLogo: res?.data?.data?.image,
            receiptCompany: {
              trn: receiptCompany?.trn,
              name: receiptCompany?.name,
              address: receiptCompany?.address,
              countryCode: receiptCompany?.countryCode,
              phoneNumber: receiptCompany?.phoneNumber,
              email: receiptCompany?.email,
            },
          }

          ApiPut('corporate/corporate_setting', body)
            .then((res) => {
              setShowCropped(false)
              SuccessToast('Company RECEIPT LOGO has been updated successfully!!!')
              getDetails()
            })
            .catch((err) => ErrorToast(err.message))
        })
        .catch((err) => ErrorToast(err.message))
    }
  }

  // function onDownloadCropClick() {
  //   if (!previewCanvasRef.current) {
  //     throw new Error('Crop canvas does not exist')
  //   }

  //   previewCanvasRef.current.toBlob((blob) => {
  //     if (!blob) {
  //       throw new Error('Failed to create blob')
  //     }
  //     if (blobUrlRef.current) {
  //       URL.revokeObjectURL(blobUrlRef.current)
  //     }
  //     blobUrlRef.current = URL.createObjectURL(blob)
  //     console.log(blobUrlRef.current)
  //     hiddenAnchorRef.current!.href = blobUrlRef.current
  //     hiddenAnchorRef.current!.click()
  //   })
  // }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop, scale, rotate)
      }
    },
    100,
    [completedCrop, scale, rotate]
  )

  // function handleToggleAspectClick() {
  //   if (aspect) {
  //     setAspect(undefined)
  //   } else if (imgRef.current) {
  //     const {width, height} = imgRef.current
  //     setAspect(16 / 9)
  //     const newCrop = centerAspectCrop(width, height, 16 / 9)
  //     setCrop(newCrop)
  //     // Updates the preview
  //     setCompletedCrop(convertToPixelCrop(newCrop, width, height))
  //   }
  // }

  // upload logo
  const onFilesAddedSingle = async (event: any) => {
    const file = event.target.files[0]
    let fileURL = URL.createObjectURL(file)
    // setLogo(fileURL)

    var formdata = new FormData()
    formdata.append('image', file)

    ApiPost('upload/compress_image/cheque', formdata)
      .then((res) => {
        const body = {
          isReceipt: true,
          receiptLogo: res?.data?.data?.image,
          receiptCompany: {
            trn: receiptCompany?.trn,
            name: receiptCompany?.name,
            address: receiptCompany?.address,
            countryCode: receiptCompany?.countryCode,
            phoneNumber: receiptCompany?.phoneNumber,
            email: receiptCompany?.email,
          },
        }

        ApiPut('corporate/corporate_setting', body)
          .then((res) => {
            SuccessToast('Company RECEIPT LOGO has been updated successfully!!!')
            getDetails()
          })
          .catch((err) => ErrorToast(err.message))
      })
      .catch((err) => ErrorToast(err.message))
  }

  // delete logo
  const deleteLogo = () => {
    const body = {
      url: logo,
    }
    ApiPost(`upload/delete_file`, body)
      .then((res) => {
        setLogo(null)
        SuccessToast('Company RECEIPT LOGO has been removed successfully!!!')
        const body = {
          isReceipt: true,
          receiptLogo: null,
          receiptCompany: {
            trn: receiptCompany?.trn,
            name: receiptCompany?.name,
            address: receiptCompany?.address,
            countryCode: receiptCompany?.countryCode,
            phoneNumber: receiptCompany?.phoneNumber,
            email: receiptCompany?.email,
          },
        }

        ApiPut('corporate/corporate_setting', body)
          .then((res) => {
            getDetails()
          })
          .catch((err) => ErrorToast(err.message))
      })
      .catch((err) => {
        ErrorToast(err.message)
        console.log('err', err)
      })
  }

  //
  const getDetails = () => {
    ApiGet('corporate/corporate_setting')
      .then((res) => {
        setReceiptCompany(res?.data?.data?.receiptCompany)
        setLogo(res?.data?.data?.receiptLogo)
        setTRN(res?.data?.data?.receiptCompany?.trn)
      })
      .catch((err) => {
        console.log('err', err)
      })
  }

  useEffect(() => {
    getDetails()
  }, [])

  useEffect(() => {
    fancybox.bind('[data-fancybox]', {
      Toolbar: {
        display: {
          left: ['infobar'],
          middle: ['zoomIn', 'zoomOut', 'toggle1to1', 'rotateCCW', 'rotateCW', 'flipX', 'flipY'],
          right: ['slideshow', 'thumbs', 'close'],
        },
      },
    })
  }, []);

   // update TRN
   const updateTRN = () => {
    if (trn != '') {
      const body = {
        isReceipt: true,
        receiptLogo: logo,
        receiptCompany: {
          trn: trn,
          name: receiptCompany?.name,
          address: receiptCompany?.address,
          countryCode: receiptCompany?.countryCode,
          phoneNumber: receiptCompany?.phoneNumber,
          email: receiptCompany?.email,
        },
      }

      ApiPut('corporate/corporate_setting', body)
        .then((res) => {
          setTRNModal(false)
          SuccessToast('TRN has been updated successfully!!!')
          getDetails()
        })
        .catch((err) => ErrorToast(err.message))
    } else {
      setTRNError(true)
    }
  }

  return (
    <>
      {/* <div className='app-main flex-column flex-row-fluid' id='kt_app_main'> */}
      <div id='' className='px-2'>
        <div className='d-flex align-items-center mb-10'>
          <div className='d-flex align-items-center'>
            <span className='rounded-circle cursor-pointer' onClick={() => navigate('/settings')}>
              <img src={backArrow} height={14} width={14} className='me-2' />
            </span>
            <h2 className='page-heading m-0 head-text'>Receipt</h2>
          </div>
          <div className='ms-auto'></div>
          <div className='ms-5'>
            <img src={invoiceImg} height='40px' width='30px' />
          </div>
        </div>

        {/* Tabs */}
        <div
          className='d-flex align-items-center gap-2 gap-lg-5 justify-content-center align-items-center'
          style={{marginBottom: '35px'}}
        >
          <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2'>
            <li className='nav-item'>
              <a
                className='nav-link text-active-primary pb-2 me-5'
                data-bs-toggle='tab'
                onClick={() => navigate('/settings/receipt')}
              >
                Receipt No. Generator
              </a>
            </li>
            <li className='nav-item'>
              <a
                className='nav-link text-active-primary pb-2 mx-5 active'
                data-bs-toggle='tab'
                onClick={() => navigate('/settings/receipt-design')}
              >
                Receipt Design
              </a>
            </li>
          </ul>
        </div>

        {/*  */}
        <Row className='mb-3 d-none d-md-flex'>
          <Col xs={7} sm={7} md={7} lg={6}></Col>
          <Col  xs={5} sm={5} md={5} lg={6} className='text-center'>
            <h2 className='page-heading m-0 head-text'>Receipt Preview</h2>
          </Col>
        </Row>

        <Row>
          <Col md={6} className='pe-5'>
            <Row className='mb-3'>
              <Col md={3}>
                {/* Logo */}
                <div className='d-flex mb-3 '>
                  {logo == null ? (
                    <>
                      <label
                        htmlFor='media'
                        className='btn btn-sm fw-bold form-label m-0 upload text-center pb-5'
                        style={{
                          height: '180px',
                          minWidth: '180px',
                          backgroundColor: '#8a8aa3',
                          fontSize: '22px',
                          // paddingLeft: '40px',
                          color: 'white',
                        }}
                      >
                        <img />
                        <b
                          style={{
                            fontSize: '26px',
                            display: 'block',
                            marginTop: '-7px',
                            fontWeight: '700',
                            marginBottom: '5px',
                          }}
                        >
                          LOGO
                        </b>
                        Click to upload
                      </label>

                      <input
                        type='file'
                        hidden
                        id='media'
                        className='form-control form-control-solid'
                        name='passport'
                        onChange={onSelectFile}
                      />
                    </>
                  ) : (
                    <div className='d-flex'>
                      <img
                        src={`${Bucket}${logo}`}
                        height='180'
                        width='180'
                        style={{
                          borderRadius: '7px',
                          border: '2px solid black',
                          objectFit: 'contain',
                          cursor:'pointer',
                        }}
                        data-fancybox
                        data-src={`${Bucket}${logo}`}
                      />

                      <div>
                        <label
                          htmlFor='media'
                          className='fw-bold form-label m-0 upload text-center pb-5 bg-white'
                          style={{
                            height: '25px',
                            width: '25px',
                            border: '1px solid #48a794 ',
                            borderRadius: '4px',
                            transform: 'translate(-45px,-10px)',
                          }}
                        >
                          <img
                            src={editpen}
                            alt=''
                            className=''
                            style={{cursor: 'pointer', height: '18px', width: '18px'}}
                          />
                        </label>

                        <input
                          type='file'
                          hidden
                          id='media'
                          className='form-control form-control-solid'
                          name='passport'
                          onChange={onSelectFile}
                        />
                      </div>
                      <div>
                        <div
                          className='fw-bold form-label m-0 upload text-center pb-5 bg-white'
                          style={{
                            height: '25px',
                            width: '25px',
                            border: '1px solid crimson',
                            borderRadius: '4px',
                            transform: 'translate(-38px,-10px)',
                          }}
                          onClick={() => deleteLogo()}
                        >
                          <img
                            src={deleteImg}
                            alt=''
                            className=''
                            style={{
                              cursor: 'pointer',
                              height: '18px',
                              width: '18px',
                              marginLeft: '2px',
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </Col>
              <Col md={9} className='ps-0'>
              </Col>
            </Row>

              {/* TRN */}
              <div className='card px-5 py-5 mt-5'>
              <div className='d-flex'>
                <h2 className='top head-text '>Tax Registration Number (TRN)</h2>
                <img
                  src={editpen}
                  alt=''
                  className='ms-auto me-2'
                  style={{cursor: 'pointer', height: '20px', width: '20px'}}
                  onClick={() => {
                    setTRNModal(true)
                  }}
                />
              </div>

              <div className='d-flex grid_big_col py-2 fs-7'>
                <label
                  htmlFor='exampleFormControlInput1'
                  className='form-label blue-label fs-7'
                  style={{minWidth: '105px'}}
                >
                  TRN
                </label>
                <div className='d-flex'>
                  {' '}
                  {receiptCompany?.trn != null ? receiptCompany?.trn : '-'}
                </div>
              </div>
            </div>

            {/* Corporate Details */}
            <div className='card px-5 py-5 mt-5'>
              <div className='d-flex'>
                <h2 className='top head-text '>Corporate Details</h2>
                <img
                  src={editpen}
                  alt=''
                  className='ms-auto me-2'
                  style={{cursor: 'pointer', height: '20px', width: '20px'}}
                  onClick={() => {
                    setEditDetails(true)
                  }}
                />
              </div>

              <div className='d-flex grid_big_col py-2 fs-7'>
                <label
                  htmlFor='exampleFormControlInput1'
                  className='form-label blue-label fs-7'
                  style={{minWidth: '115px'}}
                >
                  Name
                </label>
                <div className='d-flex'>
                  {' '}
                  {receiptCompany?.name != null ? receiptCompany?.name : '-'}
                </div>
              </div>

              <div className='d-flex grid_big_col py-2 fs-7'>
                <label
                  htmlFor='exampleFormControlInput1'
                  className='form-label blue-label fs-7'
                  style={{minWidth: '115px'}}
                >
                  Address
                </label>
                <div className='d-flex'>
                  {receiptCompany?.address != null ? receiptCompany?.address : '-'}
                </div>
              </div>

              <div className='d-flex grid_big_col py-2 fs-7'>
                <label
                  htmlFor='exampleFormControlInput1'
                  className='form-label blue-label fs-7'
                  style={{minWidth: '115px'}}
                >
                  Contact No
                </label>
                <div className='d-flex'>
                  {receiptCompany?.phoneNumber != null
                    ? receiptCompany?.countryCode + ' ' + receiptCompany?.phoneNumber
                    : '-'}
                </div>
              </div>

              <div className='d-flex grid_big_col py-2 fs-7'>
                <label
                  htmlFor='exampleFormControlInput1'
                  className='form-label blue-label fs-7'
                  style={{minWidth: '115px'}}
                >
                  Email
                </label>
                <div className='d-flex'>
                  {' '}
                  {receiptCompany?.email != null ? receiptCompany?.email : '-'}
                </div>
              </div>
            </div>
          </Col>
          <Col md={6} className=' p-4 ps-6 pe-7'>
            {' '}
            <Row>
              <Col xs={12} className='card'>
            <Row className='mt-2'>
              <Col xs={6} sm={6} md={6} lg={6}>
                <div className='d-flex mb-3 mt-5 pt-5 '>
                  {logo == null ? (
                    <>
                     
                    </>
                  ) : (
                    <img
                      src={`${Bucket}${logo}`}
                      height='110'
                      width='110'
                      style={{borderRadius: '7px'}}
                    />
                  )}
                </div>
              </Col>
              <Col xs={6} sm={6} md={6} lg={6} className='pe-4'>
                <div className='d-flex align-items-center my-2'>
                 
                </div>
                <div className='d-flex align-items-center my-2 '>
                  <h1 className='ms-auto mb-0'>RECEIPT</h1>
                </div>

                {/*  */}
                <div className='d-flex align-items-center justify-content-start my-2'>
                  <label
                    className='mb-1 me-3'
                    style={{
                      minWidth: '100px',
                      fontSize: '1rem',
                      color: '#516176',
                      fontWeight: '600',
                    }}
                  >
                    Invoice No.
                  </label>
                  <div className='ms-auto' style={{fontWeight: '500'}}>
                    1223456
                  </div>
                </div>

                {/*  */}
                <div className='d-flex align-items-center justify-content-start my-2'>
                  <label
                    className='mb-1 me-3 '
                    style={{
                      minWidth: '100px',
                      fontSize: '1rem',
                      color: '#516176',
                      fontWeight: '600',
                    }}
                  >
                    Receipt No
                  </label>
                  <div className='ms-auto' style={{fontWeight: '500'}}>
                    {' '}
                    7654321
                  </div>
                </div>

                <div className='d-flex align-items-center justify-content-start my-2'>
                  <label
                    className='mb-1 me-3 '
                    style={{
                      minWidth: '100px',
                      fontSize: '1rem',
                      color: '#516176',
                      fontWeight: '600',
                    }}
                  >
                    Date Paid
                  </label>
                  <div className='ms-auto' style={{fontWeight: '500'}}>
                    2023-08-22
                  </div>
                </div>
              </Col>
            </Row>
            <div className='mt-5 pb-5 d-flex' style={{borderBottom: '2px dashed lightgray'}}>
              <div className='text-end ms-auto ' style={{fontWeight: '600', width: '52%'}}>
                <p style={{marginBottom: '0.1rem'}}>
                  {' '}
                  {receiptCompany?.name != null ? receiptCompany?.name : '-'}
                </p>
                <p style={{marginBottom: '0.1rem'}}>
                  {' '}
                  {receiptCompany?.address != null ? receiptCompany?.address : '-'}
                </p>

                <p style={{marginBottom: '0.1rem'}}>
                  {receiptCompany?.phoneNumber != null
                    ? receiptCompany?.countryCode + ' ' + receiptCompany?.phoneNumber
                    : '-'}
                </p>
                <p style={{marginBottom: '0.1rem'}}>
                  {receiptCompany?.email != null ? receiptCompany?.email : '-'}
                </p>
              </div>
            </div>
            <table
              className='table align-middle fs-6 gy-5 mt-3 pb-5'
              id='kt_ecommerce_sales_table'
              style={{borderBottom: '1.5px dashed lightgray'}}
            >
              <thead>
                <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                  <th className='min-w-10px'>#</th>
                  <th className='min-w-50px'>Item</th>
                  <th className='min-w-50px'>Total Amount</th>
                  <th className='min-w-50px'>Amount Paid</th>
                  <th className='min-w-50px'>Prior Amount Paid</th>
                  <th className='min-w-50px'>Outstanding Amount</th>
                </tr>
              </thead>
              <tbody style={{fontWeight: '500'}}>
                <tr style={{borderBottom: '1px solid lightgray', height: '45px'}}>
                  <td className='py-2'>1</td>
                  <td className='py-2'>Rent</td>
                  <td className='py-2'>500</td>
                  <td className='py-2'>100</td>
                  <td className='py-2'>200</td>
                  <td className='py-2'>200</td>
                </tr>

                <tr style={{height: '50px'}}>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className='py-2 ps-5' style={{color: '#B5B5C3'}}>
                    Total Amount
                  </td>
                  <td className='py-2'>300</td>
                </tr>

                <tr style={{height: '30px'}}>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className='py-2 ps-5' style={{color: '#B5B5C3'}}>
                    Outstanding Amount
                  </td>
                  <td className='py-2'>200</td>
                </tr>
                <tr style={{height: '50px'}} className='pb-4'>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className='py-2 ps-5'>Amount Paid</td>
                  <td className='py-2 fs-3' style={{fontWeight: '800px'}}>
                    {' '}
                    <b>AED 100</b>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className='d-flex ms-2'>
              <div style={{width: '50%'}}>
                <div className='d-flex my-2 mb-2'>
                  <div className=''>
                    <label
                      className='mb-1 me-1 fs-4'
                      style={{minWidth: '160px', fontWeight: '700'}}
                    >
                      Payment Method
                    </label>
                  </div>
                  <div className=''>
                    <label
                      className='mb-1 me-1 fs-6 pt-1'
                      style={{minWidth: '160px', fontWeight: '500'}}
                    >
                      Bank
                    </label>
                  </div>
                </div>

                <>
                  <div className='d-flex align-items-center mb-3'>
                    <label
                      className='mb-1 me-1'
                      style={{minWidth: '110px', maxWidth: '100px', fontWeight: '500'}}
                    >
                      Account Name
                    </label>
                    <b> Test Account</b>
                  </div>
                  <div className='d-flex align-items-center mb-3'>
                    <label
                      className='mb-1 me-1'
                      style={{minWidth: '110px', maxWidth: '110px', fontWeight: '500'}}
                    >
                      IBAN
                    </label>
                    <b> 7836637263726373</b>
                  </div>
                  <div className='d-flex align-items-center mb-3'>
                    <label
                      className='mb-1 me-1'
                      style={{minWidth: '110px', maxWidth: '110px', fontWeight: '500'}}
                    >
                      Account No.
                    </label>
                    <b> 19839931</b>
                  </div>
                  <div className='d-flex align-items-center mb-3'>
                    <label
                      className='mb-1 me-1'
                      style={{minWidth: '110px', maxWidth: '110px', fontWeight: '500'}}
                    >
                      Bank Name
                    </label>
                    <b> test bank</b>
                  </div>
                </>
              </div>
            </div>
            </Col>
            </Row>


          </Col>
        </Row>
      </div>

      {/*  */}
      {editDetails && (
        <EditCorporateDetails
          show={editDetails}
          handleClose={() => {
            setEditDetails(false)
          }}
          companyDetails={receiptCompany}
          getDetails={getDetails}
          type={'receipt'}
          logo={logo}
        />
      )}

          {/* MODAL TO CROP LOGO */}
      {showCropped && (
        <Modal
          id='kt_modal_create_app'
          tabIndex={-1}
          aria-hidden='true'
          dialogClassName='modal-dialog modal-dialog-centered mw-600px mx-auto'
          show={showCropped}
          onHide={() => setShowCropped(false)}
          backdrop={true}
          size='sm'
        >
          <div
            className='modal-header text-white ps-5 pe-1 header-style'
            style={{zIndex: '1', opacity: '0.85', borderBottom: '0px', minHeight: '93px'}}
          >
            <Row className='w-100'>
              <Col  xs={8} sm={8} md={8}>
                <h2 className='mx-0 my-1 mt-2 text-white'>Receipt Logo </h2>
              </Col>
              <Col  xs={4} sm={4} md={4} className='text-end pe-0'>
                <div
                  className='btn btn-sm btn-icon btn-active-color-primary ps-auto modal-header-cross'
                  onClick={() => setShowCropped(false)}
                >
                  <img className='svg-icon-1 text-black' src={close} width='14px' height='14px' />
                </div>
              </Col>
            </Row>
          </div>

          <div className='header-bg'>
            <Row className='mx-2'>
              <Col md={9}></Col>
              <Col md={2} className='pt-2' style={{paddingLeft: '2.8rem'}}>
                {/* <img src={latePayment} height='80px' width='80px' className='me-3 ms-5' /> */}
              </Col>
            </Row>
          </div>
          <div
            className='modal-body py-lg-10 px-lg-10 pt-lg-7'
            style={{transform: 'translate(0px, -80px)', marginBottom: '-80px'}}
          >
            <Row className='align-items-end'>
              <Col lg={12} md={12} className='align-items-center text-center'>
                <button
                  className='btn btn-sm fw-bold ms-3 ps-2 text-white'
                  style={{
                    width: '185px',
                    fontSize: '1.2rem',
                    paddingRight: '55px',
                    borderRadius: '2px',
                    backgroundColor: '#35475e',
                    boxShadow: '0px 0px 20px 0px #516176',
                  }}
                  onClick={() => getCroppedImage()}
                >
                  <img
                    src={submitImg}
                    style={{
                      height: '20px',
                      width: '20px',
                      marginRight: '15px',
                      marginLeft: '4px',
                      marginTop: '-3px',
                    }}
                  />{' '}
                  {'  '}
                  Upload
                </button>
              </Col>
            </Row>

            <Row className='mt-4'>
              <div className='Crop-Controls'>
                {/* <input type="file" accept="image/*" onChange={onSelectFile} /> */}
                {/* <div>
          <label htmlFor="scale-input">Scale: </label>
          <input
            id="scale-input"
            type="number"
            step="0.1"
            value={scale}
            disabled={!imgSrc}
            onChange={(e) => setScale(Number(e.target.value))}
          />
        </div> */}
              </div>
              {!!imgSrc && (
                <ReactCrop
                  crop={crop}
                  onChange={(_, percentCrop) => setCrop(percentCrop)}
                  onComplete={(c) => setCompletedCrop(c)}
                  aspect={aspect}
                  locked={true}
                  className='p-0'
                >
                  <img
                    ref={imgRef}
                    alt='Crop me'
                    src={imgSrc}
                    // style={{transform: `scale(${scale}) rotate(${rotate}deg)`}}
                    onLoad={onImageLoad}
                  />
                </ReactCrop>
              )}
              {!!completedCrop && (
                <>
                  <div className='d-none'>
                    <canvas
                      ref={previewCanvasRef}
                      style={{
                        border: '1px solid black',
                        objectFit: 'contain',
                        width: completedCrop.width,
                        height: completedCrop.height,
                      }}
                    />
                  </div>
                  {/* <div>
                    <button onClick={onDownloadCropClick}>Download Crop</button>
                    <a
                      ref={hiddenAnchorRef}
                      download
                      style={{
                        position: 'absolute',
                        top: '-200vh',
                        visibility: 'hidden',
                      }}
                    >
                      Hidden download
                    </a>
                  </div> */}
                </>
              )}
            </Row>
          </div>
        </Modal>
      )}

      {/*  */}

      {/*  */}
      {trnModal && (
        <Modal
          id='kt_modal_create_app'
          tabIndex={-1}
          aria-hidden='true'
          dialogClassName='modal-dialog modal-dialog-centered mw-600px mx-auto'
          show={trnModal}
          onHide={() => setTRNModal(false)}
          backdrop={true}
          size='sm'
        >
          <div
            className='modal-header text-white ps-5 pe-1 header-style'
            style={{zIndex: '1', opacity: '0.85', borderBottom: '0px', minHeight: '93px'}}
          >
            <Row className='w-100'>
              <Col xs={8} sm={8} md={8}>
                <h2 className='mx-0 my-1 mt-2 text-white'>Tax Registration Number </h2>
              </Col>
              <Col xs={4} sm={4} md={4} className='text-end pe-0'>
                <div
                  className='btn btn-sm btn-icon btn-active-color-primary ps-auto modal-header-cross'
                  onClick={() => setTRNModal(false)}
                >
                  <img className='svg-icon-1 text-black' src={close} width='14px' height='14px' />
                </div>
              </Col>
            </Row>
          </div>

          <div className='header-bg'>
            <Row className='mx-2'>
              <Col md={9}></Col>
              <Col md={2} className='pt-2' style={{paddingLeft: '2.8rem'}}>
                {/* <img src={latePayment} height='80px' width='80px' className='me-3 ms-5' /> */}
              </Col>
            </Row>
          </div>
          <div
            className='modal-body py-lg-10 px-lg-10 pt-lg-7'
            style={{transform: 'translate(0px, -80px)', marginBottom: '-65px'}}
          >
            <Row className='align-items-end'>
              <Col xs={12} sm={6} md={6} className='align-items-center mt-3'>
                <label className='required mb-2 label-color'>TRN</label>
                <input
                  style={
                    trn == ''
                      ? {
                          border: '0.1rem solid #bec3cb',
                          borderRadius: '2px',
                          padding: '0.7rem 1rem',
                        }
                      : {
                          border: '0.1rem solid #384a62 ',
                          borderRadius: '2px',
                          padding: '0.7rem 1rem',
                        }
                  }
                  value={trn}
                  type='text'
                  placeholder='Enter TRN'
                  name='item_id'
                  onChange={(e) => {
                    let newVal : any = capitalizeFirstLetter(e.target.value)
                    setTRN(newVal)
                    setTRNError(false)
                  }}
                  className={
                    trnError
                      ? 'form-control form-control-solid-bg border-danger'
                      : 'form-control form-control-solid-bg'
                  }
                />
              </Col>
              <Col xs={12} sm={6} md={6} className='align-items-center text-end mt-3'>
                <button
                  className='btn btn-sm fw-bold text-white'
                  style={{
                    fontSize: '1.2rem',
                    paddingRight: '55px',
                    borderRadius: '2px',
                    backgroundColor: '#35475e',
                    boxShadow: '0px 0px 20px 0px #516176',
                  }}
                  onClick={() => updateTRN()}
                >
                  <img
                    src={submitImg}
                    style={{
                      height: '18px',
                      width: '18px',
                      marginRight: '20px',
                      marginLeft: '-7px',
                      marginTop: '-3px',
                    }}
                  />
                  Update
                </button>
              </Col>
            </Row>
          </div>
        </Modal>
      )}

    </>
  )
}

export default ReceiptDesign
