import React from 'react'
import { UseFormRegister, FieldValues, UseFormSetValue, UseFormTrigger } from 'react-hook-form'

interface InputRequiredProps {
  register: UseFormRegister<FieldValues>
  errors?: any // Adjust the type according to your specific error structure
  value: string
  setValue: UseFormSetValue<FieldValues>
  trigger: UseFormTrigger<FieldValues>
  onBlur: () => void
  onChange: (value: string) => void // Callback function to pass updated value to parent
  placeholder: string
  name: string
  pattern: RegExp
  maxLength: number
  style?: React.CSSProperties
  className?: string,
  isRequired?: boolean
}

const InputRequired: React.FC<InputRequiredProps> = ({
  register,
  errors,
  value,
  setValue,
  trigger,
  onBlur,
  onChange,
  placeholder,
  name,
  pattern,
  maxLength,
  style,
  className,
  isRequired = false
}) => {
  const [patternMismatched, setPatternMismatched] = React.useState<boolean>(false)
  const [lengthError, setLengthError] = React.useState<boolean>(false)

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value

    // Check pattern and length
    setPatternMismatched(!pattern.test(newValue))
    setLengthError(newValue.length > maxLength)

    // Update value if valid
    if (pattern.test(newValue) && newValue.length <= maxLength) {
      const formattedValue = newValue.charAt(0).toUpperCase() + newValue.slice(1)
      setValue(name, formattedValue, { shouldValidate: true })
      onChange(formattedValue) // Pass the updated value to the parent
    }

    // Manually trigger validation after the value change
    await trigger(name)
  }

  return (
    <div>
      {/* <div className='row ms-0 mt-4'> */}
        <div className='d-block'>
          <input
            // {...register(name)}
            {...register(name, {
              required: isRequired ? 'This field is required' : false, // Add required validation based on the flag
              pattern: {
                value: pattern,
                message: 'Only letters, numbers, periods, underscores, hyphens, and spaces are allowed'
              },
              maxLength: {
                value: maxLength,
                message: `Maximum ${maxLength} characters allowed`
              }
            })}
            type='text'
            className={`form-control form-control-solid mytest tenancy-control mb-1 bg-white text-end ${className}`}
            name={name}
            value={value}
            style={style}
            placeholder={placeholder}
            onChange={handleChange}
            onBlur={onBlur}
            autoComplete='off'
          />
          
        </div>

        {/* Display validation messages */}
        {patternMismatched && (
          <div className='col-12 px-0 d-flex'>
            <p style={{ color: 'var(--kt-danger)', gridColumn: '2/2' }} className='m-0 mt-1 fs-5'>
              {'Only letters, numbers, periods, underscores, hyphens, and spaces are allowed'}
            </p>
          </div>
        )}

        {lengthError && (
          <div className='col-12 px-0 d-flex'>
            <p style={{ color: 'var(--kt-danger)', gridColumn: '2/2' }} className='m-0 mt-1 fs-5' >
              {`Maximum ${maxLength}  characters allowed`}
            </p>
          </div>
        )}

        {errors && errors[name] && typeof errors[name].message === 'string' && (
          <div className='col-12 px-0 d-flex'>
            <p style={{ color: 'var(--kt-danger)', gridColumn: '2/2' }} className='m-0 mt-1'>
              {errors[name].message}
            </p>
          </div>
        )}
      {/* </div> */}
    </div>
  )
}

export default InputRequired
