import React, {useEffect, useState, useRef, useCallback} from 'react'
import {Row, Col, OverlayTrigger, Tooltip} from 'react-bootstrap'
import {IoMdInformationCircleOutline} from 'react-icons/io'
import {MultiSelect} from 'react-multi-select-component'
import addGreen from '../../../../../../img/add.png'
import switchToTextImg from '../../../../../../img/switch-to-text.png'
import plusBlue from '../../../../../../img/add-blue.png'
import redCross from '../../../../../../img/remove.png'
import switchToListImg from '../../../../../../img/switch-to-list.png'
import trashImg from '../../../../../../img/trash.png'
import {
  capitalizeFirstLetter,
  formatAmount,
  preventExpAndSign,
  removeLeadingZeros,
} from '../../../../../../Utilities/utils'
import moment from 'moment'
import Swal from 'sweetalert2'
import {useParams} from 'react-router-dom'
import SelectList from '../../SelectItem'

interface PaymentItemsProps {
  incmData?: any
  updateParent?: any
  updateRequest?: any
  isEdit?: any
  from?: any
  updateLatePaymentType?: any
  updateParentAfterDelete?: any
  doNotApplyLatePaymentType?: any
  updateParentForAll?: any
  incmAPIDetails?: any
  isPrint?: boolean
}

const PaymentItems: React.FC<PaymentItemsProps> = ({
  incmData,
  updateParent,
  updateRequest,
  isEdit,
  from,
  updateLatePaymentType,
  updateParentAfterDelete,
  doNotApplyLatePaymentType,
  updateParentForAll,
  incmAPIDetails,
  isPrint = false,
}: PaymentItemsProps) => {
  const {flg} = useParams()
  const [dueDateError, setDueDateError] = useState<any>(false)
  const [latePaymentFeeStatus, setLatePaymentFeeStatus] = useState<any>(
    incmData?.latePaymentType != undefined && incmData?.latePaymentType != ''
      ? incmData?.latePaymentType
      : ''
  )
  const [latePaymentFeeObjectForAll, setLatePaymentFeeObjectForAll] = useState<any>([])

  const [latePaymentOptions, setLatePaymentOptions] = useState<any>([
    {label: 'Do Not Apply', value: '0', type: 'doNotApply', frequency: 'none'},
    {label: '1% - Daily', value: '1', type: 'percentage', frequency: 'daily'},
    {label: '5% - Daily', value: '5', type: 'percentage', frequency: 'daily'},
    {label: 'AED 500 - Daily', value: '500', type: 'fixed', frequency: 'daily'},
    {label: 'AED 200 - Weekly', value: '200', type: 'fixed', frequency: 'weekly'},
  ])

  const [latePaymentOptionsForAll, setLatePaymentOptionsForAll] = useState<any>([
    {label: '1% - Daily', value: '1', type: 'percentage', frequency: 'daily'},
    {label: '5% - Daily', value: '5', type: 'percentage', frequency: 'daily'},
    {label: 'AED 500 - Daily', value: '500', type: 'fixed', frequency: 'daily'},
    {label: 'AED 200 - Weekly', value: '200', type: 'fixed', frequency: 'weekly'},
  ])

  let isDuplicated: any = []
  const [selectedIndex, setSelectedIndex] = useState<any>()
  const [selectModal, setSelectModal] = useState<any>(false)

  const [paymentItems, setPaymentItems] = useState<any>(
    incmData?.items?.length > 0
      ? incmData?.items
      : [
          {
            id: '',
            name: '',
            paymentItemListId: '',
            amount: '',
            quantity: '',
            VATAmount: '',
            VATPercentage: '',
            property: undefined,
            totalAmount: '',
            outstandingAmount: '',
            amountPaid: 0,
            totalAmountPaid: 0,
          },
        ]
  )

  const handleAddExpenseItems = () => {
    const values = [...paymentItems]

    // Add the new item
    const newItem = {
      id: '',
      name: '',
      paymentItemListId: '',
      amount: '',
      quantity: '',
      VATAmount: '',
      VATPercentage: '',
      totalAmount: '',
      outstandingAmount: '',
      amountPaid: 0,
      totalAmountPaid: 0,
      frontendStorage: {
        isList: true,
        latePaymentObject: {},
        isLatePaymentItem: false,
        itemUniqueId: new Date().getTime(), // Unique ID for the new item
        paymentItemId: null,
        isLatePaymentItemAdded: false, // Indicates if a late payment item has been added
      },
      isLatePaymentItem: false,
      latePaymentFees: 0,
      latePaymentSelectionStatus: 0, // "notSelected": 0, "apply": 1, "notApply": 2
      latePaymentObject: {},
      oldLatePaymentCycleList: [],
      nextLatePaymentCycle: null,
    }

    // Check if there is already a late payment option selected in the dropdown
    if (latePaymentFeeObjectForAll && latePaymentFeeObjectForAll.length > 0) {
      newItem.latePaymentObject = latePaymentFeeObjectForAll
      newItem.frontendStorage.latePaymentObject = latePaymentFeeObjectForAll
    }

    // Add the new item to the list
    values.push(newItem)

    // Update the payment items state
    setPaymentItems(values)
    updateParent(values) // Call the parent update function
    // Check if there is already a late payment option selected in the dropdown
    if (latePaymentFeeObjectForAll && latePaymentFeeObjectForAll.length > 0) {
      latePaymentFeeCalculationForAll(latePaymentFeeObjectForAll[0], values)
    }
  }

  // if late payment field was added, then changing amount, quantity or VAt should update late payment fee
  const latePaymentFeeCalculationFromOtherInputFields = (
    item: any,
    lateFeeOptions: any,
    ind: number,
    allItems: any
  ) => {
    console.log(item)
    if (allItems[ind].frontendStorage.isLatePaymentItemAdded == true) {
     
      const {amount = 0, quantity = 1, VATAmount = 0} = item
      const {type, frequency, value} = lateFeeOptions

      // Total cost including VAT
      const totalAmount = Number(amount) * Number(quantity) * (1 + Number(VATAmount) / 100)

      // Parse dueDate and get today's date
      const due = new Date(incmData?.dueDate)
      const today = new Date()

      // Calculate the number of days late (excluding the due date)
      const timeDifference = today.getTime() - due.getTime()
      let daysLate = Math.ceil(timeDifference / (1000 * 3600 * 24)) // Convert milliseconds to days

      // Exclude due date itself if daysLate > 0
      if (daysLate > 0) {
        daysLate -= 1
      } else {
        daysLate = 0 // If not late, daysLate should be 0
      }

      // Late fee calculation based on frequency
      const getFrequencyMultiplier = () => {
        switch (frequency) {
          case 'daily':
            return daysLate
          case 'weekly':
            return Math.ceil(daysLate / 7)
          case 'monthly':
            return Math.ceil(daysLate / 30)
          default:
            return 1
        }
      }

      const frequencyMultiplier = getFrequencyMultiplier()

      // Calculate late fee based on either percentage or fixed value
      let lateFee = 0
      if (type == 'percentage') {
        lateFee = (totalAmount * value * frequencyMultiplier) / 100
      } else if (type == 'fixed') {
        lateFee = value * frequencyMultiplier
      }

      // Return total amount including late fee
      // return totalAmount + lateFee;

      allItems[ind].latePaymentFees = lateFee

      if (allItems[ind].frontendStorage.isLatePaymentItemAdded == true) {
        let values = [...allItems]

        values?.map((item: any, index: any) => {
          if (allItems[ind]?.frontendStorage?.itemUniqueId == item?.frontendStorage?.mainItemId) {
            allItems[index].name = `Late Payment - item ${
              allItems[ind].frontendStorage?.paymentItemId
                ? allItems[ind].frontendStorage?.paymentItemId
                : allItems[ind]?.name
            }  (${moment(today)?.format('DD.MM.YYYY')})`
            allItems[index].amount = isNaN(Number(lateFee) / Number(frequencyMultiplier)) ? 0 : (Number(lateFee) / Number(frequencyMultiplier))
            allItems[index].totalAmount = lateFee
            allItems[index].quantity = frequencyMultiplier
          }
        })
      }


      allItems[ind].frontendStorage.isLatePaymentItemAdded = true

      setPaymentItems(allItems)
      updateParent(allItems)
    }
  }


  // calculating late payment fee - when "Apply to selected items" is checkedmi
  const latePaymentFeeCalculation = (
    item: any,
    lateFeeOptions: any,
    ind: number,
    allItems: any
  ) => {
    // console.log(lateFeeOptions)

    let idsToDelete: any = []

    if (lateFeeOptions?.type == 'doNotApply' || lateFeeOptions == undefined) {
      allItems[ind].frontendStorage.isLatePaymentItemAdded = false
      allItems[ind].frontendStorage.latePaymentFees = 0

      let values: any = [...allItems]

      values?.map((item: any, index: any) => {
        if (allItems[ind]?.frontendStorage?.itemUniqueId == item?.frontendStorage?.mainItemId) {
          if (allItems[ind]?._id != undefined) idsToDelete?.push(allItems[index]?._id)
          allItems.splice(index, 1)
        }
      })
    } else {
      const {amount, quantity, VATAmount} = item
      const {type, frequency, value} = lateFeeOptions

      // Total cost including VAT
      const totalAmount = amount * quantity * (1 + VATAmount / 100)

      // Parse dueDate and get today's date
      const due = new Date(incmData?.dueDate)
      const today = new Date()

      // Calculate the number of days late (excluding the due date)
      const timeDifference = today.getTime() - due.getTime()
      let daysLate = Math.ceil(timeDifference / (1000 * 3600 * 24)) // Convert milliseconds to days

      // Exclude due date itself if daysLate > 0
      if (daysLate > 0) {
        daysLate -= 1
      } else {
        daysLate = 0 // If not late, daysLate should be 0
      }

      // Late fee calculation based on frequency
      const getFrequencyMultiplier = () => {
        switch (frequency) {
          case 'daily':
            return daysLate
          case 'weekly':
            return Math.ceil(daysLate / 7)
          case 'monthly':
            return Math.ceil(daysLate / 30)
          default:
            return 1
        }
      }

      const frequencyMultiplier = getFrequencyMultiplier()

      // console.log(frequencyMultiplier)

      // Calculate late fee based on either percentage or fixed value
      let lateFee = 0
      if (type == 'percentage') {
        lateFee = (totalAmount * value * frequencyMultiplier) / 100
      } else if (type == 'fixed') {
        lateFee = value * frequencyMultiplier
      }

      // Return total amount including late fee
      // return totalAmount + lateFee;

      allItems[ind].latePaymentFees = lateFee

      if (allItems[ind].frontendStorage.isLatePaymentItemAdded == true) {
        let values = [...allItems]

        values?.map((item: any, index: any) => {
          if (allItems[ind]?.frontendStorage?.itemUniqueId == item?.frontendStorage?.mainItemId) {
            allItems[index].name = `Late Payment - item ${
              allItems[ind].frontendStorage?.paymentItemId
                ? allItems[ind].frontendStorage?.paymentItemId
                : allItems[ind].name
            }  (${moment(today)?.format('DD.MM.YYYY')})`
            allItems[index].amount = isNaN(Number(lateFee) / Number(frequencyMultiplier)) ? 0 : (Number(lateFee) / Number(frequencyMultiplier))
            allItems[index].totalAmount = lateFee
            allItems[index].quantity = frequencyMultiplier
          }
        })
      } else {
        allItems?.push({
          id: '',
          name: `Late Payment - item ${
            allItems[ind].frontendStorage?.paymentItemId
              ? allItems[ind].frontendStorage?.paymentItemId
              : allItems[ind].name
          }  (${moment(today)?.format('DD.MM.YYYY')})`,
          paymentItemListId: '',
          amount: isNaN(Number(lateFee) / Number(frequencyMultiplier)) ? 0 : (Number(lateFee) / Number(frequencyMultiplier)),
          quantity: frequencyMultiplier,
          VATAmount: 0,
          VATPercentage: 0,
          totalAmount: lateFee,
          outstandingAmount: '',
          amountPaid: 0,
          totalAmountPaid: 0,
          frontendStorage: {
            isList: true,
            latePaymentObject: {},
            isLatePaymentItem: true,
            itemUniqueId: new Date().getTime(),
            paymentItemId: null,
            mainItemId: allItems[ind].frontendStorage.itemUniqueId,
          },
          isLatePaymentItem: true,
          latePaymentFees: 0,
          latePaymentSelectionStatus: 0, // "notSelected": 0, "apply": 1, "notApply": 2
          latePaymentObject: {},
          oldLatePaymentCycleList: [],
          nextLatePaymentCycle: null,
          // paymentSubItemStatus:2,
        })
      }

      allItems[ind].frontendStorage.isLatePaymentItemAdded = true
    }

    setPaymentItems(allItems)
    updateParent(allItems)
    // updateParentAfterDelete(allItems, idsToDelete)
  }

 

  const latePaymentFeeCalculationForAll = (lateFeeOptions: any, allItems: any) => {
    let updatedValues: any = [...allItems]
    let values: any = [...allItems]

    let idsToDelete: any = []
    let indicesToDelete: number[] = []

    console.log('number','test')

    updatedValues?.forEach((itm: any, ind: any) => {
      if (updatedValues[ind].frontendStorage.isLatePaymentItem == false) {
        if (lateFeeOptions?.type === 'doNotApply' || lateFeeOptions === undefined) {
          updatedValues[ind].frontendStorage.isLatePaymentItemAdded = false
          updatedValues[ind].frontendStorage.latePaymentFees = 0

          values?.forEach((item: any, index: any) => {
            if (
              updatedValues[ind]?.frontendStorage?.itemUniqueId == item?.frontendStorage?.mainItemId
            ) {
              indicesToDelete.push(index) // Store the index to delete later
              if (item?._id !== undefined) idsToDelete.push({_id: item?._id})
            }
          })
        } else {
          const {amount = 0, quantity = 1, VATAmount = 0} = itm
          const {type, frequency, value} = lateFeeOptions
          // Total cost including VAT
          const totalAmount = Number(amount) * Number(quantity) * (1 + Number(VATAmount) / 100)
          
          // Parse dueDate and get today's date
          const due = new Date(incmData?.dueDate)
          const today = new Date()

          if (isNaN(due.getTime())) {
            console.error('Invalid dueDate:', incmData?.dueDate)
            return // Handle invalid date scenario
          }

          // Calculate the number of days late (excluding the due date)
          const timeDifference = today.getTime() - due.getTime()
          let daysLate = Math.ceil(timeDifference / (1000 * 3600 * 24)) // Convert milliseconds to days

          // Exclude due date itself if daysLate > 0
          if (daysLate > 0) {
            daysLate -= 1
          } else {
            daysLate = 0 // If not late, daysLate should be 0
          }

          // Late fee calculation based on frequency
          const getFrequencyMultiplier = () => {
            switch (frequency) {
              case 'daily':
                return daysLate
              case 'weekly':
                return Math.ceil(daysLate / 7)
              case 'monthly':
                return Math.ceil(daysLate / 30)
              default:
                console.warn('Unknown frequency:', frequency)
                return 1
            }
          }

          const frequencyMultiplier = getFrequencyMultiplier()

          // Calculate late fee based on either percentage or fixed value
          let lateFee = 0
          if (type === 'percentage') {
            lateFee = (totalAmount * value * frequencyMultiplier) / 100
          } else if (type === 'fixed') {
            lateFee = value * frequencyMultiplier
          }

          updatedValues[ind].latePaymentFees = lateFee

          if (updatedValues[ind].frontendStorage.isLatePaymentItemAdded === true) {
            let tempValues = [...updatedValues]

            tempValues?.forEach((item: any, index: any) => {
              if (
                updatedValues[ind]?.frontendStorage?.itemUniqueId ==
                item?.frontendStorage?.mainItemId
              ) {
                updatedValues[index].name = `Late Payment - item ${
                  updatedValues[ind].frontendStorage?.paymentItemId
                    ? updatedValues[ind].frontendStorage?.paymentItemId
                    : updatedValues[ind].name
                }  (${today.toLocaleDateString('en-GB')})`
                updatedValues[index].amount = isNaN(Number(lateFee) / Number(frequencyMultiplier)) ? 0 : (Number(lateFee) / Number(frequencyMultiplier))
                updatedValues[index].totalAmount = lateFee
                updatedValues[index].quantity = frequencyMultiplier
              }
            })
          } else {
            updatedValues?.push({
              id: '',
              name: `Late Payment - item ${
                updatedValues[ind].frontendStorage?.paymentItemId
                  ? updatedValues[ind].frontendStorage?.paymentItemId
                  : updatedValues[ind].name
              }  (${today.toLocaleDateString('en-GB')})`,
              paymentItemListId: '',
              amount: isNaN(Number(lateFee) / Number(frequencyMultiplier)) ? 0 : (Number(lateFee) / Number(frequencyMultiplier)),
              quantity: Number(frequencyMultiplier),
              VATAmount: 0,
              VATPercentage: 0,
              totalAmount: lateFee,
              outstandingAmount: '',
              amountPaid: 0,
              totalAmountPaid: 0,
              frontendStorage: {
                isList: true,
                latePaymentObject: lateFeeOptions,
                isLatePaymentItem: true,
                itemUniqueId: new Date().getTime(),
                paymentItemId: null,
                mainItemId: updatedValues[ind].frontendStorage.itemUniqueId,
              },
              isLatePaymentItem: true,
              latePaymentFees: 0,
              latePaymentSelectionStatus: 0, // "notSelected": 0, "apply": 1, "notApply": 2
              latePaymentObject: lateFeeOptions,
              oldLatePaymentCycleList: [],
              nextLatePaymentCycle: null,
            })
          }

          console.log(updatedValues)
          updatedValues[ind].frontendStorage.isLatePaymentItemAdded = true
        }
      }
    })

    // Remove items marked for deletion
    updatedValues = updatedValues.filter((_: any, i: any) => !indicesToDelete.includes(i))

    if (lateFeeOptions?.type === 'doNotApply' || lateFeeOptions === undefined) {
      doNotApplyLatePaymentType(1, updatedValues, idsToDelete, lateFeeOptions)
    } else {
      setPaymentItems(updatedValues)
      updateParentForAll(updatedValues, lateFeeOptions)
    }
  }

  const handleRemoveIncomeItems = (ind: any) => {
    let id: any = paymentItems[ind]?._id
    const values = [...paymentItems]

    let ids: any = [paymentItems[ind]?._id]

    for (let i = paymentItems?.length - 1; i >= 0; i--) {
      // console.log(paymentItems[ind]?.frontendStorage?.paymentItemId)
      // console.log( paymentItems[i]?.frontendStorage?.mainItemId)
      if (
        paymentItems[i]?.frontendStorage?.mainItemId ==
        paymentItems[ind]?.frontendStorage?.itemUniqueId
      ) {
        // console.log(paymentItems[i])
        values.splice(i, 1)
        ids?.push(paymentItems[i]?._id)
      }
    }

    values.splice(ind, 1)
    setPaymentItems(values)
    if (flg == '1') updateParent(values)
    else updateParent(values)
    // else updateParentAfterDelete(values, ids)
  }

  React.useEffect(() => {
    setPaymentItems(incmData?.items)
  }, [incmData])

  const [totalAmount, setTotalAmount] = useState<any>({
    subTotal: 0,
    vat: 0,
    total: 0,
  })

  useEffect(() => {
    const calSubTotal = paymentItems?.reduce((acc: any, currVal: any) => {
      if (currVal?.amount && currVal?.quantity) {
        let totalAmountOfItem: any = 0
        let vatAmnt: any =
          ((Number(currVal?.amount) * Number(currVal?.quantity)) / 100) *
          Number(currVal?.VATPercentage)
        totalAmountOfItem = Number(currVal?.amount) * Number(currVal?.quantity) + vatAmnt
        return acc + Number(totalAmountOfItem)
      }
      return acc
    }, 0)

    const calVatTotal = paymentItems?.reduce((acc: any, currVal: any) => {
      if (currVal?.amount && currVal?.quantity && currVal?.VATAmount) {
        let totalVatAmount: any = 0
        let vatAmnt: any =
          ((Number(currVal?.amount) * Number(currVal?.quantity)) / 100) *
          Number(currVal?.VATPercentage)
        return acc + Number(vatAmnt)
      }
      return acc
    }, 0)
    updateRequest(calSubTotal + calVatTotal, calSubTotal, calVatTotal)
    setTotalAmount({
      ...totalAmount,
      subTotal: calSubTotal,
      vat: calVatTotal,
      total: calSubTotal + calVatTotal,
    })
  }, [paymentItems])

  useEffect(() => {
    setLatePaymentFeeStatus(incmData?.latePaymentType)
    setLatePaymentFeeObjectForAll(
      incmData?.latePaymentType == 1 &&
        incmData?.frontendStorage?.lateFeeForAll != undefined &&
        incmData?.frontendStorage?.lateFeeForAll?.label != undefined
        ? [incmData?.frontendStorage?.lateFeeForAll]
        : []
    )
  }, [incmData])

  const [checkedValues, setCheckedValues] = useState<any>([])
  let totalSelected: any = 0

  const deleteSelectedPaymentItems = () => {
    let updatePaymentItems = [...paymentItems] // Clone the original payment items array
    let itemsToDeleteIndices: number[] = [] // Array to hold the indices of selected items
    let itemsToDeleteUniqueIds: string[] = [] // Array to hold itemUniqueIds of selected normal items
    let deletedItemIds: string[] = [] // Array to hold _id of deleted normal and late items

    // First pass: Collect indices and uniqueIds of normal items to delete
    checkedValues?.forEach((isChecked: boolean, ind: number) => {
      if (isChecked) {
        const normalItem = paymentItems[ind]

        // Add the index of the normal item to the list of indices to delete
        itemsToDeleteIndices.push(ind)

        // Collect the _id of the normal item if it exists
        if (normalItem?._id) {
          deletedItemIds.push(normalItem._id) // Collect the _id of the normal item
        }

        // If the item has an itemUniqueId, add it to the uniqueIds list for later comparison
        if (normalItem?.frontendStorage?.itemUniqueId) {
          itemsToDeleteUniqueIds.push(normalItem.frontendStorage.itemUniqueId)
        }
      }
    })

    // Second pass: Remove all normal and associated late payment items
    updatePaymentItems = updatePaymentItems.filter((item: any, index: number) => {
      // Check if the current index is in the list of indices to delete
      if (itemsToDeleteIndices.includes(index)) {
        // Collect the _id of the normal item if it exists
        if (item?._id) {
          deletedItemIds.push(item._id) // Collect the _id of the normal item
        }
        return false // Remove normal item
      }

      // Check if the item is a late payment item and if its mainItemId is in the list of uniqueIds to delete
      if (
        item?.frontendStorage?.mainItemId &&
        itemsToDeleteUniqueIds.includes(item.frontendStorage.mainItemId)
      ) {
        // Collect the _id of the late payment item if it exists
        if (item?._id) {
          deletedItemIds.push(item._id) // Collect the _id of the late payment item
        }
        return false // Remove associated late payment item
      }

      // Keep the item if it's not flagged for deletion
      return true
    })

    // Update the state and parent component with the updated list
    setPaymentItems(updatePaymentItems)

  updateParent(updatePaymentItems)
    // else updateParentAfterDelete(updatePaymentItems, deletedItemIds)
  }

  useEffect(() => {
    let values: any = []
    for (let i = 0; i < incmData.items?.length; i++) {
      // if(!incmData.items[i]?.frontendStorage?.isLatePaymentItem){
      values[i] = false
      // }
    }

    setCheckedValues(values)
  }, [incmData?.items])

  const isDisabled = useCallback(() => {
    if (
      incmData?.items?.some((itm: any) => {
        return (
          itm?.frontendStorage?.isLatePaymentItem === false &&
          (itm?.name?.trim()?.length === 0 ||
            itm?.name === '' ||
            itm?.amount === '' ||
            itm?.amount === undefined ||
            Number(itm?.amount) === 0 ||
            itm?.quantity === '' ||
            itm?.quantity === undefined ||
            Number(itm?.quantity) === 0)
        )
      })
    ) {
      return true
    } else {
      return false
    }
  }, [incmData?.items])

  const intervalRef = useRef<NodeJS.Timer | null>(null)
  
  useEffect(() => {
    intervalRef.current = setInterval(() => {
      const checkboxes = document.querySelectorAll(
        '.property-test .multi-select-container .rmsc .item-renderer input[type=checkbox]'
      )
      // console.log(checkboxes)
      checkboxes.forEach((checkbox) => {
        checkbox.setAttribute('type', 'radio')
      })
    }, 100)

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
      }
    }
  }, [])

  return (
    <>
      {isEdit ? (
        <>
          <div>
            <Row className='mt-7 separator mx-0'></Row>
            <div className='mt-5 px-6'>
              <div className='d-flex align-items-start'>
                <p className='mb-0 me-3'>Late Payment Fees</p>
                <p className='mb-0 me-5'>
                  <span>
                    <OverlayTrigger
                      placement='bottom'
                      delay={{show: 250, hide: 400}}
                      overlay={
                        <Tooltip id='button-tooltip-3' className='custom-tooltip'>
                          <p className='mb-2'>
                            You can apply a late payment fee on your payment in the case
                            your payor did not settle the payment by the due date.
                          </p>
                          <p className='mb-2'>
                            You can select to apply late payment fees on an item level or
                            an overall payment level. Therefore, you will be able to set
                            different late payment fees for each item or apply one late
                            payment fee on all items at once.
                          </p>
                          <p className='mb-2'>
                            {' '}
                            Each late payment fee generated for each item will be added to
                            your payment items separately.
                          </p>
                        </Tooltip>
                      }
                    >
                      <span>
                        {' '}
                        <IoMdInformationCircleOutline className='ms-0 cursor-pointer exclam-circle' />
                      </span>
                    </OverlayTrigger>
                  </span>
                </p>
                <div className='d-flex align-items-center tst ms-10'>
                  <input
                    type='checkbox'
                    className='me-3 cursor-pointer form-check-input'
                    name='selected-item-name'
                    id='selected-item'
                    value='selected-item'
                    checked={incmData?.latePaymentType == 0}
                    onChange={(e: any) => {
                      if (incmData?.dueDate == null) {
                        setDueDateError(true)
                      } else {
                        setDueDateError(false)
                        // if (incmData?.latePaymentType === 0) {
                        //   setLatePaymentFeeStatus('') // Deselect if clicked again
                        //   let values = [...paymentItems]
                        //   const filteredValues = values.filter(
                        //     (item) => !item.frontendStorage.isLatePaymentItem
                        //   )
                        //   filteredValues.map((itm: any) => {
                        //     itm.frontendStorage.isLatePaymentItemAdded = false
                        //     itm.frontendStorage.latePaymentObject = {}
                        //   })
                        //   // Keep only normal items
                        //   setPaymentItems(filteredValues)
                        //   updateLatePaymentType(null, filteredValues, {})
                        // } else {
                        //   setLatePaymentFeeStatus(0)

                        //   updateLatePaymentType(0)
                        // }
                        if (incmData?.latePaymentType === 0) {
                          // Filter and update payment items
                          const filteredValues = paymentItems
                            .map((item: any) => {
                              if (!item.frontendStorage.isLatePaymentItem) {
                                item.frontendStorage.isLatePaymentItemAdded = false
                                item.frontendStorage.latePaymentObject = {}
                                return item
                              }
                            })
                            .filter(Boolean) // Remove undefined items

                          setPaymentItems(filteredValues)
                          updateLatePaymentType(null, filteredValues, {})
                        } else {
                          updateLatePaymentType(0, paymentItems)
                        }
                      }
                    }}
                  />
                  <h4 className='m-0'>
                    <label htmlFor='selected-item' className='fs-4'>
                      Apply to selected items
                    </label>
                  </h4>
                </div>
                <div className='ms-10 ps-5'>
                  <div className='d-flex align-items-center tst '>
                    <input
                      type='checkbox'
                      className='me-3 cursor-pointer form-check-input'
                      name='selected-all-name'
                      id='selected-all'
                      value='selected-all'
                      checked={incmData?.latePaymentType == 1}
                      onChange={(e: any) => {
                        if (incmData?.dueDate == null) {
                          setDueDateError(true)
                        } else {
                          setDueDateError(false)
                          // if (incmData?.latePaymentType === 1) {
                          //   setLatePaymentFeeStatus('') // Deselect if clicked again
                          //   let values = [...paymentItems]
                          //   const filteredValues = values.filter(
                          //     (item) => !item.frontendStorage.isLatePaymentItem
                          //   )
                          //   filteredValues.map((itm: any) => {
                          //     itm.frontendStorage.isLatePaymentItemAdded = false
                          //     itm.frontendStorage.latePaymentObject = {}
                          //   })
                          //   // Keep only normal items
                          //   //   values?.map((vl: any, ind: any) => {
                          //   //   values[ind].frontendStorage.latePaymentObject = {}
                          //   // })
                          //   setPaymentItems(filteredValues)
                          //   updateLatePaymentType(null, filteredValues, {})
                          // } else {
                          //   setLatePaymentFeeStatus(1)

                          //   updateLatePaymentType(1)
                          // }


                          // Filter and update payment items
                          const filteredValues = paymentItems
                            .filter((item: any) => !item.frontendStorage.isLatePaymentItem)
                            .map((item: any) => {
                              item.frontendStorage.isLatePaymentItemAdded = false
                              item.frontendStorage.latePaymentObject = {}
                              return item
                            })

                            setPaymentItems(filteredValues)
                            updateLatePaymentType(
                            incmData?.latePaymentType === 1 ? null : 1,
                            filteredValues,
                            {}
                          )
                        }
                      }}
                    />
                    <h4 className='m-0'>
                      <label htmlFor='selected-all' className='fs-4'>
                        Apply to all items
                      </label>
                    </h4>
                  </div>
                  {incmData?.latePaymentType == 1 && (
                    <div className='test property-test multi-select-grey-bg mt-3'>
                      <div
                        className={`multi-select-container ${
                          latePaymentFeeObjectForAll?.length === 0
                            ? 'no-value late-payment-fee'
                            : 'has-value'
                        }
                                `}
                      >
                        <MultiSelect
                          options={latePaymentOptionsForAll}
                          value={
                            latePaymentFeeObjectForAll?.length > 0
                              ? latePaymentFeeObjectForAll
                              : []
                          }
                          onChange={(e: any) => {
                            const newVal = e?.length > 1 ? e.slice(1) : e // Assign newVal based on length
                            const updatedValues = paymentItems.map((vl: any) => {
                              vl.latePaymentObject = newVal
                              vl.frontendStorage.latePaymentObject = newVal
                              return vl
                            })

                            setLatePaymentFeeObjectForAll(newVal)

                            if (newVal.length === 0) {
                              // If dropdown is cleared, remove all late payment items
                              const filteredValues = updatedValues.filter(
                                (item: any) => !item.frontendStorage.isLatePaymentItem
                              )

                              filteredValues.forEach((item: any) => {
                                item.frontendStorage.isLatePaymentItemAdded = false
                                item.frontendStorage.latePaymentObject = {}
                              })

                              const idsToDelete = updatedValues
                                .filter(
                                  (item: any) =>
                                    item.frontendStorage.isLatePaymentItem && item._id !== undefined
                                )
                                .map((item: any) => ({_id: item._id}))

                              const remainingValues = updatedValues.filter(
                                (item: any) => !item.frontendStorage.isLatePaymentItem
                              )

                              setPaymentItems(remainingValues)
                              setLatePaymentFeeObjectForAll(newVal)
                              updateParentForAll(remainingValues, {})
                            } else {
                              // Apply the late payment fee calculation to all items (existing and newly added)
                              latePaymentFeeCalculationForAll(newVal[0], updatedValues)
                            }
                          }}
                          labelledBy='Type'
                          hasSelectAll={false}
                          overrideStrings={{
                            allItemsAreSelected: `${latePaymentFeeObjectForAll?.length?.[0]?.label}`,
                          }}
                          closeOnChangedValue={true}
                        />
                        {/* <MultiSelect
                          options={latePaymentOptions}
                          value={latePaymentFeeObjectForAll?.length > 0 ? latePaymentFeeObjectForAll : []}
                          onChange={(e: any) => {
                            let values = [...paymentItems];  // Get the current payment items list
                            let newVal: any = e;

                            if (e?.length > 1) newVal = e.slice(1); // Handle multiple selections

                            // values.forEach((vl: any) => {
                            //   vl.latePaymentObject = newVal;
                            //   vl.frontendStorage.latePaymentObject = newVal;
                            // });
                            values?.map((vl: any, ind: any) => {
                              values[ind].latePaymentObject = newVal
                              values[ind].frontendStorage.latePaymentObject = newVal
                            })

                            setLatePaymentFeeObjectForAll(newVal);

                            if (newVal.length === 0) {
                              // If the dropdown is cleared, remove all late payment items
                              const filteredValues = values.filter(item => !item.frontendStorage.isLatePaymentItem);
                              setPaymentItems(filteredValues); // Update the items with only normal payment items
                            } else {
                              // If a late fee option is selected, apply the late payment calculation
                              latePaymentFeeCalculationForAll(newVal[0], values);
                            }
                          }}
                          labelledBy="Type"
                          hasSelectAll={false}
                        /> */}
                      </div>
                    </div>
                  )}
                </div>
                <div className='d-flex align-items-center tst ms-10 ps-5'>
                  <input
                    type='checkbox'
                    className='me-3 cursor-pointer form-check-input'
                    name='do-not-apply-name'
                    id='do-not-apply'
                    value='do-not-apply'
                    checked={incmData?.latePaymentType == 2}
                    onChange={(e: any) => {
                      setDueDateError(false)
                      if (incmData?.latePaymentType === 2) {
                        updateLatePaymentType(null)
                        doNotApplyLatePaymentType(null, paymentItems, [])
                      } else {
                        let idsToDelete: Array<{_id: any}> = []
                        const updatedItems = paymentItems.filter((item: any) => {
                          const isLatePaymentItem = item?.frontendStorage?.isLatePaymentItem
                          if (isLatePaymentItem) {
                            // idsToDelete.push({_id: item._id})
                            return false // Remove late payment items
                          } else if (isLatePaymentItem && flg == '1') {
                            return false // Remove late payment items
                          }
                          item.frontendStorage.latePaymentObject = {}
                          item.frontendStorage.isLatePaymentItemAdded = false
                          return true // Keep non-late payment items
                        })
                        console.log(updatedItems)
                        setPaymentItems(updatedItems)
                        doNotApplyLatePaymentType(2, updatedItems, idsToDelete)
                      }
                    }}
                  />
                  <h4 className='m-0'>
                    <label htmlFor='do-not-apply' className='fs-4'>
                      Do not Apply
                    </label>
                  </h4>
                </div>
              </div>

              {dueDateError && (
                <p
                  style={{color: 'var(--kt-danger)', gridColumn: '2/2'}}
                  className='m-0 mt-2'
                >
                  Please select the payment due date first.
                </p>
              )}
            </div>
            <Row className='mt-7 separator mx-0'></Row>
          </div>
        </>
      ) : (
        <></>
      )}

      {checkedValues?.length > 0 && (
        <>
          {checkedValues?.map((ch: any, ind: any) => {
            if (ch == true) totalSelected = totalSelected + 1
            return <></>
          })}
        </>
      )}

      <div className='ms-auto pe-0'>
        <div className='d-flex justify-content-end align-items-center'>
          {totalSelected > 0 && (
            <>
              <div className='d-flex '>
                <h4 className='my-2' style={{color: 'black'}}>
                  <label className='labl-gry '>
                    {' '}
                    <i>Total Selected </i>
                  </label>

                  <span className='ms-5 ps-1 me-4'>
                    <b>{totalSelected}</b>
                  </span>
                </h4>
              </div>
              <button
                type='button'
                className='btn btn-sm fw-bold my-4  mx-3 red-hollow-btn btn-fit-content'
                onClick={() => {
                  if (flg == '1') {
                    deleteSelectedPaymentItems()
                  } else {
                    Swal.fire({
                      html: ` 
                          <div class='fs-3'> <b>Delete Payment Item</></div>
                           <div class='fs-5 mt-4'>You are about to delete the selected payment items.</div>
                          <div class='fs-5 mt-4'> Are you sure you want to continue? </div>`,
                      // icon: 'warning',
                      showConfirmButton: true,
                      confirmButtonColor: '#35475e',
                      confirmButtonText: 'Yes',
                      showCancelButton: true,
                      cancelButtonText: 'No',
                      cancelButtonColor: '#fff',
                      customClass: {
                        confirmButton: 'custom-confirm-button',
                        cancelButton: 'custom-cancel-button',
                        popup: 'custom-popup',
                      },
                      reverseButtons: true, // Reverse the order of buttons reverseButtons: true,
                    }).then((res: any) => {
                      if (res.isConfirmed) {
                        deleteSelectedPaymentItems()
                      }
                    })
                  }
                }}
              >
                <img src={trashImg} height={18} width={18} className='me-3' /> Delete
              </button>
            </>
          )}
          {isEdit ? (
            <button
              type='button'
              className='btn btn-sm fw-bold my-4 px-2 green-hollow-btn btn-fit-content'
              disabled={isDisabled()}
              onClick={() => {
                handleAddExpenseItems()
              }}
            >
              <img src={addGreen} height={18} width={18} className='me-4' /> Add Payment
              Item
            </button>
          ) : (
            <></>
          )}
        </div>
      </div>

      {isEdit ? <></> : <Row className='mt-7 separator mx-0'></Row>}
      <div>
        <div
          className={` ${isPrint ? '' : 'table-responsive'} mt-3 ${
            isEdit ? 'min-h-350' : 'auto'
          }`}
          style={{maxHeight: isPrint || !isEdit ? 'auto' : '700px'}}
        >
          <table
            className={`  table align-middle  fs-6 gy-5`}
            id='kt_ecommerce_sales_table'
          >
            <thead>
              <tr className='text-start text-gray-400 fw-bold fs-7 gs-0'>
                {/* <th className='text-start min-w-50px'>ID</th>  */}
                {isEdit && <th className='text-start min-w-50px'></th>}
                <th className='text-start min-w-50px'>#</th>
                <th className='text-start min-w-150px'>Item</th>
                <th className='text-start min-w-100px'>Amount</th>
                <th className='text-start min-w-75px'>QTY</th>
                <th className='text-start min-w-100px'>VAT</th>
                {isEdit && <th className='text-start min-w-150px'>Late Payment Fee</th>}
                <th className={`text-start ${isEdit ? 'min-w-175px' : 'min-w-125px'} `}>
                  Total Amount
                </th>
                {/* <th className='text-start min-w-150px'>Credit</th>
                  <th className='text-start min-w-150px'>Debit</th>
                  <th className='text-start min-w-150px'>Paid</th>
                  <th className='text-start min-w-150px'>Refunded</th>
                  <th className='text-start min-w-150px'>Outstanding</th> */}
                {isEdit && <th className='text-start min-w-50px ps-1'></th>}
              </tr>
            </thead>

            {paymentItems?.map((rsOut: any, indOut: any) => {
              {
                paymentItems?.map((rsIn: any, indIn: any) => {
                  if (
                    rsOut?.expenseItemId == rsIn?.expenseItemId &&
                    (((Number(rsOut?.property?.level) == 2 ||
                      Number(rsOut?.property?.level) == 3) &&
                      rsOut?.property?.details?.unitNo ==
                        rsIn?.property?.details?.unitNo) ||
                      (Number(rsOut?.property?.level) == 1 &&
                        Number(rsIn?.property?.level) == 1)) &&
                    indOut != indIn
                  ) {
                    isDuplicated[indOut] = true
                  }
                })
              }
            })}

            <tbody className='fw-semibold'>
              {paymentItems?.map((rs: any, index: any) => {
                let totalAmountOfItem: any = 0
                let vatAmnt: any =
                  ((Number(rs?.amount) * Number(rs?.quantity)) / 100) *
                  Number(rs?.VATPercentage)
                totalAmountOfItem = Number(rs?.amount) * Number(rs?.quantity) + vatAmnt
                return (
                  <tr className=''>
                    {isEdit && (
                      <>
                        {!rs?.frontendStorage?.isLatePaymentItem ? (
                          <td className='text-start test'>
                            <div className='form-check form-check-custom form-check-solid form-check-sm'>
                              <input
                                disabled={paymentItems?.findIndex(
                                  (itm: any) => itm?.frontendStorage?.isLatePaymentItem === false
                                ) === index || index == 0}
                                className='form-check-input'
                                type='checkbox'
                                id='checklist'
                                name='checklist'
                                checked={checkedValues[index] == true ? true : false}
                                onChange={(e: any) => {
                                  const values = [...checkedValues]
                                  values[index] = !values[index]
                                  setCheckedValues(values)
                                }}
                              />
                            </div>
                          </td>
                        ) : (
                          <td className='text-start'></td>
                        )}
                      </>
                    )}
                    <td className='text-start'>
                      {index < 9 ? `0${index + 1}` : `${index + 1}`}
                    </td>
                    <td className='text-start min-w-100px'>
                      {isEdit ? (
                        <>
                          {rs?.frontendStorage?.isLatePaymentItem == false ? (
                            <>
                              {rs?.frontendStorage?.isList ? (
                                <div className='d-flex align-items-center'>
                                  <img
                                    src={switchToTextImg}
                                    height={20}
                                    width={20}
                                    className='me-2 cursor-pointer'
                                    onClick={() => {
                                      let values = [...paymentItems]
                                      values[index].frontendStorage.isList = false
                                      values[index].frontendStorage.paymentItemId = null
                                      setPaymentItems(values)
                                      updateParent(values)
                                    }}
                                  />
                                  <div className='d-flex align-items-center'>
                                    <div
                                      className='form-control form-control-solid tenancy-control status-w-200 cursor-pointer'
                                      style={{
                                        height: '35px',
                                        width: '300px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        border:
                                          rs?.name?.trim()?.length === 0
                                            ? '0.1rem solid #bec3cb '
                                            : '0.1rem solid #384a62',
                                        color:
                                          rs?.name?.trim()?.length === 0
                                            ? '#a1a5b7'
                                            : '#384a62',
                                        fontWeight:
                                          rs?.name?.trim()?.length === 0 ? '500' : '',
                                      }}
                                      onClick={(e) => {
                                        if (rs?.name?.trim()?.length === 0) {
                                          setSelectedIndex(index)
                                          setSelectModal(true)
                                        }
                                      }}
                                    >
                                      {' '}
                                      {rs?.name?.trim()?.length === 0 ? (
                                        <i>{'Select Item'}</i>
                                      ) : (
                                        `${rs?.name}`
                                      )}
                                    </div>

                                    {rs?.name?.trim()?.length == 0 ? (
                                      <img
                                        src={plusBlue}
                                        height={16}
                                        width={16}
                                        style={{marginLeft: '-30px'}}
                                        onClick={() => {
                                          setSelectedIndex(index)
                                          setSelectModal(true)
                                        }}
                                        className='cursor-pointer'
                                      />
                                    ) : (
                                      <img
                                        src={redCross}
                                        height={16}
                                        width={16}
                                        style={{marginLeft: '-30px'}}
                                        onClick={() => {
                                          let values = [...paymentItems]
                                          values[index].name = ''
                                          setPaymentItems(values)
                                          updateParent(values)
                                        }}
                                        className='cursor-pointer'
                                      />
                                    )}
                                  </div>
                                </div>
                              ) : (
                                <div className='d-flex align-items-center'>
                                  <img
                                    src={switchToListImg}
                                    height={20}
                                    width={20}
                                    className='me-2 cursor-pointer'
                                    onClick={() => {
                                      let values = [...paymentItems]
                                      values[index].frontendStorage.isList = true
                                      setPaymentItems(values)
                                      updateParent(values)
                                    }}
                                  />
                                  <div className='d-flex align-items-center'>
                                    <input
                                      //   {...register('documentName', {required: true})}
                                      type='text'
                                      className='form-control form-control-solid mytest tenancy-control status-w-200 cursor-pointer'
                                      name='documentName'
                                      value={rs?.name}
                                      style={{height: '35px', width: '300px'}}
                                      placeholder={'Enter Item Name'}
                                      onChange={(e: any) => {
                                        let values = [...paymentItems]
                                        values[index].name = capitalizeFirstLetter(
                                          e.target.value
                                        )
                                        setPaymentItems(values)

                                        latePaymentFeeCalculationFromOtherInputFields(
                                          rs,
                                          rs?.frontendStorage?.latePaymentObject?.[0],
                                          index,
                                          values
                                        )
                                      }}
                                      onBlur={() => {
                                        updateParent(paymentItems)
                                      }}
                                      autoComplete='off'
                                    />
                                  </div>
                                </div>
                              )}
                            </>
                          ) : (
                            <>{rs?.name ? rs?.name : '-'}</>
                          )}
                        </>
                      ) : (
                        <>{rs?.name ? rs?.name : '-'}</>
                      )}
                    </td>
                    <td className='text-start'>
                      {isEdit ? (
                        <>
                          {rs?.frontendStorage?.isLatePaymentItem == false ? (
                            <>
                              <input
                                //   {...register('documentName', {required: true})}
                                type='number'
                                className='form-control form-control-solid mytest tenancy-control status-w-170'
                                name='documentName'
                                value={rs?.amount}
                                style={{height: '35px', width: '300px'}}
                                placeholder={'Enter Amount'}
                                onKeyDown={preventExpAndSign}
                                onChange={(e: any) => {
                                  let newValue = removeLeadingZeros(e.target.value)

                                  let values = [...paymentItems]
                                  values[index].amount = newValue
                                  let vatAmount: any =
                                    ((Number(newValue) * Number(values[index].quantity)) / 100) *
                                    values[index].VATPercentage

                                  values[index].totalAmount =
                                    Number(newValue) * Number(values[index].quantity) +
                                    Number(vatAmount)
                                  values[index].outstandingAmount =
                                    Number(values[index].totalAmount) -
                                    Number(values[index].amountPaid)

                                  setPaymentItems(values)
                                  // updateParent(values)

                                  latePaymentFeeCalculationFromOtherInputFields(
                                    rs,
                                    rs?.frontendStorage?.latePaymentObject?.[0],
                                    index,
                                    values
                                  )
                                }}
                                onBlur={() => {
                                  if (totalAmountOfItem >= rs?.amountPaid)
                                    updateParent(paymentItems)
                                }}
                                autoComplete='off'
                              />
                              {totalAmountOfItem < rs?.amountPaid && (
                                <p className='text-danger mb-0 status-w-170'>
                                  {' '}
                                  The total amount of the expense item is less than the
                                  payment amount recorded for it.
                                </p>
                              )}
                            </>
                          ) : (
                            <>
                              {'AED '} {rs?.amount ? formatAmount(rs?.amount) : 0}
                            </>
                          )}
                        </>
                      ) : (
                        <>
                        {'AED '} {rs?.amount ? `${formatAmount(rs?.amount)}` : '-'}
                      </>
                      )}
                    </td>

                    <td className='text-start'>
                      {isEdit ? (
                        <>
                          {rs?.frontendStorage?.isLatePaymentItem == false ? (
                            <input
                              type='number'
                              className='form-control form-control-solid mytest tenancy-control  status-w-100'
                              name='documentName'
                              value={rs?.quantity}
                              style={{height: '35px', width: '300px'}}
                              placeholder={'QTY'}
                              onKeyDown={preventExpAndSign}
                              onChange={(e: any) => {
                                let values = [...paymentItems]
                                let newValue = removeLeadingZeros(e.target.value)

                                values[index].quantity = newValue

                                let vatAmount: any =
                                  ((Number(newValue) * Number(values[index].amount)) /
                                    100) *
                                  values[index].VATPercentage
                                values[index].totalAmount =
                                  Number(newValue) * Number(values[index].amount) +
                                  Number(vatAmount)
                                values[index].outstandingAmount =
                                  Number(values[index].totalAmount) -
                                  Number(values[index].amountPaid)

                                setPaymentItems(values)
                                // updateParent(values)

                                latePaymentFeeCalculationFromOtherInputFields(
                                  rs,
                                  rs?.frontendStorage?.latePaymentObject?.[0],
                                  index,
                                  values
                                )
                              }}
                              onBlur={() => {
                                if (totalAmountOfItem >= rs?.amountPaid)
                                  updateParent(paymentItems)
                              }}
                              autoComplete='off'
                            />
                          ) : (
                            <>{formatAmount(rs?.quantity)}</>
                          )}
                        </>
                      ) : (
                        <>{rs?.quantity ? `${formatAmount(rs?.quantity)}` : '-'}</>
                      )}
                    </td>
                    <td className='text-start'>
                      {isEdit ? (
                        <>
                          {rs?.frontendStorage?.isLatePaymentItem == false ? (
                            <input
                              //   {...register('documentName', {required: true})}
                              type='number'
                              className='form-control form-control-solid mytest tenancy-control  status-w-100'
                              name='documentName'
                              value={rs?.VATPercentage}
                              style={{height: '35px', width: '300px'}}
                              placeholder={'%'}
                              onKeyDown={preventExpAndSign}
                              onChange={(e: any) => {
                                let values = [...paymentItems]

                                let newValue = removeLeadingZeros(e.target.value)

                                values[index].VATPercentage = newValue
                                let vatAmount: any =
                                  ((Number(values[index].amount) *
                                    Number(values[index].quantity)) /
                                    100) *
                                  Number(newValue)
                                values[index].VATAmount = vatAmount
                                values[index].totalAmount =
                                  Number(values[index].amount) *
                                    Number(values[index].quantity) +
                                  Number(vatAmount)
                                values[index].outstandingAmount =
                                  Number(values[index].totalAmount) -
                                  Number(values[index].amountPaid)
                                setPaymentItems(values)
                                // updateParent(values)

                                latePaymentFeeCalculationFromOtherInputFields(
                                  rs,
                                  rs?.frontendStorage?.latePaymentObject?.[0],
                                  index,
                                  values
                                )
                              }}
                              onBlur={() => {
                                if (totalAmountOfItem >= rs?.amountPaid)
                                  updateParent(paymentItems)
                              }}
                              autoComplete='off'
                            />
                          ) : (
                            <>{rs?.VATPercentage ? `${formatAmount(rs?.VATPercentage)}%` : '0%'}</>
                          )}
                        </>
                      ) : (
                        <>{rs?.VATPercentage ? `${rs?.VATPercentage}%` : '-'}</>
                      )}
                    </td>
                    {isEdit && (
                      <td className='text-start'>
                        {rs?.frontendStorage?.isLatePaymentItem == false && (
                          <>
                            {incmData?.latePaymentType == null ? (
                              '-'
                            ) : (
                              <>
                                {incmData?.latePaymentType == 0 ? (
                                  <div className='test property-test multi-select-grey-bg'>
                                    <div
                                      className={`multi-select-container ${
                                        rs?.frontendStorage?.latePaymentObject?.length ===
                                        0
                                          ? 'no-value select'
                                          : 'has-value'
                                      }`}
                                    >
                                      <MultiSelect
                                        options={latePaymentOptions}
                                        value={
                                          rs?.frontendStorage?.latePaymentObject?.length >
                                          0
                                            ? rs?.frontendStorage?.latePaymentObject
                                            : []
                                        }
                                        onChange={(e: any) => {
                                          let values = [...paymentItems]

                                          let newVal: any = e
                                          if (e?.length > 1) newVal = e.slice(1)

                                          values[index].latePaymentObject = newVal
                                          if (newVal?.[0]?.type == 'doNotApply')
                                            values[index].latePaymentSelectionStatus = 2
                                          else
                                            values[index].latePaymentSelectionStatus = 1

                                          values[
                                            index
                                          ].frontendStorage.latePaymentObject = newVal

                                          latePaymentFeeCalculation(
                                            rs,
                                            newVal?.[0],
                                            index,
                                            values
                                          )

                                          setPaymentItems(values)
                                          updateParent(values)
                                        }}
                                        labelledBy='Type'
                                        hasSelectAll={false}
                                        overrideStrings={{
                                          allItemsAreSelected: `${rs?.frontendStorage?.latePaymentObject?.[0]?.label}`,
                                        }}
                                        closeOnChangedValue={true}
                                      />
                                    </div>
                                  </div>
                                ) : incmData?.latePaymentType == 1 ? (
                                  <>
                                    {`${
                                      rs?.frontendStorage?.latePaymentObject?.length > 0
                                        ? rs?.frontendStorage?.latePaymentObject?.[0]
                                            ?.label
                                        : '-'
                                    }`}{' '}
                                  </>
                                ) : incmData?.latePaymentType == 2 ? (
                                  <>{'Not Applied'} </>
                                ) : (
                                  <></>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </td>
                    )}

                    <td className='text-start '>
                      <div className='d-flex align-items-center mt-2'>
                        <b>
                        {'AED '} {totalAmountOfItem ? `${formatAmount(totalAmountOfItem)}` : '0'} 
                        </b>
                      </div>
                    </td>
                  
                    {isEdit && (paymentItems?.findIndex(
                                  (itm: any) => itm?.frontendStorage?.isLatePaymentItem === false
                                ) !== index) && (
                      <td className='text-start ps-1'>
                        {rs?.frontendStorage?.isLatePaymentItem == false && (
                          <img
                            src={trashImg}
                            height={16}
                            width={16}
                            onClick={() => {
                              if (flg == '1') handleRemoveIncomeItems(index)
                              else {
                                Swal.fire({
                                  html: ` 
                                  <div class='fs-3'> <b>Delete Payment Item</></div>
                                  <div class='fs-5 mt-4'>You are about to delete an payment item.</div>
                                  <div class='fs-5 mt-4'> Are you sure you want to continue? </div>`,
                                  // icon: 'warning',
                                  showConfirmButton: true,
                                  confirmButtonColor: '#35475e',
                                  confirmButtonText: 'Yes',
                                  showCancelButton: true,
                                  cancelButtonText: 'No',
                                  cancelButtonColor: '#fff',
                                  customClass: {
                                    confirmButton: 'custom-confirm-button',
                                    cancelButton: 'custom-cancel-button',
                                    popup: 'custom-popup',
                                  },
                                  reverseButtons: true, // Reverse the order of buttons reverseButtons: true,
                                }).then((res: any) => {
                                  if (res.isConfirmed) {
                                    handleRemoveIncomeItems(index)
                                  }
                                })
                              }
                            }}
                            className='cursor-pointer'
                          />
                        )}
                      </td>
                    )}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
        <Row className='mt-7 separator mx-0'></Row>
        <div className='row mt-7 mx-0'>
          <div className={`${isEdit ? 'col-8' : 'col-7'}  mx-0 px-0`}></div>
          <div className={`${isEdit ? 'col-4' : 'col-5'} mx-0 px-0`}>
            <div className='row mx-0' style={{paddingLeft: isEdit ? '0px' : '50px'}}>
              <div className='col-4 px-0'>
                <h4 className='my-2 mb-3'>
                  <label className={`text-gray-400 fw-bold fs-4 text-uppercase`}>
                    {' '}
                    Sub total
                  </label>
                </h4>
              </div>
              <div className='col-8 ' style={{paddingLeft: isEdit ? '24px' : '54px'}}>
                <b>
                  {' '}
                  {Number(totalAmount?.subTotal) > 0 ? (
                    <>AED {formatAmount(totalAmount?.subTotal)}</>
                  ) : (
                    <>AED 0</>
                  )}
                </b>
              </div>
            </div>
            <div className='row mx-0 mt-3' style={{paddingLeft: isEdit ? '0px' : '50px'}}>
              <div className='col-4 px-0'>
                <h4 className='my-2 mb-3'>
                  <label className={`text-gray-400 fw-bold fs-4 text-uppercase`}>
                    {' '}
                    VAT
                  </label>
                </h4>
              </div>
              <div className='col-8 ' style={{paddingLeft: isEdit ? '24px' : '54px'}}>
                <b>
                  {' '}
                  {Number(totalAmount?.vat) > 0 ? (
                    <>AED {formatAmount(totalAmount?.vat)}</>
                  ) : (
                    <>AED 0</>
                  )}
                </b>
              </div>
            </div>
            <div className='row mx-0 mt-3' style={{paddingLeft: isEdit ? '0px' : '50px'}}>
              <div className='col-4 px-0'>
                <h4 className='my-2 mb-3'>
                  <label
                    className={`fw-bold fs-4 text-uppercase`}
                    style={{color: '#000000'}}
                  >
                    {' '}
                    Total
                  </label>
                </h4>
              </div>
              <div className='col-8 ' style={{paddingLeft: isEdit ? '24px' : '54px'}}>
                <b>
                  {' '}
                  {Number(totalAmount?.total) > 0 ? (
                    <>AED {formatAmount(totalAmount?.total)}</>
                  ) : (
                    <>AED 0</>
                  )}
                </b>
              </div>
            </div>
          </div>
        </div>
        {/* <Row className='mt-7 separator mx-0'></Row> */}
      </div>
      {selectModal && (
        <SelectList
          show={selectModal}
          handleClose={() => {
            setSelectModal(false)
          }}
          updateDetails={(val: any) => {
            let values = [...paymentItems]

            values[selectedIndex].name = val?.name
            values[selectedIndex].paymentItemListId = val?._id
            values[selectedIndex].frontendStorage.paymentItemId = val?.id

            setPaymentItems(values)

            updateParent(values)
            setSelectModal(false)
          }}
          tenantData={''}
        />
      )}
    </>
  )
}

export default PaymentItems
