import React, {useState} from 'react'
import moment, {Moment} from 'moment'
import addWhite from '../../../../img/add-white.png'
import {ApiPut, ApiGet} from '../../../../apiCommon/helpers/API/ApiData'
import correct from '../../../../img/correct.png'
import removed from '../../../../img/remove-filled.png'
import pencil from '../../../../img/edit-pen.png'
import trashImg from '../../../../img/trash.png'
import {ErrorToast, SuccessToast} from '../../../../apiCommon/helpers/Toast'
import externalTeam from '../../../../img/external-team-green.png'
import {useParams} from 'react-router-dom'
import swal from 'sweetalert2'
import userIcon from '../../../../img/green-user-filled.png'
import {CircularProgress} from '@mui/material'

interface ButtonProps {
  modalData?: any
  coments?: any
}

const HandlingTeamCommentsExceptUnit = ({modalData, coments}: ButtonProps) => {
  const {id} = useParams()

  const [isLoading, setIsLoading] = useState<any>(false)

  const userData: any = localStorage.getItem('userData')
  const user = JSON.parse(userData)

  const [comment, setComment] = useState<any>('')
  const [commentToEdit, setCommentToEdit] = useState<any>('')

  const [comments, setComments] = useState<any>(coments)
  const [commentError, setCommentError] = useState<any>(false)

  const [selectedInd, setSelectedInd] = useState<any>(-1)

  const convertUTCToLocalTime = (utcTimeString: any) => {
    const localDate = moment.utc(utcTimeString).local()
    return localDate.format('DD.MM.YYYY - HH:mm')
  }

  //
  const updateServiceJob = (data: any) => {
    setIsLoading(true)

    let comm: any = []
    data?.map((dt: any, ind: any) => {
      comm[ind] = dt
      comm[ind].createdBy = dt?.createdBy?._id
    })

    const body = {
      id: id,
      comments: comm,
    }

    ApiPut(`corporate/maintenance_service_job`, body)
      .then((res: any) => {
        getJobById()
      })
      .catch((err: any) => {
        setIsLoading(false)
        ErrorToast(err?.message)
      })
  }

  //
  const getJobById = () => {
    ApiGet(`corporate/maintenance_service_job/${id}`)
      .then((res: any) => {
        setComments(res?.data?.data?.comments)
        setIsLoading(false)
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
        setIsLoading(false)
      })
  }

  const sortedComments = comments
    ?.slice()
    .sort((a: any, b: any) => new Date(b?.date).getTime() - new Date(a?.date).getTime())

  return (
    <>
      {/* Comments */}
      <div className='card card-flush py-0 mt-5 mx-3 side mb-0'>
        <div className='row mx-0'>
          <div className='col-md-6 col-12 py-5 ps-0'>
            <div className='d-flex px-7'>
              <div className='d-block'>
                <h2 className='top ps-0'>
                  <b>Handling Team Comments</b>{' '}
                </h2>
                <h3 className='top ps-0 d-block'>
                  <i className=''> Include job specific comments shared with the handling team.</i>
                </h3>
              </div>

              <img src={externalTeam} height={45} width={45} className='ms-auto' />
            </div>

            <div className='d-flex mt-3 px-7'>
              <textarea
                className='form-control form-control-solid m-0 mytest'
                rows={3}
                name='comment'
                placeholder='Enter your comments here..'
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              ></textarea>
            </div>

            {commentError && (
              <p style={{color: 'var(--kt-danger)', gridColumn: '2/2'}} className='m-0 mt-1 px-7'>
                Please enter comment
              </p>
            )}

            <div className='d-flex justify-content-center mt-5  px-7'>
              <button
                type='button'
                className='btn btn-sm fw-bold  me-0 green-submit-btn ps-3 pe-10 btn-fit-content'
                onClick={() => {
                  if (comment?.trim()?.length == 0) {
                    setCommentError(true)
                  } else {
                    const userData: any = localStorage.getItem('userData')
                    const user = JSON.parse(userData)

                    let values = [...comments]
                    values[values?.length] = {
                      createdBy: {
                        _id: user?._id,
                        firstName: user?.firstName,
                        lastName: user?.lastName,
                      },
                      createdByType: 0,
                      comment: comment,
                      date: new Date().toISOString(),
                    }
                    setComments(values)
                    setCommentError(false)
                    setComment('')

                    updateServiceJob(values)
                  }
                }}
                style={{width: 'fit-content'}}
                disabled={isLoading}
              >
                <img src={addWhite} height={17} width={17} className='me-3' /> Add Comment
              </button>
            </div>

            <div className='d-flex my-3 mx-auto  px-7' style={{width: 'fit-content'}}>
              <h4 className='my-2' style={{color: 'black'}}>
                <label className=''> Handing Team:</label>
                <span className='ms-1'>
                  {modalData?.maintenance_service_request?.[0]?.handlerType == 0 ? (
                    <b>{'Internal Team'}</b>
                  ) : (
                    <b>
                      {modalData?.maintenance_service_request?.[0]?.vendor?.length > 0
                        ? modalData?.maintenance_service_request?.[0]?.vendor?.[0]?.companyName
                        : '-'}
                    </b>
                  )}
                </span>
              </h4>
            </div>

            <div
              className='d-flex align-items-center mt-3 pb-5 mb-5  mx-3'
              style={{borderBottom: '0.1rem solid #bec3cb'}}
            ></div>
          </div>

          <div
            className='col-md-6 col-12 py-5 px-0'
            style={{border: '0.1rem solid #bec3cb', borderRight: '0px', borderRadius: '5px'}}
          >
            {isLoading ? (
              <div
                className='d-flex justify-content-center align-items-center w-100'
                style={{height: '15vh'}}
              >
                <CircularProgress style={{color: '#007a59'}} />
              </div>
            ) : (
              <>
                {comments?.length > 0 ? (
                  <div
                    style={{minHeight: '250px', maxHeight: '400px', overflow: 'auto'}}
                    className='px-7'
                  >
                    {sortedComments?.map((comm: any, ind: any) => {
                      return (
                        <>
                          <div className='d-flex mt-4 align-items-center'>
                            {comm?.createdBy?._id != user?._id ? (
                              <div className='d-flex align-items-center'>
                                <img src={userIcon} height={24} width={25} className='me-3' />
                                <h5 className='ps-1 mb-0' style={{color: '#146c6a'}}>
                                  <b>Handling Team</b>
                                </h5>
                              </div>
                            ) : (
                              <h5 className='top ps-1 mb-0'>
                                <b>
                                  {comm?.createdBy?.firstName} {comm?.createdBy?.lastName}
                                </b>
                              </h5>
                            )}

                            <h5 className='top ps-0 ms-auto mb-0'>
                              {comm?.date ? `${convertUTCToLocalTime(comm?.date)}` : ''}
                            </h5>
                          </div>
                          <div className='d-flex mt-2'>
                            <textarea
                              className={`form-control form-control-solid m-0 ${
                                selectedInd == ind
                                  ? 'jobs-comments-edit'
                                  : comm?.createdBy?._id != user?._id
                                  ? 'green-border-box '
                                  : 'jobs-comments'
                              } `}
                              rows={3}
                              name='comment'
                              placeholder='Enter your comments here'
                              value={selectedInd != ind ? comm?.comment : commentToEdit}
                              onChange={(e) => setCommentToEdit(e.target.value)}
                              disabled={selectedInd != ind}
                            ></textarea>
                          </div>

                          <div className='mt-2 mb-8'>
                            {selectedInd == ind ? (
                              <div className='d-flex me-1 mb-2'>
                                <img
                                  src={removed}
                                  height={18}
                                  width={18}
                                  className='ms-auto cursor-pointer'
                                  onClick={() => {
                                    setSelectedInd(-1)
                                  }}
                                />
                                <img
                                  src={correct}
                                  height={18}
                                  width={18}
                                  className='ms-3 cursor-pointer'
                                  onClick={() => {
                                    let values = [...comments]
                                    values[ind].comment = commentToEdit
                                    setComments(values)
                                    setSelectedInd(-1)

                                    updateServiceJob(values)
                                  }}
                                />
                              </div>
                            ) : (
                              <>
                                {comm?.createdBy?._id == user?._id && (
                                  <div className='me-1 mb-2 text-end'>
                                    <img
                                      src={pencil}
                                      alt=''
                                      height={18}
                                      width={18}
                                      className='ms-4  cursor-pointer'
                                      onClick={() => {
                                        setSelectedInd(ind)
                                        setCommentToEdit(comm?.comment)
                                      }}
                                    />
                                    <img
                                      src={trashImg}
                                      alt=''
                                      height={18}
                                      width={18}
                                      className='ms-3 cursor-pointer'
                                      onClick={() => {
                                        swal
                                          .fire({
                                            html: ` 
                                      <div class='fs-3'> <b>Delete Comment</b></div>
                                      <div class='fs-5 mt-4'> Are you sure you want to delete this comment ? </div>`,
                                            // icon: 'warning',
                                            showConfirmButton: true,
                                            confirmButtonColor: '#35475e',
                                            confirmButtonText: 'Yes',
                                            showCancelButton: true,
                                            cancelButtonText: 'No',
                                            cancelButtonColor: '#fff',
                                            customClass: {
                                              confirmButton: 'custom-confirm-button',
                                              cancelButton: 'custom-cancel-button',
                                              popup: 'custom-popup',
                                            },
                                            reverseButtons: true,
                                          })
                                          .then((res: any) => {
                                            if (res.isConfirmed) {
                                              let values = [...comments]
                                              values.splice(ind, 1)
                                              setComments(values)

                                              updateServiceJob(values)
                                            }
                                          })
                                      }}
                                    />
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                        </>
                      )
                    })}
                  </div>
                ) : (
                  <>
                    <div
                      className='d-flex justify-content-center'
                      style={{minHeight: '250px', verticalAlign: 'middle'}}
                    >
                      <h3 className='top ps-0 d-block my-auto'>
                        <i className=''>
                          {' '}
                          Job specific comments shared with the handling team will appear here.
                        </i>
                      </h3>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default HandlingTeamCommentsExceptUnit
