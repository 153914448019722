import React, {useEffect, useState} from 'react'
import ownersImgBlue from '../../../img/owners-blue.png'
import addWhiteIcon from '../../../img/add-white.png'
import trashImg from '../../../img/trash.png'
import noData from '../../../img/NoData1.svg'
import ownerFilledIconBlue from '../../../img/owners-profile-blue.png'
import './styles.scss'
import Paper from '@mui/material/Paper'
import InputBase from '@mui/material/InputBase'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import swal from 'sweetalert2'
import ReactPaginate from 'react-paginate'
import moment from 'moment'
import {ApiDelete, ApiGet, ApiPost} from '../../../apiCommon/helpers/API/ApiData'
import {SuccessToast, ErrorToast} from '../../../apiCommon/helpers/Toast'
import AddTenantModel from './components/AddOwnerModel'
import {setComefrom} from '../../../redux/counterSlice'
import {useDispatch, useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'

const Owners = () => {
  const searchContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#f0f0f0',
    borderRadius: '25px',
  }

  const searchIconStyle = {
    padding: '10px',
    color: 'gray',
  }

  const inputBaseStyle = {
    flex: 1,
    padding: '5px',
  }

  const getOwnersFilters: any = localStorage.getItem('ownerFilters')
  const ownerFilters = JSON.parse(getOwnersFilters)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  let totalSelected: any = 0
  const [ownerName, setOwnerName] = useState<any>(
    ownerFilters?.searchOwnerName ? ownerFilters?.searchOwnerName : ''
  )

  const [email, setEmail] = useState<any>(
    ownerFilters?.searchEmail ? ownerFilters?.searchEmail : ''
  )

  const [mobileNo, setMobileNo] = useState<any>(
    ownerFilters?.searchMobileNo ? ownerFilters?.searchMobileNo : ''
  )
  const [addTenantModal, setAddTenantModal] = useState<any>(false)
  const [addInviteModal, setAddInviteModal] = useState<any>(false)

  const [ownerTableData, setOwnerTableData] = useState<any>([])
  const [isLoading, setIsLoading] = useState<any>(false)
  const [page, setPage] = useState<any>(1)
  const [pageLimit, setPageLimit] = useState<any>(1)
  const [limit, setLimit] = useState<any>(25)
  const [checkedValues, setCheckedValues] = useState<any>([])
  const [ownerCountSummary, setOwnerCountSummary] = useState<any>()

  const handlePageClick = (event: any) => {
    setPage(event.selected + 1)
    getOwners(event.selected + 1)
  }

  //
  const getOwners = async (pg = 1) => {
    let body: any = {
      page: pg,
      limit: limit,
      searchOwnerName: ownerName,
      searchEmail: email,
      searchMobileNo: mobileNo,
    }
    await ApiPost('corporate/owner/get', body)
      .then((res) => {
        let values: any = []
        for (let i = 0; i < res?.data?.data?.owner_data?.length; i++) {
          values[i] = false
        }
        setCheckedValues(values)
        setPageLimit(res?.data?.data?.state?.page_limit)
        setOwnerTableData(res?.data?.data?.owner_data)
        setOwnerCountSummary(res?.data?.data?.state?.data_count)
      })
      .catch((err) => ErrorToast(err.message))
  }

  //
  const deleteOwners = () => {
    setIsLoading(true)
    let ids: any = []

    let i: any = 0

    checkedValues?.map((ch: any, ind: any) => {
      if (ch == true) {
        ids[i] = ownerTableData[ind]?._id
        i++
      }
    })

    const body = {
      ownerIds: ids,
    }

    let url: any = `corporate/owner/delete`

    ApiPost(`${url}`, body)
      .then((res: any) => {
        SuccessToast(res?.data?.message)
        getOwners()
        setIsLoading(false)
      })
      .catch((err) => {
        ErrorToast(err.message)
        setIsLoading(false)
      })
  }

  useEffect(() => {
    setPage(1)
    getOwners(1)
  }, [ownerName, email, mobileNo])


  useEffect(() => {
    const filters = {
      searchOwnerName: ownerName,
      searchEmail: email,
      searchMobileNo: mobileNo,
    }
    localStorage.setItem('ownerFilters', JSON.stringify(filters))
  }, [ownerName, email, mobileNo])

  const goToOwnerProfile = (tnt: any) => {
    dispatch(setComefrom('owners'))
    navigate(`/owner-profile/${tnt?._id}`)
  }

  return (
    <>
      <div className='mx-auto d-flex gap-5 flex-column p-0 m-0 px-2' id='kt_app_main'>
        <div className='row'>
          <div className='col-12'>
            <h1 className='page-heading  m-0'>
              <b>Owners</b>
            </h1>
          </div>
        </div>

        <div className='row'>
          <div className='col-12'>
            <div className='d-flex flex-lg-wrap flex-xl-nowrap' style={{gap: '20px'}}>
              <div
                className='customCard py-5 px-5 shadow property-insight-shadow'
                style={{width: '11rem', height: '100px'}}
              >
                <h4 className='mb-4'>Total Owners</h4>
                <div className='d-flex align-items-center'>
                  <img src={ownersImgBlue} height={35} width={35} className='me-7' />
                  <h4 className='fs-2 mb-0 ms-auto me-2'>{ownerCountSummary}</h4>
                </div>
              </div>
            </div>
          </div>
          <div className='col-12 mt-5'>
            <div className='d-flex justify-content-end align-items-end'>
              {checkedValues?.length > 0 && (
                <>
                  {checkedValues?.map((ch: any, ind: any) => {
                    if (ch == true) totalSelected = totalSelected + 1
                    return <></>
                  })}
                </>
              )}
            </div>
          </div>
          <div
            className='col-12 d-flex justify-content-between align-items-center py-5 gy-3 mx-0'
            style={{gap: '0px'}}
          >
            {/* search fields */}
            <div className={`d-flex align-items-center px-0`}>
              <div className='xyz me-5 pe-3'>
                <label className='head-text' style={{fontWeight: '600'}}>
                  {' '}
                  Search{' '}
                </label>
              </div>
              <div className='xyz me-4'>
                <Paper component='form' style={searchContainerStyle}>
                  <InputBase
                    placeholder='Owner Name'
                    style={inputBaseStyle}
                    inputProps={{'aria-label': 'ownerName'}}
                    value={ownerName}
                    onChange={(e: any) => setOwnerName(e.target.value)}
                  />
                  <IconButton style={searchIconStyle} aria-label='ownerName'>
                    <SearchIcon />
                  </IconButton>
                </Paper>
              </div>
              <div className='xyz me-4'>
                <Paper component='form' style={searchContainerStyle}>
                  <InputBase
                    placeholder='Email'
                    style={inputBaseStyle}
                    inputProps={{'aria-label': 'email'}}
                    value={email}
                    onChange={(e: any) => setEmail(e.target.value)}
                  />
                  <IconButton style={searchIconStyle} aria-label='email'>
                    <SearchIcon />
                  </IconButton>
                </Paper>
              </div>
              <div className='xyz me-4'>
                <Paper component='form' style={searchContainerStyle}>
                  <InputBase
                    placeholder='Mobile No.'
                    style={inputBaseStyle}
                    inputProps={{'aria-label': 'mobileNo'}}
                    value={mobileNo}
                    onChange={(e: any) => {
                      const value = e.target.value
                      const numericValue = value.replace(/\D/g, '')
                      setMobileNo(numericValue)
                    }}
                  />
                  <IconButton style={searchIconStyle} aria-label='mobileNo'>
                    <SearchIcon />
                  </IconButton>
                </Paper>
              </div>
            </div>
            {/* add and invite buttons */}
            <div className={`d-flex ms-auto`}>
              {totalSelected > 0 && (
                <>
                  <div className='d-flex ms-auto'>
                    <h4 className='my-2' style={{color: 'black'}}>
                      <label className='labl-gry'>
                        {' '}
                        <i>Total Selected </i>
                      </label>
                    </h4>
                    <span className='ms-5 ps-1 my-2 me-4'>
                      <b>{totalSelected}</b>
                    </span>
                  </div>
                  <button
                    type='button'
                    className='btn btn-sm fw-bold red-hollow-btn background-white m-0 ps-2 me-7'
                    onClick={() => {
                      swal
                                  .fire({
                                    html: `<div class='fs-3'> <b>Delete Owner </b></div>
                                      <div class='fs-5 mt-4'> You are about to delete the selected owners. Deleting these owners will remove them from any properties they are linked to. </div>
                                      <div class='fs-5 mt-4'> If the owners are linked to a property that is under a lease, then you will need to update the owners details in the lease.</div>
                                      <div class='fs-5 mt-4'> Are you sure you want to continue? </div>`,
                                    // icon: 'warning',
                                    showConfirmButton: true,
                                    confirmButtonColor: '#35475e',
                                    confirmButtonText: 'Yes',
                                    showCancelButton: true,
                                    cancelButtonText: 'No',
                                    cancelButtonColor: '#fff',
                                    customClass: {
                                      confirmButton: 'custom-confirm-button',
                                      cancelButton: 'custom-cancel-button',
                                      popup: 'custom-popup',
                                    },
                                    // reverseButtons: true, 
                                  })
                                .then((res) => {
                                  if (res.isConfirmed) {
                                    deleteOwners()
                                  }
                                })
                    }}
                    // disabled={isLoading}
                  >
                    <img src={trashImg} height={18} width={18} className='me-5' /> Delete
                  </button>
                </>
              )}
              <div
                className='d-flex align-items-center justify-content-end'
                style={{columnGap: '20px'}}
              >
                <button
                  type='button'
                  className='btn btn-sm fw-bold px-2 green-submit-btn'
                  onClick={() => {
                    setAddTenantModal(true)
                  }}
                >
                  <img src={addWhiteIcon} height={18} width={18} className='me-2' /> Add Owner
                </button>
              </div>
            </div>
          </div>

          <div className='col-12'>
            <div className='card card-flush py-5 px-7 mt-5'>
              <div className='app-main flex-column flex-row-fluid' id='------------kt_app_main'>
                <div className='table-responsive'>
                  <table
                    className='table align-middle table-row-dashed fs-8 gy-5 '
                    id='kt_ecommerce_sales_table'
                  >
                    <thead className='fs-7'>
                      <th className='text-start w-25px test'>
                        <div className='form-check form-check-custom form-check-solid form-check-sm  py-0 ps-0 pe-5'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            id='vacant0'
                            name='none'
                            checked={
                              totalSelected == ownerTableData?.length && ownerTableData?.length != 0
                                ? true
                                : false
                            }
                            onChange={(e: any) => {
                              let values = [...checkedValues]
                              if (e.target.checked) {
                                for (let i = 0; i < ownerTableData?.length; i++) {
                                  values[i] = true
                                }
                              } else {
                                for (let i = 0; i < ownerTableData?.length; i++) {
                                  values[i] = false
                                }
                              }
                              setCheckedValues(values)
                            }}
                          />
                        </div>
                      </th>
                      <th className='text-center min-w-25px ps-0'></th>
                      <th className='text-start min-w-75px ps-5'>ID</th>
                      <th className='text-start min-w-100px ps-0'>First Name</th>
                      <th className='text-start min-w-100px ps-2'>Last Name</th>
                      <th className='text-center min-w-125px ps-2'>Units Owned</th>
                      <th className='text-start min-w-150px ps-5'>Email</th>
                      <th className='text-start min-w-100px ps-0'>Mobile No</th>
                      <th className='text-start min-w-150px ps-5'>Added</th>
                      <th className='text-start min-w-150px ps-5'>Last Updated</th>
                    </thead>
                    <tbody className='fw-semibold head-text'>
                      {' '}
                      {/* className='fw-semibold text-gray-600'*/}
                      {ownerTableData?.length > 0 ? (
                        ownerTableData?.map((ownerItem: any, i: any) => {
                          return (
                            <tr key={i}>
                              <td className='text-start px-0 test'>
                                <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0'>
                                  <input
                                    className='form-check-input'
                                    type='checkbox'
                                    id='vacant0'
                                    name='none'
                                    checked={checkedValues[i] == true ? true : false}
                                    onChange={(e: any) => {
                                      const values = [...checkedValues]
                                      values[i] = !values[i]
                                      setCheckedValues(values)
                                    }}
                                  />
                                </div>
                              </td>
                              <td
                                className={`text-center cursor-pointer ps-0 m-0 p-0 `}
                                onClick={() => goToOwnerProfile(ownerItem)}
                              >
                                <img
                                  src={ownerFilledIconBlue}
                                  height={30}
                                  width={30}
                                  className='m-0 p-0'
                                />
                              </td>
                              <td
                                data-kt-ecommerce-order-filter='order_id'
                                className='text-start cursor-pointer ps-5'
                                onClick={() => goToOwnerProfile(ownerItem)}
                              >
                                {ownerItem?.id != undefined ? ownerItem?.id : '-'}
                              </td>
                              <td
                                className='text-start cursor-pointer ps-0'
                                onClick={() => goToOwnerProfile(ownerItem)}
                              >
                                {/* {ownerItem?.firstName ? ownerItem?.firstName : '-'} */}
                               {ownerItem?.firstName?.length > 15 ? `${ownerItem?.firstName?.substring(0,15)}...`: ownerItem?.firstName}
                              </td>
                              <td
                                className='text-start cursor-pointer ps-2'
                                onClick={() => goToOwnerProfile(ownerItem)}
                              >
                                {/* {ownerItem?.lastName ? ownerItem?.lastName : '-'} */}
                                {ownerItem?.lastName?.length > 15 ? `${ownerItem?.lastName?.substring(0,15)}...`: ownerItem?.lastName}
                              </td>

                              <td
                                className='text-center cursor-pointer min-w-125px ps-2'
                                onClick={() => goToOwnerProfile(ownerItem)}
                              >
                                 {ownerItem?.unitsOwned > 0 ? ownerItem?.unitsOwned : '0'}
                              </td>

                              <td
                                className='text-start cursor-pointer ps-5'
                                onClick={() => goToOwnerProfile(ownerItem)}
                              >
                                {ownerItem?.email ? ownerItem?.email : '-'}
                              </td>
                              <td
                                className='text-start cursor-pointer ps-0'
                                onClick={() => goToOwnerProfile(ownerItem)}
                              >
                                {ownerItem?.countryCode && ownerItem?.phoneNumber
                                  ? `+${ownerItem?.countryCode?.toString()}${
                                      ownerItem?.phoneNumber
                                    }`
                                  : '-'}
                              </td>

                              <td
                                className='text-start cursor-pointer ps-5'
                                onClick={() => goToOwnerProfile(ownerItem)}
                                data-order='2022-09-11'
                              >
                                <p className='my-0'>
                                  {ownerItem?.createdByUser?.length > 0 &&
                                    `${ownerItem?.createdByUser?.[0]?.firstName} ${ownerItem?.createdByUser?.[0]?.lastName} - `}
                                </p>
                                <p className='my-0'>
                                  {ownerItem?.createdAt
                                    ? `${moment
                                        ?.utc(ownerItem?.createdAt)
                                        ?.local()
                                        ?.format('DD.MM.YYYY - hh:mm A')}`
                                    : '-'}
                                </p>
                              </td>
                              <td
                                className='text-start cursor-pointer ps-5'
                                onClick={() => goToOwnerProfile(ownerItem)}
                                data-order='2022-09-11'
                              >
                                <p className='my-0'>
                                  {ownerItem?.updatedByUser?.length > 0 &&
                                    `${ownerItem?.updatedByUser?.[0]?.firstName} ${ownerItem?.updatedByUser?.[0]?.lastName} - `}
                                </p>
                                <p className='my-0'>
                                  {ownerItem?.updatedAt && ownerItem?.updatedByUser?.length > 0
                                    ? `${moment
                                        ?.utc(ownerItem?.updatedAt)
                                        ?.local()
                                        ?.format('DD.MM.YYYY - hh:mm A')}`
                                    : '-'}
                                </p>
                              </td>
                            </tr>
                          )
                        })
                      ) : (
                        <tr>
                          <td colSpan={10} className='text-center'>
                            <img src={noData} alt='' width={350} />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>

                {/* pagination start*/}
                <div className='row mb-5'>
                  <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
                  <div className='row mt-5'>
                    <div className='col-sm-12 col-md-12 d-flex align-items-center justify-content-center justify-content-md-end mt-5'>
                      <div
                        className='dataTables_paginate paging_simple_numbers'
                        id='kt_ecommerce_sales_table_paginate'
                      >
                        <ReactPaginate
                          breakLabel='...'
                          nextLabel='>'
                          pageClassName='paginate_button page-item +'
                          pageLinkClassName='page-link'
                          containerClassName='pagination'
                          activeClassName='active'
                          previousClassName='paginate_button page-item previous'
                          nextClassName='paginate_button page-item next'
                          previousLinkClassName='page-link'
                          nextLinkClassName='page-link'
                          onPageChange={handlePageClick}
                          pageRangeDisplayed={5}
                          pageCount={pageLimit}
                          forcePage={page - 1}
                          previousLabel='<'
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/* pagination end*/}
              </div>
            </div>
          </div>
        </div>
      </div>

      {addTenantModal && (
        <AddTenantModel
          show={addTenantModal}
          handleClose={(key: any) => {
            if (key === 0) {
              setAddTenantModal(false)
              getOwners()
            }
            if (key === 1) {
              setAddTenantModal(false)
              setAddInviteModal(true)
            }
          }}
        />
      )}
    </>
  )
}

export default Owners
