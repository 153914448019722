import React, {useEffect, useState, useRef} from 'react'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {
  ApiGet,
  ApiPost,
  ApiPut,
} from '../../../../apiCommon/helpers/API/ApiData'
import {ErrorToast, SuccessToast} from '../../../../apiCommon/helpers/Toast'
import {Controller, useForm} from 'react-hook-form'
import swal from 'sweetalert2'
import moment from 'moment'
import backArrow from '../../../../img/back-arrow.png'
import trashImg from '../../../../img/trash.png'
import Select from 'react-select'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import pencil from '../../../../img/edit-pen.png'
import Paper from '@mui/material/Paper'
import InputBase from '@mui/material/InputBase'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import ReactPaginate from 'react-paginate'
import Swal from 'sweetalert2'
import AddItem from './AddItem'
import {MultiSelect} from 'react-multi-select-component'
import addGreen from '../../../../img/add.png'
import noData from '../../../../img/NoData1.svg'
import Switch from 'react-switch'
import swapImg from "../../../../img/up-down-arrow.png";
import { components } from "react-select";
import EditAnItem from './EditAnItem'

const ExpensesList = () => {
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState<any>(false)
  const [open, setOpen] = React.useState(false)

  const [page, setPage] = useState<any>(1)
  const [limit, setLimit] = useState<any>(25)
  const [pageLimit, setPageLimit] = useState<any>(1)

  const handlePageClick = (event: any) => {
    setPage(event.selected + 1)
  }

  const [statusOptions, setStatusOptions] = useState<any>([
    {
      label: 'Active',
      value: 1,
    },
    {
      label: 'Inactive',
      value: 0,
    },
  ])
  const [selectedStatus, setSelectedStatus] = useState<any>([])

  const [typeOptions, setTypeOptions] = useState<any>([
    {
      label: 'Expense',
      value: 1,
    },
    {
      label: 'Income',
      value: 0,
    },
  ])
  const [selectedType, setSelectedType] = useState<any>([])

  const [listOptions, setListOptions] = useState<any>([])
  const [selectedList, setSelectedList] = useState<any>([])

  const [showAddItem, setShowAddItem] = useState<any>(false)
  const [showEditItem, setShowEditItem] = useState<any>(false)

  // React Select
  const customStyles = {
    container: (defaultStyles: any) => ({
      ...defaultStyles,
      width: '170px',
    }),

    option: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      color: state.isSelected ? '#000' : '#000',
      backgroundColor: state.isSelected ? '#c2d8d9' : '',
      ':hover': {
        backgroundColor: '#e0f5f0',
        color: '#000',
      },

      fontWeight: '500',
    }),

    menu: (defaultStyles: any) => ({
      ...defaultStyles,
      border: '0.1rem solid #007a59 ',
      marginTop: '0px',
      borderRadius: '0px',
      zIndex: '2',
    }),
    menuList: (defaultStyles: any) => ({
      ...defaultStyles,
      maxHeight: '200px',
    }),
    placeholder: (defaultStyles: any) => ({
      ...defaultStyles,
      fontStyle: 'Italic',
      zIndex: '1',
    }),

    input: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      color: ' #b5b5c3',
      fontWeight: '500',
    }),

    control: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      boxShadow: 'none',
      color: 'white',
      border: '0.1rem solid #bec3cb',
      borderRadius: '2px',
      padding: '0rem',
      height: '35px',
      ':hover': {
        borderColor: '#007a59',
        backgroundColor: '#fff',
      },
      ':focus': {
        borderColor: '#007a59 !important',
        backgroundColor: '#fff',
      },
      '::placeholder': {
        fontStyle: 'Italic',
      },
      minHeight: '35px',
    }),
    singleValue: (defaultStyles: any) => ({
      ...defaultStyles,
      color: '#5e6278',
      fontWeight: '600',
      zIndex: '1',
    }),
    valueContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: '0px 8px 2px 8px',
    }),
    indicatorContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: '0px 8px 2px 8px',
    }),
    indicatorsContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: '0px 8px 2px 8px',
    }),
  }

  const DropdownIndicator = (props: any) => {
    return (
      <components.DropdownIndicator {...props}>
        <div className="swap-circle-div d-flex align-items-center justify-content-center cursor-pointer">
          <img src={swapImg} height={18} width={18} alt="Dropdown Indicator" />
        </div>
      </components.DropdownIndicator>
    );
  };

  const customStylesImage = {
    container: (defaultStyles: any) => ({
      ...defaultStyles,
      // width: "200px",
    }),

    option: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      color: state.isSelected ? "#000" : "#000",
      backgroundColor: state.isSelected ? "#c2d8d9" : "",
      ":hover": {
        backgroundColor: "#e0f5f0",
        color: "#000",
      },

      fontWeight: "500",
    }),

    menu: (defaultStyles: any) => ({
      ...defaultStyles,
      border: "0.1rem solid #0d9e91 ",
      marginTop: "0px",
      borderRadius: "0px",
      zIndex: "2",
      width: "150px",
      transform: "translate(-110px, 0px)",
    }),
    menuList: (defaultStyles: any) => ({
      ...defaultStyles,
      maxHeight: "200px",
    }),

    control: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: "transparent",
      boxShadow: "none",
      border: "0rem solid transparent",
      borderRadius: "2px",
    }),

    valueContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: "0px 8px 2px 8px",
      display: "none",
    }),

    singleValue: (provided: any) => ({
      ...provided,
      backgroundImage: `url(${swapImg})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center center",
      backgroundSize: "contain", // or 'cover' based on your preference
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "transparent",
    }),
    indicatorContainer: () => ({ display: "none" }), // Hide the default dropdown indicator
    indicatorSeparator: () => ({ display: "none" }),
  };

  const searchContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#f0f0f0',
    borderRadius: '25px',
    // marginLeft: '10px',
  }

  const searchIconStyle = {
    padding: '10px',
    color: 'gray',
  }

  const inputBaseStyle = {
    flex: 1,
    padding: '5px',
  }

  const [sortType, setSortType] = useState<any>('A-Z')
  const [sortOptions, setSortOptions] = useState<any>([
    {value: 'A-Z', label: 'Alphabetic (A-Z)'},
    {value: 'Z-A', label: 'Alphabetic (Z-A)'},
    {value: 'latest', label: '(Latest - Oldest)'},
    {value: 'oldest', label: '(Oldest - Latest)'},
  ])

  const [searchName, setSearchName] = useState<any>('')
  const [checkedValues, setCheckedValues] = useState<any>([])

  let totalSelected: any = 0

  const getExpenseItemsList = () => {
    let mainId:any = []
    selectedList?.map((sl:any, ind:any)=>{
      mainId[ind] = sl?.value
    })

    let typ: any = []
    selectedType?.map((sl:any, ind:any)=>{
      typ[ind] = sl?.value
    })

    let status: any = []
    selectedStatus?.map((sl:any, ind:any)=>{
      status[ind] = sl?.value
    })


    const body = {
      page: page,
      limit: limit,
      searchName: searchName,
      sortType: sortType,
      paymentMainItemListIds: mainId,
      type: typ,
      activityStatus: status
    }

    ApiPost(`corporate/payment_sub_item_list/get`, body)
      .then((res: any) => {
        setItemsOfListData(res?.data?.data?.payment_sub_item_list_data)

        let values: any = []
        for (let i = 0; i < res?.data?.data?.payment_sub_item_list_data; i++) {
          values[i] = false
        }
        setCheckedValues(values)

        setPageLimit(res?.data?.data?.state?.page_limit)
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
      })
  }

  useEffect(() => {
    getExpenseItemsList()
  }, [searchName, sortType, selectedList, selectedType, selectedStatus])

  const [editFile, setEditFile] = useState<any>(-1)
  const [newName, setNewName] = useState<any>('')

  //
  const deleteList = () => {
    setIsLoading(true)
    let vIds: any = []

    let i: any = 0

    console.log(checkedValues)

    checkedValues?.map((ch: any, ind: any) => {
      if (ch == true) {
        vIds[i] = itemsOfListData[ind]?._id
        i++
      }
    })

    const body = {
      ids: vIds,
    }

    ApiPost(`corporate/payment_sub_item_list/delete`, body)
      .then((res: any) => {
        SuccessToast(res?.data?.message)
        setIsLoading(false)

        getExpenseItemsList()

        let values = []

        for (let i = 0; i < itemsOfListData?.length; i++) {
          values[i] = false
        }

        setCheckedValues(values)
      })
      .catch((err: any) => {
        setIsLoading(false)
        ErrorToast(err?.message)
      })
  }

  //
  const deleteSingleList = (v: any) => {
    setIsLoading(true)
    let vIds: any = []

    vIds[0] = v?._id

    const body = {
      ids: vIds,
    }

    ApiPost(`corporate/payment_main_item_list/delete`, body)
      .then((res: any) => {
        SuccessToast(res?.data?.message)
        setIsLoading(false)

        getExpenseItemsList()

        let values = []

        for (let i = 0; i < itemsOfListData?.length; i++) {
          values[i] = false
        }

        setCheckedValues(values)
      })
      .catch((err: any) => {
        setIsLoading(false)
        ErrorToast(err?.message)
      })
  }

  const updateName = (data: any) => {
    setIsLoading(true)
    const body = {
      id: data?._id,
      name: newName,
    }
    ApiPut(`corporate/payment_main_item_list`, body)
      .then((res: any) => {
        SuccessToast(res?.data?.message)
        setIsLoading(false)
        getExpenseItemsList()

        setTimeout(() => {
          setEditFile(-1)
        }, 500)
      })
      .catch((err: any) => {
        setIsLoading(false)
        ErrorToast(err?.message)
      })
  }

  const [showAddNew, setShowAddNew] = useState<any>(false)
  const [newError, setNewError] = useState<any>(false)

  const addList = () => {
    if (newName?.trim()?.length > 0) {
      setNewError(false)
      setIsLoading(true)

      let name = newName.charAt(0).toUpperCase() + newName.slice(1)

      const body = {
        name: name,
      }
      ApiPost(`corporate/payment_main_item_list`, body)
        .then((res: any) => {
          SuccessToast(res?.data?.message)
          setIsLoading(false)
          getExpenseItemsList()

          setTimeout(() => {
            setShowAddNew(false)
          }, 300)
        })
        .catch((err: any) => {
          setIsLoading(false)
          ErrorToast(err?.message)
        })
    } else {
      setNewError(true)
    }
  }

  const [itemsOfListData, setItemsOfListData] = useState<any>([])
  const [checked, setChecked] = useState<any>()
  const [selected, setSelected] = useState<any>(false)

  const [itemType, setItemType] = useState<any>()
  let [types, setTypes] = useState<any>([])

  //
  const updateSubItem = (val: any) => {
    let typetoUpdate: any = []
    itemType?.map((itm: any, ind: any) => {
      typetoUpdate[ind] = itm?.value
    })
    const body = {
      // paymentMainItemListId: id,
      id: selected?._id,
      activityStatus: val == 1 && checked ? 1 : val == 1 && !checked ? 0 : undefined,
      type: val == 2 ? typetoUpdate : undefined,
    }
    ApiPut(`corporate/payment_sub_item_list`, body)
      .then((res: any) => {
        SuccessToast(res?.data?.message)
        setIsLoading(false)
        getExpenseItemsList()
      })
      .catch((err: any) => {
        setIsLoading(false)
        ErrorToast(err?.message)
      })
  }

  useEffect(() => {
    if (checked != undefined) updateSubItem(1)
  }, [checked])

  useEffect(() => {
    if (itemType != undefined) updateSubItem(2)
  }, [itemType])

  //
  const deleteSubItems = () => {
    setIsLoading(true)
    let vIds: any = []

    let i: any = 0

    checkedValues?.map((ch: any, ind: any) => {
      if (ch == true) {
        vIds[i] = itemsOfListData[ind]?._id
        i++
      }
    })

    const body = {
      ids: vIds,
    }

    ApiPost(`corporate/payment_sub_item_list/delete`, body)
      .then((res: any) => {
        SuccessToast(res?.data?.message)
        setIsLoading(false)

        getExpenseItemsList()

        let values = []

        for (let i = 0; i < itemsOfListData?.length; i++) {
          values[i] = false
        }

        setCheckedValues(values)
      })
      .catch((err: any) => {
        setIsLoading(false)
        ErrorToast(err?.message)
      })
  }

  //
  const deleteSingleSubItem = (v: any) => {
    setIsLoading(true)
    let vIds: any = []

    vIds[0] = v?._id

    const body = {
      ids: vIds,
    }

    ApiPost(`corporate/payment_sub_item_list/delete`, body)
      .then((res: any) => {
        SuccessToast(res?.data?.message)
        setIsLoading(false)

        getExpenseItemsList()

        let values = []

        for (let i = 0; i < itemsOfListData?.length; i++) {
          values[i] = false
        }

        setCheckedValues(values)
      })
      .catch((err: any) => {
        setIsLoading(false)
        ErrorToast(err?.message)
      })
  }

   //
   const getListForDropdown = async () => {
    await ApiGet(`corporate/payment_main_item_list`)
      .then((res: any) => {
        let values: any = []
        res?.data?.data?.map((val: any, ind: any) => {
          values[ind] = {
            label: `${val?.name}`,
            value: `${val?._id}`,
          }
        })
        setListOptions(values)
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
      })
  }

  useEffect(() => {
    getListForDropdown()
  }, [])



  return (
    <>
      <div className='app-main flex-column flex-row-fluid px-2' id='kt_app_main'>
        <div id='' className='pt-0 mt-0'>
          {/* Header */}
          <div className='page-title d-flex flex-column justify-content-center flex-wrap'>
            <div className=' d-flex flex-stack pt-1 mt-0 px-0'>
              {' '}
              {/* container-xxl */}
              <div className=''>
                <div className='d-flex align-items-center mb-1'>
                  <span
                    className='rounded-circle cursor-pointer'
                    onClick={() => {
                      navigate('/settings')
                    }}
                  >
                    <img src={backArrow} height={14} width={14} />
                  </span>
                  <h2 className='m-0 head-text ms-2'>{'Payment Items'}</h2>
                </div>
              </div>
            </div>
          </div>
          {/* Tabs */}
          <div
            className='d-flex align-items-center gap-2 gap-lg-5 justify-content-center align-items-center'
            style={{marginBottom: '35px'}}
          >
            <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2 xyz'>
              <li className='nav-item'>
                <a
                  className={`nav-link text-active-primary pb-2 me-5 px-5 `}
                  data-bs-toggle='tab'
                  onClick={() => navigate('/settings/payment-items-lists')}
                >
                  Item Lists
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className={`nav-link text-active-primary pb-2 me-5 px-5 active`}
                  data-bs-toggle='tab'
                  onClick={() => navigate('/settings/payment-items')}
                >
                  All Items
                </a>
              </li>
            </ul>
          </div>

          {checkedValues?.length > 0 && (
            <>
              {checkedValues?.map((ch: any, ind: any) => {
                if (ch == true) totalSelected = totalSelected + 1
                return <></>
              })}
            </>
          )}

          <div className='card card-flush py-5 mt-5 pt-0 px-5'>
            <div
              className='d-flex align-items-center py-5 pt-2 gy-3 mx-0 mt-4 xyz tst'
              // style={{ justifyContent: "space-between" }}
            >
              <label className='head-text me-5 pe-3' style={{fontWeight: '600'}}>
                Search{' '}
              </label>

              {/* Seach by Vendor */}
              <div className='me-4 xyz'>
                <Paper component='form' style={searchContainerStyle}>
                  <InputBase
                    placeholder='Item'
                    style={inputBaseStyle}
                    inputProps={{'aria-label': 'search'}}
                    value={searchName}
                    onChange={(e: any) => setSearchName(e.target.value)}
                  />
                  <IconButton style={searchIconStyle} aria-label='search'>
                    <SearchIcon />
                  </IconButton>
                </Paper>
              </div>
              <div className='test property me-4 '>
                <div
                  className={`multi-select-container ${
                    selectedList.length === 0 ? 'no-value payment-items-list' : 'has-value'
                  }`}
                >
                  <MultiSelect
                    options={listOptions}
                    value={selectedList}
                    onChange={setSelectedList}
                    labelledBy='List'
                    overrideStrings={{
                      allItemsAreSelected: 'All Lists', // Custom text for when all items are selected
                    }}
                  />
                </div>
              </div>
              <div className='test property me-4 '>
                <div
                  className={`multi-select-container ${
                    selectedStatus.length === 0 ? 'no-value payment-items-status' : 'has-value'
                  }`}
                >
                  <MultiSelect
                    options={statusOptions}
                    value={selectedStatus}
                    onChange={setSelectedStatus}
                    labelledBy='Status'
                    overrideStrings={{
                      allItemsAreSelected: 'All Statuses', // Custom text for when all items are selected
                    }}
                  />
                </div>
              </div>
              <div className='test property me-4 '>
                <div
                  className={`multi-select-container ${
                    selectedType.length === 0 ? 'no-value payment-items-type' : 'has-value'
                  }`}
                >
                  <MultiSelect
                    options={typeOptions}
                    value={selectedType}
                    onChange={setSelectedType}
                    labelledBy='Type'
                    overrideStrings={{
                      allItemsAreSelected: 'All Types',
                    }}
                  />
                </div>
              </div>
              <div className='me-4 xyz'>
                <Select
                  placeholder={'Sort'}
                  styles={customStylesImage}
                  isSearchable={false}
                  options={sortOptions}
                  name='sort'
                  onChange={(e: any) => {
                    setSortType(e.value)
                  }}
                  components={{ DropdownIndicator }}
                />
              </div>

              <div className=' d-flex ms-auto'>
                {totalSelected > 0 && (
                  <>
                    <div className='d-flex'>
                      <h3 className='my-2' style={{color: 'black'}}>
                        <label className='labl-gry '>
                          {' '}
                          <i>Total Selected </i>
                        </label>
                      </h3>
                      <span className='ms-5 ps-1 my-2 me-4'>
                        <b>{totalSelected}</b>
                      </span>
                    </div>

                    {/* Delete CTA */}
                    <button
                      type='button'
                      className='btn btn-sm fw-bold  ms-3 red-hollow-btn px-2'
                      onClick={() => {
                        swal
                          .fire({
                            html: ` 
                            <div class='fs-3'> <b>Delete Lists</></div>
                             <div class='fs-5 mt-4'>You are about to delete the selected lists.</div>
                              <div class='fs-5 mt-4'> Once deleted, you will not be able to view them nor select them in future. All past and current requests will not be affected.
                              </div>
                            <div class='fs-5 mt-4'> Are you sure you want to continue? </div>`,
                            // icon: 'warning',
                            showConfirmButton: true,
                            confirmButtonColor: '#35475e',
                            confirmButtonText: 'Yes',
                            showCancelButton: true,
                            cancelButtonText: 'No',
                            cancelButtonColor: '#fff',
                            customClass: {
                              confirmButton: 'custom-confirm-button',
                              cancelButton: 'custom-cancel-button',
                              popup: 'custom-popup',
                            },
                            reverseButtons: true, // Reverse the order of buttons reverseButtons: true,
                          })
                          .then((res: any) => {
                            if (res.isConfirmed) {
                              deleteList()
                            }
                          })
                      }}
                      disabled={isLoading}
                    >
                      <img src={trashImg} height={18} width={18} className='me-4' /> Delete
                    </button>
                  </>
                )}

                {/* Add Item CTA */}
                <button
                  type='button'
                  className='btn btn-sm fw-bold  mx-3 px-2 green-hollow-btn'
                  onClick={() => {
                    setShowAddItem(true)
                  }}
                  disabled={isLoading || showAddNew || editFile != -1}
                >
                  <img src={addGreen} height={18} width={18} className='me-4' /> Add Item
                </button>
              </div>
            </div>

            <div className='table-responsive'>
              <table className='table align-middle fs-6 gy-5' id='kt_ecommerce_sales_table'>
                <thead>
                  <tr className='text-start text-gray-400 fw-bold gs-0 fs-5'>
                    <th className='text-start min-w-25px test'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 px-0'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          id='vacant0'
                          name='none'
                          checked={
                            totalSelected == itemsOfListData?.length && itemsOfListData?.length != 0
                              ? true
                              : false
                          }
                          onChange={(e: any) => {
                            let values = [...checkedValues]
                            if (e.target.checked) {
                              for (let i = 0; i < itemsOfListData?.length; i++) {
                                values[i] = true
                              }
                            } else {
                              for (let i = 0; i < itemsOfListData?.length; i++) {
                                values[i] = false
                              }
                            }
                            setCheckedValues(values)
                          }}
                        />
                      </div>
                    </th>
                    <th className='text-start min-w-50px ps-0'>ID</th>
                    <th className='text-start min-w-150px ps-0'>Item Name</th>
                    <th className='text-start min-w-150px ps-0'>List</th>
                    <th className='text-start min-w-200px ps-0'>Status</th>
                    <th className='text-start min-w-200px ps-0'>Type</th>
                    <th className='text-start min-w-150px ps-0'>Created</th>
                    <th className='text-start min-w-150px ps-0'>Updated</th>
                    <th className='text-start min-w-150px ps-0'></th>
                  </tr>
                </thead>
                <tbody style={{fontWeight: '500'}} className='table-body'>
                  {itemsOfListData?.length > 0 ? (
                    itemsOfListData?.map((listItem: any, ind: any) => {
                      if (listItem?.type?.length == 1) {
                        if (listItem?.type?.[0] == 0)
                          types[ind] = [
                            {
                              label: 'Income',
                              value: 0,
                            },
                          ]
                        else
                          types[ind] = [
                            {
                              label: 'Expense',
                              value: 1,
                            },
                          ]
                      } else if (listItem?.type?.length == 2) {
                        types[ind] = [
                          {
                            label: 'Income',
                            value: 0,
                          },
                          {
                            label: 'Expense',
                            value: 1,
                          },
                        ]
                      } else types[ind] = []
                      return (
                        <tr>
                          <td className='text-start test'>
                            <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 px-0'>
                              <input
                                className='form-check-input'
                                type='checkbox'
                                id='vacant0'
                                name='none'
                                checked={checkedValues[ind] == true ? true : false}
                                onChange={(e: any) => {
                                  const values = [...checkedValues]
                                  values[ind] = !values[ind]
                                  setCheckedValues(values)
                                }}
                              />
                            </div>
                          </td>
                          <td className='text-start ps-0'>
                            <div className='d-flex head-text align-items-center'>
                              <p className='user-head pe-2 mb-0 ps-0'>
                                {listItem?.id ? listItem?.id : '-'}
                                {'.   '}
                              </p>
                            </div>
                          </td>

                          <td className='text-start ps-0'>
                            <p className='user-head pe-2 mb-0'>
                              {listItem.name ? listItem?.name : '-'}
                            </p>
                          </td>
                          <td className='text-start ps-0'>
                            <p className='user-head pe-2 mb-0 cursor-pointer'>
                              {listItem?.payment_main_item_list?.[0]?.name
                                ? listItem?.payment_main_item_list?.[0]?.name
                                : '-'}
                            </p>
                          </td>
                          <td className='d-flex align-items-center ps-0'>
                            <Switch
                              defaultChecked={checked}
                              uncheckedIcon={false}
                              checkedIcon={false}
                              checked={listItem?.activityStatus === 1 ? true : false}
                              onColor='#00cf99'
                              offColor='#b0bec5'
                              onChange={(e: any) => {
                                setSelected(listItem)
                                setChecked(e)
                              }}
                            />
                            <p className='ms-4 pt-4'>
                              <i>{listItem.activityStatus === 0 ? 'Inactive' : 'Active'}</i>
                            </p>
                          </td>
                          <td className='text-start ps-0'>
                            <div className='test property me-4 d-none-svg'>
                              <div
                                className={`multi-select-container ${
                                  listItem?.type?.length === 0
                                    ? 'no-value payment-items-status'
                                    : 'has-value'
                                }`}
                              >
                                <MultiSelect
                                  options={typeOptions}
                                  value={types[ind]}
                                  onChange={(e: any) => {
                                    setSelected(listItem)
                                    setItemType(e)
                                  }}
                                  labelledBy='Status'
                                  overrideStrings={{
                                    allItemsAreSelected: 'All', // Custom text for when all items are selected
                                  }}
                                  disableSearch={true}
                                />
                              </div>
                            </div>
                          </td>

                          <td
                            className=' py-3 ps-0  ps-0 pe-5'
                            style={editFile == ind ? {opacity: '0.5'} : {opacity: '1'}}
                          >
                            <p className='my-0 user-head'>
                              {listItem?.createdBy?.[0] &&
                                `${listItem?.createdBy?.[0]?.firstName} ${listItem?.createdBy?.[0]?.lastName} - `}
                            </p>
                            <p className='my-0 user-head'>
                              {listItem?.createdAt
                                ? `${moment(listItem?.createdAt).format('DD/MM/YYYY hh:mm A')}`
                                : '-'}
                            </p>
                          </td>
                          <td
                            className='py-3 ps-0  ps-0 pe-5'
                            style={editFile == ind ? {opacity: '0.5'} : {opacity: '1'}}
                          >
                            {listItem?.updatedBy?.length > 0 ? (
                              <>
                                <p className='my-0 user-head'>
                                  {listItem?.updatedBy?.[0] &&
                                    `${listItem?.updatedBy?.[0]?.firstName} ${listItem?.updatedBy?.[0]?.lastName} - `}
                                </p>
                                <p className='my-0 user-head'>
                                  {listItem?.updatedAt
                                    ? ` ${moment(listItem?.updatedAt).format('DD/MM/YYYY hh:mm A')}`
                                    : '- -'}
                                </p>
                              </>
                            ) : (
                              <>
                                <p className='my-0 user-head'>-</p>
                              </>
                            )}
                          </td>
                          <td>
                            <div className='d-flex align-items-center'>
                              <img
                                src={pencil}
                                height={16}
                                width={16}
                                style={
                                  editFile == ind
                                    ? {opacity: '0.4', cursor: 'not-allowed'}
                                    : {opacity: '1', cursor: 'pointer'}
                                }
                                className='me-5 '
                                onClick={() => {
                                  setSelected(listItem)
                                  setShowEditItem(true)
                                }}
                              />
                              <img
                                src={trashImg}
                                height={18}
                                width={18}
                                className='me-5'
                                style={
                                  editFile == ind
                                    ? {opacity: '0.4', cursor: 'not-allowed'}
                                    : {opacity: '1', cursor: 'pointer'}
                                }
                                onClick={(e) => {
                                  if (editFile !== ind) {
                                    swal
                                      .fire({
                                        html: ` 
                                        <div class='fs-3'> <b>Delete Item</></div>
                                        <div class='fs-5 mt-4'>You are about to delete the selected item.</div>
                                      
                                        <div class='fs-5 mt-4'> Are you sure you want to continue? </div>`,
                                        // icon: 'warning',
                                        showConfirmButton: true,
                                        confirmButtonColor: '#35475e',
                                        confirmButtonText: 'Yes',
                                        showCancelButton: true,
                                        cancelButtonText: 'No',
                                        cancelButtonColor: '#fff',
                                        customClass: {
                                          confirmButton: 'custom-confirm-button',
                                          cancelButton: 'custom-cancel-button',
                                          popup: 'custom-popup',
                                        },
                                        reverseButtons: true, // Reverse the order of buttons reverseButtons: true,
                                      })
                                      .then((res: any) => {
                                        if (res.isConfirmed) {
                                          deleteSingleSubItem(listItem)
                                        }
                                      })
                                  }
                                }}
                              />
                            </div>
                          </td>
                        </tr>
                      )
                    })
                  ) : (
                    <tr>
                      <td colSpan={10} className='text-center'>
                        <img src={noData} alt='' width={350} />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            <div className='row mb-5'>
              <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
              <div className='row mt-5'>
                <div className='col-sm-12 col-md-12 d-flex align-items-center justify-content-center justify-content-md-end mt-5'>
                  <div
                    className='dataTables_paginate paging_simple_numbers'
                    id='kt_ecommerce_sales_table_paginate'
                  >
                    <ReactPaginate
                      breakLabel='...'
                      nextLabel='>'
                      pageClassName='paginate_button page-item +'
                      pageLinkClassName='page-link'
                      containerClassName='pagination'
                      activeClassName='active'
                      previousClassName='paginate_button page-item previous'
                      nextClassName='paginate_button page-item next'
                      previousLinkClassName='page-link'
                      nextLinkClassName='page-link'
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={5}
                      pageCount={pageLimit}
                      forcePage={page - 1}
                      previousLabel='<'
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <AddItem
        show={showAddItem}
        handleClose={() => setShowAddItem(false)}
        from={'list-view'}
        updateRecord={() => getExpenseItemsList()}
      />

{selected && showEditItem && (
        <EditAnItem
          show={showEditItem}
          handleClose={() => setShowEditItem(false)}
          from={'detailed-view'}
          updateRecord={() => getExpenseItemsList()}
          selectedItem={selected}
        />
      )}

      <Backdrop sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}} open={open}>
        <CircularProgress style={{color: '#007a59'}} />
        {/* <div style={{color: '#007a59', fontWeight: '700',
      }}>LOADING..</div> */}
      </Backdrop>
    </>
  )
}

export default ExpensesList
