import React from 'react'
import {useState, useEffect} from 'react'
import {Row, Col} from 'react-bootstrap'
import {useNavigate, useLocation} from 'react-router-dom'
import backArrow from '../../../../../img/back-arrow.png'
import purchaseImg from '../../../../../img/invoice-blue.png'
import {ApiGet, ApiPut} from '../../../../../apiCommon/helpers/API/ApiData'
import { SuccessToast, ErrorToast } from '../../../../../apiCommon/helpers/Toast'
import editpen from '../../../../../img/pen.png'
import POConfigureModal from './POConfigureModal'
import Switch from 'react-switch'

const PurchaseOrderGenerator = () => {
  const navigate = useNavigate()
  const [pONoModal, setpONoModal] = useState<any>(false)
  const [details, setDetails] = useState<any>(null)
  const [pOSeq, setPOSeq] = useState<any>(null)

  //
  const getDetails = () => {
    ApiGet('corporate/corporate_setting')
      .then((res) => {
        setDetails(res?.data?.data)
        setPOSeq(res?.data?.data?.purchaseOrderSequence)
      })
      .catch((err) => {
        ErrorToast(err?.message)
      })
  }

  //
  const updateActivityStatus = (val: any) => {
    let status: any
    if (val) status = 1
    else status = 0

    const body = {
      activityStatus: status,
    }

    ApiPut('corporate/corporate_setting/purchase_order', body)
      .then((res) => {
        getDetails()
      })
      .catch((err) => ErrorToast(err.message))
  }

  useEffect(() => {
    getDetails()
  }, [])

  return (
    <>
      {/* <div className='app-main flex-column flex-row-fluid' id='kt_app_main'> */}
      <div id='' className='pt-0 mt-0 px-2'>
        <div className='d-flex align-items-center gap-2 mb-10 gap-lg-3 '>
          <div className='d-flex align-items-center gap-3'>
            <span
              className='rounded-circle cursor-pointer'
              
              onClick={() => navigate('/settings')}
            >
              <img src={backArrow}  height={14} width={14} />
            </span>
            <h2 className='page-heading m-0 head-text text-capitalize'>Purchase Order</h2>
          </div>
          <div className='ms-auto'></div>
          <div className='ms-5'>
            <img src={purchaseImg} height={40} width={30} />
          </div>
        </div>

        {/* Tabs */}
        <div
          className='d-flex align-items-center gap-2 gap-lg-5 justify-content-center align-items-center'
          style={{marginBottom: '35px'}}
        >
          <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2'>
            <li className='nav-item'>
              <a
                className='nav-link text-active-primary pb-2 me-5 active'
                data-bs-toggle='tab'
                onClick={() => navigate('/settings/procurement/purchase-order')}
              >
                Purchase Order No. Generator
              </a>
            </li>
            <li className='nav-item'>
              <a
                className='nav-link text-active-primary pb-2 mx-5 '
                data-bs-toggle='tab'
                onClick={() => navigate('/settings/procurement/purchase-order-design')}
              >
                Purchase Order Design
              </a>
            </li>
          </ul>
        </div>

        <Row>
          <Col md={12} className='pe-5'>
            {/* Purchase Order No. Configuration */}
            <div className='card px-5 py-5 mt-5'>
              <div className='d-flex'>
                {/* <h1 className='top head-text '>Invoice No Configuration</h1> */}
                <div className='d-flex ms-auto'>
                  <Switch
                    onChange={(e) => {
                      updateActivityStatus(e)
                    }}
                    defaultChecked={true}
                    checked={pOSeq?.activityStatus == 1 ? true : false}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    onColor='#00cf99'
                    offColor='#b0bec5'
                    className='ms-auto me-5'
                  />
                  <img
                    src={editpen}
                    alt=''
                    className='ms-4 me-2'
                    style={{cursor: 'pointer', height: '22px', width: '22px', marginTop: '3px'}}
                    onClick={() => {
                      setpONoModal(true)
                    }}
                  />
                </div>
              </div>

              <Row className="mt-3">
                <Col xs={6} sm={6} md={4} lg={4}>
                  <div className='d-flex grid_big_col py-2 fs-7' >
                    <label
                      htmlFor='exampleFormControlInput1'
                      className='form-label blue-label fs-7'
                      style={{minWidth: '115px'}}
                    >
                      Start No.
                    </label>
                    <div className='d-flex'>{pOSeq?.startNumber}</div>
                  </div>
                </Col>
                <Col xs={6} sm={6} md={4} lg={4}>
                  <div className='d-flex grid_big_col py-2 fs-7'>
                    <label
                      htmlFor='exampleFormControlInput1 '
                      className='form-label blue-label fs-7'
                      style={{minWidth: '115px'}}
                    >
                      Increment By
                    </label>
                    <div className='d-flex'>{pOSeq?.increment}</div>
                  </div>
                </Col>
                <Col xs={6} sm={6} md={4} lg={4}>
                  <div className='d-flex grid_big_col py-2 fs-7'>
                    <label
                      htmlFor='exampleFormControlInput1'
                      className='form-label blue-label fs-7'
                      style={{minWidth: '115px'}}
                    >
                      No. of Digits
                    </label>
                    <div className='d-flex'>{pOSeq?.noOfDigits}</div>
                  </div>
                </Col>
                <Col xs={6} sm={6} md={4} lg={4}>
                  <div className='d-flex grid_big_col py-2 fs-7'>
                    <label
                      htmlFor='exampleFormControlInput1'
                      className='form-label blue-label fs-7'
                      style={{minWidth: '115px'}}
                    >
                      Prefix
                    </label>
                    <div className='d-flex'>
                      {pOSeq?.prefix != null ? pOSeq?.prefix : '-'}
                    </div>
                  </div>
                </Col>

                <Col xs={6} sm={6} md={4} lg={4}>
                  <div className='d-flex grid_big_col py-2 fs-7'>
                    <label
                      htmlFor='exampleFormControlInput1'
                      className='form-label blue-label fs-7'
                      style={{minWidth: '115px'}}
                    >
                      Suffix
                    </label>
                    <div className='d-flex'>
                      {pOSeq?.suffix != null ? pOSeq?.suffix : '-'}
                    </div>
                  </div>
                </Col>

                <Col xs={6} sm={6} md={4} lg={4}>
                  <div className='d-flex grid_big_col py-2 fs-7'>
                    <label
                      htmlFor='exampleFormControlInput1'
                      className='form-label blue-label fs-7'
                      style={{minWidth: '115px'}}
                    >
                      Last Count
                    </label>
                    <div className='d-flex'>
                      {pOSeq?.lastCount}
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>

      {/*  */}
      {pONoModal && (
        <POConfigureModal
          show={pONoModal}
          handleClose={() => {
            setpONoModal(false)
          }}
          pOSeq={pOSeq}
          getDetails={getDetails}
        />
      )}
    </>
  )
}

export default PurchaseOrderGenerator
