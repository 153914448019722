import React from 'react'
import dragImg from '../../../../../img/drag-img.png'
import addImg from '../../../../../img/add-green-filled.png'
import removeImg from '../../../../../img/remove-filled.png'

interface SectionControlsProps {
  sectionId: string
  onDuplicate: (id: string) => void
  onDelete: (id: string) => void
  dragHandleProps: any
  selectedSection:any
}

const SectionControls: React.FC<SectionControlsProps> = ({sectionId, onDuplicate, onDelete, dragHandleProps, selectedSection}) => (
  <div className={`section-controls-style ${selectedSection == sectionId ? '' : 'd-none'}`}>
    <img src={dragImg} height={16} width={16} className='mb-2 cursor-pointer' {...dragHandleProps}  />
    <img
      src={addImg}
      height={16}
      width={16}
      className='mb-2 cursor-pointer'
      onClick={() => onDuplicate(sectionId)}
    />
    <img
      src={removeImg}
      height={16}
      width={16}
      className='cursor-pointer'
      onClick={() => onDelete(sectionId)}
    />
  </div>
)

export default SectionControls
