import {useIntl} from 'react-intl'
import {MenuItem} from './MenuItem'
import {MenuInnerWithSub} from './MenuInnerWithSub'
import {MegaMenu} from './MegaMenu'

export function MenuInner() {
  const intl = useIntl()

  const userData: any = localStorage.getItem('userData')
  const user = JSON.parse(userData)

  const permission: any = localStorage.getItem('permission')
  const data = JSON.parse(permission)

  const roleAndPermissions: any = localStorage.getItem('roleAndPermissions')
  const role = JSON.parse(roleAndPermissions)
  return (
    <>
      {/* <MenuInnerWithSub title={intl.formatMessage({id: 'Overview'})} to='/dashboard2' /> */}

      <MenuItem title='Dashboard' to='/' paths={['/dashboard']} />

      {/* Old Properties Module */}
      {/* {data?.property === true && (
        <MenuItem
          title='Properties 1'
          to='/dashboard'
          paths={['/dashboard', '/building-dashboard', '/community-dashboard']}
        />
      )} */}

      {/* Updated Properties Module  */}
      {data?.property === true && (
        <MenuItem title='Properties' to='/properties' paths={['/properties',
          '/properties/building-details/:id',
          '/properties/building-details/:id/floors/:id',
          '/properties/building-details/:id/floors/:id/edit-property/:propertyId',
          '/properties/building-details/:id/floors/:id/add-property',
          '/properties/building-details/:id/add-property',
          '/properties/building-details/:id/edit-property/:propertyId',
          '/properties/add-property',
          '/properties/edit-property/:id',
          '/properties/community-details/:id',
          '/properties/community-details/:id/add-property',
          '/properties/community-details/:id/edit-property/:propertyId',
          '/properties/community-details/:id/unit-cluster/:id/add-property',
          '/properties/community-details/:id/building-cluster/:id/add-property',
          '/properties/community-details/:id/mixed-cluster/:id/add-property',
          '/properties/community-details/:id/mixed-cluster/:id/group/:id/add-property',
          '/properties/community-details/:id/mixed-cluster/:id/group/:id/floor/:id/add-property',
          '/properties/community-details/:id/building-cluster/:id/building/:id/add-property',
          '/properties/community-details/:id/building-cluster/:id/building/:id/floor/:id/add-property',
          '/properties/community-details/:id/unit-cluster/:id',
          '/properties/community-details/:id/unit-cluster/:id/edit-property/:propertyId',
          '/properties/community-details/:id/building-cluster/:id/edit-property/:propertyId',
          '/properties/community-details/:id/mixed-cluster/:id/edit-property/:propertyId',
          '/properties/community-details/:id/building-cluster/:id',
          '/properties/community-details/:id/mixed-cluster/:id',
          '/properties/community-details/:id/mixed-cluster/:id/group/:id',
          '/properties/community-details/:id/mixed-cluster/:id/group/:id/edit-property/:propertyId',
          '/properties/community-details/:id/mixed-cluster/:id/group/:id/floor/:id',
          '/properties/community-details/:id/mixed-cluster/:id/group/:id/floor/:id/edit-property/:propertyId',
          '/properties/community-details/:id/building-cluster/:id/building/:id',
          '/properties/community-details/:id/building-cluster/:id/building/:id/edit-property/:propertyId',
          '/properties/community-details/:id/building-cluster/:id/building/:id/floor/:id',
          '/properties/community-details/:id/building-cluster/:id/building/:id/floor/:id/edit-property/:propertyId',
          '/properties/community-details/:id/building-cluster/:id/building/:id/floor/:id',
          '/properties/add-development',
          '/properties/edit-development',
          '/properties/community-details/:id/building-cluster/:id/building/:id/edit-building',
          '/properties/community-details/:id/mixed-cluster/:id/group/:id/edit-building',
          '/property-profile/:id'
        ]} />
      )}

      {/* Leases */}
      {/* {data?.tenancy === true && ( */}
      {((user?.isSubUser && role?.leasing_residential?.lease_agreements?.view_list) ||
        !user?.isSubUser) && <MenuItem title='Leases' to='/tenancies' paths={['/tenancies',
          '/tenancy/:action/:flg',
          '/create-approve-tenancy/:flg',
          '/create-approve-tenancy/:flg/:unitId/:tenancyId',
          '/lease-request/:id/:unitId/:tenancyId/:type',
          '/tenancy/:action/:flg/:unitId/:tenancyId',
          '/tenancy-view/:flg/:id/:tenancyId',
          '/tenancy-termination/:flg/:id/:tenancyId',
          '/renew-tenancy/:flg/:id/:tenancyId'

        ]} />}

      {/* Tenants */}
      {/* {data?.tenant === true && <MenuItem title='Tenants-1' paths={['/tenants']} to='/tenants' />} */}
      {data?.tenant === true && <MenuItem title='Tenants' paths={['/tenants-new', '/tenant-profile/:od']} to='/tenants-new' />}

      {/* Owners */}
        <MenuItem title='Owners' paths={['/owners', '/owner-profile/:id']} to='/owners' />

      {/* Finanacials */}
      {data?.tenancy === true && (
        <MenuItem
          title='Financials'
          to='/finance-income'
          paths={[
            '/financials-income',
            '/financials-expenses',
            '/finance-crm-residents',
            '/financials-statement-reports',
            '/financials-payment-reminders',
            '/finance-income',
            '/financials/add-expense/:flg',
            '/financials/add-expense/:flg/:id',
            '/financials/view-expense/:id',
            '/finance-resident-request/:id',
            '/financials/add-income-payment/:flg',
            '/financials/add-income-payment/:flg/:id',
            '/finance-income/view-income-payment/:id',
            
          ]}
        />
      )}

      {/* Annoucements */}
      {data?.announcement === true && (
        <MenuItem title='Announcements' to='/announcements' paths={['/announcements','/create-announcement/:flg', '/create-announcement/:flg/:id'
        ]} />
      )}

      {/* Vendor Management */}
      {/* {data?.service === true && <MenuItem title='Facility' to='/facility' paths={['/facility']} />} */}
      {data?.vendorList === true && (
        <MenuItem
          title='Vendor Management'
          to='/vendor-management'
          paths={['/vendor-management',
            '/add-vendor/:flg',
            '/add-vendor/:flg/:id',
            '/vendor/:id',
            '/manage-vendors'

          ]}
          // to='/vendor-management/serviceproviders'
          // paths={['/vendor-management/serviceproviders']}
        />
      )}

      {/* {data?.externalServiceRequest === true || data?.internalServiceRequest === true ? (
        <MenuItem
          title='Request Management'
          to='/request-management/service'
          paths={['/request-management/service']}
        />
      ) : (
        ''
      )} */}

       {/* Maintenance */}
      <MenuItem
        title='Maintenance'
        to='/maintenance'
        paths={[
          '/maintenance',
          '/maintenance/create-request/:type/:flg/:jobType',
          '/maintenance/create-request/:type/:flg/:jobType/:id',
          '/maintenance/create-request/purchase-order/:type/:flg/:id',
          '/maintenance/create-request/purchase-order/:type/:flg/:id/:poType',
          '/maintenance/view-purchase-order/:type/:flg/:id/:poId',
          '/maintenance/create-request/purchase-order/:type/:flg/:id/:poId',
          '/maintenance/create-request/purchase-order/:type/:flg/:id/:poId/:poType',
          '/maintenance/request/:type/:id',
          '/maintenance/request/:reqId/job/:id',
          '/maintenance-resident-request/:id'
        ]}
      />

      {/* <MenuInnerWithSub title='Tenants' to='/crafted'></MenuInnerWithSub> */}
      {/* {data?.tenancy === true && (
        <MenuInnerWithSub title='Payments & Rentttt' to='/mega-menu'>
          <MegaMenu />
        </MenuInnerWithSub>
      )} */}

      {/* CRM */}
      <MenuItem
        title='CRM'
        to='/crm-residents'
        paths={[
          '/crm-residents',
          '/resident-request/:id'
        ]}
      />
    </>
  )
}
