import React, {useState, useEffect} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import Swal from 'sweetalert2'
import submitImg from '../../../../../../../img/Submit-White.png'
import backArrow from '../../../../../../../img/back-arrow.png'
import trashImg from '../../../../../../../img/trash.png'
import saveGreenImg from '../../../../../../../img/save-dark-green.png'
import NoteDetailsCard from '../components/NoteDetailsCard'
import {ApiGet, ApiPost, ApiDelete} from '../../../../../../../apiCommon/helpers/API/ApiData'
import {Controller, useForm} from 'react-hook-form'
import {SuccessToast, ErrorToast} from '../../../../../../../apiCommon/helpers/Toast'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import {Row, Col} from 'react-bootstrap'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {IoMdInformationCircleOutline} from 'react-icons/io'
import downloadImg from '../../../../../../../img/download-img.png'
import share from '../../../../../../../img/email.png'
import print from '../../../../../../../img/printer.png'
import editPen from '../../../../../../../img/edit-pen.png'
import moment from 'moment'

const ViewCreditNote = () => {
  const navigate = useNavigate()
  const {flg, id} = useParams()

  const [isLoading, setIsLoading] = useState<any>(false)
  const [isViewOnly, setIsViewOnly] = useState<any>(false)

  const [goToNext, setGoToNext] = useState<any>(false)
  const [selectedInvoices, setSelectedInvoices] = useState<any>([])

  const {
    register,
    control,
    handleSubmit,
    reset,
    setValue,
    formState: {errors},
    trigger,
  } = useForm<any>({mode: 'onSubmit', reValidateMode: 'onChange'})

  const [creditNoteData, setCreditNoteData] = useState<any>({
    name: 'CREDIT NOTE',
    creditNoteNo: '',
    note: '',
    payorData: {},
    items: [],
    creditNoteCompany: {},
    creditNoteLogo: null,
    showNotes: true,
    itemDescriptions: [],
  })

  //
  const updateSelectedInvoices = (val: any) => {
    let values: any = [...selectedInvoices]
    val?.map((vl: any, ind: number) => {
      values?.push(vl)
    })

    setSelectedInvoices(values)
  }

  // const [isLoading, setIsLoading] = useState<any>(false)

  const [isSaveFirst, setIsSaveFirst] = useState<any>(false)
  const [autoSaveData, setAutoSaveData] = useState<any>(false)

  const [existingInvoice, setExistingInvoice] = useState<any>(null)

  const [open, setOpen] = React.useState(false)

  //
  const addCreditNote = async (saveStatus: number) => {
    let isValid: any = true
    if (saveStatus == 1) {
      isValid = await trigger()
    }

    if (isValid) {
    } else {
    }
  }

  //
  const getCreditNoteById = async () => {}

  //
  useEffect(() => {
    if (isSaveFirst || flg == '2') getCreditNoteById()
  }, [isSaveFirst])

  //
  useEffect(() => {
    if (autoSaveData != false && flg == '2') {
      addCreditNote(0)
    }
  }, [autoSaveData])

  // delete credit note
  const deleteCreditNote = () => {}

  return (
    <>
      <div className='app-main flex-column flex-row-fluid px-2' id='kt_app_main'>
        <form
          onSubmit={handleSubmit((data: any, e: any) => {
            addCreditNote(1)
          })}
        >
          <div id='' className='pt-0 mt-0'>
            {/* Header */}
            <div className='page-title d-flex flex-column justify-content-center flex-wrap mb-4'>
              {' '}
              <div className='d-flex align-items-center mb-1'>
                <div className='d-flex align-items-center'>
                  <span
                    className='rounded-circle cursor-pointer'
                    onClick={() => {
                      navigate('/finance-income')
                    }}
                  >
                    <img src={backArrow} height='14' width='14' />
                  </span>
                  <h2 className='m-0 head-text ms-2 text-capitalize'>{`Credit Note`}</h2>
                </div>
              </div>
            </div>

            {/*  Details Card */}
            <div className='card card-flush py-7 px-5'>
              <div className='d-flex mt-4 align-items-start'>
                <div className=''>
                  <h4 className='text-dark'>
                    <label className='labl-gry status-w-150'> Credit Note No.</label>

                    <span className='ms-1'>
                      <b>{'CN_7854'}</b>
                    </span>
                  </h4>

                  <h4 className='text-dark pt-5'>
                    <label className='labl-gry status-w-150'> Credit Amount</label>

                    <span className='ms-1'>
                      <b>{'AED 8620.5'}</b>
                    </span>
                  </h4>
                </div>

                <div className='me-2 ms-auto'>
                  <div
                    className={
                      creditNoteData?.formStatus == 0
                        ? 'status draft-st ms-5 status-w-120'
                        : 'status create-st ms-5 status-w-120'
                    }
                  >
                    {creditNoteData?.formStatus == 0 ? 'Draft' : 'Created'}
                  </div>
                  <i style={{paddingRight: '3px', paddingLeft: '18px'}}>
                    {' '}
                    {creditNoteData?.formStatusDateTime
                      ? `${moment
                          .utc(creditNoteData?.formStatusDateTime)
                          .local()
                          .format('DD.MM.YYYY - hh:mm A')}`
                      : ''}
                  </i>
                </div>
              </div>

              {/* Credit Note Status */}
              <div className='d-flex py-5 align-items-start'>
                <h4 className='text-dark'>
                  <label className='labl-gry status-w-150'> Credit Note Status</label>
                </h4>

                <span className='ms-1'>
                  <div className=' me-2'>
                    <div
                      className={`status ${
                        creditNoteData?.formStatus == 0
                          ? 'status booked-bar'
                          : creditNoteData?.formStatus == 1
                          ? 'status active-bar'
                          : 'status no-st-bar'
                      } mx-auto status-w-110`}
                    >
                      {creditNoteData?.formStatus == 0
                        ? 'Open'
                        : creditNoteData?.formStatus == 1
                        ? 'Closed'
                        : '-'}
                    </div>

                    <i style={{paddingRight: '3px', paddingLeft: '18px'}}>
                      {' '}
                      {creditNoteData?.formStatusDateTime
                        ? `${moment
                            .utc(creditNoteData?.formStatusDateTime)
                            .local()
                            .format('DD.MM.YYYY - hh:mm A')}`
                        : ''}
                    </i>
                  </div>
                </span>
              </div>

              {/* Payor */}
              <div className='d-flex my-2 align-items-center'>
                <h4 className='text-dark'>
                  <label className='labl-gry status-w-150'> Payor</label>

                  <span className='ms-1'>
                    <b>{'Mukund Khunt'}</b>
                  </span>
                </h4>
              </div>

              {/* Account ID */}
              <div className='d-flex my-2 align-items-center'>
                <h4 className='text-dark'>
                  <label className='labl-gry status-w-150'> Account ID</label>

                  <span className='ms-1'>
                    <b>{'101'}</b>
                  </span>
                </h4>
              </div>

              {/* Tenant ID */}
              <div className='d-flex my-2 align-items-center'>
                <h4 className='text-dark'>
                  <label className='labl-gry status-w-150'> Tenant ID</label>

                  <span className='ms-1'>
                    <b>{'101'}</b>
                  </span>
                </h4>
              </div>
            </div>

            {/* CTAs */}
            <div className='d-flex align-items-center my-10'>
              {/* Share */}
              <button
                className='btn btn-sm fw-bold ps-3 pe-4 text-white status-w-110 blue-btn'
                onClick={() => {}}
              >
                <img
                  src={share}
                  height={18}
                  width={18}
                 className='me-4'
                />{' '}
                {'  '}
                Share
              </button>

              {/* Print */}
              <button
                className='btn btn-sm fw-bold ps-2 pe-4 ms-3 text-white status-w-110 h-31 d-flex align-items-center print-btn ps-3'
                onClick={() => {}}
              >
                <img src={print} height={18} width={18} className='me-4' />
                Print
              </button>

              {/* Delete */}
              <button
                type='button'
                className='btn btn-sm fw-bold  ms-auto red-hollow-btn ps-3 '
                disabled={isLoading || isViewOnly}
                onClick={() => {
                  Swal.fire({
                    html: ` 
                            <div class='fs-3'> <b>Delete Credit Note</></div>
                             <div class='fs-5 mt-4'></div>
                            <div class='fs-5 mt-4'> Are you sure you want to continue? </div>`,
                    // icon: 'warning',
                    showConfirmButton: true,
                    confirmButtonColor: '#35475e',
                    confirmButtonText: 'Yes',
                    showCancelButton: true,
                    cancelButtonText: 'No',
                    cancelButtonColor: '#fff',
                    customClass: {
                      confirmButton: 'custom-confirm-button',
                      cancelButton: 'custom-cancel-button',
                      popup: 'custom-popup',
                    },
                    reverseButtons: true, // Reverse the order of buttons reverseButtons: true,
                  }).then((res: any) => {
                    if (res.isConfirmed) {
                    }
                  })
                }}
              >
                <img src={trashImg} height={18} width={18} className='me-4' /> Delete
              </button>

              {/* Download */}
              <button
                type='button'
                className='btn btn-sm fw-bold blue-hollow-btn ms-3 btn-fit-content ps-2'
                style={{width: 'fit-content'}}
                onClick={() => {}}
              >
                <img src={downloadImg} height={18} width={18} className='me-3' />
                Download
              </button>

              {/* Edit */}
              <img src={editPen} height={18} width={18} className='ms-4' />
            </div>

            {/* Credit Note Details Card */}
            <NoteDetailsCard
              register={register}
              errors={errors}
              noteCardData={creditNoteData}
              setValue={setValue}
              trigger={trigger}
              control={control}
              updateNoteData={(data: any) => {
                console.log(data)
                setCreditNoteData(data)

                if (flg == '2') {
                  setTimeout(() => {
                    setAutoSaveData(Date.now())
                  }, 700)
                }
              }}
              slctdInvoices={selectedInvoices}
              editMode={false}
              from={'credit note'}
            />
          </div>
        </form>
      </div>

      <Backdrop sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}} open={open}>
        <CircularProgress style={{color: '#007a59'}} />
      </Backdrop>
    </>
  )
}

export default ViewCreditNote
