import React, {useState, useEffect} from 'react'
import moment, {Moment} from 'moment'
import addWhite from '../../../../img/add-white.png'
import {ApiPut, ApiGet} from '../../../../apiCommon/helpers/API/ApiData'
import correct from '../../../../img/correct.png'
import removed from '../../../../img/remove-filled.png'
import pencil from '../../../../img/edit-pen.png'
import trashImg from '../../../../img/trash.png'
import {ErrorToast, SuccessToast} from '../../../../apiCommon/helpers/Toast'
import scheduleWhite from '../../../../img/schedule-white.png'
import {ApiPost} from '../../../../apiCommon/helpers/API/ApiData'
import additionalImg from '../../../../img/add-require-green.png'
import incompleteImg from '../../../../img/job-incomplete-green.png'
import completeImg from '../../../../img/job-complete-green.png'
import {useParams} from 'react-router-dom'
import AddReport from './AddReport'
import ViewCorporateReport from './ViewCorporateReport'
import ViewVendorReport from './ViewVendorReport'

interface ButtonProps {
  modalData?: any
  coments?: any
  jobDetails?: any
}

const JobReportLog = ({modalData, coments, jobDetails}: ButtonProps) => {
  const {id} = useParams()

  const [isLoading, setIsLoading] = useState<any>(false)
  const [jobReports, setJobReports] = useState<any>([])

  const [showAddReport, setShowAddReport] = useState<any>(false)

  const userData: any = localStorage.getItem('userData')
  const user = JSON.parse(userData)

  const convertUTCToLocalTime = (utcTimeString: any) => {
    const localDate = moment.utc(utcTimeString).local()
    return localDate.format('DD.MM.YYYY HH:mm')
  }

  const [showCorporateReport, setShowCorporateReport] = useState<any>(false)
  const [showVendorReport, setShowVendorReport] = useState<any>(false)

  const [dataToViewEdit, setDataToViewEdit] = useState<any>()

  useEffect(() => {
    getJobReports()
  }, [])

  const getJobReports = async () => {
    const body = {
      maintenanceServiceJobId: id,
    }
    await ApiPost(`corporate/maintenance_service_job_report/get`, body)
      .then((res) => {
        setJobReports(res?.data?.data?.maintenance_service_job_report_data)
      })
      .catch((e) => {
        setIsLoading(false)
        ErrorToast(e?.message)
      })
  }

  return (
    <>
      {/*  Job Report Log */}
      <div className={'card card-flush py-5 mt-10  px-5 mb-5 mx-7 '}>
        <div className='d-flex  align-items-center'>
          <h2 className='' style={{fontWeight: '700'}}>
            {'Job Report Log'}
          </h2>

          <button
            type='button'
            className='btn btn-sm fw-bold px-2 green-submit-btn btn-fit-content ms-auto pe-5'
            onClick={() => {
              setShowAddReport(true)
            }}
            disabled={(jobDetails?.maintenanceJobStatus == 2 || jobDetails?.maintenanceJobStatus == 3) ? true : false}
          >
            <img src={scheduleWhite} height={20} width={20} className='me-3' />
            Generate Report
          </button>
        </div>
        <h3 className='top ps-0 d-block'>
          <i className=''>
            {' '}
            Submit job specific reports to your handling team and ensure all job milestones are
            documented until completion. Any report submitted by an external handling team will
            require your approval.
          </i>
        </h3>

        <div className='table-responsive mt-4'
           style={{maxHeight: '400px', overflow: 'auto'}}>
          <table
            className='table align-start table-row-dashed  fs-6 gy-5'
            id='kt_ecommerce_sales_table'
          >
            <thead>
              <th className='text-start min-w-100px'>Report Id</th>
              <th className='text-start min-w-100px '></th>
              <th className='text-start min-w-100px'>Report Type</th>
              <th className='text-center min-w-100px'>Status</th>
              <th className='text-start min-w-100px'>Submitted By</th>
              <th className='text-start min-w-100px'>Last Updated</th>
            </thead>

            <tbody className='fw-semibold head-text'> {/* className='fw-semibold text-gray-600'*/ }
              {jobReports?.map((rs: any, index: any) => {
                return (
                  <tr
                    onClick={() => {
                      console.log(rs)
                      setDataToViewEdit(rs)
                      if (rs?.submitByType == 0) setShowCorporateReport(true)
                      else setShowVendorReport(true)
                    }}
                    className="cursor-pointer"
                  >
                    <td className='text-start min-w-100px ps-0'>{rs?.id ? rs?.id : '-'}</td>
                    <td className='text-start min-w-100px'>
                      <div className='d-flex align-items-center '>
                        <span className=''>{`Job Report`}</span>
                        <img
                          src={
                            rs?.reportType == 1
                              ? incompleteImg
                              : rs?.reportType == 2
                              ? completeImg
                              : additionalImg
                          }
                          height={rs?.reportType == 1 ? 18 : rs?.reportType == 2 ? 20 : 21}
                          width={rs?.reportType == 1 ? 18 : rs?.reportType == 2 ? 20 : 21}
                          className='ms-auto'
                        />
                      </div>
                    </td>
                    <td className='text-start min-w-100px ps-0'>
                      {rs?.reportType == 1
                        ? 'Job Incomplete'
                        : rs?.reportType == 2
                        ? 'Job Complete'
                        : 'Additional Requirements'}
                    </td>
                    <td className='text-center min-w-100px'>
                      {rs?.reportType != 1 ?
                      <div
                        className={
                          rs?.reportStatus == 0
                            ? 'tenancy-stats  not-saved mx-auto'
                            : rs?.reportStatus == 1
                            ? 'tenancy-stats  requested-st mx-auto'
                            : rs?.reportStatus == 2
                            ? 'tenancy-stats  renewed-st mx-auto'
                            : rs?.reportStatus == 3
                            ? 'tenancy-stats cancelled-st mx-auto'
                            : 'tenancy-stats  not-saved mx-auto'
                        }
                      >
                        {rs?.reportStatus == 0
                          ? 'Not Approved'
                          : rs?.reportStatus == 1
                          ? 'Pending Tenant'
                          : rs?.reportStatus == 2
                          ? 'Approved'
                          : rs?.reportStatus == 3
                          ? 'Rejected'
                          : '-'}
                      </div>
                      :
                      <div>{'-'}</div>
                        }
                    </td>
                    <td className='text-start  cursor-pointer ps-0' data-order='2022-09-11'>
                      <p className='my-0'>
                        {(rs?.submitUser != undefined && rs?.submitByType == 0) ?
                          `${rs?.submitUser?.firstName} ${rs?.submitUser?.lastName} - ` : 
                          `${rs?.submitUser?.companyName} - `
                        }
                      </p>
                      <p className='my-0'>
                        {rs?.createdAt
                          ? `${moment.utc(rs?.createdAt).local().format('DD.MM.YYYY - hh:mm A')}`
                          : '-'}
                      </p>
                    </td>

                    <td className='py-3 ps-0 cursor-pointer ps-0'>
                      {rs?.updatedBy?.length > 0 ? (
                        <>
                          <p className='my-0'>
                            {rs?.updatedBy?.[0] &&
                              `${rs?.updatedBy[0]?.firstName} ${rs?.updatedBy[0]?.lastName} - `}
                          </p>
                          <p className='my-0'>
                            {rs?.updatedAt
                              ? `${moment(rs?.updatedAt).format('DD.MM.YYYY - hh:mm A')}`
                              : '-'}
                          </p>
                        </>
                      ) : (
                        <>{'-'}</>
                      )}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>

      {/* Add Report Modal */}
      {showAddReport && (
        <AddReport
          show={showAddReport}
          handleClose={() => {
            setShowAddReport(false)
          }}
          updateDetails={() => {
            getJobReports()
            setShowAddReport(false)
          }}
          tenantData={modalData}
        />
      )}

      {/* View Corporate Submitted Report*/}
      {showCorporateReport && (
        <ViewCorporateReport
          show={showCorporateReport}
          handleClose={() => {
            setShowCorporateReport(false)
          }}
          updateDetails={() => {
            getJobReports()
            setShowCorporateReport(false)
          }}
          modalData={dataToViewEdit}
        />
      )}

      {/* View Vendor Submitted Report*/}
      {showVendorReport && (
        <ViewVendorReport
          show={showVendorReport}
          handleClose={() => {
            setShowVendorReport(false)
          }}
          updateDetails={() => {
            getJobReports()
            setShowVendorReport(false)
          }}
          modalData={dataToViewEdit}
          tenantData={modalData}
        />
      )}

    </>
  )
}

export default JobReportLog
