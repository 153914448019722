import React, {useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'

// images
import submitImg from '../../../../../../img/Submit-White.png'
import backArrow from '../../../../../../img/back-arrow.png'
import redCross from '../../../../../../img/remove.png'
import trashImg from '../../../../../../img/trash.png'
import addGreen from '../../../../../../img/add.png'
import correct from '../../../../../../img/correct.png'
import removed from '../../../../../../img/remove-filled.png'
import downloadIcon from '../../../../../../img/download.png'
import greenPen from '../../../../../../img/pen.png'
import saveGreenImg from '../../../../../../img/save-dark-green.png'

// modules
import {useForm, Controller} from 'react-hook-form'
import {MultiSelect} from 'react-multi-select-component'
import Swal from 'sweetalert2'
import moment from 'moment'
import Input from '../../../../General/Input'
import {ErrorToast, SuccessToast} from '../../../../../../apiCommon/helpers/Toast'
import {Backdrop, CircularProgress} from '@mui/material'
import {
  ApiGet,
  ApiGetNoAuth,
  ApiPost,
  ApiPut,
  ApiUpload,
  Bucket,
  ApiDelete,
} from '../../../../../../apiCommon/helpers/API/ApiData'
import SelectPayorModal from '../../SelectPayorModal'
import {formatAmount, getFileIcon} from '../../../../../../Utilities/utils'
import {downloadFile} from '../../../../../../Utilities/utils'
import ModalLoader from '../../../../General/ModalLoader'
import GenerateInvoice from './GenerateInvoice'
import AttachmentModel from './AttachmentModel'

const AddInvoice = () => {
  const navigate = useNavigate()
  const {flg, type, id, tenancyId, unitId} : any = useParams()
  const {
    register,
    control,
    handleSubmit,
    reset,
    setValue,
    formState: {errors},
    trigger,
  } = useForm<any>({mode: 'onSubmit', reValidateMode: 'onChange'})

  const [isSkeleton, setIsSkeleton] = useState<any>(false)
  const [isLoading, setIsLoading] = useState<any>(false)
  const [isViewOnly, setIsViewOnly] = useState<any>(false)
  const [sendError, setSendError] = useState<any>(false)
  const [autoSaveData, setAutoSaveData] = useState<any>(false)
  const [showSelectPayor, setShowSelectPayor] = useState<any>(false)
  const [refreshChild, setRefreshChild] = useState<any>(true)

  const [selectedAccount, setSelectedAccount] = useState<any>([])
  const [accountOptions, setAccountOptions] = useState<any>([])
  const [selectAccountError, setSelectAccountErr] = useState<any>(false)

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<any>([])
  const [paymentMethodOptions, setPaymentMethodOptions] = useState<any>([
    {
      label: 'Bank Transfer',
      value: '2',
    },
    {
      label: 'Card',
      value: '0',
    },
    {
      label: 'Cash',
      value: '3',
    },
    {
      label: 'Cheque',
      value: '1',
    },
  ])

  const [incomeAPIDetails, setIncomeAPIDetails] = useState<any>()
  const [idToDelete, setIDToDelete] = useState<any>([])

  const [incomeData, setIncomeData] = useState<any>({
    selectedAccount:[],
    payorData: {},
    paymentMethod: null,
    paymentAccountId: '',
    notes: '',
    development: '',
    invoices: [],
    items: [
      {
        id: '',
        name: '',
        paymentItemListId: '',
        amount: '',
        quantity: '',
        VATAmount: '',
        VATPercentage: '',
        totalAmount: '',
        outstandingAmount: 0,
        amountPaid: 0,
        totalAmountPaid: 0,
        frontendStorage: {
          isList: true,
          latePaymentObject: {},
          isLatePaymentItem: false,
          itemUniqueId: new Date().getTime(),
          paymentItemId: null,
          isLatePaymentItemAdded: false,
        },
        isLatePaymentItem: false,
        latePaymentFees: 0,
        latePaymentSelectionStatus: 0, // "notSelected": 0, "apply": 1, "notApply": 2
        latePaymentObject: {},
        oldLatePaymentCycleList: [],
        nextLatePaymentCycle: null,
        // paymentSubItemStatus:2
      },
    ],
    attachments: [],
    reminders: [],
    paymentRecords: [],
    category: 0,
    categoryDateTime: null,
    latePaymentType: null,
    // invoiceData: [
    //   {
    //     name: 'INVOICE',
    //     invoiceNo: '',
    //     date: '',
    //     dueDate: null,
    //     invoiceLogo: '',
    //     invoiceCompany: {
    //       trn: '',
    //       name: '',
    //       address: '',
    //       countryCode: '',
    //       phoneNumber: '',
    //       email: '',
    //       _id: '',
    //     },
    //     note: '',
    //     paymentSubItems: [],
    //     paymentMethod: '',
    //     totalAmount: 0,
    //     saveStatus: 0,
    //     totalVAT: 0,
    //     subTotalAmount: 0,
    //     paymentAccountId: null,
    //     paymentId: null,
    //     status: 0,
    //     outstandingAmount: 0,
    //     itemDescriptions: [],
    //     frontendStorage: {
    //       showAccountDetails: true,
    //       showNotes: true,
    //     },
    //   },
    // ],
    frontendStorage: {lateFeeForAll: {}},
    invoiceName: 'INVOICE',
        invoiceNo: '',
        date: '',
        dueDate: null,
        invoiceLogo: '',
        invoiceCompany: {
          trn: '',
          name: '',
          address: '',
          countryCode: '',
          phoneNumber: '',
          email: '',
          _id: '',
        },
        note: '',
        paymentSubItems: [],
        totalAmount: 0,
        saveStatus: 0,
        totalVAT: 0,
        subTotalAmount: 0,
        paymentId: null,
        status: 0,
        outstandingAmount: 0,
        itemDescriptions: [],
        showAccountDetails: true,
        showNotes: true,
        // frontendStorage: {
        // },
  })

  const [invoiceData, setInvoiceData] = useState<any>({
    _id: '',
    name: 'INVOICE',
    invoiceNo: '',
    date: '',
    paymentSubItems: [],
    paymentMethod: 0,
    totalAmount: 0,
    saveStatus: 0,
    totalVAT: 0,
    subTotalAmount: 0,
    paymentAccountId: null,
    paymentId: null,
    status: 0,
    outstandingAmount: 0,
    itemDescriptions: [
      //   {
      //     title: 'Payment Terms',
      //     text: 'Payment Terms Defaullttt',
      //     html: '',
      //   },
    ],
    note: '',
    frontendStorage: {
      // showAccountDetails: showAccountDetails,
      // showNotes: showNotes,
    },
  })

  const [editFile, setEditFile] = useState<any>(-1)
  const [newName, setNewName] = useState<any>('')

  const [chequeImages, setChequeImages] = useState<any>([])
  const [chargeTenant, setChargeTenant] = useState<any>(null)

  const [open, setOpen] = React.useState(false)

  setTimeout(() => {
    setIsLoading(false)
  }, 2000)

  setTimeout(() => {
    setOpen(false)
  }, 4000)

  //  file uploading
  const [showLoader, setShowLoader] = useState<boolean>(false)
  const [uploadProgress, setUploadProgress] = useState<number>(0)
  const [totalFiles, setTotalFiles] = useState<number>(0)

  const [uploadingText, setUploadingText] = useState<any>('Uploading')
  const [percentProgress, setPercentProgress] = useState(0)

  // functions
  const handleUploadCheque = async (e: any) => {
    e.preventDefault()

    const values = [...chequeImages]

    if (e.target.files) {
      setShowLoader(true)

      setTotalFiles(e.target.files.length)

      for (let i = 0; i < e.target.files.length; i++) {
        let fileURL = e.target.files[i]

        let userData: any = localStorage.getItem('userData')
        const data = JSON.parse(userData)

        var formdata = new FormData()
        formdata.append('image', fileURL)

        let name: any =
          e.target.files[i]?.name?.charAt(0).toUpperCase() +
          e.target.files[i]?.name?.slice(1)
        const nameParts = name.split('.')
        const extension = nameParts.pop()
        let baseName = nameParts.join('.')

        // Check for duplicate names and append a number if necessary
        let uniqueName = baseName
        let counter = 1
        while (
          chequeImages.some((chq: any) => chq?.name === `${uniqueName}.${extension}`)
        ) {
          uniqueName = `${baseName}(${counter})`
          counter++
        }
        name = `${uniqueName}.${extension}`

        await ApiPost(
          `upload/image/params?fileType=cheque&databaseId=${data._id}&fileName=${name}`,
          formdata
        )
          .then((res) => {
            const name = res?.data?.data?.image.split('/')

            const fileName = name[name.length - 1]

            values?.push({
              URL: res?.data?.data?.image,
              name: fileName?.charAt(0).toUpperCase() + fileName?.slice(1),
            })

            // update the upload progress
            setUploadProgress(i + 1)

            // console.log(values);
          })
          .catch((err: any) => {
            ErrorToast(err?.message)
            setShowLoader(false)
            setUploadProgress(0) // Reset upload progress
            setTotalFiles(0)
          })
      }

      setChequeImages(values)
      setShowLoader(false)

      setUploadProgress(0) // Reset upload progress
      setTotalFiles(0)

      if (flg == '2') {
        setTimeout(() => {
          setAutoSaveData(Date.now())
        }, 700)
      }

      e.target.value = null
    }
  }

  const getAccounts = (val: any) => {
    const body = {
      limit: 50,
      page: 1,
    }
    ApiPost(`corporate/payment_account/get`, body)
      .then((res) => {
        let values: any = []

        res?.data?.data?.payment_account_data?.map((acc: any, ind: any) => {
          values[ind] = {
            label: `${acc?.accountNumber} - ${acc?.bankName}`,
            value: acc?._id,
            IBAN: acc?.IBAN,
            accountHolderName: acc?.accountHolderName,
            accountNumber: acc?.accountNumber,
            bankName: acc?.bankName,
            swiftCode: acc?.swiftCode,
          }

          if (val == acc?._id) setSelectedAccount([values[ind]])
        })

        setAccountOptions(values)
      })
      .catch((err) => console.log('err', err))
  }

  
  let grandTotalAmount: any = 0
  let grandPaidAmount: any = 0
  let grandOutstandingAmount: any = 0

  const [isSaveFirst, setIsSaveFirst] = useState<any>(false)

  const addIncomePayment = async (saveStatus: any, from: any) => {

    let isValid: any = true
    if (saveStatus == 1) {
      isValid = await trigger()
    }
    let payorSelectionErr: any = false
      if (saveStatus == 1 && incomeData?.payorData?._id == undefined) payorSelectionErr = true

    if (isValid) {
      let errors: any = false

      if (saveStatus == 1) {
        incomeData?.items?.map((itm: any, ind: any) => {
          let totalAmountOfItem: any = 0
          let vatAmnt: any =
            ((Number(itm?.amount) * Number(itm?.quantity)) / 100) * Number(itm?.VATPercentage)
          totalAmountOfItem = Number(itm?.amount) * Number(itm?.quantity) + vatAmnt
          console.log(itm)
          if (itm?.frontendStorage?.isLatePaymentItem == false && 
            (itm?.name?.trim()?.length === 0 || itm?.name == '' ||
            itm?.amount === '' ||
            itm?.amount === undefined ||
            Number(itm?.amount) == 0 ||
            itm?.quantity === '' ||
            itm?.quantity === undefined ||
            Number(itm?.quantity) == 0 ||
            itm?.VATPercentage === '' ||
            itm?.VATPercentage === undefined)
          ) {
            errors = true
          }
        })
      }

      

      let amountLessErr: any = false
      let addSectionErr : boolean = false


      if(saveStatus == 1 && incomeData?.itemDescriptions?.length > 0  ? incomeData?.itemDescriptions?.some((itm:any) => {
        const tempElement = document.createElement('div');
        tempElement.innerHTML = itm?.html;
        return ((!tempElement?.textContent?.trim() || !tempElement?.innerText?.trim()) || !itm?.title?.trim());
      }) : false){
        addSectionErr = true
      }else{
        addSectionErr = false
      }

      
      if (!errors && !payorSelectionErr && !amountLessErr && !addSectionErr) {
        setIsLoading(true)
        setOpen(true)

        // Payment Items
        let newItems: any = []
        let newItemInd: any = 0
        let updateItems: any = []
        let updateItemInd: any = 0

        let invoicePaymentItems: any = []

        var nonVat: any = 0
        var vatAmount: any = 0
        

        incomeData?.items?.map((itm: any, ind: any) => {
          let totalAmountOfItem: any = 0
          let vatAmnt: any =
            ((Number(itm?.amount) * Number(itm?.quantity)) / 100) * Number(itm?.VATPercentage)
          totalAmountOfItem = Number(itm?.amount) * Number(itm?.quantity) + vatAmnt

          let totalAmountPaidOfItem: any = 0

          if (itm?.id == '') {
            newItems[newItemInd] = {
              paymentItemListId: itm?.paymentItemListId ? itm?.paymentItemListId : null,
              name: itm?.name ? itm?.name : '',
              amount: itm?.amount == 0 ? '' : Number(itm?.amount),
              quantity: itm?.quantity == 0 ? '' : Number(itm?.quantity),
              totalAmount: isNaN(itm?.totalAmount) ? '': Number(totalAmountOfItem),
              amountPaid: isNaN(totalAmountPaidOfItem) ? undefined : Number(totalAmountPaidOfItem),
              outstandingAmount: isNaN(itm?.outstandingAmount)
                ? undefined
                : Number(itm?.outstandingAmount),
              VATAmount: isNaN(itm?.VATAmount) ? '' : Number(vatAmnt) ,
              VATPercentage: itm?.VATPercentage == 0 ? '' : Number(itm?.VATPercentage),
              latePaymentFees: itm?.latePaymentFees,
              latePaymentSelectionStatus: Number(itm?.latePaymentSelectionStatus),
              latePaymentObject: {},
              oldLatePaymentCycleList: [],
              nextLatePaymentCycle: null,
              paymentSubItemStatus: itm?.paymentSubItemStatus,
              frontendStorage: itm.frontendStorage,
            }

            invoicePaymentItems?.push(newItems[newItemInd])
            newItemInd++
          } else {
            updateItems[updateItemInd] = {
              _id: itm?._id,
              name: itm?.name ? itm?.name : '',
              paymentItemListId: itm?.paymentItemListId ? itm?.paymentItemListId : null,
              amount: itm?.amount == 0 ? '' : Number(itm?.amount),
              quantity: itm?.quantity == 0 ? '' : Number(itm?.quantity),
              totalAmount: isNaN(itm?.totalAmount) ? '' : Number(totalAmountOfItem),
              amountPaid: isNaN(totalAmountPaidOfItem) ? undefined : Number(totalAmountPaidOfItem),
              outstandingAmount: isNaN(itm?.outstandingAmount)
                ? undefined
                : Number(itm?.outstandingAmount),
              VATAmount: isNaN(itm?.VATAmount) ? '' : Number(vatAmnt) ,
              VATPercentage: itm?.VATPercentage == 0 ? '' : Number(itm?.VATPercentage),
              latePaymentFees: itm?.latePaymentFees,
              latePaymentSelectionStatus: Number(itm?.latePaymentSelectionStatus),
              latePaymentObject: {},
              oldLatePaymentCycleList: [],
              nextLatePaymentCycle: null,
              paymentSubItemStatus: itm?.paymentSubItemStatus,
              frontendStorage: itm.frontendStorage,
            }

            invoicePaymentItems?.push(updateItems[updateItemInd])
            updateItemInd++
          }
        })

        const body = {
            _id: flg == '1' ? undefined : id,
            invoiceNo: incomeData?.invoiceNo ? incomeData?.invoiceNo : '',
            name: incomeData?.invoiceName ? incomeData?.invoiceName : '',
            paymentName: incomeData?.name ? incomeData?.name : '',
            note: incomeData?.note ? incomeData?.note : '',
            dueDate:incomeData?.dueDate ? incomeData?.dueDate : '',
            invoiceLogo:incomeData?.invoiceLogo ? incomeData?.invoiceLogo : '',
            invoiceCompany: incomeData?.invoiceCompany ? incomeData?.invoiceCompany : {},
            paymentMethod:
            selectedPaymentMethod?.length > 0
              ? Number(selectedPaymentMethod?.[0]?.value)
              : '',
              formStatus: saveStatus,
              totalAmount: incomeData?.totalAmount,
              totalVAT:incomeData?.totalVAT,
              subTotalAmount: incomeData?.subTotalAmount,
              paymentAccountId: selectedAccount?.length > 0 ? selectedAccount?.[0]?.value : null,
              paymentId:null,
              status:0, 
              outstandingAmount: 0,
              itemDescriptions: incomeData?.itemDescriptions?.length > 0 ? incomeData?.itemDescriptions :[],
              formStatusDateTime: new Date(),
              isCardVerificationEnable: false,
              isLock: false,
              chequeBankName: null,
              chequeImages: Number(selectedPaymentMethod?.[0]?.value) == 1 ? chequeImages : [],
            
            latePaymentType:
              incomeData?.latePaymentType != undefined ? incomeData?.latePaymentType : '',
              paymentSubItems: [...newItems,...updateItems],
              frontendStorage: {
                showAccountDetails:incomeData?.showAccountDetails,
                showNotes: incomeData?.showNotes,
                lateFeeForAll: incomeData?.frontendStorage?.lateFeeForAll
              },
            
            tenantId:
              incomeData?.payorData?.tenant?.length > 0
                ? incomeData?.payorData?.tenant?.[0]?._id
                : null,
            tenancyId: incomeData?.payorData?._id ? incomeData?.payorData?._id : null,
            unitId: incomeData?.payorData?.unit?.[0]?._id
              ? incomeData?.payorData?.unit?.[0]?._id
              : null,
            clusterId: incomeData?.payorData?.cluster?.[0]?._id
              ? incomeData?.payorData?.cluster?.[0]?._id
              : null,
            unitGroupId: incomeData?.payorData?.unit_group?.[0]?._id
              ? incomeData?.payorData?.unit_group?.[0]?._id
              : null,
            floorId: incomeData?.payorData?.floor?.[0]?._id
              ? incomeData?.payorData?.floor?.[0]?._id
              : null,
            communityId: incomeData?.payorData?.community?.[0]?._id
              ? incomeData?.payorData?.community?.[0]?._id
              : null,
            buildingId: incomeData?.payorData?.building?.[0]?._id
              ? incomeData?.payorData?.building?.[0]?._id
              : null,
            tenantCardId: null,
            cardChargePayBy: chargeTenant,
            createdOriginType:1
        }
        ApiPost(`corporate/payment_invoice`, body)
          .then((res) => {
            if (saveStatus == 0) {
              if (flg == '1') {
                navigate(`/finance-income/add-invoice/2/${res?.data?.data?._id}`)
                setTimeout(() => {
                  setIsSaveFirst(true)
                }, 1000)
              }

              setIsSaveFirst(true)
            }else{
              SuccessToast('Invoice has been added successfully..')
              navigate('/finance-income')
                
            }

          })
          .catch((err: any) => {
            ErrorToast(err?.message)
            setTimeout(() => {
              setIsLoading(false)
              setOpen(false)
            }, 1500)
          })
      } else {
        if (payorSelectionErr) ErrorToast('Please select a PAYOR before raising this invoice.')
        else if (errors)
          ErrorToast('User cannot create Invoice with no payment item details.')
        else if(addSectionErr){
          ErrorToast('User cannot create Invoice with empty title or content in additional sections.')
        }
        // else if (noInvoiceError) ErrorToast('Please generate an invoice')
      }
    }
  }

  const getInvoiceDetails = async () => {
    try {
      const res = await ApiGet('corporate/corporate_setting');
      return res?.data?.data;  // Return the relevant data
    } catch (err) {
      console.log('Error in getInvoiceDetails:', err);
      throw err;  // Re-throw the error so it can be caught where it's called
    }
  };

  const getPaymentById = async () => {
    try {
      reset()
      const res = await ApiGet(`corporate/payment_invoice/${id}`);
     
      setChargeTenant(res?.data?.data?.cardChargePayBy);
  
      setValue('paymentName', res?.data?.data?.name);
      setValue('invoiceNo', res?.data?.data?.invoiceNo);
      setValue('dueDate', res?.data?.data?.dueDate);
      setValue(
        'accountNumber',
        res?.data?.data?.paymentAccountId ? res?.data?.data?.paymentAccountId : ''
      );
  
      setValue(
        'paymentMethod',
        res?.data?.data?.paymentMethod != null ? `${res?.data?.data?.paymentMethod}1` : ''
      );
  
      if (res?.data?.data?.paymentAccountId) {
        await getAccounts(res?.data?.data?.paymentAccountId);
      } else {
        await getAccounts('');
      }
    
      let values: any = {
        invoiceName:  res?.data?.data?.name || 'INVOICE',
        payorData: {},
        dueDate: res?.data?.data?.dueDate,
        categories: res?.data?.data?.categories,
        paymentMethod: (res?.data?.data?.paymentMethod == null || res?.data?.data?.paymentMethod == '') ? '' : res?.data?.data?.paymentMethod,
        paymentAccountId: res?.data?.data?.paymentAccountId,
        note: res?.data?.data?.note,
        items: res?.data?.data?.paymentSubItems,
        attachments: res?.data?.data?.attachments,
        reminders: res?.data?.data?.payment_reminder,
        latePaymentType: res?.data?.data?.latePaymentType,
        frontendStorage: res?.data?.data?.frontendStorage ? res?.data?.data?.frontendStorage : {lateFeeForAll: {}},
        payment_invoice: res?.data?.data?.payment_invoice,
        payment_receipt: res?.data?.data?.payment_receipt,
        invoiceCompany: {
          trn: res?.data?.data?.invoiceCompany?.trn,
          name: res?.data?.data?.invoiceCompany?.name,
          address: res?.data?.data?.invoiceCompany?.address,
          countryCode: res?.data?.data?.invoiceCompany?.countryCode,
          phoneNumber: res?.data?.data?.invoiceCompany?.phoneNumber,
          email: res?.data?.data?.invoiceCompany?.email,
          _id: res?.data?.data?.invoiceCompany?._id,
        },
        _id: res?.data?.data?._id,
        date: res?.data?.data?.date ? res?.data?.data?.date : '',
        name: res?.data?.data?.paymentName,
        invoiceNo: res?.data?.data?.invoiceNo || '',
        invoiceLogo: res?.data?.data?.invoiceCompany?.invoiceLogo,
        totalAmount: res?.data?.data?.totalAmount,
        totalVAT: res?.data?.data?.totalVAt,
        subTotalAmount: res?.data?.data?.subTotalAmount,
        saveStatus: res?.data?.data?.saveStatus,
        status: 0,
        outstandingAmount: res?.data?.data?.outstandingAmount,
        itemDescriptions: res?.data?.data?.itemDescriptions,
        selectedAccount:res?.data?.data?.payment_account,
        showAccountDetails:res?.data?.data?.frontendStorage?.showAccountDetails,
        showNotes: res?.data?.data?.frontendStorage?.showNotes,
        formStatus:res?.data?.data?.formStatus
        
      };

      setChequeImages(Number(res?.data?.data?.paymentMethod) == 1 ? res?.data?.data?.chequeImages : [])
  
      values?.items?.forEach((vl: any, ind: any) => {
        values.items[ind].outstandingAmount = vl.totalAmount - vl?.amountPaid;
        if(!(vl?.name?.trim()?.length === 0 || vl?.name == '' ||
          vl?.amount === '' ||
          vl?.amount === undefined ||
          Number(vl?.amount) == 0 ||
          vl?.quantity === '' ||
          vl?.quantity === undefined ||
          Number(vl?.quantity) == 0)
        ){
          values.items[ind].VATPercentage = values.items[ind].VATPercentage == "" ? 0 : values.items[ind].VATPercentage
        }
      });
  
      let tenancyIdToCompare: any = res?.data?.data?.tenancyId;
  
      switch (res?.data?.data?.paymentMethod) {
        case 1:
          setSelectedPaymentMethod([{ value: '1', label: 'Cheque' }]);
          break;
        case 2:
          setSelectedPaymentMethod([{ value: '2', label: 'Bank Transfer' }]);
          break;
        case 3:
          setSelectedPaymentMethod([{ value: '3', label: 'Cash' }]);
          break;
        case 0:
          setSelectedPaymentMethod([{ value: '0', label: 'Card' }]);
          break;
        default:
          setSelectedPaymentMethod([]);
      }
  
      if (res?.data?.data?.tenancyId != null) {
        const body = {
          page: 1,
          limit: 10000,
          searchTenant: '',
          searchDevelopment: '',
          searchContractNo: '',
          searchUnitNo: '',
        };
  
        try {
          const tenancyRes = await ApiPost('corporate/tenancy/overview', body);
          tenancyRes?.data?.data?.tenancy_data?.forEach((rs: any) => {
            if (rs?._id === tenancyIdToCompare) {
              values.payorData = rs;
            }
          });
        } catch (err) {
          console.error('Error fetching tenancy data:', err);
        }
      }

  
      setIncomeAPIDetails(res?.data?.data);
  
      if (flg === '2') {
        const invoiceDetails = await getInvoiceDetails();
        let valuesInv = { ...values };
  
        valuesInv.invoiceCompany = invoiceDetails?.invoiceCompany;
        valuesInv.invoiceLogo = invoiceDetails?.invoiceLogo;
        values = valuesInv;
      }
      
      setIncomeData(values);
      setIsSaveFirst(false);
      setRefreshChild(Date.now());
  
      setTimeout(() => {
        setIsSkeleton(false);
      }, 100);
  
    } catch (err:any) {
      console.error('Error:', err);
      ErrorToast(err?.message);
      setIsSkeleton(false);
    }
  };


  // const [isDisabled, setIsDisable] = useState<boolean>(false)

  // useEffect(() => {
  //   if (
  //     !incomeData?.name ||
  //     !incomeData?.dueDate ||
  //     Object.keys(incomeData?.payorData)?.length == 0 ||
  //     !incomeData?.paymentMethod || incomeData?.selectedAccount?.length == 0
  //   ) {
  //     setIsDisable(true)
  //   } else {
  //     setIsDisable(false)
  //   }
  // }, [incomeData])


  const deleteInvoice = async () => {
    setIsLoading(true)
    let url = `corporate/payment_invoice/${id}`

    await ApiDelete(`${url}`)
      .then((res: any) => {
        SuccessToast('Invoice has been deleted successfully')
        navigate('/finance-income')
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
        setIsLoading(false)
      })
  }
  useEffect(() => {
    if (flg == '2') {
      setIsSkeleton(true)
      getPaymentById()
    }
  }, [])

  useEffect(() => {
    if (isSaveFirst) getPaymentById()
  }, [isSaveFirst])

  //
  const handleImageClickCheques = () => {
    const groupName = `group_attachments_others`

    chequeImages?.map((receipt: any, idx: any) => {
      const imgeNext = document.getElementById(`clickedAttachments${idx}`)
      if (imgeNext) {
        imgeNext.setAttribute('data-src', `${Bucket}${receipt?.URL}`)
        imgeNext.setAttribute('data-fancybox', groupName)

        // imgeNext.setAttribute('src', `${Bucket}${receipt?.URL}`)
      }

      // Trigger the fancybox on the clicked image
      // if (imgeNext) {
      //   imgeNext.click()
      // }
    })
  }

  //  use effects

  
  useEffect(() => {
    if (flg == '1') getAccounts('')
  }, [])

  useEffect(() => {
      if(!incomeData?.paymentMethod){
        setChequeImages([])
      }
  }, [incomeData.paymentMethod])

  useEffect(() => {
    if (autoSaveData != false && flg == '2') {
      addIncomePayment(0, '')
    }
  }, [autoSaveData])

  useEffect(() => {
    setTimeout(() => {
      console.log(incomeData)
    },1000)
  },[incomeData])


  return (
    <>
      {!isSkeleton ? (
        <div className='app-main flex-column flex-row-fluid px-2' id='kt_app_main'>
          <form>
            <div id='' className='pt-0 mt-0'>
              {/* Header */}
              <div className='page-title d-flex flex-column justify-content-center flex-wrap'>
                {' '}
                <div className='d-flex align-items-center mb-1'>
                  <div className='d-flex align-items-center'>
                    <span
                      className='rounded-circle cursor-pointer'
                      onClick={() => {
                        if(flg == '2') 
                          {addIncomePayment(0, '').then(() => {
                             setTimeout(() => {
                           
                              navigate('/finance-income')
                             },800)
                           })
                          }else{
                            navigate('/finance-income')
                          }

                      }}
                    >
                      <img src={backArrow} height='14' width='14' />
                    </span>
                    <h2 className='m-0 head-text ms-2 text-capitalize'>{`Raise Invoice`}</h2>
                  </div>

                  <div className='d-flex ms-auto'>
                    {flg != '1' ? (
                      <button
                        type='button'
                        className='btn btn-sm fw-bold  ms-3 red-hollow-btn ps-3'
                        disabled={isLoading || isViewOnly}
                        onClick={() => {
                          Swal.fire({
                            html: ` 
                            <div class='fs-3'> <b>Delete Invoice</></div>
                             <div class='fs-5 mt-4'>You are about to delete the invoice.</div>
                            <div class='fs-5 mt-4'> Are you sure you want to continue? </div>`,
                            // icon: 'warning',
                            showConfirmButton: true,
                            confirmButtonColor: '#35475e',
                            confirmButtonText: 'Yes',
                            showCancelButton: true,
                            cancelButtonText: 'No',
                            cancelButtonColor: '#fff',
                            customClass: {
                              confirmButton: 'custom-confirm-button',
                              cancelButton: 'custom-cancel-button',
                              popup: 'custom-popup',
                            },
                            reverseButtons: true, // Reverse the order of buttons reverseButtons: true,
                          }).then((res: any) => {
                            if (res.isConfirmed) {
                              deleteInvoice()
                            }
                          })
                        }}
                      >
                        <img src={trashImg} height={18} width={18} className='me-4' />{' '}
                        Delete
                      </button>
                    ) : (
                      <button
                        type='button'
                        className='btn btn-sm fw-bold ms-3 green-hollow-btn ps-3'
                        onClick={() => {
                          addIncomePayment(0, '')
                        }}
                        disabled={isLoading || sendError}
                      >
                        <img src={saveGreenImg} height={18} width={18} className='me-4' />{' '}
                        {'  '}
                        Save
                      </button>
                    )}

                    <button
                      type='button'
                      className='btn btn-sm fw-bold ms-3 green-submit-btn ps-3'
                      onClick={() => {
                        addIncomePayment(1, '')
                      }}
                      disabled={isLoading || isViewOnly}
                    >
                      <img src={submitImg} height={19} width={19} className='me-4' />{' '}
                      {'  '}
                      Create
                    </button>
                  </div>
                </div>
                <h4 className='my-2 ms-7' style={{color: 'black'}}>
                  <label className='labl-gry '>
                    {' '}
                    Once the invoice is raised, a new payment will be created and added to
                    your payments table and the invoice be will saved under the new
                    payment.
                  </label>
                </h4>
              </div>

              <div className='row '>
                <div className='col-6 px-5 text end'>
                  <div
                    className={'card card-flush py-5 container-xxl mt-2  pe-5 mb-5 px-6 '}
                    style={{minHeight: '345px'}}
                  >
                    <div className='row'>
                      {/* Expense Details */}
                      <div className='col-12 px-0 mt-3'>
                        <div className='row me-4 ms-0 mt-0 '>
                          {/* invoice name */}
                          <div className='col-8'>
                            <Input
                              register={register}
                              errors={errors}
                              incomeData={incomeData}
                              setIncomeData={setIncomeData}
                              setValue={setValue}
                              trigger={trigger} // Pass trigger function
                              setAutoSaveData={setAutoSaveData}
                              flg={flg}
                            />
                          </div>
                          {/* form status */}
                          {/* {flg != '1' && ( */}
                          <div className='col-4 mt-0'>
                            {incomeAPIDetails?.formStatusDateTime ? (
                              <div className=' me-2'>
                                <div
                                  className={
                                    incomeAPIDetails?.formStatus == 0
                                      ? 'status draft-st ms-5 status-w-120'
                                      : 'status create-st ms-5 status-w-120'
                                  }
                                >
                                  {incomeAPIDetails?.formStatus == 0
                                    ? 'Draft'
                                    : 'Created'}
                                </div>
                                <i style={{paddingRight: '3px', paddingLeft: '18px'}}>
                                  {' '}
                                  {incomeAPIDetails?.formStatusDateTime
                                    ? `${moment
                                        .utc(incomeAPIDetails?.formStatusDateTime)
                                        .local()
                                        .format('DD.MM.YYYY - hh:mm A')}`
                                    : ''}
                                </i>
                              </div>
                            ) : (
                              <div className='status not-saved ms-5 status-w-120'>-</div>
                            )}
                          </div>
                          {/* )} */}
                        </div>

                        <div className='row me-4 ms-0 mt-0'>
                          {/* Select Payee */}
                          <div className='col-12 px-5 mt-4'>
                            <div className='d-flex my-4 '>
                              <h4 className='my-2' style={{color: 'black'}}>
                                <label style={{width: '130px'}} className='labl-gry '>
                                  {' '}
                                  Payor
                                </label>
                              </h4>
                              {incomeData?.payorData?._id == undefined ? (
                                <div className='expense-date d-flex justify-content-center'>
                                  <button
                                    type='button'
                                    className='btn btn-sm fw-bold  green-submit-btn ps-3 d-flex justify-content-center status-w-200'
                                    onClick={(e: any) => {
                                      setShowSelectPayor(true)
                                    }}
                                    disabled={isLoading || isViewOnly}
                                  >
                                    Select Payor
                                  </button>
                                  <h3 className='text-danger mb-0 ms-2'>
                                    <b>*</b>
                                  </h3>
                                </div>
                              ) : (
                                <div className='d-flex expense-date'>
                                  <span className='ms-1'>
                                    <b>{`${incomeData?.payorData?.tenant?.[0]?.firstName} ${incomeData?.payorData?.tenant?.[0]?.lastName}`}</b>
                                  </span>
                                  <img
                                    src={redCross}
                                    height={16}
                                    width={16}
                                    className='ms-auto cursor-pointer'
                                    onClick={() => {
                                      let values = {...incomeData}
                                      values.payorData = {}

                                      setIncomeData(values)

                                      if (flg == '2') {
                                        setTimeout(() => {
                                          setAutoSaveData(Date.now())
                                        }, 700)
                                      }
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                          {/* Due date */}
                          {/* <div className='col-11 col-md-9 px-5 mt-2'>
                            <div className='d-flex my-4'>
                              <h4 className='my-2' style={{color: 'black'}}>
                                <label style={{width: '130px'}} className='labl-gry '>
                                  {' '}
                                  Due Date
                                </label>
                              </h4>
                              <div >
                                <div>
                                  <Controller
                                    name='dueDate'
                                    control={control}
                                    rules={{
                                      required: 'this is required',
                                    }}
                                    render={({field}) => (
                                      <div className='expense-date'>
                                        {incomeData?.dueDate != null ? (
                                          <DatePicker
                                            name='dueDate'
                                            value={moment(
                                              new Date(incomeData?.dueDate),
                                              'DD.MM.YYYY'
                                            )}
                                            format={'DD.MM.YYYY'}
                                            // disabledDate={disabledDate}
                                            onChange={handleDateChange}
                                            placeholder='Select Due Date'
                                          />
                                        ) : (
                                          <DatePicker
                                            name='dueDate'
                                            // disabledDate={disabledDate}
                                            onChange={handleDateChange}
                                            placeholder='Select Due Date'
                                          />
                                        )}
                                      </div>
                                    )}
                                  />
                                </div>
                                {errors.dueDate && (
                                  <p
                                    style={{color: 'var(--kt-danger)', gridColumn: '2/2'}}
                                    className='m-0 mt-1'
                                  >
                                    This field is required
                                  </p>
                                )}
                              </div>
                              <h3 className='text-danger mb-0 ms-2'>
                                <b>*</b>
                              </h3>
                            </div>
                          </div> */}
                          <div className='col-11 col-md-9 px-5 mt-2'>
                            <div className='d-flex my-4'>
                              <h4 className='my-2' style={{color: 'black'}}>
                                <label style={{width: '130px'}} className='labl-gry '>
                                  {' '}
                                  Receiving Account
                                </label>
                              </h4>

                              <div className='test expense property-test'>
                                <div
                                  className={`multi-select-container ${
                                    selectedAccount?.length === 0
                                      ? 'no-value expense-account'
                                      : 'has-value'
                                  }`}
                                >
                                  <Controller
                                    name='accountNumber'
                                    control={control}
                                    rules={{
                                      required: 'This field is required',
                                    }}
                                    render={({field}) => (
                                      <MultiSelect
                                        {...field}
                                        options={accountOptions}
                                        value={selectedAccount}
                                        onChange={(e: any) => {
                                          let newVal: any = e
                                          if (newVal?.length == 0) {
                                            setSelectedPaymentMethod([])
                                            setSelectedAccount(newVal)

                                            setValue('accountNumber', '', {
                                              shouldValidate: true,
                                            }) 

                                            if (flg == '2') {
                                              setTimeout(() => {
                                                setAutoSaveData(Date.now())
                                              }, 700)
                                            }
                                          } else {
                                            if (e?.length > 1) newVal = e.slice(1)

                                            setValue('accountNumber', newVal, {
                                              shouldValidate: true,
                                            }) // Trigger validation

                                          //  if(flg == '2'){
                                          //   setValue('paymentMethod',selectedPaymentMethod?.length === 0 ? '' : selectedPaymentMethod, {
                                          //     shouldValidate: true,
                                          //   })
                                          //  }

                                            setSelectedAccount(newVal)
                                            setSelectAccountErr(false)

                                            let values = {...incomeData}
                                            values.paymentAccountId = newVal?.[0].value
                                            values.selectedAccount = newVal

                                            setIncomeData(values)

                                            if (flg == '2') {
                                              setTimeout(() => {
                                                setAutoSaveData(Date.now())
                                              }, 700)
                                            }
                                          }
                                        }}
                                        labelledBy='Type'
                                        hasSelectAll={false}
                                        overrideStrings={{
                                          allItemsAreSelected: `${selectedAccount?.[0]?.label}`,
                                        }}
                                        closeOnChangedValue={true}
                                      />
                                    )}
                                  />
                                  {errors.accountNumber && (
                                    <p
                                      style={{
                                        color: 'var(--kt-danger)',
                                        gridColumn: '2/2',
                                      }}
                                      className='m-0 mt-1'
                                    >
                                      This field is required
                                    </p>
                                  )}
                                </div>
                              </div>
                              <h3 className='text-danger mb-0 ms-2'>
                                <b>*</b>
                              </h3>
                            </div>
                          </div>

                          <div className='col-11 col-md-9 px-5 mt-2'>
                            <div className='d-flex my-4'>
                              <h4 className='my-2' style={{color: 'black'}}>
                                <label style={{width: '130px'}} className='labl-gry'>
                                  {' '}
                                  Payment Method
                                </label>
                              </h4>

                              <div className='test expense  property-test'>
                                <div
                                  className={`multi-select-container ${
                                    selectedPaymentMethod?.length === 0
                                      ? 'no-value expense-payment-method'
                                      : 'has-value'
                                  }`}
                                >
                                  <Controller
                                    name='paymentMethod'
                                    control={control}
                                    rules={{
                                      required: true,
                                    }}
                                    render={({field}) => (
                                      <MultiSelect
                                        {...field}
                                        options={paymentMethodOptions}
                                        value={selectedPaymentMethod}
                                        onChange={(e: any) => {
                                          if (selectedAccount?.length == 0) {
                                            setSelectAccountErr(true)
                                            setSelectedPaymentMethod([])
                                            let values = {...incomeData}
                                            values.paymentMethod = null
                                            // values.invoiceData[0].paymentMethod = null
                                            // setValue('paymentMethod', '', {
                                            //   shouldValidate: true,
                                            // })
                                            setIncomeData(values)
                                          } else {
                                            
                                            let newVal: any = e
                                            if (e?.length > 1) newVal = e.slice(1)

                                            setSelectedPaymentMethod(newVal)
                                            setSelectAccountErr(false)

                                            let values = {...incomeData}
                                            values.paymentAccountId = e.value
                                            values.paymentMethod =
                                              newVal?.[0]?.value
                                              
                                            // values.invoiceData[0].paymentMethod =
                                            //   newVal?.[0]?.value

                                            setValue('paymentMethod', e?.length == 0 ? '' : `${newVal}1`, {
                                              shouldValidate: true,
                                            })
                                            setIncomeData(values)



                                            if (flg == '2') {
                                              setTimeout(() => {
                                                setAutoSaveData(Date.now())
                                              }, 700)
                                            }
                                          }
                                        }}
                                        labelledBy='Type'
                                        hasSelectAll={false}
                                        overrideStrings={{
                                          allItemsAreSelected: `${selectedPaymentMethod?.[0]?.label}`,
                                        }}
                                        closeOnChangedValue={true}
                                      />
                                    )}
                                  />
                                  {errors.paymentMethod && (
                                    <p
                                      style={{
                                        color: 'var(--kt-danger)',
                                        gridColumn: '2/2',
                                      }}
                                      className='m-0 mt-1'
                                    >
                                      This field is required
                                    </p>
                                  )}
                                  {selectAccountError && (
                                    <p
                                      style={{
                                        color: 'var(--kt-danger)',
                                        gridColumn: '2/2',
                                      }}
                                      className='m-0 mt-1'
                                    >
                                      Please select the receiving account first.
                                    </p>
                                  )}
                                </div>
                              </div>
                              <h3 className='text-danger mb-0 ms-2'>
                                <b>*</b>
                              </h3>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-6 '>
                  <div className='row'>
                    {/* Property & Lease Card*/}
                    <div className='col-12 px-5'>
                      <div
                        className={
                          'card card-flush py-5 container-xxl mt-2  pe-5 mb-4 px-6'
                        }
                        style={{minHeight: '345px'}}
                      >
                        <div className='d-flex'>
                          <h2 className='top ps-0'>
                            <b>Property & Lease</b>
                          </h2>
                        </div>

                        <div className='row mx-1' style={{marginTop: '30px'}}>
                          <div className='col-4 px-0 mt-4'>
                            <div className='my-4 align-items-center'>
                              <h4 className='my-2' style={{color: 'black'}}>
                                <label style={{width: '130px'}} className='labl-gry '>
                                  {' '}
                                  Unit
                                </label>
                              </h4>

                              <span className=''>
                                <b>
                                  {incomeData?.payorData?.unit?.[0]?.unitNo
                                    ? incomeData?.payorData?.unit?.[0]?.unitNo
                                    : '-'}
                                </b>
                              </span>
                            </div>
                          </div>

                          <div className='col-4 px-0 mt-4'>
                            <div className='my-4 align-items-center'>
                              <h4 className='my-2' style={{color: 'black'}}>
                                <label style={{width: '130px'}} className='labl-gry '>
                                  {' '}
                                  Development
                                </label>
                              </h4>

                              <span className=''>
                                <b>
                                  {incomeData?.payorData?.community?.length > 0
                                    ? `${incomeData?.payorData?.community?.[0]?.name}`
                                    : incomeData?.payorData?.building?.length > 0
                                    ? `${incomeData?.payorData?.building?.[0]?.name}`
                                    : '-'}
                                </b>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className='row mx-1'>
                          <div className='col-4 px-0 mt-4'>
                            <div className='mt-4 mb-2 d-flex align-items-end'>
                              <div>
                                <h4 className='my-2' style={{color: 'black'}}>
                                  <label style={{width: '130px'}} className='labl-gry '>
                                    {' '}
                                    Contract No.
                                  </label>
                                </h4>

                                <div className='d-flex align-items-center'>
                                  <span className=''>
                                    <b>
                                      {' '}
                                      {incomeData?.payorData?.contractNo
                                        ? `${incomeData?.payorData?.contractNo}`
                                        : '-'}
                                    </b>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className='col-4 px-0 mt-4'>
                            <div className='my-4 d-flex align-items-end'>
                              <div>
                                <h4 className='my-2' style={{color: 'black'}}>
                                  <label style={{width: '130px'}} className='labl-gry '>
                                    {' '}
                                    Lease Value
                                  </label>
                                </h4>

                                <div className='d-flex align-items-center'>
                                  <span className=''>
                                    <b>
                                    {Number(incomeData?.payorData?.totalAmount) > 0 ? (
                                  <>AED {formatAmount(incomeData?.payorData?.totalAmount)}</>
                                ) : (
                                  <>AED 0</>
                                )}
                                    </b>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className='col-4 px-0 mt-4'>
                            <div className='my-4 d-flex align-items-end'>
                              <div>
                                <h4 className='my-2' style={{color: 'black'}}>
                                  <label style={{width: '130px'}} className='labl-gry '>
                                    {' '}
                                    Payments Created
                                  </label>
                                </h4>

                                <div className='d-flex align-items-center'>
                                  <span className=''>
                                    <b>
                                    {Number(incomeData?.payment?.length) > 0 ? (
                                  <>AED {formatAmount(incomeData?.payment?.length)}</>
                                ) : (
                                  <>AED 0</>
                                )}
                                    </b>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {Number(selectedPaymentMethod?.[0]?.value) == 1 && (
                <AttachmentModel
                  isEdit={true}
                  expenseData={{attachments: chequeImages}}
                  updateParent={(val: any) => {
                    setChequeImages(val)

                    if (flg == '2') {
                      setTimeout(() => {
                        setAutoSaveData(Date.now())
                      }, 700)
                    }
                  }}
                />
              )}
              {Number(selectedPaymentMethod?.[0]?.value) == 0 && (
                <div className='row'>
                  <div className='col-12 px-5'>
                    <div
                      className={
                        'card card-flush py-5 mt-2  pe-5 mb-5 px-6 '
                      }
                    >
                      {Number(selectedPaymentMethod?.[0]?.value) == 0 && (
                        <>
                          <h5 className='fs-4' style={{lineHeight: '1.5'}}>
                            <label className='labl-gry '>
                              {' '}
                              As part of the services offered by Propertise with card
                              payments, there will be a fee on card transactions.
                            </label>
                          </h5>
                          <h5 className='fs-4' style={{lineHeight: '1.5'}}>
                            How would you like to settle card processing fees?
                          </h5>

                          <div className='d-flex align-items-center mt-5'>
                            <div className='d-flex align-items-center tst mb-4'>
                              <input
                                type="radio"
                                className="me-3 cursor-pointer form-check-input"
                                {...register('settleFees', { required: 'Please select an option to proceed' })}
                                id="chargeTenant"
                                value="chargeTenant"
                                checked={chargeTenant == 1}
                                onChange={(e: any) => {
                                  setChargeTenant(1)

                                  if (flg == '2') {
                                    setTimeout(() => {
                                      setAutoSaveData(Date.now())
                                    }, 700)
                                  }
                                }}
                              />
                              <h5 className='m-0'>
                                <label htmlFor='chargeTenant' className='fs-4'>
                                  Charge Tenant
                                </label>
                              </h5>
                            </div>

                            <div
                              className='d-flex align-items-center tst mb-4'
                              style={{marginLeft: '60px'}}
                            >
                              <input
                                type="radio"
                                className="me-3 cursor-pointer form-check-input"
                                {...register('settleFees', { required: 'Please select an option to proceed' })}
                                id="chargeCorporate"
                                value="chargeCorporate"
                                name='ownerType'
                                checked={chargeTenant == 0}
                                onChange={(e: any) => {
                                  setChargeTenant(0)

                                  if (flg == '2') {
                                    setTimeout(() => {
                                      setAutoSaveData(Date.now())
                                    }, 700)
                                  }
                                }}
                              />
                              <h5 className='m-0'>
                                <label htmlFor='chargeCorporate' className='fs-4'>
                                  Deduct card fees from the amount received from the
                                  tenant.
                                </label>
                              </h5>
                            </div>

                           
                          </div>

                          {errors?.settleFees && (
                            <p className="text-danger">{"Please select an option to proceed"}</p>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              )}

              <GenerateInvoice
                incomeData={incomeData}
                incomeAPIDetails={incomeAPIDetails}
                setIncomeAPIDetails={setIncomeAPIDetails}
                idToDelete={idToDelete}
                setIDToDelete={setIDToDelete}
                updateIncomeData={(updatedData: any) => setIncomeData(updatedData)}
                register={register}
                control={control}
                setValue={setValue}
                errors={errors}
                trigger={trigger}
                autoSaveData={autoSaveData}
                setAutoSaveData={setAutoSaveData}
                open={open}
                setOpen={setOpen}
                editMode={true}
              />
            </div>
          </form>
        </div>
      ) : (
        <div
          className='d-flex justify-content-center align-items-center w-100'
          style={{height: '75vh'}}
        >
          <CircularProgress style={{color: '#007a59'}} />
        </div>
      )}

      {showSelectPayor && incomeData && (
        <SelectPayorModal
          show={showSelectPayor}
          handleClose={() => {
            setShowSelectPayor(false)
          }}
          updateDetails={(data: any) => {
            let values = {...incomeData}
            values.payorData = data

            setIncomeData(values)

            setRefreshChild(Date.now())

            if (flg == '2') {
              setTimeout(() => {
                setAutoSaveData(Date.now())
              }, 700)
            }
          }}
          expData={incomeData}
        />
      )}

      <ModalLoader
        isVisible={showLoader}
        progress={uploadProgress}
        totalFiles={totalFiles}
        uploadingText={uploadingText}
        percentageProgress={percentProgress}
      />

      <Backdrop
        sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
        open={open}
      >
        <CircularProgress style={{color: '#007a59'}} />
      </Backdrop>
    </>
  )
}

export default AddInvoice
