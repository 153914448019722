import React, {useEffect, useState} from 'react'
import {ApiPost} from '../../../../../../apiCommon/helpers/API/ApiData'
import noData from '../../../../../../img/NoData1.svg'
import moment from 'moment'
import {MultiSelect} from 'react-multi-select-component'
import {
  removeLeadingZeros,
  formatAmount,
  invertOutstandingAmount,
} from '../../../../../../Utilities/utils'

interface ButtonProps {
  slctdInvoices?: any
  updateDetails?: any
  rcptData?: any
  register?: any
  errors?: any
  setValue?: any
  trigger?: any
  control?: any
  updatePaymentRecord: (val: any) => void // Function type, not void
  autoSaveParent?: any
  editMode?: any
}

const RecordPayment = ({
  slctdInvoices,
  updateDetails,
  rcptData,
  register,
  errors,
  setValue,
  trigger,
  control,
  updatePaymentRecord,
  autoSaveParent,
  editMode,
}: ButtonProps) => {
  const [selectedAccount, setSelectedAccount] = useState<any>([])
  const [accountOptions, setAccountOptions] = useState<any>([])

  const getAccounts = (val: any) => {
    // console.log(incomeData?.paymentAccountId)
    const body = {
      limit: 50,
      page: 1,
    }
    ApiPost(`corporate/payment_account/get`, body)
      .then((res) => {
        let values: any = []

        res?.data?.data?.payment_account_data?.map((acc: any, ind: any) => {
          values[ind] = {
            label: `${acc?.accountNumber} - ${acc?.bankName}`,
            value: acc?._id,
            IBAN: acc?.IBAN,
            accountHolderName: acc?.accountHolderName,
            accountNumber: acc?.accountNumber,
            bankName: acc?.bankName,
            swiftCode: acc?.swiftCode,
          }

          // if (val == acc?._id) setSelectedAccount([values[ind]])
        })

        setAccountOptions(values)
      })
      .catch((err) => console.log('err', err))
  }

  useEffect(() => {
    if (editMode) getAccounts('')
  }, [])

  return (
    <div>
      <div
        className='card card-flush py-4 mt-7  px-5'
        style={{maxHeight: '500px', overflow: 'auto'}}
      >
        <div className='d-flex align-items-top'>
          <h2 className='pt-2 mb-0'>
            <b>{editMode ? 'Record a Payment' : 'Payment Record'}</b>
          </h2>
        </div>
        <div className='d-flex align-items-center'>
          <h4 className=' pt-2 mb-0 head-text'>
            {editMode
              ? 'Enter the details of your payment for the following items under your selected invoices'
              : 'Details of your payment for the items under your selected invoices'}
          </h4>
        </div>

        <>
          {slctdInvoices?.map((inv: any, indOut: any) => {
            let outstandingAmount: any = Number(inv?.totalAmount) - Number(inv?.totalPaidAmount)

            return (
              <div className=''>
                <div className='table-responsive pb-3'>
                  <table className='table align-start fs-6 gy-5' id='kt_ecommerce_sales_table'>
                    <thead>
                      <tr className='text-start text-gray-400 fw-bold fs-7 gs-0'>
                        <th className='min-w-125px ps-0'>Invoice No.</th>
                        <th className='min-w-125px'>Amount</th>
                        <th className='min-w-100px text-center'>Category</th>
                        <th className='min-w-125px ps-7 pe-5'>Due Date</th>
                        <th className='min-w-100px text-center'>Status</th>
                        <th className='min-w-150px'>Outstanding</th>
                        <th className='min-w-150px '>Payor</th>
                        <th className='min-w-150px'>Development</th>
                        <th className='min-w-100px'>Unit No.</th>
                        <th className='min-w-125px text-center'>Lease Status</th>
                        <th className='min-w-150px'>Lease Term</th>
                        <th className='text-start min-w-150px px-3'>Contract No.</th>
                      </tr>
                    </thead>

                    <tbody className='fw-semibold head-text'>
                      <>
                        <tr>
                          <td className='ps-0'>
                            <div className='d-flex text-decoration-underline'>
                              <b>{inv?.invoiceNo ? inv?.invoiceNo : '-'}</b>
                            </div>
                          </td>
                          <td>
                            {inv?.totalAmount > 0 ? (
                              <>AED {formatAmount(inv?.totalAmount)}</>
                            ) : (
                              <>AED 0</>
                            )}
                          </td>
                          <td>
                            <div
                              className={`status status-w-100
                                ${
                                  inv?.categories == 0
                                    ? 'active-bar '
                                    : inv?.categories == 1
                                    ? 'expired-bar'
                                    : inv?.categories == 3
                                    ? 'complete-bar'
                                    : inv?.categories == 2
                                    ? 'req-bar'
                                    : 'no-st-bar'
                                }
                              `}
                            >
                              {inv?.categories == 0
                                ? 'Upcoming'
                                : inv?.categories == 1
                                ? 'Overdue'
                                : inv?.categories == 3
                                ? 'Complete'
                                : inv?.categories == 2
                                ? 'Deferred'
                                : '-'}
                            </div>
                          </td>
                          <td className='ps-7 pe-5'>
                            {inv?.dueDate ? moment(inv?.dueDate).format('DD.MM.YYYY') : '-'}
                          </td>
                          <td>
                            <div
                              className={`status-w-100 status
                                ${
                                  inv?.status === 2
                                    ? 'pending-approval-st '
                                    : inv?.status === 1
                                    ? 'approve-st'
                                    : inv?.status === 0
                                    ? 'cancelled-st'
                                    : inv?.status === 3 || inv?.status === 4
                                    ? 'pend-st'
                                    : 'not-saved'
                                }`}
                            >
                              {inv?.status === 0
                                ? 'Unpaid'
                                : inv?.status === 1
                                ? 'Paid'
                                : inv?.status === 2
                                ? 'Partially paid'
                                : inv?.status === 3
                                ? 'Void'
                                : inv?.status === 4
                                ? 'Draft'
                                : '-'}
                            </div>
                          </td>
                          <td>
                            {outstandingAmount != undefined ? (
                              <>AED {formatAmount(invertOutstandingAmount(outstandingAmount))}</>
                            ) : (
                              <>AED 0</>
                            )}
                          </td>
                          <td className=''>
                            {inv?.tenant?.[0]?.firstName
                              ? `${inv?.tenant?.[0]?.firstName} ${inv?.tenant?.[0]?.lastName}`
                              : '-'}
                          </td>

                          <td>
                            {inv?.community?.length > 0
                              ? inv?.community?.[0]?.name
                              : inv?.building?.[0]?.name}
                          </td>
                          <td>{inv?.unit?.length > 0 ? inv?.unit?.[0]?.unitNo : '-'}</td>
                          <td>
                            <div className='d-flex justify-content-center'>
                              <div
                                className={
                                  inv?.tenancy?.[0]?.tenancyStatus == 0
                                    ? 'status booked-bar'
                                    : inv?.tenancy?.[0]?.tenancyStatus == 1
                                    ? 'status active-bar  '
                                    : inv?.tenancy?.[0]?.tenancyStatus == 2
                                    ? 'status expiring-bar'
                                    : inv?.tenancy?.[0]?.tenancyStatus == 3
                                    ? 'status expired-bar'
                                    : 'status  no-st-bar'
                                }
                              >
                                {inv?.tenancy?.[0]?.tenancyStatus == 0
                                  ? 'Booked'
                                  : inv?.tenancy?.[0]?.tenancyStatus == 1
                                  ? 'Active'
                                  : inv?.tenancy?.[0]?.tenancyStatus == 2
                                  ? 'Expiring'
                                  : inv?.tenancy?.[0]?.tenancyStatus == 3
                                  ? 'Expired'
                                  : '-'}
                              </div>
                            </div>
                          </td>

                          <td className='text-start '>
                            {inv?.tenancy?.[0]?.duration?.start_date
                              ? `${moment
                                  .utc(inv?.tenancy?.[0]?.duration?.start_date)
                                  .local()
                                  .format('DD.MM.YYYY')} `
                              : '-'}
                            {'-'}
                            {inv?.tenancy?.[0]?.duration?.end_date
                              ? `${moment
                                  .utc(inv?.tenancy?.[0]?.duration?.end_date)
                                  .local()
                                  .format('DD.MM.YYYY')} `
                              : '-'}
                          </td>

                          <td className=''>
                            {inv?.tenancy?.[0]?.contractNo > 0
                              ? inv?.tenancy?.[0]?.contractNo
                              : '-'}
                          </td>
                        </tr>
                      </>
                    </tbody>
                  </table>
                </div>

                <div className='d-flex my-4 align-items-start'>
                  <h4 className='my-2' style={{color: 'black'}}>
                    <label className='labl-gry pt-1'> Receiving Account</label>
                  </h4>

                  {editMode ? (
                    <>
                      <div className='test expense property-test ms-8'>
                        <div
                          className={`multi-select-container ${
                            inv?.payment_account.length == 0
                              ? 'no-value expense-account'
                              : 'has-value'
                          }`}
                        >
                          {/* <Controller
                        name={`accountNumber${indOut}`}
                        control={control}
                        rules={{
                          required: 'This field is required',
                        }}
                        render={({field}) => ( */}
                          <MultiSelect
                            // {...field}
                            options={accountOptions}
                            value={
                              Array.isArray(inv?.payment_account) && inv?.payment_account.length > 0
                                ? [
                                    {
                                      label: `${inv?.payment_account?.[0]?.accountNumber || ''} - ${
                                        inv?.payment_account?.[0]?.bankName || ''
                                      }`,
                                      value: inv?.payment_account?.[0]?._id || '',
                                    },
                                  ]
                                : []
                            }
                            onChange={(e: any) => {
                              let newVal: any = e

                              if (e?.length > 1) newVal = e.slice(1)

                              setValue(`accountNumber${indOut}`, newVal, {
                                shouldValidate: true,
                              }) // Trigger validation

                              let invValues: any = [...slctdInvoices]
                              invValues[indOut].paymentAccountId = newVal?.[0]?.value
                              invValues[indOut].payment_account = newVal
                              updatePaymentRecord(invValues)

                              autoSaveParent()
                            }}
                            labelledBy='Type'
                            hasSelectAll={false}
                            overrideStrings={{
                              allItemsAreSelected: `${selectedAccount?.[0]?.label}`,
                            }}
                            closeOnChangedValue={true}
                          />
                          {/* )} */}
                          {/* />
                      {`accountNumber${indOut}` && (
                        <p
                          style={{color: 'var(--kt-danger)', gridColumn: '2/2'}}
                          className='m-0 mt-1'
                        >
                          This field is required
                        </p>
                      )} */}
                        </div>
                      </div>
                      <h3 className='text-danger mb-0 ms-2'>
                        <b>*</b>
                      </h3>
                    </>
                  ) : (
                    <h4 className='my-2' style={{color: 'black'}}>
                      <label className='ms-8 pt-1'>
                        <b>
                          {`${inv?.payment_account?.[0]?.accountNumber || ''} - ${
                            inv?.payment_account?.[0]?.bankName || ''
                          }`}
                        </b>
                      </label>
                    </h4>
                  )}
                </div>

                <div className='separator my-3 mt-7'></div>

                <div
                  className='table-responsive pb-3'
                  // style={{maxHeight: '350px', overflow: 'auto'}}
                >
                  <table className='table align-start fs-6 gy-5' id='kt_ecommerce_sales_table'>
                    <thead>
                      <tr className='text-start text-gray-400 fw-bold fs-7 gs-0'>
                        <th className='min-w-25px pe-3 ps-0'>ID</th>
                        <th className='min-w-150px px-3'>Item</th>
                        <th className='min-w-100px px-3'>Amount</th>
                        <th className='min-w-100px  px-3'>QTY</th>
                        <th className='min-w-100px  px-3'>VAT</th>
                        <th className='min-w-150px  px-3'>Total Amount</th>
                        <th className='min-w-150px px-3'>Outstanding</th>
                        <th className='min-w-150px  px-3'>Amount Paid</th>
                      </tr>
                    </thead>
                    <tbody className='fw-semibold head-text'>
                      {inv?.paymentSubItems?.map((invItem: any, indIn: any) => {
                        let totalAmountOfItem: any = 0
                        let vatAmnt: any =
                          ((Number(invItem?.amount) * Number(invItem?.quantity)) / 100) *
                          Number(invItem?.VATPercentage)
                        totalAmountOfItem =
                          Number(invItem?.amount) * Number(invItem?.quantity) + vatAmnt

                        return (
                          <>
                            <td className='pe-3 ps-0'>
                              {invItem?.frontendStorage?.paymentItemId
                                ? invItem?.frontendStorage?.paymentItemId
                                : '-'}
                            </td>
                            <td className='px-3'>{invItem?.name ? invItem?.name : '-'}</td>{' '}
                            <td className='px-3'>
                              {' '}
                              {invItem?.amount ? formatAmount(invItem?.amount) : '-'}
                            </td>{' '}
                            <td className='px-3'>{invItem?.quantity ? invItem?.quantity : '-'}</td>{' '}
                            <td className='px-3'>
                              {' '}
                              {invItem.VATPercentage ? `${invItem.VATPercentage}%` : '-'}
                            </td>{' '}
                            <td className='px-3'>
                              {'AED '}
                              <b className='ps-1'>
                                {totalAmountOfItem ? formatAmount(totalAmountOfItem) : ' -'}
                              </b>
                            </td>{' '}
                            <td className='px-3'>
                              {'AED '}
                              <b className='ps-1'>
                                {invItem?.outstandingAmount
                                  ? formatAmount(
                                      invertOutstandingAmount(invItem?.outstandingAmount)
                                    )
                                  : ' -'}
                              </b>
                            </td>{' '}
                            <td className='px-3'>
                              {editMode ? (
                                <>
                                  <input
                                    //   {...register('documentName', {required: true})}
                                    type='number'
                                    className={`form-control form-control-solid mytest tenancy-control   status-w-200 ${
                                      invItem?.amountPaid > invItem?.outstandingAmount
                                        ? 'border-danger'
                                        : ''
                                    }`}
                                    name='documentName'
                                    value={invItem?.amountPaid}
                                    style={{height: '35px'}}
                                    placeholder={'AED'}
                                    onChange={(e: any) => {
                                     
                                      let values = [...slctdInvoices]
                                      let valuesItems = values[indOut].paymentSubItems
                                      let newValue :any = removeLeadingZeros(e.target.value)
                                      // console.log(newValue)
                                      valuesItems[indIn].amountPaid = Number(newValue)
                                      values[indOut].paymentSubItems = valuesItems

                                      updatePaymentRecord(values)
                                    }}
                                    autoComplete='off'
                                    onBlur={autoSaveParent}
                                  />

                                  {invItem?.amountPaid > invItem?.outstandingAmount && (
                                    <p
                                      className='text-danger mb-0 ms-0 ps-0'
                                      style={{maxWidth: '200px', wordBreak: 'break-word'}}
                                    >
                                      {' '}
                                      Amount paid is greater than the outstanding amount.
                                    </p>
                                  )}
                                </>
                              ) : (
                                <>
                                  {'AED '}
                                  <b className='ps-1'>
                                    {invItem?.amountPaid
                                      ? `${formatAmount(invItem?.amountPaid)}`
                                      : ' -'}
                                  </b>
                                </>
                              )}
                            </td>
                          </>
                        )
                      })}
                    </tbody>
                  </table>
                </div>

                <div className='separator my-3 mt-7'></div>
              </div>
            )
          })}
        </>
      </div>
    </div>
  )
}

export default RecordPayment
