import React from 'react'
import ReactApexChart from 'react-apexcharts'
import apartmentImg from '../../../img/Apartment.svg'
import buildingImg from '../../../img/allBuildingGreen.png'
import unitClusterImg from '../../../img/unitClusterGreen.png'
import unitGrpImg from '../../../img/buildingClusterGreen.png'

interface radialBarChartProps {
  developmentDetails?: any
}

const RadialBarChart: React.FC<radialBarChartProps> = ({developmentDetails}) => {
  // console.log(developmentDetails)
  const options: ApexCharts.ApexOptions = {
    chart: {
      height: 350,
      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 20,
          size: '60%',
          image: developmentDetails?.data?.isBuilding ? buildingImg : developmentDetails?.data?.isUnitCluster ? unitClusterImg : developmentDetails?.data?.isUnitGroup ?  unitGrpImg : '',
          imageWidth: 35,
          imageHeight: 35,
          imageClipped: false,
          imageOffsetY: 17, // Offset image to bottom
        },
        dataLabels: {
          // enabled:false,
          name: {
            show: false,
          },
          value: {
            show: true,
            color: '#333',
            offsetY: -20, // Move value to the top
            fontSize: '15px',
            fontWeight: '500',
          },
        },
      },
    },
    fill: {
      colors: ['#00b9a9'],
    },
    stroke: {
      lineCap: 'butt',
      width: 6,
      
    },
    labels: ['Volatility'],
  }

  const series = [67]

  return (
    <div>
      <div id='chart'>
        <ReactApexChart
          options={options}
          series={[developmentDetails?.percentValue ? developmentDetails?.percentValue?.toFixed(1) : 0]}
          type='radialBar'
          height={320}
        />
      </div>
      <div className='d-flex justify-content-center' style-={{marginTop: '-5px'}}>
        <p className='fnt-500 mb-0'>
          {developmentDetails?.data?.name ? developmentDetails?.data?.name : '---'}
        </p>
      </div>
      {developmentDetails?.data?.subName?.trim()?.length > 0 && (
        <div className='d-flex justify-content-center'>
          <p className='fnt-400 labl-gry mb-0'>
            {developmentDetails?.data?.subName ? developmentDetails?.data?.subName : '---'}
          </p>
        </div>
      )}
    </div>
  )
}

export default RadialBarChart
