import React, {useEffect, useState, useRef} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {
  ApiGet,
  ApiGetNoAuth,
  ApiPost,
  ApiPut,
  ApiUpload,
  Bucket,
  ApiDelete,
} from '../../../../apiCommon/helpers/API/ApiData'
import {ErrorToast, SuccessToast} from '../../../../apiCommon/helpers/Toast'
import swal from 'sweetalert2'
import 'antd/dist/antd.css'
import moment from 'moment'
import backArrow from '../../../../img/back-arrow.png'
import redCross from '../../../../img/remove.png'
import trashImg from '../../../../img/trash.png'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import internalTeamGreen from '../../../../img/internal-team-green.png'
import externalTeamGreen from '../../../../img/external-team-green.png'
import scheduleWhite from '../../../../img/schedule-white.png'
import {Modal} from 'react-bootstrap'
import tick from '../../../../img/tick-white.png'
import townhouse from '../../../../img/TownHouse.svg'
import appartment from '../../../../img/Apartment.svg'
import villa from '../../../../img/Villa.svg'
import other from '../../../../img/other.svg'
import buildings from '../../../../img/buildings.svg'
import common_area from '../../../../img/common_area.svg'
import penthouse from '../../../../img/PentHouse.svg'
import starYellow from '../../../../img/starnotification.svg'
import pdfDocument from '../../../../img/pdf.svg'
import docCocument from '../../../../img/doc.svg'
import fileIcon from '../../../../img/file.svg'
import pencil from '../../../../img/edit-pen.png'
import clockImg from '../../../../img/three-clock.png'
import urgencySwitch from '../../../../img/urgency-switch.png'
import ScheduleVisitation from './ScheduleVisitation'
import circularImg from '../../../../img/circular-black.png'
import cancelImg from '../../../../img/cancel.png'
import correct from '../../../../img/correct.png'
import removed from '../../../../img/remove-filled.png'
import HandlingTeamComments from './HandlingTeamComments'
import TenantComments from './TenantComments'
import JobReportLog from './JobReportLog'
import JobStartTimeModal from './JobStartTimeModal'
import JobEndTimeModal from './JobEndTimeModal'
import videoImg from '../../../../img/video.png'
import devClustGreenImg from '../../../../img/dev-clust-request-green.png'
import commonAreasGreenImg from '../../../../img/common-areas-request-green.png'
import unitGreebImg from '../../../../img/unit-request-green.png'

import {Fancybox as fancybox} from '@fancyapps/ui'
import '@fancyapps/ui/dist/fancybox/fancybox.css'
import JobDetailsModal from '../JobDetailsModal'
import HandlingTeamCommentsExceptUnit from './HandlingTeamCommentsExceptUnit'

const ServiceJob = () => {
  const {reqId, id} = useParams()
  const {state} = useLocation()

  const userData: any = localStorage.getItem('userData')
  const user = JSON.parse(userData)

  const roleAndPermissions: any = localStorage.getItem('roleAndPermissions')
  const role = JSON.parse(roleAndPermissions)

  const [open, setOpen] = React.useState(false)
  const [selectedVendor, setSelectedVendor] = useState<any>()

  const navigate = useNavigate()

  const [isSkeleton, setIsSkeleton] = useState<any>(false)

  const [requestData, setRequestData] = useState<any>({
    maintenanceMainServiceId: '',
    maintenanceSubServiceId: '',
  })

  const [isLoading, setIsLoading] = useState<any>()

  setTimeout(() => {
    setIsLoading(false)
  }, 5000)

  setTimeout(() => {
    setOpen(false)
  }, 10000)

  useEffect(() => {
    setIsSkeleton(true)
  }, [])

  const [approvalWorkflowCard, setApprovalWorkflowCard] = useState<any>(false)
  const [showSelectVendor, setShowSelectVendor] = useState<any>(false)
  const [showSchedule, setShowSchedule] = useState<any>(false)

  const [showJobStartTime, setShowJobStartTime] = useState<any>(false)
  const [showJobEndTime, setShowJobEndTime] = useState<any>(false)

  const [scheduleToAddEdit, setScheduleToAddEdit] = useState<any>('')
  const [scheduleIndToAddEdit, setScheduleIndToAddEdit] = useState<any>(-1)

  const [jobsSchedule, setJobsSchedule] = useState<any>([])
  const [jobsDetails, setJobsDetails] = useState<any>()

  //
  const getVendorById = (val: any) => {
    ApiGet(`corporate/vendor/${val}`)
      .then((res: any) => {
        setSelectedVendor(res?.data?.data)
        setShowSelectVendor(false)
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
      })
  }

  //
  const getJobById = () => {
    ApiGet(`corporate/maintenance_service_job/${id}`)
      .then((res: any) => {
        setJobsDetails(res?.data?.data)

        if (res?.data?.data?.unitId) getUnitById(res?.data?.data?.unitId)

        setRequestData(res?.data?.data?.maintenance_service_request?.[0])

        setType(
          res?.data?.data?.maintenance_service_request?.[0]?.maintenanceRequestType == 0
            ? 'reactive'
            : res?.data?.data?.maintenance_service_request?.[0]?.maintenanceRequestType == 1
            ? 'planned'
            : 'preventative'
        )

        if (res?.data?.data?.maintenance_service_request?.[0]?.vendor?.length > 0)
          getVendorById(res?.data?.data?.maintenance_service_request?.[0]?.vendor?.[0]?._id)

        // setJobsSchedule(res?.data?.data?.maintenance_service_job_schedule)

        setIsLoading(false)
        setIsSkeleton(false)
        setOpen(false)
      })
      .catch((err: any) => {
        setOpen(false)
        ErrorToast(err?.message)
        setIsLoading(false)
        setIsSkeleton(false)
      })
  }

  const [type, setType] = useState<any>()
  const [tenant, setTenant] = useState<any>()

  const [unitData, setUnitData] = useState<any>()
  const [mode, setMode] = useState<any>('')

  useEffect(() => {
    getJobById()
    getMaintenanceServiceJobSchedule()
  }, [])

  //
  const getUnitById = (value: any) => {
    ApiGet(`corporate/unit/${value}`)
      .then((res) => {
        setUnitData(res?.data?.data)
        if (res?.data?.data?.tenancy) getTenantById(res?.data?.data?.tenancy?.tenantId)
      })
      .catch((err) => console.log('err', err))
  }

  //
  const getTenantById = (value: any) => {
    ApiGet(`corporate/tenant/${value}`)
      .then((res) => {
        setTenant(res?.data?.data)
      })
      .catch((err) => console.log('err', err))
  }

  //
  const updateServiceJob = (data: any) => {
    const body = {
      id: id,
      jobUrgency: data?.jobUrgency,
    }

    ApiPut(`corporate/maintenance_service_job`, body)
      .then((res: any) => {
        getJobById()
        SuccessToast(res?.data?.message)
      })
      .catch((err: any) => {
        setOpen(false)
        ErrorToast(err?.message)
      })
  }

  //
  const cancelJob = () => {
    const body = {
      id: id,
      maintenanceJobStatus: 2,
    }

    ApiPut(`corporate/maintenance_service_job`, body)
      .then((res: any) => {
        getJobById()
        SuccessToast(res?.data?.message)
      })
      .catch((err: any) => {
        setOpen(false)
        ErrorToast(err?.message)
      })
  }

  //
  const getMaintenanceServiceJobSchedule = () => {
    const body = {
      maintenanceServiceJobId: id,
    }

    ApiPost(`corporate/maintenance_service_job_schedule/get`, body)
      .then((res: any) => {
        setJobsSchedule(res?.data?.data?.maintenance_service_job_schedule_data)
        setShowSchedule(false)
      })
      .catch((err: any) => {
        setIsLoading(false)
        ErrorToast(err?.message)
      })
  }

  //
  const cancelVisit = (vs: any) => {
    const body = {
      maintenanceServiceJobScheduleId: vs?._id,
    }
    ApiPost(`corporate/maintenance_service_job_schedule/normal_cancel`, body)
      .then((res: any) => {
        setIsLoading(false)
        SuccessToast(res?.data?.message)
        getMaintenanceServiceJobSchedule()
      })
      .catch((err: any) => {
        setIsLoading(false)
        ErrorToast(err?.message)
      })
  }

  //
  const deleteVisit = (vs: any) => {
    let ids: any = [vs?._id]
    const body = {
      ids: ids,
    }
    ApiPost(`corporate/maintenance_service_job_schedule/delete`, body)
      .then((res: any) => {
        setIsLoading(false)
        SuccessToast(res?.data?.message)
        getMaintenanceServiceJobSchedule()
      })
      .catch((err: any) => {
        setIsLoading(false)
        ErrorToast(err?.message)
      })
  }

  //
  const convertUTCToLocalTime = (utcTimeString: any, flg: any) => {
    const localDate = moment.utc(utcTimeString).local()
    if (flg == 1) return localDate.format('DD.MM.YYYY')
    else return localDate.format('HH:mm')
  }

  //
  const corporateConfirmation = (sch: any, val: any) => {
    setIsLoading(true)
    const body = {
      corporateConfirmationStatus: val,
      maintenanceServiceJobScheduleId: sch?._id,
    }
    ApiPost(`corporate/maintenance_service_job_schedule/corporate_confirmation`, body)
      .then((res: any) => {
        setIsLoading(false)
        SuccessToast(res?.data?.message)
        getMaintenanceServiceJobSchedule()
      })
      .catch((err: any) => {
        setIsLoading(false)
        ErrorToast(err?.message)
      })
  }

  useEffect(() => {
    fancybox.bind('[data-fancybox]', {
      Toolbar: {
        display: {
          left: ['infobar'],
          middle: ['zoomIn', 'zoomOut', 'toggle1to1', 'rotateCCW', 'rotateCW', 'flipX', 'flipY'],
          right: ['slideshow', 'thumbs', 'close'],
        },
      },
    })

    return () => {
      fancybox.destroy();
    };
  }, [])

   //
   const handleImageClickOfJobsAttachments = (
    attachmentId: string,
    attachmentSrc: string,
    comm: any,
    val: any
  ) => {
    console.log("I am clicked")
    const groupName = `group_job_attachments_${attachmentId}`
  
    // Add data-fancybox attribute to the clicked image
    const image = document.getElementById(attachmentId)
    console.log(image)
    if (image) {
      image.setAttribute('data-fancybox', groupName)
      image.setAttribute('data-src', attachmentSrc)
    }
  
    // Add data-fancybox attribute to the related attachments
    comm?.attachments?.forEach((attach: any, idx: number) => {
      const imageNext = document.getElementById(`${val}${idx}`)
      if (imageNext) {
        imageNext.setAttribute('data-fancybox', groupName)
        imageNext.setAttribute('data-src', `${Bucket}${attach}`)
      }
    })
  
    // Destroy and reinitialize Fancybox to ensure it recognizes the new attributes
    fancybox.destroy()
    fancybox.bind("[data-fancybox]", {})
  
    // Trigger the fancybox on the clicked image
    if (image) {
      image.click()
    }
  }
  
  return (
    <>
      {!isSkeleton ? (
        <div className='app-main flex-column flex-row-fluid px-2' id='kt_app_main'>
          <div id='' className='pt-0 mt-0'>
            {' '}
            {/* Header */}
            <div className='page-title d-flex flex-column justify-content-center flex-wrap'>
              {' '}
              <div className='d-flex align-items-center mb-1'>
                <div className='d-flex align-items-center'>
                  <span
                    className='rounded-circle cursor-pointer'
                    onClick={() => {
                      navigate(-1)
                      // navigate(`/maintenance/request/${type}/${reqId}`)
                      // navigate('-1',{
                      //   state:{
                      //     activeTab: state?.activeTab
                      //    }
                      // })
                    }}
                  >
                    <img src={backArrow} height='14' width='14' />
                  </span>
                  <h2 className='m-0 head-text ms-2 text-capitalize'>
                    {`Job`} {`${jobsDetails?.id}`}
                  </h2>
                </div>
              </div>
            </div>
            {/* Details Card */}
            <div className='card card-flush py-5 mt-5'>
              <div className='row mx-1'>
                {/* left section */}
                <div className='col-6 px-5 pe-0'>
                  <div className='row'>
                    {/* Service Details */}
                    <div className='col-12 px-0 mt-3'>
                      <div className='row me-4 ms-0 mt-0'>
                        <div className='col-8 px-5 d-flex'>
                          <h2 className='top ps-0'>
                            <b>Job Details</b>
                          </h2>
                        </div>

                        <div className='col-4 px-5 d-flex'>
                          <div style={{minWidth: '150px'}} className='ms-auto'>
                          <div
                            className={`status status-w-120  ${
                              jobsDetails?.maintenanceJobStatus == 0
                                ? 'booked-bar'
                                : jobsDetails?.maintenanceJobStatus == 1
                                ? 'expiring-bar'
                                : jobsDetails?.maintenanceJobStatus == 2
                                ? 'expired-bar'
                                : jobsDetails?.maintenanceJobStatus == 3
                                ? 'active-bar'
                                : 'no-st-bar'
                            }`}
                          >
                            {jobsDetails?.maintenanceJobStatus == 0
                              ? 'Not Started'
                              : jobsDetails?.maintenanceJobStatus == 1
                              ? 'In-Progress'
                              : jobsDetails?.maintenanceJobStatus == 2
                              ? 'Cancelled'
                              : jobsDetails?.maintenanceJobStatus == 3
                              ? 'Complete'
                              : '-'}
                          </div>
                          <i>
                            {' '}
                            {jobsDetails?.maintenanceJobStatusDateTime
                              ? `${moment
                                  ?.utc(jobsDetails?.maintenanceJobStatusDateTime)
                                  ?.local()
                                  ?.format('DD.MM.YYYY - HH:mm a')} `
                              : ''}
                          </i>
                          </div>
                        </div>
                      </div>
                      <div className='row me-4 ms-0 mt-0'>
                        <div className='col-8 px-5 mt-4'>
                          <div className='d-flex my-3'>
                            <h4 className='my-2' style={{color: 'black'}}>
                              <label style={{width: '120px'}} className='labl-gry '>
                                {' '}
                                Request ID
                              </label>
                              <span className='ms-1'>
                                <b>{requestData?.id ? requestData?.id : '-'}</b>
                              </span>
                            </h4>
                          </div>
                        </div>
                        <div className='col-4 px-5 mt-4 text-end'>
                          {jobsDetails?.maintenanceJobStatus != 2 && (
                            <button
                              type='button'
                              className='btn btn-sm fw-bold red-hollow-btn status-w-150 ps-2 ms-auto mt-2'
                              onClick={() => {
                                swal
                                  .fire({
                                    html: ` 
                                      <div class='fs-3'> <b>Cancel Job</b></div>
                                      <div class='fs-5 mt-4'> You are about to cancel this job. Are you sure you want to continue? </div>`,
                                    // icon: 'warning',
                                    showConfirmButton: true,
                                    confirmButtonColor: '#35475e',
                                    confirmButtonText: 'Yes',
                                    showCancelButton: true,
                                    cancelButtonText: 'No',
                                    cancelButtonColor: '#fff',
                                    customClass: {
                                      confirmButton: 'custom-confirm-button',
                                      cancelButton: 'custom-cancel-button',
                                      popup: 'custom-popup',
                                    },
                                    reverseButtons: true,
                                  })
                                  .then((res: any) => {
                                    if (res.isConfirmed) {
                                      cancelJob()
                                    }
                                  })
                              }}
                            >
                              <img src={redCross} height={18} width={18} className='me-6' /> Cancel
                              Job
                            </button>
                          )}
                        </div>

                        <div className='col-8 px-5 mt-1'>
                          <div className='d-flex my-3'>
                            <h4 className='my-2' style={{color: 'black'}}>
                              <label style={{width: '120px'}} className='labl-gry '>
                                {' '}
                                Job ID
                              </label>
                              <span className='ms-1'>
                                <b>{jobsDetails?.id ? jobsDetails?.id : '-'}</b>
                              </span>
                            </h4>
                          </div>
                        </div>
                        <div className='col-4 px-0 mt-4'></div>
                        <div className='col-12 px-5 mt-1'>
                          <div className='d-flex my-3 align-items-center'>
                            <h4
                              className='my-2 d-flex align-items-center '
                              style={{color: 'black'}}
                            >
                              <label style={{width: '120px'}} className='labl-gry '>
                                {' '}
                                Urgency
                              </label>
                              <span className='ms-1'>
                                <b>
                                  <div
                                    className={
                                      jobsDetails?.jobUrgency == 0
                                        ? 'urgency-stats urgencyStandardStatus '
                                        : 'urgency-stats urgencyEmergencyStatus'
                                    }
                                  >
                                    {jobsDetails?.jobUrgency == 0 ? 'Standard' : 'Emergency'}
                                  </div>
                                </b>
                              </span>
                              <span className='ms-5'>
                                <img
                                  src={urgencySwitch}
                                  height={18}
                                  width={18}
                                  className='cursor-pointer'
                                  onClick={() => {
                                    let values = {...jobsDetails}
                                    if (values?.jobUrgency == 0) values.jobUrgency = 1
                                    else values.jobUrgency = 0

                                    setJobsDetails(values)
                                    updateServiceJob(values)
                                  }}
                                />
                              </span>
                            </h4>
                          </div>
                        </div>

                        <div className='col-12 px-5 mt-1'>
                          <div className='d-flex my-3'>
                            <h4 className='my-2' style={{color: 'black'}}>
                              <label style={{width: '120px'}} className='labl-gry '>
                                {' '}
                                Main Service
                              </label>
                              <span className='ms-1'>
                                <b>
                                  {requestData?.maintenance_main_service?.[0]?.name
                                    ? requestData?.maintenance_main_service?.[0]?.name
                                    : '-'}
                                </b>
                              </span>
                            </h4>
                          </div>
                        </div>

                        <div className='col-12 px-5 mt-1'>
                          <div className='d-flex my-3'>
                            <h4 className='my-2' style={{color: 'black'}}>
                              <label style={{width: '120px'}} className='labl-gry '>
                                {' '}
                                Sub Service
                              </label>
                              <span className='ms-1'>
                                <b>
                                  {requestData?.maintenance_sub_service?.[0]?.name
                                    ? requestData?.maintenance_sub_service?.[0]?.name
                                    : '-'}
                                </b>
                              </span>
                            </h4>
                          </div>
                        </div>

                        <div className='col-12 px-5 mt-1'>
                          <div className='d-flex my-3'>
                            <h4 className='my-2' style={{color: 'black'}}>
                              <label style={{width: '120px'}} className='labl-gry '>
                                {' '}
                                Duration
                              </label>
                              <span className='ms-1'>
                                <b>
                                  {jobsDetails?.duration != undefined
                                    ? `${jobsDetails?.duration} Days`
                                    : '-'}
                                </b>
                              </span>
                            </h4>
                          </div>
                        </div>
                        <div className='col-12 px-5 mt-1'>
                          <div className='d-flex my-3'>
                            <h4 className='my-2' style={{color: 'black'}}>
                              <label style={{width: '120px'}} className='labl-gry '>
                                {' '}
                                Job Rating
                              </label>
                              <span className='ms-0'>
                                <b>
                                  {' '}
                                  <img src={starYellow} height={18} width={18} className='me-1' />
                                  {jobsDetails?.rating != undefined ? jobsDetails?.rating : '-'}
                                </b>
                              </span>
                            </h4>
                          </div>
                        </div>
                        <div className='col-12 px-5 mt-4'>
                          <div className='card card-flush py-5 container-xxl mt-2  min-res pe-5'>
                            <h4 className='ms-3 labl-gry' style={{fontWeight: '700'}}>
                              Job Attachments
                            </h4>

                            {jobsDetails?.attachments?.length > 0 ? (
                              <div
                                className='tops d-flex gap-5 align-items-start mx-5 flex-wrap mt-4'
                                style={{maxHeight: '200px', overflow: 'auto'}}
                              >
                                {jobsDetails?.attachments?.map((file: any, ind: any) => {
                                  return (
                                    <>
                                      <div className='imgs-set'>
                                        <div className='d-flex mw-350px me-3 '>
                                          {/* <a target='_blank' href={`${Bucket}${file}`}> */}
                                          <img
                                            src={
                                              file?.split('.')[1] === 'pdf'
                                                ? pdfDocument
                                                : file?.split('.').pop() === 'doc' ||
                                                  file?.split('.').pop() === 'docx'
                                                ? docCocument
                                                : file?.split('.').pop() === 'jpeg' ||
                                                  file?.split('.').pop() === 'jpg' ||
                                                  file?.split('.').pop() === 'png' ||
                                                  file?.split('.').pop() === 'svg' ||
                                                  file?.split('.').pop() === 'gif'
                                                ? `${Bucket}${file}`
                                                : file?.split('.').pop()?.toLowerCase() === 'mp4' ||
                                                  file?.split('.').pop()?.toLowerCase() ===
                                                    'webm' ||
                                                  file?.split('.').pop()?.toLowerCase() === 'avi' ||
                                                  file?.split('.').pop()?.toLowerCase() === 'mkv' ||
                                                  file?.split('.').pop()?.toLowerCase() === 'mov' ||
                                                  file?.split('.').pop()?.toLowerCase() === 'flv' ||
                                                  file?.split('.').pop()?.toLowerCase() === 'wmv'
                                                ? videoImg
                                                : fileIcon
                                            }
                                            // src={Bucket + file}
                                            width='100'
                                            height='100'
                                            className='main_img mb-5 cursor-pointer'
                                            // data-fancybox
                                            // data-src={`${Bucket}${file}`}
                                            id={`jobAttachments${ind}`}
                                            onClick={() =>
                                              handleImageClickOfJobsAttachments(
                                                `jobAttachments${ind}`,
                                                `${Bucket}${file}`,
                                                jobsDetails,
                                                `jobAttachments`
                                              )
                                            }

                                          />
                                          {/* </a> */}
                                        </div>
                                      </div>
                                    </>
                                  )
                                })}
                              </div>
                            ) : (
                              <div className='ms-3'>{'-'}</div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-6 px-5 text end'>
                  <div className='row'>
                    {/* Request Details */}
                    <div className='col-12 px-5 mt-3'>
                      <div className='card card-flush py-5 mt-2  min-res ps-3'>
                        <div className='d-flex  align-items-center'>
                          <h2 className='ms-3' style={{fontWeight: '700'}}>
                            Property
                          </h2>
                          <div className='ms-auto'>
                            {/* <img
                              src={
                                unitData?.unitType === 'town_house'
                                  ? townhouse
                                  : unitData?.unitType === 'other'
                                  ? other
                                  : unitData?.unitType === 'common_area'
                                  ? common_area
                                  : unitData?.unitType === 'villa'
                                  ? villa
                                  : unitData?.unitType === 'apartment'
                                  ? appartment
                                  : unitData?.unitType === 'penthouse'
                                  ? penthouse
                                  : ''
                              }
                              height={40}
                              className='mb-2 me-4'
                            /> */}
                            <img
                              src={`${
                                jobsDetails?.maintenanceJobType == 0
                                  ? devClustGreenImg
                                  : jobsDetails?.maintenanceJobType == 1
                                  ? commonAreasGreenImg
                                  : unitGreebImg
                              }`}
                              height={40}
                              className='mb-2 me-4'
                            />
                          </div>
                        </div>

                        {unitData &&
                          (jobsDetails?.maintenanceJobType == 2 ||
                            jobsDetails?.maintenanceJobType == 1) && (
                            <>
                              <div
                                className='row d-flex me-5 mb-5 px-0 ms-0'
                                style={{marginTop: '-5px'}}
                              >
                                <div className='col-8'>
                                  {/* Unit No  */}
                                  <div className='col-12 px-4 ps-0'>
                                    <h4 className='my-4' style={{color: 'black'}}>
                                      <label style={{minWidth: '100px'}} className='labl-gry me-3'>
                                        {' '}
                                        {jobsDetails?.maintenanceJobType == '2'
                                          ? 'Unit No'
                                          : 'Common Area'}
                                      </label>
                                      <span className='ms-5 ps-1'>
                                        <b>{unitData?.unitNo ? unitData?.unitNo : '-'}</b>
                                      </span>
                                    </h4>
                                  </div>

                                  {/* Type */}
                                  <div className='col-12 px-4 ps-0 pt-1'>
                                    <h4 className='my-4' style={{color: 'black'}}>
                                      <label style={{minWidth: '100px'}} className='labl-gry me-3'>
                                        {' '}
                                        Type{' '}
                                      </label>
                                      {jobsDetails?.maintenanceJobType == '2' ? (
                                        <span className='ms-5 ps-1'>
                                          <b>
                                            {unitData?.unitType === 0
                                              ? 'Apartment'
                                              : unitData?.unitType === 1
                                              ? 'Penthouse'
                                              : unitData?.unitType === 2
                                              ? `${unitData?.commonAreaName}`
                                              : unitData?.unitType === 3
                                              ? 'Townhouse'
                                              : unitData?.unitType === 4
                                              ? 'Villa'
                                              : unitData?.unitType === 5
                                              ? `Other - ${unitData?.otherName}`
                                              : unitData?.unitType === 6
                                              ? 'Office'
                                              : unitData?.unitType === 7
                                              ? 'Restaurant'
                                              : unitData?.unitType === 8
                                              ? 'Cafe'
                                              : unitData?.unitType === 9
                                              ? 'Retail'
                                              : '-'}

                                            {/* {unitData?.unitType === 'town_house'
                                          ? 'TownHouse'
                                          : unitData?.unitType === 'other' 
                                          ? 'Other'
                                          : unitData?.unitType === 'common_area'
                                          ? 'Common Area'
                                          : unitData?.unitType === 'villa'
                                          ? 'Villa'
                                          : unitData?.unitType === 'apartment'
                                          ? 'Apartment'
                                          : unitData?.unitType === 'penthouse'
                                          ? 'Penthouse'
                                          : '-'} */}
                                          </b>
                                        </span>
                                      ) : (
                                        <span className='ms-5 ps-1'>
                                          <b>{unitData?.commonAreaName
                                            ? unitData?.commonAreaName
                                            : '-'}</b>
                                        </span>
                                      )}
                                    </h4>
                                  </div>

                                  {unitData?.unitType != 2 && (
                                    <div className='col-12 px-4 ps-0 pt-1'>
                                      <h4 className='my-4' style={{color: 'black'}}>
                                        <label
                                          style={{minWidth: '100px'}}
                                          className='labl-gry me-3'
                                        >
                                          {' '}
                                          Bedroom{' '}
                                        </label>
                                        <span className='ms-5 ps-1'>
                                          <b>{unitData?.bedrooms ? unitData?.bedrooms : '-'}</b>
                                        </span>
                                      </h4>
                                    </div>
                                  )}

                                  {unitData?.unitType != 2 && (
                                    <div className='col-12 px-4 ps-0 pt-1'>
                                      <h4 className='my-4' style={{color: 'black'}}>
                                        <label
                                          style={{minWidth: '100px'}}
                                          className='labl-gry me-3'
                                        >
                                          {' '}
                                          Size{' '}
                                        </label>
                                        <span className='ms-5 ps-1'>
                                          <b>{unitData?.size ? `${unitData?.size} SqFt` : '-'}</b>
                                        </span>
                                      </h4>
                                    </div>
                                  )}

                                  {/* Floor */}
                                  {unitData?.floorId && (
                                    <div className='col-12 px-4 ps-0 pt-1'>
                                      <h4 className='my-4' style={{color: 'black'}}>
                                        <label
                                          style={{minWidth: '100px'}}
                                          className='labl-gry me-3'
                                        >
                                          {' '}
                                          Floor{' '}
                                        </label>
                                        <span className='ms-5 ps-1'>
                                          <b>
                                            {unitData?.floorId ? unitData?.floor?.[0]?.name : ''}
                                          </b>
                                        </span>
                                      </h4>
                                    </div>
                                  )}

                                  {/* Building */}
                                  {unitData?.cluster?.[0]?.type == 1 && (
                                    
                                    <div className='col-12 px-4 pt-1 ps-0'>
                                      <h4 className='my-4' style={{color: 'black'}}>
                                        <label
                                          style={{minWidth: '100px'}}
                                          className='labl-gry me-3'
                                        >
                                          {' '}
                                          Building{' '}
                                        </label>
                                        <span className='ms-5 ps-1'>
                                          <b>
                                            {unitData?.buildingId
                                              ? unitData?.building?.[0]?.name
                                              : '-'}
                                          </b>
                                        </span>
                                      </h4>
                                    </div>
                                  )}

                                  {/* Group */}
                                  {unitData?.cluster?.[0]?.type == 2 && (
                                    <div className='col-12 px-4 ps-0 pt-1'>
                                      <h4 className='my-4' style={{color: 'black'}}>
                                        <label
                                          style={{minWidth: '100px'}}
                                          className='labl-gry me-3'
                                        >
                                          {' '}
                                          Group{' '}
                                        </label>
                                        <span className='ms-5 ps-1'>
                                          <b>
                                            {unitData?.unitGroupId
                                              ? unitData?.unit_group?.[0]?.name
                                              : ''}
                                          </b>
                                        </span>
                                      </h4>
                                    </div>
                                  )}

                                  {unitData?.clusterId && (
                                    <div className='col-12 px-4 pt-1 ps-0'>
                                      <h4 className='my-4' style={{color: 'black'}}>
                                        <label
                                          style={{minWidth: '100px'}}
                                          className='labl-gry me-3'
                                        >
                                          {' '}
                                          Cluster{' '}
                                        </label>
                                        <span className='ms-5 ps-1'>
                                          <b>
                                            {unitData?.clusterId
                                              ? unitData?.cluster?.[0]?.name
                                              : unitData?.building?.[0]?.name}
                                          </b>
                                        </span>
                                      </h4>
                                    </div>
                                  )}

                                  <>
                                    <div className='col-12 px-4 ps-0 d-flex'>
                                      <h4 className='my-4' style={{color: 'black'}}>
                                        <label
                                          style={{minWidth: '100px'}}
                                          className='labl-gry me-3'
                                        >
                                          {' '}
                                          Development{' '}
                                        </label>
                                        <span className='ms-5 ps-1'>
                                          <b>
                                            {unitData?.communityId
                                              ? unitData?.community?.[0]?.name
                                              : unitData?.building
                                              ? unitData?.building?.[0]?.name
                                              : '-'}
                                          </b>
                                        </span>
                                      </h4>
                                    </div>
                                  </>
                                </div>
                                
                                {unitData?.unitType != 2 &&
                                <div className='col-4'>
                                  <div className='col-12 px-4'>
                                    <div
                                      className={
                                        unitData?.occupy == 0
                                          ? 'status expired-bar status-w-120 my-2'
                                          : 'status active-bar  status-w-120 my-2'
                                      }
                                    >
                                      {unitData?.occupy == 0 ? 'Vacant' : 'Occupied'}
                                    </div>
                                  </div>
                                  {unitData?.occupy == 1 && (
                                    <div className='col-12 px-4'>
                                      <div className='row'>
                                        <h4 className='my-4' style={{color: 'black'}}>
                                          <label
                                            style={{minWidth: '100px'}}
                                            className='labl-gry me-3'
                                          >
                                            {' '}
                                            Tenant{' '}
                                          </label>
                                        </h4>

                                        <h4 className='my-4' style={{color: 'black'}}>
                                          <span className=''>
                                            <b>
                                              {tenant
                                                ? `${tenant?.firstName} ${tenant?.lastName}`
                                                : '-'}
                                            </b>
                                          </span>
                                        </h4>
                                      </div>
                                    </div>
                                  )}
                                </div>
                                }

                                <>
                                {unitData?.unitType != 2 && (
                                  <div className='col-12 px-4 ps-3'>
                                    <h4 className='my-4 mb-0' style={{color: 'black'}}>
                                      <label style={{minWidth: '100px'}} className='labl-gry me-3'>
                                        {' '}
                                        Property Manager{' '}
                                      </label>
                                      <span className='ms-5 ps-1'>
                                        <b>{unitData?.managerId ? unitData?.managerId : '-'}</b>
                                      </span>
                                    </h4>
                                  </div>
                                )}
                                </>
                              </div>
                            </>
                          )}

                        {jobsDetails?.maintenanceJobType == 0 && (
                          <>
                            <div
                              className='row d-flex me-5 mb-5 px-0 ms-0'
                              style={{marginTop: '-5px'}}
                            >
                              <div className='col-12'>
                                <>
                                  {/* Mixed Cluster - Group */}
                                  {jobsDetails?.cluster?.[0]?.type == 2 && (
                                    <div className='col-12 px-4 ps-0 d-flex'>
                                      <h4 className='my-4' style={{color: 'black'}}>
                                        <label
                                          style={{minWidth: '100px'}}
                                          className='labl-gry me-3'
                                        >
                                          {' '}
                                          Group{' '}
                                        </label>
                                        <span className='ms-5 ps-1'>
                                          <b>
                                            {jobsDetails?.unit_group?.length > 0
                                              ? jobsDetails?.unit_group?.[0]?.name
                                              : '-'}
                                          </b>
                                        </span>
                                      </h4>
                                    </div>
                                  )}

                                  {/* Building Cluster - Building */}
                                  {jobsDetails?.cluster?.[0]?.type == 1 && (
                                    <div className='col-12 px-4 ps-0 d-flex'>
                                      <h4 className='my-4' style={{color: 'black'}}>
                                        <label
                                          style={{minWidth: '100px'}}
                                          className='labl-gry me-3'
                                        >
                                          {' '}
                                          Building{' '}
                                        </label>
                                        <span className='ms-5 ps-1'>
                                          <b>
                                            {jobsDetails?.building?.length > 0
                                              ? jobsDetails?.building?.[0]?.name
                                              : '-'}
                                          </b>
                                        </span>
                                      </h4>
                                    </div>
                                  )}
                                  {/* Cluster */}
                                  {jobsDetails?.communityId != null && (
                                    <div className='col-12 px-4 ps-0 d-flex'>
                                      <h4 className='my-4' style={{color: 'black'}}>
                                        <label
                                          style={{minWidth: '100px'}}
                                          className='labl-gry me-3'
                                        >
                                          {' '}
                                          Cluster{' '}
                                        </label>
                                        <span className='ms-5 ps-1'>
                                          <b>
                                            {jobsDetails?.cluster?.length > 0
                                              ? jobsDetails?.cluster?.[0]?.name
                                              : '-'}
                                          </b>
                                        </span>
                                      </h4>
                                    </div>
                                  )}
                                  {/* Development */}
                                  <div className='col-12 px-4 ps-0 d-flex'>
                                    <h4 className='my-4' style={{color: 'black'}}>
                                      <label style={{minWidth: '100px'}} className='labl-gry me-3'>
                                        {' '}
                                        Development{' '}
                                      </label>
                                      <span className='ms-5 ps-1'>
                                        <b>
                                          {jobsDetails?.communityId
                                            ? jobsDetails?.community?.[0]?.name
                                            : jobsDetails?.buildingId
                                            ? jobsDetails?.building?.[0]?.name
                                            : '-'}
                                        </b>
                                      </span>
                                    </h4>
                                  </div>
                                </>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>

                    {/* Team Card */}
                    <div className='col-12 px-5 mt-5'>
                      <div className={'card card-flush py-5  mt-5  pe-5 mb-5 ps-3'}>
                        <div className='d-flex'>
                          <div className='d-block px-3 py-2'>
                            <h2 className='top ps-0'>
                              <b>Handled By</b>
                            </h2>
                            {requestData?.handlerType == 1 ? (
                              <div className='d-flex my-4'>
                                <h4 className='my-2' style={{color: 'black'}}>
                                  <label style={{width: '130px'}} className='labl-gry '>
                                    {' '}
                                    Vendor Name
                                  </label>
                                  <span className='ms-1'>
                                    <b>
                                      {selectedVendor?.companyName
                                        ? selectedVendor?.companyName
                                        : '-'}
                                    </b>
                                  </span>
                                </h4>
                              </div>
                            ) : (
                              <div className='d-flex my-4'>
                                <h4 className='my-2' style={{color: 'black'}}>
                                  <label style={{width: '130px'}} className='labl-gry '>
                                    {' '}
                                    Team Name
                                  </label>
                                  <span className='ms-1'>
                                    <b>{'Internal Team'}</b>
                                  </span>
                                </h4>
                              </div>
                            )}
                            {requestData?.handlerType == 1 && (
                              <>
                                <div className='d-flex my-4'>
                                  <h4 className='my-2' style={{color: 'black'}}>
                                    <label style={{width: '120px'}} className='labl-gry '>
                                      {' '}
                                      Main Contact
                                    </label>
                                    <span className='ms-5'>
                                      <b>
                                        {selectedVendor?.contactInformation?.[0]?.firstName !=
                                          null ||
                                        selectedVendor?.contactInformation?.[0]?.lastName != null
                                          ? `${selectedVendor?.contactInformation?.[0]?.firstName} ${selectedVendor?.contactInformation?.[0]?.lastName}`
                                          : '-'}
                                      </b>
                                    </span>
                                  </h4>
                                </div>

                                <div className='d-flex my-4'>
                                  <h4 className='my-2' style={{color: 'black'}}>
                                    <label style={{width: '120px'}} className='labl-gry '>
                                      {' '}
                                    </label>
                                    <span className='ms-5'>
                                      <b>
                                        {selectedVendor?.contactInformation?.[0]?.email
                                          ? selectedVendor?.contactInformation?.[0]?.email
                                          : '-'}
                                      </b>
                                    </span>
                                  </h4>
                                </div>

                                <div className='d-flex my-4'>
                                  <h4 className='my-2' style={{color: 'black'}}>
                                    <label style={{width: '120px'}} className='labl-gry '>
                                      {' '}
                                    </label>
                                    <span className='ms-5'>
                                      <b>
                                        {selectedVendor?.contactInformation?.[0]?.phoneNumber
                                          ? selectedVendor?.contactInformation?.[0]?.phoneNumber
                                          : '-'}
                                      </b>
                                    </span>
                                  </h4>
                                </div>
                              </>
                            )}
                          </div>

                          <div className={`mainRequestBox mt-3 ms-auto`} style={{height: '150px'}}>
                            <h2 className={`head-text mb-3`}>
                              <b>
                                {requestData?.handlerType == 0
                                  ? 'Internal Team'
                                  : requestData?.handlerType == 1
                                  ? 'Vendor'
                                  : '-'}{' '}
                              </b>
                            </h2>
                            <img
                              src={
                                requestData?.handlerType == 0
                                  ? internalTeamGreen
                                  : requestData?.handlerType == 1
                                  ? externalTeamGreen
                                  : ''
                              }
                              height={80}
                              width={80}
                              className='me-3 d-block mt-3'
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/*  Visit Schedules */}
              <div
                className={'card card-flush py-5 mt-9 px-5 mb-5 mx-7 '}
                style={{position: 'relative', zIndex: '10'}}
              >
                <div className='d-flex  align-items-center'>
                  <h2 className='' style={{fontWeight: '700'}}>
                    {'Visit Schedules'}
                  </h2>

                  <button
                    type='button'
                    className='btn btn-sm fw-bold px-2 green-submit-btn btn-fit-content ms-auto pe-5'
                    onClick={() => {
                      setMode('add')
                      setScheduleToAddEdit({})
                      setShowSchedule(true)
                    }}
                    disabled={
                      jobsDetails?.maintenanceJobStatus == 2 ||
                      jobsDetails?.maintenanceJobStatus == 3
                        ? true
                        : false
                    }
                  >
                    <img src={scheduleWhite} height={20} width={20} className='me-3' /> Schedule
                    Visitation
                  </button>
                </div>

                <div
                  className='table-responsive mt-4'
                  style={{maxHeight: '400px', overflow: 'auto'}}
                >
                  <table
                    className='table align-start table-row-dashed fs-6 gy-5'
                    id='kt_ecommerce_sales_table'
                  >
                    <thead>
                      <th className='text-start min-w-100px ps-0'>Date</th>
                      <th className='text-start min-w-100px ps-0'>Time</th>
                      {jobsDetails?.maintenanceJobType == 2 &&
                      <th
                        className='text-start min-w-100px ps-0'
                        style={unitData?.occupy == 0 ? {opacity: '0.4'} : {opacity: '1'}}
                      >
                        Tenant
                      </th>
                      } 
                      {requestData?.handlerType == 1 && (
                        <th className='text-start min-w-100px ps-0'>Vendor</th>
                      )}
                      <th className='text-start min-w-100px ps-0'>Schedule Status</th>
                      <th className='text-center min-w-150px'>Visit Status</th>
                      <th className='text-start min-w-125px ps-0'>Start</th>
                      <th className='text-start min-w-125px ps-0'>End</th>
                      <th className='text-start min-w-150px'>Duration</th>
                      <th className='text-end min-w-75px'></th>
                    </thead>

                    {/* <div > */}
                    <tbody className='fw-semibold head-text'> {/* className='fw-semibold text-gray-600'*/ }
                      {jobsSchedule?.map((rs: any, index: any) => {
                        let givenDate = new Date(rs?.date)
                        let currentDate = new Date()

                        const givenDateWithoutTime = new Date(
                          givenDate.getFullYear(),
                          givenDate.getMonth(),
                          givenDate.getDate()
                        )
                        const currentDateWithoutTime = new Date(
                          currentDate.getFullYear(),
                          currentDate.getMonth(),
                          currentDate.getDate()
                        )

                        let result: any
                        // Compare the dates
                        if (givenDateWithoutTime.getTime() === currentDateWithoutTime.getTime()) {
                          result = 0
                        } else if (givenDateWithoutTime < currentDateWithoutTime) {
                          result = 0
                        } else {
                          result = 1
                        }
                        return (
                          <tr>
                            <td className='text-start ps-0'>
                              {rs?.date ? `${convertUTCToLocalTime(rs?.date, 1)}` : '-'}
                            </td>
                            <td className='text-start ps-0'>
                              {`
                                ${convertUTCToLocalTime(rs?.startTime, 2)} - 
                                ${convertUTCToLocalTime(rs?.endTime, 2)}`}
                            </td>
                            {jobsDetails?.maintenanceJobType == 2 &&
                            <td
                              className='text-start ps-0'
                              style={unitData?.occupy == 0 ? {opacity: '0.4'} : {opacity: '1'}}
                            >
                              {rs?.tenantConfirmationStatus == 0
                                ? 'Not Confirmed'
                                : rs?.tenantConfirmationStatus == 1
                                ? 'Confirmed'
                                : rs?.tenantConfirmationStatus == 2
                                ? 'Cancelled'
                                : rs?.tenantConfirmationStatus == 3
                                ? 'Not Registered'
                                : '-'}
                            </td>
                      }

                            {requestData?.handlerType == 1 && (
                              <td className='text-start ps-0'>
                                {rs?.vendorConfirmationStatus == 0
                                  ? 'Not Confirmed'
                                  : rs?.vendorConfirmationStatus == 1
                                  ? 'Confirmed'
                                  : rs?.vendorConfirmationStatus == 2
                                  ? 'Cancelled'
                                  : rs?.vendorConfirmationStatus == 3
                                  ? 'Not Registered'
                                  : '-'}
                              </td>
                            )}
                            <td
                              className='text-start ps-0'
                              style={
                                rs?.scheduleStatus == 0
                                  ? {}
                                  : rs?.scheduleStatus == 1
                                  ? {color: '#0d9e91'}
                                  : rs?.scheduleStatus == 2
                                  ? {color: '#d54645'}
                                  : rs?.scheduleStatus == 3
                                  ? {color: '#ff9a1e'}
                                  : rs?.scheduleStatus == 4
                                  ? {color: '#000'}
                                  : rs?.scheduleStatus == 5 || rs?.scheduleStatus == 6
                                  ? {color: '#d54645'}
                                  : {}
                              }
                            >
                              <b>
                                {rs?.scheduleStatus == 0
                                  ? 'Requested'
                                  : rs?.scheduleStatus == 1
                                  ? 'Upcoming'
                                  : rs?.scheduleStatus == 2
                                  ? 'Not Started'
                                  : rs?.scheduleStatus == 3
                                  ? 'In Progress'
                                  : rs?.scheduleStatus == 4
                                  ? 'Ended'
                                  : rs?.scheduleStatus == 5 || rs?.scheduleStatus == 6
                                  ? 'Cancelled'
                                  : '-'}
                              </b>
                              {rs?.scheduleStatus == 6 && (
                                <span>
                                  <img src={circularImg} height={16} width={16} className='ms-3' />
                                </span>
                              )}
                            </td>
                            <td className='text-center'>
                              <div
                                className={
                                  rs?.visitStatus == 0
                                    ? 'status draft-st mx-auto status-w-120'
                                    : rs?.visitStatus == 1
                                    ? 'status renewed-st mx-auto  status-w-120'
                                    : rs?.visitStatus == 2
                                    ? 'status requested-st mx-auto  status-w-120'
                                    : rs?.visitStatus == 3
                                    ? 'status cancelled-st mx-auto  status-w-120'
                                    : 'status  not-saved mx-auto  status-w-120'
                                }
                              >
                                {rs?.visitStatus == 0
                                  ? 'Early Arrival'
                                  : rs?.visitStatus == 1
                                  ? 'On Time'
                                  : rs?.visitStatus == 2
                                  ? 'Late'
                                  : rs?.visitStatus == 3
                                  ? 'No Show'
                                  : '-'}
                              </div>
                            </td>
                            <td className='text-start ps-0'>
                              <div>
                                {rs?.visitStartTime != null
                                  ? moment
                                      ?.utc(rs?.visitStartTime)
                                      ?.local()
                                      ?.format('DD.MM.YYYY - HH:mm')
                                  : '-'}

                                {/* {((((rs?.tenantConfirmationStatus == 1 &&
                                rs?.vendorConfirmationStatus == 1) ||
                                (rs?.tenantConfirmationStatus == 1 &&
                                  rs?.vendorConfirmationStatus == 3) ||
                                (rs?.tenantConfirmationStatus == 3 &&
                                  rs?.vendorConfirmationStatus == 1) ||
                                (rs?.tenantConfirmationStatus == 3 &&
                                  rs?.vendorConfirmationStatus == 3)) &&
                                rs?.scheduleStatus != 0 &&
                                rs?.scheduleStatus != 5 &&
                                rs?.scheduleStatus != 6) || result == 0) && ( */}
                                <img
                                  src={clockImg}
                                  height={16}
                                  width={16}
                                  className='mx-3 cursor-pointer'
                                  onClick={() => {
                                    setScheduleToAddEdit(rs)
                                    setShowJobStartTime(true)
                                  }}
                                />
                                {/* )} */}
                              </div>
                              {rs?.visitStartTime != null && (
                                <div className='mt-1'>
                                  <i>
                                    {`${rs?.visitStartConfirmationByUser?.firstName} ${
                                      rs?.visitStartConfirmationByUser?.lastName
                                    } - ${moment
                                      ?.utc(rs?.visitStartConfirmationStatusAt)
                                      ?.local()
                                      ?.format('DD.MM.YYYY - HH:mm')}`}
                                  </i>
                                </div>
                              )}
                            </td>
                            <td className='text-start ps-0'>
                              <div>
                                {rs?.visitEndTime != null
                                  ? moment
                                      ?.utc(rs?.visitEndTime)
                                      ?.local()
                                      ?.format('DD.MM.YYYY - HH:mm')
                                  : '-'}

                                {/* {((((rs?.tenantConfirmationStatus == 1 &&
                                rs?.vendorConfirmationStatus == 1) ||
                                (rs?.tenantConfirmationStatus == 1 &&
                                  rs?.vendorConfirmationStatus == 3) ||
                                (rs?.tenantConfirmationStatus == 3 &&
                                  rs?.vendorConfirmationStatus == 1) ||
                                (rs?.tenantConfirmationStatus == 3 &&
                                  rs?.vendorConfirmationStatus == 3)) &&
                                rs?.scheduleStatus != 0 &&
                                rs?.scheduleStatus != 1 &&
                                rs?.scheduleStatus != 2 &&
                                rs?.scheduleStatus != 5 &&
                                rs?.scheduleStatus != 6) || result == 0) && ( */}
                                {rs?.visitStartTime != null && (
                                  <img
                                    src={clockImg}
                                    height={16}
                                    width={16}
                                    className='mx-3 cursor-pointer'
                                    onClick={() => {
                                      setScheduleToAddEdit(rs)
                                      setShowJobEndTime(true)
                                    }}
                                  />
                                )}
                              </div>
                              {rs?.visitEndTime != null && (
                                <div className='mt-1'>
                                  <i>
                                    {`${rs?.visitEndConfirmationByUser?.firstName} ${
                                      rs?.visitEndConfirmationByUser?.lastName
                                    } - ${moment
                                      ?.utc(rs?.visitEndConfirmationStatusAt)
                                      ?.local()
                                      ?.format('DD.MM.YYYY - HH:mm')}`}
                                  </i>
                                </div>
                              )}
                            </td>
                            <td className='text-start'>
                              <div className='d-flex align-items-center '>
                                {(rs?.visitEndTime != null || rs?.visitStartTime != null) &&
                                rs?.totalDuration != undefined
                                  ? `${Math.floor(rs?.totalDuration)}m`
                                  : '-'}
                              </div>
                            </td>
                            <td className='text-end ps-0'>
                              {(rs?.createdByType == 1 || rs?.createdByType == 2) &&
                              rs?.scheduleStatus == 0 &&
                              rs?.corporateConfirmationStatus != 1 &&
                              rs?.corporateConfirmationStatus != 2 ? (
                                <div className='d-flex ms-auto' style={{width: 'max-content'}}>
                                  <img
                                    src={removed}
                                    height={18}
                                    width={18}
                                    className='ms-4 cursor-pointer'
                                    onClick={() => {
                                      swal
                                        .fire({
                                          html: ` 
                                      <div class='fs-3'> <b>Cancel Visit Schedule Request</b></div>
                                      <div class='fs-5 mt-4'> Are you sure you want to cancel this visit schedule request ? </div>`,
                                          // icon: 'warning',
                                          showConfirmButton: true,
                                          confirmButtonColor: '#35475e',
                                          confirmButtonText: 'Yes',
                                          showCancelButton: true,
                                          cancelButtonText: 'No',
                                          cancelButtonColor: '#fff',
                                          customClass: {
                                            confirmButton: 'custom-confirm-button',
                                            cancelButton: 'custom-cancel-button',
                                            popup: 'custom-popup',
                                          },
                                          reverseButtons: true,
                                        })
                                        .then((res: any) => {
                                          if (res.isConfirmed) {
                                            corporateConfirmation(rs, 2)
                                          }
                                        })
                                    }}
                                  />
                                  <img
                                    src={correct}
                                    height={18}
                                    width={18}
                                    className='mx-5 cursor-pointer'
                                    onClick={() => {
                                      corporateConfirmation(rs, 1)
                                    }}
                                  />
                                </div>
                              ) : (
                                <div className='d-flex ms-auto' style={{width: 'max-content'}}>
                                  {rs?.scheduleStatus == 4 ||
                                  rs?.scheduleStatus == 5 ||
                                  rs?.scheduleStatus == 6 ? (
                                    <></>
                                  ) : (
                                    <img
                                      src={pencil}
                                      alt=''
                                      height={18}
                                      width={18}
                                      className='ms-4 cursor-pointer'
                                      onClick={() => {
                                        setMode('edit')
                                        setScheduleToAddEdit(rs)
                                        setScheduleIndToAddEdit(index)
                                        setShowSchedule(true)
                                      }}
                                    />
                                  )}

                                  {rs?.scheduleStatus == 5 || rs?.scheduleStatus == 6 ? (
                                    <img
                                      src={trashImg}
                                      height={19}
                                      width={19}
                                      className='ms-auto me-5 cursor-pointer'
                                      onClick={() => {
                                        swal
                                          .fire({
                                            html: ` 
                                      <div class='fs-3'> <b>Delete Visit Schedule</b></div>
                                      <div class='fs-5 mt-4'> Are you sure you want to delete this visit ? </div>`,
                                            // icon: 'warning',
                                            showConfirmButton: true,
                                            confirmButtonColor: '#35475e',
                                            confirmButtonText: 'Yes',
                                            showCancelButton: true,
                                            cancelButtonText: 'No',
                                            cancelButtonColor: '#fff',
                                            customClass: {
                                              confirmButton: 'custom-confirm-button',
                                              cancelButton: 'custom-cancel-button',
                                              popup: 'custom-popup',
                                            },
                                            reverseButtons: true,
                                          })
                                          .then((res: any) => {
                                            if (res.isConfirmed) {
                                              deleteVisit(rs)
                                            }
                                          })
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src={cancelImg}
                                      height={18}
                                      width={18}
                                      className='mx-5 cursor-pointer'
                                      onClick={() => {
                                        swal
                                          .fire({
                                            html: ` 
                                      <div class='fs-3'> <b>Cancel Visit Schedule</b></div>
                                      <div class='fs-5 mt-4'> Are you sure you want to cancel this visit schedule ? </div>`,
                                            // icon: 'warning',
                                            showConfirmButton: true,
                                            confirmButtonColor: '#35475e',
                                            confirmButtonText: 'Yes',
                                            showCancelButton: true,
                                            cancelButtonText: 'No',
                                            cancelButtonColor: '#fff',
                                            customClass: {
                                              confirmButton: 'custom-confirm-button',
                                              cancelButton: 'custom-cancel-button',
                                              popup: 'custom-popup',
                                            },
                                            reverseButtons: true,
                                          })
                                          .then((res: any) => {
                                            if (res.isConfirmed) {
                                              cancelVisit(rs)
                                            }
                                          })
                                      }}
                                    />
                                  )}
                                </div>
                              )}
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                    {/* </div> */}
                  </table>
                </div>
              </div>
              {jobsDetails?.maintenanceJobType == 2 ? (
                <div className='row mx-1 d-flex'>
                  <div
                    className='col-12 col-md-6'
                    style={{display: 'flex', flexDirection: 'column'}}
                  >
                    <HandlingTeamComments modalData={jobsDetails} coments={jobsDetails?.comments} />
                  </div>
                  <div
                    className='col-12 col-md-6 position-relative'
                    style={{display: 'flex', flexDirection: 'column'}}
                  >
                    {unitData?.occupy == 0 && (
                      <div
                        className='tenantOpacityBox'
                        style={{zIndex: '1000', opacity: '0.7', cursor: 'not-allowed'}}
                      ></div>
                    )}
                    <TenantComments modalData={tenant} coments={jobsDetails?.tenantComments} />
                  </div>
                </div>
              ) : (
                <div className='row mx-1 d-flex'>
                  <div
                    className='col-12 col-md-12'
                    style={{display: 'flex', flexDirection: 'column'}}
                  >
                    <HandlingTeamCommentsExceptUnit
                      modalData={jobsDetails}
                      coments={jobsDetails?.comments}
                    />
                  </div>
                </div>
              )}

              <JobReportLog
                modalData={tenant}
                coments={jobsDetails?.tenantComments}
                jobDetails={jobsDetails}
              />
            </div>
          </div>
        </div>
      ) : (
        <div
          className='d-flex justify-content-center align-items-center w-100'
          style={{height: '75vh'}}
        >
          <CircularProgress style={{color: '#007a59'}} />
        </div>
      )}

      <Backdrop sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}} open={open}>
        <CircularProgress style={{color: '#007a59'}} />
      </Backdrop>
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-900px mx-auto'
        show={approvalWorkflowCard}
        onHide={() => {
          setApprovalWorkflowCard(false)
        }}
        backdrop={true}
        size='sm'
      >
        <div
          className='modal-body py-lg-0 px-lg-0'
          style={{
            transform: 'translate(0px, -70px)',
            // border: '3px solid #384a62',
            top: '70px',
            borderRadius: '5px',
            boxShadow: '0px 0px 20px 0px #516176',
            // minHeight: '150px',
          }}
        >
          <div className='my-1 pb-1 row mx-3 mt-3'>
            <div className='col-md-12 text-end'>
              <label
                className='head-text fs-5 cursor-pointer'
                style={{fontWeight: '700'}}
                onClick={() => {
                  setApprovalWorkflowCard(false)
                }}
              >
                X
              </label>
            </div>
          </div>
        </div>
      </Modal>

      {/* Schedule */}
      {showSchedule && mode && (
        <ScheduleVisitation
          show={showSchedule}
          handleClose={() => {
            setShowSchedule(false)
          }}
          modalData={scheduleToAddEdit}
          dataInd={scheduleIndToAddEdit}
          updateData={(data: any, ind: any) => {
            getMaintenanceServiceJobSchedule()
          }}
          mode={mode}
        />
      )}

      {/* Job Start Time */}
      {showJobStartTime && (
        <JobStartTimeModal
          show={showJobStartTime}
          handleClose={() => {
            setShowJobStartTime(false)
          }}
          modalData={scheduleToAddEdit}
          updateData={(data: any, ind: any) => {
            getMaintenanceServiceJobSchedule()
            setShowJobStartTime(false)
          }}
        />
      )}

      {/* Job End Time */}
      {showJobEndTime && (
        <JobEndTimeModal
          show={showJobEndTime}
          handleClose={() => {
            setShowJobEndTime(false)
          }}
          modalData={scheduleToAddEdit}
          updateData={(data: any, ind: any) => {
            getMaintenanceServiceJobSchedule()
            setShowJobEndTime(false)
          }}
        />
      )}
    </>
  )
}

export default ServiceJob
