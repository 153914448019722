import React, {useState} from 'react'
import daysDueIcon from '../../../../img/daysDue.png'
import {useFormContext} from 'react-hook-form'
import {preventExpAndSign, removeLeadingZeros} from '../../../../Utilities/utils'

interface LatePaymentInputI {
  isDisabled:boolean
  iconSize: number
  icon?: string
  type?: string
  label: string
  name: string
  value: string
  onChange: any
  required?: boolean
  firstPlaceholder?: string
  secondPlaceHolder?: string
  className?: string
  placeholderMargin?: string
}

const PaymentDueInput = ({
  type = 'text',
  label,
  name,
  value,
  onChange,
  required = false,
  firstPlaceholder = '-',
  secondPlaceHolder = 'Days',
  icon,
  iconSize,
  className = '',
  placeholderMargin = '',
  isDisabled
}: // capitalizeFirst = false
LatePaymentInputI) => {
  const {
    register,
    setValue,
    formState: {errors},
  } = useFormContext()

  const handleChange = (e: any) => {
    let newValue = removeLeadingZeros(e.target.value)

    onChange(newValue)

    if(newValue.trim().length === 0) {
        setValue(name, newValue, {
          shouldValidate: true,
        })
      }else{
        setValue(name, newValue, {
          shouldValidate: true,
        })
      }
  }

  function enforceMinMax(event:any) {
    const el = event.target;
    if (el.value != "") {
      if (parseInt(el.value) < parseInt(el.min)) {
        el.value = el.min;
      }
      console.log(el.min)
      console.log(el.max)
      if (parseInt(el.value) > parseInt(el.max)) {
        el.value = el.max;
      }
    }
  }

  return (
    <div className={`${className}`}>
      <div className='label-container'>
        <img src={icon} height={iconSize} width={iconSize} />
        <label className='input-label required'>{label}</label>
      </div>
        <h4>
      <div className='input-wrapper'>
          <input
          disabled={isDisabled}
            {...register(name, {required})}
            onKeyUp={enforceMinMax}
            min={0}
            type={type}
            name={name}
            value={value}
            onKeyDown={type === 'number' ? preventExpAndSign : () => {}}
            onChange={handleChange}
            className={`form-control form-control-solid  latePaymentInput ${
              type === 'number' ? 'hide-number-input-arrows' : ''
            } ${value ? 'latePaymentInputFilled' : ''}`}
            style={{backgroundColor: '#fff', width: '180px', height: '35px',color:'#333333',fontWeight:'500'}}
            autoComplete='off'
          />
          <span className='placeholder-dash'>
            {value === '' ? `${firstPlaceholder}` : ''}
          </span>
          <span className='placeholder-days' style={{right: `${placeholderMargin}`}}>
            {`${secondPlaceHolder}`}
          </span>
      </div>
       
      <div className='input-wrapper'>
        {errors[name] && (
          <p style={{color: 'var(--kt-danger)', gridColumn: '2/2'}} className='m-0 mt-1'>
            This field is required
          </p>
        )}
      </div>
      </h4>
    </div>
  )
}

export default PaymentDueInput
