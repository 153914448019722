import React, {useState, useEffect} from 'react'
import {Modal, Row, Col} from 'react-bootstrap'
import {CircularProgress} from '@mui/material'
import redCross from '../../../../img/remove.png'
import plusWhite from '../../../../img/add-white.png'
import {ApiPost, ApiGet} from '../../../../apiCommon/helpers/API/ApiData'
import {SuccessToast, ErrorToast} from '../../../../apiCommon/helpers/Toast'
import {useLocation, useParams} from 'react-router-dom'
import plusGreen from '../../../../img/add.png'
import Backdrop from '@mui/material/Backdrop'
import Select from 'react-select'

interface AddItemProps {
  show: boolean
  handleClose: any
  from: any
  updateRecord: any
}

const AddItem: React.FC<AddItemProps> = ({show, handleClose, from, updateRecord}) => {
  const {state} = useLocation()
  const {id, poId} = useParams()
  const [expenseItems, setExpenseItems] = useState<any>([
    {
      item: '',
    },
  ])

  const [isLoading, setIsLoading] = useState<any>(false)
  const [open, setOpen] = useState<any>(false)

  const [list, setList] = useState<any>([])
  const [selectedList, setSelectedList] = useState<any>('')

  //
  const handleItemsAddFields = () => {
    const values = [...expenseItems]
    values.push({
      item: '',
    })
    setExpenseItems(values)
  }

  //
  const handleItemRemoveFields = (index: any) => {
    const values = [...expenseItems]
    values.splice(index, 1)
    setExpenseItems(values)
  }

  //
  const addItems = async () => {
    let items: any = []
    let j = 0

    let successItems: any = 0
    let failedItems: any = 0
    for (let i = 0; i < expenseItems?.length; i++) {
      if (expenseItems[i]?.item?.length != 0) {
        items[j] = expenseItems[i]?.item
        j++

        const body = {
          name: expenseItems[i]?.item,
          type: [0, 1],
          paymentMainItemListId: from == 'detailed-view' ? id : selectedList,
        }

        let url: any = 'corporate/payment_sub_item_list'

        await ApiPost(`${url}`, body)
          .then((res: any) => {
            successItems = successItems + 1
          })
          .catch((err: any) => {
            failedItems = failedItems + 1
            // ErrorToast(err?.message)
          })
      }
    }

    if (items?.length == successItems) SuccessToast('Items have been added successfully')
    else if (items?.length == failedItems) ErrorToast('Error adding items..')
    else SuccessToast(`${successItems} items successful, ${failedItems}  failed`)

    handleClose()
    updateRecord()
    setExpenseItems([
      {
        item: '',
      },
    ])
  }

  const [listOptions, setListOption] = useState<any>([])

  // React Select
  const customStyles = {
    container: (defaultStyles: any) => ({
      ...defaultStyles,
      width: '250px',
    }),

    option: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      color: state.isSelected ? '#000' : '#000',
      backgroundColor: state.isSelected ? '#c2d8d9' : '',
      ':hover': {
        backgroundColor: '#e0f5f0',
        color: '#000',
      },

      fontWeight: '500',
    }),

    menu: (defaultStyles: any) => ({
      ...defaultStyles,
      border: '0.1rem solid #007a59 ',
      marginTop: '0px',
      borderRadius: '0px',
      zIndex: '2',
    }),
    menuList: (defaultStyles: any) => ({
      ...defaultStyles,
      maxHeight: '200px',
    }),
    placeholder: (defaultStyles: any) => ({
      ...defaultStyles,
      fontStyle: 'Italic',
      zIndex: '1',
    }),

    input: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      color: ' #b5b5c3',
      fontWeight: '500',
    }),

    control: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      boxShadow: 'none',
      color: 'white',
      border: '0.1rem solid #bec3cb',
      borderRadius: '2px',
      padding: '0rem',
      height: '35px',
      ':hover': {
        borderColor: '#007a59',
        backgroundColor: '#fff',
      },
      ':focus': {
        borderColor: '#007a59 !important',
        backgroundColor: '#fff',
      },
      '::placeholder': {
        fontStyle: 'Italic',
      },
      minHeight: '35px',
    }),
    singleValue: (defaultStyles: any) => ({
      ...defaultStyles,
      color: '#5e6278',
      fontWeight: '600',
      zIndex: '1',
    }),
    valueContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: '0px 8px 2px 8px',
    }),
    indicatorContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: '0px 8px 2px 8px',
    }),
    indicatorsContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: '0px 8px 2px 8px',
    }),
  }

  //
  const getListForDropdown = async () => {
    await ApiGet(`corporate/payment_main_item_list`)
      .then((res: any) => {
        let values: any = []
        res?.data?.data?.map((val: any, ind: any) => {
          values[ind] = {
            label: `${val?.name}`,
            value: `${val?._id}`,
          }
        })
        setList(values)
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
      })
  }

  useEffect(() => {
    getListForDropdown()
  }, [])

  return (
    <div>
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-750px mx-auto'
        show={show}
        onHide={() => {
          handleClose()
          setExpenseItems([
            {
              item: '',
            },
          ])
        }}
        backdrop={true}
        size='sm'
        style={{}}
      >
        <Row>
          <div
            className='card p-5 px-9'
            style={{
              boxShadow: '0px 0px 20px 0px #516176',
              zIndex: '1',
              borderRadius: '0px',
              minHeight: '450px',
            }}
          >
            <div className='d-flex align-items-start'>
              <div className='d-block'>
                <h2 className='page-heading d-flex text-dark fw-bold flex-column justify-content-center '>
                  Add Item
                </h2>{' '}
                {from == 'list-view' && <p>Select a list you would like to add your items to.</p>}
              </div>

              <div className='d-flex ms-auto align-items-center'>
                <label
                  className='head-text fs-3 cursor-pointer ms-3'
                  style={{fontWeight: '700'}}
                  onClick={() => {
                    setExpenseItems([
                      {
                        item: '',
                      },
                    ])
                    handleClose()
                  }}
                >
                  X
                </label>
              </div>
            </div>

            {from == 'detailed-view' ? (
              <>
                <div className='d-flex align-items-start justify-content-center my-3'>
                  <h3>
                    <b>Item List</b>
                  </h3>
                </div>

                <div className='d-flex align-items-start justify-content-center mb-3'>
                  <h4>
                    <b>{state?.name}</b>
                  </h4>
                </div>
              </>
            ) : (
              <>
                <div className='d-flex align-items-start justify-content-center my-3'>
                  <h3>
                    <b>Item List</b>
                  </h3>
                </div>
                <div className='d-flex justify-content-center me-4 xyz mb-3'>
                  <Select
                    styles={customStyles}
                    isSearchable={false}
                    options={list}
                    name='sortType'
                    onChange={(e: any) => {
                      setSelectedList(e.value)
                    }}
                    placeholder={'Select Item List'}
                  />
                </div>
              </>
            )}

            <div className='separator my-4'> </div>

            <div className='d-flex align-items-start justify-content-center mb-2'>
              <h2>
                <b>Items</b>
              </h2>
            </div>

            <div className='d-flex align-items-center'>
              <div
                className='d-flex align-items-center cursor-pointer m-5 ms-3 mt-7'
                onClick={() => handleItemsAddFields()}
                style={{marginTop: '30px'}}
              >
                <img src={plusGreen} className='ms-0' height={16} width={16} />
                <p className='dark-green-clr ms-3 mb-0 fnt-600'>Add Another Item</p>
              </div>

              <button
                type='button'
                className='btn btn-sm fw-bold  me-3  green-submit-btn ps-3 status-w-150 ms-auto d-flex justify-content-center'
                onClick={() => {
                  addItems()
                }}
                disabled={from == 'list-view' && selectedList == ''}
              >
                {/* <img src={plusWhite} height={18} width={18} className='me-4' />  */}
                Add
              </button>
            </div>

            <div style={{maxHeight: '350px', overflow: 'auto'}} className='mt-5'>
              {expenseItems.map((rec: any, index: any) => {
                return (
                  <Row className='mx-1 mt-4 ms-0'>
                    <Col lg={6} md={6} className='align-items-center ps-0'>
                      {/* <label className='required mb-2 label-color'>Days after payment is due</label> */}
                      <input
                        type='text'
                        className='form-control form-control-solid-bg mytest'
                        placeholder='Enter Item Name..'
                        name='item_id'
                        value={rec.item}
                        onChange={(e) => {
                          const values = [...expenseItems]
                          let newValue:any = e.target.value != '' ? (e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)) : e.target.value;
                          values[index].item = newValue
                          setExpenseItems(values)
                        }}
                        style={{
                          border: '0.1rem solid #bec3cb',
                          borderRadius: '2px',
                          padding: '0.4rem 1rem',
                        }}
                      />
                    </Col>
                    <Col lg={3} className='align-items-center mb-0  m-2'>
                      <div className='d-flex align-items-center mt-0'>
                        <img
                          src={redCross}
                          height={18}
                          width={18}
                          className='main_img cursor-pointer '
                          onClick={() => handleItemRemoveFields(index)}
                        />
                      </div>
                    </Col>
                    <Col lg={3} md={3} className='align-items-center text-end'></Col>
                  </Row>
                )
              })}
            </div>
          </div>
        </Row>
      </Modal>

      <Backdrop sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}} open={open}>
        <CircularProgress style={{color: '#007a59'}} />
        {/* <div style={{color: '#007a59', fontWeight: '700',
      }}>LOADING..</div> */}
      </Backdrop>
    </div>
  )
}

export default AddItem
